import styled from 'styled-components'

import { capsHeading4_9, textLink3_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const Rounded = styled.span`
  ${capsHeading4_9};

  width: max-content;
  height: max-content;

  padding: ${convertPxToRem(6, 11)};
  border-radius: ${convertPxToRem(5)};
  text-align: center;
  white-space: nowrap;
`

const Circle = styled.span`
  ${textLink3_10};
  width: max-content;
  height: max-content;

  padding: ${convertPxToRem(9, 11)};
  border-radius: ${convertPxToRem(16)};
  text-align: center;
  white-space: nowrap;
`

export const ChipTag = {
  Rounded,
  Circle,
}

import { useFragment } from '@apollo/client'

import { CHAT_DETAILS_FRAGMENT } from './graphql'

export function useChatDetailsFragment(id: string) {
  const from = { __typename: 'Chat', id }
  const fragment = useFragment({ fragment: CHAT_DETAILS_FRAGMENT, from })

  return fragment.complete ? fragment.data : null
}

export { CHAT_DETAILS_FRAGMENT } from './graphql'
export type { ChatDetails } from './types'

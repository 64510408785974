import { useEffect } from 'react'

import { useUnit } from 'effector-react'

import { $deeplink, consumed, saved } from '../model/query'

export function useSaveDeeplink() {
  const save = useUnit(saved)

  useEffect(
    () => save(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

export function useHasDeeplink() {
  return useUnit($deeplink) !== null
}

export function useRestoreDeeplink() {
  const consume = useUnit(consumed)

  useEffect(() => consume(), [consume])
}

import { split, from, Operation, ApolloClient } from '@apollo/client'
import { Kind, OperationTypeNode, type OperationDefinitionNode } from 'graphql'

import auxillaryHeadersLink from 'src/apollo/links/auxillary'
import errorLink from 'src/apollo/links/error'
import loggingLink from 'src/apollo/links/logging'
import retryLink from 'src/apollo/links/retry'
import { instateActionCableLink } from 'src/apollo/links/sockets'
import timeoutLink from 'src/apollo/links/timeout'
import tokenLink from 'src/apollo/links/token'
import uploadLink from 'src/apollo/links/upload'
import { store } from 'src/store'

const hasSubscriptionOperation = ({ query: { definitions } }: Operation) =>
  definitions
    .filter(
      (def): def is OperationDefinitionNode =>
        def.kind === Kind.OPERATION_DEFINITION,
    )
    .some(({ operation }) => operation === OperationTypeNode.SUBSCRIPTION)

export default from([
  errorLink,
  retryLink,
  timeoutLink,
  auxillaryHeadersLink,
  loggingLink(store),
  tokenLink,
  split(hasSubscriptionOperation, instateActionCableLink(), uploadLink),
])

export function onClientCreated(client: ApolloClient<unknown>) {
  errorLink.client = client
  tokenLink.client = client
}

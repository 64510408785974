import React from 'react'

import styled from 'styled-components'

import { AppointmentStatus } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { heading3_25 } from 'src/theme/fonts'

import {
  AppointmentDoctor,
  AppointmentFacility,
} from '../../api/appointmentQuery/types'
import { isDoctorAssignee } from '../../lib/doctor'

interface AppointmentHeaderTitleProps {
  status: AppointmentStatus
  assignee: AppointmentDoctor | AppointmentFacility
}

export function AppointmentHeaderTitle({
  status,
  assignee,
}: AppointmentHeaderTitleProps) {
  const t = useScopedTranslation('translation.appointments.title')

  switch (status) {
    case AppointmentStatus.Suggested:
      return <TitleWrapper>{t('confirm')}</TitleWrapper>

    default:
      return (
        <TitleWrapper>
          {isDoctorAssignee(assignee)
            ? t('with_doctor', assignee)
            : t('at_facility', assignee)}
        </TitleWrapper>
      )
  }
}

const TitleWrapper = styled.h1`
  ${heading3_25};

  grid-row: 1 / 2;
  grid-column: 1 / 2;
`

import React, { ReactElement, cloneElement, useMemo } from 'react'

import styled from 'styled-components'

import { StepIndicatorCircleIcon } from 'src/shared/ui/icons/misc/StepIndicatorCircleIcon'
import { StepIndicatorDotsIcon } from 'src/shared/ui/icons/misc/StepIndicatorDotsIcon'
import { primaryDarkNavy, primaryWhite, secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { PharmacyStepContent, ContentStepType } from '../types'

interface StepProps {
  title: string
  actions: PharmacyStepContent[]

  components: {
    [Action in ContentStepType]: ReactElement<{
      action: PharmacyStepContent
    }>
  }
}

export function SingleStepItem({ title, components, actions }: StepProps) {
  const parts = useMemo(
    () =>
      actions.map((action) =>
        cloneElement(components[action.type]!, { action, key: action.content }),
      ),
    // TODO: look at performance & optimize
    [actions, components],
  )

  return (
    <>
      <LargeDot />
      <StepTitle>{title}</StepTitle>

      <SmallDots />
      <StepContent>{parts}</StepContent>
    </>
  )
}

const StepTitle = styled.h6`
  ${capsHeading1_12};
  text-transform: uppercase;

  height: ${convertPxToRem(18)};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${secondaryGrey};
  [data-theme='dark'] > & {
    color: ${primaryWhite};
  }
`

const StepContent = styled.div`
  padding-bottom: ${convertPxToRem(12)};

  color: ${primaryDarkNavy};

  [data-theme='dark'] > & {
    color: ${primaryWhite};
  }
`

const LargeDot = styled(StepIndicatorCircleIcon)`
  stroke: ${secondaryGrey};

  place-self: center;

  [data-theme='dark'] > & {
    stroke: ${primaryWhite};
  }
`

const SmallDots = styled(StepIndicatorDotsIcon)`
  margin: ${convertPxToRem(6, 0)};
  fill: ${secondaryGrey};

  place-self: center;

  [data-theme='dark'] > & {
    fill: ${primaryWhite};
  }

  &:last-of-type {
    visibility: hidden;
  }
`

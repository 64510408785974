import { TypedDocumentNode, gql } from '@apollo/client'

import { ChatBrief } from './types'

export const CHAT_BRIEF_FRAGMENT: TypedDocumentNode<
  ChatBrief,
  Record<string, never>
> = gql`
  fragment chatBrief on Chat {
    id
    subject
    updatedAt: updated_at

    isUnread: is_unread

    isResolved: is_resolved
    isInactive: is_inactive

    preview: last_message {
      ...chatPreview
    }
  }

  fragment chatPreview on ChatMessage {
    id
    body

    attachment {
      ... on ChatMessageFileType {
        kind: __typename
        contentType: content_type
      }

      ... on SystemNotificationType {
        kind: __typename
        message
      }
    }
  }
`

/* eslint-disable effector/mandatory-scope-binding */
import { ApolloClient, ApolloLink } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'
import { GraphQLFormattedError } from 'graphql'

import { $$logging } from '@entities/logging'

import { $$security } from '@features/security'

import { signOut } from 'src/apollo/utils/authHelpers'
import { ClientContext } from 'src/apollo/utils/types'
import { GraphQLErrorCode } from 'src/constants/errorCodeConstants'
import { ROUTES } from 'src/constants/routesConstants'
import { remoteErrorReceived } from 'src/features/RemoteErrorHandler/model'
import { RemoteError } from 'src/features/RemoteErrorHandler/types'
import persistentStorageInterface from 'src/utils/storage/persistent'
import tokensStorageInterface from 'src/utils/storage/tokens'

type GraphQLErrorHandler = (options: {
  error: GraphQLFormattedError
  name: string
  client: ApolloClient<unknown>
}) => void

type ErrorCode = GraphQLErrorCode | 'unknown'

interface LinkWithClient extends ApolloLink {
  client: ApolloClient<unknown>
}

const SkippableError = new Set<ErrorCode>()
  .add(GraphQLErrorCode.UNABLE_REGISTER)
  .add(GraphQLErrorCode.UNAUTHORIZED)
  .add(GraphQLErrorCode.UNAUTHENTICATED)

const genericError =
  (text: string): GraphQLErrorHandler =>
  ({ error, name }) => {
    $$logging.log({
      line: `[Apollo] @${name} ${text}: ${error.message}`,
      level: 'error',
      meta: { on: 'apollo', operation: name },
    })
  }

const errorHandlers: Record<ErrorCode, GraphQLErrorHandler> = {
  // Requested resource not found
  NOT_FOUND: genericError('Not Found'),
  // There was an error updating a resource
  NOT_UPDATED: genericError('Not Updated'),
  // Unprocessed and unhandled server error
  SERVER_ERROR: genericError('Server Error'),
  // There was an error during registration
  UNABLE_REGISTER: genericError('Unable to register'),
  // There was an error during registration
  LOCKED: genericError('Locked'),

  VERIFY_CODE_EXPIRED: genericError('Verify Code Expired'),
  VERIFY_CODE_INCORRECT: genericError('Verify Code Incorrect'),

  UNAUTHENTICATED: (handle) => {
    genericError('Unauthenticated')(handle)

    void signOut(handle.client, { preventLocationReplace: true })

    const { search } = window.location
    window.location.replace(`/${ROUTES.UNAUTHENTICATED}${search}`)
  },

  EXPIRED_SESSION: (handle) => {
    genericError('Expired Session')(handle)

    const {
      access: getPersistentStorageState,
      modify: { clearRedirect },
    } = persistentStorageInterface

    const {
      modify: { resetTokens },
    } = tokensStorageInterface

    const { redirectUrl } = getPersistentStorageState()

    clearRedirect()
    resetTokens()

    window.location.replace(redirectUrl ?? `/${ROUTES.EXPIRED_SESSION}`)
  },

  UNAUTHORIZED: (handle) => {
    genericError('Unauthorized')(handle)

    remoteErrorReceived(RemoteError.unauthorized)
  },

  UNCONFIRMED: (handle) => {
    genericError('Unconfirmed')(handle)

    remoteErrorReceived(RemoteError.unconfirmed)
  },

  DEACTIVATED: (handle) => {
    genericError('Deactivated')(handle)

    const { access: tokensStorageState } = tokensStorageInterface
    const { access } = tokensStorageState()

    if (access) {
      void signOut(handle.client, { preventLocationReplace: true })

      window.location.replace(`/${ROUTES.DEACTIVATED}`)
    }
  },

  // Unknown case
  unknown: genericError('Unknown'),
}

interface NetworkErrorContext {
  error: NonNullable<NetworkError>
  name: string
}

// TODO: move into a separate link exported from Security feature
// So that the logic is not spread across the codebase
function handleNetworkError({ error, name }: NetworkErrorContext) {
  console.warn(`[Apollo] [Network] @${name}: ${error.message}`)

  if (!('statusCode' in error)) return

  if (error.statusCode === 451) $$security.report()
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const name = operation.operationName ?? 'UnknownOperation'

    if (name.includes('IgnoreErrors')) {
      if (response) response.errors = undefined

      return
    }

    const context = operation.getContext() as ClientContext

    if (networkError) handleNetworkError({ error: networkError, name })

    if (!graphQLErrors) return

    for (const error of graphQLErrors) {
      const raw = error.extensions?.code as GraphQLErrorCode | undefined
      const code = raw && raw in errorHandlers ? raw : 'unknown'

      if (context.isSkipError && SkippableError.has(code)) continue

      const handler = errorHandlers[code]

      handler({ error, name, client: errorLink.client })
    }
  },
) as LinkWithClient

export default errorLink

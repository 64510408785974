import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { drugSearchConfigApplied } from '../model/core'

interface ButtonsProps {
  isPreconfigured: boolean
  handleApply: () => void
}

function Buttons({ isPreconfigured, handleApply }: ButtonsProps) {
  const { t } = useTranslation()
  const i18n = {
    buttonTitle: t('translation.medications.drug_search.buttons.search_prices'),
    cancel: t('translation.shared.buttons.button_cancel'),
    apply: t('translation.shared.buttons.apply'),
  }
  const navigate = useNavigate()

  const goBack = () => {
    void navigate(-1)
  }

  if (!isPreconfigured) {
    return (
      <Root>
        <SearchButton
          theme="primary"
          title={i18n.buttonTitle}
          dataTestProp="drugSearchConfiguration.searchPricesButton"
        />
      </Root>
    )
  }

  return (
    <Root>
      <CancelButton
        theme="linkBlue"
        title={i18n.cancel}
        handleClick={goBack}
        dataTestProp="drugSearchConfiguration.cancelButton"
      />
      <ApplyButton
        theme="primary"
        title={i18n.apply}
        handleClick={handleApply}
        dataTestProp="drugSearchConfiguration.applyButton"
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${convertPxToRem(32)};
  ${mediaQueryFor.mobile} {
    margin-top: auto;
  }
`
const CancelButton = styled(ButtonBottom)`
  flex-basis: 33.33%;
`
const ApplyButton = styled(ButtonBottom)`
  flex-basis: 66.66%;
`

const SearchButton = reflect({
  view: ButtonBottom,
  bind: {
    handleClick: drugSearchConfigApplied,
  },
})

export const ConfigurationButtons = reflect({
  view: Buttons,
  bind: {
    handleApply: drugSearchConfigApplied,
  },
})

import React from 'react'

import { connect, ConnectedProps } from 'react-redux'

import Spinner from 'src/components/Spinner'
import { FeatureFlag } from 'src/constants/featureFlags'
import type { RootState } from 'src/store'
import { arrify } from 'src/utils/functionalHelpers'

interface FeatureGuardOwnProps {
  permit: FeatureFlag | [FeatureFlag, ...FeatureFlag[]]
  mode?: 'some' | 'every'
}

interface RoutedFeatureGuard {
  path: string
  component: React.ReactElement
}
interface DefaultFeatureGuard {
  children: React.ReactElement
}

type FeatureGuardContents = RoutedFeatureGuard | DefaultFeatureGuard

type FeatureGuardProps = FeatureGuardOwnProps &
  ConnectedProps<typeof connector> &
  FeatureGuardContents

const isRouted = (
  props: FeatureGuardContents & { path?: string },
): props is RoutedFeatureGuard => Boolean(props.path)

function FeatureGuard({ allowed, loading, ...props }: FeatureGuardProps) {
  const children = isRouted(props) ? props.component : props.children

  if (!children)
    throw new Error(
      '[FeatureGuard]: Did not find child component. Make sure us pass children/component prop.',
    )

  if (loading) return <Spinner />
  if (allowed) return children

  return null
}

const mapStateToProps = (
  { features: { featureFlags }, common }: RootState,
  { permit, mode = 'every' }: FeatureGuardOwnProps,
) => ({
  loading: !common.isInitialDataReceived,
  allowed: arrify(permit)[mode]((flag) => featureFlags[flag]),
})

// To reduce re-renders, only watch specific parts of Redux state
const areStatesEqual = (next: RootState, prev: RootState) =>
  next.common.isInitialDataReceived === prev.common.isInitialDataReceived &&
  next.features === prev.features

const connector = connect(mapStateToProps, null, null, { areStatesEqual })

/**
 * @deprecated Use `FeatureGuard` from 'src/entities/features' instead.
 */
export default connector(FeatureGuard)

const physical = [
  /^\/appointment\/(\d+)/i,
  /^\/bill-claim\/(\d+)/i,
  /^\/care-plan\/(\d+)/i,
  /^\/home\/bill-claims\/active/i,
  /^\/find-care\/specialist-select/i,
  /^\/home\/get-care/i,
  /^\/new-bill-claim/i,
  /^\/profile\/wellness/i,
  /^\/profile\/health/i,
  /^\/home\/medications\/tabs\/cabinet/i,
  /^\/medications\/care-complete-details\/(\d+)/i,
  /^\/home\/coverage\/pharmacy-benefit-details\/mail-order-info/i,
]

const virtual = {
  'new-chat': /^\/chats\/new/i,
  'chat': /^\/chat\/(?<chatId>\d+)/i,
} as const

const resources = [...physical, ...Object.values(virtual)]

type VirtualMatch = `virtual:${keyof typeof virtual}`

export const deeplink = {
  virtual,

  is: (path: string) => resources.some((regex) => regex.test(path)),
  match: (path: string) => {
    // physical
    if (physical.some((regex) => regex.test(path))) return 'physical' as const

    // virtual
    for (const [match, regex] of Object.entries(virtual))
      if (regex.test(path)) return `virtual:${match}` as VirtualMatch

    return 'none' as const
  },
}

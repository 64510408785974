import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { FeatureFlag } from 'src/constants/featureFlags'

interface IFeaturesSlice {
  featureFlags: Partial<Record<FeatureFlag, boolean>>
}

const initialState: IFeaturesSlice = {
  featureFlags: {},
}

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatureFlags(
      state,
      { payload }: PayloadAction<Record<FeatureFlag, boolean>>,
    ) {
      state.featureFlags = payload
    },
  },
})

export const { setFeatureFlags } = featuresSlice.actions

export default featuresSlice.reducer

import { RxCardData } from '../../types'

import { RxCardBackContent } from './RxCardBackContent'
import { RxCardFrontContent } from './RxCardFrontContent'

interface RxCardControllerProps {
  card: RxCardData
  isFront?: boolean
}

export function RxCardController({
  card,
  isFront = false,
}: RxCardControllerProps) {
  if (isFront) return <RxCardFrontContent {...card} />

  return <RxCardBackContent {...card} />
}

import { gql } from '@apollo/client'

import VIEW_SETTINGS_FRAGMENT from 'src/apollo/fragments/VIEW_SETTINGS_FRAGMENT'

const VIEW_SETTINGS_QUERY = gql`
  query viewConfigQuery {
    currentUser {
      ...viewSettingsFragment
    }
  }
  ${VIEW_SETTINGS_FRAGMENT}
`

export default VIEW_SETTINGS_QUERY

import { produce } from 'immer'

export const ONLY_DIGITS_REG = /^\d+$/

export const EMPTY_SEGMENT_VALUE = '_'

export const EMPTY_CODE_VALUE = '______' // 6 underscores

const replaceEmptyValues = (val: string, newVal: string) => {
  let i = 0
  //  eslint-disable-next-line no-plusplus
  return val.replace(/_/g, () => newVal[i++] || '_')
}

export const appendToCode = ({
  newValue,
  focusedSegment,
  code,
}: {
  newValue: string
  focusedSegment: number
  code: string
}) => {
  // we need to check if there was a value to decide which symbols in target.value is the real new value
  // i.e. in case we paste 6 symbols in already filled input the target.value has 1 old symbol plus 6 new symbols
  const hasValue = code[focusedSegment] !== EMPTY_SEGMENT_VALUE

  const onlyDigitsValue = newValue.replace(/\D/g, '')
  const slicedValue = hasValue ? onlyDigitsValue.slice(1, 7) : onlyDigitsValue

  return replaceEmptyValues(
    slicedValue.length > 1 ? EMPTY_CODE_VALUE : code,
    slicedValue,
  )
}

export const removeFromCode = (codeArray: string[]) => {
  const lastDigitIndex = codeArray.findLastIndex(
    (digit: string) => digit !== EMPTY_SEGMENT_VALUE,
  )

  const next = produce(codeArray, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft[lastDigitIndex] = EMPTY_SEGMENT_VALUE
  }).join('')
  return next
}

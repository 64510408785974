import { gql, TypedDocumentNode } from '@apollo/client'

import {
  ToggleCareTeamDoctorVariables,
  ToggleCareTeamDoctorResult,
} from './types'

export const CARE_TEAM_DOCTOR_MUTATIONS: TypedDocumentNode<
  ToggleCareTeamDoctorResult,
  ToggleCareTeamDoctorVariables
> = gql`
  mutation toggleCareTeamDoctor($id: ID!, $isFavorite: Boolean!) {
    removeFromCareTeam(id: $id, type: doctor) @include(if: $isFavorite) {
      id
      care_team_doctors {
        nodes {
          id
        }
      }
    }

    addToCareTeam(id: $id, type: doctor) @skip(if: $isFavorite) {
      id
      care_team_doctors {
        nodes {
          id
        }
      }
    }
  }
`

import { createEffect, createEvent, createStore, sample } from 'effector'

import client from 'src/apollo/client'

import {
  DRUG_CONFIGURATION_QUERY,
  DrugConfiguration,
  DrugInformation,
} from './api/drugConfigurationQuery'

const reset = createEvent<void>()

const $options = createStore<DrugConfiguration[]>([]).reset(reset)

const $isDrugCovered = createStore<boolean>(false)
const $hasError = createStore<boolean>(false)

const loadOptionsFx = createEffect<{ drugName: string }, DrugInformation>()

loadOptionsFx.use(async ({ drugName }) => {
  const { data } = await client.query({
    query: DRUG_CONFIGURATION_QUERY,
    variables: { drugName },
  })

  return data?.drugConfiguration?.drug
})

sample({
  source: loadOptionsFx.doneData,
  fn: (data) => data?.configurations ?? [],
  target: $options,
})

sample({
  source: loadOptionsFx.doneData,
  fn: (data) => !data?.isAlternativesOnly,
  target: $isDrugCovered,
})

sample({
  source: loadOptionsFx.doneData,
  fn: (data) => !data,
  target: $hasError,
})

export const $$drugConfiguration = {
  $options,
  $isDrugCovered,
  $hasError,
  reset,
  loadOptionsFx,
}

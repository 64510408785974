import { useCallback } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'

import { FETCH_FIRST_CARDS_QUANTITY } from 'src/constants/graphQlConstants'

import { CARE_PLANS_SUBSCRIPTION } from '../carePlansSubscription/document'

import { CARE_PLANS_QUERY } from './document'

interface IUseCarePlansQueryArgs {
  isSkipQuery?: boolean
  isActive?: boolean
}

export const useCarePlansQuery = ({
  isSkipQuery,
  isActive,
}: IUseCarePlansQueryArgs = {}) => {
  const variables = {
    filters: { archived: !isActive },
    first: FETCH_FIRST_CARDS_QUANTITY,
  }

  const {
    data,
    loading: isLoading,
    fetchMore,
    networkStatus,
    subscribeToMore,
    refetch,
  } = useQuery(CARE_PLANS_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: isSkipQuery,
  })

  const subscribeCarePlansToEvent = useCallback(
    () =>
      subscribeToMore({
        document: CARE_PLANS_SUBSCRIPTION,
        variables: {
          archived: !isActive,
        },
        updateQuery: (currentCache) => {
          void refetch()
          return currentCache
        },
      }),
    [isActive, refetch, subscribeToMore],
  )

  return {
    data: data?.carePlans?.nodes ?? [],
    isFetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
    isFirstDataRequest: data === undefined,
    isLoading,
    fetchMore,
    pageInfo: data?.carePlans?.pageInfo ?? {
      hasNextPage: false,
      endCursor: '',
    },
    subscribeCarePlansToEvent,
  }
}

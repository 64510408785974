import { ComponentType } from 'react'

import { Effect, Event, EventCallable, Store } from 'effector'

export interface CreateModalOptions {
  view: ComponentType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeOpen: EventCallable<void> | Effect<void, any>
  openOn: Event<boolean>
}

export interface Modal {
  View: ComponentType
  open: EventCallable<void>
  closed: Event<void>
  next: EventCallable<void>
  __: {
    $state: Store<ModalState>
  }
}

export enum ModalState {
  Closed = 'closed',
  Checking = 'checking',
  Open = 'open',
}

import { useNavigate, useLocation } from '@reach/router'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { primaryLightBlue } from 'src/theme/colors'
import { getSlug } from 'src/utils/currentUserHelpers'
import { StorageContainer, useLocalStorageState } from 'src/utils/storage'

export function DeactivatedErrorAlert() {
  const t = useScopedTranslation('translation.shared')

  const navigate = useNavigate()
  const location = useLocation()

  const slug = getSlug(location)

  const { email } = useLocalStorageState(StorageContainer.persistent)

  const handleClick = () => {
    navigate(`${slug}/${ROUTES.RELOGIN}`, {
      state: { email, isEmailVerified: false },
      replace: true,
    })
  }

  const NAVIGATORS_EMAIL = 'navigators@rightwayhealthcare.com'

  const message = (
    <Trans
      t={t}
      i18nKey="errors.deactivated.description"
      values={{ email: NAVIGATORS_EMAIL }}
      components={{
        emailLink: <EmailLink href={`mailto:${NAVIGATORS_EMAIL}`} />,
      }}
    />
  )

  return (
    <NativeAlert description={message}>
      <NativeAlertAction
        mode="secondary"
        onClick={handleClick}
        data-test="DeactivatedErrorAlert.action"
      >
        {t('buttons.okay')}
      </NativeAlertAction>
    </NativeAlert>
  )
}
const EmailLink = styled.a`
  color: ${primaryLightBlue};
`

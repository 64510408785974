import { list, variant } from '@effector/reflect'
import styled from 'styled-components'

import { $drugSearchPrices } from 'src/entities/drugDetails'
import { Disclaimer } from 'src/shared/ui/Disclaimer'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function DisclaimersView() {
  return (
    <Root>
      <DisclaimersList />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${convertPxToRem(10)};

  margin: ${convertPxToRem(0, -40)};

  ${mediaQueryFor.mobile} {
    margin: ${convertPxToRem(0, -24)};
  }
`

const $disclaimers = $drugSearchPrices.map(
  (data) => data?.drugSearchPrices?.disclaimers ?? [],
)

const DisclaimersList = list({
  view: Disclaimer,
  source: $disclaimers,
})

export const Disclaimers = variant({
  if: $disclaimers.map((items) => items.length > 0),
  then: DisclaimersView,
})

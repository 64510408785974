import { Reference, TypePolicy } from '@apollo/client'

export const ProcessedMedicationsTypePolicy: TypePolicy = {
  fields: {
    hasMedications: {
      read: (_, { readField }) =>
        Boolean(
          readField<Reference[]>('past')?.length ||
            readField<Reference[]>('active')?.length ||
            readField<Reference[]>('pending')?.length,
        ),
    },
  },
}

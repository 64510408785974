import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IUserSlice {
  userId: string | null
  branding: {
    slug: string
    logoUrl: string
    logoSidebarUrl: string
    title: string
    poweredByText: string
    faviconUrl: string
    welcomeText: string
    carouselItems: [] // FIXME: provide type definition
  }
  isSequoiaUser: boolean
}

const initialState: IUserSlice = {
  userId: null,
  branding: {
    slug: '',
    logoUrl: '',
    logoSidebarUrl: '',
    title: '',
    poweredByText: '',
    faviconUrl: '',
    welcomeText: '',
    carouselItems: [],
  },
  isSequoiaUser: navigator.userAgent.indexOf('Sequoia') !== -1, // determine to see if we are accessing Rightway site through the Sequoia mobile application
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, { payload }: PayloadAction<string>) => {
      state.userId = payload
    },
    setBranding: (state, { payload }) => {
      state.branding = payload
    },
  },
})

export const { setUserId, setBranding } = userSlice.actions

export default userSlice.reducer

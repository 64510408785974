import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { $$chat } from 'src/entities/chat'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { heading8_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function ChatResolved() {
  const t = useScopedTranslation('translation.chat.thread.chat_bubble')

  return (
    <Wrapper>
      <Notice>{t('inactive_text')}</Notice>
      <StartChat title={t('new_conversation')} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: ${convertPxToRem(8)};

  padding: ${convertPxToRem(24, 12, 12, 12)};
`

const Notice = styled.span`
  ${heading8_12};

  text-align: center;
  color: ${secondaryGrey};
`

const StartChat = reflect({
  view: ButtonBottom,
  bind: {
    theme: 'primary',
    handleClick: $$chat.create,
  },
})

import React from 'react'

import useCurrentUserQuery from 'src/apollo/hooks/useCurrentUserQuery'

import { useRefreshTokenMutation } from '../api/refreshTokenMutation'
import { IterableControllerGate } from '../model/gate'

interface CurrentUserWithExternalId {
  externalId: string | null
}

export function IterableController() {
  const refresh = useRefreshTokenMutation()
  const { externalId }: CurrentUserWithExternalId = useCurrentUserQuery()

  /**
   * `userId` is known to be non-null,
   * because otherwise user would not have been logged in
   */
  if (!externalId) return null

  return <IterableControllerGate userId={externalId} refreshToken={refresh} />
}

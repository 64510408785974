/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFactory } from '@withease/factories'
import {
  createEvent,
  createStore,
  Effect,
  Event,
  EventCallable,
  sample,
} from 'effector'
import { Mutation } from 'effector-apollo'
import { equals } from 'patronum'

/**
 * Possible actions supported by a `createStep`.
 *
 * - `chain` type with arbitrary `start` and `complete` events,
 *    where `step` will command the chain to `start` and await
 *    for the chain to call `complete`.
 * - `fire`-and-forget type, which will be considered complete
 *    once it is fired.
 * - `effect` type where the {@link Effect} will be run by
 *   `step` until completion, successful or not.
 * - `mutation` type, similar to `effect`, which will be
 *   run until the mutation finishes.
 */
type StepAction =
  | { start: EventCallable<void>; complete: Event<void> }
  | { fire: EventCallable<void> }
  | { effect: Effect<void, any, any> }
  | { mutation: Mutation<any, any> }

/**
 * A step represents a collection of actions which will be
 * queued to execute _in parallel_.
 */
interface Step {
  /** A command which will launch the actions. */
  start: EventCallable<void>
  /** An event that will fire once the step completes all its actions. */
  done: Event<void>
}

function toStart(action: StepAction) {
  if ('effect' in action) return action.effect
  if ('mutation' in action) return action.mutation.start
  if ('fire' in action) return action.fire

  return action.start
}

function toComplete(action: StepAction) {
  if ('effect' in action) return action.effect.finally
  if ('mutation' in action) return action.mutation.finished.finally
  if ('fire' in action) return action.fire

  return action.complete
}

function createStepFactory(actions: StepAction[]): Step {
  const start = createEvent<void>()
  const increment = createEvent<void>()

  const $running = createStore<boolean>(false).on(start, () => true)

  const $completed = createStore<number>(0)
    .on(increment, (completed) => completed + 1)
    .reset(start)

  sample({
    clock: start,
    target: actions.map(toStart),
  })

  // below sample assumes every action completes exactly *once*
  // if that does not hold, update to `combineEvents` usage
  sample({
    clock: actions.map(toComplete),
    filter: $running,
    target: increment,
    // we must disable batching to prevent pure computations
    // erroneously triggering increment only once
    batch: false,
  })

  const done = sample({
    clock: [$completed.updates, /* if no actions exist */ start],
    filter: equals($completed, actions.length),
    fn: (): void => {},
  })

  return { start, done }
}

function chainStepFactory(steps: [Step, ...Step[]]): Step {
  const chain = steps.slice(1)
  let prev = steps.at(0)!

  for (const step of chain) {
    sample({ clock: prev.done, target: step.start })
    prev = step
  }

  return { start: steps.at(0)!.start, done: steps.at(-1)!.done }
}

/** Create a {@link Step} from a list of {@link StepAction}. */
export const createStep = createFactory(createStepFactory)

/**
 * Chain together a list of {@link Step | Steps}
 * to execute them _in sequence_.
 *
 * **Note** that `chainStep` modifies steps to run each
 * after another in order regardless of how those steps
 * are launched. Think of this like `sample` for steps.
 */
export const chainStep = createFactory(chainStepFactory)

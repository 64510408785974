import { ChangeEventHandler } from 'react'

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import { ONLY_DIGITS_REG } from 'src/sections/SectionRegistration/RegistrationElements/CodeInput/codeInputHelpers'
import { remap } from 'src/shared/lib/effector/remap'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { InlineInputError } from 'src/shared/ui/InlineInputError'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondarySoftGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $currentConfigState,
  $isCustomDaysSupplyValue,
  drugSearchConfigChanged,
} from '../model/core'
import { ConfigType, ErrorKeys, SavedConfig } from '../model/types'
import { $validationErrors } from '../model/validation'

import { Dropdown } from './Dropdown'

interface DaysSupplyProps {
  currentConfigState: SavedConfig
  isCustomDaysSupplyValue: boolean
  configChanged: ({ type, value }: { type: ConfigType; value: string }) => void
  validationError: ErrorKeys | null
}

function DaysSupplyView({
  currentConfigState,
  isCustomDaysSupplyValue,
  configChanged,
  validationError,
}: DaysSupplyProps) {
  const t = useScopedTranslation('translation.medications.drug_search')
  const i18n = {
    daysSupply: t('labels.days_supply'),
    daysSupplyPlaceholder: t('labels.select', {
      value: t('labels.days_supply').toLocaleLowerCase(),
    }),
    custom: t('labels.custom'),
    placeholder: t('labels.days'),
  }

  const daysSupplyOptions = {
    30: '30',
    60: '60',
    90: '90',
    custom: i18n.custom,
  }

  const selectionHandler = (value: string) => {
    configChanged({
      type: ConfigType.DaysSupply,
      value,
    })
  }

  const handleClear = () => {
    configChanged({
      type: ConfigType.DaysSupplyCustom,
      value: '',
    })
  }

  const quantityHandler: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (ONLY_DIGITS_REG.test(target.value) || target.value === '') {
      configChanged({
        type: ConfigType.DaysSupplyCustom,
        value: target.value,
      })
    }
  }

  return (
    <Root>
      <DaysSupplySelect
        title={i18n.daysSupply}
        value={currentConfigState.daysSupply}
        placeholder={i18n.daysSupplyPlaceholder}
        options={daysSupplyOptions}
        onSelect={selectionHandler}
        dataTestProp="dropdown.daysSupply"
        type={ConfigType.DaysSupply}
      />

      {isCustomDaysSupplyValue && (
        <QuantityValue>
          <Input
            placeholder={i18n.placeholder}
            onChange={quantityHandler}
            value={currentConfigState.daysSupplyCustom}
            data-test="daysSupply.input"
            data-has-error={Boolean(validationError)}
          />
          <ButtonClear onClick={handleClear} data-test="daysSupply.buttonClear">
            <Sprite name="misc/xmark" />
          </ButtonClear>

          {validationError && (
            <ErrorMessage dataTestProp="quantity.error">
              {t(validationError)}
            </ErrorMessage>
          )}
        </QuantityValue>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: ${convertPxToRem(16)};
`
const DaysSupplySelect = styled(Dropdown)`
  flex: 1;
`

const Input = styled.input`
  width: 100%;
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(0, 16)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  color: ${primaryDarkNavy};
  ${text1_16};

  ::placeholder {
    color: ${secondaryGrey};
  }

  :disabled {
    opacity: 1;
    background-color: ${secondarySoftGrey};
  }

  &[data-has-error='true'] {
    border-color: ${statusErrorRed};
    ::placeholder {
      color: ${primaryDarkNavy};
    }
  }
`

// ToDo: Get rid of the duplicate wrappers (NEX-17802)
const ButtonClear = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${convertPxToRem(48)};
  width: ${convertPxToRem(40)};

  cursor: pointer;

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }
`

const ErrorMessage = styled(InlineInputError)`
  position: absolute;
  padding-left: ${convertPxToRem(4)};
  bottom: ${convertPxToRem(-14)};
  left: 0;
`

const QuantityValue = styled.div`
  flex-basis: 66.66%;
  position: relative;
  ${Input}:placeholder-shown + ${ButtonClear} {
    display: none;
  }
`

export const DaysSupply = reflect({
  view: DaysSupplyView,
  bind: {
    currentConfigState: $currentConfigState,
    isCustomDaysSupplyValue: $isCustomDaysSupplyValue,
    configChanged: drugSearchConfigChanged,
    validationError: remap($validationErrors, ConfigType.DaysSupplyCustom),
  },
})

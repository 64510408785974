import { useMutation } from '@apollo/client'

import { ACCEPT_TERMS_OF_SERVICE_MUTATION } from './document'

export function useAcceptTermsOfServiceMutation() {
  const [acceptTermsOfService, { loading: isLoading }] = useMutation(
    ACCEPT_TERMS_OF_SERVICE_MUTATION,
  )

  return { acceptTermsOfService, isLoading }
}

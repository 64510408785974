import React from 'react'

import styled from 'styled-components'

import { nativeSystemSeparator } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { NativeAlert, NativeAlertProps } from './NativeAlert'

type NativeInlineAlertProps = NativeAlertProps & {
  children: [React.ReactNode, React.ReactNode]
}

export const NativeInlineAlert = styled(NativeAlert)`
  grid-template-columns: 50% 50%;
  grid-template-rows: max-content max-content;
  grid-template-areas: 'content content';

  button:first-of-type {
    border-right: ${convertPxToRem(0.5)} inset ${nativeSystemSeparator};
  }
` as React.FC<NativeInlineAlertProps>

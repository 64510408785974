import { gql } from '@apollo/client'

/**
 * This is a local schema for mocked queries, types, etc.
 *
 * When a field or query is yet to be added on the backend,
 * but is required for local development/staging, you can add it here.
 *
 * To use the schema below, set up a Cache Policy for your field/query,
 * and then use `@client` directive on local-only fields.
 *
 * Extending types {@link https://spec.graphql.org/October2021/#sec-Object-Extensions}
 * For documentation, refer to {@link https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies}.
 *
 * After the field is implemented on the backend remove it here, then
 * remove the `@client` directive from the document.
 */
export const mockTypeDefinitions = gql`
  scalar ID

  extend type MedicationProcessed {
    hasMedications: Boolean
  }
`

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface SearchBarButtonProps {
  text: string
  dataTestProp?: string
  className?: string

  onClick: () => void

  icon?: React.ReactNode
}

export function SearchBarButton({
  text,
  dataTestProp = 'searchBarButton',
  className = undefined,

  onClick,

  icon = <Sprite name="misc/search" />,
}: SearchBarButtonProps) {
  return (
    <Root onClick={onClick} data-test={dataTestProp} className={className}>
      {icon}

      {text}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(10)};

  width: 100%;
  min-height: ${convertPxToRem(40)};

  padding: ${convertPxToRem(0, 12)};

  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};

  color: ${secondaryGrey};

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};
  }

  cursor: pointer;
`

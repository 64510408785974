import { createEffect, createEvent, sample } from 'effector'

import { postpone } from 'src/shared/lib/effector/postpone'

import { trackFreshpaint } from '../lib'
import { FreshpaintEvent, FreshpaintTrackingInfo } from '../types'

import { $isFreshpaintReady } from './api'

type AnyTrackingEvent = FreshpaintTrackingInfo<FreshpaintEvent>

export const freshpaintEventTracked = createEvent<FreshpaintTrackingInfo>()
const trackFreshpaintEventFx = createEffect<AnyTrackingEvent, void>()

sample({
  clock: postpone({ clock: freshpaintEventTracked, until: $isFreshpaintReady }),
  target: trackFreshpaintEventFx,
})

trackFreshpaintEventFx.use(trackFreshpaint)

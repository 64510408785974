import { sample } from 'effector'

import { $assistanceForm, assistanceFormChanged, GetAssistance } from './core'
import {
  AssistanceFormInputType,
  AssistanceFormCheckedAnswerEvent,
} from './types'

export const assistanceRadioAnswerSelected =
  GetAssistance.event<AssistanceFormCheckedAnswerEvent>()

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.CompanyName,
  fn: (formData, { value }) => ({ ...formData, companyName: value }),
  target: $assistanceForm,
})

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.Name,
  fn: (formData, { value }) => ({ ...formData, name: value }),
  target: $assistanceForm,
})

$assistanceForm.on(assistanceRadioAnswerSelected, (state, { value, id }) => ({
  ...state,
  [id]: value,
}))

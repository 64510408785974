import React from 'react'

import PropTypes from 'prop-types'

import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export default function SwipeDownIcon({
  width = convertPxToRem(24),
  height = convertPxToRem(24),
  fill = primaryWhite,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4 11 8 5 8-5"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SwipeDownIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

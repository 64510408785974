import { TypedDocumentNode, gql } from '@apollo/client'

import { EarlyAccessQueryResult, EarlyAccessQueryVariables } from './types'

export const EARLY_ACCESS_QUERY: TypedDocumentNode<
  EarlyAccessQueryResult,
  EarlyAccessQueryVariables
> = gql`
  query earlyAccessQuery {
    currentUser {
      id
      earlyAccess: early_access {
        ...earlyAccess
      }
    }
  }

  fragment earlyAccess on UserEarlyAccess {
    rightwayProducts: rightway_products
    launchDate: launch_date
  }
`

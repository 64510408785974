import React, { MouseEventHandler } from 'react'

import styled from 'styled-components'

import NavigateToIcon from 'src/components/SvgIcons/NavigateToIcon'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface PriceModalProps {
  closeCostTooltip: MouseEventHandler<HTMLDivElement>
  children: React.ReactNode
}

export function PriceModal({ closeCostTooltip, children }: PriceModalProps) {
  const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  return (
    <Overlay data-test="priceModal.overlay" onClick={closeCostTooltip}>
      <ExpandWrapper onClick={closeCostTooltip}>
        <NavigateToIcon
          width={convertPxToRem(24)}
          height={convertPxToRem(24)}
          fill={primaryWhite}
          rotate="90"
        />
      </ExpandWrapper>
      <Modal data-test="priceModal.Modal" onClick={stopPropagation}>
        {children}
      </Modal>
    </Overlay>
  )
}

const ExpandWrapper = styled.div`
  display: none;
  justify-content: center;
  padding-bottom: ${convertPxToRem(18)};
  cursor: pointer;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`
const Modal = styled.div`
  position: relative;
  width: ${convertPxToRem(500)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(20)};
  min-height: ${convertPxToRem(240)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueryFor.mobile} {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
  }
`
const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaQueryFor.mobile} {
    justify-content: flex-end;
  }
`

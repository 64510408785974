import { FetchResult, useMutation } from '@apollo/client'

import { REFRESH_ITERABLE_TOKEN_MUTATION } from './document'
import {
  RefreshIterableTokenResult,
  RefreshIterableTokenVariables,
} from './types'

export function useRefreshTokenMutation() {
  const [refresh] = useMutation<
    RefreshIterableTokenResult,
    RefreshIterableTokenVariables
  >(REFRESH_ITERABLE_TOKEN_MUTATION)

  return refresh
}

export type RefreshTokenMutation = () => Promise<
  FetchResult<RefreshIterableTokenResult>
>

export type { RefreshIterableTokenResult }

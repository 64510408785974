import { memo } from 'react'

import styled from 'styled-components'

import { UploadFileScanStatus } from 'src/shared/api/types'
import { Sprite } from 'src/shared/ui/Sprite'
import { statusErrorRed } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { MessageBubble } from '../MessageBubble'

import { ScanStatus } from './ScanStatus'

interface InsecureAttachmentProps {
  scanStatus: UploadFileScanStatus

  isOwned: boolean
}

function InsecureAttachmentComponent({
  scanStatus,
  isOwned,
}: InsecureAttachmentProps) {
  return (
    <>
      <Bubble data-unavailable>
        <Icon name="misc/file_image" />
      </Bubble>

      <ScanStatus status={scanStatus} isOwned={isOwned} />
    </>
  )
}

const Bubble = styled(MessageBubble)`
  &[data-unavailable='true'] {
    border: ${convertPxToRem(1)} solid ${statusErrorRed};
  }
`

const Icon = styled(Sprite)`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
`

export const InsecureAttachment = memo(InsecureAttachmentComponent)

import { useCallback, useMemo } from 'react'

import { InfoBox, InfoBoxProps } from '@react-google-maps/api'
import styled from 'styled-components'

import {
  LargeMapBaseMarker,
  LargeMapMarkerComponent,
  LargeMapProvidedProps,
} from 'src/features/LargeMap'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { GeoTagIcon } from 'src/shared/ui/icons/misc/GeoTagIcon'
import {
  featurePaleYellow,
  primaryDarkNavy,
  primaryWhite,
  primaryBlue,
} from 'src/theme/colors'
import { heading8_12, textLink3_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const infoBoxOptions: InfoBoxProps['options'] = {
  boxStyle: {
    overflow: 'unset',
    width: 'auto',
  },
  pane: 'mapPane',
  closeBoxURL: '',
  enableEventPropagation: true,
  alignBottom: true,
}

interface RetailPharmacyMarker extends LargeMapBaseMarker {
  name: string
  cost: string
  isRejectedPrice: boolean
  isPreferred: boolean
}

interface RetailPharmacyMarkerProps
  extends LargeMapProvidedProps<RetailPharmacyMarker> {
  onClick: (name: string) => void
  selectedMarkerId: string
}

function RetailPharmacyMarkerComponent({
  marker,
  onClick,
  selectedMarkerId,
  map,
}: RetailPharmacyMarkerProps) {
  const t = useScopedTranslation('translation.shared.labels')

  const { name, cost, isPreferred, latitude, longitude, isRejectedPrice } =
    marker

  const position = useMemo(
    () =>
      new google.maps.LatLng({
        lat: latitude,
        lng: longitude,
      }),
    [latitude, longitude],
  )

  const isSelected = useMemo(
    () => selectedMarkerId === name,
    [name, selectedMarkerId],
  )
  const onSelectMarker = useCallback(() => {
    map.panTo(position)
    onClick(name)
  }, [map, name, onClick, position])

  return (
    <InfoBox
      zIndex={isSelected ? 2 : 1}
      position={position}
      options={infoBoxOptions}
    >
      <Root
        onClick={onSelectMarker}
        data-is-selected={isSelected}
        data-test="retailPharmacyMarker"
      >
        <GeoTagVector />
        <Content>
          <MarkerTitle>{name}</MarkerTitle>
          <Row>
            <Cost>
              {isRejectedPrice ? 'N/A' : `$${Number(cost).toFixed(2)}`}
            </Cost>

            {isPreferred && <PreferrefLabel>{t('preferred')}</PreferrefLabel>}
          </Row>
        </Content>
      </Root>
    </InfoBox>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-direction: column;
  border-radius: ${convertPxToRem(5)};
  padding: ${convertPxToRem(10)};
  background-color: ${primaryWhite};
  gap: ${convertPxToRem(5)};

  [data-is-selected='true'] & {
    background-color: ${primaryBlue};
  }
`
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(5)};
`
const MarkerTitle = styled.span`
  ${textLink3_10};
  white-space: nowrap;
  min-width: ${convertPxToRem(30)};
  color: ${primaryDarkNavy};

  [data-is-selected='true'] & {
    color: ${primaryWhite};
  }
`
const Cost = styled.span`
  ${heading8_12};
  white-space: nowrap;
  color: ${primaryBlue};

  [data-is-selected='true'] & {
    color: ${primaryWhite};
  }
`
const PreferrefLabel = styled.span`
  ${textLink3_10};
  color: ${primaryDarkNavy};
  background-color: ${featurePaleYellow};
  padding: ${convertPxToRem(4)};
  border-radius: ${convertPxToRem(5)};
`
const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${convertPxToRem(10)};
  cursor: pointer;
`
const GeoTagVector = styled(GeoTagIcon)`
  position: absolute;
  bottom: 0;

  > path {
    fill: ${primaryWhite};
  }

  [data-is-selected='true'] & > path {
    fill: ${primaryBlue};
  }
`

export const RetailPharmacyMarker =
  RetailPharmacyMarkerComponent as LargeMapMarkerComponent<RetailPharmacyMarkerProps>

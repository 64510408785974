import { useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import FeatureGuard from 'src/components/FeatureGuard'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { $$device } from 'src/shared/lib/device'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import { RootState } from 'src/store'
import { secondaryBackgroundGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { CallButton } from './CallButton'

type MobileHeaderViewProps = ConnectedProps<typeof connector>

function MobileHeaderView({
  logoUrl,
  isSequoiaUser,
  homePage,
}: MobileHeaderViewProps) {
  const navigate = useNavigate()

  const isMobile = useUnit($$device.$mobile)

  if (isSequoiaUser || !isMobile) return null

  const goToProfile = () => {
    void navigate(`/${ROUTES.PROFILE}`)
  }

  const goToHomePage = () => {
    void navigate(`/${homePage}`)
  }

  return (
    <Root data-test="mobileHeader">
      <FeatureGuard permit={FeatureFlag.SHARED_PROFILE}>
        <Profile data-test="mobileHeader.profile" onClick={goToProfile}>
          <Sprite name="services/profile" />
        </Profile>
      </FeatureGuard>

      <Logo
        data-test="mobileHeader.logo"
        onClick={goToHomePage}
        src={logoUrl}
      />

      <StyledCallButton />
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-areas: 'profile logo call';
  align-items: center;

  width: 100%;
  height: ${convertPxToRem(72)};

  padding: ${convertPxToRem(0, 24)};

  background-color: ${secondaryBackgroundGrey};
`

const Profile = styled(NavButton)`
  grid-area: profile;

  & > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`

const Logo = styled.img`
  grid-area: logo;

  height: ${convertPxToRem(30)};
`
const StyledCallButton = styled(CallButton)`
  grid-area: call;
  justify-self: end;
`

const mapStateToProps = ({ user, common }: RootState) => ({
  logoUrl: user.branding.logoSidebarUrl,
  isSequoiaUser: user.isSequoiaUser,

  homePage: common.homePage,
})

const connector = connect(mapStateToProps, null)

export const MobileHeader = connector(MobileHeaderView)

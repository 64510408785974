import { combine } from 'effector'
import { createQuery } from 'effector-apollo'
import { not } from 'patronum'

import client from 'src/apollo/client'
import { remap } from 'src/shared/lib/effector/remap'

import { DRUG_INFO_QUERY, Drug } from '../api/drugInfoQuery'

export const drugInfoQuery = createQuery({ document: DRUG_INFO_QUERY, client })

export const $isDrugCovered = not(
  remap(drugInfoQuery.$data, 'drugInfo.drug.isAlternativesOnly'),
)

export const $hasError = combine(
  drugInfoQuery.$finished,
  drugInfoQuery.$data,
  (hasFinished, data) => hasFinished && !data?.drugInfo?.drug,
)

export const $alternatives = drugInfoQuery.$data.map<Drug[]>(
  (info) => info?.drugInfo?.alternatives ?? [],
)

import { createRoute, redirect } from '@tanstack/react-router'

import { $$auth } from '@shared/auth'

import { rootRoute } from './root'

export const secureScope = createRoute({
  getParentRoute: () => rootRoute,
  id: '@secure',
  beforeLoad: ({ context: { scope } }) => {
    const authorized = scope.getState($$auth.$authenticated)
    if (!authorized) throw redirect({ to: '/' })
  },
})

export const publicScope = createRoute({
  getParentRoute: () => rootRoute,
  id: '@public',
  beforeLoad: ({ context: { scope } }) => {
    const authorized = scope.getState($$auth.$authenticated)
    if (authorized) throw redirect({ to: '/' })
  },
})

export const lockedScope = createRoute({
  getParentRoute: () => rootRoute,
  id: '@locked',
})

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ITutorialSlice {
  tutorialStep: null | number
  isCompleted: boolean
}

const initialState: ITutorialSlice = {
  tutorialStep: 0,
  isCompleted: true,
}

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    advanceTutorial: (state) => {
      state.tutorialStep = (state.tutorialStep ?? 0) + 1
    },
    resetTutorial: (state) => {
      state.tutorialStep = null
    },
    setIsCompleted: (state, action: PayloadAction<boolean>) => {
      state.isCompleted = action.payload
    },
  },
})

export const { advanceTutorial, setIsCompleted, resetTutorial } =
  tutorialSlice.actions

export default tutorialSlice.reducer

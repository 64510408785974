import { createEffect } from 'effector'

import { cache } from 'src/apollo/client'

import {
  FACILITY_CARE_PLAN_FAVORITE_FRAGMENT,
  FACILITY_FAVORITE_FRAGMENT,
} from '../api/facilityFavorite'
import { ToggleFavoriteFacilityVariables } from '../api/toggleFavoriteFacilityMutation/types'

export const toggleInFacilityUpdateFx = createEffect<
  ToggleFavoriteFacilityVariables,
  void
>(({ id, isFavorite }) => {
  cache.writeFragment({
    id: cache.identify({
      id,
      __typename: 'Facility',
    }),
    data: { id, isFavorite: !isFavorite },
    fragment: FACILITY_FAVORITE_FRAGMENT,
  })

  cache.writeFragment({
    id: cache.identify({
      id,
      __typename: 'CarePlanFacility',
    }),
    data: { id, isFavorite: !isFavorite },
    fragment: FACILITY_CARE_PLAN_FAVORITE_FRAGMENT,
  })
})

import React, { memo, useContext } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import useChatsQuery from 'src/apollo/hooks/useChatsQuery'
import useNavigatorActivitiesQuery from 'src/apollo/hooks/useNavigatorActivitiesQuery'
import assistantIcon from 'src/assets/icons/care_exp_ill.png'
import IconIndicator from 'src/components/ImgIcons/IconIndicator'
import InboxIcon from 'src/components/SvgIcons/InboxIcon'
import NewMessageIcon from 'src/components/SvgIcons/NewMessageIcon'
import { TUTORIAL_FIRST_STEP } from 'src/sections/SectionDashboard/dashboardHelpers'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import { openInbox, startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  primaryWhite,
  secondaryLightGrey,
  secondaryGrey,
  primaryDarkNavy,
  primaryBlue,
} from 'src/theme/colors'
import { textLink1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function MessageButton({
  color,
  title,
  Icon,
  hasData,
  isMargin,
  onClick,
  size = convertPxToRem(16),
  disabled,
}) {
  return (
    <MessageBtn
      hasData={hasData}
      isMargin={isMargin}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon width={size} height={size} fill={color} />
      <TextWrapper hasData={hasData}>{title}</TextWrapper>
    </MessageBtn>
  )
}

function HomeChatBox({
  hasPadding = false,
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  goToInbox = () => {},
  isFirstTutorialStep = false,
}) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.home.header.chat_message'),
    unreadChat: (count) => t('translation.home.header.unread_chats', { count }),
    unreadChats: (count) =>
      t('translation.home.header.unread_chats_plural', { count }),
    newMessage: t('translation.home.header.compose_message'),
    allMessages: t('translation.home.header.all_messages'),
  }
  const { isHideChatBox, isAnimateChatBox } = useContext(HeaderAnimationContext)

  const { newMessagesCount } = useNavigatorActivitiesQuery()

  const { inboxTotalCount } = useChatsQuery({
    policy: 'cache-only',
  })
  const hasData = !!parseInt(inboxTotalCount, 10)

  const titleChat =
    newMessagesCount === 1
      ? i18n.unreadChat(newMessagesCount)
      : i18n.unreadChats(newMessagesCount)

  const navigateToInbox = () => {
    showThirdSectionModal()
    goToInbox()
  }

  const navigateToNewMessage = () => {
    showThirdSectionModal()
    openNewChat()
  }

  const hasNewData = !!newMessagesCount

  return (
    <Root
      hasPadding={hasPadding}
      isHideChatBox={isHideChatBox}
      isAnimateChatBox={isAnimateChatBox}
    >
      <Content isCurrentTutorialStep={isFirstTutorialStep}>
        <Wrapper>
          <AssistanceImgWrapper src={assistantIcon} />
          <IndicatorWrapper>
            <IconIndicator
              width={convertPxToRem(18)}
              height={convertPxToRem(18)}
            />
          </IndicatorWrapper>
        </Wrapper>
        <ContentContainer>
          {hasData && !hasNewData && (
            <MessageButton
              onClick={navigateToInbox}
              title={i18n.allMessages}
              color={secondaryGrey}
              Icon={InboxIcon}
              isMargin
              disabled={isFirstTutorialStep}
            />
          )}
          {hasNewData && (
            <MessageButton
              onClick={navigateToInbox}
              title={titleChat}
              hasData
              Icon={InboxIcon}
              isMargin
              disabled={isFirstTutorialStep}
            />
          )}
          {!hasData && !hasNewData && <Title>{i18n.title}</Title>}
          <MessageButton
            onClick={navigateToNewMessage}
            title={i18n.newMessage}
            Icon={NewMessageIcon}
            size={convertPxToRem(13)}
            disabled={isFirstTutorialStep}
          />
        </ContentContainer>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: none;
  padding: ${({ hasPadding }) => hasPadding && convertPxToRem(0, 16)};
  margin-bottom: ${convertPxToRem(12)};
  ${mediaQueryFor.mobile} {
    display: flex;
    flex-shrink: 0;
    visibility: ${({ isHideChatBox }) =>
      isHideChatBox ? 'hidden' : 'visible'};
    opacity: ${({ isAnimateChatBox }) => (isAnimateChatBox ? 0 : 1)};
    transition: opacity 0.4s ease-in;
  }
`
const Content = styled.div`
  display: flex;
  align-items: center;
  min-height: ${convertPxToRem(92)};
  width: 100%;
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(16)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  z-index: ${({ isCurrentTutorialStep }) => (isCurrentTutorialStep ? 2 : 0)};
`
const Wrapper = styled.div`
  position: relative;
  padding: ${convertPxToRem(10)};
`

const AssistanceImgWrapper = styled.img``
const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(11)};
  right: ${convertPxToRem(11)};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${convertPxToRem(7, 12, 6, 0)};
`
const MessageBtn = styled.button`
  display: flex;
  align-items: center;
  padding: ${convertPxToRem(0, 6, 0, 12)};
  min-height: ${convertPxToRem(37)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(36)};
  border: ${({ hasData }) =>
    `${convertPxToRem(1)} solid ${hasData ? primaryBlue : secondaryLightGrey}`};
  margin-bottom: ${({ isMargin }) => isMargin && convertPxToRem(5)};
`
const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: ${convertPxToRem(5)};
  height: ${convertPxToRem(37)};
  padding: ${convertPxToRem(9, 0, 6, 14)};
  color: ${primaryDarkNavy};
  ${textLink1_16}
`
const TextWrapper = styled.div`
  padding-left: ${convertPxToRem(7)};
  text-align: start;
  color: ${({ hasData }) => (hasData ? primaryBlue : secondaryGrey)};
  ${textLink1_16}
`

HomeChatBox.propTypes = {
  showThirdSectionModal: PropTypes.func,
  openNewChat: PropTypes.func,
  goToInbox: PropTypes.func,
  isFirstTutorialStep: PropTypes.bool,
  hasPadding: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isFirstTutorialStep:
    state.tutorial.tutorialStep === TUTORIAL_FIRST_STEP &&
    !state.tutorial.isCompleted,
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
  goToInbox: () => dispatch(openInbox()),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(HomeChatBox))

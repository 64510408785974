import { pathOr } from 'ramda'

export const GOOGLE_MAP_ZOOM_MAX_VALUE = 16

export const extractCarePlanCost = (data) => ({
  copay: data?.copay ?? '',
  deductible: data?.deductible ?? '',
  insuranceCopay: data?.insurance_copay ?? '',
  insurancePays: data?.insurance_pays ?? '',
  total: data?.total ?? '',
  userPays: data?.user_pays ?? '',
})

export const extractCarePlanAssignee = ({ data, assigneeId }) => {
  const carePlanAssignees = data?.carePlan?.assignees ?? []
  const assignee =
    carePlanAssignees.filter((item) => item.id === assigneeId)[0] ?? {}

  const cost = assignee?.cost ?? {}

  return {
    carePlanCost: extractCarePlanCost(cost),
    introduction: assignee?.introduction ?? null,
    id: assignee?.id,
    tier: assignee?.tier?.name ?? '',
  }
}

export const formatDistance = (distance) => {
  const distanceToFormat = Number(distance)

  if (distanceToFormat && distanceToFormat < 2) {
    return distanceToFormat.toFixed(1)
  }

  return Math.floor(distanceToFormat).toString()
}

export const selectedTiers = (_insuranceTiers) =>
  _insuranceTiers.filter((tier) => tier.default_state).map((tier) => tier.kind)

export const checkIsGeoCoordsChanged = (geoCoords, qsFilters) => {
  const qsLat = pathOr('', ['location', 'coords', 'latitude'], qsFilters)
  const qsLong = pathOr('', ['location', 'coords', 'longitude'], qsFilters)
  const qsPlace = pathOr('', ['location', 'place'], qsFilters)
  const qeoLat = pathOr('', ['latitude'], geoCoords)
  const qeoLong = pathOr('', ['longitude'], geoCoords)

  return (
    !qsPlace && (toNumber(qsLat) !== qeoLat || toNumber(qsLong) !== qeoLong) // place priority
  )
}

export const getInitialFilters = (queryString, geoCoords) => {
  // type DoctorsFilterInputType {
  //   name: String
  //   rating: Float
  //   address: AddressInput
  //   location: LatitudeLongitudeInput
  //   distance: Int = 10000
  //   gender: DOCTOR_GENDER_TYPE
  //   language: DOCTOR_LANGUAGE_TYPE
  //   specialty: DOCTOR_SPECIALTY_GROUP
  //   request_type: DOCTOR_REQUEST_TYPE = "specialist_visit"
  //   insurance_tier: [INSURANCE_TIER_TYPE!]
  // }

  // set filters from url
  const qsFilters = queryString.parsed
  const filters = {}

  if (!qsFilters.location) {
    queryString.change(
      (q) => ({
        // set to url, ?distance=5&rating=3& ...
        ...q,
        ...{
          location: {
            coords: geoCoords,
          },
        },
      }),
      /* callback: */ () => {},
      /* replace: */ true,
    )
    // prepopulate default or geoPosition coords
    filters.location = { coords: geoCoords }
  }

  Object.keys(qsFilters).forEach((key) => {
    switch (key) {
      case 'name': {
        filters.name = qsFilters[key]
        break
      }
      case 'rating': {
        // transform string to number
        filters.rating = qsFilters[key] ? Number(qsFilters[key]) : null
        break
      }
      case 'location': {
        // transform string to number
        filters.location = {
          coords: {
            latitude: Number(qsFilters[key].coords?.latitude),
            longitude: Number(qsFilters[key].coords?.longitude),
          },
          place: qsFilters[key].place,
        }
        break
      }
      case 'distance': {
        // transform string to number
        filters.distance = qsFilters[key] ? Number(qsFilters[key]) : null
        break
      }
      case 'gender': {
        filters.gender = qsFilters[key]
        break
      }
      case 'language': {
        filters.language = qsFilters[key]
        break
      }
      case 'specialty': {
        filters.specialty = qsFilters[key]
        break
      }
      case 'request_type': {
        filters.request_type = qsFilters[key]
        break
      }
      case 'insurance_tiers': {
        filters.insurance_tiers = qsFilters[key] || []
        break
      }
      case 'category': {
        filters.category = qsFilters[key]
        break
      }
      default:
        break
    }
  })

  return filters
}

const toNumber = (value) => Number(value)

import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryBlue, primaryWhite, secondaryLightGrey } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RxCardData } from '../../types'

import { RxCardController } from './RxCardController'
import { RxCardLogo } from './RxCardLogo'

interface RxCardProps {
  card: RxCardData
  isFront?: boolean
  setIsRotated: (v: boolean) => void
}

export function RxCard({ card, isFront = false, setIsRotated }: RxCardProps) {
  const t = useScopedTranslation(
    'translation.home.coverage.pharmacy_benefits.rx_card',
  )

  return (
    <Root data-test="rxCard" data-is-front={isFront}>
      <CardWrapper>
        <LogoWithContent>
          <RxCardLogo />
          <CardContent>
            <RxCardController card={card} isFront={isFront} />
          </CardContent>
        </LogoWithContent>
        <TapForBackCTA
          data-test="rxCard.flipButton"
          onClick={() => setIsRotated(isFront)}
        >
          {isFront ? t('tap_back') : t('tap_front')}
        </TapForBackCTA>
      </CardWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;

  /**
   * translateZ is to fix safari-specific bug where
   * left side of rotated elements is not clickable
   * See https://bugs.webkit.org/show_bug.cgi?id=54371
   */

  transform: rotateY(180deg) translateZ(1px);

  &[data-is-front='true'] {
    transform: rotateY(0);
  }

  &[data-is-front='false'] {
    position: absolute;
  }

  background-color: ${primaryWhite};
  border-radius: 0.5rem;
  box-shadow: 0 2rem 1rem -1.5rem rgb(206 206 206 / 50%);
  border: 0.06rem solid ${secondaryLightGrey};
  padding: ${convertPxToRem(16, 23, 13)};
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`
const LogoWithContent = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`
const CardContent = styled.div`
  padding-top: ${convertPxToRem(16)};
`
const TapForBackCTA = styled.span`
  ${textLink2_14};
  color: ${primaryBlue};
  cursor: pointer;
  user-select: none;
`

import { useQuery } from '@apollo/client'

import { FEATURE_FLAG_IDENTITY_QUERY } from './document'

export function useFeatureFlagIdentityQuery() {
  const { data, loading: isLoading } = useQuery(FEATURE_FLAG_IDENTITY_QUERY, {
    context: { isSkipError: true },
    canonizeResults: true, // prevents re-initialization of LaunchDarkly client
  })

  return { user: data?.currentUser ?? null, isLoading }
}

import { useCallback } from 'react'

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import {
  chatCreated,
  createChatFx,
  ChatCreatedArguments,
} from 'src/features/drugSearchTicketCreation'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryBlue, secondaryGrey } from 'src/theme/colors'
import { text2_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface GenericErrorProps {
  description?: string
  onChat: (param: ChatCreatedArguments) => void
  isLoading: boolean
}

function GenericError({
  description = undefined,
  onChat,
  isLoading,
}: GenericErrorProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const openChat = useCallback(() => {
    onChat({})
  }, [onChat])

  return (
    <Root data-test="genericErrorContent.root">
      <Content>
        <ExclamationIcon
          width={convertPxToRem(24)}
          height={convertPxToRem(24)}
        />
        <Description>
          {description || t('errors.generic')}
          <ChatWithGuide
            data-test="genericError.chat"
            onClick={openChat}
            data-is-loading={isLoading}
          >
            {t('buttons.chat_with_a_pharmacy_guide')}
          </ChatWithGuide>
        </Description>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${convertPxToRem(16)};
  padding: ${convertPxToRem(97, 60)};
`
const Description = styled.span`
  color: ${secondaryGrey};
  text-align: center;
  ${text2_14};
`
const ChatWithGuide = styled.span`
  margin-left: ${convertPxToRem(3)};
  ${textLink2_14};
  color: ${primaryBlue};
  cursor: pointer;

  &[data-is-loading='true'] {
    opacity: 0.6;
    cursor: default;
  }
`

export const GenericErrorContent = reflect({
  view: GenericError,
  bind: {
    onChat: chatCreated,
    isLoading: createChatFx.pending,
  },
})

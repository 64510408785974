import React, { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { signOut } from 'src/apollo/utils/authHelpers'
import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { getSlug } from 'src/utils/currentUserHelpers'
import { useHasDeeplink } from 'src/utils/hooks/useDeeplink'

function UnauthenticatedErrorAlert() {
  const { t } = useTranslation()
  const i18n = {
    description: t('translation.home.unauthenticated_error.description'),
    buttonTitle: t('translation.home.unauthenticated_error.return_login'),
  }

  const client = useApolloClient()
  const location = useLocation()
  const slug = getSlug(location)

  const hasDeeplink = useHasDeeplink()

  const { search } = location

  const onButtonClick = () => {
    signOut(client, { preventLocationReplace: true })
    window.location.replace(`${slug}/${ROUTES.EMAIL_ENTRY}${search}`)
  }

  useEffect(() => {
    if (hasDeeplink) {
      window.location.replace(`/${slug}${search}`)
    }
  }, [hasDeeplink, search, slug])

  if (hasDeeplink) return <Spinner />

  return (
    <NativeAlert description={i18n.description}>
      <NativeAlertAction mode="secondary" onClick={onButtonClick}>
        {i18n.buttonTitle}
      </NativeAlertAction>
    </NativeAlert>
  )
}

export default UnauthenticatedErrorAlert

import { gql } from '@apollo/client'

const NAVIGATOR_ACTIVITIES_QUERY = gql`
  query navigatorActivitiesQuery {
    currentUser {
      id
      navigator_work_schedule {
        online
      }
      activities {
        chat {
          count
          ids
        }
      }
    }
  }
`

export default NAVIGATOR_ACTIVITIES_QUERY

import { TypedDocumentNode, gql } from '@apollo/client'

import { TrackingIdentityResult, TrackingIdentityVariables } from './types'

export const TRACKING_IDENTITY_QUERY: TypedDocumentNode<
  TrackingIdentityResult,
  TrackingIdentityVariables
> = gql`
  query trackingIdentity {
    user: currentUser {
      id
      serviceId: employee_external_service_id
    }
  }
`

import { useContext, UIEvent, MouseEventHandler } from 'react'

import { reflect } from '@effector/reflect'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import FeatureGuard from 'src/components/FeatureGuard'
import LinkWithArrow from 'src/components/LinkWithArrow'
import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import OptionsForCareIcon from 'src/components/SvgIcons/OptionsForCareIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { $isInEarlyAccess } from 'src/entities/earlyAccess/model'
import { isMobileDevice } from 'src/sections/SectionDashboard/dashboardHelpers'
import {
  GetCareNavigationButton,
  GetCareButtons,
} from 'src/sections/SectionHome/GetCare/getCareHelpers'
import NavButton from 'src/sections/SectionHome/GetCare/NavButton'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import HomeChatBox from 'src/sections/SectionNavigator/HomeChatBox'
import { RootState } from 'src/store'
import { BodyPadding40, ContentsRootBase } from 'src/theme/baseStyles'
import {
  secondaryLightGrey,
  primaryDarkNavy,
  secondaryGrey,
  primaryWhite,
  featurePaleBlue,
} from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type GetCareProps = ConnectedProps<typeof connector> & RouteComponentProps

function GetCare({ isSequoiaUser = false }: GetCareProps) {
  const { t } = useTranslation()
  const i18n = {
    carePlansCardTitle: t('translation.care_plans.title'),
    buttonRequestSupport: t('translation.care_plans.button_request_care_plan'),
    carePlansCardDescription: t('translation.care_plans.empty_plans.active'),
  }

  const navigate = useNavigate()

  const { setScrollTop } = useContext(HeaderAnimationContext)

  const handleLargeCard: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    navigate(`/${ROUTES.CARE_PLANS_ACTIVE_PATH}`)
  }

  const navigateTo = (path: string) => () => {
    navigate(path)
  }

  const navigateToNewCarePlan = () => {
    navigate(`/${ROUTES.CARE_PLANS_NEW_PATH}`)
  }

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    if (isMobileDevice) setScrollTop(event.currentTarget.scrollTop)
  }

  const renderNavButton = ({
    to,
    title,
    description,
    guard,
    ...props
  }: GetCareNavigationButton) => (
    <FeatureGuard permit={guard} key={title}>
      <NavButton
        onClick={navigateTo(to)}
        title={t(title)}
        description={t(description)}
        {...props}
      />
    </FeatureGuard>
  )

  return (
    <GetCareContainer onScroll={handleScroll} isSequoiaUser={isSequoiaUser}>
      <HomeChatBox />

      <FeatureGuard permit={FeatureFlag.CARE_PLANS}>
        <LargeCardContainer>
          <ProfileOption onClick={handleLargeCard} data-test="carePlansButton">
            <LinkWithArrow
              title={i18n.carePlansCardTitle}
              navigateTo={() => {}}
              icon={
                <IconSvg40
                  fill={primaryDarkNavy}
                  IconSvg={OptionsForCareIcon}
                  bgColor={featurePaleBlue}
                  border="none"
                />
              }
            />
            <Description>{i18n.carePlansCardDescription}</Description>
          </ProfileOption>

          <SubmitButtonContainer>
            <ButtonRequestCarePlan
              handleClick={navigateToNewCarePlan}
              theme="carePlan"
              title={i18n.buttonRequestSupport}
              dataTestProp="buttonRequestCarePlan"
            />
          </SubmitButtonContainer>
        </LargeCardContainer>
      </FeatureGuard>

      <NavContainer>
        <NavButtonsList>{GetCareButtons.map(renderNavButton)}</NavButtonsList>
      </NavContainer>
    </GetCareContainer>
  )
}

const ProfileOption = styled.button`
  border: none;
  padding: 0;
  width: 100%;
  background-color: ${primaryWhite};
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  cursor: pointer;
`
const GetCareContainer = styled.div<{ isSequoiaUser: boolean }>`
  ${ContentsRootBase};
  ${BodyPadding40};
  ${mediaQueryFor.tabletOrLess} {
    padding-bottom: ${convertPxToRem(200)};
  }
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(16)};
    padding-bottom: ${convertPxToRem(200)};
    padding-top: ${({ isSequoiaUser }) => !isSequoiaUser && 0};
  }
  display: block;
`
const LargeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${convertPxToRem(16)};
  padding: ${convertPxToRem(22, 16, 16)};
  background-color: ${primaryWhite};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
`
const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const NavButtonsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${convertPxToRem(8)};
  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueryFor.tabletOrLess} {
    flex-direction: row;
    padding-bottom: ${convertPxToRem(8)};
    margin-bottom: 0;
  }
  ${mediaQueryFor.mobile} {
    flex-direction: column;
  }
`
const Description = styled.div`
  display: flex;
  width: 100%;
  padding: ${convertPxToRem(15, 10, 22)};
  color: ${secondaryGrey};
  ${text2_14};
  align-items: left;
`

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${convertPxToRem(16)};
`

const ButtonRequestCarePlan = reflect({
  view: ButtonBottom,
  bind: {
    disabled: $isInEarlyAccess,
  },
})

const mapStateToProps = ({ user }: RootState) => ({
  isSequoiaUser: user.isSequoiaUser,
})

const connector = connect(mapStateToProps)
export default connector(GetCare)

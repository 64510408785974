import { useMemo } from 'react'

import { Marker } from '@react-google-maps/api'

import {
  LargeMapProvidedProps,
  LargeMapBaseMarker,
  LargeMapMarkerComponent,
} from 'src/features/LargeMap/ui/LargeMap'

type PharmacyDetailsMarker = LargeMapBaseMarker

type PharmacyDetailsMapMarkerProps =
  LargeMapProvidedProps<PharmacyDetailsMarker>

function PharmacyDetailsMapMarkerComponent({
  marker,
}: PharmacyDetailsMapMarkerProps) {
  const { id, latitude, longitude } = marker

  const position = useMemo(
    () => ({ lat: latitude, lng: longitude }),
    [latitude, longitude],
  )

  return <Marker key={id} position={position} />
}

export const PharmacyDetailsMapMarker =
  PharmacyDetailsMapMarkerComponent as LargeMapMarkerComponent<PharmacyDetailsMapMarkerProps>

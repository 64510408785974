import { resetFreshpaintFx } from './model/api'
import { freshpaintEventTracked } from './model/precision'

export const $$freshpaint = {
  track: freshpaintEventTracked,
  resetFx: resetFreshpaintFx,
}

export { FreshpaintGate as FreshpaintController } from './model/identity'
export { FreshpaintEvent } from './types'

export { /** @deprecated Use `$$freshpaint` instead */ freshpaintEventTracked }

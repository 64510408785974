import { createEvent, createStore, sample, split } from 'effector'
import { createMutation } from 'effector-apollo'
import { condition } from 'patronum'

import client from 'src/apollo/client'

import { TOGGLE_FAVORITE_PHARMACY_MUTATION } from '../api/toggleFavoritePharmacyMutation'
import { ToggleFavoritePharmacyVariables } from '../api/toggleFavoritePharmacyMutation/types'

import {
  addPharmacyErrorPopupShown,
  addPharmacyLimitErrorPopupShown,
  addPharmacySuccessPopupShown,
  removePharmacyErrorPopupShown,
  removePharmacySuccessPopupShown,
} from './popup'

export const pharmacyFavoriteToggled =
  createEvent<ToggleFavoritePharmacyVariables>()

export const toggleFavoritePharmacyMutation = createMutation({
  client,
  document: TOGGLE_FAVORITE_PHARMACY_MUTATION,
})

export const $pharmacyPendingById = createStore<string[]>([])
  .on(toggleFavoritePharmacyMutation.start, (state, { id }) => [...state, id])
  .on(toggleFavoritePharmacyMutation.finished.finally, (state, { variables }) =>
    state.filter((id) => id !== variables.id),
  )

sample({
  clock: pharmacyFavoriteToggled,
  target: toggleFavoritePharmacyMutation.start,
})

split({
  source: toggleFavoritePharmacyMutation.finished.failure,
  match: ({ variables, error }) => {
    if (variables.isFavorite) return 'remove'

    return error.graphQLErrors?.[0]?.extensions?.code === 'EXCEEDING_LIMIT'
      ? 'limit'
      : 'add'
  },
  cases: {
    limit: addPharmacyLimitErrorPopupShown,
    add: addPharmacyErrorPopupShown,
    remove: removePharmacyErrorPopupShown,
  },
})

condition({
  source: toggleFavoritePharmacyMutation.finished.success,
  if: ({ variables }) => variables.isFavorite,
  then: removePharmacySuccessPopupShown,
  else: addPharmacySuccessPopupShown,
})

import { Link } from '@reach/router'
import { useUnit } from 'effector-react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { $$device } from '@shared/lib/device'
import { useScopedTranslation } from '@shared/lib/useScopedTranslation'
import { Sprite } from '@shared/ui/Sprite'

import navigatorBillingIcon from 'src/assets/icons/navigator_icon_billing.png'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { quickLinks } from 'src/sections/SectionDashboard/dashboardHelpers'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function GetHelpTabs({
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  hasTelemedicineAccess = false,
  hasNavFeaturesAccess = false,
  hasPBMFeaturesAccess = false,
  hasBillAdvocacyAccess = false,
  hasCarePlansAccess = false,
}) {
  const t = useScopedTranslation('translation.home.tabbar.buttons')
  const tChat = useScopedTranslation('translation.chat.inbox.without_messages')

  const handleClick = () => {
    showThirdSectionModal()
    openNewChat()
  }

  const hasNavAndPbmAccess = hasPBMFeaturesAccess && hasNavFeaturesAccess

  const getGetHelpOptions = () => {
    const getHelpOptions = []

    if (hasNavAndPbmAccess) {
      if (hasCarePlansAccess) {
        getHelpOptions.push(quickLinks.carePlans)
      }

      getHelpOptions.push(quickLinks.telemed, quickLinks.pharmacy)

      if (hasBillAdvocacyAccess) {
        getHelpOptions.splice(2, 0, quickLinks.billClaims)
      }
    } else if (hasNavFeaturesAccess) {
      if (hasCarePlansAccess) {
        getHelpOptions.push(quickLinks.carePlans)
      }
      getHelpOptions.push(quickLinks.telemed)

      if (hasBillAdvocacyAccess) {
        getHelpOptions.splice(2, 0, quickLinks.billClaims)
      }
    } else if (hasPBMFeaturesAccess) {
      getHelpOptions.push(quickLinks.pharmacy, quickLinks.mailOrder)
    }
    return getHelpOptions
  }

  const isMobile = useUnit($$device.$mobile)

  return (
    <>
      {getGetHelpOptions().map((item) => {
        const isNeedHideTelemedicineRoute =
          item.to === ROUTES.TALK_WITH_DOCTOR && !hasTelemedicineAccess

        if (isNeedHideTelemedicineRoute) return null

        return (
          <QuickLink key={item.title} to={item.to}>
            <IconWrapper backgroundColor={item.bgColor}>
              <Icon name={item.icon} />
            </IconWrapper>
            <IconTitle>{t(item.title)}</IconTitle>
          </QuickLink>
        )
      })}
      {isMobile && (
        <QuickLink to={ROUTES.HOME_ACTIVITY_PATH} onClick={handleClick}>
          <img src={navigatorBillingIcon} alt="navigator avatar" />
          <IconTitle>
            {hasPBMFeaturesAccess && !hasNavFeaturesAccess
              ? t('chat_with_pharmacy')
              : tChat('button_chat')}
          </IconTitle>
        </QuickLink>
      )}
    </>
  )
}

const QuickLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: ${convertPxToRem(8)};

  background-color: ${primaryWhite};

  margin-bottom: ${convertPxToRem(12)};
  padding: ${convertPxToRem(4)};

  width: 100%;
  height: ${convertPxToRem(48)};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(38)};

  :active,
  :hover,
  :focus {
    outline: none;
  }

  :last-child {
    margin-bottom: 0;
  }
`
const IconTitle = styled.span`
  ${textLink2_14};
  color: ${secondaryGrey};
  width: 100%;
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${convertPxToRem(10)};

  border-radius: 50%;

  background-color: ${({ backgroundColor }) => backgroundColor};
`

const Icon = styled(Sprite)`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};

  color: ${primaryDarkNavy};
`

const mapStateToProps = ({ features }) => ({
  hasTelemedicineAccess: features.featureFlags[FeatureFlag.TELEMEDICINE],
  hasNavFeaturesAccess: features.featureFlags[FeatureFlag.CORE_NAV],
  hasPBMFeaturesAccess: features.featureFlags[FeatureFlag.PBM],
  hasBillAdvocacyAccess: features.featureFlags[FeatureFlag.BILL_ADVOCACY],
  hasCarePlansAccess: features.featureFlags[FeatureFlag.CARE_PLANS],
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GetHelpTabs)

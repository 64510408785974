import React from 'react'

import styled from 'styled-components'

import {
  primaryLightBlue,
  darkNavy,
  secondaryLightGrey,
  secondaryGrey,
  primaryWhite,
  secondarySoftGrey,
} from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface RadioButtonProps {
  children: React.ReactNode

  isChecked?: boolean
  onClick?: () => void

  className?: string
  dataTestProp?: string
}

export function RadioButton({
  children,

  isChecked = false,
  onClick = undefined,

  className = undefined,
  dataTestProp = 'radioButton',
}: RadioButtonProps) {
  return (
    <Root
      onClick={onClick}
      className={className}
      data-checked={isChecked}
      data-test={dataTestProp}
    >
      <Option />
      {children}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: ${convertPxToRem(16)};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  background-color: ${primaryWhite};

  padding: ${convertPxToRem(16)};
  border-radius: ${convertPxToRem(8)};

  cursor: pointer;

  ${text2_14};
  color: ${darkNavy};

  &[data-checked='true'] {
    background-color: ${secondarySoftGrey};
    border-color: ${secondarySoftGrey};
  }
`

const Option = styled.div`
  width: ${convertPxToRem(18)};
  height: ${convertPxToRem(18)};

  padding: ${convertPxToRem(2)};
  background-clip: content-box;

  border: ${convertPxToRem(1)} solid ${secondaryGrey};
  border-radius: 50%;

  flex-shrink: 0;

  [data-checked='true'] > & {
    border: ${convertPxToRem(2)} solid ${primaryLightBlue};
    background-color: ${primaryLightBlue};
  }
`

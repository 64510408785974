import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { primaryBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function InboxIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryBlue,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 19 18"
      style={{ flexShrink: 0 }}
    >
      <g fill={fill} clipPath="url(#clip0)">
        <path d="M8.791 10.614c.387.355 1.012.355 1.399 0l7.662-7.063c.154-.142.155-.373 0-.515-.06-.056-.138-.093-.223-.104-.075-.01-.15-.015-.227-.015H1.582c-.077 0-.153.005-.229.015-.216.029-.366.214-.334.413.012.078.051.15.112.206l7.66 7.063z" />
        <path d="M18.896 4.253c-.054-.022-.117-.01-.159.028L13.834 8.8c-.077.072-.077.188 0 .259l3.99 3.677c.235.21.242.556.014.773-.227.218-.603.224-.839.014l-.014-.013-3.99-3.677c-.076-.072-.202-.072-.279 0l-1.684 1.552c-.85.783-2.226.783-3.076 0L6.27 9.835c-.078-.071-.203-.071-.28 0L2 13.512c-.235.21-.61.204-.838-.014-.222-.212-.222-.548 0-.76l3.99-3.677c.076-.071.076-.186 0-.258L.246 4.283c-.041-.04-.104-.05-.158-.029-.054.02-.088.068-.089.121v8.75c0 .805.708 1.458 1.582 1.458h15.82c.874 0 1.582-.653 1.582-1.458v-8.75c0-.054-.035-.102-.088-.122z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H18.984V17.5H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InboxIcon

import { DrugPrice } from 'src/entities/drugDetails'
import { PharmacyTypeEnum } from 'src/shared/api/types'

import { MailOrderCard } from './MailOrderCard'
import { RetailPharmacyCard } from './RetailPharmacyCard'

interface PharmacySelectorProps {
  pharmacyData: DrugPrice
}

export function PharmacySelector({ pharmacyData }: PharmacySelectorProps) {
  switch (pharmacyData.pharmacy.pharmacyType) {
    case PharmacyTypeEnum.Cvs:
    case PharmacyTypeEnum.Retail:
      return <RetailPharmacyCard pharmacyData={pharmacyData} />
    case PharmacyTypeEnum.MarkCuban:
    case PharmacyTypeEnum.Welldyne:
    case PharmacyTypeEnum.Walgreens:
      return <MailOrderCard pharmacyData={pharmacyData} />
    case PharmacyTypeEnum.UnspecifiedType:
      return null
  }
}

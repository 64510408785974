import { RefObject, useEffect } from 'react'

import { useUnit } from 'effector-react'

import { $$chat } from 'src/entities/chat'

export function useConsumePreset(ref: RefObject<HTMLElement>) {
  const message = useUnit($$chat.$preset)

  const consume = useUnit($$chat.consumePreset)

  useEffect(() => {
    if (!message || !ref.current) return

    // eslint-disable-next-line no-param-reassign
    ref.current.innerText = message

    consume()
  }, [message, ref, consume])
}

import React, { memo } from 'react'

import styled from 'styled-components'

import useNavigatorActivitiesQuery from 'src/apollo/hooks/useNavigatorActivitiesQuery'
import {
  primaryWhite,
  statusActive,
  secondaryLightGrey,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface IconIndicatorProps {
  width: string
  height: string
}

function IconIndicator({ width, height }: IconIndicatorProps) {
  const { isNavigatorOnline } = useNavigatorActivitiesQuery()

  return (
    <Icon width={width} height={height} isNavigatorOnline={isNavigatorOnline} />
  )
}

export default memo(IconIndicator)

interface StyledProps {
  width: string
  height: string
  isNavigatorOnline: boolean
}

const Icon = styled.div<StyledProps>`
  width: ${({ width }) => width || convertPxToRem(12)};
  height: ${({ height }) => height || convertPxToRem(12)};
  border: solid ${convertPxToRem(2)} ${primaryWhite};
  border-radius: 50%;
  background-color: ${({ isNavigatorOnline }) =>
    isNavigatorOnline ? statusActive : secondaryLightGrey};
`

import { MouseEventHandler } from 'react'

import { ContentWithPriceData } from './ContentWithPriceData'
import { TooltipContent } from './TooltipContent'

interface ContentControllerProps {
  priceId: string
  cost: string
  closeCostTooltip: MouseEventHandler<HTMLDivElement>
  insurancePay: string | null
  additionalInfo: string
}

export function ContentController({
  priceId,
  closeCostTooltip,
  cost,
  insurancePay,
  additionalInfo,
}: ContentControllerProps) {
  if (!priceId)
    return (
      <TooltipContent
        closeCostTooltip={closeCostTooltip}
        patientPay={cost}
        insurancePay={insurancePay}
        additionalInfo={additionalInfo}
      />
    )

  return (
    <ContentWithPriceData
      priceId={priceId}
      closeCostTooltip={closeCostTooltip}
      additionalInfo={additionalInfo}
    />
  )
}

import { $isClosable, $step, stepChangedBack } from './model/core'
import {
  $editableLocationId,
  locationInitialized,
  locationSelected,
} from './model/edit'
import {
  $hasPreferredLocation,
  $locationsReady,
  $preferredLocation,
} from './model/locations'
import {
  $locations,
  $locationsFetchPending,
  $locationsReceived,
  $locationsUpdatePending,
} from './model/requests'

export { UserLocations } from './ui/UserLocations'
export { GeolocationController } from './ui/GeolocationController'

export const $$userLocations = {
  $step,
  $isClosable,
  stepChangedBack,

  $editableLocationId,
  locationInitialized,
  locationSelected,

  $preferredLocation,
  $locationsReady,
  $hasPreferredLocation,

  $locations,
  $locationsUpdatePending,
  $locationsReceived,
  $locationsFetchPending,
}

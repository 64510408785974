import {
  getInAppMessages,
  trackInAppConsume,
  trackInAppOpen,
} from '@iterable/web-sdk'
import { createEffect, createStore, sample } from 'effector'

import config from 'src/config'
import { freshpaintEventTracked } from 'src/entities/freshpaint'
import { FreshpaintEvent } from 'src/entities/freshpaint/types'
import { IterableInAppMessage } from 'src/sections/SectionHome/Activity/types'

import { mapInAppMessages } from './helpers'

export const getInAppMessagesFx = createEffect<void, IterableInAppMessage[]>()
export const dismissIterableFx = createEffect<string, void>()
const trackActivityCardReceivedFx = createEffect<IterableInAppMessage[], void>()

export const $inAppMessages = createStore<IterableInAppMessage[]>([])
  .on(getInAppMessagesFx.doneData, (_, data) => data)
  .on(dismissIterableFx, (messages, dismissedId) =>
    messages.filter(({ messageId }) => dismissedId !== messageId),
  )

sample({
  clock: dismissIterableFx,
  fn: (id) => ({
    event: FreshpaintEvent.ActivityCardDismissed,
    properties: { CardID: id },
  }),
  target: freshpaintEventTracked,
})

sample({
  source: getInAppMessagesFx.doneData,
  fn: (cards) => cards.filter(({ read }) => !read),
  target: trackActivityCardReceivedFx,
})

getInAppMessagesFx.use(async () => {
  const { data } = await getInAppMessages({
    count: 20,
    packageName: config.ITERABLE_PACKAGE,
  })

  return mapInAppMessages(data.inAppMessages)
})

dismissIterableFx.use(async (id: string) => {
  await trackInAppConsume({
    messageId: id,
    deviceInfo: {
      appPackageName: config.ITERABLE_PACKAGE,
    },
  })
})

trackActivityCardReceivedFx.use((cards) => {
  cards.forEach(({ messageId }) =>
    trackInAppOpen({
      messageId,
      deviceInfo: {
        appPackageName: config.ITERABLE_PACKAGE,
      },
    }),
  )

  cards
    .map(({ customPayload }) => ({
      event: FreshpaintEvent.ActivityCardRecieved,
      properties: { CardID: customPayload.id },
    }))
    .forEach((payload) => freshpaintEventTracked(payload))
})

import { useContext } from 'react'

import { useChatDetailsFragment } from '../../api/chatDetails'
import { ThreadIdContext } from '../../lib/ThreadIdContext'

import { ChatReply } from './ChatReply'
import { ChatResolved } from './ChatResolved'

export function ChatActions() {
  const id = useContext(ThreadIdContext)!
  const chat = useChatDetailsFragment(id)

  /**
   * New chats (with `id === NEW_THREAD_ID`) are not yet created,
   * fragment will be `null`. So, they can never show ChatResolved
   */
  if (chat?.isResolved) return <ChatResolved />

  return <ChatReply />
}

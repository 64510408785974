import { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react'

import styled from 'styled-components'

import {
  FILE_SIZE_LIMIT_BYTES,
  UPLOAD_TYPES,
} from 'src/constants/uploadConstants'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { InvalidAttachmentAlert } from './AttachmentAlert/InvalidAttachmentAlert'
import { LargeAttachmentAlert } from './AttachmentAlert/LargeAttachmentAlert'

interface ReplyAttachmentProps {
  submit: (payload: { file: File }) => void
}

type AttachmentError = null | 'size' | 'type'

/**
 * On desktop, this will directly pop up a file picker,
 * without an option to capture a photo.
 *
 * On mobile, both iOS and Android show a native pop-up
 * allowing to choose file source: camera, gallery or files
 */
export function ReplyAttachment({ submit }: ReplyAttachmentProps) {
  const [error, setError] = useState<AttachmentError>(null)

  const ref = useRef<HTMLInputElement>(null)
  const attach = () => ref.current?.click()

  const onSubmit = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      const file = target.files?.[0]
      if (!file) return

      // Reset file input to allow uploading the same file twice
      if (ref.current) ref.current.value = ''

      if (file.size >= FILE_SIZE_LIMIT_BYTES) return setError('size')
      if (!acceptType.includes(file.type)) return setError('type')

      submit({ file })
    },
    [submit],
  )

  const dismissAlert = useCallback(() => setError(null), [])

  const Alert = ErrorComponentMap.get(error)

  return (
    <>
      <FileInput
        type="file"
        data-test="replyAttachment.input"
        accept={acceptType}
        ref={ref}
        onChange={onSubmit}
      />

      <Attachment data-test="replyAttachment.button" onClick={attach} />

      {Alert && <Alert close={dismissAlert} />}
    </>
  )
}

const acceptType = Object.values(UPLOAD_TYPES).join(',')

const ErrorComponentMap = new Map<AttachmentError, FC<{ close: () => void }>>()
  .set('size', LargeAttachmentAlert)
  .set('type', InvalidAttachmentAlert)

const Attachment = styled(Sprite).attrs({ name: 'misc/attachment' })`
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};

  flex: 0 0 ${convertPxToRem(24)};

  /* increase clickable area */
  padding: ${convertPxToRem(8)};
  margin: ${convertPxToRem(-8)};

  color: ${primaryBlue};

  cursor: pointer;
`

const FileInput = styled.input`
  display: none;
`

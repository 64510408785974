import { createDomain } from 'effector'

type ActivityCardType = 'custom' | 'ordinary' | 'iterable'

interface CardDismissionRequest {
  id: string
  cardType: ActivityCardType
}

export const activityFeed = createDomain('activityFeed')

export const cardDismissionAsked = activityFeed.event<CardDismissionRequest>()
export const cardDismissionReset = activityFeed.event<void>()
export const cardDismissionConfirmed = activityFeed.event<void>()

export const $dismissingCardId = activityFeed.store<string>('')
export const $dismissingCardType =
  activityFeed.store<ActivityCardType>('ordinary')

export const $hasDismissTarget = $dismissingCardId.map((id) => id !== '')

$dismissingCardId
  .on(cardDismissionAsked, (_, { id }) => id)
  .reset(cardDismissionReset)

$dismissingCardType
  .on(cardDismissionAsked, (_, { cardType }) => cardType)
  .reset(cardDismissionReset)

import { TypedDocumentNode, gql } from '@apollo/client'

import { ChatDetails } from './types'

export const CHAT_DETAILS_FRAGMENT: TypedDocumentNode<
  ChatDetails,
  Record<string, never>
> = gql`
  fragment chatDetails on Chat {
    id
    subject

    createdAt: created_at

    isUnread: is_unread
    isResolved: is_resolved
  }
`

import { useEffect } from 'react'

import useBillClaimsFiledQuery from 'src/apollo/hooks/useBillClaimsFiledQuery'
import useBillClaimsResolvedQuery from 'src/apollo/hooks/useBillClaimsResolvedQuery'
import useChatsQuery from 'src/apollo/hooks/useChatsQuery'
import useCurrentUserIgnoreErrorsQuery from 'src/apollo/hooks/useCurrentUserIgnoreErrorsQuery'
import useNavigatorActivitiesQuery from 'src/apollo/hooks/useNavigatorActivitiesQuery'
import { useFeedItemsQuery } from 'src/entities/feedItems'
import { useCarePlansQuery } from 'src/sections/SectionCarePlans/CarePlans/api/carePlansQuery'
import usePageVisibility from 'src/utils/hooks/api/usePageVisibility'

function SubscriptionHandler() {
  const { userData } = useCurrentUserIgnoreErrorsQuery()

  const hasUser = !!userData.id

  return hasUser ? <CurrentUserSubscriptions /> : null
}

function CurrentUserSubscriptions() {
  const isPageVisible = usePageVisibility()

  const isSkipQuery = true
  const { subscribeToFeedItems } = useFeedItemsQuery()

  const { subscribeBillClaimsFiledtoEvent } =
    useBillClaimsFiledQuery(isSkipQuery)

  const {
    subscribeBillClaimsResolvedtoEvent,
    subscribeBillClaimsTotalSavings,
  } = useBillClaimsResolvedQuery(isSkipQuery)

  const { subscribeCarePlansToEvent: subscribeCarePlansActiveToEvent } =
    useCarePlansQuery({
      isSkipQuery,
      isActive: true,
    })

  const { subscribeCarePlansToEvent: subscribeCarePlansPastToEvent } =
    useCarePlansQuery({
      isSkipQuery,
      isActive: false,
    })

  // TODO: Remove the following subscriptions once Chat migration is complete
  const {
    subscribeChatsActivityToEvent,
    refetchIfNotLoading: refetchNavigatorActivities,
  } = useNavigatorActivitiesQuery()

  const {
    subscribeInboxToEvent: subscribeChatsMessagesEvent,
    refetch: refetchInboxData,
  } = useChatsQuery({ policy: 'cache-only' })

  useEffect(() => {
    const unsubscribeTodo = subscribeToFeedItems()
    const unsubscribeBillClaimFiled = subscribeBillClaimsFiledtoEvent()
    const unsubscribeBillClaimResolved = subscribeBillClaimsResolvedtoEvent()
    const unsubscribeBillClaimTotalSavings = subscribeBillClaimsTotalSavings()
    const unsubscribeCarePlansActive = subscribeCarePlansActiveToEvent()
    const unsubscribeCarePlansPast = subscribeCarePlansPastToEvent()
    const unsubscribeChatsActivityToEvent = subscribeChatsActivityToEvent()
    const unsubscribeChatsMessagesEvent = subscribeChatsMessagesEvent()
    return () => {
      unsubscribeTodo()
      unsubscribeBillClaimFiled()
      unsubscribeBillClaimResolved()
      unsubscribeBillClaimTotalSavings()
      unsubscribeCarePlansActive()
      unsubscribeCarePlansPast()
      unsubscribeChatsActivityToEvent()
      unsubscribeChatsMessagesEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPageVisible) {
      void refetchInboxData()
      refetchNavigatorActivities()
    }
    // FIXME: if all deps are included, triggers infinite refetch of chat and navigatorActivities queries
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageVisible])

  return null
}

export default SubscriptionHandler

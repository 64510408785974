import React from 'react'

import styled from 'styled-components'

import {
  primaryDarkNavy,
  featurePaleBlue,
  primaryWhite,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading7_14, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface CarePlanCardProps {
  icon: NonNullable<React.ReactElement>

  title: string
  subtitle: string

  onClick?: () => void
}

export function CarePlanCard({
  icon,

  title,
  subtitle,

  onClick = undefined,
}: CarePlanCardProps) {
  return (
    <Card onClick={onClick}>
      <IconBubble>{icon}</IconBubble>

      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Card>
  )
}

const Title = styled.span`
  ${heading7_14};

  grid-row: 1 / 2;
  grid-column: 2 / 3;
`

const Subtitle = styled.span`
  ${text3_12};

  grid-row: 2 / 3;
  grid-column: 2 / 3;
`

const IconBubble = styled.div`
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  background-color: ${featurePaleBlue};
  border-radius: 50%;

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  padding: ${convertPxToRem(10)};

  > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`

const Card = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: ${convertPxToRem(20, 19)};

  row-gap: ${convertPxToRem(1)};
  column-gap: ${convertPxToRem(18)};

  border: solid 1px ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};

  background-color: ${primaryWhite};
  color: ${primaryDarkNavy};

  padding: ${convertPxToRem(16, 21)};

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`

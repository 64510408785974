import { reflect, variant } from '@effector/reflect'
import { Store } from 'effector'

import { $$chat } from 'src/entities/chat'
import { ChatsInbox } from 'src/widgets/ChatsInbox'
import { ChatThread } from 'src/widgets/ChatThread'

import { useChatsSubscription } from '../api/chatsSubscription'
import { $chatView } from '../model/view'
import { ChatSectionView } from '../types'

export function ThirdSectionChats() {
  useChatsSubscription()

  return <Content />
}

const ChatView = reflect({
  view: ChatThread,
  bind: { id: $$chat.$id as Store<string> },
})

const Content = variant({
  source: $chatView,
  cases: {
    [ChatSectionView.Inbox]: ChatsInbox,
    [ChatSectionView.Chat]: ChatView,
  },
})

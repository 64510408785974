import { TypedDocumentNode, gql } from '@apollo/client'

import { DestroyLocationResult, DestroyLocationVariables } from './types'

export const DESTROY_LOCATION_MUTATION: TypedDocumentNode<
  DestroyLocationResult,
  DestroyLocationVariables
> = gql`
  mutation destroyLocation($id: ID!) {
    destroyLocation(id: $id) {
      id
    }
  }
`

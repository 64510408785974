import { featurePaleYellow, secondarySoftGrey } from 'src/theme/colors'

const LABEL_COLOR_REFILL_STATUS = {
  ready_to_refill: featurePaleYellow,
  no_refills: secondarySoftGrey,
  too_early: secondarySoftGrey,
  pending: featurePaleYellow,
  approved: featurePaleYellow,
  denied: featurePaleYellow,
  dismissed: featurePaleYellow,
  withdrawn: featurePaleYellow,
  partial_approval: featurePaleYellow,
}

export const getRefillColor = (refStatus, reqStatus) => {
  if (refStatus) {
    return LABEL_COLOR_REFILL_STATUS[refStatus]
  }
  return LABEL_COLOR_REFILL_STATUS[reqStatus]
}

export const extractMedicationPriceBreakdownData = (data) => ({
  patientPayType: data?.medicationPriceBreakdown?.patient_pay_type ?? '',
  patientPayAmount: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.patient_pay_amount,
  ),
  ingredientCost: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.ingredient_cost,
  ),
  planPayAmount: parseNumberToFixedFloat(
    data?.medicationPriceBreakdown?.plan_pay_amount,
  ),
  planName: data?.medicationPriceBreakdown?.plan_name ?? '',
})

const parseNumberToFixedFloat = (value) => {
  const parsedValue = parseFloat(value)
  if (!!parsedValue && !Number.isNaN(parsedValue)) {
    return parsedValue.toFixed(2)
  }
  return '0'
}

export const UNSPECIFIED_ENUM_VALUE = 'unspecified'

import { MockedResponse } from '@apollo/client/testing'

import { MockPreferredPharmacy } from 'src/entities/pharmacy/api/preferredPharmacyQuery/mocks'
import { ApolloMock } from 'src/shared/api/mocks'

import { DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY } from './document'
import { PharmaciesResult, PharmaciesVariables } from './types'

export const pharmaciesMock = [
  { ...MockPreferredPharmacy, name: 'pharmacy_1' },
  { ...MockPreferredPharmacy, name: 'pharmacy_2' },
  { ...MockPreferredPharmacy, name: 'pharmacy_3' },
  { ...MockPreferredPharmacy, name: 'pharmacy_4' },
  { ...MockPreferredPharmacy, name: 'pharmacy_5' },
  { ...MockPreferredPharmacy, name: 'pharmacy_6' },
  { ...MockPreferredPharmacy, name: 'pharmacy_7' },
]

const withPharmacies: ApolloMock<PharmaciesResult, PharmaciesVariables> = {
  request: {
    query: DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY,
    variables: { latitude: 1.0, longitude: 1.0 },
  },
  result: {
    data: {
      pharmacies: pharmaciesMock,
    },
  },
}
const nothing: MockedResponse<Record<string, unknown>, PharmaciesVariables> = {
  request: {
    query: DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY,
    variables: { latitude: 1.0, longitude: 1.0 },
  },
  result: { data: { pharmacies: [] } },
}
export const MockPreferredPharmaciesQuery = {
  withPharmacies,
  nothing,
}

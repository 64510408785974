import { TypedDocumentNode, gql } from '@apollo/client'

import {
  FeatureFlagIdentityResult,
  FeatureFlagIdentityVariables,
} from './types'

export const FEATURE_FLAG_IDENTITY_QUERY: TypedDocumentNode<
  FeatureFlagIdentityResult,
  FeatureFlagIdentityVariables
> = gql`
  query featureFlagIdentity {
    currentUser {
      id
      employeeId: employee_id

      organization {
        id
        name
      }
    }
  }
`

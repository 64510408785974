import React from 'react'

interface BillSupportIconProps {
  className?: string
}

export function BillSupportIcon({
  className = undefined,
}: BillSupportIconProps) {
  return (
    <svg
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#a)">
        <path d="M15.11 13.417a3.992 3.992 0 00-2.522-3.725l-4.25-1.7A1.512 1.512 0 018.9 5.076h4.186a1.25 1.25 0 100-2.5h-1.628a.208.208 0 01-.208-.209V1.25a1.25 1.25 0 00-2.5 0v1.143a.208.208 0 01-.19.208 4 4 0 00-1.143 7.71l4.25 1.699a1.513 1.513 0 01-.563 2.917H6.912a1.25 1.25 0 100 2.5h1.63c.115 0 .208.093.208.208v1.115a1.25 1.25 0 102.5 0v-1.142c0-.109.082-.2.19-.209a4.008 4.008 0 003.67-3.982z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h20v20H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}

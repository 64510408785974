import { useMatch } from '@reach/router'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { useScopedTranslation } from '@shared/lib/useScopedTranslation'

import { ROUTES } from 'src/constants/routesConstants'
import { $$device } from 'src/shared/lib/device'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  secondaryBackgroundGrey,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text4_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const rightway = 'https://rightwayhealthcare.com'

export function PrivacyAndTerms() {
  const t = useScopedTranslation('translation.home.tabbar')

  const isDesktop = useUnit($$device.$desktop)

  const isActivity = useMatch(ROUTES.HOME_ACTIVITY) !== null

  if (isDesktop || !isActivity) return null

  return (
    <Root data-test="privacyAndTerms">
      <Link
        href={`${rightway}/termsservices/`}
        target="_blank"
        rel="noreferrer"
      >
        {t('privacy_title')}
      </Link>

      <Link
        href={`${rightway}/privacypolicy/`}
        target="_blank"
        rel="noreferrer"
      >
        {t('privacy_policy')}
      </Link>

      <PoweredBy>
        {t('powered_by')}
        <Logo />
      </PoweredBy>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  background-color: ${secondaryBackgroundGrey};
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`

const Link = styled.a`
  ${text4_10};
  color: ${secondaryGrey};
  text-transform: uppercase;
  text-decoration: underline;
  padding: ${convertPxToRem(16, 8)};
`

const PoweredBy = styled.span`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  gap: ${convertPxToRem(4)};
  padding: ${convertPxToRem(16, 8)};

  ${text4_10};
  color: ${secondaryGrey};
  text-transform: uppercase;

  @media (max-width: 346px) {
    flex-grow: 0;
  }
`

const Logo = styled(Sprite).attrs({ name: 'logo/rightway_text' })`
  height: ${convertPxToRem(14)};
`

import { useSubscription } from '@apollo/client'

import { APPOINTMENT_SUBSCRIPTION } from './document'
import {
  AppointmentsSubscriptionResult,
  AppointmentsSubscriptionVariables,
} from './types'

export function useAppointmentSubscription(id: string) {
  useSubscription<
    AppointmentsSubscriptionResult,
    AppointmentsSubscriptionVariables
  >(APPOINTMENT_SUBSCRIPTION, { variables: { id } })
}

import { ConfigurationButtons } from './ConfigurationButtons'
import { DropdownsList } from './DropdownsList'

interface ConfigurationContentProps {
  isPreconfigured: boolean
}

export function ConfigurationContent({
  isPreconfigured,
}: ConfigurationContentProps) {
  return (
    <>
      <DropdownsList />
      <ConfigurationButtons isPreconfigured={isPreconfigured} />
    </>
  )
}

import { useUnit } from 'effector-react'

import { LocationKindType } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { HomeIcon } from 'src/shared/ui/icons/misc/HomeIcon'
import { LocationCrossIcon } from 'src/shared/ui/icons/misc/LocationCrossIcon'
import { WorkIcon } from 'src/shared/ui/icons/misc/WorkIcon'

import { UserLocation } from '../../api/location'
import { locationSelected } from '../../model/edit'
import { $preferredLocationId, locationPreferred } from '../../model/locations'

import { LocationCard } from './LocationCard'

interface SavedLocationCardProps {
  location: UserLocation
}

const IconMap = {
  [LocationKindType.HomeAddress]: <HomeIcon />,
  [LocationKindType.WorkAddress]: <WorkIcon />,
  [LocationKindType.OtherAddress]: <LocationCrossIcon />,
}

export function SavedLocationCard({ location }: SavedLocationCardProps) {
  const tShared = useScopedTranslation('translation.shared.buttons')
  const t = useScopedTranslation('translation.profile.preferred_locations')
  const i18n = {
    [LocationKindType.HomeAddress]: t('home_address'),
    [LocationKindType.WorkAddress]: t('work_address'),
    [LocationKindType.OtherAddress]: location.name ?? t('other_address'),
  }

  const preferredId = useUnit($preferredLocationId)
  const [selectLocation, preferLocation] = useUnit([
    locationSelected,
    locationPreferred,
  ])

  const onEdit = () => {
    selectLocation(location.id)
  }

  const onSelect = () => {
    preferLocation(location.id)
  }

  return (
    <LocationCard
      title={i18n[location.kind]}
      subtitle={location.address}
      label={tShared('edit')}
      icon={IconMap[location.kind]}
      onLabelClick={onEdit}
      onClick={onSelect}
      isSelected={location.id === preferredId}
    />
  )
}

import { variant } from '@effector/reflect'

import { $drugSearchInput } from '../../model/search'

import { RecentSearches } from './RecentSearches'
import { SearchResults } from './SearchResults'

const $hasDrugSearchInput = $drugSearchInput.map((value) => value.length >= 3)

export const Content = variant({
  if: $hasDrugSearchInput,
  then: SearchResults,
  else: RecentSearches,
})

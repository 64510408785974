import FeatureGuard from 'src/components/FeatureGuard'
import { FeatureFlag } from 'src/constants/featureFlags'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleBlue,
  featurePaleGreen,
  featurePaleRed,
  featurePaleYellow,
} from 'src/theme/colors'

import { PresetItem } from './PresetItem'

export function UnityPresets() {
  const t = useScopedTranslation('translation.chat.thread.answers')

  return (
    <>
      <FeatureGuard permit={FeatureFlag.CARE_PLANS}>
        <PresetItem
          icon={<Sprite name="legacy/options_for_care" />}
          color={featurePaleBlue}
        >
          {t('health_issue')}
        </PresetItem>
      </FeatureGuard>

      <FeatureGuard permit={FeatureFlag.BILL_ADVOCACY}>
        <PresetItem
          icon={<Sprite name="legacy/billing_advocacy" />}
          color={featurePaleGreen}
        >
          {t('overcharged')}
        </PresetItem>
      </FeatureGuard>

      <FeatureGuard permit={FeatureFlag.SHARED_INSURANCE_BENEFITS}>
        <PresetItem
          icon={<Sprite name="misc/health_profile" />}
          color={featurePaleRed}
        >
          {t('plan_benefits')}
        </PresetItem>
      </FeatureGuard>

      <PresetItem icon={<Sprite name="misc/pill" />} color={featurePaleYellow}>
        {t('med_cost')}
      </PresetItem>
    </>
  )
}

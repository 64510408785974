import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import CurrentCard from 'src/sections/SectionHome/Activity/CurrentCard'
import InAppCard from 'src/sections/SectionHome/Activity/InAppCard'
import {
  $$activityFeedErrorModal,
  cardDismissionAsked,
} from 'src/sections/SectionHome/Activity/model'
import {
  ActivityCardItem,
  FeedItemType,
  IterableInAppMessage,
} from 'src/sections/SectionHome/Activity/types'

interface FeedItemProps {
  item: {
    card: IterableInAppMessage | ActivityCardItem
    id: string
    type: FeedItemType
    createdAt: number
  }
}

function FeedItem({ item }: FeedItemProps) {
  switch (item.type) {
    case FeedItemType.InApp: {
      const { customPayload, messageId } = item.card as IterableInAppMessage
      return (
        <CardCategoryWrapper>
          <BoundIterableCard
            inAppMessagePayload={customPayload}
            messageId={messageId}
          />
        </CardCategoryWrapper>
      )
    }
    case FeedItemType.Todo:
      return (
        <CardCategoryWrapper>
          <BoundCurrentCard item={item.card as ActivityCardItem} />
        </CardCategoryWrapper>
      )
    default:
      return null
  }
}

const BoundCurrentCard = reflect({
  view: CurrentCard,
  bind: {
    setErrorMessage: $$activityFeedErrorModal.setError,
    setDismissItemId: cardDismissionAsked.prepend((id) => ({
      id,
      cardType: 'ordinary',
    })),
  },
})

const BoundIterableCard = reflect({
  view: InAppCard,
  bind: {
    setErrorMessage: $$activityFeedErrorModal.setError,
    setDismissItemType: cardDismissionAsked.prepend((id) => ({
      id,
      cardType: 'iterable',
    })),
  },
})

const CardCategoryWrapper = styled.li``

export default FeedItem

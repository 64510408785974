import { TFunction } from 'i18next'
import moment from 'moment'

import { Appointment } from '../api/appointmentQuery/types'
import { AsapTime, SpecificTime, TimeBlock } from '../api/timeSlots/types'

type ConvertableSlot = AsapTime | SpecificTime | TimeBlock

function preciseToMeridiem(precise: string): string {
  return moment(precise, ['HH:mm:ss']).format('h:mma')
}

export function convertSlot(t: TFunction, slot: ConvertableSlot): string {
  switch (slot.__typename) {
    case 'AsSoonAsPossible':
      return convertAsap(t, slot)

    case 'SpecificTime':
      return convertSpecific(t, slot)

    case 'TimeBlock':
      return convertTimeBlock(t, slot)
  }
}

function convertAsap(
  t: TFunction,
  { isAmPreferred, isPmPreferred }: AsapTime,
): string {
  // `|| !opposite` is nesseccary to display both AM and PM when *none* of the checkboxes were selected.
  const partsOfDay = [
    { value: 'AM', enabled: isAmPreferred || !isPmPreferred },
    { value: 'PM', enabled: isPmPreferred || !isAmPreferred },
  ]

  const values = {
    preferred: partsOfDay
      .filter(({ enabled }) => enabled)
      .map(({ value }) => value),
  }

  return t('asap', values)
}

function convertSpecific(
  t: TFunction,
  { date, startTime, endTime }: SpecificTime,
): string {
  const values = {
    start: preciseToMeridiem(startTime),
    end: preciseToMeridiem(endTime),

    day: moment(date).format('dddd, MMMM Do'),
  }

  return t('specific', values)
}

function convertTimeBlock(
  t: TFunction,
  { startTime, endTime, days }: TimeBlock,
): string {
  const values = {
    start: preciseToMeridiem(startTime),
    end: preciseToMeridiem(endTime),

    days: days
      .filter(({ enabled }) => enabled)
      .map(({ displayName }) => displayName),
  }

  return t('time_block', values)
}

interface SingleTime {
  date: string | Date
  startTime: string
}

export function convertSingleTime({ date, startTime }: SingleTime) {
  return {
    date: moment(date).format('dddd, MMMM Do'),
    time: preciseToMeridiem(startTime),
  }
}

export function pickSuggestedTimeSlot(timeSlots: Appointment['timeSlots']) {
  return (
    timeSlots.find(({ __typename }) => __typename === 'SuggestedTime') ?? null
  )
}

export function pickConfirmedTimeSlot(timeSlots: Appointment['timeSlots']) {
  return (
    timeSlots.find(({ __typename }) => __typename === 'ConfirmedTime') ?? null
  )
}

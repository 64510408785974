import { gql, TypedDocumentNode } from '@apollo/client'

import {
  ToggleFavoritePharmacyResult,
  ToggleFavoritePharmacyVariables,
} from './types'

export const TOGGLE_FAVORITE_PHARMACY_MUTATION: TypedDocumentNode<
  ToggleFavoritePharmacyResult,
  ToggleFavoritePharmacyVariables
> = gql`
  mutation toggleFavoritePharmacy($id: String!, $isFavorite: Boolean!) {
    remove: unfavoritePharmacy(nabp: $id) @include(if: $isFavorite) {
      isFavorite
      nabp
    }

    add: favoritePharmacy(nabp: $id) @skip(if: $isFavorite) {
      isFavorite
      nabp
    }
  }
`

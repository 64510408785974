import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { pathOr } from 'ramda'

import CURRENT_USER_IGNORE_ERRORS_QUERY from 'src/apollo/queries/CURRENT_USER_IGNORE_ERRORS_QUERY'

const useCurrentUserIgnoreErrorsQuery = () => {
  const { data, loading, error } = useQuery(CURRENT_USER_IGNORE_ERRORS_QUERY, {
    fetchPolicy: 'network-only',
  })
  const userData = useMemo(() => pathOr({}, ['currentUser'], data), [data])
  return { userData, loading, error }
}

export default useCurrentUserIgnoreErrorsQuery

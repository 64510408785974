import React, { useMemo } from 'react'

import { Marker } from '@react-google-maps/api'

import {
  LargeMapProvidedProps,
  LargeMapBaseMarker,
  LargeMapMarkerComponent,
} from 'src/features/LargeMap/ui/LargeMap'

type AppointmentMarker = LargeMapBaseMarker

type AppointmentMapMarkerProps = LargeMapProvidedProps<AppointmentMarker>

function AppointmentMapMarkerComponent({ marker }: AppointmentMapMarkerProps) {
  const { id, latitude, longitude } = marker

  const position = useMemo(
    () => ({ lat: latitude, lng: longitude }),
    [latitude, longitude],
  )

  return <Marker key={id} position={position} />
}

export const AppointmentMapMarker =
  AppointmentMapMarkerComponent as LargeMapMarkerComponent<AppointmentMapMarkerProps>

import { attach, sample, split } from 'effector'

import { GraphQLErrorCode } from 'src/constants/errorCodeConstants'
import { ROUTES } from 'src/constants/routesConstants'
import { $$navigate, NavigateParams } from 'src/entities/navigate'
import { GetAssistanceScreenType } from 'src/sections/SectionRegistration/pages/GetAssistance/model'
import { getGraphQlError } from 'src/utils/errorHelpers'

import { EligibilityCheckGate, checkEligibilityFx } from './core'

const navigateToFailureFx = attach({
  effect: $$navigate.navigateFx,
  source: EligibilityCheckGate.state,
  mapParams({ to, state = {}, withQuery }: NavigateParams, { email }) {
    return { to, withQuery, replace: true, state: { email, ...state } }
  },
})

// FIXME: see https://github.com/effector/effector/issues/999
const cases = {
  [GraphQLErrorCode.DEACTIVATED]: attach({
    effect: navigateToFailureFx,
    mapParams: ({ requestId: requestIdentifier }) => ({
      to: ROUTES.GET_ASSISTANCE,
      state: {
        screenType: GetAssistanceScreenType.Deactivated,
        requestIdentifier,
      },
    }),
  }),

  [GraphQLErrorCode.LOCKED]: attach({
    effect: navigateToFailureFx,
    mapParams: ({ requestId: requestIdentifier }) => ({
      to: ROUTES.GET_ASSISTANCE,
      state: {
        screenType: GetAssistanceScreenType.Locked,
        requestIdentifier,
      },
    }),
  }),

  [GraphQLErrorCode.NOT_FOUND]: attach({
    effect: navigateToFailureFx,
    mapParams: ({ requestId: requestIdentifier }) => ({
      to: `/${ROUTES.SIGN_UP}`,
      state: { source: 'failed-quick', requestIdentifier },
      withQuery: true,
    }),
  }),

  __: attach({
    effect: navigateToFailureFx,
    mapParams: () => ({ to: ROUTES.EMAIL_ENTRY }),
  }),
}

split({
  source: sample({
    clock: checkEligibilityFx.failData,
    fn: (err) => getGraphQlError(err).graphQLErrors,
  }),
  match: ({ code }) => code,
  cases,
})

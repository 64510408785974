function incrementMinorVersion(version = '') {
  const [major, minor] = version.split('.').map(Number)

  return [major, minor + 1, 0].join('.')
}

const getReleaseName = () => {
  if (__APP_ENV__ === 'production') {
    return __APP_VERSION__
  }

  const version = incrementMinorVersion(__APP_VERSION__)

  return version
}

export default getReleaseName

import { gql } from '@apollo/client'

const PAGINATED_PAGE_INFO_FRAGMENT = gql`
  fragment paginatedPageInfo on PaginatedPageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
    totalCount
  }
`

export default PAGINATED_PAGE_INFO_FRAGMENT

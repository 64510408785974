import { initialize } from '@iterable/web-sdk'

import config from 'src/config'

import { RefreshTokenMutation } from '../api/refreshTokenMutation'

import { IterableError } from './IterableError'

export type IterableUtils = Awaited<ReturnType<typeof initializeIterable>>

export async function aquireFreshIterableToken(
  refresh: RefreshTokenMutation,
): Promise<string> {
  const { data } = await refresh()

  if (!data)
    throw new IterableError('Failed to aquire Iterable token from the Backend.')

  return data.refreshToken.token
}

export interface InitializeIterableParams {
  refreshToken: RefreshTokenMutation
}

export async function initializeIterable({
  refreshToken,
}: InitializeIterableParams) {
  const key = config.ITERABLE_KEY
  if (!key) throw new IterableError('ITERABLE_KEY is missing, not connecting')

  const utils = initialize(
    key,
    aquireFreshIterableToken.bind(null, refreshToken),
  )

  return utils
}

export interface AuthorizeIterableParams
  extends Pick<IterableUtils, 'setUserID'> {
  userId: string
}
export async function authorizeIterable({
  userId,
  setUserID,
}: AuthorizeIterableParams): Promise<void> {
  try {
    await setUserID(userId)
  } catch (err) {
    const message =
      err instanceof Error
        ? err.message
        : 'Unknown Authorization Error happened'

    throw new IterableError(message)
  }
}

export type DestroyIterableParams = Pick<IterableUtils, 'logout'>
export async function destroyIterable({ logout }: DestroyIterableParams) {
  try {
    await logout()
  } catch (err) {
    const message =
      err instanceof Error ? err.message : 'Unknown Logout Error happened'

    throw new IterableError(message)
  }
}

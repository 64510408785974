import { useCallback, useMemo, useState } from 'react'

import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleBlue,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading5_19, text1_16, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useCancelAppointmentTimeSlotMutation } from '../../api/cancelAppointmentTimeSlotMutation'
import { AsapTime, SpecificTime, TimeBlock } from '../../api/timeSlots/types'
import { convertSlot } from '../../lib/timeSlots'

import { AppointmentSlotRemovalAlert } from './AppointmentSlotRemovalAlert'

interface AppointmentChoiceCardProps {
  index: number
  slot: AsapTime | TimeBlock | SpecificTime
}

export function AppointmentChoiceCard({
  index,
  slot,
}: AppointmentChoiceCardProps) {
  const t = useScopedTranslation('translation.appointments.choice_card')

  const [isModalOpen, setModalOpen] = useState(false)

  const { cancelTimeSlot, isCancellationPending } =
    useCancelAppointmentTimeSlotMutation()

  const description = useMemo(() => convertSlot(t, slot), [t, slot])

  const handlePopupClick = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleModalClosure = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleDeletion = useCallback(() => {
    setModalOpen(false)

    cancelTimeSlot(slot.id)
  }, [cancelTimeSlot, slot.id])

  const i18n = {
    title: t('title', { count: index, ordinal: true }),
  }

  return (
    <Card data-disabled={isCancellationPending}>
      <ChoiceCounter>{index}</ChoiceCounter>
      <ChoiceTitle>{i18n.title}</ChoiceTitle>

      <CloseButton data-test="close" onClick={handlePopupClick} />

      <ChoiceDescription>{description}</ChoiceDescription>

      {isModalOpen && (
        <AppointmentSlotRemovalAlert
          onDelete={handleDeletion}
          onClose={handleModalClosure}
        />
      )}
    </Card>
  )
}

const Card = styled.div`
  background-color: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);

  border: solid 1px ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};

  padding: ${convertPxToRem(20)};

  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content max-content;
  grid-auto-flow: row;

  gap: ${convertPxToRem(24, 16)};

  transition: opacity 0.2s ease-in-out;

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.6;
  }
`

const ChoiceCounter = styled.div`
  ${text1_16};

  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};

  line-height: ${convertPxToRem(24)};
  text-align: center;

  border-radius: 50%;

  background-color: ${featurePaleBlue};
`

const ChoiceTitle = styled.span`
  ${heading5_19};
`

const ChoiceDescription = styled.span`
  ${text2_14};
  color: ${secondaryGrey};

  grid-row: 2 / 3;
  grid-column: 1 / 3;

  &::first-letter {
    text-transform: uppercase;
  }
`

const CloseButton = styled(Sprite).attrs({ name: 'nav/close' })`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};

  color: ${secondaryGrey};

  cursor: pointer;
`

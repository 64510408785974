import { createQuery } from 'effector-apollo'

import client from 'src/apollo/client'

import { FEATURES_QUERY } from './graphql'

export const featuresQuery = createQuery({
  document: FEATURES_QUERY,
  client,
})

export type { Feature } from './types'

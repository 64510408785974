import { gql } from '@apollo/client'

export const LOCATION_FRAGMENT = gql`
  fragment userLocation on Location {
    id
    address
    addressLine1: address_line_1
    addressLine2: address_line_2
    city
    state
    zip
    latitude
    longitude
    kind
    name
    isPreferred: is_preferred
  }
`

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import { SearchBar } from 'src/features/SearchBar'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import { heading3_25 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $search, searchChanged } from '../../model/chats'

export function ChatsSearch() {
  const t = useScopedTranslation('translation.chat.inbox.with_messages')

  return (
    <Wrapper>
      <Conversation name="misc/chat" />
      <Title>{t('title')}</Title>

      <Search placeholder={t('input_placeholder')} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;

  align-items: center;
  gap: ${convertPxToRem(16)};

  padding: ${convertPxToRem(28, 24)};

  background-color: ${primaryWhite};
`

const Conversation = styled(Sprite)`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};

  margin-left: ${convertPxToRem(16)};

  color: ${secondaryGrey};
`

const Title = styled.span`
  ${heading3_25};
`

const ReflectedSearch = reflect({
  view: SearchBar,
  bind: { value: $search, onChange: searchChanged },
})

// FIXME: order reflect -> styled should be reversed, but Reflect breaks typings
const Search = styled(ReflectedSearch)`
  grid-column: 1 / 3;
  grid-row: 2 / 3;

  background-color: ${secondaryBackgroundGrey};
`

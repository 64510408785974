import styled from 'styled-components'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatFileAttachment, ChatMessage } from '../../../api/chatMessage'
import { MessageBubble } from '../MessageBubble'
import { UpdatedAt } from '../UpdatedAt'

import { AttachmentContent } from './AttachmentContent'
import { ScanStatus } from './ScanStatus'

interface DeliveredAttachmentProps {
  message: ChatMessage & { attachment: ChatFileAttachment }
}

export function DeliveredAttachment({ message }: DeliveredAttachmentProps) {
  const { url, contentType, scanStatus } = message.attachment

  return (
    <>
      <MessageBubble>
        <AttachmentContent
          id={message.id}
          link={url}
          contentType={contentType}
        />
      </MessageBubble>

      <Details>
        <ScanStatus status={scanStatus} isOwned={message.isOwned} />

        <UpdatedAt at={message.updatedAt!} />
      </Details>
    </>
  )
}

const Details = styled.div`
  display: flex;
  flex-wrap: nowrap;

  gap: ${convertPxToRem(4)};
`

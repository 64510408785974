import { useCallback, useEffect, useMemo } from 'react'

import { useMutation } from '@apollo/client'
import { useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { REFRESH_TOKENS_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { signOut } from 'src/apollo/utils/authHelpers'
import { ClientContext, RefreshMutationResponse } from 'src/apollo/utils/types'
import Modal from 'src/components/Modals/Modal'
import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { loggingLineCreated, logmask } from 'src/entities/logging'
import { $$sessionStorage } from 'src/shared/storage/session'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16, heading8_14 } from 'src/theme/fonts'
import { getGraphQlError } from 'src/utils/errorHelpers'
import useQueryString from 'src/utils/hooks/useQueryString'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'

function TokenSignIn() {
  const { parsed } = useQueryString()

  const { setRedirect } = useLocalStorage(StorageContainer.persistent)
  const { setTokens } = useLocalStorage(StorageContainer.tokens)

  const navigate = useNavigate()
  const { resetSession } = useUnit($$sessionStorage)

  const log = useUnit(loggingLineCreated)

  const {
    refresh_token: queryStringToken,
    relay_state,
    redirect_url: redirectUrl,
  } = parsed

  const [refresh, { error, client }] = useMutation<RefreshMutationResponse>(
    REFRESH_TOKENS_MUTATION,
    {
      errorPolicy: 'all',
      context: {
        isSkipError: true,
        headers: {
          'X-Refresh-Token': queryStringToken as string,
        },
      } satisfies ClientContext,
    },
  )

  const requestError = useMemo(
    () => (error ? getGraphQlError(error).graphQLErrors.message : null),
    [error],
  )

  const loginWithSSO = useCallback(async () => {
    const { data } = await refresh()

    await signOut(client, { isSsoLogin: true })

    if (!data || !data.refreshTokens) return

    const { access_token, refresh_token } = data.refreshTokens

    log({
      line: 'SSO login successfull',
      meta: { on: 'TokenSignIn' },
    })

    setTokens({ access: access_token, refresh: refresh_token })
    resetSession()

    const dest_url = relay_state || `/${ROUTES.HOME}`
    navigate(dest_url.toString(), { replace: true })
  }, [client, navigate, resetSession, refresh, relay_state, setTokens, log])

  useEffect(() => {
    if (redirectUrl) setRedirect(redirectUrl.toString())
  }, [setRedirect, redirectUrl])

  useEffect(() => {
    log({
      line: 'SSO login attempted',
      meta: {
        on: 'TokenSignIn',
        token: logmask.jwt(queryStringToken?.toString()),
      },
    })

    loginWithSSO()
  }, [loginWithSSO, log, queryStringToken])

  if (!requestError) return <Spinner />

  return (
    <Modal width={convertPxToRem(320)}>
      <TokenErrors requestError={requestError} />
    </Modal>
  )
}

export function TokenErrors({ requestError }: { requestError: string }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const returnToHome = useCallback(() => {
    navigate('/', { replace: true })
  }, [navigate])

  const i18n = {
    returnButton: t('translation.auth.failed.button'),
  }

  return (
    <ErrorWrapper data-test="tokenErrorModal">
      <ErrorMessage>{requestError}</ErrorMessage>
      <ErrorButton data-test="tokenErrorModal.button" onClick={returnToHome}>
        {i18n.returnButton}
      </ErrorButton>
    </ErrorWrapper>
  )
}

const ErrorWrapper = styled.div``

const ErrorMessage = styled.p`
  padding: ${convertPxToRem(22, 70)};
  ${heading8_14};
  color: ${secondaryGrey};
  text-align: center;
`

const ErrorButton = styled.button`
  background-color: ${primaryWhite};
  border: none;
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  color: ${statusErrorRed};
  padding: ${convertPxToRem(11, 0)};
  :hover {
    color: ${secondaryGrey};
  }
  width: 100%;
  border-radius: ${convertPxToRem(0, 0, 20, 20)};
  ${text1_16};
`

export default TokenSignIn

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { useUnit } from 'effector-react'

import { $remoteError } from './model'
import { RemoteError } from './types'
import { RemoteErrorUnauthorized } from './ui/RemoteErrorUnauthorized'
import { RemoteErrorUnconfirmed } from './ui/RemoteErrorUnconfirmed'

export function RemoteErrorHandler() {
  const error = useUnit($remoteError)

  if (!error) return null

  const Component = ErrorUI.get(error)!
  return <Component />
}

const ErrorUI = new Map<RemoteError, React.ComponentType>([
  [RemoteError.unauthorized, RemoteErrorUnauthorized],
  [RemoteError.unconfirmed, RemoteErrorUnconfirmed],
])

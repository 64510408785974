import { useCallback, useState } from 'react'

import { variant } from '@effector/reflect'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import { DrugPrice } from 'src/entities/drugDetails'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $currentPharmacy } from '../model'

import { ExtendedPharmacyContent } from './ExtendedPharmacyContent'
import { PharmacyMap } from './PharmacyMap'

interface ExtendedPharmacyInfoProps {
  data: DrugPrice
}

function ExtendedPharmacyInfoComponent({ data }: ExtendedPharmacyInfoProps) {
  const [isMapFocused, setMapFocused] = useState(false)
  const toggleMap = useCallback(
    () => setMapFocused((isFocused) => !isFocused),
    [],
  )

  const { pharmacy } = data

  return (
    <Root
      data-map-focused={isMapFocused}
      data-test="extendedPharmacyContent.root"
    >
      <MapHolder data-test="extendedPharmacyContent.mapHolder">
        <PharmacyMap pharmacy={pharmacy} />

        <NavigationWrapper>
          <HeaderNavLightTheme withMiddleButton={false} />
        </NavigationWrapper>
      </MapHolder>

      <ExtendedPharmacyContent data={data} toggleMap={toggleMap} />
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr max-content;
  grid-auto-flow: column;

  overflow: hidden;

  width: 100%;
  height: 100%;

  transition: grid-template-rows 0.4s ease-in-out;

  &[data-map-focused='true'] {
    grid-template-rows: 5fr 3fr max-content;
  }
`
const MapHolder = styled.div`
  position: relative;

  width: 100%;
  height: calc(100% + 24px);
`

const NavigationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: ${convertPxToRem(26)};
  pointer-events: none;
`

export const ExtendedPharmacyInfo = variant({
  if: $currentPharmacy.map((data) => Boolean(data)),
  then: variant({
    if: $currentPharmacy.map(
      (data) => !data?.pharmacy.latitude || !data?.pharmacy.longitude,
    ),
    then: ExtendedPharmacyContent,
    else: ExtendedPharmacyInfoComponent,
    bind: {
      data: $currentPharmacy.map((data) => data!),
    },
  }),
})

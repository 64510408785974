import { gql } from '@apollo/client'

import CHAT_MESSAGE_FRAGMENT from 'src/apollo/fragments/CHAT_MESSAGE_FRAGMENT'
import NAVIGATOR_FRAGMENT from 'src/apollo/fragments/NAVIGATOR_FRAGMENT'
import PAGINATED_PAGE_INFO_FRAGMENT from 'src/apollo/fragments/PAGINATED_PAGE_INFO_FRAGMENT'

const CHATS_QUERY = gql`
  query chatsQuery($filters: ChatsFilterInput, $cursor: String, $first: Int) {
    chats(filters: $filters, first: $first, after: $cursor)
      # TODO: remove this @connection after migration to new chats
      # is finished. This separates old and new chats in cache since
      # they must not conflict
      @connection(key: "old") {
      nodes {
        id
        created_at
        is_inactive
        is_resolved
        last_message {
          ...chatMessageFragment
        }
        navigator {
          ...navigatorFragment
        }
        subject
      }
      pageInfo {
        ...paginatedPageInfo
      }
    }
  }
  ${NAVIGATOR_FRAGMENT}
  ${CHAT_MESSAGE_FRAGMENT}
  ${PAGINATED_PAGE_INFO_FRAGMENT}
`
export default CHATS_QUERY

import { TypedDocumentNode, gql } from '@apollo/client'

import { DrugSearchResult, DrugSearchVariables } from './types'

export const DRUG_SEARCH_QUERY: TypedDocumentNode<
  DrugSearchResult,
  DrugSearchVariables
> = gql`
  query drugSearch($text: String!) {
    drugs: pdsDrugs(search_text: $text) {
      name
    }
  }
`

import { reflect } from '@effector/reflect'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { $preferredPharmacy } from 'src/entities/drugDetails'
import {
  ChatCreatedArguments,
  chatCreated,
  createChatFx,
} from 'src/features/drugSearchTicketCreation'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NoticeBubble } from 'src/shared/ui/NoticeBubble'
import { featurePaleRed, primaryBlue } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface NotAvailableMessageProps {
  onChat: (param: ChatCreatedArguments) => void
  pharmacyName: string
  isLoading: boolean
  isForPreferred?: boolean
}

function NotAvailableMessageView({
  onChat,
  pharmacyName,
  isLoading,
  isForPreferred = false,
}: NotAvailableMessageProps) {
  const t = useScopedTranslation('translation.medications.drug_search.messages')

  const openChat = () => {
    onChat({ pharmacyName })
  }

  return (
    <Root>
      <Trans
        t={t}
        i18nKey={
          isForPreferred
            ? 'not_available_preferred_price'
            : 'not_available_section_price'
        }
        components={{
          chatLink: (
            <ChatLink
              data-test="notAvailableMessageView.chatLink"
              data-is-loading={isLoading}
              onClick={openChat}
            />
          ),
        }}
        parent="span"
      />
    </Root>
  )
}

const Root = styled(NoticeBubble)`
  margin-bottom: ${convertPxToRem(8)};
  background-color: ${featurePaleRed};

  :last-child {
    margin-bottom: 0;
  }
`

const ChatLink = styled.span`
  border: none;
  background: none;
  padding: 0;
  line-height: ${convertPxToRem(22)};

  color: ${primaryBlue};
  ${textLink2_14};
  cursor: pointer;

  &[data-is-loading='true'] {
    opacity: 0.7;
    cursor: default;
  }
`

export const NotAvailableMessage = reflect({
  view: NotAvailableMessageView,
  bind: {
    onChat: chatCreated,
    pharmacyName: $preferredPharmacy.map((data) => data?.pharmacy.name ?? ''),
    isLoading: createChatFx.pending,
  },
})

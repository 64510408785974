import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { primaryBlue } from 'src/theme/colors'
import { text4_12 } from 'src/theme/fonts'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface PhoneStringProps {
  value: string
}

export function PhoneString({ value }: PhoneStringProps) {
  return (
    <CallLink
      data-test="callPhoneNumberFromRxCard"
      href={formatPhone(value, PHONE_FORMATS.uri) ?? undefined}
    >
      <Sprite
        name="misc/call"
        height={convertPxToRem(12)}
        width={convertPxToRem(13)}
      />
      <Text>{value}</Text>
    </CallLink>
  )
}

const Text = styled.span`
  ${text4_12};
  padding-left: ${convertPxToRem(3)};
`
const CallLink = styled.a`
  display: flex;
  align-items: center;
  color: ${primaryBlue};
  :active,
  :hover,
  :focus {
    outline: none;
  }
`

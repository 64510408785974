import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { equals, not, reset, reshape, status } from 'patronum'

import { getCoordinates } from './lib/position'
import { Coordinates, LocationError, LocationState, RequestMode } from './types'

export const coordinatesRequested = createEvent<RequestMode>()

export const permissionCheckRequested = createEvent<void>()

export const checkPermissionFx = createEffect<void, PermissionState>()
export const aquireCoordinatesFx = createEffect<
  void,
  Coordinates,
  LocationError
>()

export const $current = restore(aquireCoordinatesFx.doneData, null)

export const $state = createStore<LocationState>(LocationState.Unknown)
  .on(aquireCoordinatesFx, () => LocationState.Requested)
  .on(aquireCoordinatesFx.done, () => LocationState.Known)
  .on(aquireCoordinatesFx.fail, () => LocationState.Failed)
  .on(checkPermissionFx.fail, () => LocationState.Failed)

export const $error = createStore<LocationError | null>(null)

export const $permission = createStore<PermissionState>('prompt')
  .on(checkPermissionFx.doneData, (_, state) => state)
  .on(aquireCoordinatesFx.done, () => 'granted')
  .on(checkPermissionFx.fail, () => 'denied')
  .on(aquireCoordinatesFx.fail, () => 'denied')

export const $isKnown = equals($state, LocationState.Known)
export const $checkStatus = status({ effect: checkPermissionFx })

export const permission = reshape({
  source: $permission,
  shape: {
    $granted: (state) => state === 'granted',
    $prompt: (state) => state === 'prompt',
    $denied: (state) => state === 'denied',
  },
})

sample({
  clock: permissionCheckRequested,
  target: checkPermissionFx,
})

sample({
  clock: coordinatesRequested,
  filter: not(aquireCoordinatesFx.pending),
  target: aquireCoordinatesFx,
})

sample({
  clock: aquireCoordinatesFx.failData,
  source: coordinatesRequested,
  filter: (mode) => mode === RequestMode.Manual,
  fn: (_, code) => code,
  target: $error,
})

reset({
  clock: aquireCoordinatesFx.done,
  target: $error,
})

checkPermissionFx.use(() =>
  navigator.permissions
    .query({ name: 'geolocation' })
    .then(({ state }) => state),
)

aquireCoordinatesFx.use(() =>
  getCoordinates().then(({ latitude, longitude }) => ({ latitude, longitude })),
)

import { gql } from '@apollo/client'

import BILL_CLAIM_FRAGMENT from 'src/apollo/fragments/BILL_CLAIM_FRAGMENT'
import PAGINATED_PAGE_INFO_FRAGMENT from 'src/apollo/fragments/PAGINATED_PAGE_INFO_FRAGMENT'

const BILL_CLAIMS_QUERY = gql`
  query billClaimsQuery(
    $filters: BillClaimFilterInput
    $first: Int
    $cursor: String
  ) {
    billClaims(filters: $filters, first: $first, after: $cursor) {
      nodes {
        ...billClaimFragment
      }
      pageInfo {
        ...paginatedPageInfo
      }
    }
  }
  ${BILL_CLAIM_FRAGMENT}
  ${PAGINATED_PAGE_INFO_FRAGMENT}
`

export default BILL_CLAIMS_QUERY

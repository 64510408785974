import { ApolloClient } from '@apollo/client'

import { destroyActionCableLink } from 'src/apollo/links/sockets'
import { ROUTES } from 'src/constants/routesConstants'
import { resetFreshpaintFx } from 'src/entities/freshpaint/model/api' // FIXME: breakage of FSD to prevent deps cycle
import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'
import { $$sessionStorage } from 'src/shared/storage/session'
import persistentStorageInterface from 'src/utils/storage/persistent'
import tokensStorageInterface from 'src/utils/storage/tokens'

interface SignOutParams {
  isSsoLogin?: boolean
  preventLocationReplace?: boolean
  resetRedux?: () => void
}

export async function signOut(
  client: ApolloClient<unknown>,
  {
    isSsoLogin = false,
    preventLocationReplace: isPreventLocationReplace = false,
    resetRedux,
  }: SignOutParams = {},
) {
  try {
    client.stop()
    await client.clearStore() // clear apolloStore

    void resetFreshpaintFx() // FIXME: scope bind
    destroyActionCableLink()

    tokensStorageInterface.modify.resetTokens()
    $$sessionStorage.sessionReset() // FIXME: scope bind!
    $$drugSearchConfigurationStorage.configsCleared() // FIXME: scope bind!

    if (typeof resetRedux === 'function') resetRedux()

    if (isSsoLogin) {
      persistentStorageInterface.modify.clearEmail()
      return
    }

    if (!isPreventLocationReplace) window.location.replace(ROUTES.EMAIL_ENTRY)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[Apollo] SignOut err: ', err)
  }
}

export const EMPLOYEE_ACCESIBILITY_STATUSES = {
  REGISTERED: 'registered',
  ELIGIBLE: 'eligible',
  SET_PASSWORD_REQUIRED: 'set_password_required',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// We need any to type some functions correctly

export function pipe<Fns extends readonly UnaryFunction[]>(
  ...fns: Pipeable<Fns>
) {
  return (arg: PipeParams<Fns>): PipeReturn<Fns> =>
    fns.reduce((acc, cur) => cur(acc), arg) as PipeReturn<Fns>
}

export function compose<Fns extends readonly UnaryFunction[]>(
  ...fns: Composable<Fns>
) {
  return (arg: ComposeParams<Fns>): ComposeReturn<Fns> =>
    fns.reduceRight((acc, cur) => cur(acc), arg) as ComposeReturn<Fns>
}

export function arrify<T>(param: T | T[]): T[] {
  return Array.isArray(param) ? param : [param]
}

// === Types ===

type UnaryFunction = (x: any) => any

/* Pipe */
type Pipeable<Fn> = Fn extends readonly [UnaryFunction]
  ? Fn
  : Fn extends readonly [...infer Rest, any]
  ? readonly [...Pipeable<Rest>, (arg: PipeReturn<Rest>) => any]
  : never

type PipeParams<Fns extends readonly UnaryFunction[]> = Parameters<Fns[0]>[0]

type PipeReturn<Fns> = Fns extends readonly [
  ...any[],
  infer Last extends UnaryFunction,
]
  ? ReturnType<Last>
  : never

type Composable<Fn> = Fn extends readonly [UnaryFunction]
  ? Fn
  : Fn extends readonly [any, ...infer Rest extends readonly UnaryFunction[]]
  ? readonly [(arg: ComposeReturn<Rest>) => any, ...Composable<Rest>]
  : never

type ComposeReturn<Fns extends readonly UnaryFunction[]> = ReturnType<Fns[0]>

type ComposeParams<Fns> = Fns extends readonly [
  ...any[],
  infer Last extends UnaryFunction,
]
  ? Parameters<Last>[0]
  : never

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P]
}

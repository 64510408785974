import { ForwardedRef, forwardRef, type SVGProps } from 'react'

import styled from 'styled-components'

import { IconsMeta, type IconsMap } from './meta.generated'

interface IconProps extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: AnySpriteName
}

type SpriteName<Key extends keyof IconsMap> = `${Key}/${IconsMap[Key]}`

type AnySpriteName = {
  [Key in keyof IconsMap]: SpriteName<Key>
}[keyof IconsMap]

function SpriteView(
  { name: icon, className, ...props }: IconProps,
  ref: ForwardedRef<SVGSVGElement>,
) {
  const { filePath, name, axis, viewBox } = useIconMeta(icon)

  return (
    <SpriteVector
      className={className}
      ref={ref}
      // View Attributes
      viewBox={viewBox}
      data-axis={axis}
      // Improve UX
      focusable="false"
      aria-hidden
      {...props}
    >
      <use href={`/sprites/${filePath}#${name}`} />
    </SpriteVector>
  )
}

function useIconMeta<Key extends keyof IconsMap>(fqname: SpriteName<Key>) {
  const [sprite, name] = fqname.split('/') as [Key, IconsMap[Key]]

  const { filePath, items } = IconsMeta[sprite]
  const { viewBox, height, width } = items[name]

  const axis = width === height ? 'xy' : width > height ? 'x' : 'y'

  return { filePath, name, axis, viewBox }
}

const SpriteVector = styled.svg`
  user-select: none;

  fill: currentColor;
  color: inherit;

  box-sizing: content-box;
  display: inline-block;
`

export const Sprite = forwardRef(SpriteView)

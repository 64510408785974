import { reflect } from '@effector/reflect'

import { $rejectionInfo, DrugRejection } from 'src/entities/drugDetails'
import { RejectTypeEnum } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'

import { ErrorChatWidget } from '../content/ChatWidget/ErrorChatWidget'
import { OnlyChatWidget } from '../content/ChatWidget/OnlyChatWidget'

import { ConfigErrorContent } from './ConfigErrorContent'
import { GenericErrorContent } from './GenericErrorContent'

interface ErrorContentControllerProps {
  rejectionInfo: DrugRejection | null
}

function ErrorContentControllerComponent({
  rejectionInfo,
}: ErrorContentControllerProps) {
  const t = useScopedTranslation('translation.medications.drug_search.buttons')

  const i18n = {
    [RejectTypeEnum.DaysSupplyExceeds]: t('update_days_supply'),
    [RejectTypeEnum.QuantityExceeds]: t('update_quantity'),
  }

  switch (rejectionInfo?.rejectType) {
    case RejectTypeEnum.DaysSupplyExceeds:
    case RejectTypeEnum.QuantityExceeds:
      return (
        <ConfigErrorContent rejectMessage={rejectionInfo.rejectMessage}>
          <ErrorChatWidget
            description={rejectionInfo.rejectDescription}
            updateTitle={i18n[rejectionInfo.rejectType]}
          />
        </ConfigErrorContent>
      )
    case RejectTypeEnum.DrugNotCovered:
      return (
        <ConfigErrorContent
          rejectMessage={rejectionInfo.rejectMessage}
          dataTestProp="drugNotCoveredError.root"
        >
          <OnlyChatWidget description={rejectionInfo.rejectDescription} />
        </ConfigErrorContent>
      )

    default:
      return (
        <GenericErrorContent description={rejectionInfo?.rejectDescription} />
      )
  }
}

export const ErrorContentController = reflect({
  view: ErrorContentControllerComponent,
  bind: {
    rejectionInfo: $rejectionInfo,
  },
})

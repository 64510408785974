import { MouseEventHandler } from 'react'

import styled from 'styled-components'

import {
  StepActionBaseProps,
  StepActionComponent,
  ContentStepType,
} from '../../types'

interface StepRxContentProps
  extends StepActionBaseProps<ContentStepType.RxCard> {
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

function StepRxContentComponent({
  action,
  className = undefined,
  onClick = undefined,
}: StepRxContentProps) {
  return (
    <StepAction
      onClick={onClick}
      className={className}
      data-test="stepRxContent.stepAction"
    >
      {action.content}
    </StepAction>
  )
}

const StepAction = styled.span`
  text-decoration: underline;

  color: inherit;
  cursor: pointer;
`

export const StepRxContent =
  StepRxContentComponent as StepActionComponent<StepRxContentProps>

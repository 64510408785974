import { FieldPolicy, Reference } from '@apollo/client'
import uniqBy from 'lodash/uniqBy'

interface DoctorsCacheEntry {
  nodes: Reference[]
  pageInfo: {
    endCursor: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor: string
    totalCount: string
  }
}

export const DoctorsPolicy: FieldPolicy<DoctorsCacheEntry> = {
  keyArgs: ['filters'],
  merge(existing, incoming) {
    if (!existing) {
      return incoming
    }

    const doctors = uniqBy([...existing.nodes, ...incoming.nodes], '__ref')

    return {
      nodes: doctors,
      pageInfo: incoming.pageInfo,
    }
  },
}

import { FunctionComponent } from 'react'

import FavouriteIcon from 'src/components/SvgIcons/FavouriteIcon'
import LocatorIcon from 'src/components/SvgIcons/LocatorIcon'
import { IconProps } from 'src/components/SvgIcons/types'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { featurePaleRed, featurePaleBlue } from 'src/theme/colors'

export interface GetCareNavigationButton {
  to: string
  Icon: FunctionComponent<IconProps>

  title: string
  description: string

  color: string
  dataTestProp: string

  guard: FeatureFlag
}

const i18n = {
  careFinder: 'translation.home.care_tools.care_finder.title',
  careFinderDescription:
    'translation.home.care_tools.care_finder_card_description',

  myCareTeam: 'translation.home.care_tools.my_care_team.title',
  myCareTeamDescription:
    'translation.home.care_tools.my_care_team_card_description',
}

export const GetCareButtons: GetCareNavigationButton[] = [
  {
    to: `/${ROUTES.FIND_CARE}`,
    Icon: LocatorIcon,

    title: i18n.careFinder,
    description: i18n.careFinderDescription,

    color: featurePaleBlue,
    dataTestProp: 'getCareButton.findCare',
    guard: FeatureFlag.CARE_LOCATOR,
  },
  {
    to: `/${ROUTES.CARE_TEAM_PROVIDERS_PATH}`,
    Icon: FavouriteIcon,

    title: i18n.myCareTeam,
    description: i18n.myCareTeamDescription,

    color: featurePaleRed,
    dataTestProp: 'getCareButton.careTeam',
    guard: FeatureFlag.MY_CARE_TEAM,
  },
]

import { lazyWithRetry } from 'src/shared/lib/lazy'

const EmailEntry = lazyWithRetry(
  () => import('src/sections/SectionAuth/EmailEntry'),
)
const PasswordEntry = lazyWithRetry(
  () => import('src/sections/SectionAuth/PasswordEntry'),
)

const CreateNewPassword = lazyWithRetry(
  () => import('src/sections/SectionAuth/PasswordEntry/ResetPassword'),
)

export { EmailEntry, PasswordEntry, CreateNewPassword }

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import iphoneWithLogo from 'src/assets/images/iphone_with_logo@3x.png'
import SwipeDownIcon from 'src/components/SvgIcons/SwipeDownIcon'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  modalBlackOpacity,
  primaryWhite,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { heading4_21, text1_16, text2_14_Medium } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { locationRequested, modalClosed } from '../model/actions'

interface ShareYourLocationProps {
  isMobileModal?: boolean
}

export function ShareYourLocation({
  isMobileModal = false,
}: ShareYourLocationProps) {
  const t = useScopedTranslation('translation.auth.location')

  // TODO: move to reflect
  const close = useUnit(modalClosed)
  const request = useUnit(locationRequested)

  return (
    <Root onClick={close} data-test="shareLocationModal">
      {isMobileModal && <SwipeDownIcon />}

      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <HeaderContainer>
          <ImageWrapper>
            <Image
              src={iphoneWithLogo}
              alt="Phone with geo tag on the screen"
            />
          </ImageWrapper>
        </HeaderContainer>

        <Title>{t('title')}</Title>
        <Description>{t('description')}</Description>

        <ConfirmButton onClick={request}>{t('confirm')}</ConfirmButton>
        <DismissButton onClick={close}>{t('dismiss')}</DismissButton>
      </ModalContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;

  background-color: ${modalBlackOpacity};
  z-index: 2;

  width: 100%;
  height: 100%;

  ${mediaQueryFor.mobile} {
    justify-content: end;
  }
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: max-content;
  max-width: ${convertPxToRem(500)};

  background: ${primaryWhite};
  padding: ${convertPxToRem(42, 24, 12, 24)};

  border-radius: ${convertPxToRem(20, 20, 20, 20)};

  ${mediaQueryFor.mobile} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Image = styled.img`
  width: ${convertPxToRem(66)};
  height: ${convertPxToRem(120)};
`

const HeaderContainer = styled.div`
  align-items: center;
  margin-bottom: ${convertPxToRem(16)};
`

const Title = styled.span`
  ${heading4_21};
  text-align: center;
  margin-bottom: ${convertPxToRem(16)};
`

const Description = styled.div`
  ${text1_16};
  margin-bottom: ${convertPxToRem(32)};
  color: ${secondaryGrey};
`

const ConfirmButton = styled.button`
  ${text2_14_Medium};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${primaryBlue};
  color: ${primaryWhite};
  outline: none;
  border: none;

  width: 100%;
  height: ${convertPxToRem(48)};

  margin-top: ${convertPxToRem(32)};
  border-radius: ${convertPxToRem(20)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

const DismissButton = styled.button`
  ${text2_14_Medium};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  outline: none;
  border: none;
  background: transparent;

  color: ${primaryBlue};

  width: 100%;
  height: ${convertPxToRem(40)};

  margin-top: ${convertPxToRem(16)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

import { useCallback, useEffect } from 'react'

import { useUnit } from 'effector-react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'
import { $$sessionStorage } from 'src/shared/storage/session'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'

function ExpiredSession() {
  const t = useScopedTranslation('translation.shared')

  const { resetSession } = useUnit($$sessionStorage)
  const { clearConfigs } = useUnit($$drugSearchConfigurationStorage)

  const { getState: getPersistentStorageState, clearRedirect } =
    useLocalStorage(StorageContainer.persistent)

  const clickHandler = useCallback(() => {
    resetSession()

    const { redirectUrl } = getPersistentStorageState()

    const url = redirectUrl ?? `/${ROUTES.EMAIL_ENTRY}`
    return window.location.replace(url)
  }, [getPersistentStorageState, resetSession])

  useEffect(() => {
    clearRedirect()
    clearConfigs()
  }, [clearRedirect, clearConfigs])

  return (
    <Root>
      <Helmet>
        <title>{t('inactivity.logged_out.title')}</title>
      </Helmet>

      <NativeAlert
        title={t('inactivity.logged_out.title')}
        description={t('inactivity.logged_out.desc')}
      >
        <NativeAlertAction mode="secondary" onClick={clickHandler}>
          {t('buttons.okay')}
        </NativeAlertAction>
      </NativeAlert>
    </Root>
  )
}

export default ExpiredSession

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

import { attach, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { condition } from 'patronum'

import { ROUTES } from 'src/constants/routesConstants'
import { $$drugInfo } from 'src/entities/drugInfo'
import { $$navigate, NavigateParams } from 'src/entities/navigate'
import {
  $hasPreferredPharmacy,
  $preferredPharmacy,
} from 'src/entities/pharmacy'
import { remap } from 'src/shared/lib/effector/remap'

interface DrugSearchAlternativesGateProps {
  drugName: string
}

const DrugSearchAlternatives = createDomain('DrugSearchAlternatives')

export const DrugSearchAlternativesGate =
  createGate<DrugSearchAlternativesGateProps>({
    name: 'DrugSearchAlternativesGate',
    domain: DrugSearchAlternatives,
  })

export const drugSearchAlternativesReset = DrugSearchAlternatives.event<void>()
export const alternativeSelected = DrugSearchAlternatives.event<string>()

const skipAlternativesFx = attach({
  effect: $$navigate.navigateFx,
  source: remap(DrugSearchAlternativesGate.state, 'drugName'),
  mapParams({ to, withQuery }: NavigateParams, drugName) {
    return {
      to,
      withQuery,
      replace: true,
      state: { drugName, isPreconfigured: false },
    }
  },
})

sample({
  source: DrugSearchAlternativesGate.open,
  target: $$drugInfo.drugInfoQuery.start,
})

sample({
  clock: DrugSearchAlternativesGate.close,
  target: $$drugInfo.drugInfoQuery.reset,
})

sample({
  clock: sample({
    clock: $$drugInfo.drugInfoQuery.finished.success,
    filter: DrugSearchAlternativesGate.status,
  }),
  source: {
    drugInfo: remap($$drugInfo.drugInfoQuery.$data, 'drugInfo'),
    preferredPharmacy: $preferredPharmacy,
  },
  filter: ({ drugInfo }) => drugInfo?.alternatives.length === 0,
  fn: ({ preferredPharmacy }) => ({
    to: preferredPharmacy
      ? ROUTES.DRUG_SEARCH_CONFIGURE
      : ROUTES.DRUG_SEARCH_INTRO,
  }),
  target: skipAlternativesFx,
})

sample({
  clock: alternativeSelected,
  fn: (drugName) => ({ drugName, isPreconfigured: false }),
  target: condition({
    if: $hasPreferredPharmacy,
    then: $$navigate.withStateTo(ROUTES.DRUG_SEARCH_CONFIGURE),
    else: $$navigate.withStateTo(ROUTES.DRUG_SEARCH_INTRO),
  }),
})

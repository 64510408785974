import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function CoverageIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = secondaryGrey,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill={fill}>
        <path d="M8.125 10h-.833c-.056 0-.109-.022-.148-.061-.039-.04-.06-.092-.06-.147v-.834c-.001-.165-.067-.324-.184-.441-.117-.118-.276-.183-.442-.184h-1.25c-.166 0-.324.066-.442.184-.117.117-.183.276-.183.441v.834c0 .055-.022.108-.06.147-.04.039-.093.061-.148.061h-.833c-.166 0-.325.066-.442.183-.117.117-.183.276-.183.442v1.25c0 .166.066.325.183.442.117.117.276.183.442.183h.833c.055 0 .108.022.147.061.04.04.061.092.061.147v.834c0 .165.066.324.183.441.118.117.276.183.442.184h1.25c.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441v-.834c0-.055.022-.108.061-.147.04-.04.092-.061.147-.061h.834c.166 0 .324-.066.442-.183.117-.117.183-.276.183-.442v-1.25c0-.166-.066-.325-.183-.442-.118-.117-.276-.183-.442-.183z" />
        <path d="M20 3.75c0-.442-.176-.866-.488-1.179-.313-.312-.737-.488-1.179-.488H1.667c-.442 0-.866.176-1.179.488C.176 2.884 0 3.308 0 3.75v12.5c0 .442.176.866.488 1.178.313.313.737.489 1.179.489h16.666c.442 0 .866-.176 1.179-.489.312-.312.488-.736.488-1.178V3.75zM2.083 16.25c-.11 0-.216-.044-.294-.122-.078-.078-.122-.184-.122-.295v-8.75c0-.11.044-.216.122-.294.078-.078.184-.122.294-.122h15.834c.11 0 .216.044.294.122.078.078.122.184.122.294v8.75c0 .11-.044.217-.122.295-.078.078-.184.122-.294.122H2.083z" />
        <path d="M16.042 9.792h-3.959c-.165 0-.324.065-.441.183-.118.117-.183.276-.183.442 0 .165.065.324.183.442.117.117.276.183.442.183h3.958c.166 0 .325-.066.442-.183.117-.118.183-.277.183-.442 0-.166-.066-.325-.183-.442-.117-.118-.276-.183-.442-.183zM15 12.292h-2.916c-.166 0-.325.066-.442.183-.118.117-.183.276-.183.442 0 .165.065.324.183.442.117.117.276.183.442.183H15c.166 0 .325-.066.442-.183.117-.118.183-.277.183-.442 0-.166-.066-.325-.183-.442-.117-.117-.276-.183-.442-.183z" />
      </g>
    </svg>
  )
}

export default CoverageIcon

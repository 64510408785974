import i18next from 'src/translations/i18n'

export const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/

export const PASSWORD_TYPE = {
  NEW: 'new',
  CREATE: 'create',
  CONFIRM: 'confirm',
  CURRENT: 'current',
}

export const PASSWORD_ENTRY_SCREEN_TYPES = {
  ALREADY_REGISTERED: 'already_registered',
  SUCCESS_SCREEN: 'success_screen',
}

export const getPasswordEntryTitles = (screenType) => {
  switch (screenType) {
    case PASSWORD_ENTRY_SCREEN_TYPES.ALREADY_REGISTERED:
      return {
        title: i18next.t('translation.auth.signin.already_registered_title'),
        subtitle: i18next.t(
          'translation.auth.signin.already_registered_subtitle',
        ),
      }
    default:
      return {
        title: i18next.t('translation.auth.signin.welcome_back'),
        subtitle: null,
      }
  }
}

export const DEACTIVATED_TITLE = 'translation.auth.deactivated_title'

export const REQUIRED_TITLE = 'translation.auth.required'

export const INVALID_EMAIL_MESSAGE = 'translation.auth.signin.rules.message'

export const RESEND_TIME = 120

import { useRef, useCallback, RefCallback, useEffect } from 'react'

export function useIntersectionObserver(updater: { current: () => void }) {
  const root = useRef<Element>()
  const element = useRef<Element>()

  const obs = useRef<IntersectionObserver>()

  const callback: IntersectionObserverCallback = useCallback(
    (entries) => {
      const entry = entries.find((item) => item.target === element.current)
      if (entry?.isIntersecting) updater.current()
    },
    [updater],
  )

  const observe: RefCallback<Element> = useCallback((ref) => {
    // always stop observing the old element
    if (element.current) obs.current?.unobserve(element.current)

    // if unmounted, we're done
    if (!ref) return

    // we're mounted to the DOM
    element.current = ref
    obs.current?.observe(ref)
  }, [])

  useEffect(() => {
    obs.current = new IntersectionObserver(callback, { root: root.current })

    // if we were observing on some element, restart observing
    if (element.current) obs.current.observe(element.current)

    return () => obs.current?.disconnect()
  }, [callback])

  return { root, observe }
}

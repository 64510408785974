import React from 'react'

import { RouteComponentProps, WindowLocation, useLocation } from '@reach/router'
import { useGate } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import { withDispatch } from 'src/store'
import { openChatThread } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { PharmacyDetailsGate } from '../model'

import { ExtendedPharmacyInfo } from './ExtendedPharmacyInfo'
import { PharmacyInfo } from './PharmacyInfo'

interface LocationState {
  drugName?: string
  pharmacyNabp?: string
  isExtended?: boolean
}

type PharmacyDetailsProps = ConnectedProps<typeof connector>

function PharmacyDetails({
  dispatchOpenChatThread,
  dispatchSetIsShowThirdSectionModal,
}: PharmacyDetailsProps) {
  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName = '', pharmacyNabp = '', isExtended = false } = state ?? {}

  useGate(PharmacyDetailsGate, {
    drugName,
    pharmacyNabp,
    dispatchOpenChatThread,
    dispatchSetIsShowThirdSectionModal,
  })

  return (
    <Wrapper>
      {isExtended ? <ExtendedPharmacyInfo /> : <PharmacyInfo />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${convertPxToRem(40)};
  display: flex;
  flex: 1;
  overflow-y: auto;

  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`

const mapDispatchToProps = withDispatch({
  setIsShowThirdSectionModal,
  openChatThread,
})

const connector = connect(null, mapDispatchToProps)
export const DrugSearchPharmacyDetails = connector(
  PharmacyDetails,
) as React.FC<RouteComponentProps>

import React from 'react'

import styled from 'styled-components'

import navigatorBillingIcon from 'src/assets/icons/navigator_icon_billing.png'

function NavigatorIconInGetHelp() {
  return <Image src={navigatorBillingIcon} alt="navigatorBillingIcon" />
}

export default NavigatorIconInGetHelp

const Image = styled.img``

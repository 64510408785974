import React from 'react'

import styled from 'styled-components'

import CheckMark from 'src/components/SvgIcons/CheckMark'
import { primaryBlue, secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface CheckboxProps {
  checked?: boolean
}

export function Checkbox({ checked = false }: CheckboxProps) {
  return (
    <Root>
      <CheckInput type="checkbox" checked={checked} readOnly />
      <CheckMarker>
        <CheckIcon width={convertPxToRem(12)} height={convertPxToRem(12)} />
      </CheckMarker>
    </Root>
  )
}

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const CheckMarker = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(18)};
  width: ${convertPxToRem(18)};
  border-radius: ${convertPxToRem(4)};
  border: ${convertPxToRem(1)} solid ${secondaryGrey};
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

const CheckIcon = styled(CheckMark)`
  display: none;
`

const Root = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  ${CheckInput}:checked ~ span {
    background-color: ${primaryBlue};
    border: ${convertPxToRem(1)} solid transparent;

    svg {
      display: block;
    }
  }

  ${CheckInput}:checked ~ span:after {
    display: block;
  }
`

import { OnDataOptions, useSubscription } from '@apollo/client'

import { Event as SubscriptionEvent } from 'src/shared/api/types'

import { CHAT_MESSAGES_UPDATE_FRAGMENT } from '../chatQuery'

import { CHAT_SUBSCRIPTION } from './graphql'
import { ChatMessagesResult } from './types'

const onUpdate =
  (chatId: string) =>
  ({ client, data: { data } }: OnDataOptions<ChatMessagesResult>) => {
    client.cache.updateFragment(
      {
        fragment: CHAT_MESSAGES_UPDATE_FRAGMENT,
        id: client.cache.identify({ __typename: 'Chat', id: chatId }),
      },
      (chat) => {
        if (!chat?.messages?.nodes) return /* skip */ undefined
        if (!data?.chat?.message) return /* skip */ undefined

        const { message, event } = data.chat
        let { nodes } = chat.messages

        switch (event) {
          case SubscriptionEvent.Created:
            nodes = nodes.filter((item) => item!.id !== message.id) // ensure no duplicates exist
            nodes.unshift(message) // created messages are always 'first'
            break

          case SubscriptionEvent.Deleted:
            client.cache.evict({ id: client.cache.identify(message) })
            setTimeout(() => client.cache.gc(), 0) // schedule deletion for later™

            nodes = nodes.filter((item) => item!.id !== message.id)
            break

          case SubscriptionEvent.Updated: /* skip: Apollo Will update cache using ID */
          case SubscriptionEvent.Added: /* skip: not relevant */
          case SubscriptionEvent.Removed: /* skip: not relevant */
          default: /* do nothing */
        }

        return { messages: { nodes } }
      },
    )
  }

export function useChatSubscription(id: string) {
  useSubscription(CHAT_SUBSCRIPTION, {
    variables: { id },
    onData: onUpdate(id),
    ignoreResults: true,
  })
}

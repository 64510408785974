import { list, variant } from '@effector/reflect'
import styled from 'styled-components'

import {
  $recommendedPharmacies,
  $specialtyPharmacies,
} from 'src/entities/drugDetails'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { NotAvailableMessage } from './NotAvailableMessage'
import { PharmacySelector } from './PharmacySelector'

function RecommendedPharmaciesListView() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  return (
    <Section>
      <Title>{t('recommended_pharmacies')}</Title>
      <Pharmacies />
    </Section>
  )
}

const Title = styled.span`
  display: flex;

  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  text-transform: uppercase;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${convertPxToRem(8)};
`

const PharmaciesList = list({
  view: PharmacySelector,
  source: $recommendedPharmacies,
  mapItem: {
    pharmacyData: (pharmacy) => pharmacy,
  },
  getKey: ({ pharmacy }) => pharmacy.nabp,
})

const Pharmacies = variant({
  if: $recommendedPharmacies.map((pharmacies) => pharmacies.length > 0),
  then: PharmaciesList,
  else: NotAvailableMessage,
})

export const RecommendedPharmaciesList = variant({
  if: $specialtyPharmacies.map((pharmacies) => pharmacies.length === 0),
  then: RecommendedPharmaciesListView,
})

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $currentConfigState,
  $drugDosageOptions,
  $drugFormOptions,
  drugSearchConfigChanged,
} from '../model/core'
import { ConfigType } from '../model/types'

import { DaysSupply } from './DaysSupply'
import { Dropdown } from './Dropdown'
import { Quantity } from './Quantity'

export function DropdownsList() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')
  const i18n = {
    form: t('form'),
    formPlaceholder: t('select', {
      value: t('form').toLocaleLowerCase(),
    }),
    dosage: t('dosage'),
    dosagePlaceholder: t('select', {
      value: t('dosage').toLocaleLowerCase(),
    }),
  }

  const [drugForms, drugDosages, currentConfigState] = useUnit([
    $drugFormOptions,
    $drugDosageOptions,
    $currentConfigState,
  ])
  const configChanged = useUnit(drugSearchConfigChanged)

  const selectionHandler = (type: ConfigType) => (value: string) => {
    configChanged({ type, value })
  }

  return (
    <Root>
      <DaysSupply />

      <Dropdown
        title={i18n.form}
        value={currentConfigState.form}
        placeholder={i18n.formPlaceholder}
        options={drugForms}
        onSelect={selectionHandler(ConfigType.Form)}
        dataTestProp="dropdown.form"
        type={ConfigType.Form}
      />

      <Dropdown
        title={i18n.dosage}
        value={currentConfigState.dosage}
        placeholder={i18n.dosagePlaceholder}
        options={drugDosages}
        onSelect={selectionHandler(ConfigType.Dosage)}
        dataTestProp="dropdown.dosage"
        isDisabled={!currentConfigState.form}
        type={ConfigType.Dosage}
      />

      <Quantity />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${convertPxToRem(32)};
`

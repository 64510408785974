import React from 'react'

import styled from 'styled-components'

import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import { useTermsOfServiceAgreementFragment } from 'src/entities/termsOfServices'
import {
  TooltipModalWrapper,
  TooltipModalRoot,
} from 'src/sections/SectionHome/Coverage/styled'
import { ContentContainer } from 'src/sections/SectionRegistration/styled'
import { secondaryGrey, primaryDarkNavy, primaryWhite } from 'src/theme/colors'
import { text1_16, heading4_21 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TermsModalProps {
  id: string
  closeModal: () => void
}

export function TermsModal({ id, closeModal }: TermsModalProps) {
  const { agreement } = useTermsOfServiceAgreementFragment(id)

  if (!agreement) return null

  const { displayName, text } = agreement

  return (
    <TooltipModalRoot>
      <Content>
        <TooltipModalBackButtonWrapper>
          <ButtonClose40 onClick={closeModal} border="none" withShadow />
        </TooltipModalBackButtonWrapper>
        <Wrapper>
          <TooltipModalWrapperTitle>{displayName}</TooltipModalWrapperTitle>

          <TooltipModalWrapperDescription>
            <ContentContainer dangerouslySetInnerHTML={{ __html: text }} />
          </TooltipModalWrapperDescription>
        </Wrapper>
      </Content>
    </TooltipModalRoot>
  )
}

const TooltipModalWrapperTitle = styled.div`
  ${heading4_21};
  text-align: center;
  color: ${primaryDarkNavy};
  display: flex;
  justify-content: center;
  padding-top: ${convertPxToRem(50)};
`
const TooltipModalWrapperDescription = styled.div`
  ${text1_16};
  color: ${secondaryGrey};
  margin-top: ${convertPxToRem(16)};
  padding-bottom: ${convertPxToRem(20)};
`
const TooltipModalBackButtonWrapper = styled.div`
  position: absolute;
  top: ${convertPxToRem(18)};
  right: 0;
  left: 0;
  background-color: ${primaryWhite};
  display: flex;
  justify-content: flex-end;
  padding: ${convertPxToRem(0, 24, 10, 0)};
`
const Content = styled(TooltipModalWrapper)`
  position: relative;
`
const Wrapper = styled.div`
  max-height: ${convertPxToRem(450)};
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

import i18next from 'src/translations/i18n'

import {
  AssistanceFormErrorType,
  AssistanceFormInputType,
  GetAssistanceScreenType,
  RadioQuestionIds,
} from './types'

const i18n = {
  registeredWithRightway:
    'translation.registration.get_assistance_ineligible.form.registered_with_rightway',
  joinEmployer:
    'translation.registration.get_assistance_ineligible.form.join_employer',
  typeRecipient:
    'translation.registration.get_assistance_ineligible.form.type_recipient',
  yes: 'translation.shared.buttons.yes',
  no: 'translation.shared.buttons.no',
  employer: 'translation.registration.get_assistance_ineligible.form.employer',
  dependent:
    'translation.registration.get_assistance_ineligible.form.dependent',
  receivedCode:
    'translation.registration.get_assistance_verification_code.form.received_code',
  requestedAnotherCode:
    'translation.registration.get_assistance_verification_code.form.requested_another_code',
}

export const getAssistanceFormErrorsMessages = () => ({
  [AssistanceFormErrorType.EmailRequired]: {
    message: i18next.t('translation.registration.get_assistance.required'),
  },
  [AssistanceFormErrorType.Email]: {
    message: i18next.t(
      'translation.registration.get_assistance.form.email_warning',
    ),
  },
  [AssistanceFormErrorType.Phone]: {
    message: i18next.t(
      'translation.registration.get_assistance.form.phone_warning',
    ),
  },
})

export const applicableAssistanceErrors: Record<
  GetAssistanceScreenType,
  AssistanceFormErrorType[]
> = {
  deactivated: [AssistanceFormErrorType.Email, AssistanceFormErrorType.Phone],
  locked: [AssistanceFormErrorType.Email, AssistanceFormErrorType.Phone],
  ineligible: [
    AssistanceFormErrorType.EmailRequired,
    AssistanceFormErrorType.Email,
    AssistanceFormErrorType.Phone,
  ],
  universal: [
    AssistanceFormErrorType.EmailRequired,
    AssistanceFormErrorType.Email,
    AssistanceFormErrorType.Phone,
  ],
  [GetAssistanceScreenType.VerificationCode]: [],
}

export const assistanceInputsErrors = {
  [AssistanceFormInputType.Email]: [
    AssistanceFormErrorType.EmailRequired,
    AssistanceFormErrorType.Email,
  ],
  [AssistanceFormInputType.Phone]: [AssistanceFormErrorType.Phone],
}

const universalQuestions = [
  {
    questionTitle: i18n.registeredWithRightway,
    id: RadioQuestionIds.Registered,
    answers: [
      {
        title: i18n.yes,
        checked: false,
        value: true,
      },
      {
        title: i18n.no,
        checked: false,
        value: false,
      },
    ],
    answer: null,
  },
  {
    questionTitle: i18n.joinEmployer,
    id: RadioQuestionIds.JoinInTheLast30Days,
    answers: [
      {
        title: i18n.yes,
        checked: false,
        value: true,
      },
      {
        title: i18n.no,
        checked: false,
        value: false,
      },
    ],
    answer: null,
  },
  {
    questionTitle: i18n.typeRecipient,
    id: RadioQuestionIds.ReferredFrom,
    answers: [
      {
        title: i18n.employer,
        checked: false,
        value: 'employer',
      },
      {
        title: i18n.dependent,
        checked: false,
        value: 'dependent',
      },
    ],
    answer: null,
  },
]

const verificationCodeQuestions = [
  {
    questionTitle: i18n.receivedCode,
    id: RadioQuestionIds.VerificationCodeEmailInbox,
    answers: [
      {
        title: i18n.yes,
        checked: false,
        value: true,
      },
      {
        title: i18n.no,
        checked: false,
        value: false,
      },
    ],
    answer: null,
  },
  {
    questionTitle: i18n.requestedAnotherCode,
    id: RadioQuestionIds.VerificationCodeAnotherCode,
    answers: [
      {
        title: i18n.yes,
        checked: false,
        value: true,
      },
      {
        title: i18n.no,
        checked: false,
        value: false,
      },
    ],
    answer: null,
  },
]

export const assistanceRadioQuestions = {
  universal: universalQuestions,
  verificationCode: verificationCodeQuestions,
}

import { createEvent, sample } from 'effector'

import { $$geolocation, RequestMode } from 'src/entities/geolocation'
import { $$sessionStorage } from 'src/shared/storage/session'

export const modalClosed = createEvent<void>()
export const locationRequested = createEvent<void>()

sample({
  clock: modalClosed,
  target: $$sessionStorage.locationModalClosed,
})

sample({
  clock: locationRequested,
  fn: () => RequestMode.Manual,
  target: $$geolocation.coordinatesRequested,
})

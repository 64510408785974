import { AppointmentFacility } from '../../api/appointmentQuery/types'

import { AppointmentProfile } from './AppointmentProfile'

interface AppointmentFacilityInfoProps {
  facility: AppointmentFacility
}

export function AppointmentFacilityInfo({
  facility,
}: AppointmentFacilityInfoProps) {
  const { name, address, distance, cost } = facility

  return (
    <AppointmentProfile
      name={name}
      cost={cost.userPays}
      address={address}
      distance={distance}
      dataTestProp="appointmentFacilityProfile"
    />
  )
}

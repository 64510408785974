import {
  $navigate,
  navigateFx,
  backFx,
  navigateTo,
  navigateWithQueryTo,
  navigateWithStateTo,
} from './navigate'

export const $$navigate = {
  navigateFx,
  backFx,
  $fn: $navigate,

  to: navigateTo,
  withQueryTo: navigateWithQueryTo,
  withStateTo: navigateWithStateTo,
}

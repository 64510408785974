import React from 'react'

interface RefreshIconProps {
  className?: string
}

export function RefreshIcon({ className = undefined }: RefreshIconProps) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.28437 5.33124C3.525 4.64999 3.91562 4.00936 4.46562 3.46249C6.41875 1.50936 9.58438 1.50936 11.5375 3.46249L12.0719 3.99999H10.5C9.94687 3.99999 9.5 4.44686 9.5 4.99999C9.5 5.55311 9.94687 5.99999 10.5 5.99999H14.4844H14.4969C15.05 5.99999 15.4969 5.55311 15.4969 4.99999V0.999988C15.4969 0.446863 15.05 -1.21594e-05 14.4969 -1.21594e-05C13.9437 -1.21594e-05 13.4969 0.446863 13.4969 0.999988V2.59999L12.95 2.04999C10.2156 -0.684387 5.78438 -0.684387 3.05 2.04999C2.2875 2.81249 1.7375 3.70936 1.4 4.66874C1.21562 5.19061 1.49063 5.75936 2.00938 5.94374C2.52813 6.12811 3.1 5.85311 3.28437 5.33436V5.33124ZM1.21875 8.04061C1.0625 8.08749 0.9125 8.17186 0.790625 8.29686C0.665625 8.42186 0.58125 8.57186 0.5375 8.73436C0.528125 8.77186 0.51875 8.81249 0.5125 8.85311C0.503125 8.90624 0.5 8.95936 0.5 9.01249V13C0.5 13.5531 0.946875 14 1.5 14C2.05313 14 2.5 13.5531 2.5 13V11.4031L3.05 11.95C5.78438 14.6812 10.2156 14.6812 12.9469 11.95C13.7094 11.1875 14.2625 10.2906 14.6 9.33436C14.7844 8.81249 14.5094 8.24374 13.9906 8.05936C13.4719 7.87499 12.9 8.14999 12.7156 8.66874C12.475 9.34999 12.0844 9.99061 11.5344 10.5375C9.58125 12.4906 6.41563 12.4906 4.4625 10.5375L4.45937 10.5344L3.925 9.99999H5.5C6.05312 9.99999 6.5 9.55311 6.5 8.99999C6.5 8.44686 6.05312 7.99999 5.5 7.99999H1.5125C1.4625 7.99999 1.4125 8.00311 1.3625 8.00936C1.3125 8.01561 1.26562 8.02499 1.21875 8.04061Z"
        fill="#2064D2"
      />
    </svg>
  )
}

import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { modalBlackOpacity, primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function Modal({ width = convertPxToRem(270), children }) {
  return (
    <Root>
      <Content width={width}>{children}</Content>
    </Root>
  )
}

export default Modal

Modal.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
}

const Root = styled.div`
  position: fixed;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${modalBlackOpacity};
  padding: ${convertPxToRem(0, 15)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
`

const Content = styled.div`
  background-color: ${primaryWhite};
  width: ${({ width }) => width && width};
  display: flex;
  margin: auto;
  flex-direction: column;
  border-radius: ${convertPxToRem(14)};
  position: relative;
`

import { useMemo } from 'react'

import { useNavigate, useLocation, WindowLocation } from '@reach/router'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import HeaderNavDarkTheme from 'src/components/HeaderNavs/HeaderNavDarkTheme'
import { ROUTES } from 'src/constants/routesConstants'
import { ContentStepType, StepData, Steps } from 'src/features/Steps'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import {
  featurePaleYellow,
  primaryDarkNavy,
  primaryWhite,
} from 'src/theme/colors'
import { heading2_29, heading6_16, text1_16, text2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface LocationState {
  drugName: string
}

export function DrugSearchIntro() {
  const {
    state: { drugName },
  } = useLocation() as WindowLocation<LocationState>

  const navigate = useNavigate()
  const t = useScopedTranslation('translation.medications.drug_search.intro')

  const i18n = {
    headerTitle: t('title'),
    headerSubTitle: t('subtitle'),
    contentTitle: t('steps_title'),
    buttonTitle: t('button_title'),
  }

  const steps: StepData[] = useMemo(
    () => [
      {
        title: t('steps.step_1.title'),
        content: [
          {
            type: ContentStepType.Text,
            content: t('steps.step_1.content'),
          },
        ],
      },
      {
        title: t('steps.step_2.title'),
        content: [
          {
            type: ContentStepType.Text,
            content: t('steps.step_2.content'),
          },
        ],
      },
      {
        title: t('steps.step_3.title'),
        content: [
          {
            type: ContentStepType.Text,
            content: t('steps.step_3.content'),
          },
        ],
      },
    ],
    [t],
  )

  const navigateToConfiguration = () => {
    void navigate(ROUTES.DRUG_SEARCH_CONFIGURE, {
      state: { drugName, isPreconfigured: false },
      replace: true,
    })
  }

  return (
    <DarkWrapper>
      <HeaderNavDarkTheme isLeftBtnClose onClose={navigateToConfiguration} />

      <Header>
        <Title>{i18n.headerTitle}</Title>
        <SubTitle>{i18n.headerSubTitle}</SubTitle>
      </Header>

      <ContentTitle>{i18n.contentTitle}</ContentTitle>

      <StyledSteps steps={steps} theme="dark" />

      <ForwardButtonWrapper>
        <ButtonBottom
          theme="pbm"
          title={i18n.buttonTitle}
          handleClick={navigateToConfiguration}
        />
      </ForwardButtonWrapper>
    </DarkWrapper>
  )
}

const DarkWrapper = styled.div`
  padding: ${convertPxToRem(40)};
  margin: ${convertPxToRem(40)};
  background-color: ${primaryDarkNavy};
  color: ${primaryWhite};

  display: flex;
  flex-flow: column nowrap;

  border-radius: ${convertPxToRem(8)};

  flex: 1 0 max-content;

  ${mediaQueryFor.tabletOrLess} {
    margin: ${convertPxToRem(24)};
  }

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 42)};
    margin: 0;
    border-radius: 0;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(66, 0, 32)};

  gap: ${convertPxToRem(10)};

  ${mediaQueryFor.mobile} {
    padding-bottom: ${convertPxToRem(24)};
  }
`

const ForwardButtonWrapper = styled.div`
  flex: 0 0 ${convertPxToRem(76)};

  display: flex;
  align-items: end;

  ${mediaQueryFor.tabletOrLess} {
    flex-grow: 1;
  }
`

const Title = styled.h1`
  ${heading2_29};
`

const SubTitle = styled.div`
  ${text1_16};
`

const ContentTitle = styled.h2`
  padding-bottom: ${convertPxToRem(16)};
  color: ${featurePaleYellow};
  ${heading6_16};
`

const StyledSteps = styled(Steps)`
  ${text2_14};
  width: 100%;

  &[data-theme='dark'] > h6 {
    color: ${featurePaleYellow};
  }
`

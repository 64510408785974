import { TypedDocumentNode, gql } from '@apollo/client'

import {
  DrugSearchPricesQueryResult,
  DrugSearchPricesQueryVariables,
} from './types'

export const DRUG_SEARCH_PRICES_QUERY: TypedDocumentNode<
  DrugSearchPricesQueryResult,
  DrugSearchPricesQueryVariables
> = gql`
  query drugSearchPricesQuery(
    $ndc: String
    $gpi: String
    $days_supply: Int
    $quantity: Float
  ) {
    drugSearchPrices: pdsPrices(
      ndc: $ndc
      gpi: $gpi
      days_supply: $days_supply
      quantity: $quantity
    ) {
      disclaimers {
        text
        type
      }
      recommendedPharmacies: recommended {
        ...drugPrice
      }
      otherPharmacies: other {
        ...drugPrice
      }
      preferredPharmacy: preferred {
        ...drugPrice
      }
      rejection {
        ...drugRejection
      }
      specialtyPharmacies: specialty {
        ...drugPrice
      }
    }
  }

  fragment drugPrice on DrugPrice {
    isBestPrice: is_best_price
    patientPay: patient_pay
    insurancePay: insurance_pay
    isRejected: is_rejected
    pharmacy {
      ...drugPricesPharmacy
    }
    priceId: price_id
  }

  fragment drugPricesPharmacy on Pharmacies {
    addressLine1: address_line1
    city
    deliveryTime: delivery_time
    daysSupply: days_supply
    pharmacyType: pharmacy_type
    latitude
    longitude
    nabp
    name
    pharmacySteps: pharmacy_steps {
      ...pharmacySteps
    }
    state
    telephoneNumber: telephone_number
    workSchedule: work_schedule {
      ...workSchedule
    }
    zip
  }

  fragment workSchedule on WorkSchedule {
    closeHour: close_hour
    dayOfWeek: day_of_week
    openHour: open_hour
  }

  fragment pharmacySteps on PharmacySteps {
    content {
      ...pharmacyStepContent
    }
    title
  }

  fragment pharmacyStepContent on PharmacyStepContent {
    content
    type
    url
  }

  fragment drugRejection on DrugRejection {
    rejectDescription: reject_description
    rejectMessage: reject_message
    rejectType: reject_type
  }
`

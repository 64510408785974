import { useMemo } from 'react'

import { getManifestFile } from 'src/utils/currentUserHelpers'

interface UseDynamicManifestParams {
  faviconUrl: string
  title: string
}

function useDynamicManifest({
  faviconUrl,
  title,
}: UseDynamicManifestParams): string {
  const manifest = useMemo(
    () => getManifestFile({ faviconUrl, title }),
    [faviconUrl, title],
  )

  return manifest
}

export default useDynamicManifest

import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import NavigatorDarkTheme from 'src/components/NavigatorIcons/NavigatorIconDarkTheme'
import ButtonBack40 from 'src/shared/ui/buttons/ButtonBack40'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

/**
 * @typedef {Object} HeaderNavDarkThemeProps
 * @prop {() => void=} onClose
 * @prop {() => void=} onBack
 * @prop {boolean} [isLeftBtnClose=false]
 */

/**
 * @param {HeaderNavDarkThemeProps} props
 */
function HeaderNavDarkTheme({
  onClose = null,
  onBack = null,
  isLeftBtnClose = false,
}) {
  const navigate = useNavigate()

  const handleClose = () => {
    if (onClose) onClose()
    else void navigate(-1)
  }

  const handleBack = () => {
    if (onBack) onBack()
    else void navigate(-1)
  }

  return (
    <Root>
      {isLeftBtnClose ? (
        <ButtonClose40
          onClick={handleClose}
          fill={primaryWhite}
          bgColor="none"
          border="none"
          width="auto"
        />
      ) : (
        <ButtonBack40 onClick={handleBack} isDarkTheme />
      )}

      <ButtonNavigatorWrapper>
        <NavigatorDarkTheme />
      </ButtonNavigatorWrapper>

      <ButtonCallWrapper>
        <ButtonCall40 fill={primaryWhite} bgColor="none" width="auto" />
      </ButtonCallWrapper>
    </Root>
  )
}

export default HeaderNavDarkTheme

HeaderNavDarkTheme.propTypes = {
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  isLeftBtnClose: PropTypes.bool,
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${convertPxToRem(40)};
`

const ButtonNavigatorWrapper = styled.div`
  ${mediaQueryFor.desktop} {
    display: none;
  }
`

const ButtonCallWrapper = styled.div`
  ${mediaQueryFor.desktop} {
    display: none;
  }
`

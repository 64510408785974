import { TypedDocumentNode, gql } from '@apollo/client'

import { PHARMACY_FRAGMENT } from '@entities/pharmacy'

import { PharmaciesResult, PharmaciesVariables } from './types'

export const DRUG_SEARCH_PHARMACIES_QUERY: TypedDocumentNode<
  PharmaciesResult,
  PharmaciesVariables
> = gql`
  query pharmacies($latitude: Float!, $longitude: Float!) {
    pharmacies: pharmacySearch(latitude: $latitude, longitude: $longitude) {
      ...pharmacy
    }
  }

  ${PHARMACY_FRAGMENT}
`

/**
 * @deprecated Use `FeatureFlag` from 'src/entities/features' instead.
 */
export enum FeatureFlag {
  CARE_LOCATOR = 'find_care',
  RATINGS = 'ratings',
  TELEMEDICINE = 'telemedicine',
  PROFILE = 'profile',
  PBM = 'pbm',
  PbmAccumulator = 'pbm_accumulator',
  PBM_DRUG_SEARCH = 'pbm_drug_search',
  CORE_NAV = 'core',
  MED_CABINET = 'medicine_cabinet',
  MY_CARE_TEAM = 'my_care_team',
  CARE_LOCATOR_NET_FILTER = 'care_locator_net_filter',
  SHARED_SETTINGS = 'shared_settings',
  SHARED_INSURANCE_BENEFITS = 'shared_insurance_benefits',
  SHARED_PROFILE = 'shared_profile',
  SHARED_ACTIVITY_FEED = 'shared_activity_feed',
  BILL_ADVOCACY = 'bill_advocacy',
  SHARED_SESSION_TIMEOUT = 'shared_session_timeout',
  GET_CARE_SECTION_NAVIGATION = 'get_care_section_navigation',
  CARE_PLANS = 'care_plans',
  APPOINTMENTS = 'appointments',
  HEALTH_PROFILE = 'shared_health_profile',
  VisionCoverage = 'shared_vision_coverage',
  DentalCoverage = 'shared_dental_coverage',
  PharmacyCoverage = 'shared_pharmacy_coverage',
  WELLNESS = 'wellness',
  CORE_INSURANCE_CARD = 'core_insurance_card',
  EARLY_ACCESS = 'early_access',
  ClearCost = 'clear_cost',
}

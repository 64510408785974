/* eslint-disable no-param-reassign */
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum THIRD_SECTION_CONTENT {
  CHAT = 'CHAT',
  SELECT_PHONE = 'SELECT_PHONE',
}

export interface IMobileTabLink {
  id: string
  to: string
  tabTitle: string
  icon: string
  section: string
}

interface IMainLink {
  to: string
  icon: string
  title: string
  section: string
}

interface ICommonSlice {
  isShowThirdSectionModal: boolean
  isShowGuidesPhoneNumberModal: boolean
  isInitialDataReceived: boolean

  hasMobileToolbar: boolean

  thirdSectionContent: THIRD_SECTION_CONTENT
  activeSection: string
  homePage: string
  availableRoutes: {
    homeTabsDataMobile: IMobileTabLink[]
    mainLinks: IMainLink[]
  }
}

const initialState: ICommonSlice = {
  isShowThirdSectionModal: false,
  isShowGuidesPhoneNumberModal: false,
  isInitialDataReceived: false,
  hasMobileToolbar: true,
  thirdSectionContent: THIRD_SECTION_CONTENT.CHAT,
  activeSection: '',
  homePage: '',
  availableRoutes: { homeTabsDataMobile: [], mainLinks: [] },
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsShowThirdSectionModal(state, { payload }: PayloadAction<boolean>) {
      state.isShowThirdSectionModal = payload
    },
    setThirdSectionContent(
      state,
      { payload }: PayloadAction<THIRD_SECTION_CONTENT>,
    ) {
      state.thirdSectionContent = payload
    },
    setIsShowGuidesPhoneNumberModal(
      state,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isShowGuidesPhoneNumberModal = payload
    },
    setIsInitialDataReceived(state, { payload }: PayloadAction<boolean>) {
      state.isInitialDataReceived = payload
    },
    setActiveSection(state, { payload }: PayloadAction<string>) {
      state.activeSection = payload
    },
    setAvailableRoutes(
      state,
      { payload }: PayloadAction<ICommonSlice['availableRoutes']>,
    ) {
      state.availableRoutes = payload
    },
    setHomePage(state, { payload }: PayloadAction<string>) {
      state.homePage = payload
    },
    turnOffMobileToolbar(state) {
      state.hasMobileToolbar = false
    },
  },
})

export const {
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  setIsShowGuidesPhoneNumberModal,
  setActiveSection,
  setIsInitialDataReceived,
  setAvailableRoutes,
  setHomePage,
  turnOffMobileToolbar,
} = commonSlice.actions

export const clearStore = createAction('common/clearStore')

export default commonSlice.reducer

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import MEMBER_PROFILE_QUERY from 'src/apollo/queries/MEMBER_PROFILE_QUERY'
import { extractProfileData } from 'src/sections/SectionProfile/profileHelpers'

function useMemberProfileQuery({
  isSkipRequest = false,
  isSkipError = false,
} = {}) {
  const { data, loading, error } = useQuery(MEMBER_PROFILE_QUERY, {
    skip: isSkipRequest,
    context: { isSkipError },
  })

  const profileData = useMemo(() => extractProfileData(data), [data])

  return {
    profileData: profileData || {},
    profileLoading: loading,
    error,
  }
}

export default useMemberProfileQuery

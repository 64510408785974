import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function PrimaryCareIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryDarkNavy,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M16.006 12.692c-.126.01-.25.016-.381.016s-.255-.005-.381-.016c-.117-.01-.232.029-.318.108-.086.08-.135.191-.135.308v.85c0 2.417-1.958 4.375-4.375 4.375-2.416 0-4.375-1.958-4.375-4.375v-2.366c2.403-.409 4.162-2.488 4.167-4.925v-2.5c-.004-1.48-.984-2.78-2.406-3.19-.056-.015-.094-.066-.094-.124v-.02c0-.46-.373-.833-.833-.833-.46 0-.834.373-.834.833v2.084c0 .46.373.833.834.833.46 0 .833-.373.833-.833 0-.038.021-.073.055-.09.034-.016.074-.013.104.01.423.31.673.804.674 1.33v2.5C8.541 8.507 7.05 10 5.208 10c-1.84 0-3.333-1.492-3.333-3.333v-2.5c0-.527.25-1.022.674-1.334.03-.022.07-.026.104-.009.035.018.056.054.055.093 0 .46.373.833.833.833.46 0 .834-.373.834-.833V.833C4.375.373 4.002 0 3.54 0c-.46 0-.833.373-.833.833v.02c0 .058-.038.109-.094.125C1.192 1.387.212 2.687.208 4.167v2.5c.005 2.437 1.764 4.516 4.167 4.925v2.366C4.375 17.295 7.08 20 10.416 20c3.337 0 6.042-2.705 6.042-6.042v-.85c0-.117-.048-.23-.134-.309-.087-.079-.202-.118-.319-.108z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.458 7.708c0-2.3 1.865-4.166 4.167-4.166 2.3.002 4.164 1.866 4.166 4.166 0 2.301-1.865 4.167-4.166 4.167-2.302 0-4.167-1.866-4.167-4.167zm1.667 0c0 1.381 1.119 2.5 2.5 2.5.663 0 1.299-.263 1.767-.732.47-.469.733-1.105.733-1.768 0-1.38-1.12-2.5-2.5-2.5-1.381 0-2.5 1.12-2.5 2.5z"
        clipRule="evenodd"
      />
      <path
        fill={fill}
        d="M15.625 8.958c.69 0 1.25-.56 1.25-1.25s-.56-1.25-1.25-1.25-1.25.56-1.25 1.25.56 1.25 1.25 1.25z"
      />
    </svg>
  )
}

export default PrimaryCareIcon

import { gql } from '@apollo/client'

const CHAT_MESSAGE_FRAGMENT = gql`
  fragment chatMessageFragment on ChatMessage {
    body
    id
    is_self_sent
    updated_at
    created_at
    receiver_read_at
    attachment {
      ... on ChatMessageFileType {
        content_type
        size
        short_url
        scan_status
      }
      ... on SystemNotificationType {
        message
      }
    }
  }
`

export default CHAT_MESSAGE_FRAGMENT

import { ReactElement, Children, cloneElement } from 'react'

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { primaryWhite, primaryBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { HoverButtonItemProps, hoverButtonItemFactory } from './HoverButtonItem'

interface HoverButtonProps {
  className?: string
  dataTestProp?: string
  children:
    | ReactElement<HoverButtonItemProps>
    | ReactElement<HoverButtonItemProps>[]
}

export function HoverButton({
  className = '',
  dataTestProp = 'hoverButton',
  children,
}: HoverButtonProps) {
  const isWide = Children.count(children) === 1

  return (
    <Root className={className} data-test={dataTestProp}>
      {Children.map(children, (child) => (
        <>
          <Splitter />
          {cloneElement(child, { isWide })}
        </>
      ))}
    </Root>
  )
}

HoverButton.Map = hoverButtonItemFactory({
  icon: () => <Sprite name="map/locator" />,
  text: {
    wide: 'map',
    short: 'map_short',
  },
})

HoverButton.Filter = hoverButtonItemFactory({
  icon: () => <Sprite name="misc/filter" />,
  text: {
    wide: 'filter',
    short: 'filter_short',
  },
})

HoverButton.List = hoverButtonItemFactory({
  icon: () => <Sprite name="misc/list" />,
  text: {
    wide: 'list',
    short: 'list_short',
  },
})

const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: ${primaryBlue};
  border-radius: ${convertPxToRem(36)};

  & svg {
    color: ${primaryWhite};

    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};
  }
`
const Splitter = styled.div`
  width: ${convertPxToRem(1)};
  background-color: ${primaryWhite};
  height: ${convertPxToRem(24)};
  :first-child {
    display: none;
  }
`

import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Sprite } from '@shared/ui/Sprite'

import { UniversalHeader } from '@widgets/Navigation'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { ContentsRootBase } from 'src/theme/baseStyles'
import {
  primaryWhite,
  primaryDarkNavy,
  secondaryLightGrey,
  featurePaleBlue,
} from 'src/theme/colors'
import { text1_16, capsHeading1_12, heading2_29 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ModalTelemed({
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  homePage = '',
}) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.home.talk_with_doctor.title'),
    subtitle: t('translation.home.talk_with_doctor.sub_title'),
    firstDescription: t('translation.home.talk_with_doctor.description_1'),
    secondDescription: t('translation.home.talk_with_doctor.description_2'),
    thirdDescription: t('translation.home.talk_with_doctor.description_3'),
    buttonText: t('translation.chat.inbox.without_messages.button_chat'),
  }

  const navigate = useNavigate()

  const navigateBack = () => {
    navigate(`/${homePage}`)
  }
  const handleClick = () => {
    showThirdSectionModal()
    openNewChat()
  }
  return (
    <Wrapper>
      <Root>
        <BgOverlay />
        <Content>
          <UniversalHeader theme="dark" onClick={navigateBack} />

          <Header>
            <Title>{i18n.subtitle.toUpperCase()}</Title>
            <WebCameraIcon>
              <Sprite name="services/telemedicine" />
            </WebCameraIcon>
          </Header>

          <ContentTitle>{i18n.title}</ContentTitle>

          <Description>{i18n.firstDescription}</Description>
          <Description>{i18n.secondDescription}</Description>
          <Description>{i18n.thirdDescription}</Description>
        </Content>
        <ButtonBottomWrapper onClick={handleClick}>
          <ButtonBottom theme="carePlan" title={i18n.buttonText} />
        </ButtonBottomWrapper>
      </Root>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertPxToRem(40)};

  ${mediaQueryFor.mobile} {
    padding: 0;
  }
`
const Root = styled.div`
  ${ContentsRootBase};
  border-radius: ${convertPxToRem(8)};

  ${mediaQueryFor.mobile} {
    border-radius: 0;
  }
`
const Content = styled.div`
  ${ContentsRootBase};
  padding: ${convertPxToRem(40, 40, 200)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 150)};
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${convertPxToRem(45)};
  margin-bottom: ${convertPxToRem(25)};
`
const Title = styled.div`
  color: ${secondaryLightGrey};
  padding-bottom: ${convertPxToRem(23)};
  ${capsHeading1_12};
`

const WebCameraIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  border-radius: 50%;

  background-color: ${featurePaleBlue};

  & > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};

    color: ${primaryDarkNavy};
  }
`

const ContentTitle = styled.div`
  color: ${primaryWhite};
  padding-bottom: ${convertPxToRem(13)};
  ${heading2_29};
`
const Description = styled.div`
  padding-bottom: ${convertPxToRem(22)};
  color: ${primaryWhite};
  ${text1_16}
`
const BgOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${primaryDarkNavy};
`

const ButtonBottomWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(45)};
  left: 0;
  right: 0;
  padding: ${convertPxToRem(0, 40)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(0, 24)};
  }
`

const mapStateToProps = ({ common }) => ({
  homePage: common.homePage,
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalTelemed)

import type { FieldPolicy, Reference } from '@apollo/client'
import uniqBy from 'lodash/uniqBy'

interface ActivityFeedCacheEntry {
  nodes: Reference[]
  pageInfo: {
    endCursor: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor: string
    totalCount: string
  }
}

/**
 * A cache policy for ActivityFeedQuery.
 *
 * 1. ActivityFeedQuery is only grouped by filters, not 'cursor' or 'first'
 * 2. When we fetchMore, add cards to the end of the list, but ignore duplicates
 */
export const ActivityFeedPolicy: FieldPolicy<ActivityFeedCacheEntry> = {
  keyArgs: ['filters'],
  merge(existing, incoming) {
    if (!existing) {
      // This is the first 'page'
      return incoming
    }

    const cards = uniqBy([...existing.nodes, ...incoming.nodes], '__ref')

    return {
      nodes: cards,
      pageInfo: incoming.pageInfo,
    }
  },
}

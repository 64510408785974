import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export default function MailIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryDarkNavy,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)" fill={fill}>
        <path d="M9.262 12.13a1.043 1.043 0 0 0 1.473 0l8.072-8.072a.417.417 0 0 0-.235-.707 1.64 1.64 0 0 0-.239-.018H1.667c-.081 0-.161.006-.241.018a.417.417 0 0 0-.234.707l8.07 8.072z" />
        <path d="M19.907 4.86a.152.152 0 0 0-.167.032l-5.167 5.167a.21.21 0 0 0 0 .295l4.203 4.203a.625.625 0 1 1-.884.884l-4.202-4.203a.208.208 0 0 0-.295 0l-1.774 1.774a2.292 2.292 0 0 1-3.24 0L6.605 11.24a.21.21 0 0 0-.295 0l-4.202 4.203a.625.625 0 0 1-.884-.885l4.203-4.202a.21.21 0 0 0 0-.295L.26 4.894a.15.15 0 0 0-.167-.032A.152.152 0 0 0 0 5v10c0 .92.746 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V5a.151.151 0 0 0-.093-.14z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

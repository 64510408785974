export enum GetAssistanceScreenType {
  Universal = 'universal',
  Deactivated = 'deactivated',
  Ineligible = 'ineligible',
  Locked = 'locked',
  VerificationCode = 'verification_code',
}

export enum AssistanceFormInputType {
  Email = 'email',
  Phone = 'phone',
  CompanyName = 'companyName',
  Name = 'name',
}

export type ValidationInputType =
  | AssistanceFormInputType.Email
  | AssistanceFormInputType.Phone

export enum RadioQuestionIds {
  Registered = 'registered',
  JoinInTheLast30Days = 'join_in_the_last_30_days',
  ReferredFrom = 'referred_from',
  VerificationCodeEmailInbox = 'verification_code_email_inbox',
  VerificationCodeAnotherCode = 'verification_code_another_code',
}

export interface AssistanceFormRadioQuestion {
  questionTitle: string
  id: RadioQuestionIds
  answers: AssistanceFormAnswerItem[]
  answer: boolean | string | null
}

export interface AssistanceFormRadioAnswers {
  [RadioQuestionIds.Registered]?: boolean | string | null
  [RadioQuestionIds.JoinInTheLast30Days]?: boolean | string | null
  [RadioQuestionIds.ReferredFrom]?: boolean | string | null
  [RadioQuestionIds.VerificationCodeEmailInbox]?: boolean | string | null
  [RadioQuestionIds.VerificationCodeAnotherCode]?: boolean | string | null
}

export interface AssistanceFormResponses extends AssistanceFormRadioAnswers {
  name?: string
  email?: string
  companyName?: string
  phone?: string
}

export interface AssistanceFormCheckedAnswerEvent {
  id: string
  value: boolean | string | null
}

interface AssistanceFormAnswerItem {
  title: string
  checked: boolean
  value: boolean | string | null
}

export interface AssistanceFormChangedValueEvent {
  value: string
  type: AssistanceFormInputType
}

interface RegistrationFeedbackMutationResult {
  data?: { registrationLookupFeedback: boolean }
}

interface RegistrationFeedbackMutationParams {
  variables: {
    request_identifier: string | null
    data: RegistrationFeedbackMutationData
  }
}

export interface RegistrationFeedbackMutationData {
  phone_number?: string | null
  email: string
  assistance_type?: string | null
  organization_name?: string | null
  any_other_names?: string | null
}

export interface GetAssistanceGateProps {
  email: string
  requestIdentifier: string
  requiredIdentifiers: {
    first_name?: string
    last_name?: string
    dob?: string
    ssn?: string
    member_id?: string
  }
  screenType: GetAssistanceScreenType
  registrationFeedbackMutation: (
    p: RegistrationFeedbackMutationParams,
  ) => Promise<RegistrationFeedbackMutationResult>
  navigateToSuccessScreen: () => void
}

export interface AssistanceFormErrors {
  emailRequired?: { message: string }
  email?: { message: string }
  phone?: { message: string }
}

export enum AssistanceFormErrorType {
  EmailRequired = 'emailRequired',
  Email = 'email',
  Phone = 'phone',
}

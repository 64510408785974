import React, { useState, MouseEventHandler, useCallback } from 'react'

import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { TermsOfService } from 'src/entities/termsOfServices'
import { TermsAgreement } from 'src/sections/SectionRegistration/pages/TermsOfService/components/TermsAgreement'
import { TermsModal } from 'src/sections/SectionRegistration/pages/TermsOfService/components/TermsModal'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import ButtonBack40 from 'src/shared/ui/buttons/ButtonBack40'
import { Checkbox } from 'src/shared/ui/Checkbox'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import { heading2_29, text1_16, text3_12 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TermsOfServiceProps {
  navigateBack?: () => void
  onSubmit: () => void
  isLoading: boolean
  terms: TermsOfService
}

export function TermsOfServiceContent({
  navigateBack = undefined,
  onSubmit,
  isLoading,
  terms,
}: TermsOfServiceProps) {
  const t = useScopedTranslation('translation.registration.registration_terms')

  const [modalData, setModalData] = useState<string | null>(null)

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)

  const closeModal = () => setModalData(null)

  const toggleHasAcceptedTerms: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      setHasAcceptedTerms((hasAccepted) => !hasAccepted)
    },
    [],
  )

  const {
    agreement,
    agreements,
    introduction,
    title,
    shouldBeAccepted,
    callToAction,
  } = terms

  const isAcceptancePending = shouldBeAccepted && !hasAcceptedTerms

  return (
    <Root>
      <ButtonWrapper>
        {navigateBack && <ButtonBack40 onClick={navigateBack} />}
      </ButtonWrapper>

      <ContentWrapper>
        <HeaderWrapper>
          <Title>{title}</Title>
          <Description>{introduction}</Description>
        </HeaderWrapper>
        <TermsContent>
          {agreements.map((agr) => (
            <TermsAgreement
              key={agr.id}
              agreement={agr}
              setModalData={setModalData}
            />
          ))}

          {shouldBeAccepted && (
            <AcceptContent>
              <CheckboxWrapper
                data-test="checkBox"
                onClick={toggleHasAcceptedTerms}
              >
                <Checkbox checked={hasAcceptedTerms} />
              </CheckboxWrapper>
              <Note>{agreement}</Note>
            </AcceptContent>
          )}
        </TermsContent>

        {!!modalData && <TermsModal id={modalData} closeModal={closeModal} />}
      </ContentWrapper>

      <ButtonBottomWrapper>
        <ButtonBottom
          disabled={isAcceptancePending || isLoading}
          loading={isLoading}
          handleClick={onSubmit}
          theme="primary"
          title={callToAction ?? t('btn_agree')}
        />
      </ButtonBottomWrapper>
    </Root>
  )
}

const Root = styled.div`
  flex: 1;
  position: relative;
  ${mediaQueryFor.mobile} {
    position: static;
  }
`

const ButtonWrapper = styled.div`
  padding-bottom: ${convertPxToRem(53)};
  min-height: ${convertPxToRem(93)};

  ${mediaQueryFor.mobile} {
    position: absolute;
    left: ${convertPxToRem(32)};
    right: 0;
    top: 0;
    padding: ${convertPxToRem(40, 0, 10)};
    background-color: ${primaryWhite};
    z-index: 1;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  padding-bottom: ${convertPxToRem(50)};

  max-height: ${convertPxToRem(490)};
  ${mediaQueryFor.mobile} {
    max-height: none;
    padding-top: ${convertPxToRem(93)};
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${convertPxToRem(15)};
`

const TermsContent = styled.div`
  padding-bottom: ${convertPxToRem(20)};
`
const Title = styled.h1`
  color: ${primaryDarkNavy};
  ${heading2_29};
`

const Description = styled.span`
  margin-top: ${convertPxToRem(10)};
  color: ${secondaryGrey};
  ${text1_16};
`

const Note = styled.span`
  color: ${primaryDarkNavy};
  ${text3_12};
  padding-left: ${convertPxToRem(20)};
`

const AcceptContent = styled.div`
  padding: ${convertPxToRem(0, 0, 20, 20)};
  display: flex;
  flex-direction: row;
`

const CheckboxWrapper = styled.div`
  position: relative;
  padding-right: ${convertPxToRem(20)};
`

const ButtonBottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  box-shadow: ${convertPxToRem(0, -6, 30, 30)} ${secondaryBackgroundGrey};
  border-radius: ${convertPxToRem(36)};

  ${mediaQueryFor.mobile} {
    right: ${convertPxToRem(32)};
    left: ${convertPxToRem(32)};
    bottom: ${convertPxToRem(28)};
    box-shadow: ${convertPxToRem(0, 10, 15, 30)} ${secondaryBackgroundGrey};
  }
`

import { createApi, createStore } from 'effector'
import { persist } from 'effector-storage/local'

export interface DrugSearchConfiguration {
  drugName: string
  isDrugCovered: boolean
  config: {
    daysSupply: string
    daysSupplyCustom: string
    form: string
    dosage: string
    typeOfQuantity: string
    quantity: string
    ndc: string
    gpi: string
  }
}

type DrugSearchStorageState = DrugSearchConfiguration[]

const $state = createStore<DrugSearchStorageState>([])

const api = createApi($state, {
  reset: () => $state.defaultState,

  configSaved: (currentState, newConfig: DrugSearchConfiguration) => [
    ...currentState.filter(({ drugName }) => drugName !== newConfig.drugName),
    newConfig,
  ],

  configRemoved: (currentState, targetDrug: string) => [
    ...currentState.filter(({ drugName }) => drugName !== targetDrug),
  ],
})

persist({ store: $state, key: 'drugSearchConfig' })

export const $$drugSearchConfigurationStorage = {
  $state,
  ...api,
  '@@unitShape': () => ({
    saveConfig: api.configSaved,
    removeConfig: api.configRemoved,
    clearConfigs: api.reset,
  }),
}

import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface BackIconProps {
  width?: string
  height?: string
  fill?: string
}

function BackIcon({
  width = convertPxToRem(24),
  height = convertPxToRem(24),
  fill = 'none',
}: BackIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M19 12H5M12 19l-7-7 7-7"
      />
    </svg>
  )
}

export default BackIcon

import { MouseEventHandler } from 'react'

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { Formatter } from 'src/shared/lib/formatters'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  secondaryBackgroundGrey,
  secondaryGrey,
} from 'src/theme/colors'
import {
  capsHeading1_12,
  heading3_25,
  heading6_16_Medium,
  text3_12,
} from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TooltipContentProps {
  patientPay: string
  insurancePay: string | null
  additionalInfo?: string
  isLoading?: boolean
  closeCostTooltip: MouseEventHandler<HTMLElement>
}

export function TooltipContent({
  closeCostTooltip,
  patientPay,
  insurancePay,
  isLoading = false,
  additionalInfo = '',
}: TooltipContentProps) {
  const t = useScopedTranslation(
    'translation.medications.pricing_tab.mc_tooltip',
  )

  if (isLoading)
    return (
      <Root>
        <Spinner />
      </Root>
    )

  return (
    <Root>
      <Header>
        <Info>
          <Title>{t('price_estimate')}</Title>
          {/* @ts-expect-error format also accepts strings */}
          <Cost>{Formatter.currency.format(patientPay)}</Cost>
        </Info>

        <NavButton data-test="close.price.tooltip" onClick={closeCostTooltip}>
          <Sprite name="nav/close" />
        </NavButton>
      </Header>

      <Content>
        <SectionTitle>{t('cost_breakdown')}</SectionTitle>

        {!!insurancePay && (
          <SectionItem>
            <SectionText>{t('your_plan_pays')}</SectionText>
            {/* @ts-expect-error format also accepts strings */}
            <SectionCost>{Formatter.currency.format(insurancePay)}</SectionCost>
          </SectionItem>
        )}

        <SectionItem>
          <SectionText>{t('you_pay')}</SectionText>
          {/* @ts-expect-error format also accepts strings */}
          <SectionCost>{Formatter.currency.format(patientPay)}</SectionCost>
        </SectionItem>

        {Boolean(additionalInfo) && <Note>{additionalInfo}</Note>}
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: ${convertPxToRem(24, 36, 32)};
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(39, 12, 51)};
  }
`
const Content = styled.div`
  background-color: ${secondaryBackgroundGrey};
  padding: ${convertPxToRem(16, 18)};
  border-radius: ${convertPxToRem(8)};
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${convertPxToRem(25)};
  padding: ${convertPxToRem(0, 12)};
  width: 100%;
`
const Info = styled.div``
const Title = styled.div`
  color: ${primaryDarkNavy};
  ${text3_12};
`
const Cost = styled.div`
  color: ${primaryDarkNavy};
  ${heading3_25};
`
const SectionTitle = styled.div`
  color: ${secondaryGrey};
  ${capsHeading1_12};
  text-transform: uppercase;
  margin-bottom: ${convertPxToRem(11)};
`
const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${convertPxToRem(5)};
`
const SectionText = styled.span`
  color: ${primaryDarkNavy};
  ${text3_12};
`
const SectionCost = styled.span`
  color: ${primaryDarkNavy};
  ${heading6_16_Medium};
`
const Note = styled.span`
  display: flex;
  padding-top: ${convertPxToRem(20)};
  color: ${secondaryGrey};
  ${text3_12};
`

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'

interface InvalidAttachmentAlertProps {
  close: () => void
}

export function InvalidAttachmentAlert({ close }: InvalidAttachmentAlertProps) {
  const t = useScopedTranslation('translation.shared')

  return (
    <NativeAlert
      dataTestProp="replyAttachment.invalidType"
      // FIXME: update path to translations
      title={t('special.wrong_file_format_title')}
      description={t('special.wrong_file_format_description')}
    >
      <NativeAlertAction mode="secondary" onClick={close}>
        {t('buttons.okay')}
      </NativeAlertAction>
    </NativeAlert>
  )
}

import { variant } from '@effector/reflect'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import useSignOutMutation from 'src/apollo/hooks/useSignOutMutation'
import { ROUTES } from 'src/constants/routesConstants'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { NativeInlineAlert } from 'src/shared/ui/alerts/NativeInlineAlert'
import { withDispatch } from 'src/store'
import { clearStore } from 'src/store/slices/common'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $isTimeoutAlertVisible,
  sessionExtended,
  userListenersCleared,
  userListenersSetup,
} from '../model'

interface InactivityListenerProps extends ConnectedProps<typeof connector> {
  extendSession: () => void
}

function InactivityListenerComponent({
  dispatchClearStore,
  extendSession,
}: InactivityListenerProps) {
  const t = useScopedTranslation('translation.shared.inactivity.warning')

  const { handleSignOut } = useSignOutMutation({
    preventLocationReplace: true,
    resetRedux: dispatchClearStore,
  })

  const signOut = async () => {
    await handleSignOut()
    window.location.replace(`/${ROUTES.RELOGIN}`)
  }

  return (
    <StyledNativeInlineAlert
      title={t('title')}
      description={t('description')}
      dataTestProp="sessionTimeout"
    >
      <NativeAlertAction
        mode="danger"
        onClick={signOut}
        data-test="sessionTimeout.signOut"
      >
        {t('button_sign_out')}
      </NativeAlertAction>
      <NativeAlertAction
        mode="primary"
        onClick={extendSession}
        data-test="sessionTimeout.stay"
      >
        {t('button_stay')}
      </NativeAlertAction>
    </StyledNativeInlineAlert>
  )
}

const StyledNativeInlineAlert = styled(NativeInlineAlert)`
  width: ${convertPxToRem(280)};
`

const mapDispatchToProps = withDispatch({ clearStore })

const connector = connect(null, mapDispatchToProps)

export const InactivityListener = variant({
  if: $isTimeoutAlertVisible,
  then: connector(InactivityListenerComponent),
  bind: { extendSession: sessionExtended },
  hooks: { mounted: userListenersSetup, unmounted: userListenersCleared },
})

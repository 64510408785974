import React from 'react'

import { primaryDarkNavy } from 'src/theme/colors'

export function LocationCrossIcon() {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 12A3.749 3.749 0 0 1 12 8.25 3.749 3.749 0 0 1 15.75 12 3.749 3.749 0 0 1 12 15.75 3.749 3.749 0 0 1 8.25 12zM12 0c.83 0 1.5.672 1.5 1.5v1.624a9 9 0 0 1 7.373 7.376H22.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-1.627a8.993 8.993 0 0 1-7.373 7.373V22.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-1.627A9 9 0 0 1 3.124 13.5H1.5a1.5 1.5 0 1 1 0-3h1.624A9.008 9.008 0 0 1 10.5 3.124V1.5c0-.828.67-1.5 1.5-1.5zM6 12a6 6 0 1 0 12 0 6 6 0 1 0-12 0z"
        fill={primaryDarkNavy}
      />
    </svg>
  )
}

import { attach } from 'effector'

import { $$router } from 'src/shared/lib/router'

import { NavigateParams } from '../types'

// TODO: move this entity to `router` lib
export const { $navigate } = $$router

export const navigateFx = attach({
  source: $navigate,
  effect(navigate, { to, withQuery, ...options }: NavigateParams) {
    const url = withQuery ? `${to}${window.location.search}` : to
    return navigate(url, options)
  },
})

export const backFx = attach({
  source: $navigate,
  effect: (navigate) => navigate(-1),
})

export const navigateTo = (
  to: string,
  options: Omit<NavigateParams, 'to'> = {},
) => navigateFx.prepend<void>(() => ({ to, ...options }))

export const navigateWithQueryTo = (
  to: string,
  options: Omit<NavigateParams, 'to' | 'withQuery'> = {},
) => navigateFx.prepend<void>(() => ({ to, withQuery: true, ...options }))

export const navigateWithStateTo = <State extends NonNullable<unknown>>(
  to: string,
  options: Omit<NavigateParams, 'to' | 'state'> = {},
) => navigateFx.prepend((state: State) => ({ ...options, to, state }))

import {
  $pharmacies,
  $hasPharmacies,
  $hasExtendOption,
  $isShortList,
  $foundPharmacies,
} from './model/core'

export { pharmaciesListExpanded, INITIAL_LIST_LENGTH } from './model/core'
export {
  drugSearchPharmaciesQuery,
  DRUG_SEARCH_PHARMACIES_QUERY,
} from './api/pharmaciesSearchQuery'
export type { PharmaciesResult } from './api/pharmaciesSearchQuery/types'
export { MockPreferredPharmaciesQuery } from './api/pharmaciesSearchQuery/mocks'

export const $$pharmacySearch = {
  $pharmacies,
  $hasPharmacies,
  $hasExtendOption,
  $isShortList,
  $foundPharmacies,
}

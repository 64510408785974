import { createDomain, sample, createApi } from 'effector'
import { createQuery } from 'effector-apollo'
import { createGate } from 'effector-react'
import { condition, debounce, reset } from 'patronum'

import client from 'src/apollo/client'
import { ROUTES } from 'src/constants/routesConstants'
import {
  $drugSearchFlowEntrypoint,
  DrugSearchFlowEntrypoint,
} from 'src/entities/drugDetails'
import { $$navigate } from 'src/entities/navigate'
import { preferredPharmacyQuery } from 'src/entities/pharmacy'

import { DRUG_SEARCH_QUERY } from '../api/drugSearchQuery'

export const DrugSearch = createDomain('DrugSearch')

interface DrugSearchGateProps {
  returnTo: DrugSearchFlowEntrypoint
}

export const DrugSearchGate = createGate<DrugSearchGateProps>({
  name: 'DrugSearchGate',
  domain: DrugSearch,
})

export const drugSearchPageReset = DrugSearch.event<void>()
export const drugSearchInputChanged = DrugSearch.event<string>()
export const drugSearchMedicationSelected = DrugSearch.event<string>()
export const drugSearchRecentConfigSelected = DrugSearch.event<string>()

export const drugSearchQuery = createQuery({
  document: DRUG_SEARCH_QUERY,
  client,
})

export const $isDrugSearchLoading = DrugSearch.store(false)
export const $drugSearchInput = DrugSearch.store<string>('')

export const $drugSearchResults = drugSearchQuery.$data.map(
  (data) => data?.drugs ?? [],
)

const { startSearching, stopSearching } = createApi($isDrugSearchLoading, {
  startSearching: () => true,
  stopSearching: () => false,
})

sample({
  source: drugSearchInputChanged,
  target: $drugSearchInput,
})

condition({
  source: $drugSearchInput,
  if: (value) => value.length >= 3,
  then: startSearching,
  else: stopSearching,
})

const SEARCH_DEBOUNCE = 1000
sample({
  clock: debounce({ source: $drugSearchInput, timeout: SEARCH_DEBOUNCE }),
  filter: (text) => text.length >= 3,
  fn: (text) => ({ text }),
  target: drugSearchQuery.start,
})

sample({
  clock: drugSearchQuery.finished.success,
  source: $drugSearchInput,
  filter: (current, { variables: { text: sent } }) => current === sent,
  target: stopSearching,
})

sample({
  source: drugSearchRecentConfigSelected,
  fn: (drugName) => ({ drugName, isPreconfigured: false }),
  target: $$navigate.withStateTo(
    `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_CONFIGURE_PATH}`,
  ),
})

sample({
  source: drugSearchMedicationSelected,
  fn: (drugName) => ({ drugName }),
  target: $$navigate.withStateTo(
    `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_ALTERNATIVES}`,
  ),
})

sample({
  clock: DrugSearchGate.open,
  fn: ({ returnTo }) => returnTo,
  target: $drugSearchFlowEntrypoint,
})

sample({
  clock: DrugSearchGate.open,
  target: preferredPharmacyQuery.refresh,
})

reset({
  clock: drugSearchPageReset,
  target: [$isDrugSearchLoading, $drugSearchInput],
})

import { gql, TypedDocumentNode } from '@apollo/client'

import {
  AcceptTermsOfServiceResult,
  AcceptTermsOfServiceVariables,
} from './types'

export const ACCEPT_TERMS_OF_SERVICE_MUTATION: TypedDocumentNode<
  AcceptTermsOfServiceResult,
  AcceptTermsOfServiceVariables
> = gql`
  mutation acceptTermsOfService($id: ID!) {
    termsOfService: acceptTermsOfService(attributes: { id: $id }) {
      id
      accepted
    }
  }
`

import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

import client from 'src/apollo/client'

import { EARLY_ACCESS_QUERY, EarlyAccess } from '../api/earlyAccessQuery'

const EarlyAccessEntity = createDomain('EarlyAccess')

const EarlyAccessEntityGate = createGate({
  name: 'EarlyAccessEntityGate',
  domain: EarlyAccessEntity,
})

const fetchEarlyAccessFx = EarlyAccessEntity.effect<void, EarlyAccess>()

const $earlyAccess = EarlyAccessEntity.store<EarlyAccess | null>(null)
  .on(fetchEarlyAccessFx.doneData, (_, data) => data)
  .reset(EarlyAccessEntityGate.close)

const $isInEarlyAccess = $earlyAccess.map((earlyAccess) => Boolean(earlyAccess))

sample({
  clock: EarlyAccessEntityGate.open,
  target: fetchEarlyAccessFx,
})

fetchEarlyAccessFx.use(async () => {
  const { data } = await client.query({ query: EARLY_ACCESS_QUERY })

  return data.currentUser.earlyAccess
})

export {
  EarlyAccessEntityGate as EarlyAccessController,
  $earlyAccess,
  $isInEarlyAccess,
  fetchEarlyAccessFx,
}

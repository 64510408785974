import { useEffect, useState } from 'react'

import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import useViewSettingsQuery from 'src/apollo/hooks/useViewSettingsQuery'
import {
  setIsInitialDataReceived as setIsInitialDataReceivedAction,
  setAvailableRoutes as setAvailableRoutesAction,
  setHomePage as setHomePageAction,
  setActiveSection as setActiveSectionAction,
} from 'src/store/slices/common'
import { setFeatureFlags as setFeatureFlagsAction } from 'src/store/slices/features'
import {
  getActiveSection,
  getAvailableRoutes,
  getHomePage,
} from 'src/utils/routeHelpers'

function InitialDataHandler({
  setIsInitialDataReceived = () => {},
  setFeatureFlags = () => {},
  setAvailableRoutes = () => {},
  setHomePage = () => {},
  setActiveSection = () => {},
}) {
  const [isFeatureFlagsLoaded, setFeatureFlagsLoaded] = useState(false)

  // Feature Flags
  const {
    featureFlags,
    isLoadingUserSettings,
    hasFeaturesData,
    error: featuresQueryError,
  } = useViewSettingsQuery()

  useEffect(() => {
    if (isLoadingUserSettings) {
      return
    }
    if (!featuresQueryError && !hasFeaturesData) {
      return
    }

    setFeatureFlagsLoaded(true)
    setFeatureFlags(featureFlags)
  }, [
    featureFlags,
    featuresQueryError,
    hasFeaturesData,
    isLoadingUserSettings,
    setFeatureFlags,
  ])

  // Active section observer
  const location = useLocation()

  useEffect(() => {
    setActiveSection(getActiveSection(location.pathname))
  }, [location.pathname, setActiveSection])

  // Initial Data Received handler
  useEffect(() => {
    if (!isFeatureFlagsLoaded) {
      return
    }

    setAvailableRoutes(getAvailableRoutes(featureFlags))
    setHomePage(getHomePage(featureFlags))
    setIsInitialDataReceived(true)
  }, [
    isFeatureFlagsLoaded,
    setIsInitialDataReceived,
    setAvailableRoutes,
    setHomePage,
    featureFlags,
  ])

  return null
}

InitialDataHandler.propTypes = {
  setFeatureFlags: PropTypes.func,
  setIsInitialDataReceived: PropTypes.func,
  setAvailableRoutes: PropTypes.func,
  setHomePage: PropTypes.func,
  setActiveSection: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  setIsInitialDataReceived: (isReceived) =>
    dispatch(setIsInitialDataReceivedAction(isReceived)),
  setFeatureFlags: (featureFlags) =>
    dispatch(setFeatureFlagsAction(featureFlags)),
  setAvailableRoutes: (availableRoutes) =>
    dispatch(setAvailableRoutesAction(availableRoutes)),
  setHomePage: (homePage) => dispatch(setHomePageAction(homePage)),
  setActiveSection: (activeSection) =>
    dispatch(setActiveSectionAction(activeSection)),
})

export default connect(null, mapDispatchToProps)(InitialDataHandler)

import { useCallback, useMemo, useState } from 'react'

import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import styled from 'styled-components'

import { PopupModal } from '@features/PopupModal'

import { UniversalHeader } from '@widgets/Navigation'

import { ROUTES } from 'src/constants/routesConstants'
import { Pharmacy } from 'src/entities/pharmacy'
import { HoverButton } from 'src/features/HoverButton'
import {
  LargeMap,
  LargeMapHookParams,
  MAP_PADDING_PIXELS,
} from 'src/features/LargeMap'
import {
  $$pharmacySearch,
  drugSearchPharmaciesQuery,
} from 'src/features/pharmacySearch'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { FavoritePharmacyCard } from 'src/widgets/FavoritePharmacyCard'

import { $markers, mapInitialized } from '../model'

import { PharmacyMarker } from './PharmacyMarker'

interface SearchPharmaciesMapProps {
  pharmacies: Pharmacy[]
  isLoading: boolean
}

function SearchPharmaciesMapView({
  pharmacies,
  isLoading,
}: SearchPharmaciesMapProps) {
  const t = useScopedTranslation('translation.medications.drug_search.status')

  const navigate = useNavigate()

  const [selectedPharmacyName, setSelectedPharmacyName] = useState('')

  const navigateToPharmaciesList = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_PHARMACIES_SEARCH, {
      replace: true,
    })
  }, [navigate])

  const selectedPharmacy = useMemo(
    () => pharmacies?.find(({ name }) => name === selectedPharmacyName),
    [pharmacies, selectedPharmacyName],
  )

  const onSelectMarker = useCallback((name: string) => {
    setSelectedPharmacyName(name)
  }, [])

  const onMapLoaded = useCallback(({ map, bounds }: LargeMapHookParams) => {
    map.fitBounds(bounds, MAP_PADDING_PIXELS)
  }, [])

  if (isLoading) {
    return <SpinnerWithTitle text={t('searching')} />
  }

  return (
    <Root>
      <Content>
        <Map
          onLoaded={onMapLoaded}
          draggable="greedy"
          element={
            <PharmacyMarker
              selectedMarkerId={selectedPharmacyName}
              onClick={onSelectMarker}
            />
          }
        />
        <HeaderButtons>
          <UniversalHeader mode="close" onClick={navigateToPharmaciesList} />
        </HeaderButtons>
      </Content>

      <BottomContainer>
        <HoverButton>
          <HoverButton.List
            onClick={navigateToPharmaciesList}
            dataTestProp="listButton"
          />
        </HoverButton>

        {selectedPharmacy && (
          <FavoritePharmacyCard
            dataTestProp="selectedPharmacy"
            pharmacy={selectedPharmacy}
            isInNetwork
          />
        )}
      </BottomContainer>

      <Banners />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertPxToRem(24)};
  position: relative;

  ${mediaQueryFor.mobile} {
    padding: 0;
  }
`
const Banners = styled(PopupModal)`
  left: ${convertPxToRem(48)};
  right: ${convertPxToRem(48)};

  ${mediaQueryFor.mobile} {
    left: ${convertPxToRem(24)};
    right: ${convertPxToRem(24)};
  }
`
const Content = styled.div`
  position: relative;
  width: 100%;

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledMap = styled(LargeMap)`
  width: 100%;
  height: 100%;

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: ${convertPxToRem(48)};
  right: ${convertPxToRem(48)};
  gap: ${convertPxToRem(16)};
  position: absolute;
  bottom: ${convertPxToRem(50)};
  ${mediaQueryFor.mobile} {
    left: ${convertPxToRem(24)};
    right: ${convertPxToRem(24)};
  }
`
const HeaderButtons = styled.div`
  position: absolute;
  top: ${convertPxToRem(40)};
  left: ${convertPxToRem(40)};
  right: ${convertPxToRem(40)};

  pointer-events: none;

  ${mediaQueryFor.mobile} {
    top: ${convertPxToRem(25)};
    left: ${convertPxToRem(25)};
    right: ${convertPxToRem(25)};
  }
`

const Map = reflect({
  view: StyledMap,
  bind: {
    markers: $markers,
  },
})

export const SearchPharmaciesMap = reflect({
  view: SearchPharmaciesMapView,
  bind: {
    pharmacies: $$pharmacySearch.$foundPharmacies,
    isLoading: drugSearchPharmaciesQuery.$pending,
  },
  hooks: {
    mounted: mapInitialized,
  },
})

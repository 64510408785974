import { useLazyQuery } from '@apollo/client'

import { UploadFileType } from 'src/shared/api/types'

import { UPLOADED_FILE_QUERY } from './document'

interface UseUploadedFileArgs {
  id: string
  type: UploadFileType
}

export function useUploadedFileQuery({ id, type }: UseUploadedFileArgs) {
  const [getNewLink] = useLazyQuery(UPLOADED_FILE_QUERY, {
    variables: { id, type },
    fetchPolicy: 'network-only',
  })

  return { getNewLink }
}

import React, { useMemo } from 'react'

import styled from 'styled-components'

import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { Appointment } from '../../api/appointmentQuery/types'
import { pickSuggestedTimeSlot } from '../../lib/timeSlots'

import { DefaultActionBar } from './DefaultActionBar'
import { SuggestedActionBar } from './SuggestedActionBar'

interface AppointmentActionBarProps {
  timeSlots: Appointment['timeSlots']
}

export function AppointmentActionBar({ timeSlots }: AppointmentActionBarProps) {
  const suggestedSlotId = useMemo(
    () => pickSuggestedTimeSlot(timeSlots)?.id ?? null,
    [timeSlots],
  )

  if (suggestedSlotId) {
    return (
      <Wrapper>
        <SuggestedActionBar timeSlotId={suggestedSlotId} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <DefaultActionBar />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: ${convertPxToRem(10)};

  padding: ${convertPxToRem(24)};

  border-radius: ${convertPxToRem(20, 20, 0, 0)};
  background: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, -4, 10, 0)} rgba(0, 0, 0, 0.08);
`

import { useQuery } from '@apollo/client'

import { INBOX_STATUS_QUERY } from './graphql'

export function useInboxStatusQuery() {
  const { data } = useQuery(INBOX_STATUS_QUERY)

  return {
    unreadCount: data?.user?.unreadCount ?? 0,
    isOnline: data?.user?.status?.isOnline ?? false,
  }
}

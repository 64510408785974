import styled from 'styled-components'

import { DrugDetailsName } from 'src/pages/drugSearch/Details/ui/content/DrugDetailsName'
import { DrugDetailsSearch } from 'src/pages/drugSearch/Details/ui/content/DrugDetailsSearch'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { ContentWithLightNavHeader } from 'src/widgets/ContentWithLightNavHeader'

import { ErrorContentController } from './ErrorContentController'

export function DrugSearchDetailsErrorPage() {
  return (
    <ContentWithLightNavHeader>
      <Root data-test="drugSearchDetailsErrorPage.root">
        <DrugDetailsName />
        <SearchSection>
          <DrugDetailsSearch />
          <ErrorContentController />
        </SearchSection>
      </Root>
    </ContentWithLightNavHeader>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;
  gap: ${convertPxToRem(24, 0)};
  flex: 1;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const SearchSection = styled(Section)`
  gap: ${convertPxToRem(16)};
  flex: 1;
`

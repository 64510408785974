import { trackMediaQuery } from '@withease/web-api'

import { setup } from './setup'

const query = {
  mobile: '(max-width: 1199px)', // width <= 1199px
  desktop: '(min-width: 1200px)', // width > 1199px
}

const { mobile, desktop } = trackMediaQuery(query, { setup })

export const $mobile = mobile.$matches
export const $desktop = desktop.$matches

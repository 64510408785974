import React, { CSSProperties } from 'react'

import styled from 'styled-components'

import { primaryLightBlue } from 'src/theme/colors'

interface LinkDecoratorProps {
  decoratedHref: string
  decoratedText: string
  extraStyles?: CSSProperties
}

function LinkDecorator({
  decoratedHref,
  decoratedText,
  extraStyles = {},
}: LinkDecoratorProps) {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  return (
    <StyledLink
      style={extraStyles}
      onClick={onClick}
      target="_blank"
      href={decoratedHref}
      rel="noopener"
    >
      {decoratedText}
    </StyledLink>
  )
}

export const linkDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number,
) => (
  <LinkDecorator
    key={key}
    decoratedHref={decoratedHref}
    decoratedText={decoratedText}
  />
)

const StyledLink = styled.a`
  color: ${primaryLightBlue};
`

export default LinkDecorator

import { gql } from '@apollo/client'

const DISMISS_FEEDITEM_MUTATION = gql`
  mutation dismissFeedItem($id: ID!) {
    dismissFeedItem(id: $id) {
      id
      expired_at
    }
  }
`

export default DISMISS_FEEDITEM_MUTATION

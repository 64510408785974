import React from 'react'

import styled from 'styled-components'

import { primaryBlue, primaryWhite, secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12, heading7_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ActivityCounterProps {
  count: number
  counterTitle: string
}

function ActivityCounter({ count, counterTitle }: ActivityCounterProps) {
  return (
    <Root>
      <Counter data-test="activity.counter">{count}</Counter>
      <CounterTitle data-test="activity.counter.title">
        {counterTitle}
      </CounterTitle>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${convertPxToRem(13)};
  margin-bottom: ${convertPxToRem(6)};
`
const Counter = styled.div`
  height: ${convertPxToRem(24)};
  width: ${convertPxToRem(24)};
  border-radius: 50%;
  text-align: center;
  background-color: ${primaryBlue};
  color: ${primaryWhite};
  ${heading7_14};
  font-size: ${convertPxToRem(12)};
  line-height: ${convertPxToRem(24)};
`
const CounterTitle = styled.div`
  margin-left: ${convertPxToRem(8)};
  color: ${secondaryGrey};
  ${capsHeading1_12};
`

export default ActivityCounter

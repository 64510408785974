import { Link } from '@reach/router'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryBlue, primaryDarkNavy } from 'src/theme/colors'
import { heading6_16_Medium, heading7_14, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface UpdateFacilityPopupContentProps {
  title: string
  description?: string
}

export function UpdateFacilityPopupContent({
  title,
  description = undefined,
}: UpdateFacilityPopupContentProps) {
  const t = useScopedTranslation(
    'translation.home.care_tools.my_care_team.toasts.facilities',
  )

  return (
    <Root>
      <Title>{t(title)}</Title>
      {description && (
        <Trans
          t={t}
          parent={Description}
          i18nKey={description}
          components={DescriptionComponent}
        />
      )}
    </Root>
  )
}

const TextLink = styled(Link)`
  color: ${primaryBlue};
  ${heading7_14};
`

const DescriptionComponent = {
  action: (
    <TextLink
      data-test="textLink"
      to={`/${ROUTES.CARE_TEAM}/${ROUTES.CARE_TEAM_FACILITIES}`}
    />
  ),
}

const Title = styled.span`
  color: ${primaryDarkNavy};
  ${heading6_16_Medium};
`

const Description = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(4)};
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { SignOutResult, SignOutVariables } from './types'

export const SIGN_OUT_MUTATION: TypedDocumentNode<
  SignOutResult,
  SignOutVariables
> = gql`
  mutation signOut {
    signOut {
      access_token
    }
  }
`

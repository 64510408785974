import { merge, sample } from 'effector'
import { createQuery } from 'effector-apollo'
import { createGate } from 'effector-react'
import { and } from 'patronum'

import client from 'src/apollo/client'
import { postpone } from 'src/shared/lib/effector/postpone'
import { remap } from 'src/shared/lib/effector/remap'

import { TRACKING_IDENTITY_QUERY } from '../api/trackingIdentityQuery'

import {
  $isFreshpaintReady,
  identifyFreshpaintFx,
  initFreshpaintFx,
} from './api'

export const FreshpaintGate = createGate('FreshpaintGate')

export const identityQuery = createQuery({
  client,
  document: TRACKING_IDENTITY_QUERY,
  context: { isSkipError: true },
})

const updated = merge([
  FreshpaintGate.open,
  identityQuery.finished.success,
  initFreshpaintFx.done,
])

sample({
  clock: FreshpaintGate.open,
  target: [identityQuery.refresh, initFreshpaintFx],
})

sample({
  clock: postpone({
    clock: updated,
    until: and($isFreshpaintReady, identityQuery.$succeeded),
  }),
  source: remap(identityQuery.$data, 'user.serviceId'),
  filter: Boolean, // By the time the query is 'done', data should actually be here, so we're not loosing any triggers
  target: identifyFreshpaintFx,
})

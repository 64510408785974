import { TypedDocumentNode, gql } from '@apollo/client'

import { PHARMACY_FRAGMENT } from 'src/entities/pharmacy'

import { PharmaciesResult, PharmaciesVariables } from './types'

export const DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY: TypedDocumentNode<
  PharmaciesResult,
  PharmaciesVariables
> = gql`
  query pharmacies($latitude: Float, $longitude: Float) {
    pharmacies: pdsPharmacies(latitude: $latitude, longitude: $longitude) {
      ...pharmacy
    }
  }

  ${PHARMACY_FRAGMENT}
`

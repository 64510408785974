import { createQuery } from 'effector-apollo'

import client from 'src/apollo/client'

import { DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY } from './document'

export const drugSearchPharmaciesQuery = createQuery({
  document: DRUG_SEARCH_PREFERRED_PHARMACIES_QUERY,
  client,
})

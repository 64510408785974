import React from 'react'

import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ContentWithLightNavHeaderProps {
  children: React.ReactNode
  onBack?: () => void
}

export function ContentWithLightNavHeader({
  children,
  onBack = undefined,
}: ContentWithLightNavHeaderProps) {
  return (
    <Root>
      <Content>
        <HeaderWrapper>
          <HeaderNavLightTheme onBack={onBack} />
        </HeaderWrapper>

        {children}
      </Content>
    </Root>
  )
}

const Root = styled.div`
  padding: ${convertPxToRem(24)};

  ${mediaQueryFor.mobile} {
    padding: 0;
    display: flex;
    flex: 1;
  }
`
const Content = styled.div`
  padding: ${convertPxToRem(40)};
  width: 100%;
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 42)};
  }

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${primaryWhite};
`
const HeaderWrapper = styled.div`
  padding-bottom: ${convertPxToRem(26)};
  width: 100%;
`

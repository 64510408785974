import { createEvent, createStore, sample } from 'effector'
import { createQuery } from 'effector-apollo'

import client from 'src/apollo/client'

import { DRUG_CONFIGURATION_QUERY } from './api/drugConfigurationQuery'

const loadOptionsQuery = createQuery({
  document: DRUG_CONFIGURATION_QUERY,
  client,
})

const reset = createEvent<void>()

const $options = loadOptionsQuery.$data.map(
  (data) => data?.drugConfiguration?.drug?.configurations ?? [],
)

const $isDrugCovered = createStore<boolean>(false)
const $hasError = createStore<boolean>(false)

sample({
  source: loadOptionsQuery.finished.success,
  fn: ({ data }) => !data.drugConfiguration?.drug?.isAlternativesOnly,
  target: $isDrugCovered,
})

sample({
  source: loadOptionsQuery.finished.success,
  fn: ({ data }) => !data.drugConfiguration,
  target: $hasError,
})

export const $$drugConfiguration = {
  $options,
  $isDrugCovered,
  $hasError,
  reset,
  loadOptionsQuery,
}

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'

interface LargeAttachmentAlertProps {
  close: () => void
}

export function LargeAttachmentAlert({ close }: LargeAttachmentAlertProps) {
  const t = useScopedTranslation('translation.chat.large_file_error')
  const tShared = useScopedTranslation('translation.shared.buttons')

  return (
    <NativeAlert
      dataTestProp="replyAttachment.tooLarge"
      title={t('title')}
      description={t('description')}
    >
      <NativeAlertAction mode="secondary" onClick={close}>
        {tShared('okay')}
      </NativeAlertAction>
    </NativeAlert>
  )
}

import { TypedDocumentNode, gql } from '@apollo/client'

import { InsuranceInfo } from './types'

export const INSURANCE_INFO_FRAGMENT: TypedDocumentNode<
  InsuranceInfo,
  Record<string, never>
> = gql`
  fragment insuranceInfo on UserInsuranceInfoType {
    medical {
      name
      id
      groupId: group_id
    }
    dental {
      name
      id
      groupId: group_id
    }
    vision {
      name
      id
      groupId: group_id
    }
    pharmacy {
      name
      groupId: group_id
      bin
      pcn
    }
  }
`

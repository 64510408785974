import { gql } from '@apollo/client'

const VIEW_SETTINGS_FRAGMENT = gql`
  fragment viewSettingsFragment on User {
    id
    view_settings {
      features {
        id
        display_name
        name
        child_of
        available
      }
    }
  }
`

export default VIEW_SETTINGS_FRAGMENT

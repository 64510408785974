export enum UserLocationsStep {
  List = 'List',
  Search = 'Search',
  Edit = 'Edit',
}

export enum DeviceLocationState {
  Aquired = 'Aquired',
  Loading = 'Loading',
  Available = 'Available',
  Accessible = 'Accessible',
  Restricted = 'Restricted',
}

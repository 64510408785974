import { useCallback } from 'react'

import { useNavigate } from '@reach/router'
import { ConnectedProps, connect } from 'react-redux'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { ButtonBack40 } from 'src/shared/ui/buttons/ButtonBack40'
import { Sprite } from 'src/shared/ui/Sprite'
import { withDispatch } from 'src/store'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { DrugSearchEarlyAccessMessage } from './DrugSearchEarlyAccessMessage'

type DrugSearchEarlyAccessProps = ConnectedProps<typeof connector>

function DrugSearchEarlyAccess({
  dispatchSetIsShowThirdSectionModal,
  dispatchStartNewChat,
}: DrugSearchEarlyAccessProps) {
  const t = useScopedTranslation('translation.medications.drug_search.buttons')

  const navigate = useNavigate()

  const handleOpenChat = () => {
    dispatchStartNewChat()
    dispatchSetIsShowThirdSectionModal(true)
  }

  const navigateBack = useCallback(() => navigate(-1), [navigate])

  return (
    <Root data-test="drugSearchEarlyAccess">
      <Content>
        <ButtonBack40
          onClick={navigateBack}
          dataTestProp="drugSearchEarlyAccess.backButton"
        />
        <MessageBox>
          <ExclamationIcon />
          <DrugSearchEarlyAccessMessage />
        </MessageBox>
        <ButtonBottom
          title={t('chat_with_a_pharmacy_guide')}
          theme="primary"
          handleClick={handleOpenChat}
          dataTestProp="drugSearchEarlyAccess.chatButton"
        />
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;

  padding: ${convertPxToRem(40)};

  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: ${convertPxToRem(40)};

  border-radius: ${convertPxToRem(8)};
  background-color: ${primaryWhite};
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  align-items: center;
  justify-content: center;

  padding: ${convertPxToRem(123, 0, 295)};

  ${mediaQueryFor.tabletOrLess} {
    padding: ${convertPxToRem(103, 0, 375)};
  }
`

const ExclamationIcon = styled(Sprite).attrs({ name: 'misc/exclamation_mark' })`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  color: ${secondaryGrey};
`

const mapDispatchToProps = withDispatch({
  setIsShowThirdSectionModal,
  startNewChat,
})

const connector = connect(null, mapDispatchToProps)

export const DrugSearchEarlyAccessPage = connector(DrugSearchEarlyAccess)

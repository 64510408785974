export function initFreshpaint() {
  return new Promise<void>((resolve) => {
    freshpaint.ready(resolve)
  })
}

export function identifyFreshpaint(userId: string): Promise<void> {
  return new Promise((resolve) => {
    freshpaint.identify(userId, undefined, undefined, resolve)
  })
}

export function resetFreshpaint() {
  freshpaint.reset()
}

interface TrackFreshpaintParams {
  event: string
  properties?: Parameters<typeof freshpaint.track>[1]
}

export function trackFreshpaint({
  event,
  properties,
}: TrackFreshpaintParams): Promise<void> {
  return new Promise((resolve) => {
    freshpaint.track(event, properties, undefined, resolve)
  })
}

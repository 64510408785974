import { gql } from '@apollo/client'

import FEED_FIELDS_FRAGMENT from 'src/apollo/fragments/FEED_FIELDS_FRAGMENT'

const CARE_PLAN_FRAGMENT = gql`
  fragment carePlanFragment on CarePlan {
    id
    archived
    description
    image_url
    introduction
    status
    title
    created_at
    feed_fields {
      ...feedFieldsFragment
    }
  }
  ${FEED_FIELDS_FRAGMENT}
`

export default CARE_PLAN_FRAGMENT

import { ReactElement } from 'react'

import styled from 'styled-components'

import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { CallButton } from './CallButton'
import { DirectionsButton } from './DirectionsButton'
import { WebsiteButton } from './WebsiteButton'

interface ActionsProps {
  children: ReactElement | ReactElement[]
}

export function Actions({ children }: ActionsProps) {
  return <Root>{children}</Root>
}

Actions.Call = CallButton
Actions.Directions = DirectionsButton
Actions.Website = WebsiteButton

const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertPxToRem(24)};
  color: ${secondaryGrey};
`

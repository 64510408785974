import React, { Suspense } from 'react'

import { withScope, captureException } from '@sentry/react'

import { CrashModal } from './CrashModal'

export class CrashBoundary extends React.Component<React.PropsWithChildren> {
  state = { hasError: false }

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
    withScope((scope) => {
      scope.setTag('captured_at', 'CrashBoundary')

      enchanceWithStackCause(error, componentStack ?? '')

      captureException(error, { contexts: { react: { componentStack } } })
    })

    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props

    if (!hasError) return children

    return (
      <Suspense>
        <CrashModal />
      </Suspense>
    )
  }
}

function enchanceWithStackCause(error: Error, stack: string): void {
  const boundaryError = new Error(error.message)
  boundaryError.name = `[CrashBoundary] ${boundaryError.name}`
  boundaryError.stack = stack

  // eslint-disable-next-line no-param-reassign
  error.cause = boundaryError
}

import { useMemo } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import { FeatureFlag } from 'src/constants/featureFlags'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { RootState } from 'src/store'
import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { heading4_21, text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { NavigationPresets } from './NavigationPresets'
import { PharmacyPresets } from './PharmacyPresets'
import { UnityPresets } from './UnityPresets'

type ChatPresetsProps = ConnectedProps<typeof connector>

function ChatPresetsComponent({
  hasNavigationBenefit,
  hasPharmacyBenefit,
}: ChatPresetsProps) {
  const t = useScopedTranslation('translation.chat.thread')

  const content = useMemo(() => {
    if (hasNavigationBenefit && hasPharmacyBenefit) return <UnityPresets />
    if (hasNavigationBenefit) return <NavigationPresets />
    if (hasPharmacyBenefit) return <PharmacyPresets />
  }, [hasNavigationBenefit, hasPharmacyBenefit])

  return (
    <Wrapper>
      <Header>
        <Title>{t('title')}</Title>
        <Subtitle>{t('description')}</Subtitle>
      </Header>

      <List>{content}</List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: ${convertPxToRem(32)};

  padding: ${convertPxToRem(20)};
`

const Header = styled.div`
  display: flex;
  flex-flow: column nowrap;

  align-items: center;

  gap: ${convertPxToRem(4)};
`

const Title = styled.h2`
  ${heading4_21};
  color: ${primaryDarkNavy};
`

const Subtitle = styled.span`
  ${text1_16};
  color: ${secondaryGrey};
`

const List = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: ${convertPxToRem(8)};
`

const mapStateToProps = ({ features }: RootState) => ({
  hasPharmacyBenefit: features.featureFlags[FeatureFlag.PBM],
  hasNavigationBenefit: features.featureFlags[FeatureFlag.CORE_NAV],
})

const connector = connect(mapStateToProps, null)
export const ChatPresets = connector(ChatPresetsComponent)

import React, { useCallback, useMemo } from 'react'

import styled from 'styled-components'

import { AppointmentStatus } from 'src/shared/api/types'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { Appointment } from '../../api/appointmentQuery/types'
import { useCancelAppointmentTimeSlotMutation } from '../../api/cancelAppointmentTimeSlotMutation'
import { pickConfirmedTimeSlot } from '../../lib/timeSlots'

import { AppointmentCancelContextMenu } from './AppointmentCancelContextMenu'
import { AppointmentHeaderDescription } from './AppointmentHeaderDescription'
import { AppointmentHeaderTitle } from './AppointmentHeaderTitle'

interface AppointmentTitleProps {
  appointment: Appointment
}

export function AppointmentHeader({ appointment }: AppointmentTitleProps) {
  const { status, displayStatus, assignee, timeSlots } = appointment

  const { cancelTimeSlot, isCancellationPending } =
    useCancelAppointmentTimeSlotMutation()

  const confirmedSlotId = useMemo(
    () => pickConfirmedTimeSlot(timeSlots)?.id ?? null,
    [timeSlots],
  )

  const handleCancellation = useCallback(() => {
    if (confirmedSlotId) cancelTimeSlot(confirmedSlotId)
  }, [cancelTimeSlot, confirmedSlotId])

  const isCancellable =
    status === AppointmentStatus.Confirmed && !isCancellationPending

  return (
    <Wrapper data-test="appointmentHeader">
      <AppointmentHeaderTitle assignee={assignee} status={status} />
      <AppointmentHeaderDescription
        status={status}
        statusName={displayStatus}
      />

      {isCancellable && (
        <AppointmentCancelContextMenu onCancel={handleCancellation} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content max-content;

  color: ${primaryDarkNavy};

  gap: ${convertPxToRem(12)};
`

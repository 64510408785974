import { variant } from '@effector/reflect'
import { Router, Redirect } from '@reach/router'

import { ROUTES } from 'src/constants/routesConstants'
import { $isInEarlyAccess } from 'src/entities/earlyAccess/model'

import { AlternativePrompt } from './Alternative'
import { DrugSearchConfiguration } from './Configuration'
import { DrugSearchDetailsPage } from './Details'
import { DrugSearchDetailsMap } from './DetailsMap'
import { DrugSearchEarlyAccessPage } from './EarlyAccess'
import { DrugSearchErrorPage } from './Error'
import { DrugSearchIntro } from './Intro'
import { DrugSearchPharmacyDetails } from './PharmacyDetails'
import { DrugSearchPreferredPharmacyFind } from './PreferredPharmacyFind'
import { DrugSearchPreferredPharmacyMap } from './PreferredPharmacyMap'
import { DrugSearchPreferredPharmacyResult } from './PreferredPharmacyResult'
import { DrugSearch } from './Search'

function DrugSearchRouterView() {
  return (
    <Router className="rootRouter">
      <DrugSearch
        // @ts-expect-error: Reach Router Component props
        path={ROUTES.DRUG_SEARCH}
      />
      <AlternativePrompt path={ROUTES.DRUG_SEARCH_ALTERNATIVES} />
      <DrugSearchErrorPage path={ROUTES.DRUG_SEARCH_ERROR} />
      <DrugSearchPreferredPharmacyFind
        path={ROUTES.DRUG_SEARCH_PHARMACY_SEARCH_PATH}
      />
      <DrugSearchPreferredPharmacyResult
        // @ts-expect-error: Reach Router Component props
        path={ROUTES.DRUG_SEARCH_PHARMACY_RESULTS_PATH}
      />
      <DrugSearchPreferredPharmacyMap
        // @ts-expect-error: Reach Router Component props
        path={`${ROUTES.DRUG_SEARCH_PHARMACY}/${ROUTES.DRUG_SEARCH_PHARMACY_MAP}`}
      />
      <DrugSearchConfiguration
        // @ts-expect-error: Reach Router Component props
        path={ROUTES.DRUG_SEARCH_CONFIGURE_PATH}
      />
      <DrugSearchIntro
        // @ts-expect-error: Reach Router Component props
        path={ROUTES.DRUG_SEARCH_INTRO_PATH}
      />
      <DrugSearchDetailsPage path={ROUTES.DRUG_SEARCH_DETAILS} />
      <DrugSearchPharmacyDetails path={ROUTES.DRUG_SEARCH_DETAILS_PHARMACY} />
      <DrugSearchDetailsMap path={`/${ROUTES.DRUG_SEARCH_DETAILS_MAP}`} />
      <Redirect from="/" to={ROUTES.DRUG_SEARCH} noThrow />
    </Router>
  )
}

export const DrugSearchRouter = variant({
  if: $isInEarlyAccess,
  then: DrugSearchEarlyAccessPage,
  else: DrugSearchRouterView,
})

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleBlue,
  featurePaleRed,
  featurePaleYellow,
} from 'src/theme/colors'

import { PresetItem } from './PresetItem'

export function PharmacyPresets() {
  const t = useScopedTranslation('translation.chat.thread.answers')

  return (
    <>
      <PresetItem icon={<Sprite name="misc/mail" />} color={featurePaleBlue}>
        {t('mail_order')}
      </PresetItem>

      <PresetItem icon={<Sprite name="misc/pill" />} color={featurePaleRed}>
        {t('med_cost')}
      </PresetItem>

      <PresetItem icon={<Sprite name="misc/clock" />} color={featurePaleYellow}>
        {t('prior_auth_status')}
      </PresetItem>

      <PresetItem
        icon={<Sprite name="legacy/question_mark" />}
        color={featurePaleRed}
      >
        {t('about_app')}
      </PresetItem>
    </>
  )
}

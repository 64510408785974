import { useContext } from 'react'

import { useUnit } from 'effector-react'

import { $$chat } from 'src/entities/chat'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { NativeInlineAlert } from 'src/shared/ui/alerts/NativeInlineAlert'

import { useDeactivateChatMutation } from '../../api/deactivateChatMutation'
import { ThreadIdContext } from '../../lib/ThreadIdContext'

interface DeactivateModalProps {
  close: () => void
}

export function DeactivateModal({ close }: DeactivateModalProps) {
  const t = useScopedTranslation('translation.chat.thread')

  const id = useContext(ThreadIdContext)!

  const { deactivate: deactivateChat, isLoading } =
    useDeactivateChatMutation(id)

  const closeChat = useUnit($$chat.close)
  const deactivate = () => void deactivateChat().then(() => closeChat())

  return (
    <NativeInlineAlert
      title={t('confirm_title')}
      description={t('confirm_description')}
    >
      <NativeAlertAction
        mode="danger"
        disabled={isLoading}
        onClick={deactivate}
      >
        {t('confirm_delete')}
      </NativeAlertAction>

      <NativeAlertAction mode="primary" disabled={isLoading} onClick={close}>
        {t('confirm_cancel')}
      </NativeAlertAction>
    </NativeInlineAlert>
  )
}

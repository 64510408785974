import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import 'moment/locale/es'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

import config from 'src/config'
import resources from 'src/translations/resources'
import { localeDetector } from 'src/utils/i18Helpers'

const options = {
  backends: [Backend, resourcesToBackend(resources)],
  backendOptions: [
    {
      crossDomain: true,

      loadPath: ([lng]) =>
        lng === 'dev'
          ? /* falsy value to abort download for local i18n */ (false as never)
          : `${config.TRANSLATIONS_URL}{{lng}}.json`,
    } satisfies HttpBackendOptions,
    {},
  ],
}

const locale = localeDetector()

void i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: locale,
    supportedLngs: ['es', 'en', 'dev'],

    fallbackLng: {
      es: ['en', 'dev'],
      en: ['dev'],
    },

    load: 'languageOnly',

    defaultNS: 'translation',

    returnEmptyString: true,
    partialBundledLanguages: true,

    backend: options,

    react: { useSuspense: true },
    interpolation: { escapeValue: false },
  })

moment.locale(locale)

export default i18n

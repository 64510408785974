import { useMutation } from '@apollo/client'

import { READ_CHAT_MUTATION } from './graphql'

/*
 * Note that updating the 'unread chats badge' is done
 * solely through a subscription, since we're not able
 * to update it client-side.
 */
export function useReadChatMutation(id: string) {
  const [read] = useMutation(READ_CHAT_MUTATION, {
    variables: { id },
    optimisticResponse: { chat: { id, isUnread: false } },
    ignoreResults: true,
  })

  return { read }
}

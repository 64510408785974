import { useNavigate } from '@reach/router'

import CardCategory from 'src/components/Cards/CardCategory'
import { CustomFeedItem } from 'src/entities/feedItems'
import {
  BG_COLORS,
  getLabelColors,
  openActivityCard,
} from 'src/sections/SectionHome/Activity/activityFeedHelpers'
import { CustomFeedItemsTypes } from 'src/shared/api/types'

interface CustomActivityCardProps {
  customFeedItem: CustomFeedItem
  setErrorMessage: (value: string) => void
  setDismissItemType: (value: CustomFeedItemsTypes) => void
}
export default function CustomActivityCard({
  customFeedItem,
  setDismissItemType,
  setErrorMessage,
}: CustomActivityCardProps) {
  const { cardType, title, body, label, callToAction, imageUrl, url } =
    customFeedItem

  const navigate = useNavigate()

  const labelColor = getLabelColors({
    category: cardType,
    resourceStatus: null,
    isResourceArchived: false,
  })

  const categoryCardItem = {
    id: cardType,
    title,
    description: body,
    label,
    callToAction,
    imageUrl,
  }

  const openDismissAlert = () => {
    setDismissItemType(cardType)
  }

  return (
    <CardCategory
      dataTestProp={`${cardType}-card`}
      item={categoryCardItem}
      bgColor={BG_COLORS[cardType]}
      labelBg={labelColor}
      onClick={openActivityCard({ url, setErrorMessage, navigate })}
      dismissable
      openDismissAlert={openDismissAlert}
    />
  )
}

import { variant } from '@effector/reflect'
import { Redirect, useLocation, WindowLocation } from '@reach/router'
import { useGate } from 'effector-react'

import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { $$drugConfiguration } from 'src/entities/drugConfiguration'
import { preferredPharmacyQuery } from 'src/entities/pharmacy'
import { select } from 'src/shared/lib/effector/select'
import { ContentWithLightNavHeader } from 'src/widgets/ContentWithLightNavHeader'

import { DrugSearchConfigGate } from '../model/core'

import { Configuration } from './Configuration'
import { PreferredPharmacyController } from './PreferredPharmacyController'

interface LocationState {
  drugName: string
}

export function DrugSearchConfiguration() {
  const { state } = useLocation() as WindowLocation<LocationState>

  useGate(DrugSearchConfigGate, { drugName: state?.drugName ?? '' })

  return <ConfigurationController />
}

function ContentView() {
  return (
    <ContentWithLightNavHeader>
      <PreferredPharmacyController />

      <Configuration />
    </ContentWithLightNavHeader>
  )
}

function ErrorView() {
  const { state } = useLocation() as WindowLocation<LocationState>

  return (
    <Redirect
      to={ROUTES.DRUG_SEARCH_ERROR_PATH}
      state={{ drugName: state?.drugName ?? '' }}
      noThrow
    />
  )
}

const ConfigurationController = variant({
  source: select(
    [
      [$$drugConfiguration.loadOptionsFx.pending, 'loading'],
      [preferredPharmacyQuery.$pending, 'loading'],
      [$$drugConfiguration.$hasError, 'error'],
    ],
    'content',
  ),
  cases: {
    loading: Spinner,
    error: ErrorView,
    content: ContentView,
  },
})

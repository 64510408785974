import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import DISMISS_CUSTOM_FEED_ITEM_MUTATION from 'src/apollo/mutations/DISMISS_CUSTOM_FEED_ITEM_MUTATION'
import CUSTOM_FEED_ITEMS_QUERY from 'src/apollo/queries/CUSTOM_FEED_ITEMS_QUERY'
import { getGraphQlError } from 'src/utils/errorHelpers'

interface UseDismissCustomFeedItemResponse {
  dismissCustomFeedItem: {
    success: boolean
    errors: {
      key: string
      messages: string
    }
  }
}

interface UseDismissCustomFeedItemVariables {
  feed_item_type: string
}

export default function useDismissCustomFeedItemMutation() {
  const [handleMutation, { loading }] = useMutation<
    UseDismissCustomFeedItemResponse,
    UseDismissCustomFeedItemVariables
  >(DISMISS_CUSTOM_FEED_ITEM_MUTATION, {
    refetchQueries: [{ query: CUSTOM_FEED_ITEMS_QUERY }],
  })

  const handleDismissCustomFeedItem = useCallback(
    async (variables: UseDismissCustomFeedItemVariables) => {
      try {
        return await handleMutation({ variables })
      } catch (err) {
        const gqlError = getGraphQlError(err)
        return gqlError
      }
    },
    [handleMutation],
  )

  return {
    handleDismissCustomFeedItem,
    dismissCustomFeedItemLoading: loading,
  }
}

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import FavouriteIcon from 'src/components/SvgIcons/FavouriteIcon'
import LocatorIcon from 'src/components/SvgIcons/LocatorIcon'
import { Sprite } from 'src/shared/ui/Sprite'
import { ModalBase, IconSvgBase } from 'src/theme/baseStyles'
import { primaryWhite, secondaryGrey, primaryBlue } from 'src/theme/colors'
import { heading4_21, text2_14, text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ActionConfrimationModal({
  toggleTooltip,
  actionPrimary,
  actionSecondary,
  actionType,
  additionalInfo = '',
  isFacility,
}) {
  const { t } = useTranslation()

  const i18n = {
    titleDoctor: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.doctor.title',
    ),
    descriptionDoctor: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.doctor.description',
    ),

    titleFacility: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.facility.title',
    ),
    descriptionFacility: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.facility.description',
    ),

    callButtonDoctor: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.doctor.call_button',
      {
        insert: additionalInfo,
      },
    ),
    callButtonFacility: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.facility.call_button',
      {
        insert: additionalInfo,
      },
    ),
    directionsButton: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.directions_button',
    ),
    careTeamButton: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.care_team_button',
    ),

    chatButton: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.chat_button',
    ),
    dismissButton: t(
      'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.dismiss_button',
    ),
  }

  const getSecondaryButtonText = () => {
    switch (actionType) {
      case ACTION_TYPE.CALL_PHONE:
        return isFacility ? i18n.callButtonFacility : i18n.callButtonDoctor
      case ACTION_TYPE.FIND_DIRECTIONS:
        return i18n.directionsButton
      case ACTION_TYPE.TOGGLE_CARE_TEAM:
        return i18n.careTeamButton
      default:
        // must not happen
        return ''
    }
  }

  const getSecondaryButtonIcon = () => {
    switch (actionType) {
      case ACTION_TYPE.CALL_PHONE:
        return CallIcon
      case ACTION_TYPE.FIND_DIRECTIONS:
        return LocatorIcon
      case ACTION_TYPE.TOGGLE_CARE_TEAM:
        return FavouriteIcon
      default:
        // must not happen
        return ''
    }
  }

  const secondaryButtonText = getSecondaryButtonText()
  const SecondaryIcon = getSecondaryButtonIcon()

  const preventBubbling = (ev) => ev.stopPropagation()
  return (
    <ModalRoot onClick={toggleTooltip}>
      <ModalWrapper onClick={preventBubbling}>
        <ModalHeader>
          <ModalCloseAction>
            <ButtonClose40 border={false} withShadow onClick={toggleTooltip} />
          </ModalCloseAction>
        </ModalHeader>

        <ModalTitle>
          {isFacility ? i18n.titleFacility : i18n.titleDoctor}
        </ModalTitle>
        <ModalContent>
          {isFacility ? i18n.descriptionFacility : i18n.descriptionDoctor}
        </ModalContent>

        <PrimaryActionButton onClick={actionPrimary}>
          {i18n.chatButton}
        </PrimaryActionButton>
        <SecondaryActionButton onClick={actionSecondary}>
          <IconWrapper>
            <SecondaryIcon fill={primaryBlue} />
          </IconWrapper>
          {secondaryButtonText}
        </SecondaryActionButton>
        <DismissButton onClick={toggleTooltip}>
          {i18n.dismissButton}
        </DismissButton>
      </ModalWrapper>
    </ModalRoot>
  )
}

ActionConfrimationModal.propTypes = {
  toggleTooltip: PropTypes.func.isRequired,
  actionPrimary: PropTypes.func.isRequired,
  actionSecondary: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string,
  isFacility: PropTypes.bool.isRequired,
}

export const ACTION_TYPE = {
  CALL_PHONE: 'phone',
  FIND_DIRECTIONS: 'directions',
  TOGGLE_CARE_TEAM: 'care_team',
  NONE: 'none', // modal state
}

export default ActionConfrimationModal

const ModalRoot = styled.div`
  ${ModalBase};
  background: rgba(46, 49, 65, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 3;
`

const ModalWrapper = styled.div`
  background: ${primaryWhite};
  padding: ${convertPxToRem(24, 36, 36, 36)};
  border-radius: ${convertPxToRem(20)};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
  max-width: ${convertPxToRem(500)};
  width: 100%;
  ${mediaQueryFor.desktop} {
    padding: ${convertPxToRem(24, 36, 36)};
  }
`

const ModalHeader = styled.div`
  padding: ${convertPxToRem(0)};
`

const ModalCloseAction = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ModalTitle = styled.span`
  ${heading4_21};
`

const IconWrapper = styled.div`
  ${IconSvgBase};
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
`

const CallIcon = styled(Sprite).attrs({ name: 'misc/call' })``

const ModalContent = styled.div`
  ${text1_16};
  color: ${secondaryGrey};

  margin-top: ${convertPxToRem(24)};
`

const PrimaryActionButton = styled.div`
  ${text2_14};
  background-color: ${primaryBlue};
  color: ${primaryWhite};

  width: 100%;
  height: ${convertPxToRem(40)};

  padding: ${convertPxToRem(8, 16)};
  margin-top: ${convertPxToRem(32)};
  border-radius: ${convertPxToRem(20)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

const SecondaryActionButton = styled.div`
  ${text2_14};
  background-color: ${primaryWhite};
  color: ${primaryBlue};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: ${convertPxToRem(8)};

  width: 100%;
  height: ${convertPxToRem(40)};

  padding: ${convertPxToRem(8, 16)};
  margin-top: ${convertPxToRem(16)};
  border: ${convertPxToRem(1)} solid ${secondaryGrey};
  border-radius: ${convertPxToRem(20)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

const DismissButton = styled.div`
  ${text2_14};
  font-weight: 500;

  color: ${secondaryGrey};

  width: 100%;
  height: ${convertPxToRem(40)};

  padding: ${convertPxToRem(9, 0)};
  margin-top: ${convertPxToRem(16)};

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  flex-grow: 0;
`

import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { CANCEL_APPOINTMENT_TIME_SLOT_MUTATION } from './document'

export function useCancelAppointmentTimeSlotMutation() {
  const [cancelAppointmentTimeSlotM, { loading: isCancellationPending }] =
    useMutation(CANCEL_APPOINTMENT_TIME_SLOT_MUTATION, { errorPolicy: 'none' })

  const cancelTimeSlot = useCallback(
    (timeSlot: string) =>
      cancelAppointmentTimeSlotM({ variables: { timeSlot } }),
    [cancelAppointmentTimeSlotM],
  )

  return {
    cancelTimeSlot,
    isCancellationPending,
  }
}

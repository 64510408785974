import { gql } from '@apollo/client'

const UPDATE_TUTORIAL_MUTATION = gql`
  mutation updateTutorial($attributes: CurrentUserAttributesInput!) {
    updateCurrentUser(attributes: $attributes) {
      id
      tooltips_viewed
    }
  }
`

export default UPDATE_TUTORIAL_MUTATION

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react'

// cannot use useNavigate here
// eslint-disable-next-line no-restricted-imports
import { navigate } from '@reach/router'
import { useUnit } from 'effector-react'
// @ts-expect-error qs types
import { stringify as stringifyQuery } from 'qs'
import { useDispatch } from 'react-redux'

import { ROUTES } from 'src/constants/routesConstants'
import { loggingLineCreated } from 'src/entities/logging'
import { openChatThread, startNewChat } from 'src/store/slices/chats'
import {
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'
import { AppDispatch } from 'src/store/store'

enum Resource {
  Appointments = 'appointments',
  BillClaim = 'bill-claim',
  BillClaimsList = 'bill-claims-list',
  CareSpecialist = 'specialist-select',
  CarePlan = 'care-plan',
  GetCare = 'get-care',
  NewBillClaim = 'new-bill-claim',
  NewChat = 'new-chat',
  Chat = 'chat',
  Wellness = 'wellness',
}

interface ResourceHandlerParams {
  /**
   * Matched path from deeplinkTo
   */
  path: string
  /**
   * Current path in the address bar
   */
  currentPath: string
  /**
   * Redux store dispatch
   */
  dispatch: AppDispatch
}

interface ResourceConfig {
  handler: (params: ResourceHandlerParams) => void | Promise<void>
  filter: (params: ResourceHandlerParams) => boolean
}

const defaultResourceConfig: ResourceConfig = {
  handler: ({ path }: ResourceHandlerParams) => navigate(path),
  filter: ({ path, currentPath }: ResourceHandlerParams) =>
    path !== currentPath,
}

const RESOURCE_MATCHERS: Record<Resource, RegExp> = {
  [Resource.Appointments]: /^\/appointment\/(\d+)/i,
  [Resource.BillClaim]: /^\/bill-claim\/(\d+)/i,
  [Resource.BillClaimsList]: /^\/home\/bill-claims\/active/i,
  [Resource.CarePlan]: /^\/care-plan\/(\d+)/i,
  [Resource.CareSpecialist]: /^\/find-care\/specialist-select/i,
  [Resource.GetCare]: /^\/home\/get-care/i,
  [Resource.NewBillClaim]: /^\/new-bill-claim/i,
  [Resource.NewChat]: /^\/chats\/new/i,
  [Resource.Chat]: /^\/chat\/(?<chatId>\d+)/i,
  [Resource.Wellness]: /^\/profile\/wellness/i,
}

const RESOURCE_CONFIG: Record<Resource, ResourceConfig> = {
  [Resource.Appointments]: defaultResourceConfig,
  [Resource.BillClaim]: defaultResourceConfig,
  [Resource.BillClaimsList]: defaultResourceConfig,
  [Resource.CarePlan]: defaultResourceConfig,
  [Resource.CareSpecialist]: defaultResourceConfig,
  [Resource.GetCare]: defaultResourceConfig,
  [Resource.NewBillClaim]: defaultResourceConfig,
  [Resource.Wellness]: defaultResourceConfig,

  [Resource.NewChat]: {
    handler: ({ dispatch, currentPath }) => {
      if (RESOURCE_MATCHERS[Resource.NewChat].test(currentPath))
        void navigate(`/${ROUTES.HOME}`, { replace: true })

      dispatch(setIsShowThirdSectionModal(true))
      dispatch(setThirdSectionContent(THIRD_SECTION_CONTENT.CHAT))
      dispatch(startNewChat())
    },
    filter: () => true,
  },

  [Resource.Chat]: {
    handler: ({ dispatch, currentPath, path }) => {
      const regex = RESOURCE_MATCHERS[Resource.Chat]

      const match = path.match(regex)!
      if (regex.test(currentPath))
        void navigate(`/${ROUTES.HOME}`, { replace: true })

      dispatch(setIsShowThirdSectionModal(true))
      dispatch(setThirdSectionContent(THIRD_SECTION_CONTENT.CHAT))
      dispatch(openChatThread(match.groups!.chatId))
    },
    filter: () => true,
  },
}

const DEEPLINK_KEY = 'deeplinkTo'

function matchResource(path: string) {
  const matchers = Object.entries(RESOURCE_MATCHERS) as [Resource, RegExp][]
  // eslint-disable-next-line no-unused-vars
  const [resource] = matchers.find(([_, rule]) => rule.test(path)) ?? [null]

  return resource
}

function getPurePath() {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete(DEEPLINK_KEY)

  const search = searchParams.toString()

  const path =
    search.length > 0
      ? `${window.location.pathname}?${search}`
      : window.location.pathname

  return path
}

export function useSaveDeeplink() {
  const path = getPurePath()
  const resource = useMemo(() => matchResource(path), [path])

  const log = useUnit(loggingLineCreated)

  useEffect(() => {
    if (resource) {
      log({
        line: `Deeplink saved`,
        meta: { on: 'deeplink', resource },
      })

      const route = [
        window.location.pathname,
        '?',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        stringifyQuery({ deeplinkTo: path }, { encode: false }),
      ].join('')

      void navigate(route, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useHasDeeplink() {
  const hasMatch = useMemo(() => {
    const search = new URLSearchParams(window.location.search)

    const path = search.get(DEEPLINK_KEY) || ''

    const match = matchResource(path)
    return match !== null
  }, [])

  return hasMatch
}

export function useRestoreDeeplink() {
  const dispatch = useDispatch()

  const log = useUnit(loggingLineCreated)

  const [resource, matchedPath] = useMemo(() => {
    const search = new URLSearchParams(window.location.search)
    const path = search.get(DEEPLINK_KEY) || ''

    return [matchResource(path), path]
  }, [])

  useEffect(() => {
    const currentPath = window.location.pathname

    const modifyHistory = async (config: ResourceConfig) => {
      await navigate(getPurePath(), { replace: true })

      const params: ResourceHandlerParams = {
        path: matchedPath,
        dispatch,
        currentPath,
      }

      if (config.filter(params)) await config.handler(params)
    }

    if (resource) {
      const config = RESOURCE_CONFIG[resource]

      log({
        line: 'Deeplink restored',
        meta: { on: 'deeplink', resource },
      })

      void modifyHistory(config)
    }
  }, [matchedPath, resource, dispatch, log])
}

import {
  attach,
  combine,
  createDomain,
  createStore,
  sample,
  split,
} from 'effector'
import { createGate } from 'effector-react'
import { spread } from 'patronum'

import { ROUTES } from 'src/constants/routesConstants'
import { $$drugConfiguration } from 'src/entities/drugConfiguration'
import { Coordinates } from 'src/entities/geolocation'
import { $$navigate, NavigateParams } from 'src/entities/navigate'
import { preferredPharmacyQuery } from 'src/entities/pharmacy'
import { $$pharmacySearch } from 'src/features/pharmacySearch'
import { remap } from 'src/shared/lib/effector/remap'
import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'

import { filterSavedConfig } from './helpers'
import {
  ConfigChangedEvent,
  ConfigType,
  SavedConfig,
  ValidationErrors,
} from './types'

interface DrugSearchConfigGateGateProps {
  drugName: string
}

export const DrugSearchConfig = createDomain('DrugSearchConfig')
export const DrugSearchConfigGate = createGate<DrugSearchConfigGateGateProps>({
  name: 'DrugSearchConfigGate',
  domain: DrugSearchConfig,
})

export const drugSearchConfigChanged =
  DrugSearchConfig.event<ConfigChangedEvent>()
export const drugSearchConfigApplied = DrugSearchConfig.event()
export const preferredPharmacyChangeStarted =
  DrugSearchConfig.event<Coordinates>()

const resetShape = split(drugSearchConfigChanged, {
  [ConfigType.Form]: ({ type }) => type === ConfigType.Form,
  [ConfigType.Dosage]: ({ type }) => type === ConfigType.Dosage,
  [ConfigType.TypeOfQuantity]: ({ type }) => type === ConfigType.TypeOfQuantity,
})

export const openNextScreenFx = attach({
  effect: $$navigate.navigateFx,
  source: remap(DrugSearchConfigGate.state, 'drugName'),
  mapParams({ to, state = {} }: NavigateParams, drugName) {
    return {
      to,
      state: { ...state, drugName },
    }
  },
})

const $daysSupply = createStore('').reset($$drugConfiguration.reset)
const $daysSupplyCustom = createStore('').reset($$drugConfiguration.reset)

export const $isCustomDaysSupplyValue = $daysSupply.map(
  (value) => value === 'custom',
)

const $form = createStore('').reset($$drugConfiguration.reset)
const $dosage = createStore('').reset(
  $$drugConfiguration.reset,
  resetShape.form,
)
const $typeOfQuantity = createStore('').reset(
  $$drugConfiguration.reset,
  resetShape.form,
  resetShape.dosage,
)
const $quantity = createStore('').reset($$drugConfiguration.reset)

export const configFields = {
  $daysSupply,
  $daysSupplyCustom,
  $form,
  $dosage,
  $typeOfQuantity,
  $quantity,
}

const $savedConfig = DrugSearchConfig.store<SavedConfig>({
  [ConfigType.DaysSupply]: '',
  [ConfigType.DaysSupplyCustom]: '',
  [ConfigType.Form]: '',
  [ConfigType.Dosage]: '',
  [ConfigType.TypeOfQuantity]: '',
  [ConfigType.Quantity]: '',
}).reset($$drugConfiguration.reset)

export const defaultValidationState: ValidationErrors = {
  [ConfigType.DaysSupply]: null,
  [ConfigType.DaysSupplyCustom]: null,
  [ConfigType.Form]: null,
  [ConfigType.Dosage]: null,
  [ConfigType.TypeOfQuantity]: null,
  [ConfigType.Quantity]: null,
}

export const $drugConfigOptionsMap = $$drugConfiguration.$options.map(
  (configs) =>
    configs.flatMap(({ dosages, form }) =>
      dosages.flatMap(({ packages, dosage }) =>
        packages.map((pack) => ({ form, dosage, ...pack })),
      ),
    ),
)

const $ndc = combine(
  $typeOfQuantity,
  $dosage,
  $drugConfigOptionsMap,
  (typeOfQuantity, dosage, drugs) =>
    drugs.find(
      (drug) => drug.package === typeOfQuantity && drug.dosage === dosage,
    )?.ndc ?? '',
)

const $gpi = combine(
  $typeOfQuantity,
  $dosage,
  $drugConfigOptionsMap,
  (typeOfQuantity, dosage, drugs) =>
    drugs.find(
      (drug) => drug.package === typeOfQuantity && drug.dosage === dosage,
    )?.gpi ?? '',
)

export const $currentConfigState = combine({
  daysSupply: $daysSupply,
  daysSupplyCustom: $daysSupplyCustom,
  form: $form,
  dosage: $dosage,
  quantity: $quantity,
  typeOfQuantity: $typeOfQuantity,
  ndc: $ndc,
  gpi: $gpi,
})

export const $isFormFieldAvailable = combine(
  $currentConfigState,
  ({ daysSupply, daysSupplyCustom }) =>
    Boolean(daysSupplyCustom || (daysSupply && daysSupply !== 'custom')),
)

export const $drugFormOptions = $$drugConfiguration.$options.map((configs) =>
  configs.reduce((acc, { form }) => ({ ...acc, [form]: form }), {}),
)

export const $drugDosageOptions = combine(
  $form,
  $drugConfigOptionsMap,
  (currentForm, configOptions) =>
    configOptions
      .filter((config) => config.form === currentForm)
      .reduce((acc, { dosage }) => ({ ...acc, [dosage]: dosage }), {}),
)

export const $drugQuantityTypeOptions = combine(
  $form,
  $dosage,
  $drugConfigOptionsMap,
  (currentForm, currentDosage, configOptions) =>
    configOptions
      .filter(
        (config) =>
          config.form === currentForm && config.dosage === currentDosage,
      )
      .reduce((acc, item) => ({ ...acc, [item.package]: item.package }), {}),
)

spread({
  source: drugSearchConfigChanged.map(({ type, value }) => ({ [type]: value })),
  targets: {
    [ConfigType.DaysSupply]: $daysSupply,
    [ConfigType.DaysSupplyCustom]: $daysSupplyCustom,
    [ConfigType.Form]: $form,
    [ConfigType.Dosage]: $dosage,
    [ConfigType.TypeOfQuantity]: $typeOfQuantity,
    [ConfigType.Quantity]: $quantity,
  },
})

spread({
  source: sample({
    clock: $$drugConfiguration.$options,
    source: { savedConfig: $savedConfig, options: $drugConfigOptionsMap },
    filter: ({ savedConfig }) => Boolean(savedConfig),
    fn: ({ savedConfig, options }) => filterSavedConfig(savedConfig, options),
  }),
  targets: {
    [ConfigType.DaysSupply]: $daysSupply,
    [ConfigType.DaysSupplyCustom]: $daysSupplyCustom,
    [ConfigType.Form]: $form,
    [ConfigType.Dosage]: $dosage,
    [ConfigType.TypeOfQuantity]: $typeOfQuantity,
    [ConfigType.Quantity]: $quantity,
  },
})

sample({
  source: DrugSearchConfigGate.open,
  target: $$drugConfiguration.loadOptionsFx,
})

sample({
  source: DrugSearchConfigGate.open,
  target: preferredPharmacyQuery.refresh,
})

sample({
  clock: sample({
    clock: DrugSearchConfigGate.open,
    source: $$drugSearchConfigurationStorage.$state,
    fn: (savedDrugs, { drugName }) =>
      savedDrugs.find((drug) => drug.drugName === drugName)?.config,
  }),
  filter: Boolean,
  target: $savedConfig,
})

sample({
  source: DrugSearchConfigGate.close,
  target: $$drugConfiguration.reset,
})

sample({
  clock: preferredPharmacyChangeStarted,
  target: $$pharmacySearch.$preferredPharmacyCoordinates,
})

sample({
  clock: preferredPharmacyChangeStarted,
  target: openNextScreenFx.prepend(() => ({
    to: `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_PHARMACY_RESULTS_PATH}`,
  })),
})

sample({
  clock: drugSearchConfigChanged,
  filter: ({ type }) => type === ConfigType.DaysSupply,
  fn: ({ value }) => (value === 'custom' ? '' : value),
  target: $daysSupplyCustom,
})

import { ChangeEventHandler } from 'react'

import styled from 'styled-components'

import { InlineInputError } from 'src/shared/ui/InlineInputError'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TextInputProps {
  value?: string
  placeholder?: string
  onChange: (val: string) => void
  onClear?: () => void
  error?: string | null
  className?: string
  dataTestProp?: string
}

export function TextInput({
  placeholder = '',
  value = '',
  onChange,
  onClear = undefined,
  error = null,
  className = undefined,
  dataTestProp = 'textInput',
}: TextInputProps) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    onChange(target.value)
  }

  const withClearButton = Boolean(onClear && value)
  const hasError = Boolean(error)

  return (
    <Root className={className}>
      <Input
        data-test={dataTestProp}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        data-has-error={hasError}
      />

      <ButtonClear
        data-with-clear-btn={withClearButton}
        onClick={onClear}
        data-test="textInput.clearButton"
      >
        <Sprite name="misc/xmark" />
      </ButtonClear>

      {hasError && <InlineInputError>{error as string}</InlineInputError>}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${convertPxToRem(4)};
`

const Input = styled.input`
  width: 100%;
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(16)};

  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};

  background-color: ${primaryWhite};
  color: ${primaryDarkNavy};

  ${text1_16};

  ::placeholder {
    color: ${secondaryMidGrey};
  }

  &[data-has-error='true'] {
    border-color: ${statusErrorRed};
  }
`

// ToDo: Get rid of the duplicate wrappers (NEX-17802)
const ButtonClear = styled.div`
  display: none;
  position: absolute;
  right: 0;

  width: ${convertPxToRem(48)};
  height: ${convertPxToRem(48)};

  cursor: pointer;

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }

  &[data-with-clear-btn='true'] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

import { MouseEventHandler } from 'react'

import { variant, list } from '@effector/reflect'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'
import { primaryBlue, secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RecentSearchItem } from './RecentSearchItem'

const $hasRecentSearches = $$drugSearchConfigurationStorage.$state.map(
  (savedDrugs) => savedDrugs.length > 0,
)

const Items = list({
  view: RecentSearchItem,
  source: $$drugSearchConfigurationStorage.$state,
  mapItem: {
    name: ({ drugName }) => drugName,
  },
  getKey: ({ drugName }) => drugName,
})

export const RecentSearches = variant({
  if: $hasRecentSearches,
  then: RecentSearchesComponent,
  else: () => null,
})

function RecentSearchesComponent() {
  const { t } = useTranslation()
  const i18n = {
    recentSearches: t(
      'translation.medications.drug_search.labels.recent_searches',
    ),
    clearAll: t('translation.shared.buttons.clear_all'),
  }

  const { clearConfigs } = useUnit($$drugSearchConfigurationStorage)

  const handleClearAll: MouseEventHandler<HTMLSpanElement> = (event) => {
    event.stopPropagation()
    clearConfigs()
  }

  return (
    <Root data-test="recentSearches">
      <CounterSection>
        <Title>{i18n.recentSearches}</Title>
        <ClearButton
          data-test="recentSearches.clearButton"
          onClick={handleClearAll}
        >
          {i18n.clearAll}
        </ClearButton>
      </CounterSection>

      <List>
        <Items />
      </List>
    </Root>
  )
}

const CounterSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${convertPxToRem(24)};

  width: 100%;
`
const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(13)};

  width: 100%;
`
const List = styled(Root)`
  flex-direction: column-reverse;
`
const Title = styled.span`
  color: ${secondaryGrey};
  ${capsHeading1_12};
  text-transform: uppercase;
`
const ClearButton = styled.span`
  color: ${primaryBlue};
  ${textLink2_14};
  cursor: pointer;
`

import React from 'react'

import styled from 'styled-components'

import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import { ModalBox } from 'src/shared/ui/ModalBox'
import { primaryWhite, primaryBlue, secondaryGrey } from 'src/theme/colors'
import { heading4_21, text1_16, textLink1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface DisclaimerModalProps {
  onClose: React.MouseEventHandler<HTMLDivElement>
  children: React.ReactElement | React.ReactElement[]
  dataTestProp?: string
}

export function DisclaimerModal({
  onClose,
  children,
  dataTestProp = 'disclaimerModal',
}: DisclaimerModalProps) {
  return (
    <ModalBox handleClick={onClose} dataTestProp={dataTestProp}>
      <Wrapper>
        <ButtonCloseWrapper>
          <ButtonClose40
            border="none"
            withShadow
            onClick={onClose}
            dataTestProp={`${dataTestProp}.buttonClose`}
          />
        </ButtonCloseWrapper>
        {children}
      </Wrapper>
    </ModalBox>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(24, 36, 36)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(30, 24, 40)};
  }
`

const ButtonCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${convertPxToRem(20)};

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

const ModalTitle = styled.span`
  display: block;
  margin-bottom: ${convertPxToRem(24)};

  ${heading4_21};

  ${mediaQueryFor.mobile} {
    margin-bottom: ${convertPxToRem(16)};

    text-align: center;
  }
`

const ModalDescription = styled.span`
  display: block;
  margin-bottom: ${convertPxToRem(32)};
  white-space: pre-wrap;

  color: ${secondaryGrey};
  ${text1_16};

  ${mediaQueryFor.mobile} {
    margin-bottom: ${convertPxToRem(24)};
  }
`

const ButtonPrimary = styled.button`
  width: 100%;
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(10, 30)};

  border: 0;
  border-radius: ${convertPxToRem(36)};

  background-color: ${primaryBlue};
  color: ${primaryWhite};
  ${textLink1_16};
`

const ButtonSecondary = styled(ButtonPrimary)`
  margin-top: ${convertPxToRem(16)};

  background-color: transparent;
  color: ${primaryBlue};

  ${mediaQueryFor.mobile} {
    margin-top: ${convertPxToRem(8)};
  }
`

DisclaimerModal.Title = ModalTitle
DisclaimerModal.Description = ModalDescription
DisclaimerModal.PrimaryAction = ButtonPrimary
DisclaimerModal.SecondaryAction = ButtonSecondary

import { gql, TypedDocumentNode } from '@apollo/client'

import { HasChatsResult, HasChatsVariables } from './types'

export const HAS_CHATS_QUERY: TypedDocumentNode<
  HasChatsResult,
  HasChatsVariables
> = gql`
  query hasChats {
    chats(first: 1, filters: { search: "", only_active: true })
      @connection(key: "new") {
      nodes {
        id
      }
    }
  }
`

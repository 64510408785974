import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import { Coordinates } from 'src/entities/geolocation'
import { $preferredPharmacy, PreferredPharmacy } from 'src/entities/pharmacy'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { getAddressField } from 'src/shared/lib/getAddressField'
import { RefreshIcon } from 'src/shared/ui/icons/misc/RefreshIcon'
import {
  primaryBlue,
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading5_19, text2_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { preferredPharmacyChangeStarted } from '../model/core'

interface PreferredPharmacyCardProps {
  pharmacy: PreferredPharmacy
  changePharmacy: (coords: Coordinates) => void
}

function PreferredPharmacyCardComponent({
  pharmacy,
  changePharmacy,
}: PreferredPharmacyCardProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const startChangingPharmacy = () => {
    changePharmacy({
      latitude: pharmacy.latitude,
      longitude: pharmacy.longitude,
    })
  }

  return (
    <Card>
      <PharmacyName>{pharmacy?.name}</PharmacyName>
      <ChangeButton
        onClick={startChangingPharmacy}
        data-test="preferredPharmacyCard.change"
      >
        <RefreshIcon />
        {t('buttons.change')}
      </ChangeButton>
      <Address>{getAddressField(pharmacy)}</Address>
    </Card>
  )
}

const Card = styled.div`
  display: grid;
  gap: ${convertPxToRem(8)};
  grid-template-columns: 1fr;
  grid-template-areas: 'name change' 'address address';

  border: 1px solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};

  padding: ${convertPxToRem(16, 20)};
  width: 100%;

  box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);
`
const PharmacyName = styled.span`
  grid-area: name;
  ${heading5_19};
  color: ${primaryDarkNavy};
`
const ChangeButton = styled.button`
  grid-area: change;
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(4)};

  ${textLink2_14};
  color: ${primaryBlue};

  border: none;
  background: none;
  padding: 0;
`
const Address = styled.span`
  grid-area: address;
  ${text2_14};
  color: ${secondaryGrey};
`

export const PreferredPharmacyCard = reflect({
  view: PreferredPharmacyCardComponent,
  bind: {
    pharmacy: $preferredPharmacy.map((pharmacy) => pharmacy!),
    changePharmacy: preferredPharmacyChangeStarted,
  },
})

import { sample } from 'effector'
import { createGate } from 'effector-react'

import { $$navigate } from 'src/entities/navigate'
import {
  $$pharmacySearch,
  drugSearchPharmaciesQuery,
  selectPharmacyFx,
} from 'src/features/pharmacySearch'
import { DEFAULT_COORDS } from 'src/utils/locationHelpers'

export const PreferredPharmacyMapGate = createGate<{ drugName: string }>({
  name: 'PreferredPharmacyMapGate',
})

export const $markers = $$pharmacySearch.$foundPharmacies.map((pharmacies) =>
  pharmacies.map((pharmacy) => ({
    name: pharmacy.name,
    id: pharmacy.name,
    latitude: pharmacy.latitude,
    longitude: pharmacy.longitude,
  })),
)

sample({
  clock: selectPharmacyFx.doneData,
  filter: PreferredPharmacyMapGate.status,
  target: $$navigate.backFx,
})

sample({
  clock: PreferredPharmacyMapGate.open,
  source: $$pharmacySearch.$preferredPharmacyCoordinates,
  fn: (coordinates) => coordinates ?? DEFAULT_COORDS,
  target: drugSearchPharmaciesQuery.refresh,
})

import { RouteComponentProps } from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'

import InitialDataHandler from 'src/components/InitialDataHandler'
import { FeatureFlag } from 'src/constants/featureFlags'
import { EarlyAccessController } from 'src/entities/earlyAccess/model'
import { FreshpaintController } from 'src/entities/freshpaint'
import { LaunchDarklyController } from 'src/entities/launchDarkly'
import { InactivityListener } from 'src/features/InactivityListener'
import Dashboard from 'src/sections/SectionDashboard/Dashboard'
import TutorialOverlay from 'src/sections/SectionDashboard/TutorialOverlay'
import HeaderAnimationProvider from 'src/sections/SectionHome/HeaderAnimationContext'
import { RootState } from 'src/store'

type SectionDashboardProps = ConnectedProps<typeof connector> &
  RouteComponentProps

function SectionDashboard({
  hasSessionTimeoutAccess = false,
  isInitialDataReceived = false,
}: SectionDashboardProps) {
  return (
    <HeaderAnimationProvider>
      <TutorialOverlay />
      <InitialDataHandler />
      <EarlyAccessController />

      <Dashboard />
      {isInitialDataReceived && hasSessionTimeoutAccess && (
        <InactivityListener />
      )}

      <FreshpaintController />
      <LaunchDarklyController />
    </HeaderAnimationProvider>
  )
}

const mapStateToProps = ({ features, common }: RootState) => ({
  hasSessionTimeoutAccess:
    features.featureFlags[FeatureFlag.SHARED_SESSION_TIMEOUT],
  isInitialDataReceived: common.isInitialDataReceived,
})

const connector = connect(mapStateToProps, null)
export default connector(SectionDashboard)

import { ApolloProvider } from '@apollo/client'
import { LocationProvider } from '@reach/router'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'

import client from 'src/apollo/client'
import App from 'src/App'
import { startupIngestion } from 'src/entities/logging'
import { CrashBoundary } from 'src/features/CrashBoundary'
import { store } from 'src/store'
import getReleaseName from 'src/utils/sentryHelpers/getReleaseName'

import 'src/store/effects/subscribe'
import 'src/translations/i18n'

import './reset.css'
import './index.css'

/**
 * If dynamic chunk fails, force page to reload.
 *
 * This is really bad, since
 * - User can get stuck in an infinite loop (reload is stateless)
 * - We loose all client-side state
 * - We do not prompt the user if they want to reload
 *
 * Hopefully Vite breaks up chunks in sensible places, not in a middle of the form.
 * This is still better than having an instant 'crash' page
 */
window.addEventListener('vite:preloadError', () => window.location.reload())

// Setup sentry
Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  environment: __APP_ENV__,
  release: getReleaseName(),
  enabled: ['staging', 'preproduction', 'production'].includes(__APP_ENV__),
})

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <ApolloProvider client={client}>
    <ReduxProvider store={store}>
      <CrashBoundary>
        <LocationProvider>
          <App />
        </LocationProvider>
      </CrashBoundary>
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

startupIngestion()

import { createEvent, createStore, sample, split } from 'effector'
import { persist } from 'effector-storage'

import { $$router, persistRouter } from '@shared/lib/router'

import { $$logging } from '@entities/logging'

import { deeplink } from '../lib/resources'

import { navigateFx, chatCreated, chatOpened } from './actions'
import { setup } from './setup'

export const consumed = createEvent<void>()
export const saved = createEvent<void>()

export const $deeplink = createStore<string | null>(null)

const found = sample({ clock: consumed, source: $deeplink, filter: Boolean })

sample({
  clock: saved,
  source: $$router.$path,
  filter: deeplink.is,
  target: $deeplink,
})

split({
  source: found,
  match: deeplink.match,
  cases: {
    'physical': navigateFx,
    'virtual:new-chat': chatCreated,
    'virtual:chat': chatOpened,
  },
})

sample({ clock: consumed, target: $deeplink.reinit })

sample({
  clock: saved,
  fn: () => ({ line: 'Deeplink saved', meta: { on: 'deeplink' } }),
  target: $$logging.log,
})

sample({
  clock: found,
  fn: () => ({ line: 'Deeplink restored', meta: { on: 'deeplink' } }),
  target: $$logging.log,
})

persist({
  adapter: persistRouter.query,
  pickup: setup,
  store: $deeplink,
  method: 'replace',
  key: 'deeplinkTo',
})

import { variant } from '@effector/reflect'
import { useUnit } from 'effector-react'
import { equals } from 'patronum'
import styled from 'styled-components'

import { TextInput } from 'src/features/TextInput'
import { LocationKindType } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { RadioButton } from 'src/shared/ui/buttons/RadioButton'
import { secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $editableLocation,
  $editableLocationId,
  locationUpdated,
} from '../../model/edit'
import { $availableLocationKinds } from '../../model/locations'

function LocationNameView() {
  const tLocations = useScopedTranslation(
    'translation.profile.preferred_locations',
  )
  const tErrors = useScopedTranslation('translation.shared.errors')
  const i18n = {
    [LocationKindType.HomeAddress]: tLocations('home'),
    [LocationKindType.WorkAddress]: tLocations('work'),
    [LocationKindType.OtherAddress]: tLocations('choose_name'),
  }

  const location = useUnit($editableLocation)
  const kinds = useUnit($availableLocationKinds)
  const editLocation = useUnit(locationUpdated)

  const handleSelect = (kind: LocationKindType) => () => {
    editLocation({ kind })
  }

  const handleChange = (value: string) => {
    editLocation({ name: value })
  }

  const handleClear = () => {
    editLocation({ name: '' })
  }

  if (!location) return null

  const error = location.name === '' ? tErrors('required') : null

  const hasNameInput = location.kind === LocationKindType.OtherAddress

  return (
    <Root>
      <LocationLabel>{tLocations('save_address')}</LocationLabel>

      <RadioWrapper>
        {kinds.map((item) => (
          <RadioButton
            key={item}
            isChecked={location.kind === item}
            onClick={handleSelect(item)}
          >
            {i18n[item]}
          </RadioButton>
        ))}

        {hasNameInput && (
          <TextInput
            value={location.name ?? ''}
            placeholder={tLocations('enter_name')}
            onChange={handleChange}
            onClear={handleClear}
            error={error}
          />
        )}
      </RadioWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: ${convertPxToRem(8)};
`

const LocationLabel = styled.span`
  color: ${secondaryGrey};
  text-transform: uppercase;
  ${capsHeading1_12};
`

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(8)};
`

export const LocationName = variant({
  if: equals($editableLocationId, null),
  then: LocationNameView,
})

import { attach, sample, split } from 'effector'
import { createGate } from 'effector-react'

import { EMAIL_PATTERN } from 'src/sections/SectionAuth/PasswordEntry/signHelpers'
import { validatePhone } from 'src/utils/phoneHelpers'

import {
  assistanceFormSubmitted,
  $assistanceForm,
  assistanceFormValidated,
  $assistanceRadioQuestions,
} from './core'
import { assistanceRadioQuestions } from './helpers'
import {
  AssistanceFormResponses,
  GetAssistanceGateProps,
  GetAssistanceScreenType,
  RegistrationFeedbackMutationData,
} from './types'

export const GetAssistanceGate =
  createGate<GetAssistanceGateProps>('GetAssistanceGate')

export const submitAssistanceFormFx = attach({
  source: GetAssistanceGate.state,
  effect: (
    {
      registrationFeedbackMutation,
      requestIdentifier,
      requiredIdentifiers,
      screenType,
    },
    data: RegistrationFeedbackMutationData,
  ) =>
    registrationFeedbackMutation({
      variables: {
        request_identifier: requestIdentifier,
        data: {
          assistance_type: screenType,
          ...data,
          ...requiredIdentifiers,
        },
      },
    }),
})

export const navigateToAssistanceSuccessFx = attach({
  source: GetAssistanceGate.state,
  effect: ({ navigateToSuccessScreen }, { data }) => {
    if (data.registrationLookupFeedback) {
      navigateToSuccessScreen()
    }
  },
})

sample({
  clock: assistanceFormSubmitted,
  source: $assistanceForm,
  filter: ({ email = '', phone = '' }) => {
    const hasEmailError = email.length > 0 && !EMAIL_PATTERN.test(email)
    const hasPhoneError = phone.length > 0 && !validatePhone(phone)
    return !(hasEmailError || hasPhoneError)
  },
  target: assistanceFormValidated,
})

sample({
  clock: GetAssistanceGate.open,
  fn: ({ screenType }) =>
    screenType === GetAssistanceScreenType.VerificationCode // because the verificationCode list of questions is used only in one case, and the universal one in all others
      ? assistanceRadioQuestions.verificationCode
      : assistanceRadioQuestions.universal,
  target: $assistanceRadioQuestions,
})

export const submitLockedFx = attach({
  source: GetAssistanceGate.state,
  effect: submitAssistanceFormFx,
  mapParams: (responses: AssistanceFormResponses, { email }) => ({
    email: responses.email || email,
    phone_number: responses.phone || null,
  }),
})

export const submitIneligibleFx = attach({
  source: GetAssistanceGate.state,
  effect: submitAssistanceFormFx,
  mapParams: (responses: AssistanceFormResponses, { email }) => ({
    email: responses.email || email,
    phone_number: responses.phone || null,
    organization_name: responses.companyName || null,
    any_other_names: responses.name || null,
    registered: responses.registered,
    join_in_the_last_30_days: responses.join_in_the_last_30_days,
    referred_from: responses.referred_from,
  }),
})

const submitVerificationCodeFx = attach({
  source: GetAssistanceGate.state,
  effect: submitAssistanceFormFx,
  mapParams: (
    {
      verification_code_email_inbox,
      verification_code_another_code,
    }: AssistanceFormResponses,
    { email },
  ) => ({
    email,
    verification_code_email_inbox,
    verification_code_another_code,
  }),
})

split({
  source: assistanceFormValidated,
  match: GetAssistanceGate.state.map(
    ({ screenType }) => screenType ?? GetAssistanceScreenType.Universal,
  ),
  cases: {
    [GetAssistanceScreenType.Locked]: submitLockedFx,
    [GetAssistanceScreenType.VerificationCode]: submitVerificationCodeFx,
    __: submitIneligibleFx,
  },
})

sample({
  source: submitAssistanceFormFx.doneData,
  target: navigateToAssistanceSuccessFx,
})

import { ReactElement } from 'react'

import styled from 'styled-components'

import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { NoticeBubble } from 'src/shared/ui/NoticeBubble'
import { featurePaleYellow, primaryOrange } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ConfigErrorContentProps {
  rejectMessage: string
  children: ReactElement
  dataTestProp?: string
}

export function ConfigErrorContent({
  rejectMessage,
  children,
  dataTestProp = 'configErrorContent.root',
}: ConfigErrorContentProps) {
  return (
    <Root data-test={dataTestProp}>
      <Description icon={<ExclamationIcon fill={primaryOrange} />}>
        {rejectMessage}
      </Description>
      {children}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${convertPxToRem(16)};
`
const Description = styled(NoticeBubble)`
  background-color: ${featurePaleYellow};
`

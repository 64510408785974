import { useMemo, useCallback } from 'react'

import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'

import CHATS_QUERY from 'src/apollo/queries/CHATS_QUERY'
import CHATS_EVENTS_SUBSCRIPTION from 'src/apollo/subscriptions/CHATS_EVENTS_SUBSCRIPTION'
import { extractInboxData } from 'src/sections/SectionNavigator/navigatorHelpers'

interface IUseChatsQueryArgs {
  search?: string
  policy?: WatchQueryFetchPolicy
}

function useChatsQuery({
  search = '',
  policy = 'cache-first',
}: IUseChatsQueryArgs = {}) {
  const variables = {
    first: 25,
    filters: {
      only_active: true,
      search: search || '',
    },
  }

  const { data, loading, fetchMore, networkStatus, subscribeToMore, refetch } =
    useQuery(CHATS_QUERY, {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: policy,
    })

  const subscribeInboxToEvent = useCallback(
    () =>
      subscribeToMore({
        document: CHATS_EVENTS_SUBSCRIPTION,
        updateQuery: (currentCache) => {
          refetch()
          return currentCache
        },
      }),
    [subscribeToMore, refetch],
  )

  const { inboxData, pageInfo } = useMemo(() => extractInboxData(data), [data])

  return {
    inboxData,
    isFirstDataRequest: data === undefined,
    inboxFetchMoreLoading: networkStatus === 3,
    inboxDataLoading: loading,
    inboxPageInfo: pageInfo,
    inboxFetchMore: fetchMore,
    inboxTotalCount: pageInfo.totalCount,
    subscribeInboxToEvent,
    fetchMore,
    refetch,
  }
}

export default useChatsQuery

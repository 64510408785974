/* eslint no-param-reassign: ["error", { "props": true, ignorePropertyModificationsFor: ["draft"] }] */
import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client'
import { produce } from 'immer'

import { ClientContext } from 'src/apollo/utils/types'
import type { AppStore } from 'src/store'

const LOGGING_OPERATIONS = [
  'employeeAccessibilityCheck',
  'registrationLookupQuery',
  'signInMutation',
  'signUp',
  'refreshTokens',
  'requestPasswordResetMutation',
  'verifyPasswordReset',
  'resetPasswordMutation',
  'verifyEmailSend',
  'verifyEmailCheck',
]

export class DefensiveLoggingLink extends ApolloLink {
  #store: AppStore

  constructor(store: AppStore) {
    super()

    this.#store = store
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    if (!LOGGING_OPERATIONS.includes(operation.operationName))
      return forward(operation)

    const ctx = produce(operation.getContext() as ClientContext, (draft) => {
      if (!draft.headers) draft.headers = {}

      draft.headers['Rightway-Consumer-Details'] = this.details
    })

    operation.setContext(ctx)

    return forward(operation)
  }

  private collect(): unknown {
    const { geolocation, user } = this.#store.getState()

    return {
      UserAgent: window.navigator.userAgent,
      Geolocation: geolocation.locationCheck,
      Version: __APP_VERSION__,
      Environment: __APP_ENV__,
      UserID: user.userId,
      Sequoia: user.isSequoiaUser,
      Slug: user.branding.slug,
      Screen: `${window.screen.width}x${window.screen.height}`,
      CookiesEnabled: navigator.cookieEnabled,
      Languages: navigator.languages,
    }
  }

  private get details(): string {
    let info: unknown

    try {
      info = this.collect()
    } catch (err) {
      return ''
    }

    return btoa(JSON.stringify(info))
  }
}

export default (store: AppStore) => new DefensiveLoggingLink(store)

import { gql, TypedDocumentNode } from '@apollo/client'

import { ServiceAgreement } from './types'

export const TERMS_OF_SERVICE_AGREEMENT_FRAGMENT: TypedDocumentNode<
  ServiceAgreement,
  Record<string, never>
> = gql`
  fragment serviceAgreement on TermsOfServiceAgreement {
    id
    displayName: display_name
    text
    url
    kind
  }
`

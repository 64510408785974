import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

interface EditIconProps extends IconProps {
  border?: string
}

export function EditIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = secondaryGrey,
  border = 'none',
}: EditIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 21 20"
    >
      <circle stroke={border} fill="none" r="19" cx="10" cy="10" />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.725 6.78l1.01-1.009-.001-.002c.58-.581.754-1.454.44-2.213-.314-.758-1.054-1.253-1.875-1.254-.538-.001-1.055.213-1.435.594l-1.01 1.01c-.236.236-.236.617 0 .853l2.019 2.021c.235.235.617.235.852 0zm-11.79 6.113L3.59 16.26c-.09.224-.037.48.133.65.173.166.426.218.65.134l3.365-1.347c.076-.03.145-.075.203-.134l7.104-7.104c.112-.113.176-.266.176-.425 0-.16-.064-.313-.176-.427l-2.02-2.018c-.24-.226-.614-.226-.853 0l-7.103 7.103c-.057.058-.102.127-.134.202z"
        clipRule="evenodd"
      />
    </svg>
  )
}

import React, { useState, useEffect } from 'react'

import { useUnit } from 'effector-react'

import { $$geolocation, LocationError } from 'src/entities/geolocation'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'

export default function PureGeolocationProvider() {
  const t = useScopedTranslation(
    'translation.home.care_tools.care_finder.geolocation',
  )

  const [withAlert, setWithAlert] = useState(false)
  const error = useUnit($$geolocation.$error)

  useEffect(() => {
    if (error) setWithAlert(true)
  }, [error])

  const dismissModal = () => setWithAlert(false)

  if (!withAlert) return null

  const i18n = {
    errors: {
      [LocationError.Denied]: t('error_denied'),
      [LocationError.Unavailable]: t('error_not_available'),
      [LocationError.Timeout]: t('error_timeout'),
      [LocationError.Unsupported]: t('error_not_supported'),
      [LocationError.Unknown]: t('error_unknown'),
    },
    dismiss: t('modal_button'),
  }

  const derivedErrorFromCode = error
    ? i18n.errors[error] ?? i18n.errors[LocationError.Unknown]
    : ''

  return (
    <NativeAlert description={derivedErrorFromCode}>
      <NativeAlertAction mode="secondary" onClick={dismissModal}>
        {i18n.dismiss}
      </NativeAlertAction>
    </NativeAlert>
  )
}

import { PossibleTypesMap } from '@apollo/client'

export const GraphQLTypesRelations: PossibleTypesMap = {
  CareSearchInterface: ['CareSearchSubSpecialty', 'CareSearchSpecialty'],

  PharmacyCardButtonUnion: [
    'PharmacyCardWebURLButton',
    'PharmacyCardOutboundSsoButton',
  ],

  PartnerCardButtonUnion: [
    'PartnerCardWebURLButton',
    'PartnerCardOutboundSsoButton',
  ],

  ChatMessageAttachmentUnionType: [
    'ChatMessageFileType',
    'SystemNotificationType',
  ],

  CarePlanAssigneeUnion: ['CarePlanDoctor', 'CarePlanFacility'],
}

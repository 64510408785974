/* eslint-disable no-restricted-syntax */
import { LocationAttributesInput, UsStateName } from 'src/shared/api/types'

const UsTerritory = new Set<UsStateName>([UsStateName.Vi, UsStateName.Pr])

export function mapGeocodedAddress(source: google.maps.GeocoderResult) {
  const result: Omit<LocationAttributesInput, 'kind' | 'is_preferred'> = {
    latitude: source.geometry.location.lat(),
    longitude: source.geometry.location.lng(),

    address_line_1: '',
    zip: '',
  }

  for (const component of source.address_components) {
    for (const type of component.types) {
      switch (type) {
        case 'postal_code':
          result.zip = component.long_name
          break

        case 'premise': // premise (named group) can be used instead of street number
        case 'street_number':
          result.address_line_1 = result.address_line_1
            ? `${component.long_name} ${result.address_line_1}`
            : component.long_name
          break

        case 'route':
          result.address_line_1 = result.address_line_1
            ? `${result.address_line_1} ${component.long_name}`
            : component.long_name
          break

        case 'administrative_area_level_1':
          result.state = component.short_name as UsStateName
          break

        case 'neighborhood':
        case 'sublocality':
        case 'locality':
          result.city = component.long_name
          break

        case 'country':
          // Special treatment for unincorporated US territory
          if (UsTerritory.has(component.short_name as UsStateName))
            result.state = component.short_name as UsStateName
          break
      }
    }
  }

  return result
}

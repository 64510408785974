import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { WrappedRxCard } from './WrappedRxCard'

interface RxCardModalProps {
  handlerCard: () => void
}

export function RxCardModal({ handlerCard }: RxCardModalProps) {
  const { t } = useTranslation()

  const i18n = {
    close: t('translation.shared.buttons.button_close'),
  }

  return (
    <Overlay onClick={handlerCard} data-test="rxCardModal.overlay">
      <Modal onClick={(e) => e.stopPropagation()} data-test="rxCardModal">
        <ModalContent>
          <CloseButton
            fill={secondaryGrey}
            border="none"
            withShadow
            onClick={handlerCard}
            dataTestProp="rxCardModal.closeButton"
          />
          <OverlayCard data-test="rxCardModal.card">
            <WrappedRxCard />
          </OverlayCard>
        </ModalContent>
        <ButtonBottom
          theme="pbm"
          title={i18n.close}
          handleClick={handlerCard}
          dataTestProp="rxCardModal.buttonBottom"
        />
      </Modal>
    </Overlay>
  )
}

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  padding: ${convertPxToRem(24, 16)};

  background-color: rgba(0, 0, 0, 0.5);

  ${mediaQueryFor.desktop} {
    justify-content: center;
    align-items: center;
  }
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(24)};

  ${mediaQueryFor.desktop} {
    justify-content: end;
    gap: ${convertPxToRem(32)};
    padding: ${convertPxToRem(24, 36, 36)};

    width: ${convertPxToRem(500)};

    border-radius: ${convertPxToRem(20)};
    background-color: ${primaryWhite};
  }
`

const OverlayCard = styled.div`
  z-index: 4;
`

const ModalContent = styled.div``

const CloseButton = styled(ButtonClose40)`
  display: none;

  ${mediaQueryFor.desktop} {
    display: flex;
    margin-left: auto;
  }
`

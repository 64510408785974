import { TypedDocumentNode, gql } from '@apollo/client'

import {
  DoctorCareTeamFavoriteFragment,
  CarePlanDoctorFavoriteFragment,
} from './types'

export const DOCTOR_CARE_TEAM_FAVORITE_FRAGMENT: TypedDocumentNode<
  DoctorCareTeamFavoriteFragment,
  Record<string, never>
> = gql`
  fragment doctorCareTeamFavoriteFragment on Doctor {
    id

    isFavorite: is_added_to_care_team
  }
`

export const DOCTOR_CARE_PLAN_FAVORITE_FRAGMENT: TypedDocumentNode<
  CarePlanDoctorFavoriteFragment,
  Record<string, never>
> = gql`
  fragment carePlanDoctorFavoriteFragment on CarePlanDoctor {
    id

    isFavorite: is_added_to_care_team
  }
`

import React from 'react'

interface FilterIconProps {
  className?: string
}

export function FilterIcon({ className = undefined }: FilterIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M.122 1.716A1.245 1.245 0 0 1 1.25 1h13.5a1.252 1.252 0 0 1 .966 2.044L10 10.028V14a.999.999 0 0 1-1.6.8l-2-1.5a.993.993 0 0 1-.4-.8v-2.472L.281 3.041a1.247 1.247 0 0 1-.159-1.325z"
        fill="#fff"
      />
    </svg>
  )
}

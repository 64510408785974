import React from 'react'

import { Link, Redirect, useLocation } from '@reach/router'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

import useBrandingUserQuery from 'src/apollo/hooks/useBrandingUserQuery'
import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { capitalize } from 'src/sections/SectionAuth/authHelpers'
import CarouselItem from 'src/sections/SectionAuth/CarouselItem'
import {
  primaryBlue,
  secondaryLightGrey,
  secondaryBackgroundGrey,
  primaryWhite,
} from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { getSlug } from 'src/utils/currentUserHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { StorageContainer, useLocalStorageState } from 'src/utils/storage'

function Welcome() {
  const { t } = useTranslation()
  const i18n = {
    buttonGetStarted: t('translation.auth.welcome.button_getstarted'),
    welcomeTitle: t('translation.auth.wizard.screens.welcome.title'),
    welcomeTitleEmbright: t(
      'translation.auth.wizard.screens.welcome.title_embright',
    ),
  }
  const location = useLocation()
  const slug = getSlug(location)

  const { branding, isLoadingBranding } = useBrandingUserQuery(slug)
  const { logoUrl, welcomeText, carouselItems } = branding
  const { email } = useLocalStorageState(StorageContainer.persistent)

  if (email) {
    return <Redirect to={ROUTES.EMAIL_ENTRY} noThrow />
  }

  if (isLoadingBranding) {
    return <Spinner />
  }

  const slides = [
    {
      imageUrl: logoUrl,
      title: welcomeText,
      description: t('translation.auth.wizard.screens.welcome.description'),
    },
  ]

  slides.push(...carouselItems)

  const items = slides.map((item) => (
    <CarouselItem key={item.title} item={item} />
  ))

  return (
    <Root>
      <Wrapper>
        <Top>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay
            interval={2000}
            infiniteLoop
            swipeable
            emulateTouch
            renderIndicator={(onClickHandler, isSelected, index) => (
              <DotItem
                key={index}
                onClick={onClickHandler}
                isActive={isSelected}
              />
            )}
          >
            {items}
          </Carousel>
        </Top>
        <Bottom>
          <GetStartedLink to={`${ROUTES.EMAIL_ENTRY}${location.search}`}>
            <ButtonBottom
              theme="primary"
              title={capitalize(i18n.buttonGetStarted)}
            />
          </GetStartedLink>
        </Bottom>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${secondaryBackgroundGrey};
  ${mediaQueryFor.mobile} {
    background-color: ${primaryWhite};
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-width: ${convertPxToRem(500)};
  border-radius: ${convertPxToRem(44)};
  background-color: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(0, 0, 0, 0.1);
  padding: ${convertPxToRem(40, 32, 26)};
  ${mediaQueryFor.mobile} {
    border-radius: 0;
    width: 100vw;
    box-shadow: none;
  }
`

const Top = styled.div`
  width: 100%;
  margin-bottom: ${convertPxToRem(15)};
`

const Bottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`
const GetStartedLink = styled(Link)``
const DotItem = styled.div`
  width: 6px; // we use pixels here instead of rem because the size of the middle dot
  height: 6px; // set in rems changes in fact in a browser.
  display: inline-block;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? primaryBlue : secondaryLightGrey};
  margin-right: ${convertPxToRem(6)};
  cursor: pointer;
`

export default Welcome

import React, { memo } from 'react'

import styled from 'styled-components'

import { secondaryLightGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface IconSvg40Props {
  onClick?: () => void
  IconSvg: React.ComponentType<{ fill?: string }>
  bgColor?: string
  withShadow?: boolean
  border?: string
  fill: string
  brRadius?: string
  cursor?: string
  dataTestProp?: string
  width?: string
  height?: string
}

function IconSvg40Component({
  onClick = () => {},
  IconSvg,
  bgColor = '',
  withShadow = false,
  border = `${convertPxToRem(1)} solid ${secondaryLightGrey}`,
  fill,
  brRadius = '50%',
  cursor = 'pointer',
  dataTestProp = 'iconSvg40',
  width = convertPxToRem(40),
  height = convertPxToRem(40),
}: IconSvg40Props) {
  return (
    <Icon
      style={{
        backgroundColor: bgColor,
        border,
        borderRadius: brRadius,
        cursor,
        width,
        height,
      }}
      onClick={onClick}
      data-with-shadow={withShadow}
      data-test={dataTestProp}
    >
      <IconSvg fill={fill} />
    </Icon>
  )
}

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  &[data-with-shadow='true'] {
    box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);
  }
`

/** @deprecated Use `Sprite` and create custom `Wrapper` with styled-components if needed */
export default memo(IconSvg40Component)

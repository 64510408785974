import React, { memo } from 'react'

import styled, { keyframes } from 'styled-components'

import { featurePaleBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface SpinnerProps {
  size: 'smallest' | 'extraSmall' | 'small' | 'large'
  color: string
  dataTestProp?: string
  className?: string
}

const SIZES = {
  smallest: 12,
  extraSmall: 16,
  small: 20,
  large: 36,
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

function Spinner({
  size = 'large',
  color = featurePaleBlue,
  dataTestProp = 'spinner',
  className = '',
}: Partial<SpinnerProps>) {
  return (
    <Root data-test={dataTestProp} className={className}>
      <Loading size={size} color={color} />
    </Root>
  )
}

export default memo(Spinner)

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Loading = styled.div<SpinnerProps>`
  border: ${convertPxToRem(3)} solid rgba(0, 0, 0, 0.1);
  border-top: ${convertPxToRem(3)} solid
    ${({ color }) => color || featurePaleBlue};
  border-radius: 50%;
  width: ${({ size }) => convertPxToRem(SIZES[size])};
  height: ${({ size }) => convertPxToRem(SIZES[size])};
  animation: ${spin} 0.6s linear infinite;
`

export const SCREEN_SIZES = {
  MOBILE: 1199,
  DESKTOP: 1200,
  MOBILE_REDUCED_HEIGHT: 560,
  MOBILE_REDUCED_WIDTH: 576,
}

const size = {
  mobile: `(max-width: ${SCREEN_SIZES.MOBILE}px)`,
  desktop: `(min-width: ${SCREEN_SIZES.DESKTOP}px)`,
  mobileReducedHeight: `(max-height: ${SCREEN_SIZES.MOBILE_REDUCED_HEIGHT}px)`,
  mobileReducedWidth: `(max-width: ${SCREEN_SIZES.MOBILE_REDUCED_WIDTH}px)`,
}

const mediaQueryFor = {
  mobile: `@media ${size.mobile}`,
  desktop: `@media ${size.desktop}`,
  mobileReducedHeight: `@media ${size.mobileReducedHeight} and ${size.mobileReducedWidth}`,
}

export default mediaQueryFor

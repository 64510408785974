import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ArrowRightSmallIconProps {
  width?: string
  height?: string
  fill?: string
  className?: string
}
export default function ArrowRightSmallIcon({
  width = convertPxToRem(17),
  height = convertPxToRem(16),
  fill = secondaryGrey,
  className = undefined,
}: ArrowRightSmallIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M5.793 3.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L9.086 8 5.793 4.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const checkIsEndScrollReached = (
  event,
  SCROLL_GAP,
  isReversePagination,
) => {
  const {
    target: { scrollHeight, clientHeight, scrollTop },
  } = event
  const maxScrolling = scrollHeight - clientHeight

  const remainedScrollHeight = isReversePagination
    ? maxScrolling + scrollTop
    : maxScrolling - scrollTop

  return remainedScrollHeight <= SCROLL_GAP
}

export const checkIsScrollTopEqualValue = (event, MAX_SCROLL_TOP) => {
  const {
    target: { scrollTop },
  } = event

  return scrollTop >= MAX_SCROLL_TOP
}

import { TypedDocumentNode, gql } from '@apollo/client'

import { CHAT_BRIEF_FRAGMENT } from '../chatBrief'

import { ChatsResult, ChatsVariables } from './types'

export const CHATS_QUERY: TypedDocumentNode<ChatsResult, ChatsVariables> = gql`
  query chats($search: String!, $cursor: String) {
    chats(
      filters: { only_active: true, search: $search }
      first: 10
      after: $cursor
    )
      # TODO: remove this connection directive & move it to keyArgs
      # after these chats are the only chats
      # Without @connection this cache key interferes with old chats
      @connection(key: "new") {
      nodes {
        ...chatBrief
      }

      pageInfo {
        cursor: endCursor
        hasNextPage
      }
    }
  }

  ${CHAT_BRIEF_FRAGMENT}
`

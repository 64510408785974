import React, { Ref, ChangeEventHandler, MouseEventHandler } from 'react'

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { primaryDarkNavy, primaryWhite, secondaryGrey } from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface SearchBarProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void

  className?: string
  dataTestProp?: string

  icon?: React.ReactNode

  inputRef?: Ref<HTMLInputElement>
}

export function SearchBar({
  onChange: change,

  className = undefined,
  dataTestProp = 'searchBar',

  icon = <SearchIcon />,

  inputRef = null,
  ...rest
}: SearchBarProps) {
  const onClear: MouseEventHandler = () => change('')

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    change(event.target.value)

  return (
    <Wrapper className={className} data-test={dataTestProp}>
      {icon}

      <Search
        onChange={onChange}
        data-test={`${dataTestProp}.input`}
        ref={inputRef}
        {...rest}
      />

      <ClearIcon onClick={onClear} data-test={`${dataTestProp}.clear`} />
    </Wrapper>
  )
}

const Wrapper = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 100%;
  height: ${convertPxToRem(40)};

  gap: ${convertPxToRem(10)};
  padding: ${convertPxToRem(10, 12)};

  border-radius: ${convertPxToRem(8)};

  background-color: ${primaryWhite};

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }
`

const Search = styled.input`
  ${text1_16};

  flex: 1 1 100%;
  width: 100%;

  border: none;
  background: transparent;
  color: ${primaryDarkNavy};

  &::placeholder {
    color: ${secondaryGrey};
    user-select: none;
  }

  &:disabled {
    cursor: pointer;
    opacity: 1; // iOS Safari
  }
`

const SearchIcon = styled(Sprite).attrs({ name: 'misc/search' })``

const ClearIcon = styled(Sprite).attrs({ name: 'misc/xmark' })`
  cursor: pointer;

  ${Search}:placeholder-shown + & {
    display: none;
  }
`

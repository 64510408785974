import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

interface WebCameraIconProps extends IconProps {
  fillCircle?: string
}

export function WebCameraIcon({
  width = convertPxToRem(40),
  height = convertPxToRem(40),
  fill = 'none',
  fillCircle = 'none',
}: WebCameraIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill={fillCircle} />
      <path
        fill={fill}
        d="M20.001 19.21c1.15 0 2.084-.934 2.084-2.084s-.933-2.084-2.084-2.084c-1.15 0-2.083.933-2.083 2.084 0 1.15.933 2.083 2.083 2.083zM23.467 25.059c-.125-.104-.298-.127-.446-.059-1.913.897-4.125.897-6.037 0-.148-.069-.322-.046-.447.06-.936.796-1.57 1.89-1.795 3.097-.117.638.304 1.25.942 1.368.07.013.143.02.215.02h8.207c.65 0 1.176-.527 1.176-1.176 0-.071-.007-.142-.02-.212-.224-1.208-.858-2.302-1.795-3.098z"
      />
      <path
        fill={fill}
        d="M20.001 24.625c3.912 0 7.084-3.171 7.084-7.083 0-3.912-3.172-7.084-7.084-7.084-3.912 0-7.083 3.172-7.083 7.084.004 3.91 3.173 7.078 7.083 7.083zm3.75-7.083c0 2.07-1.679 3.75-3.75 3.75-2.07 0-3.75-1.68-3.75-3.75v-3.81c.001-.056.025-.11.067-.148 2.072-1.94 5.294-1.94 7.367 0 .042.039.066.094.066.152v3.806z"
      />
    </svg>
  )
}

import { MouseEventHandler } from 'react'

import styled from 'styled-components'

import KebabIcon from 'src/components/SvgIcons/KebabIcon'
import { IconSvgBase } from 'src/theme/baseStyles'

interface ButtonKebab24Props {
  onClick?: MouseEventHandler<HTMLDivElement>
}

export function ButtonKebab24({ onClick = undefined }: ButtonKebab24Props) {
  return (
    <Root onClick={onClick}>
      <KebabIcon />
    </Root>
  )
}

const Root = styled.div`
  pointer-events: auto;

  ${IconSvgBase}
`

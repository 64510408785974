import { gql } from '@apollo/client'

const CHATS_ACTIVITY_SUBSCRIPTION = gql`
  subscription userChatActivitiesSubscription($event_types: [EVENT_TYPE]) {
    events(event_types: $event_types) {
      current_user {
        id
        navigator_work_schedule {
          online
        }
        claims_total_amount_saved
        activities {
          chat {
            count
            ids
          }
        }
      }
    }
  }
`

export default CHATS_ACTIVITY_SUBSCRIPTION

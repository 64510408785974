import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

import { ROUTES } from 'src/constants/routesConstants'
import {
  $preferredPharmacy,
  $specialtyPharmacies,
  DrugPrice,
  $otherPharmacies,
  $recommendedPharmacies,
} from 'src/entities/drugDetails'
import { $$navigate } from 'src/entities/navigate'
import { createChatFx } from 'src/features/drugSearchTicketCreation'

interface PharmacyDetailsGateProps {
  drugName: string
  pharmacyNabp: string
  dispatchOpenChatThread: (payload: string) => void
  dispatchSetIsShowThirdSectionModal: (payload: boolean) => void
}

export const DrugSearchPharmacyDetails = createDomain(
  'DrugSearchPharmacyDetails',
)

export const PharmacyDetailsGate = createGate<PharmacyDetailsGateProps>({
  name: 'PharmacyDetailsGate',
  domain: DrugSearchPharmacyDetails,
})

export const $allPharmacies = combine(
  $preferredPharmacy,
  $otherPharmacies,
  $specialtyPharmacies,
  $recommendedPharmacies,
  (preferred, ...pharmacies): DrugPrice[] =>
    preferred ? pharmacies.flat().concat(preferred) : pharmacies.flat(),
)

export const $currentPharmacy =
  DrugSearchPharmacyDetails.store<DrugPrice | null>(null)

const startOverFx = attach({
  source: $$navigate.$fn,
  effect(navigate, drugName: string) {
    void navigate(`/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_DETAILS}`, {
      state: { drugName },
    })
  },
})

export const openDrugSearchChatFx = attach({
  source: PharmacyDetailsGate.state,
  effect(
    { dispatchOpenChatThread, dispatchSetIsShowThirdSectionModal },
    chatId: string,
  ) {
    dispatchOpenChatThread(chatId)
    dispatchSetIsShowThirdSectionModal(true)
  },
})

sample({
  clock: PharmacyDetailsGate.open,
  source: $allPharmacies,
  fn: (allPharmacies, { pharmacyNabp }) =>
    allPharmacies.find(({ pharmacy }) => pharmacy?.nabp === pharmacyNabp)!,
  target: $currentPharmacy,
})

sample({
  clock: PharmacyDetailsGate.open,
  source: $allPharmacies,
  filter: (allPharmacies, { pharmacyNabp }) =>
    !pharmacyNabp || allPharmacies.length === 0,
  fn: (_, { drugName }) => drugName,
  target: startOverFx,
})

sample({
  clock: createChatFx.doneData,
  filter: PharmacyDetailsGate.status,
  target: openDrugSearchChatFx,
})

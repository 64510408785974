import React, { ReactNode } from 'react'

import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { secondaryGrey, primaryDarkNavy } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface InfoTitleProps {
  icon: ReactNode
  textKey: string
  value: string
  type: string
}

function InfoTitle({ icon, textKey, value, type }: InfoTitleProps) {
  return (
    <Root>
      {icon}
      <Trans
        parent={Title}
        i18nKey={textKey}
        values={{ [type]: value }}
        components={{
          [type]: <Value />,
        }}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.span`
  padding-left: ${convertPxToRem(8)};
  color: ${secondaryGrey};
  ${text2_14};
`

const Value = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

export default InfoTitle

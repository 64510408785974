import React, { MouseEventHandler } from 'react'

import styled from 'styled-components'

import {
  primaryLightBlue,
  primaryDarkNavy,
  featurePaleBlue,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading6_16, text2_14, textLink2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface LocationCardProps {
  title: string
  subtitle?: string
  onClick?: () => void

  label?: string
  onLabelClick?: () => void

  icon: React.ReactNode
  isSelected?: boolean
}

export function LocationCard({
  title,
  subtitle = '',
  onClick = undefined,

  label = '',
  onLabelClick = () => {},

  icon,
  isSelected = false,
}: LocationCardProps) {
  const handleLabelClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    onLabelClick()
  }

  return (
    <Wrapper
      onClick={onClick}
      data-selected={isSelected}
      data-has-subtitle={!!subtitle}
      data-test="locationCard"
    >
      <IconWrapper>{icon}</IconWrapper>

      <Name>{title}</Name>

      {subtitle && <Address>{subtitle}</Address>}

      {label && <Label onClick={handleLabelClick}>{label}</Label>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content;
  grid-template-areas: 'icon name button';

  row-gap: ${convertPxToRem(4)};
  column-gap: ${convertPxToRem(16)};

  --side: ${convertPxToRem(40)};

  height: ${convertPxToRem(77)};
  width: calc(100% + var(--side) * 2);
  align-items: center;
  align-content: center;

  padding: ${convertPxToRem(16)} var(--side);
  margin: ${convertPxToRem(0)} calc(-1 * var(--side));

  cursor: pointer;
  position: relative;

  &[data-selected='true'] {
    background-color: ${featurePaleBlue};
  }

  &[data-has-subtitle='true'] {
    grid-template-rows: max-content max-content;

    grid-template-areas:
      'icon name button'
      'icon address button';
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--side);
    width: calc(100% - var(--side) * 2);

    height: ${convertPxToRem(1)};
    background-color: ${secondaryLightGrey};
  }

  ${mediaQueryFor.mobile} {
    --side: ${convertPxToRem(24)};
  }
`

const IconWrapper = styled.div`
  grid-area: icon;

  width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};
  padding: ${convertPxToRem(6)};

  border-radius: ${convertPxToRem(8)};

  background-color: ${primaryWhite};

  > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};

    color: ${primaryDarkNavy};
  }
`

const Name = styled.span`
  ${heading6_16};
  color: ${primaryDarkNavy};

  line-height: ${convertPxToRem(22)};

  grid-area: name;
`

const Address = styled.span`
  ${text2_14};
  color: ${secondaryGrey};

  line-height: ${convertPxToRem(18)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  grid-area: address;
`

const Label = styled.button`
  ${textLink2_14};
  color: ${primaryLightBlue};

  grid-area: button;
  text-transform: capitalize;

  border: none;
  background: transparent;
`

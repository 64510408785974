import { createDomain, sample } from 'effector'

import client from 'src/apollo/client'
import { remap } from 'src/shared/lib/effector/remap'
import {
  $$drugSearchConfigurationStorage,
  DrugSearchConfiguration,
} from 'src/shared/storage/drugSearch'

import {
  DRUG_SEARCH_PRICES_QUERY,
  DrugSearchPricesQueryResult,
} from '../api/drugSearchPricesQuery'

const defaultConfig = {
  drugName: '',
  isDrugCovered: false,
  config: {
    daysSupply: '',
    daysSupplyCustom: '',
    form: '',
    dosage: '',
    typeOfQuantity: '',
    quantity: '',
    ndc: '',
    gpi: '',
  },
}

export const enum DrugSearchFlowEntrypoint {
  Activity = 'Activity',
  Medications = 'Medications',
}

const DrugDetailsPrices = createDomain('DrugDetailsPrices')

export const drugDetailsRequested = DrugDetailsPrices.event<string>()

export const loadPricesByConfigFx = DrugDetailsPrices.effect<
  DrugSearchConfiguration,
  DrugSearchPricesQueryResult
>()

export const $drugSearchFlowEntrypoint = DrugDetailsPrices.store(
  DrugSearchFlowEntrypoint.Medications,
)

export const $drugSearchPrices =
  DrugDetailsPrices.store<DrugSearchPricesQueryResult | null>(null)

export const $currentDrugConfig =
  DrugDetailsPrices.store<DrugSearchConfiguration>(defaultConfig)

export const $preferredPharmacy = remap(
  $drugSearchPrices,
  'drugSearchPrices.preferredPharmacy',
)

export const $recommendedPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.recommendedPharmacies ?? [],
)

export const $otherPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.otherPharmacies ?? [],
)

export const $specialtyPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.specialtyPharmacies ?? [],
)

export const $rejectionInfo = remap(
  $drugSearchPrices,
  'drugSearchPrices.rejection',
)

export const $hasErrorInPricesRequest = $drugSearchPrices.map((prices) =>
  Boolean(
    prices?.drugSearchPrices?.rejection ??
      (!prices?.drugSearchPrices?.preferredPharmacy &&
        prices?.drugSearchPrices?.specialtyPharmacies.length === 0),
  ),
)

sample({
  clock: drugDetailsRequested,
  source: $$drugSearchConfigurationStorage.$state,
  filter: (savedDrugs, currentDrugName) =>
    savedDrugs.some(({ drugName }) => drugName === currentDrugName),
  fn: (savedDrugs, currentDrugName) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    savedDrugs.find(({ drugName }) => drugName === currentDrugName)!,
  target: loadPricesByConfigFx,
})

sample({
  source: loadPricesByConfigFx.doneData,
  target: $drugSearchPrices,
})

loadPricesByConfigFx.use(async ({ config }) => {
  const { data } = await client.query({
    query: DRUG_SEARCH_PRICES_QUERY,
    variables: {
      ndc: config.ndc,
      gpi: config.gpi,
      days_supply: Number(config.daysSupplyCustom),
      quantity: Number(config.quantity),
    },
  })

  return data
})

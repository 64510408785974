import { ApolloClient } from '@apollo/client'
import { attach, createStore } from 'effector'

type ApiClient = ApolloClient<unknown>

/** Current instance of Apollo Client */
const $client = createStore<ApiClient>(/* DI */ null!)

const stopClientFx = attach({
  source: $client,
  effect: (client) => client.stop(),
})

const resetClientFx = attach({
  source: $client,
  effect: (client): void => void client.clearStore(),
})

export const $$client = {
  $instance: $client,

  stopFx: stopClientFx,
  resetFx: resetClientFx,
}

import { TypedDocumentNode, gql } from '@apollo/client'

import { LOCATION_FRAGMENT } from '../location/document'

import { CreateLocationResult, CreateLocationVariables } from './types'

export const CREATE_LOCATION_MUTATION: TypedDocumentNode<
  CreateLocationResult,
  CreateLocationVariables
> = gql`
  mutation createLocation($attributes: LocationAttributesInput!) {
    createLocation(attributes: $attributes) {
      ...userLocation
    }
  }
  ${LOCATION_FRAGMENT}
`

export const SCREEN_SIZES = {
  MOBILE: 576,
  TABLET_MIN: 577,
  TABLET_MIDDLE: 760,
  TABLET_MAX: 1199,
  DESKTOP_MIN: 1200,
  DESKTOP_MIDDLE: 1400,

  MOBILE_REDUCED_HEIGHT: 560,
}

const size = {
  mobile: `(max-width: ${SCREEN_SIZES.MOBILE}px)`,
  tabletOrLess: `(max-width: ${SCREEN_SIZES.TABLET_MAX}px)`,
  tabletMiddle: `(max-width: ${SCREEN_SIZES.TABLET_MIDDLE}px) and (min-width: ${SCREEN_SIZES.TABLET_MIN}px)`,
  tablet: `(max-width: ${SCREEN_SIZES.TABLET_MAX}px) and (min-width: ${SCREEN_SIZES.TABLET_MIN}px)`,
  desktop: `(min-width: ${SCREEN_SIZES.DESKTOP_MIN}px)`,
  mobileReducedHeight: `(max-height: ${SCREEN_SIZES.MOBILE_REDUCED_HEIGHT}px)`,
}

const middleSizes = {
  middleDesktop: `(max-width: ${SCREEN_SIZES.DESKTOP_MIDDLE}px) and (min-width: ${SCREEN_SIZES.DESKTOP_MIN}px)`,
}

const mediaQueryFor = {
  mobile: `@media ${size.mobile}`,
  tablet: `@media ${size.tablet}`,
  tabletOrLess: `@media ${size.tabletOrLess}`,
  tabletMiddle: `@media ${size.tabletMiddle}`,
  desktop: `@media ${size.desktop}`,
  middleDesktop: `@media ${middleSizes.middleDesktop}`,
  mobileReducedHeight: `@media ${size.mobileReducedHeight} and ${size.mobile}`,
}

export default mediaQueryFor

import { variant } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { ModalBox } from 'src/shared/ui/ModalBox'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { heading2_29, text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $isOpen, modalHidden } from '../model'

import { Icons } from './Icons'

function RatingModalComponent() {
  const t = useScopedTranslation(
    'translation.home.care_tools.care_finder.rating_tooltip',
  )

  const close = useUnit(modalHidden)

  return (
    <ModalBox handleClick={close}>
      <Content data-test="ratingModal">
        <NavigationBar>
          <NavButton onClick={close}>
            <Sprite name="nav/close" />
          </NavButton>
        </NavigationBar>

        <Icons />

        <Title>{t('title')}</Title>

        <Description>
          <DescriptionItem>{t('first_description')}</DescriptionItem>
          <DescriptionItem>{t('second_description')}</DescriptionItem>
        </Description>

        <ButtonBottom handleClick={close} title={t('button')} theme="primary" />
      </Content>
    </ModalBox>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${convertPxToRem(24, 36, 36)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(30, 24, 40)};
  }
`

const NavigationBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

const Title = styled.span`
  ${heading2_29};
  color: ${primaryDarkNavy};

  text-align: center;

  padding: ${convertPxToRem(50, 0, 16)};

  ${mediaQueryFor.mobile} {
    text-align: start;

    padding: ${convertPxToRem(28, 0, 16)};
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${convertPxToRem(10)};
  padding-bottom: ${convertPxToRem(24)};

  text-align: center;

  ${mediaQueryFor.mobile} {
    text-align: start;
  }
`

const DescriptionItem = styled.span`
  ${text1_16};
  color: ${secondaryGrey};
`

export const RatingModal = variant({
  if: $isOpen,
  then: RatingModalComponent,
})

import { gql, TypedDocumentNode } from '@apollo/client'

import {
  FacilityCarePlanFavoriteFragment,
  FacilityFavoriteFragment,
} from './types'

export const FACILITY_FAVORITE_FRAGMENT: TypedDocumentNode<
  FacilityFavoriteFragment,
  Record<string, never>
> = gql`
  fragment facilityFavoriteFragment on Facility {
    id

    isFavorite: is_added_to_care_team
  }
`

export const FACILITY_CARE_PLAN_FAVORITE_FRAGMENT: TypedDocumentNode<
  FacilityCarePlanFavoriteFragment,
  Record<string, never>
> = gql`
  fragment facilityCarePlanFavoriteFragment on CarePlanFacility {
    id

    isFavorite: is_added_to_care_team
  }
`

import {
  Event,
  EventCallableAsReturnType,
  Store,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { and, not } from 'patronum'

/**
 * Delays the execution of `clock` until `until` becomes `true`.
 */
export function postpone<T>({
  clock,
  until,
}: {
  clock: Event<T>
  until: Store<boolean>
}): EventCallableAsReturnType<T> {
  const target = createEvent<T>()

  const $fired = createStore(false)
    .on(target, () => true)
    .on(clock, () => false)

  sample({
    clock: [clock, until],
    source: clock,
    filter: and(until, not($fired)),
    target,
  })

  return target
}

import { Domain } from 'effector'

interface CreateErrorModalParams {
  domain: Domain
}

export default function createErrorModal({ domain }: CreateErrorModalParams) {
  const setError = domain.event<string>()
  const resetError = domain.event<void>()

  const $errorMessage = domain
    .store<string>('')
    .on(setError, (_, error) => error)
    .reset(resetError)

  const $hasError = $errorMessage.map((error) => error !== '')

  return {
    setError,
    resetError,

    $errorMessage,

    $hasError,
  }
}

import { gql } from '@apollo/client'

const INSURANCE_INFO_FRAGMENT = gql`
  fragment insuranceInfoFragment on UserInsuranceInfoType {
    medical {
      name
      id
      group_id
    }
    dental {
      name
      id
      group_id
    }
    vision {
      name
      id
      group_id
    }
    pharmacy {
      name
      id
      group_id
      bin
      pcn
    }
  }
`

export default INSURANCE_INFO_FRAGMENT

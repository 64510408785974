import React from 'react'

import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'

import Spinner from 'src/components/Spinner'
import { loggingLineCreated } from 'src/entities/logging'
import {
  useAcceptTermsOfServiceMutation,
  useTermsOfServiceQuery,
} from 'src/entities/termsOfServices'
import {
  FormWrapper,
  Root,
} from 'src/sections/SectionAuth/PasswordEntry/styled'
import { TermsOfServiceContent } from 'src/sections/SectionRegistration/pages/TermsOfService/TermsOfServiceContent'
import { $$sessionStorage } from 'src/shared/storage/session'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'

function AcceptTerms() {
  const { t } = useTranslation()
  const i18n = {
    alertTitle: t('translation.shared.alert_text.term_of_service_error'),
    reloadBtnTitle: t('translation.shared.buttons.reload'),
  }

  const log = useUnit(loggingLineCreated)
  const { acceptTermsOfService: saveTermsAccepted } = useUnit($$sessionStorage)

  const {
    termsOfService,
    isLoading: isTermsLoading,
    error: termsQueryError,
    hasTermsData,
  } = useTermsOfServiceQuery(undefined)
  const { acceptTermsOfService, isLoading } = useAcceptTermsOfServiceMutation()

  const onSubmit = async () => {
    await acceptTermsOfService({ variables: { id } })

    log({
      line: `Terms of Service accepted #${id}`,
      meta: { termsOfServiceID: id },
    })

    saveTermsAccepted()
  }

  const reloadApp = () => window.location.reload()

  if (!hasTermsData && !termsQueryError)
    return (
      <NativeAlert description={i18n.alertTitle}>
        <NativeAlertAction mode="secondary" onClick={reloadApp}>
          {i18n.reloadBtnTitle}
        </NativeAlertAction>
      </NativeAlert>
    )

  if (isTermsLoading) return <Spinner size="large" />

  const { id } = termsOfService!

  return (
    <Root>
      <FormWrapper>
        <TermsOfServiceContent
          isLoading={isLoading}
          onSubmit={onSubmit}
          terms={termsOfService!}
        />
      </FormWrapper>
    </Root>
  )
}

export default AcceptTerms

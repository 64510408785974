import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { condition } from 'patronum'

import { ROUTES } from 'src/constants/routesConstants'
import { $$navigate } from 'src/entities/navigate'
import { $$pharmacySearch } from 'src/features/pharmacySearch'
import { $$userLocations } from 'src/widgets/UserLocations'

export const DrugSearchPreferredPharmacyFind = createDomain(
  'DrugSearchPreferredPharmacyFind',
)

interface PreferredPharmacyFindGateProps {
  isFreshSearch: boolean
  drugName: string
}

export const PreferredPharmacyFindGate =
  createGate<PreferredPharmacyFindGateProps>({
    name: 'PreferredPharmacyFindGate',
    domain: DrugSearchPreferredPharmacyFind,
  })

export const pharmaciesSearchStarted = DrugSearchPreferredPharmacyFind.event()
export const navigatedBack = DrugSearchPreferredPharmacyFind.event()

export const $preferredLocationCoordinates =
  $$userLocations.$preferredLocation.map((location) => ({
    latitude: location?.latitude ?? 0,
    longitude: location?.longitude ?? 0,
  }))

sample({
  clock: pharmaciesSearchStarted,
  source: $preferredLocationCoordinates,
  target: $$pharmacySearch.$preferredPharmacyCoordinates,
})

sample({
  clock: pharmaciesSearchStarted,
  source: PreferredPharmacyFindGate.state,
  target: $$navigate.withStateTo(ROUTES.DRUG_SEARCH_PHARMACY_RESULTS, {
    replace: true,
  }),
})

sample({
  clock: pharmaciesSearchStarted,
  fn: () => true,
  target: $$pharmacySearch.$isShortList,
})

condition({
  source: navigatedBack,
  if: $$userLocations.$isClosable,
  then: $$navigate.backFx,
  else: $$userLocations.stepChangedBack,
})

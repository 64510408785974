import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { ButtonNavigateTo16 } from 'src/shared/ui/buttons/ButtonNavigateTo16'
import {
  primaryLightBlue,
  primaryDarkNavy,
  statusActive,
} from 'src/theme/colors'
import { capsHeading1_12, textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface AlternativeItemProps {
  name: string
  selectAlternative: (name: string) => void
}

export function AlternativeItem({
  name,
  selectAlternative,
}: AlternativeItemProps) {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  const handleclick = () => {
    selectAlternative(name)
  }

  return (
    <Root onClick={handleclick} data-test="alternativeItem">
      <Info>
        <Title>{name}</Title>
        <Brand>{t('generic')}</Brand>
      </Info>

      <ButtonNavigateTo16 fill={primaryLightBlue} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: ${convertPxToRem(72)};
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Title = styled.span`
  color: ${primaryDarkNavy};
  ${textLink1_16};
  margin-bottom: ${convertPxToRem(4)};
`
const Brand = styled.span`
  color: ${statusActive};
  ${capsHeading1_12};
  letter-spacing: 0;
`

import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function LocationExclamationSolid({
  width = convertPxToRem(18),
  height = convertPxToRem(24),
  fill = primaryWhite,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1109 23.4C12.5156 20.3906 18 13.0969 18 9C18 4.03125 13.9688 0 9 0C4.03125 0 0 4.03125 0 9C0 13.0969 5.48438 20.3906 7.88906 23.4C8.46562 24.1172 9.53438 24.1172 10.1109 23.4ZM9 4.5C9.62344 4.5 10.125 5.00156 10.125 5.625V10.875C10.125 11.4984 9.62344 12 9 12C8.37656 12 7.875 11.4984 7.875 10.875V5.625C7.875 5.00156 8.37656 4.5 9 4.5ZM7.5 15C7.5 14.6022 7.65804 14.2206 7.93934 13.9393C8.22064 13.658 8.60218 13.5 9 13.5C9.39782 13.5 9.77936 13.658 10.0607 13.9393C10.342 14.2206 10.5 14.6022 10.5 15C10.5 15.3978 10.342 15.7794 10.0607 16.0607C9.77936 16.342 9.39782 16.5 9 16.5C8.60218 16.5 8.22064 16.342 7.93934 16.0607C7.65804 15.7794 7.5 15.3978 7.5 15Z"
        fill={fill}
      />
    </svg>
  )
}

export default LocationExclamationSolid

import { useCallback } from 'react'

import { variant } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { ROUTES } from 'src/constants/routesConstants'
import {
  chatCreated,
  createChatFx,
  ChatCreatedArguments,
} from 'src/features/drugSearchTicketCreation'
import { remap } from 'src/shared/lib/effector/remap'
import { textLink2_14 } from 'src/theme/fonts'

import { $isShowChatWidget, DrugSearchDetailsGate } from '../../../model'

import { ChatWidget } from './ChatWidget'

interface ErrorChatWidgetComponentProps {
  onChat: (param: ChatCreatedArguments) => void
  isLoading: boolean
  description: string
  updateTitle: string
  drugName: string
}

function ErrorChatWidgetComponent({
  onChat,
  isLoading,
  description,
  updateTitle,
  drugName,
}: ErrorChatWidgetComponentProps) {
  const { t } = useTranslation()

  const i18n = {
    chatButton: t('translation.medications.drug_search.buttons.chat'),
  }

  const navigate = useNavigate()

  const openChat = useCallback(() => {
    onChat({})
  }, [onChat])

  const navigateToConfigScreen = useCallback(() => {
    void navigate(
      `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_CONFIGURE_PATH}`,
      // isPreconfigured: false - because there should be one button "Search Prices".
      // Change it to true to change into two buttons ("Apply" and "Cancel").
      { state: { drugName, isPreconfigured: false } },
    )
  }, [drugName, navigate])

  return (
    <ChatWidget description={description}>
      <ChatButton
        title={i18n.chatButton}
        theme="linkBlue"
        handleClick={openChat}
        loading={isLoading}
        disabled={isLoading}
      />
      <UpdateButton
        title={updateTitle}
        theme="primary"
        handleClick={navigateToConfigScreen}
      />
    </ChatWidget>
  )
}

const ChatButton = styled(ButtonBottom)`
  width: max-content;
  ${textLink2_14};
`
const UpdateButton = styled(ButtonBottom)`
  ${textLink2_14};
`

export const ErrorChatWidget = variant({
  if: $isShowChatWidget,
  then: ErrorChatWidgetComponent,
  bind: {
    onChat: chatCreated,
    isLoading: createChatFx.pending,
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})

import React, { MouseEventHandler } from 'react'

import useMedicationPriceBreakdownQuery from 'src/apollo/hooks/useMedicationPriceBreakdownQuery'

import { TooltipContent } from './TooltipContent'

interface TooltipContentProps {
  priceId: string
  additionalInfo?: string
  closeCostTooltip: MouseEventHandler<HTMLDivElement>
}

export function ContentWithPriceData({
  priceId,
  closeCostTooltip,
  additionalInfo = '',
}: TooltipContentProps) {
  const {
    price: { patientPayAmount, planPayAmount },
    isLoading,
  } = useMedicationPriceBreakdownQuery(priceId)

  return (
    <TooltipContent
      patientPay={patientPayAmount}
      insurancePay={planPayAmount}
      isLoading={isLoading}
      closeCostTooltip={closeCostTooltip}
      additionalInfo={additionalInfo}
    />
  )
}

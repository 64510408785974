/* eslint no-param-reassign: ["warn", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import { createStorageInterface, StorageContainer } from 'src/utils/storage/api'

interface ITokenStorage {
  access: string
  refresh: string
}

export default createStorageInterface({
  container: StorageContainer.tokens,
  initialState: {} as ITokenStorage,

  actions: {
    setTokens(draft, { access, refresh }: ITokenStorage) {
      draft.access = access
      draft.refresh = refresh
    },

    resetTokens() {
      return null
    },
  },
})

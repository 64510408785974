import en_appointments from './en/appointments.en.json'
import en_authentication from './en/authentication.en.json'
import en_bookappointment from './en/bookappointment.en.json'
import en_careplans from './en/careplans.en.json'
import en_chat from './en/chat.en.json'
import en_home from './en/home.en.json'
import en_medications from './en/medications.en.json'
import en_modal_photo from './en/modalPhoto.en.json'
import en_profile from './en/profile.en.json'
import en_registration from './en/registration.en.json'
import en_shared from './en/shared.en.json'

const dev = {
  translation: {
    translation: {
      ...en_home,
      ...en_chat,
      ...en_bookappointment,
      ...en_careplans,
      ...en_profile,
      ...en_registration,
      ...en_appointments,
      ...en_authentication,
      ...en_modal_photo,
      ...en_shared,
      ...en_medications,
    },
  },
} as const

const resources = {
  dev,
}

export default resources

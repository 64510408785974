import {
  $isKnown,
  $current,
  $state,
  $error,
  permission,
  $permission,
  coordinatesRequested,
  permissionCheckRequested,
  $checkStatus,
} from './model'
import { Coordinates, LocationState, LocationError, RequestMode } from './types'

export const $$geolocation = {
  coordinatesRequested,
  permissionCheckRequested,

  $current,
  $state,
  $error,
  $permission,

  $isKnown,
  $checkStatus,
  permission,
}

export { type Coordinates, LocationState, LocationError, RequestMode }

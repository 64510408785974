import { createDomain, sample } from 'effector'
import { reset } from 'patronum'

import {
  AssistanceFormInputType,
  AssistanceFormChangedValueEvent,
  AssistanceFormRadioQuestion,
  AssistanceFormResponses,
} from './types'

export const GetAssistance = createDomain('GetAssistanceDomain')

export const assistanceFormChanged =
  GetAssistance.event<AssistanceFormChangedValueEvent>()
export const assistanceFormReset = GetAssistance.event()
export const assistanceFormSubmitted = GetAssistance.event()
export const assistanceFormValidated =
  GetAssistance.event<AssistanceFormResponses>()

export const $assistanceForm = GetAssistance.store<AssistanceFormResponses>({})

export const $assistanceRadioQuestions = GetAssistance.store<
  AssistanceFormRadioQuestion[]
>([])

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.Phone,
  fn: (formData, { value }) => ({ ...formData, phone: value }),
  target: $assistanceForm,
})

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.Email,
  fn: (formData, { value }) => ({ ...formData, email: value }),
  target: $assistanceForm,
})

reset({
  clock: assistanceFormReset,
  target: [$assistanceForm, $assistanceRadioQuestions],
})

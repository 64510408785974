import { useCallback } from 'react'

// ToDo: return after migration to the pbm resolvers
// import { useFragment } from '@apollo/client'
import { useStoreMap, useUnit } from 'effector-react'

// ToDo: return after migration to the pbm resolvers
// import { PHARMACY_FRAGMENT } from '@entities/pharmacy'

import { pharmacyFavoriteToggled, $pharmacyPendingById } from '../model'

interface UseFavoritePharmacyToggleParams {
  id: string
  isFavorite: boolean
}

export function useFavoritePharmacyToggle({
  id,
  isFavorite,
}: UseFavoritePharmacyToggleParams) {
  const pharmacyToggle = useUnit(pharmacyFavoriteToggled)

  // ToDo: return after migration to the pbm resolvers
  // const { data } = useFragment({
  //   fragment: PHARMACY_FRAGMENT,
  //   fragmentName: 'pharmacy',
  //   from: { __typename: 'PbmRetailPharmacy', nabp: id },
  // })

  const isLoading = useStoreMap({
    store: $pharmacyPendingById,
    keys: [id],
    fn: (ids) => ids.includes(id),
  })

  const toggle = useCallback(() => {
    pharmacyToggle({ id, isFavorite })
  }, [id, isFavorite, pharmacyToggle])

  return {
    toggle,
    isLoading,
  }
}

import { sample } from 'effector'
import { createGate } from 'effector-react'
import type { LDContext } from 'launchdarkly-js-client-sdk'

import { $$darkly } from 'src/shared/lib/darkly'

interface LaunchDarklyContextProps {
  context: LDContext | null
}

export const LaunchDarklyContextGate = createGate<LaunchDarklyContextProps>()

sample({
  clock: LaunchDarklyContextGate.state,
  filter: ({ context }) => Boolean(context),
  fn: ({ context }) => context as LDContext,
  target: $$darkly.identify,
})

import defaults from 'lodash/defaults'

import { LargeMapBaseMarker } from 'src/features/LargeMap'

import {
  AppointmentDoctor,
  AppointmentFacility,
  DoctorAddress,
} from '../api/appointmentQuery/types'

export function isDoctorAssignee(
  assignee: AppointmentDoctor | AppointmentFacility,
): assignee is AppointmentDoctor {
  return assignee.__typename === 'AppointmentDoctor'
}

export function pickDoctorInitials({
  firstName,
  lastName,
}: AppointmentDoctor): string {
  return [firstName[0], lastName[0]].join('')
}

export function pickDoctorSpecialty({
  specialties,
}: AppointmentDoctor): string | null {
  return (
    specialties.find(({ displayName }) => Boolean(displayName))?.displayName ??
    null
  )
}

export function pickDoctorAddress<T extends { isPreferred: boolean }>(
  available: T[],
  saved: T,
): Omit<T, 'isPreferred'> {
  const preferred = available.find(({ isPreferred }) => isPreferred)

  return defaults({}, preferred, saved)
}

export function pickDoctorMarker(
  doctor: AppointmentDoctor,
  savedAddress: DoctorAddress,
): LargeMapBaseMarker {
  const { longitude, latitude } = pickDoctorAddress(
    doctor.addresses,
    savedAddress,
  )

  return { id: 'address', latitude, longitude }
}

import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function CheckMark({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryWhite,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47429 4.73357C1.42611 4.68761 1.35038 4.68748 1.30204 4.73326L0.414038 5.57443C0.389172 5.59798 0.375061 5.6307 0.375 5.66495C0.37494 5.6992 0.388936 5.73198 0.413718 5.75562L3.90945 9.09045C3.95775 9.13652 4.03372 9.13652 4.08202 9.09045L11.5863 1.93161C11.611 1.90802 11.625 1.87534 11.625 1.84116C11.625 1.80699 11.611 1.77431 11.5863 1.75072L10.7045 0.909555C10.6563 0.863498 10.5803 0.86348 10.532 0.909514L3.99578 7.13899L1.47429 4.73357Z"
        fill={fill}
        stroke="white"
        strokeWidth="0.25"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckMark

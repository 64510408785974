import { useCallback, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import useCurrentUserQuery from 'src/apollo/hooks/useCurrentUserQuery'
import BILL_CLAIMS_QUERY from 'src/apollo/queries/BILL_CLAIMS_QUERY'
import BILL_CLAIMS_SUBSCRIPTION from 'src/apollo/subscriptions/BILL_CLAIMS_SUBSCRIPTION'
import CHATS_ACTIVITY_SUBSCRIPTION from 'src/apollo/subscriptions/CHATS_ACTIVITY_SUBSCRIPTION'
import { FETCH_FIRST_CARDS_QUANTITY } from 'src/constants/graphQlConstants'
import { extractBillClaimsData } from 'src/sections/SectionBillingAdvocacy/billAdvocacyHelpers'

const useBillClaimsResolvedQuery = (isSkipQuery) => {
  const { data, loading, fetchMore, networkStatus, refetch, subscribeToMore } =
    useQuery(BILL_CLAIMS_QUERY, {
      variables: {
        filters: { resolved: true },
        first: FETCH_FIRST_CARDS_QUANTITY,
      },
      notifyOnNetworkStatusChange: true,
      skip: isSkipQuery,
    })

  const { claimsTotalSavings } = useCurrentUserQuery()

  const { claimsData, pageInfo, totalCount } = useMemo(
    () => extractBillClaimsData(data),
    [data],
  )

  const subscribeBillClaimsResolvedtoEvent = useCallback(
    () =>
      subscribeToMore({
        document: BILL_CLAIMS_SUBSCRIPTION,
        variables: {
          resolved: true,
        },
        updateQuery: (currentCache) => {
          refetch()
          return currentCache
        },
      }),
    [refetch, subscribeToMore],
  )
  const subscribeBillClaimsTotalSavings = useCallback(
    () =>
      subscribeToMore({
        document: CHATS_ACTIVITY_SUBSCRIPTION,
        variables: {
          event_types: ['claim_activity'],
        },
        updateQuery: (currentCache) => {
          refetch()
          return currentCache
        },
      }),
    [refetch, subscribeToMore],
  )

  return {
    data: claimsData,
    claimsTotalSavings,
    fetchMoreLoading: networkStatus === 3,
    isFirstDataRequest: data === undefined,
    loading,
    fetchMore,
    pageInfo,
    resolvedTotalCount: totalCount,
    refetch,
    subscribeBillClaimsResolvedtoEvent,
    subscribeBillClaimsTotalSavings,
  }
}

export default useBillClaimsResolvedQuery

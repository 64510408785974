import React, { MouseEventHandler, useCallback } from 'react'

import styled from 'styled-components'

import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import { CardItem } from 'src/sections/SectionHome/Activity/types'
import { Sprite } from 'src/shared/ui/Sprite'
import { CardCategoryBoxShadow } from 'src/theme/baseStyles'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
} from 'src/theme/colors'
import {
  heading6_16_Medium,
  heading7_14,
  text2_14,
  capsHeading3_9_Medium,
} from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ICardCategoryProps {
  item: CardItem
  bgColor: string
  onClick?: () => void
  labelBg: string
  IconSvg?: React.ComponentType
  dataTestProp: string
  dismissable?: boolean
  openDismissAlert?: () => void
}

function CardCategory({
  item,
  bgColor,
  onClick = () => {},
  labelBg,
  IconSvg = () => null,
  dataTestProp,
  dismissable = false,
  openDismissAlert = () => {},
}: ICardCategoryProps) {
  const {
    title,
    description,
    label,
    callToAction,
    resourceImageUrl,
    imageUrl,
  } = item

  const cardImageUrl = resourceImageUrl || imageUrl

  const onDismiss: MouseEventHandler = useCallback(
    (ev) => {
      ev.stopPropagation()
      openDismissAlert()
    },
    [openDismissAlert],
  )

  return (
    <Root data-test={dataTestProp} onClick={onClick}>
      <Header>
        <HeaderContent>
          {!!cardImageUrl && (
            <Icon src={cardImageUrl} bgColor={bgColor} alt={title} />
          )}

          {!cardImageUrl && IconSvg && (
            <IconSvg40
              fill={primaryDarkNavy}
              IconSvg={IconSvg}
              bgColor={bgColor}
            />
          )}

          <Title>{title}</Title>
        </HeaderContent>

        {dismissable && (
          <Dismiss
            data-test={`${dataTestProp}.button.dismiss`}
            name="nav/close"
            onClick={onDismiss}
          />
        )}
      </Header>

      <Description>{description}</Description>

      <Footer>
        <Label style={{ backgroundColor: labelBg }}>{label}</Label>
        <CallToAction>{callToAction}</CallToAction>
      </Footer>
    </Root>
  )
}

const Root = styled.div`
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  padding: ${convertPxToRem(17, 20, 20, 17)};
  background-color: ${primaryWhite};
  cursor: pointer;
  margin-bottom: ${convertPxToRem(16)};
  ${CardCategoryBoxShadow};
`
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${convertPxToRem(15)};
  justify-content: space-between;
`
const Title = styled.h2`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  margin-left: ${convertPxToRem(14)};
  word-break: normal;
  text-transform: capitalize;
`
const Description = styled.div`
  color: ${secondaryGrey};
  ${text2_14};
  word-break: normal;
  overflow-wrap: break-word;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${convertPxToRem(14)};
`

const Label = styled.span`
  color: ${primaryDarkNavy};
  text-transform: uppercase;
  padding: ${convertPxToRem(8, 11)};
  border-radius: ${convertPxToRem(5)};
  ${capsHeading3_9_Medium};
`
const CallToAction = styled.span`
  color: ${primaryDarkNavy};
  ${heading7_14};
`
const Icon = styled.img<{ bgColor: string }>`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
`
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`

const Dismiss = styled(Sprite)`
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};

  flex-shrink: 0;

  color: ${secondaryMidGrey};
`

export default CardCategory

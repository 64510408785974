import { useQuery } from '@apollo/client'

import { TERMS_OF_SERVICE_QUERY } from './document'

export function useTermsOfServiceQuery(token: string | undefined) {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(TERMS_OF_SERVICE_QUERY, {
    variables: {
      token,
    },
  })

  const termsOfService = data?.termsOfService

  const hasTermsData = Boolean(data?.termsOfService)

  return { termsOfService, isLoading, error, hasTermsData }
}

import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CustomFeedItem, FeedItem } from 'src/entities/feedItems'
import ActivityCounter from 'src/sections/SectionHome/Activity/ActivityCounter'
import CustomActivityCard from 'src/sections/SectionHome/Activity/CustomActivityCard'
import FeedItemComponent from 'src/sections/SectionHome/Activity/FeedItem'
import { mergeFeedItems } from 'src/sections/SectionHome/Activity/feedItemsHelpers'
import {
  cardDismissionReset,
  $$activityFeedErrorModal,
  cardDismissionConfirmed,
  $dismissPending,
  cardDismissionAsked,
  $hasDismissTarget,
} from 'src/sections/SectionHome/Activity/model'
import { IterableInAppMessage } from 'src/sections/SectionHome/Activity/types'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { NativeInlineAlert } from 'src/shared/ui/alerts/NativeInlineAlert'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import ActivityBlankState from './ActivityBlankState'

interface ActivityListProps {
  feedItems: FeedItem[]
  customFeedItems: CustomFeedItem[]
  inAppMessages: IterableInAppMessage[]
  hasTodoNextPage: boolean
  totalCount: string
}

function ActivityList({
  feedItems,
  customFeedItems,
  inAppMessages,
  hasTodoNextPage,
  totalCount,
}: ActivityListProps) {
  const { t } = useTranslation()
  const i18n = {
    buttonOk: t('translation.shared.buttons.button_ok'),
    buttonCancel: t('translation.shared.buttons.button_cancel'),
    buttonDelete: t('translation.shared.buttons.button_dismiss'),
    dismissTitle: t('translation.home.activity.dismiss_card.dismiss_title'),
    dismissDescription: t(
      'translation.home.activity.dismiss_card.dismiss_description',
    ),
    counterTitle: t('translation.home.activity.titles.to_do'),
  }

  const items = mergeFeedItems({ feedItems, inAppMessages, hasTodoNextPage })

  const [withDismissAlert, withErrorAlert] = useUnit([
    $hasDismissTarget,
    $$activityFeedErrorModal.$hasError,
  ])

  const hasCustomFeedItem = Boolean(customFeedItems.length > 0)

  const count =
    Number(totalCount) + customFeedItems.length + inAppMessages.length

  if (count === 0) {
    return <ActivityBlankState />
  }

  return (
    <Root data-test="activityList">
      {withDismissAlert && (
        <NativeInlineAlert
          title={i18n.dismissTitle}
          description={i18n.dismissDescription}
        >
          <DismissButton>{i18n.buttonDelete}</DismissButton>
          <CancelButton>{i18n.buttonCancel}</CancelButton>
        </NativeInlineAlert>
      )}

      {withErrorAlert && (
        <BoundErrorAlert>
          <AlertButton>{i18n.buttonOk}</AlertButton>
        </BoundErrorAlert>
      )}

      <ActivityCounter count={count} counterTitle={i18n.counterTitle} />
      <CardContainer data-test="activityList.card">
        {hasCustomFeedItem &&
          customFeedItems.map((item) => (
            <CardCategoryWrapper key={item.title}>
              <BoundCustomActivityCard customFeedItem={item} />
            </CardCategoryWrapper>
          ))}
        {items.map((item) => (
          <FeedItemComponent key={item.id} item={item} />
        ))}
      </CardContainer>
    </Root>
  )
}

const BoundErrorAlert = reflect({
  view: NativeAlert,
  bind: {
    description: $$activityFeedErrorModal.$errorMessage,
  },
})

const AlertButton = reflect({
  view: NativeAlertAction,
  bind: {
    mode: 'secondary',
    onClick: $$activityFeedErrorModal.resetError,
  },
})

const DismissButton = reflect({
  view: NativeAlertAction,
  bind: {
    mode: 'danger',
    onClick: cardDismissionConfirmed,
    disabled: $dismissPending,
  },
})

const CancelButton = reflect({
  view: NativeAlertAction,
  bind: {
    mode: 'primary',
    onClick: cardDismissionReset,
    disabled: $dismissPending,
  },
})

const BoundCustomActivityCard = reflect({
  view: CustomActivityCard,
  bind: {
    setErrorMessage: $$activityFeedErrorModal.setError,
    setDismissItemType: cardDismissionAsked.prepend((id) => ({
      id,
      cardType: 'custom',
    })),
  },
})

const Root = styled.div``
const CardContainer = styled.ul``
const CardCategoryWrapper = styled.li`
  margin-bottom: ${convertPxToRem(16)};
  &:last-child {
    margin-bottom: 0;
  }
`

export default ActivityList

import React, { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { signOut } from 'src/apollo/utils/authHelpers'
import ModalActionConfirmation from 'src/components/Modals/ModalActionConfirmation'
import { primaryLightBlue } from 'src/theme/colors'
import { text2_14_Bold } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import useQueryString from 'src/utils/hooks/useQueryString'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'

function SsoFailedScreen() {
  const client = useApolloClient()
  const { t } = useTranslation()

  const i18n = {
    ssoFailedTitle: t('translation.auth.failed.title'),
    ssoContactPhone: t('translation.auth.failed.contact_phone'),
    ssoContactEmail: t('translation.auth.failed.contact_email'),
    ssoFailedButton: t('translation.auth.failed.button'),
    ssoFailedButtonBack: t('translation.auth.failed.button_back'),
  }
  const { parsed } = useQueryString()
  const { redirect_url } = parsed

  const handleClick = () => {
    window.location.replace((redirect_url || '/') as string)
  }

  useEffect(() => {
    signOut(client, { isSsoLogin: true })
  }, [client])

  return (
    <Root>
      <ModalActionConfirmation
        title={i18n.ssoFailedTitle}
        description={
          <Trans
            t={t}
            i18nKey="translation.auth.failed.description"
            values={{
              email: i18n.ssoContactEmail,
              phone: i18n.ssoContactPhone,
            }}
            components={{
              phoneLink: (
                <ContactLink
                  href={
                    formatPhone(i18n.ssoContactPhone, PHONE_FORMATS.uri) ?? ''
                  }
                />
              ),
              emailLink: (
                <ContactLink href={`mailto:${i18n.ssoContactEmail}`} />
              ),
            }}
            parent={null}
          />
        }
        buttons={[
          {
            text: redirect_url
              ? i18n.ssoFailedButtonBack
              : i18n.ssoFailedButton,
            textColor: primaryLightBlue,
            onClick: handleClick,
          },
        ]}
        isBold
        hPadding={66}
      />
    </Root>
  )
}

export default SsoFailedScreen as React.FC<RouteComponentProps>

const Root = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

// On mobile email and phone can overflow (as they are long)
const ContactLink = styled.a`
  ${mediaQueryFor.mobile} {
    display: flex;
    justify-content: center;
  }
  ${text2_14_Bold};
  color: ${primaryLightBlue};
`

import { useMemo } from 'react'

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { secondaryGrey } from 'src/theme/colors'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'

import { ActionTitle } from './ActionTitle'
import { IconWrapper } from './IconWrapper'

interface CallButtonProps {
  phoneNumber?: string
  title?: string
  icon?: 'misc/call' | 'misc/phone_rotary'
}

export function CallButton({
  phoneNumber = '',
  title = '',
  icon = 'misc/call',
}: CallButtonProps) {
  const link = useMemo(
    () => formatPhone(phoneNumber, PHONE_FORMATS.uri),
    [phoneNumber],
  )

  if (!link) return null

  return (
    <ButtonWrapper>
      <Link
        href={link}
        target="_blank"
        rel="noopener"
        data-test="actions.callButton"
      >
        <IconWrapper>
          <Sprite name={icon} />
        </IconWrapper>
      </Link>
      <ActionTitle>{title}</ActionTitle>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Link = styled.a`
  color: ${secondaryGrey};
`

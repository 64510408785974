import { gql, TypedDocumentNode } from '@apollo/client'

import { TERMS_OF_SERVICE_AGREEMENT_FRAGMENT } from '../termsOfService/document'

import { TermsOfServiceResult, TermsOfServiceVariables } from './types'

export const TERMS_OF_SERVICE_QUERY: TypedDocumentNode<
  TermsOfServiceResult,
  TermsOfServiceVariables
> = gql`
  query TermsOfService($token: String) {
    termsOfService(token: $token) {
      ...termsOfService
    }
  }

  fragment termsOfService on TermsOfService {
    id
    shouldBeAccepted: accepts_required
    accepted
    callToAction: call_to_action
    title
    introduction
    agreement
    agreements {
      ...serviceAgreement
    }
  }

  ${TERMS_OF_SERVICE_AGREEMENT_FRAGMENT}
`

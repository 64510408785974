import { useContext, useState } from 'react'

import { Link, useLocation, useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ButtonNavigateToChatNew40 from 'src/components/Buttons/ButtonNavigateToChatNew40'
import FeatureGuard from 'src/components/FeatureGuard'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import InboxIcon from 'src/components/SvgIcons/InboxIcon'
import MiscProfileIcon from 'src/components/SvgIcons/MiscProfileIcon'
import NavigateToIcon from 'src/components/SvgIcons/NavigateToIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import {
  useInboxStatusQuery,
  useInboxStatusUpdatesSubscription,
} from 'src/features/InboxStatus'
import {
  TUTORIAL_FIRST_STEP,
  TUTORIAL_THIRD_STEP,
} from 'src/sections/SectionDashboard/dashboardHelpers'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import { openInbox, startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink1_16, textLink2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkPathName } from 'src/utils/routeHelpers'

// TODO: will be reworked within the NEX-19411

function HeaderNavMainTableOrLess({
  isSequoiaUser = false,
  logoUrl = '',
  logoSidebarUrl = '',
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  goToInbox = () => {},
  isFirstTutorialStep = false,
  isThirdTutorialStep = false,
  mainLinks = [],
  homePage = '',
  activeSection = '',
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { unreadCount } = useInboxStatusQuery()

  useInboxStatusUpdatesSubscription()

  const { isChangeGetHelpBtn } = useContext(HeaderAnimationContext)

  const [isVisibleMoreLinks, setIsVisibleMoreLinks] = useState(false)

  const hasNewMessages = !!unreadCount
  const isSingleMessage = unreadCount === 1

  const i18n = {
    moreTab: t('translation.home.tabs.more'),
    viewAllMessages: t('translation.home.header.all_messages'),
    unreadChats: t('translation.home.header.unread_chats', {
      count: unreadCount,
    }),
    unreadChatsPlural: t('translation.home.header.unread_chats_plural', {
      count: unreadCount,
    }),
    logo: t('translation.home.tabbar.logo'),
  }

  const getMessageButtonTitle = () =>
    isSingleMessage ? i18n.unreadChats : i18n.unreadChatsPlural

  const location = useLocation()

  const activeTab = mainLinks[0]
  const isActiveFirsTab = checkPathName(activeTab?.to, location)

  const toggleMoreLinks = () => {
    setIsVisibleMoreLinks((isVisible) => !isVisible)
  }
  const closeMoreLinks = (event) => {
    event.stopPropagation()
    setIsVisibleMoreLinks(false)
  }

  const navigateToChatModal = () => {
    if (isFirstTutorialStep) return
    showThirdSectionModal()
    goToInbox()
  }

  const navigateToNewChat = () => {
    showThirdSectionModal()
    openNewChat()
  }

  const renderMoreLinks = () => (
    <MoreLinks>
      {mainLinks.slice(1).map((item) => {
        const isActive = activeSection === item.section

        return (
          <MoreLink
            key={item.title}
            onClick={closeMoreLinks}
            isActive={isActive}
            to={`/${item.to}`}
          >
            {t(item.title)}
          </MoreLink>
        )
      })}
    </MoreLinks>
  )

  return (
    <Root
      isSequoiaUser={isSequoiaUser}
      isChangeGetHelpBtn={isChangeGetHelpBtn}
      data-test="header-nav-main-tablet-root"
    >
      <Logo
        src={logoUrl}
        onClick={() => navigate(`/${homePage}`)}
        alt={i18n.logo}
        data-test="header-nav-main-tablet-logo"
      />

      <FeatureGuard permit={FeatureFlag.SHARED_PROFILE}>
        <ButtonProfileWrapper onClick={() => navigate(`/${ROUTES.PROFILE}`)}>
          <IconSvg40
            fill={secondaryGrey}
            IconSvg={MiscProfileIcon}
            bgColor={primaryWhite}
            border="none"
            withShadow
          />
        </ButtonProfileWrapper>
      </FeatureGuard>

      {isSequoiaUser && (
        <ButtonProfileWrapper
          onClick={navigateToNewChat}
          data-test="profile-button-wrapper"
        >
          <ButtonNavigateToChatNew40 />
        </ButtonProfileWrapper>
      )}

      <Tabs>
        {mainLinks.map(({ title, to, section }) => {
          const isActive = activeSection === section

          return (
            <TabLinkWrapper key={title}>
              <TabLink
                isActive={isActive}
                to={`/${to}`}
                isCurrentTutorialStep={
                  isThirdTutorialStep &&
                  title === 'translation.home.tabs.activity'
                }
              >
                {t(title)}
                <BottomLine
                  isActive={isActive}
                  isThirdTutorialStep={isThirdTutorialStep}
                />
              </TabLink>
            </TabLinkWrapper>
          )
        })}
      </Tabs>

      <TabsMiddleTablet>
        <TabLinkWrapper>
          <TabLink
            key={activeTab.title}
            isActive={isActiveFirsTab}
            to={`/${activeTab.to}`}
            onClick={closeMoreLinks}
            isThirdTutorialStep={isThirdTutorialStep}
          >
            {t(activeTab.title)}
            <BottomLine
              isActive={isActiveFirsTab}
              isThirdTutorialStep={isThirdTutorialStep}
            />
          </TabLink>
        </TabLinkWrapper>

        <MoreTab key={i18n.moreTab} onClick={toggleMoreLinks}>
          {i18n.moreTab}
          <NavigateToIcon rotate="90" fill={primaryDarkNavy} />
          {isVisibleMoreLinks && renderMoreLinks()}
        </MoreTab>
      </TabsMiddleTablet>

      <LogoMobile
        isVisible={!isChangeGetHelpBtn}
        src={logoSidebarUrl}
        alt=""
        data-test="header-nav-main-tablet-mobilelogo"
      />

      <NavigatorIconWrapper isVisible={isChangeGetHelpBtn}>
        <NavigatorIconLightTheme isPreventOnClick={isFirstTutorialStep} />
      </NavigatorIconWrapper>

      <MessageButton
        onClick={navigateToChatModal}
        isFirstTutorialStep={isFirstTutorialStep}
      >
        <InboxIcon fill={primaryWhite} />
        <MessageButtonTitle>
          {hasNewMessages ? getMessageButtonTitle() : i18n.viewAllMessages}
        </MessageButtonTitle>
      </MessageButton>

      <CallIconWrapper>
        <ButtonCall40 fill={secondaryGrey} bgColor="none" withShadow />
      </CallIconWrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: ${convertPxToRem(0, 40)};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${primaryWhite};

  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);

  ${mediaQueryFor.desktop} {
    display: none;
  }
  ${mediaQueryFor.tablet} {
    min-height: ${convertPxToRem(96)};
  }
  ${mediaQueryFor.mobile} {
    min-height: ${convertPxToRem(72)};
    justify-content: center;
    background-color: ${secondaryBackgroundGrey};
    box-shadow: none;
    display: ${({ isSequoiaUser, isChangeGetHelpBtn }) =>
      isSequoiaUser && !isChangeGetHelpBtn ? 'none' : 'flex'};
  }
`

const ButtonProfileWrapper = styled.div`
  position: absolute;
  left: ${convertPxToRem(24)};
  display: none;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`

const MessageButton = styled.button`
  position: absolute;
  right: ${convertPxToRem(40)};
  background-color: ${primaryBlue};
  display: flex;
  align-items: center;
  padding: ${convertPxToRem(8, 16)};
  border: none;
  border-radius: ${convertPxToRem(40)};
  z-index: ${({ isFirstTutorialStep }) => isFirstTutorialStep && 2};
  cursor: ${({ isFirstTutorialStep }) =>
    isFirstTutorialStep ? 'default' : 'pointer'};
  ${mediaQueryFor.mobile} {
    display: none;
  }
`
const MessageButtonTitle = styled.div`
  color: ${primaryWhite};
  ${textLink2_14};
  margin-left: ${convertPxToRem(8)};
`
const Tabs = styled.ul`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding-right: ${convertPxToRem(200)};
  ${mediaQueryFor.tabletMiddle} {
    display: none;
  }
  ${mediaQueryFor.mobile} {
    display: none;
  }
`
const TabsMiddleTablet = styled.ul`
  display: none;
  height: 100%;
  padding-right: ${convertPxToRem(72)};
  ${mediaQueryFor.tabletMiddle} {
    display: flex;
  }
`
const BottomLine = styled.div`
  border-bottom: ${({ isActive }) =>
    isActive && `${convertPxToRem(4)} solid ${primaryBlue}`};
  position: absolute;
  display: ${({ isThirdTutorialStep }) => isThirdTutorialStep && 'none'};
  bottom: 0;
  left: 0;
  right: 0;
`
const TabLink = styled(Link)`
  color: ${({ isActive }) => (isActive ? primaryBlue : primaryDarkNavy)};
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  background-color: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && primaryWhite};
  border-radius: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(24)};
  padding: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(0, 8)};
  ${textLink1_16};
  position: relative;
  height: 100%;
  height: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? '50%' : '100%'};
  cursor: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'default' : 'pointer'};
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: ${convertPxToRem(24)};
  :last-child {
    margin-right: 0;
  }
  :focus {
    outline: none;
  }
`

const MoreTab = styled.button`
  color: ${primaryDarkNavy};
  ${textLink1_16};
  border: none;
  background-color: ${primaryWhite};
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: ${convertPxToRem(24)};
`
const MoreLink = styled(Link)`
  color: ${({ isActive }) => (isActive ? primaryBlue : primaryDarkNavy)};
  background-color: ${({ isActive }) =>
    isActive ? secondaryBackgroundGrey : primaryWhite};
  ${textLink1_16};
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: ${convertPxToRem(16)};
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  :first-child {
    border-radius: ${convertPxToRem(8, 8, 0, 0)};
  }
  :last-child {
    border-radius: ${convertPxToRem(0, 0, 8, 8)};
  }
`
const TabLinkWrapper = styled.li`
  display: flex;
  align-items: center;
`

const MoreLinks = styled.ul`
  position: absolute;
  top: ${convertPxToRem(68)};
  min-width: ${convertPxToRem(196)};
  background-color: ${primaryWhite};
  z-index: 2;
  box-shadow: ${convertPxToRem(0, 2, 8)} rgba(0, 0, 0, 0.15);
  border-radius: ${convertPxToRem(8)};
`
const Logo = styled.img`
  height: ${convertPxToRem(47)};
  cursor: pointer;
  ${mediaQueryFor.mobile} {
    display: none;
  }
`
const LogoMobile = styled.img`
  display: none;
  position: absolute;
  height: ${convertPxToRem(30)};
  ${mediaQueryFor.mobile} {
    display: flex;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    transition: opacity 0.2s ease-in;
  }
`
const CallIconWrapper = styled.div`
  display: none;
  position: absolute;
  right: ${convertPxToRem(24)};
  width: ${convertPxToRem(40)};
  flex-direction: column;
  align-items: center;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`
const NavigatorIconWrapper = styled.div`
  box-shadow: ${convertPxToRem(0, 0, 0, 1)} ${secondaryLightGrey};
  border-radius: 50%;
  position: absolute;
  opacity: ${({ navigatorLogoOpacity }) =>
    navigatorLogoOpacity && navigatorLogoOpacity};
  display: none;
  ${mediaQueryFor.mobile} {
    display: flex;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.2s ease-in;
  }
`

HeaderNavMainTableOrLess.propTypes = {
  isSequoiaUser: PropTypes.bool,
  showThirdSectionModal: PropTypes.func,
  openNewChat: PropTypes.func,
  goToInbox: PropTypes.func,
  isFirstTutorialStep: PropTypes.bool,
  isThirdTutorialStep: PropTypes.bool,
  logoUrl: PropTypes.string,
  logoSidebarUrl: PropTypes.string,
  mainLinks: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      icon: PropTypes.elementType,
      title: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  homePage: PropTypes.string,
  activeSection: PropTypes.string,
}

const mapStateToProps = ({ tutorial, user, common }) => ({
  isFirstTutorialStep:
    tutorial.tutorialStep === TUTORIAL_FIRST_STEP && !tutorial.isCompleted,
  isThirdTutorialStep: tutorial.tutorialStep === TUTORIAL_THIRD_STEP,
  mainLinks: common.availableRoutes?.mainLinks,
  homePage: common.homePage,
  activeSection: common.activeSection,
  logoSidebarUrl: user.branding?.logoSidebarUrl,
  logoUrl: user.branding?.logoUrl,
  isSequoiaUser: user.isSequoiaUser,
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
  goToInbox: () => dispatch(openInbox()),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(HeaderNavMainTableOrLess)

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import CURRENT_USER_QUERY from 'src/apollo/queries/CURRENT_USER_QUERY'
import { extractCurrentUserData } from 'src/utils/currentUserHelpers'

function useCurrentUserQuery({ skip = false } = {}) {
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY, { skip })

  const {
    userId,
    organization,
    claimsTotalSavings,
    tooltipsViewed,
    employeeId,
    family,
    address,
    externalId,
  } = useMemo(() => extractCurrentUserData(data), [data])

  return {
    userId,
    tooltipsViewed,
    organization,
    claimsTotalSavings,
    loading,
    error,
    employeeId,
    family,
    externalId,
    address,
  }
}

export default useCurrentUserQuery

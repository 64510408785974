import { useCallback } from 'react'

import { reflect } from '@effector/reflect'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import { DrugPrice } from 'src/entities/drugDetails'
import { ScheduleList } from 'src/entities/schedule'
import { Actions } from 'src/features/Actions'
import {
  chatCreated,
  createChatFx,
  ChatCreatedArguments,
} from 'src/features/drugSearchTicketCreation'
import { MedicationCostWithTooltip } from 'src/features/MedicationCostWithTooltip/ui/MedicationCostWithTooltip'
import { getAddressField } from 'src/shared/lib/getAddressField'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { ChipTag } from 'src/shared/ui/labels/ChipTag'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleRed,
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
} from 'src/theme/colors'
import { heading3_25, text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { PharmacySteps } from './PharmacySteps'

interface ExtendedPharmacyContentProps {
  data: DrugPrice
  isLoading: boolean
  onChat: (param: ChatCreatedArguments) => void
  toggleMap?: () => void
}

function ExtendedPharmacyComponent({
  data,
  isLoading,
  onChat,
  toggleMap = undefined,
}: ExtendedPharmacyContentProps) {
  const { patientPay, insurancePay, pharmacy, isRejected } = data
  const t = useScopedTranslation('translation.medications.drug_search')
  const tButton = useScopedTranslation('translation.shared.buttons')

  const openChat = useCallback(() => {
    onChat({ price: patientPay, pharmacyName: pharmacy.name })
  }, [onChat, patientPay, pharmacy.name])

  return (
    <Content>
      {!toggleMap && (
        <NavigationWrapper>
          <HeaderNavLightTheme withMiddleButton={false} />
        </NavigationWrapper>
      )}
      {toggleMap && (
        <Notch onClick={toggleMap} data-test="extendedPharmacy.notch">
          <DragLineIcon />
        </Notch>
      )}
      <Info data-with-map={Boolean(toggleMap)}>
        <Helmet>
          <title>{pharmacy.name}</title>
        </Helmet>

        <PharmacyName>{pharmacy.name}</PharmacyName>

        {isRejected && <NoPriceLabel>{t('labels.no_price')}</NoPriceLabel>}

        {!isRejected && (
          <MedicationCostWithTooltip
            cost={patientPay}
            insurancePay={insurancePay}
            additionalInfo={t('messages.prices_modal_note')}
          />
        )}
        <Address>{getAddressField(pharmacy)}</Address>
        <BorderLine />

        <Actions>
          <Actions.Call
            phoneNumber={pharmacy.telephoneNumber}
            title={tButton('call_now')}
          />
          <Actions.Directions location={pharmacy} />
        </Actions>

        <PharmacySteps
          title={t('labels.what_you_need')}
          steps={pharmacy.pharmacySteps}
        />
        <ScheduleList schedule={pharmacy.workSchedule} />
      </Info>

      <ButtonContainer>
        <ButtonBottom
          dataTestProp="extendedPharmacyContent.chatButton"
          theme="primary"
          title={t('buttons.chat_with_a_pharmacy_guide')}
          handleClick={openChat}
          loading={isLoading}
          disabled={isLoading}
        />
      </ButtonContainer>
    </Content>
  )
}

const Content = styled.div`
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  width: 100%;
  height: 100%;

  background-color: ${primaryWhite};
  overflow: hidden;

  border-radius: ${convertPxToRem(20)};
  box-shadow: ${convertPxToRem(0, -6, 14, 0)} rgba(0, 0, 0, 0.08);

  gap: ${convertPxToRem(16)};
`

const Notch = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;

  border: none;
  background: ${primaryWhite};

  padding: ${convertPxToRem(4, 0, 0, 0)};
  margin: ${convertPxToRem(0, -24, -8, -24)};

  height: ${convertPxToRem(24)};
`

const DragLineIcon = styled(Sprite).attrs({ name: 'nav/drag_line' })`
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};
  color: ${secondaryMidGrey};
`

const Info = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  gap: ${convertPxToRem(12)};
  padding: ${convertPxToRem(0, 24)};

  &[data-with-map='true'] {
    margin-top: ${convertPxToRem(38)};
  }
`

const BorderLine = styled.hr`
  border: none;
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  margin: 0;
`

const Address = styled.div`
  ${text1_16};
  color: ${secondaryGrey};
`

const PharmacyName = styled.span`
  color: ${primaryDarkNavy};
  text-transform: capitalize;
  ${heading3_25};
`

const NavigationWrapper = styled.div`
  padding-top: ${convertPxToRem(26)};
  pointer-events: none;
`

const ButtonContainer = styled.div`
  padding: ${convertPxToRem(0, 24, 24)};
`

const NoPriceLabel = styled(ChipTag.Rounded)`
  background-color: ${featurePaleRed};
  color: ${primaryDarkNavy};
  text-transform: uppercase;
`

export const ExtendedPharmacyContent = reflect({
  view: ExtendedPharmacyComponent,
  bind: {
    onChat: chatCreated,
    isLoading: createChatFx.pending,
  },
})

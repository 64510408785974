/* eslint-disable no-restricted-syntax */
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector'
import { debounce, not } from 'patronum'

import client from 'src/apollo/client'
import { SIGN_OUT_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { signOut } from 'src/apollo/utils/authHelpers'
import { ROUTES } from 'src/constants/routesConstants'
import tokensStorageInterface from 'src/utils/storage/tokens'

import { USER_ACTIVITY_EVENTS } from './lib'

export const userMoved = createEvent<void>()
export const userTimedOut = createEvent<void>()
export const sessionExtended = createEvent<void>()
export const userListenersSetup = createEvent<void>()
export const userListenersCleared = createEvent<void>()

export const resetUserSessionFx = createEffect<void, void>()
export const setupUserListenersFx = createEffect<void, () => void>()
const destroyUserListenersFx = createEffect<() => void, void>()

export const $isTimeoutAlertVisible =
  createStore<boolean>(false).reset(sessionExtended)

sample({
  clock: debounce({ source: userMoved, timeout: 240_000 }),
  fn: () => true,
  target: $isTimeoutAlertVisible,
})

sample({
  clock: debounce({
    source: sample({ clock: userMoved, filter: not($isTimeoutAlertVisible) }),
    timeout: 300_000,
  }),
  target: userTimedOut,
})

sample({
  clock: sessionExtended,
  target: userMoved,
})

sample({
  clock: userTimedOut,
  target: resetUserSessionFx,
})

sample({
  clock: userListenersSetup,
  target: setupUserListenersFx,
})

sample({
  clock: userListenersCleared,
  source: setupUserListenersFx.doneData,
  target: destroyUserListenersFx,
})

resetUserSessionFx.use(async () => {
  const tokens = tokensStorageInterface.access()

  await client.mutate({
    mutation: SIGN_OUT_MUTATION,
    variables: { access_token: tokens.access },
    context: { isSkipError: true },
  })

  await signOut(client, { preventLocationReplace: true })

  window.location.replace(`/${ROUTES.EXPIRED_SESSION}`)
})

setupUserListenersFx.use(() => {
  const reset = scopeBind(userMoved, { safe: true }) as () => void

  for (const name of USER_ACTIVITY_EVENTS) {
    document.addEventListener(name, reset, { passive: true })
  }

  return () => {
    for (const name of USER_ACTIVITY_EVENTS) {
      document.removeEventListener(name, reset)
    }
  }
})

destroyUserListenersFx.use((destroy) => destroy())

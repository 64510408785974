import { IconProps } from 'src/components/SvgIcons/types'
import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ExclamationIconProps extends IconProps {
  onClick?: () => void
}
function ExclamationIcon({
  width = convertPxToRem(50),
  height = convertPxToRem(50),
  fill = secondaryGrey,
  onClick = () => {},
  className = undefined,
}: ExclamationIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.966.006a25.426 25.426 0 0 0-17.727 7.61A24.58 24.58 0 0 0 .004 25.433 24.537 24.537 0 0 0 24.584 50h.446c13.912-.143 25.082-11.52 24.968-25.433A24.51 24.51 0 0 0 24.966.006zm-3.09 34.458a3.075 3.075 0 0 1 3.02-3.187h.055a3.181 3.181 0 0 1 3.173 3.062 3.073 3.073 0 0 1-3.018 3.187h-.057a3.185 3.185 0 0 1-3.172-3.062zm1.042-20.918v12.499a2.083 2.083 0 0 0 4.166 0V13.546a2.083 2.083 0 0 0-4.166 0z"
        fill={fill}
      />
    </svg>
  )
}

export default ExclamationIcon

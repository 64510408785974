import { IconProps } from 'src/components/SvgIcons/types'
import { primaryOrange } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function StarIcon({
  width = convertPxToRem(28),
  height = convertPxToRem(28),
  fill = primaryOrange,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.944 1.2a.467.467 0 0 0-.266.1.491.491 0 0 0-.163.238l-2.39 7.394H.476c-.1 0-.198.033-.279.094a.506.506 0 0 0-.003.792l6.215 4.687-2.39 7.652a.506.506 0 0 0 .175.55.467.467 0 0 0 .56-.003l5.065-3.82v-4.896c0-1.756.389-3.149 1.027-4.237a6.23 6.23 0 0 1 2.466-2.361l-1.89-5.852a.49.49 0 0 0-.183-.252.465.465 0 0 0-.294-.086zM14.395 9.332a4.024 4.024 0 0 0-1.68 1.57c-.407.693-.715 1.676-.715 3.086v4.808l5.182 3.908a.466.466 0 0 0 .56.002.506.506 0 0 0 .174-.55l-2.389-7.65-.17-.618c.066-.32.181-.617.4-.864.313-.355.716-.526 1.18-.545.112-.005.225-.004.338-.003.1.001.198.002.297 0 .08-.003.096.025.095.1-.002.753-.002 1.506 0 2.26l-.002.027c-.002.026-.004.053.009.076.015.007.024 0 .033-.006l.004-.003.254-.18c.212-.148.423-.297.635-.444l.746-.514a274.41 274.41 0 0 0 1.52-1.054c.247-.172.495-.346.744-.518a307.829 307.829 0 0 1 1.522-1.05l.317-.22c.17-.12.34-.239.51-.355.061-.04.047-.053.001-.083a5.046 5.046 0 0 1-.13-.092 2710.797 2710.797 0 0 0-1.025-.713l-1.064-.738-1.02-.707-.85-.587-.616-.425-.484-.335-.926-.64c-.017-.01-.032-.024-.047-.037a.266.266 0 0 0-.132-.073v.64l.001 1.885c0 .073-.015.099-.09.095-.577.01-.6.01-.917.022-.24.023-.318.025-.476.048-.213.031-.427.062-.638.107a5.595 5.595 0 0 0-1.15.37z"
        fill={fill}
      />
    </svg>
  )
}

export default StarIcon

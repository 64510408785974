import { gql } from '@apollo/client'

const MEDICAL_BILLS_FRAGMENT = gql`
  fragment medicalBillsFragment on MedicalBillFile {
    content_size
    content_type
    short_url
    id
    scan_status
  }
`

export default MEDICAL_BILLS_FRAGMENT

import { variant } from '@effector/reflect'
import { WindowLocation, useLocation } from '@reach/router'
import { not } from 'patronum'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import { $hasErrorInPricesRequest } from 'src/entities/drugDetails'
import { DrugDetailsName } from 'src/pages/drugSearch/Details/ui/content/DrugDetailsName'
import { PreferredPharmacy } from 'src/pages/drugSearch/Details/ui/content/DrugDetailsPreferredPharmacy'
import { DrugDetailsSearch } from 'src/pages/drugSearch/Details/ui/content/DrugDetailsSearch'
import { OtherPharmaciesList } from 'src/pages/drugSearch/Details/ui/content/OtherPharmaciesList'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { navigatedBack } from '../model'

// ToDo: Remove it later
// import { DismissibleChatWidget } from './content/ChatWidget/DismissibleChatWidget'
import { Disclaimers } from './content/Disclaimers'
import { DrugDetailsLocation } from './content/DrugDetailsLocation'
import { InfoMessage } from './content/InfoMessage'
import { RecommendedPharmaciesList } from './content/RecommendedPharmaciesList'
import { SpecialtyPharmaciesList } from './content/SpecialtyPharmaciesList'
import { DrugSearchDetailsErrorPage } from './errors/DrugSearchDetailsErrorPage'

interface LocationState {
  drugName?: string
}

interface DetailsPageProps {
  goBack: () => void
}

function DetailsPageComponent({ goBack }: DetailsPageProps) {
  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName = '' } = state ?? {}

  return (
    <Root>
      <Wrapper>
        <HeaderWrapper>
          <HeaderNavLightTheme onBack={goBack} />
        </HeaderWrapper>
        <Content>
          <DrugDetailsName />
          <SearchSection>
            <DrugDetailsSearch />
            <Disclaimers />
            <InfoMessage />
            {/* 
              // ToDo: Remove the component later
              <DismissibleChatWidget />
            */}
          </SearchSection>

          <CardSection>
            <PreferredPharmacy />
            <RecommendedPharmaciesList />
            <LocationSection>
              {/* Will be replaced with the common widget in NEX-18282 */}
              <DrugDetailsLocation drugName={drugName} />

              <SpecialtyPharmaciesList />
              <OtherPharmaciesList />
            </LocationSection>
          </CardSection>
        </Content>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: ${convertPxToRem(24)};

  ${mediaQueryFor.mobile} {
    padding: 0;
    display: flex;
    flex: 1;
  }
`
const Wrapper = styled.div`
  padding: ${convertPxToRem(40)};
  width: 100%;
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 42)};
  }

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${primaryWhite};
`
const HeaderWrapper = styled.div`
  padding-bottom: ${convertPxToRem(26)};
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;
  gap: ${convertPxToRem(24, 0)};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const SearchSection = styled(Section)`
  gap: ${convertPxToRem(16)};
`

const CardSection = styled(Section)`
  gap: ${convertPxToRem(24)};
`
const LocationSection = styled(Section)`
  gap: ${convertPxToRem(10)};
`

export const DetailsPage = variant({
  if: not($hasErrorInPricesRequest),
  then: DetailsPageComponent,
  else: DrugSearchDetailsErrorPage,
  bind: {
    goBack: navigatedBack,
  },
})

import React, { useMemo } from 'react'

import { CarePlanCard } from 'src/shared/ui/cards/CarePlanCard'
import { CalendarIcon } from 'src/shared/ui/icons/misc/CalendarIcon'

import {
  CancelledTime,
  ConfirmedTime,
  PendingTime,
  SuggestedTime,
} from '../../api/timeSlots/types'
import { convertSingleTime } from '../../lib/timeSlots'

interface AppointmentTimeCardProps {
  slot: SuggestedTime | PendingTime | ConfirmedTime | CancelledTime
}

export function AppointmentTimeCard({ slot }: AppointmentTimeCardProps) {
  const { date, time } = useMemo(() => convertSingleTime(slot), [slot])

  return <CarePlanCard icon={<CalendarIcon />} title={date} subtitle={time} />
}

import { ChangeEventHandler } from 'react'

import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { remap } from 'src/shared/lib/effector/remap'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { InlineInputError } from 'src/shared/ui/InlineInputError'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondarySoftGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $currentConfigState,
  $drugQuantityTypeOptions,
  drugSearchConfigChanged,
} from '../model/core'
import { ConfigType } from '../model/types'
import { $validationErrors } from '../model/validation'

import { Dropdown } from './Dropdown'

const QUANTITY_TYPING_FORMAT = /^[0-9]*[.,]?[0-9]*$/

interface QuantityProps {
  quantityError: string | null
}

function QuantityView({ quantityError }: QuantityProps) {
  const t = useScopedTranslation('translation.medications.drug_search')
  const i18n = {
    title: t('labels.quantity'),
    select: t('labels.select', {
      value: t('labels.form').toLocaleLowerCase(),
    }),
  }

  const [currentConfigState, drugQuantityTypeOptions] = useUnit([
    $currentConfigState,
    $drugQuantityTypeOptions,
  ])
  const configChanged = useUnit(drugSearchConfigChanged)

  const selectionHandler = (value: string) => {
    configChanged({ type: ConfigType.TypeOfQuantity, value })
  }

  const quantityHandler: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (QUANTITY_TYPING_FORMAT.test(target.value)) {
      configChanged({
        type: ConfigType.Quantity,
        value: target.value.replace(',', '.'),
      })
    }
  }

  const clearQuantity = () => {
    configChanged({ type: ConfigType.Quantity, value: '' })
  }

  return (
    <Root>
      <Row>
        <QuantityType
          title={i18n.title}
          value={currentConfigState.typeOfQuantity}
          placeholder={i18n.select}
          options={drugQuantityTypeOptions}
          isDisabled={!currentConfigState.dosage}
          onSelect={selectionHandler}
          dataTestProp="quantity.quantityType"
          type={ConfigType.TypeOfQuantity}
        />
        <QuantityValue>
          <Input
            placeholder={i18n.title}
            onChange={quantityHandler}
            value={currentConfigState.quantity}
            data-test="quantity.input"
            inputMode="decimal"
            data-has-error={Boolean(quantityError)}
          />

          <ButtonClear onClick={clearQuantity} data-test="quantity.buttonClear">
            <Sprite name="misc/xmark" />
          </ButtonClear>

          {quantityError && <ErrorMessage>{t(quantityError)}</ErrorMessage>}
        </QuantityValue>
      </Row>
    </Root>
  )
}

const Root = styled.div``

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: ${convertPxToRem(8)};
`

const QuantityType = styled(Dropdown)`
  flex-basis: 50%;

  ${mediaQueryFor.mobile} {
    flex-basis: 60%;
  }
`
const ErrorMessage = styled(InlineInputError)`
  position: absolute;
  padding-left: ${convertPxToRem(4)};
  bottom: ${convertPxToRem(-14)};
  left: 0;
`
const Input = styled.input`
  width: 100%;
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(0, 16)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  color: ${primaryDarkNavy};
  ${text1_16};

  ::placeholder {
    color: ${secondaryGrey};
  }

  :disabled {
    opacity: 1;
    background-color: ${secondarySoftGrey};
  }

  &[data-has-error='true'] {
    border-color: ${statusErrorRed};
    ::placeholder {
      color: ${primaryDarkNavy};
    }
  }
`

// ToDo: Get rid of the duplicate wrappers (NEX-17802)
const ButtonClear = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: ${convertPxToRem(48)};
  width: ${convertPxToRem(48)};

  cursor: pointer;

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }
`

const QuantityValue = styled.div`
  flex-basis: 50%;
  position: relative;
  ${Input}:placeholder-shown + ${ButtonClear} {
    display: none;
  }

  ${mediaQueryFor.mobile} {
    flex-basis: 40%;
  }
`

export const Quantity = reflect({
  view: QuantityView,
  bind: {
    quantityError: remap($validationErrors, ConfigType.Quantity),
  },
})

import React from 'react'

import { RouteComponentProps, WindowLocation, useLocation } from '@reach/router'
import { useGate, useUnit } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'

import { loadPricesByConfigFx } from 'src/entities/drugDetails'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { withDispatch } from 'src/store'
import { openChatThread } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'

import { DrugSearchDetailsGate } from '../model'

import { DetailsPage } from './DrugSearchDetailsPage'

interface LocationState {
  drugName?: string
}

type DetailsPageProps = ConnectedProps<typeof connector>

function DrugSearchDetailsPage({
  dispatchOpenChatThread,
  dispatchSetIsShowThirdSectionModal,
}: DetailsPageProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName = '' } = state ?? {}

  useGate(DrugSearchDetailsGate, {
    drugName,
    dispatchOpenChatThread,
    dispatchSetIsShowThirdSectionModal,
  })

  const isLoading = useUnit(loadPricesByConfigFx.pending)

  if (isLoading) {
    return <SpinnerWithTitle text={t('status.loading_prices')} />
  }

  return <DetailsPage />
}

const mapDispatchToProps = withDispatch({
  setIsShowThirdSectionModal,
  openChatThread,
})

const connector = connect(null, mapDispatchToProps)
export const DrugSearchDetailsPageController = connector(
  DrugSearchDetailsPage,
) as React.FC<RouteComponentProps>

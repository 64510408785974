import styled from 'styled-components'

import {
  StepActionBaseProps,
  StepActionComponent,
  ContentStepType,
} from '../../types'

interface StepLinkContentProps
  extends StepActionBaseProps<ContentStepType.Link> {
  className?: string
}

function StepLinkContentComponent({
  action,
  className = undefined,
}: StepLinkContentProps) {
  return (
    <StepLink href={action.url ?? ''} className={className}>
      {action.content}
    </StepLink>
  )
}

const StepLink = styled.a`
  text-decoration: underline;

  color: inherit;
  cursor: pointer;
`

export const StepLinkContent =
  StepLinkContentComponent as StepActionComponent<StepLinkContentProps>

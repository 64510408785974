import coreParsePhoneNumber, {
  findPhoneNumbersInText as coreFindPhoneNumbersInText,
  MetadataJson,
} from 'libphonenumber-js/core'

import metadata from 'src/utils/data/libphonenumber.metadata.json'

export const DEFAULT_NUMBER = '(305) 851-7310'
export const MASKS = {
  /**
   * @deprecated since Rightway is US only. See [NEX-9013] for more info.
   */
  INTERNATIONAL: '+9 (999) 999-9999',
  NATIONAL: '(999) 999-9999',
}

export const PHONE_FORMATS = {
  national: 'national',
  /**
   * @deprecated since Rightway is US only. See [NEX-9013] for more info.
   */
  international: 'international',
  /**
   * @deprecated since Rightway is US only. See [NEX-9013] for more info.
   */
  nationalWithCode: 'national-with-code',
  uri: 'uri',
  plain: 'plain',
  plainWithCode: 'plain-with-code',
}
const countryCodes = ['US', 'PR', 'VI']

export const parsePhoneNumber = (text: string) => {
  if (!text) return null
  return coreParsePhoneNumber(text, 'US', metadata as MetadataJson) ?? null
}
export const findPhoneNumbersInText = (text: string) =>
  coreFindPhoneNumbersInText(text, 'US', metadata as MetadataJson)

/**
 * Converts each detected phone number into a URI
 *
 * @example "text 800 543 1110" -> "text tel:+18005431110"
 */
export const linkifyPhoneNumbersInText = (text: string) =>
  findPhoneNumbersInText(text).reduceRight(
    (current, { startsAt: start, endsAt: end, number }) =>
      `${current.slice(0, start)}${number.getURI()}${current.slice(end)}`,
    text,
  )

export function validatePhone(value: string): boolean {
  if (!value) return false

  const phone = parsePhoneNumber(value)

  return (
    Boolean(phone?.isValid()) &&
    Boolean(phone?.country && countryCodes.includes(phone.country))
  )
}

export function formatPhone(value: string, format = PHONE_FORMATS.national) {
  if (!value) return null

  const phone = parsePhoneNumber(value)

  if (
    !phone?.isValid() ||
    (phone.country && !countryCodes.includes(phone.country))
  ) {
    return null
  }

  switch (format) {
    case PHONE_FORMATS.nationalWithCode:
      return `+1 ${phone.formatNational()}`

    case PHONE_FORMATS.national:
      return phone.formatNational()

    case PHONE_FORMATS.international:
      return phone.formatInternational()

    case PHONE_FORMATS.uri:
      return phone.getURI()

    case PHONE_FORMATS.plain:
      return phone.format('E.164').replace('+1', '')

    case PHONE_FORMATS.plainWithCode:
      return phone.format('E.164')

    default:
      return null
  }
}

import React, { Fragment, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ClockIcon from 'src/components/SvgIcons/ClockIcon'
import { WorkSchedule } from 'src/entities/drugDetails'
import {
  primaryDarkNavy,
  secondaryBackgroundGrey,
  secondaryGrey,
  statusErrorRed,
  statusActive,
} from 'src/theme/colors'
import { capsHeading1_12, text2_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { getFormattedSchedule, getWorkingInfo } from '../lib'

interface DrugDetailsScheduleProps {
  schedule: WorkSchedule[]
}

export function ScheduleList({ schedule }: DrugDetailsScheduleProps) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.home.care_tools.care_finder.hourse_panel.title'),
    open: t('translation.shared.schedule.open'),
    closed: t('translation.shared.schedule.closed'),
  }
  const scheduleInfo = useMemo(() => getWorkingInfo(schedule), [schedule])
  const formattedSchedule = useMemo(
    () => getFormattedSchedule(schedule),
    [schedule],
  )

  if (!scheduleInfo) return null

  return (
    <Schedule data-test="scheduleComponent">
      <Label>
        <ClockIcon fill={secondaryGrey} />
        <LabelTitle>{i18n.title}</LabelTitle>
      </Label>
      <ScheduleStatusBlock>
        <ScheduleStatus data-is-open={scheduleInfo.isOpenNow}>
          {scheduleInfo.isOpenNow ? i18n.open : i18n.closed}
        </ScheduleStatus>
        <ScheduleMessage>{scheduleInfo.when}</ScheduleMessage>
      </ScheduleStatusBlock>
      <List>
        {formattedSchedule.map(({ dayOfWeek, workingTime }) => (
          <Fragment key={`${dayOfWeek}_${workingTime}`}>
            <DayName>{dayOfWeek}</DayName>
            <DayTime>{workingTime}</DayTime>
          </Fragment>
        ))}
      </List>
    </Schedule>
  )
}

const Schedule = styled.div`
  display: grid;
  gap: ${convertPxToRem(17)};
  padding: ${convertPxToRem(16, 0, 16, 16)};

  background-color: ${secondaryBackgroundGrey};
  border-radius: ${convertPxToRem(8)};
`

const ScheduleStatusBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${convertPxToRem(6)};
`

const ScheduleStatus = styled.span`
  color: ${statusErrorRed};
  ${textLink2_14};
  text-transform: uppercase;

  &[data-is-open='true'] {
    color: ${statusActive};
  }
`

const ScheduleMessage = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${convertPxToRem(6)};
`

const DayName = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
  text-transform: capitalize;
`

const DayTime = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(8)};
`

const LabelTitle = styled.div`
  color: ${secondaryGrey};
  ${capsHeading1_12};
`

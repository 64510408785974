import styled from 'styled-components'

import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import LocatorIcon from 'src/components/SvgIcons/LocatorIcon'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { openLocations } from 'src/utils/locationHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ActionTitle } from './ActionTitle'

interface DirectionsButtonProps {
  location: {
    latitude?: number
    longitude?: number
  }
}

export function DirectionsButton({ location }: DirectionsButtonProps) {
  const t = useScopedTranslation('translation.shared.buttons')
  const { latitude = 0, longitude = 0 } = location

  const goToDirections = () =>
    openLocations({
      location: { latitude, longitude },
    })

  if (!location.latitude || !location.longitude) return null

  return (
    <ButtonWrapper>
      <IconSvg40
        fill={secondaryGrey}
        IconSvg={LocatorIcon}
        border="none"
        onClick={goToDirections}
        withShadow
        dataTestProp="actions.directionsButton"
        width={convertPxToRem(48)}
        height={convertPxToRem(48)}
      />
      <ActionTitle>{t('directions')}</ActionTitle>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

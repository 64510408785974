import { list, reflect, variant } from '@effector/reflect'
import { and } from 'patronum'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { capsHeading1_12, heading6_16_Medium } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $completions,
  $completionsPending,
  $search,
  completionSelected,
} from '../../model/autocomplete'

const $hasSearch = $search.map(Boolean)
interface FoundAddressesListProps {
  count: number
}

function FoundAddressesListView({ count }: FoundAddressesListProps) {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  return (
    <Root data-test="foundAddresses">
      <Header>{t('results', { count })}</Header>

      <Addresses />

      <SearchFallback />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  width: 100%;
`

const Title = styled.span`
  ${capsHeading1_12};
  color: ${secondaryGrey};

  text-transform: uppercase;
`

const Result = styled.span.attrs({ 'data-test': 'foundAddresses.result' })`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  cursor: pointer;

  line-height: ${convertPxToRem(52)};
  height: ${convertPxToRem(52)};
  width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`

const Header = variant({
  if: $hasSearch,
  then: Title,
})

const Addresses = list({
  view: Result,
  source: $completions,
  mapItem: {
    children: ({ description }) => description,
    onClick:
      ({ place_id }) =>
      () =>
        completionSelected(place_id),
  },
  getKey: ({ place_id }) => place_id,
})

const SearchFallback = variant({
  if: and($completionsPending, $hasSearch),
  then: () => <Spinner size="small" />,
})

export const FoundAddressesList = reflect({
  view: FoundAddressesListView,
  bind: {
    count: $completions.map((completions) => completions.length),
  },
})

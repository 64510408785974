import { OnDataOptions, useSubscription } from '@apollo/client'

import { Event as SubscriptionEvent } from 'src/shared/api/types'

import { CHATS_SUBSCRIPTION, CHATS_UPDATE_QUERY } from './graphql'
import { ChatsSubscriptionResult } from './types'

export function useChatsSubscription() {
  useSubscription(CHATS_SUBSCRIPTION, { onData, ignoreResults: true })
}

function onData({
  client,
  data: { data },
}: OnDataOptions<ChatsSubscriptionResult>) {
  client.cache.updateQuery(
    {
      query: CHATS_UPDATE_QUERY,
    },
    (prev) => {
      if (!data?.chatsEvents?.chat) return /* skip */

      const { chat, event } = data.chatsEvents
      let nodes = prev?.chats?.nodes ?? []

      switch (event) {
        case SubscriptionEvent.Created:
        case SubscriptionEvent.Updated:
          nodes = nodes.filter((item) => item!.id !== chat.id)
          nodes.unshift(chat)
          break

        default: /* pass */
      }

      const chats = { __typename: 'ChatConnection', nodes }
      return { chats }
    },
  )
}

import React from 'react'

import { DrugPrice } from 'src/entities/drugDetails'
import { PharmacyTypeEnum } from 'src/shared/api/types'

import { MailOrderCard } from './MailOrderCard'
import { RetailPharmacyCard } from './RetailPharmacyCard'

interface CardControllerProps {
  pharmacyData: DrugPrice
}

export function SpecialtyPharmaciesCardController({
  pharmacyData,
}: CardControllerProps) {
  switch (pharmacyData.pharmacy.pharmacyType) {
    case PharmacyTypeEnum.Cvs:
      return <RetailPharmacyCard pharmacyData={pharmacyData} />
    default:
      return <MailOrderCard pharmacyData={pharmacyData} />
  }
}

import styled, { css } from 'styled-components'

import { primaryLightBlue } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const IconSvgBase = css`
  width: ${({ width }) => width};
  height: ${({ height }) => height || convertPxToRem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius || '50%'};
  flex-shrink: 0;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ border }) => border};
  cursor: pointer;
  ${({ withShadow }) => withShadow && IconBoxShadow};
`

export const IconBoxShadow = css`
  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);
`
export const KebabBoxShadow = css`
  box-shadow: ${convertPxToRem(0, 2, 8)} rgba(0, 0, 0, 0.15);
`

export const CardCategoryBoxShadow = css`
  box-shadow: ${convertPxToRem(0, 4, 26)} rgba(187, 187, 187, 0.15);
`
export const ContentsRootBase = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`
export const HeaderPaddingH40 = css`
  padding: ${convertPxToRem(0, 40)};
`

export const BodyPadding40 = css`
  padding: ${convertPxToRem(40)};
`
export const MobileBodyPadding24 = css`
  padding: ${convertPxToRem(24)};
`
export const FooterPaddingH40 = css`
  padding: ${convertPxToRem(0, 40)};
`
export const MobileFooterPaddingH24 = css`
  padding: ${convertPxToRem(0, 24)};
`

export const ModalBase = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
`

/* accessibility keyboard focus */
const FocusDefaultStyle = css`
  box-shadow: ${convertPxToRem(0, 0, 1)} ${primaryLightBlue};
`

export const FocusWithin = css`
  &:focus-within {
    ${FocusDefaultStyle};
  }
`

export const FocusDefault = css`
  &:focus {
    ${FocusDefaultStyle};
  }
`

export const BottomTabBarWrapper = styled.div`
  display: none;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`

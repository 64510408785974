import { ReactElement } from 'react'

import { useUnit } from 'effector-react'

import { Sprite } from '@shared/ui/Sprite'

import { LocationKindType } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'

import { UserLocation } from '../../api/location'
import { locationSelected } from '../../model/edit'
import { $preferredLocationId, locationPreferred } from '../../model/locations'

import { LocationCard } from './LocationCard'

interface SavedLocationCardProps {
  location: UserLocation
}

const IconMap: Record<LocationKindType, ReactElement> = {
  [LocationKindType.HomeAddress]: <Sprite name="services/home" />,
  [LocationKindType.WorkAddress]: <Sprite name="misc/briefcase" />,
  [LocationKindType.OtherAddress]: <Sprite name="map/target" />,
}

export function SavedLocationCard({ location }: SavedLocationCardProps) {
  const tShared = useScopedTranslation('translation.shared.buttons')
  const t = useScopedTranslation('translation.profile.preferred_locations')
  const i18n = {
    [LocationKindType.HomeAddress]: t('home_address'),
    [LocationKindType.WorkAddress]: t('work_address'),
    [LocationKindType.OtherAddress]: location.name ?? t('other_address'),
  }

  const preferredId = useUnit($preferredLocationId)
  const [selectLocation, preferLocation] = useUnit([
    locationSelected,
    locationPreferred,
  ])

  const onEdit = () => {
    selectLocation(location.id)
  }

  const onSelect = () => {
    preferLocation(location.id)
  }

  return (
    <LocationCard
      title={i18n[location.kind]}
      subtitle={location.address}
      label={tShared('edit')}
      icon={IconMap[location.kind]}
      onLabelClick={onEdit}
      onClick={onSelect}
      isSelected={location.id === preferredId}
    />
  )
}

import { sample, restore } from 'effector'
import { pending } from 'patronum'

import client from 'src/apollo/client'
import { LocationAttributesInput } from 'src/shared/api/types'

import { CREATE_LOCATION_MUTATION } from '../api/createLocationMutation'
import { DESTROY_LOCATION_MUTATION } from '../api/destroyLocationMutation'
import { UserLocation } from '../api/location'
import { LOCATIONS_QUERY } from '../api/locationsQuery'
import { UPDATE_LOCATION_MUTATION } from '../api/updateLocationMutation'

import { UserLocations, UserLocationsGate } from './core'

export const locationsRequested = UserLocations.event<void>()

export const $locations = UserLocations.store<UserLocation[]>([])

export const fetchUserLocationsFx = UserLocations.effect<void, UserLocation[]>()
export const createUserLocationFx = UserLocations.effect<
  { attributes: LocationAttributesInput },
  UserLocation
>()
export const updateUserLocationFx = UserLocations.effect<
  { id: string; attributes: LocationAttributesInput },
  UserLocation
>()
export const destroyUserLocationFx = UserLocations.effect<string, void>()

export const $locationsUpdatePending = pending({
  effects: [createUserLocationFx, updateUserLocationFx, destroyUserLocationFx],
})

export const $locationsFetchPending = pending({
  effects: [fetchUserLocationsFx],
})

export const $locationsReceived = restore(
  fetchUserLocationsFx.done.map(() => true),
  false,
)

$locations
  .on(fetchUserLocationsFx.doneData, (_, locations) => locations)
  .on(destroyUserLocationFx.done, (locations, { params: removedId }) =>
    locations.filter((location) => location.id !== removedId),
  )
  .on(createUserLocationFx.doneData, (locations, created) => [
    ...locations.map((location) => ({ ...location, isPreferred: false })),
    created,
  ])
  .on(updateUserLocationFx.doneData, (locations, updated) =>
    locations.map((location) => {
      if (location.id === updated.id) return updated
      if (updated.isPreferred) return { ...location, isPreferred: false }
      return location
    }),
  )

sample({
  clock: UserLocationsGate.open,
  target: locationsRequested,
})

sample({
  clock: locationsRequested,
  target: fetchUserLocationsFx,
})

fetchUserLocationsFx.use(async () => {
  const { data } = await client.query({
    query: LOCATIONS_QUERY,
    fetchPolicy: 'network-only',
  })

  return data.currentUser.locations
})

createUserLocationFx.use(async (variables) => {
  const { data } = await client.mutate({
    mutation: CREATE_LOCATION_MUTATION,
    variables,
  })

  const location = data?.createLocation
  if (!location) throw new Error('Creating a location failed')

  return location
})

updateUserLocationFx.use(async (variables) => {
  const { data } = await client.mutate({
    mutation: UPDATE_LOCATION_MUTATION,
    variables,
  })

  const location = data?.updateLocation
  if (!location) throw new Error('Updating a location failed')

  return location
})

destroyUserLocationFx.use(async (id) => {
  await client.mutate({
    mutation: DESTROY_LOCATION_MUTATION,
    variables: { id },
  })
})

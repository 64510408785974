/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
import { Store } from 'effector'

/**
 * Generates a union of all possible paths in an object.
 *
 * - Does not support recursive types and *will* generate
 * an `type instantiation is excessively deep` error.
 * - Does not support arrays since accessing arrays is
 * out of scope for `remap`.
 */
type KeysOf<T> = T extends unknown[]
  ? never /* skip over arrays intentionally */
  : {
      [Key in keyof T & string]-?: NonNullable<T[Key]> extends object
        ? Key | `${Key}.${KeysOf<NonNullable<T[Key]>>}`
        : Key // terminate at leaf
    }[keyof T & string]

/** Splits a dot-separated path into a tuple. */
type Split<K> = K extends `${infer Left}.${infer Right}`
  ? [Left, ...Split<Right>]
  : [K]

/**
 * Reads a field from and object using a specified path.
 *
 * Works similar to optional chaining except uses `null`
 * instead of `undefined` for nullish paths.
 */
type Read<V, L extends string[]> = V extends object
  ? L extends [infer K extends keyof V, ...infer Rest extends string[]]
    ? Rest extends []
      ? V[K]
      : Read<V[K], Rest>
    : null
  : null

/**
 * Maps an Effector {@link Store|`Store`} to a new derived `Store` containing a nested field.
 *
 * `remap` supports accessing deeply nested fields using a dot notation
 * in `path`, as well as `null`-safety (if any intermediate object in
 * `path` is `null`, the whole `Store` will be `null` as well).
 *
 * @template T Value of an original `Store`
 * @template K A requested path
 *
 * @param {Store<T>} store A source store with data
 * @param {K} path A dot-separated path to the desired value within the store
 *
 * @returns {Store<Read<T, Split<K>>>} A new `Store` containing the value at the specified path.
 */
export function remap<T, const K extends KeysOf<NonNullable<T>>>(
  store: Store<T>,
  path: K,
): Store<Read<T, Split<K>>> {
  return store.map(
    (value: any) =>
      path.split('.').reduce((current, key) => current?.[key] ?? null, value),
    { skipVoid: false },
  )
}

import { reflect } from '@effector/reflect'

import { Sprite } from '@shared/ui/Sprite'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { locationInitialized } from 'src/widgets/UserLocations/model/edit'

import { LocationCard } from './LocationCard'

export function AddLocationCard() {
  const t = useScopedTranslation('translation.profile.preferred_locations')
  const sharedT = useScopedTranslation('translation.shared.buttons')

  return (
    <Card
      title={t('add_preferred_address')}
      subtitle={t('save_location')}
      label={sharedT('add')}
    />
  )
}

const Card = reflect({
  view: LocationCard,
  bind: {
    onLabelClick: locationInitialized,
    onClick: locationInitialized,
    icon: <Sprite name="map/target" />,
  },
})

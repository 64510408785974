import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { hardGray, primaryBlue } from 'src/theme/colors'
import { heading2_17 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function RxCardLogo() {
  return (
    <LogoContainer>
      <LogoIcon />

      <LogoText />

      <RxText>Rx</RxText>
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  display: flex;
`
const RxText = styled.span`
  ${heading2_17};
`
const LogoIcon = styled(Sprite).attrs({ name: 'misc/rightway' })`
  padding-right: ${convertPxToRem(5)};

  width: ${convertPxToRem(18)};
  height: ${convertPxToRem(18)};

  color: ${primaryBlue};
`
const LogoText = styled(Sprite).attrs({ name: 'misc/rightway_text_logo' })`
  padding-right: ${convertPxToRem(2)};
  height: ${convertPxToRem(25)};

  color: ${hardGray};
`

import { gql, TypedDocumentNode } from '@apollo/client'

import { OrganizationPhoneResult, OrganizationPhoneVariables } from './types'

export const ORGANIZATION_PHONE_QUERY: TypedDocumentNode<
  OrganizationPhoneResult,
  OrganizationPhoneVariables
> = gql`
  query organizationPhone {
    user: currentUser {
      id

      organization {
        id

        navigator: navigator_phone_number
        pharmacist: pharmacist_phone_number
      }
    }
  }
`

import React from 'react'

import { StepActionBaseProps, StepActionComponent } from '../../types'

interface StepTextContentProps extends StepActionBaseProps {
  className?: ''
}

function StepTextContentComponent({
  action,
  className = undefined,
}: StepTextContentProps) {
  return <span className={className}>{action.content}</span>
}

export const StepTextContent =
  StepTextContentComponent as StepActionComponent<StepTextContentProps>

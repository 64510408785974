import { reflect } from '@effector/reflect'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import { $$chat } from 'src/entities/chat'
import { InboxStatus } from 'src/features/InboxStatus'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import { withDispatch } from 'src/store'
import {
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'
import { heading4_21 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface InboxHeaderProps extends ConnectedProps<typeof connector> {
  className?: string
}

function InboxHeaderComponent({
  dispatchCall,
  dispatchClose,

  className = undefined,
}: InboxHeaderProps) {
  const t = useScopedTranslation('translation.chat.inbox')

  return (
    <Root className={className}>
      <CloseButton onClick={dispatchClose}>
        <Sprite data-test="inboxHeader.close" name="nav/close" />
      </CloseButton>

      <Status />

      <Actions>
        <CreateChat data-test="inboxHeader.chat" />

        <NavButton data-test="inboxHeader.call" onClick={dispatchCall}>
          <Sprite name="misc/call" />
        </NavButton>
      </Actions>

      <Title>{t('title')}</Title>
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: max-content max-content;

  grid-template-areas:
    'close guide actions'
    'title title title';

  column-gap: ${convertPxToRem(8)};
  row-gap: ${convertPxToRem(24)};

  flex: 0 0 max-content;
  padding: ${convertPxToRem(24)};
`

const Status = styled(InboxStatus)`
  grid-area: guide;
`

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  flex-flow: row nowrap;

  justify-self: end;

  gap: ${convertPxToRem(8)};
`

const CloseButton = styled(NavButton)`
  grid-area: close;

  ${mediaQueryFor.desktop} {
    display: none;
  }
`

const Title = styled.span`
  ${heading4_21};

  grid-area: title;
  justify-self: center;
  text-align: center;
`

const CreateChat = reflect({
  view: NavButton,
  bind: {
    // @ts-expect-error: faulty reflect typings
    onClick: $$chat.create,
    children: <Sprite name="misc/pencil" />,
  },
})

const mapDispatchToProps = withDispatch({
  close: () => setIsShowThirdSectionModal(false),
  call: () => setThirdSectionContent(THIRD_SECTION_CONTENT.SELECT_PHONE),
})

const connector = connect(null, mapDispatchToProps)
export const InboxHeader = connector(InboxHeaderComponent)

export { Actions as InboxHeaderActions }

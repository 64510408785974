import { createStore, sample } from 'effector'

import { featuresQuery } from '../api/featuresQuery'
import { featuresToMap } from '../lib'
import { FeatureFlagMap } from '../types'

export const $features = createStore<FeatureFlagMap>({})

sample({
  clock: sample({
    clock: featuresQuery.finished.success,
    fn: ({ data }) => data?.user?.settings.features,
  }),
  filter: Boolean,
  fn: featuresToMap,
  target: $features,
})

import { $$popup } from '@features/PopupModal'

import { FavoritePharmacyUpdatePopup } from '../ui/FavoritePharmacyUpdatePopup'

export const addPharmacySuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: FavoritePharmacyUpdatePopup.AddPharmacySuccess,
})

export const addPharmacyErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: FavoritePharmacyUpdatePopup.AddPharmacyFailure,
})

export const addPharmacyLimitErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: FavoritePharmacyUpdatePopup.AddPharmacyLimitFailure,
})

export const removePharmacySuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: FavoritePharmacyUpdatePopup.RemovePharmacySuccess,
})

export const removePharmacyErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: FavoritePharmacyUpdatePopup.RemovePharmacyFailure,
})

const PRICE_LIKE_NUMBER = /^\d+(?:[.,](\d+))?$/
const NOT_NUMBER_SYMBOLS = /[^\d.]+/

function parsePrice(value: string | number) {
  // Remove everything but digits and a dot
  const stripped = String(value)
    .replace(',', '.')
    .replace(NOT_NUMBER_SYMBOLS, '')
  return Number(stripped)
}

export function tryConvertToPrice(value: string | number) {
  const price = String(value)
  const match = price.match(PRICE_LIKE_NUMBER)

  // The value does not look like number
  if (match === null) {
    return price
  }

  // Extract number of digits after comma/dot and select this very presicion
  const precision = match[1]?.length ?? 0
  return formatPrice({ value, precision })
}

interface IFormatPriceArgs {
  value: string | number
  precision?: number
  separator?: boolean
  withCurrency?: boolean
}

export function formatPrice({
  value,
  precision = 0,
  separator = true,
  withCurrency = true,
}: IFormatPriceArgs) {
  const price = parsePrice(value)

  return price.toLocaleString('en-US', {
    useGrouping: separator,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: withCurrency ? 'currency' : 'decimal',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
}

export function formatPriceWithDynamicPrecision({
  value,
  ...rest
}: Omit<IFormatPriceArgs, 'precision'>) {
  const price = parsePrice(value)

  // Has cents -> use 2 decimal places, othewise 0
  const precision = price % 1 > 0 ? 2 : 0

  return formatPrice({
    value,
    precision,
    ...rest,
  })
}

import { UpdateDoctorPopupContent } from './UpdateDoctorPopupContent'
import { UpdateFacilityPopupContent } from './UpdateFacilityPopupContent'

export const CareTeamUpdatePopup = {
  AddDoctorSuccess: (
    <UpdateDoctorPopupContent
      title="add.success.title"
      description="add.success.text"
    />
  ),
  AddDoctorFailure: (
    <UpdateDoctorPopupContent
      title="add.error.title"
      description="add.error.text"
    />
  ),
  RemoveDoctorSuccess: (
    <UpdateDoctorPopupContent title="remove.success.title" />
  ),
  RemoveDoctorFailure: (
    <UpdateDoctorPopupContent
      title="remove.error.title"
      description="remove.error.text"
    />
  ),

  AddFacilitySuccess: (
    <UpdateFacilityPopupContent
      title="add.success.title"
      description="add.success.text"
    />
  ),
  AddFacilityFailure: (
    <UpdateFacilityPopupContent
      title="add.error.title"
      description="add.error.text"
    />
  ),
  RemoveFacilitySuccess: (
    <UpdateFacilityPopupContent title="remove.success.title" />
  ),
  RemoveFacilityFailure: (
    <UpdateFacilityPopupContent
      title="remove.error.title"
      description="remove.error.text"
    />
  ),
}

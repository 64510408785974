import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Formatter } from 'src/shared/lib/formatters'
import { ChipTag } from 'src/shared/ui/labels/ChipTag'
import {
  featurePaleRed,
  primaryDarkNavy,
  primaryOrange,
  secondaryGrey,
} from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { MedicationCostWithTooltip } from './MedicationCostWithTooltip'

interface PriceLabelProps {
  patientPay: string
  isBestPrice: boolean
  isRejected: boolean
  insurancePay: string
}

export function PriceLabel({
  patientPay,
  isBestPrice,
  isRejected,
  insurancePay,
}: PriceLabelProps) {
  const { t } = useTranslation()

  const i18n = {
    bestPrice: t('translation.home.medication_details.pricing.best_price'),
    planPays: t('translation.medications.pricing_tab.mc_tooltip.plan_pays', {
      /* @ts-expect-error format also accepts strings */
      value: Formatter.currency.format(insurancePay),
    }),
    pricesNote: t(
      'translation.medications.drug_search.messages.prices_modal_note',
    ),
    noPrice: t('translation.medications.drug_search.labels.no_price'),
  }

  if (isRejected)
    return (
      <StyledLabel>
        <NoPriceLabel>{i18n.noPrice}</NoPriceLabel>
      </StyledLabel>
    )

  return (
    <StyledLabel>
      <MedicationCostWithTooltip
        cost={patientPay}
        insurancePay={insurancePay}
        additionalInfo={i18n.pricesNote}
      />
      <PlanPays>{i18n.planPays}</PlanPays>
      {isBestPrice && <BestPriceLabel>{i18n.bestPrice}</BestPriceLabel>}
    </StyledLabel>
  )
}

const PlanPays = styled.span`
  ${text3_12};
  color: ${secondaryGrey};
  white-space: nowrap;
`

const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
  place-items: end;
  grid-area: label;
  gap: ${convertPxToRem(4)};
`
const BestPriceLabel = styled(ChipTag.Rounded)`
  background-color: ${primaryOrange};
  text-transform: uppercase;
  color: ${primaryDarkNavy};
`
const NoPriceLabel = styled(BestPriceLabel)`
  background-color: ${featurePaleRed};
`

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { InboxStatus } from '@features/InboxStatus'

import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import SwipeDownIcon from 'src/components/SvgIcons/SwipeDownIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import GuidesPhoneLink from 'src/sections/SectionDashboard/GuidesPhoneNumbersModal/GuidesPhoneLink'
import {
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'
import { primaryWhite, modalBlackOpacity } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function GuidesPhoneNumbersModal({
  setChatContent = () => {},
  closeModal = () => {},
  isMobileModal = false,
  hasNavFeaturesAccess = false,
  hasPBMFeaturesAccess = false,
}) {
  const { t } = useTranslation()
  const i18n = {
    callToActionPhoneNumberModal: t(
      'translation.home.call_modal.choose_a_number',
    ),
    healthGuide: t('translation.home.call_modal.health_guide'),
    pharmacyGuide: t('translation.home.call_modal.pharmacy_guide'),
  }

  const hasOnlyPBM = !hasNavFeaturesAccess && hasPBMFeaturesAccess

  const phone = useOrganizationPhoneQuery()

  return (
    <Root
      isMobileModal={isMobileModal}
      onClick={isMobileModal ? closeModal : null}
      data-test="guidesModal"
    >
      {isMobileModal && <SwipeDownIcon />}
      <ModalContainer
        isMobileModal={isMobileModal}
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer isMobileModal={isMobileModal}>
          {!isMobileModal && (
            <>
              <LeftButtonContainer />
              <NavigatorIcon />
            </>
          )}
          {isMobileModal && (
            <TitleMobile>{i18n.callToActionPhoneNumberModal}</TitleMobile>
          )}
          <ButtonClose40
            onClick={isMobileModal ? closeModal : setChatContent}
            border="none"
            dataTestProp="guidesModal.closeButton"
            withShadow
          />
        </HeaderContainer>
        {!isMobileModal && <Title>{i18n.callToActionPhoneNumberModal}</Title>}

        {!hasOnlyPBM && (
          <GuidesPhoneLink
            title={i18n.healthGuide}
            phoneNumber={phone.navigator}
            dataTestProp="navigatorPhone"
          />
        )}
        {hasPBMFeaturesAccess && (
          <GuidesPhoneLink
            title={i18n.pharmacyGuide}
            phoneNumber={phone.pharmacist}
            dataTestProp="pharmacistPhone"
          />
        )}
      </ModalContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isMobileModal }) =>
    isMobileModal ? 'flex-end' : 'flex-start'};
  align-items: center;
  position: ${({ isMobileModal }) => (isMobileModal ? 'absolute' : 'static')};
  background-color: ${({ isMobileModal }) =>
    isMobileModal ? `${modalBlackOpacity}` : `${primaryWhite}`};
  z-index: 2;
  width: 100%;
  height: 100%;
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ isMobileModal }) =>
    isMobileModal ? `${convertPxToRem(10)}` : '0'};
  border-radius: ${({ isMobileModal }) =>
    isMobileModal ? `${convertPxToRem(20, 20, 0, 0)}` : '0'};
  background: ${primaryWhite};
  width: 100%;
  padding: ${convertPxToRem(24)};
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isMobileModal }) =>
    isMobileModal ? `${convertPxToRem(16)}` : `${convertPxToRem(30)}`};
`
const LeftButtonContainer = styled.div`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
`

const NavigatorIcon = styled(InboxStatus)`
  pointer-events: none;
`

const Title = styled.span`
  ${heading4_21};
  text-align: center;
  margin-bottom: ${convertPxToRem(30)};
`
const TitleMobile = styled.span`
  ${heading4_21};
`

const mapStateToProps = ({ features }) => ({
  hasNavFeaturesAccess: features.featureFlags[FeatureFlag.CORE_NAV],
  hasPBMFeaturesAccess: features.featureFlags[FeatureFlag.PBM],
})

const mapDispatchToProps = (dispatch) => ({
  setChatContent: () =>
    dispatch(setThirdSectionContent(THIRD_SECTION_CONTENT.CHAT)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuidesPhoneNumbersModal)

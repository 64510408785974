import { gql, TypedDocumentNode } from '@apollo/client'

import { ReadChatResult, ReadChatVariables } from './types'

export const READ_CHAT_MUTATION: TypedDocumentNode<
  ReadChatResult,
  ReadChatVariables
> = gql`
  mutation readChat($id: ID!) {
    chat: readChat(id: $id) {
      id

      isUnread: is_unread
    }
  }
`

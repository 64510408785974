import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatPreview } from '../../api/chatBrief'

interface MessagePreview {
  preview: ChatPreview
}

export function MessagePreview({ preview }: MessagePreview) {
  const t = useScopedTranslation(
    'translation.chat.inbox.with_messages.inbox_card',
  )

  const { attachment, body } = preview

  if (!attachment) return <Message>{body}</Message>

  switch (attachment.kind) {
    case 'SystemNotificationType':
      return <Message>{attachment.message}</Message>

    case 'ChatMessageFileType': {
      return (
        <Attachment>
          <AttachmentIcon />

          {isImage(attachment.contentType) ? t('image') : t('document')}
        </Attachment>
      )
    }

    default:
      return null
  }
}

// Crude mime-type check
const isImage = (type: string) => type.startsWith('image/')

const Content = styled.div`
  ${text2_14};

  grid-area: preview;

  width: 100%;
  height: max-content;

  overflow: hidden;
`

const Message = styled(Content).attrs({ as: 'p' })`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Attachment = styled(Content)`
  line-height: ${convertPxToRem(16)};

  display: flex;
  flex-flow: row nowrap;
  gap: ${convertPxToRem(10)};
`

const AttachmentIcon = styled(Sprite).attrs({ name: 'misc/attachment' })`
  width: ${convertPxToRem(16)};
  height: ${convertPxToRem(16)};
`

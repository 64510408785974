import React from 'react'

import PropTypes from 'prop-types'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

function TabbarMiscProfileIcon({
  width = convertPxToRem(40),
  height = convertPxToRem(40),
  fill = 'none',
  border = 'none',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="-10 -10 40 40"
    >
      <circle stroke={border} fill="none" r="19" cx="10" cy="10" />
      <path
        d="M10 10.4167C12.7614 10.4167 15 8.17817 15 5.41675C15 2.65532 12.7614 0.416748 10 0.416748C7.23858 0.416748 5 2.65532 5 5.41675V5.41664C5.0027 8.17692 7.23961 10.4139 9.99989 10.4167L10 10.4167ZM10 8.75008C8.15905 8.75008 6.66667 7.2577 6.66667 5.41675V5.41674C6.66805 4.99638 6.75127 4.5803 6.91167 4.19174V4.19174C6.93496 4.13577 6.98105 4.09241 7.03833 4.07258C7.09612 4.0532 7.15951 4.06021 7.21167 4.09174V4.09174C8.4516 4.83499 9.8702 5.2273 11.3158 5.22674C11.9047 5.22636 12.4916 5.1604 13.0658 5.03008C13.1778 5.00586 13.2883 5.07703 13.3125 5.18904C13.3139 5.19572 13.3151 5.20246 13.3158 5.20925V5.20925C13.3255 5.27804 13.3313 5.34731 13.3333 5.41675V5.41675C13.3333 7.2577 11.841 8.75008 10 8.75008H10Z"
        fill={fill}
      />
      <path
        d="M10.0002 11.25C5.63 11.2551 2.08855 14.7965 2.0835 19.1667C2.0835 19.3968 2.27004 19.5833 2.50016 19.5833H17.5002C17.7303 19.5833 17.9168 19.3968 17.9168 19.1667V19.1668C17.9118 14.7966 14.3704 11.2551 10.0002 11.25L10.0002 11.25Z"
        fill={fill}
      />
    </svg>
  )
}

TabbarMiscProfileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  border: PropTypes.string,
}

export default TabbarMiscProfileIcon

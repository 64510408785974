import { gql, TypedDocumentNode } from '@apollo/client'

import type { FeaturesResult, FeaturesVariables } from './types'

export const FEATURES_QUERY: TypedDocumentNode<
  FeaturesResult,
  FeaturesVariables
> = gql`
  query features {
    user: currentUser {
      id
      settings: view_settings {
        features {
          ...feature
        }
      }
    }
  }

  fragment feature on Feature {
    name
    available
  }
`

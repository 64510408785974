import { reflect, variant } from '@effector/reflect'
import {
  UnitTargetable,
  attach,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { equals } from 'patronum'

import { CreateModalOptions, Modal, ModalState } from './types'

export function createModal({
  view: Component,
  beforeOpen,
  openOn,
}: CreateModalOptions): Modal {
  const $state = createStore<ModalState>(ModalState.Closed)

  const open = createEvent<void>()
  const closed = createEvent<void>()
  const next = createEvent<void>()

  $state.on(open, () => ModalState.Checking)

  sample({
    clock: open,
    target: beforeOpen as UnitTargetable<void>,
  })

  sample({
    clock: openOn,
    filter: equals($state, ModalState.Checking),
    fn: (isOpen): ModalState => (isOpen ? ModalState.Open : ModalState.Closed),
    target: $state,
  })

  sample({
    clock: next,
    filter: equals($state, ModalState.Open),
    fn: (): ModalState => ModalState.Closed,
    target: $state,
  })

  sample({
    clock: $state,
    filter: (state) => state === ModalState.Closed,
    target: closed,
  })

  const View = variant({
    if: equals($state, ModalState.Open),
    then: Component,
  })

  return { View, open, closed, next, __: { $state } }
}

export function createModalsQueue(modals: Modal[]) {
  const $idx = createStore(0)

  const next = createEvent()
  const mounted = createEvent()

  $idx.on(next, (idx) => idx + 1)

  const currentModalRunFx = attach({
    source: $idx,
    effect(idx) {
      modals[idx].open()
    },
  })

  sample({
    clock: [mounted, $idx.updates],
    filter: $idx.map((idx) => idx < modals.length),
    target: currentModalRunFx,
  })

  sample({
    clock: modals.map(({ closed }) => closed),
    target: next,
  })

  function Queue({ idx }: { idx: number }) {
    if (idx === modals.length) return null

    const { View } = modals[idx]

    return <View data-test={`modal-${idx}`} />
  }

  return reflect({
    view: Queue,
    bind: { idx: $idx },
    hooks: { mounted },
  })
}

import { UpdatePharmacyPopupContent } from './UpdatePharmacyPopupContent'

export const FavoritePharmacyUpdatePopup = {
  AddPharmacySuccess: <UpdatePharmacyPopupContent title="add.success.title" />,
  AddPharmacyFailure: (
    <UpdatePharmacyPopupContent
      title="add.error.common.title"
      description="add.error.common.text"
    />
  ),
  AddPharmacyLimitFailure: (
    <UpdatePharmacyPopupContent
      title="add.error.limit.title"
      description="add.error.limit.text"
    />
  ),
  RemovePharmacySuccess: (
    <UpdatePharmacyPopupContent title="remove.success.title" />
  ),
  RemovePharmacyFailure: (
    <UpdatePharmacyPopupContent
      title="remove.error.title"
      description="remove.error.text"
    />
  ),
}

import { attach, createEvent, sample } from 'effector'
import { createGate } from 'effector-react'

import { $$sessionStorage } from 'src/shared/storage/session'

interface EarlyAccessModalGateProps {
  dispatchStartNewChat: () => void
  dispatchSetIsShowThirdSectionModal: (isShow: boolean) => void
}

export const EarlyAccessModalGate = createGate<EarlyAccessModalGateProps>(
  'EarlyAccessModalGate',
)

export const chatOpened = createEvent<void>()
export const modalClosed = createEvent<void>()

export const openChatFx = attach({
  source: EarlyAccessModalGate.state,
  effect: ({ dispatchStartNewChat, dispatchSetIsShowThirdSectionModal }) => {
    dispatchSetIsShowThirdSectionModal(true)
    dispatchStartNewChat()
  },
})

sample({
  clock: chatOpened,
  target: [openChatFx, modalClosed],
})

sample({
  clock: modalClosed,
  target: $$sessionStorage.earlyAccessModalClosed,
})

/**
 * A compatibility-layer with `tokenStorageInterface`.
 *
 * This would then be replaced by directly writing to LocalStorage
 * using `persist` and `$access` / `$refresh`.
 */

import { createEffect, createEvent, sample } from 'effector'

import tokensStorageInterface from 'src/utils/storage/tokens'

export type TokenStore = { access: string; refresh: string }

export const save = createEvent<TokenStore>()
export const reset = createEvent<void>()

const saveFx = createEffect((tokens: TokenStore) =>
  tokensStorageInterface.modify.setTokens(tokens),
)

const resetFx = createEffect(() => tokensStorageInterface.modify.resetTokens())

sample({ clock: save, target: saveFx })
sample({ clock: reset, target: resetFx })

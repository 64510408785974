import { useCallback, useState } from 'react'

import { useParams } from '@reach/router'
import styled from 'styled-components'

import GenericEmptyStateIcon from 'src/components/GenericEmptyStateIcon'
import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import Spinner from 'src/components/Spinner'
import { PopupModal } from 'src/features/PopupModal'
import { Sprite } from 'src/shared/ui/Sprite'
import { BodyPadding40 } from 'src/theme/baseStyles'
import {
  primaryWhite,
  secondaryLightGrey,
  secondaryMidGrey,
} from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useAppointmentQuery } from '../api/appointmentQuery'
import { useAppointmentSubscription } from '../api/appointmentSubscription'

import { AppointmentActionBar } from './AppointmentActionBar'
import { AppointmentAssigneeActions } from './AppointmentAssigneeActions'
import { AppointmentAssigneeInfo } from './AppointmentAssigneeInfo'
import { AppointmentHeader } from './AppointmentHeader'
import { AppointmentMap } from './AppointmentMap'
import { AppointmentTimeSlots } from './AppointmentTimeSlots'

interface AppointmentPageParams {
  appointmentId: string
}

export function AppointmentPage() {
  const { appointmentId } = useParams<AppointmentPageParams>()

  useAppointmentSubscription(appointmentId)

  const { appointment, isAppointmentLoading } =
    useAppointmentQuery(appointmentId)

  const [isMapFocused, setMapFocused] = useState(false)
  const toggleMap = useCallback(
    () => setMapFocused((isFocused) => !isFocused),
    [],
  )

  if (isAppointmentLoading && !appointment) return <Spinner />

  if (!appointment) return <GenericEmptyStateIcon />

  return (
    <Page data-map-focused={isMapFocused} data-test="appointmentPage">
      <MapHolder>
        <AppointmentMap appointment={appointment} />

        <NavigationWrapper>
          <HeaderNavLightTheme />
        </NavigationWrapper>
      </MapHolder>

      <Content>
        <Notch onClick={toggleMap} data-test="appointmentPage.notch">
          <DragLineIcon />
        </Notch>

        <AppointmentHeader appointment={appointment} />

        <AppointmentTimeSlots timeSlots={appointment.timeSlots} />

        <Line />
        <AppointmentAssigneeInfo appointment={appointment} />

        <Line />
        <AppointmentAssigneeActions appointment={appointment} />
      </Content>

      <ActionBarWrapper>
        <AppointmentActionBar timeSlots={appointment.timeSlots} />
      </ActionBarWrapper>

      <PopupModal />
    </Page>
  )
}

const Page = styled.div`
  position: relative;
  ${BodyPadding40};
  padding-bottom: 0;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr max-content;
  grid-auto-flow: column;

  overflow: hidden;

  width: 100%;
  height: 100%;

  transition: grid-template-rows 0.4s ease-in-out;

  &[data-map-focused='true'] {
    grid-template-rows: 5fr 3fr max-content;
  }

  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`

const MapHolder = styled.div`
  position: relative;

  width: 100%;
  height: calc(100% + 24px);
`

const NavigationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: ${convertPxToRem(26)};
  pointer-events: none;
`

const Content = styled.div`
  position: relative;
  overflow-y: auto;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  width: 100%;
  height: 100%;

  background-color: ${primaryWhite};

  border-radius: ${convertPxToRem(20, 20, 0, 0)};
  box-shadow: ${convertPxToRem(0, -6, 14, 0)} rgba(0, 0, 0, 0.08);

  padding: ${convertPxToRem(0, 24, 24, 24)};

  gap: ${convertPxToRem(16)};
`

const Line = styled.hr`
  width: 100%;
  height: 0;

  border: ${convertPxToRem(0.5)} solid ${secondaryLightGrey};
  margin: 0;
`

const Notch = styled.button`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;

  border: none;
  background: ${primaryWhite};

  padding: ${convertPxToRem(4, 0, 0, 0)};
  margin-bottom: ${convertPxToRem(-8)};

  height: ${convertPxToRem(24)};
`

const DragLineIcon = styled(Sprite).attrs({ name: 'nav/drag_line' })`
  width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};
  color: ${secondaryMidGrey};
`

const ActionBarWrapper = styled.div`
  z-index: 0;

  background-color: ${primaryWhite};
`

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface SpinnerWithTitleProps {
  text: string
  className?: string
}

export function SpinnerWithTitle({
  text,
  className = '',
}: SpinnerWithTitleProps) {
  return (
    <Root className={className}>
      <StyledSpinner />
      <Title>{text}</Title>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${convertPxToRem(12)};
`
const StyledSpinner = styled(Spinner)`
  flex: none;
`
const Title = styled.span`
  color: ${secondaryGrey};
  ${text2_14};
`

/* eslint no-param-reassign: ["warn", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import type { Coordinates } from 'src/entities/geolocation'
import { DEFAULT_COORDS } from 'src/utils/locationHelpers'
import { createStorageInterface, StorageContainer } from 'src/utils/storage/api'

interface IPersistentStorage {
  redirectUrl: string | null

  email: string | null

  location: Coordinates
}

const initialState: IPersistentStorage = {
  redirectUrl: null,
  email: null,
  location: DEFAULT_COORDS,
}

export default createStorageInterface({
  container: StorageContainer.persistent,
  initialState,
  actions: {
    setRedirect(draft, url: string) {
      draft.redirectUrl = url
    },
    clearRedirect(draft) {
      draft.redirectUrl = null
    },

    setEmail(draft, email: string) {
      draft.email = email
    },
    clearEmail(draft) {
      draft.email = null
    },

    setLocation(draft, location: Coordinates) {
      draft.location = location
    },
  },
})

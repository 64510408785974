import { Router } from '@reach/router'

import { AppointmentPage } from 'src/pages/AppointmentPage'

function SectionAppointments() {
  return (
    <Router className="rootRouter">
      <AppointmentPage path="/:appointmentId" />
    </Router>
  )
}

export default SectionAppointments

import { memo, useCallback, useContext } from 'react'

import styled from 'styled-components'

import { NEW_THREAD_ID } from 'src/entities/chat'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useSendChatMessageMutation } from '../../api/sendChatMessageMutation'
import { useStartChatMutation } from '../../api/startChatMutation'
import { ThreadIdContext } from '../../lib/ThreadIdContext'

import { ReplyAttachment } from './ReplyAttachment'
import { ReplyInput } from './ReplyInput'

type SubmitReplyParams = { text: string } | { file: File }

function ChatReplyComponent() {
  const id = useContext(ThreadIdContext)!

  const { start, isLoading } = useStartChatMutation()
  const { send } = useSendChatMessageMutation()

  const submit = useCallback(
    async (params: SubmitReplyParams) => {
      const chatId = id === NEW_THREAD_ID ? await start() : id
      await send({ variables: { ...params, chatId } })
    },
    [id, send, start],
  )

  return (
    <Wrapper>
      <ReplyAttachment submit={submit} />
      <ReplyInput isLoading={isLoading} submit={submit} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: ${convertPxToRem(16)};

  padding: ${convertPxToRem(8, 16)};
`

export const ChatReply = memo(ChatReplyComponent)

import { ChangeEventHandler } from 'react'

import styled from 'styled-components'

import { InlineInputError } from 'src/shared/ui/InlineInputError'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TextInputProps {
  value?: string
  placeholder?: string
  onChange: (val: string) => void
  onClear?: () => void
  error?: string | null
  className?: string
  dataTestProp?: string
}

export function TextInput({
  placeholder = '',
  value = '',
  onChange,
  onClear = undefined,
  error = null,
  className = undefined,
  dataTestProp = 'textInput',
}: TextInputProps) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    onChange(target.value)
  }

  const hasError = Boolean(error)

  return (
    <Root className={className}>
      <InputWrapper data-has-error={hasError}>
        <Input
          data-test={dataTestProp}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        {onClear && (
          <ClearIcon onClick={onClear} data-test={`${dataTestProp}.clear`} />
        )}
      </InputWrapper>

      {hasError && (
        <InlineInputError dataTestProp={`${dataTestProp}.error`}>
          {error as string}
        </InlineInputError>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: ${convertPxToRem(4)};
`

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  height: ${convertPxToRem(48)};
  width: 100%;

  gap: ${convertPxToRem(10)};
  padding: ${convertPxToRem(0, 16)};

  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};

  background-color: ${primaryWhite};
  color: ${primaryDarkNavy};

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }

  &[data-has-error='true'] {
    border-color: ${statusErrorRed};
  }
`

const Input = styled.input`
  width: 100%;

  padding: 0;
  border: 0;
  background-color: transparent;
  ${text1_16};

  &::placeholder {
    color: ${secondaryMidGrey};
  }
`

const ClearIcon = styled(Sprite).attrs({ name: 'misc/xmark' })`
  cursor: pointer;

  ${Input}:placeholder-shown + & {
    display: none;
  }
`

import { MouseEventHandler, useCallback } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text1_16, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { stepChanged, stepChangedBack } from '../../model/core'
import { UserLocationsStep } from '../../types'

interface AddressFieldProps {
  address: string
}

export function AddressField({ address }: AddressFieldProps) {
  const t = useScopedTranslation(
    'translation.profile.account_settings.preference',
  )

  const [setStep, goBack] = useUnit([stepChanged, stepChangedBack])

  const handleClearAddress: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation()
      setStep(UserLocationsStep.Search)
    },
    [setStep],
  )

  return (
    <Root>
      <FieldLabel>{t('address')}</FieldLabel>

      <Wrapper data-test="addressField" onClick={goBack}>
        <Address>{address}</Address>

        <IconWrapper
          data-test="addressField.clear"
          onClick={handleClearAddress}
        >
          <ClearIcon />
        </IconWrapper>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: ${convertPxToRem(4)};
`

const FieldLabel = styled.span`
  ${text3_12};
  color: ${primaryDarkNavy};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: ${convertPxToRem(16)};
  min-height: ${convertPxToRem(50)};

  border: solid ${convertPxToRem(1)} ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
`

const Address = styled.span`
  display: flex;
  text-overflow: clip;
  overflow: hidden;

  ${text1_16};
  color: ${primaryDarkNavy};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  cursor: pointer;
`

const ClearIcon = styled(Sprite).attrs({ name: 'misc/xmark' })`
  width: ${convertPxToRem(16)};
  height: ${convertPxToRem(16)};
  color: ${secondaryGrey};
`

import { path } from 'ramda'

import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'

const pathsArray = ['bill-claims', 'care-team', 'find-care', 'talk-with-doctor']

export const checkRootPathName = (tab, location) => {
  const pathName = path(['pathname'], location)

  const targetTab = tab.split('/')[0]
  const actualTab = pathName.split('/')[1]
  const homePathName = `/home${pathName}`

  if (pathsArray.includes(actualTab)) return homePathName.includes(targetTab)
  return pathName.includes(targetTab)
}

/** @returns {boolean} */
export const checkPathName = (_path, location) => {
  const pathName = path(['pathname'], location)
  return pathName.includes(_path)
}

export const getAvailableRoutes = (featureFlags) => {
  const homeTabsDataMobile = []
  if (featureFlags[FeatureFlag.CORE_NAV]) {
    if (featureFlags[FeatureFlag.GET_CARE_SECTION_NAVIGATION]) {
      homeTabsDataMobile.push({
        id: 'getCare',
        to: `/${ROUTES.HOME_GET_CARE_PATH}`,
        tabTitle: 'translation.home.tabs.get_care',
        icon: 'services/primary_care_doctor',
        section: ROUTES.HOME_GET_CARE,
      })
    }
    if (featureFlags[FeatureFlag.BILL_ADVOCACY]) {
      homeTabsDataMobile.push({
        id: 'billing',
        to: `/${ROUTES.HOME_BILL_CLAIMS_ACTIVE_PATH}`,
        tabTitle: 'translation.home.care_tools.bill_support.title',
        icon: 'services/billing_advocacy',
        section: ROUTES.BILL_CLAIM,
      })
    }
    if (featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      homeTabsDataMobile.unshift({
        id: 'activity',
        to: `/${ROUTES.HOME_ACTIVITY_PATH}`,
        tabTitle: 'translation.home.tabs.activity',
        icon: 'services/home',
        section: ROUTES.HOME_ACTIVITY,
      })
    }
    if (featureFlags[FeatureFlag.MED_CABINET]) {
      homeTabsDataMobile.splice(
        featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED] ? 2 : 1,
        0,
        {
          id: 'medications',
          to: `/${ROUTES.HOME_MEDICATIONS_CABINET_PATH}`,
          tabTitle: 'translation.home.medications.medication_link',
          icon: 'services/rx',
          section: ROUTES.MEDICATIONS,
        },
      )
    }
  } else if (featureFlags[FeatureFlag.PBM]) {
    if (featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      homeTabsDataMobile.push({
        id: 'activity',
        to: `/${ROUTES.HOME_ACTIVITY_PATH}`,
        tabTitle: 'translation.home.tabs.activity',
        icon: 'services/home',
        section: ROUTES.HOME_ACTIVITY,
      })
    }
    if (featureFlags[FeatureFlag.MED_CABINET]) {
      homeTabsDataMobile.push({
        id: 'medications',
        to: `/${ROUTES.HOME_MEDICATIONS_CABINET_PATH}`,
        tabTitle: 'translation.home.medications.medication_link',
        icon: 'services/rx',
        section: ROUTES.MEDICATIONS,
      })
    }
  }

  const mainLinks = []

  if (featureFlags[FeatureFlag.CORE_NAV]) {
    if (featureFlags[FeatureFlag.GET_CARE_SECTION_NAVIGATION]) {
      mainLinks.push({
        to: ROUTES.HOME_GET_CARE_PATH,
        icon: 'services/primary_care_doctor',
        title: 'translation.home.tabs.get_care',
        section: ROUTES.HOME_GET_CARE,
        links: [
          featureFlags[FeatureFlag.CARE_PLANS] && {
            title: 'translation.home.tabbar.care_plans',
            to: ROUTES.CARE_PLANS_ACTIVE_PATH,
            section: ROUTES.CARE_PLAN,
          },
          featureFlags[FeatureFlag.CARE_LOCATOR] && {
            title: 'translation.home.care_tools.care_finder.title',
            to: ROUTES.FIND_CARE,
            section: ROUTES.FIND_CARE,
          },
          featureFlags[FeatureFlag.MY_CARE_TEAM] && {
            title: 'translation.home.care_tools.my_care_team.title',
            to: ROUTES.CARE_TEAM_PROVIDERS_PATH,
            section: ROUTES.CARE_TEAM,
          },
        ].filter(Boolean),
      })
    }
    if (featureFlags[FeatureFlag.BILL_ADVOCACY]) {
      mainLinks.push({
        to: ROUTES.HOME_BILL_CLAIMS_ACTIVE_PATH,
        icon: 'services/billing_advocacy',
        title: 'translation.home.care_tools.bill_support.title',
        section: ROUTES.BILL_CLAIM,
      })
    }
    if (featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      mainLinks.unshift({
        to: ROUTES.HOME_ACTIVITY_PATH,
        icon: 'services/home',
        title: 'translation.home.tabs.activity',
        section: ROUTES.HOME_ACTIVITY,
      })
    }
    if (featureFlags[FeatureFlag.MED_CABINET]) {
      mainLinks.splice(
        featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED] ? 2 : 1,
        0,
        {
          to: ROUTES.HOME_MEDICATIONS_CABINET_PATH,
          icon: 'services/rx',
          title: 'translation.home.medications.medication_link',
          section: ROUTES.MEDICATIONS,
        },
      )
    }
  } else if (featureFlags[FeatureFlag.PBM]) {
    if (featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      mainLinks.push({
        to: ROUTES.HOME_ACTIVITY_PATH,
        icon: 'services/home',
        title: 'translation.home.tabs.activity',
        section: ROUTES.HOME_ACTIVITY,
      })
    }
    if (featureFlags[FeatureFlag.MED_CABINET]) {
      mainLinks.push({
        to: ROUTES.HOME_MEDICATIONS_CABINET_PATH,
        icon: 'services/rx',
        title: 'translation.home.medications.medication_link',
        section: ROUTES.MEDICATIONS,
      })
    }
  }

  if (featureFlags[FeatureFlag.SHARED_INSURANCE_BENEFITS]) {
    homeTabsDataMobile.push({
      id: 'coverage',
      to: `/${ROUTES.HOME_COVERAGE_PATH}`,
      tabTitle: 'translation.home.tabs.coverage',
      icon: 'services/coverage',
      section: ROUTES.HOME_COVERAGE,
    })
    mainLinks.push({
      to: ROUTES.HOME_COVERAGE_PATH,
      icon: 'services/coverage',
      title: 'translation.home.tabs.coverage',
      section: ROUTES.HOME_COVERAGE,
    })
  }
  if (featureFlags[FeatureFlag.SHARED_PROFILE]) {
    mainLinks.push({
      to: ROUTES.PROFILE,
      icon: 'services/profile',
      title: 'translation.profile.title',
      section: ROUTES.PROFILE,
    })
  }

  return { homeTabsDataMobile, mainLinks }
}

export const getHomePage = (featureFlags) => {
  if (featureFlags[FeatureFlag.CORE_NAV]) {
    if (!featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      return ROUTES.HOME_GET_CARE_PATH
    }
    return ROUTES.HOME_ACTIVITY_PATH
  }
  if (featureFlags[FeatureFlag.PBM]) {
    if (featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED]) {
      return ROUTES.HOME_ACTIVITY_PATH
    }
    if (featureFlags[FeatureFlag.MED_CABINET]) {
      return ROUTES.HOME_MEDICATIONS_CABINET_PATH
    }
    if (featureFlags[FeatureFlag.SHARED_INSURANCE_BENEFITS]) {
      return ROUTES.HOME_COVERAGE_PATH
    }
  }
  return null
}

export const getActiveSection = (pathname) => {
  const getCareRoutes = [
    ROUTES.HOME_GET_CARE,
    ROUTES.CARE_PLAN,
    ROUTES.FIND_CARE,
    ROUTES.CARE_TEAM,
  ]

  if (getCareRoutes.some((route) => pathname?.includes(route))) {
    return ROUTES.HOME_GET_CARE
  }

  if (pathname?.includes(ROUTES.BILL_CLAIM)) {
    return ROUTES.BILL_CLAIM
  }

  if (pathname?.includes(ROUTES.HOME_ACTIVITY)) {
    return ROUTES.HOME_ACTIVITY
  }

  if (
    pathname?.includes(ROUTES.MEDICATIONS) ||
    pathname?.includes(ROUTES.DRUG_SEARCH_PATH)
  ) {
    return ROUTES.MEDICATIONS
  }

  if (pathname?.includes(ROUTES.HOME_COVERAGE)) {
    return ROUTES.HOME_COVERAGE
  }

  if (pathname?.includes(ROUTES.PROFILE)) {
    return ROUTES.PROFILE
  }

  return ''
}

import { useEffect } from 'react'

import useBillClaimsFiledQuery from 'src/apollo/hooks/useBillClaimsFiledQuery'
import useBillClaimsResolvedQuery from 'src/apollo/hooks/useBillClaimsResolvedQuery'
import useCurrentUserIgnoreErrorsQuery from 'src/apollo/hooks/useCurrentUserIgnoreErrorsQuery'
import { useFeedItemsQuery } from 'src/entities/feedItems'
import { useCarePlansQuery } from 'src/sections/SectionCarePlans/CarePlans/api/carePlansQuery'

function SubscriptionHandler() {
  const { userData } = useCurrentUserIgnoreErrorsQuery()

  const hasUser = !!userData.id

  return hasUser ? <CurrentUserSubscriptions /> : null
}

function CurrentUserSubscriptions() {
  const isSkipQuery = true
  const { subscribeToFeedItems } = useFeedItemsQuery()

  const { subscribeBillClaimsFiledtoEvent } =
    useBillClaimsFiledQuery(isSkipQuery)

  const { subscribeBillClaimsResolvedtoEvent } =
    useBillClaimsResolvedQuery(isSkipQuery)

  const { subscribeCarePlansToEvent: subscribeCarePlansActiveToEvent } =
    useCarePlansQuery({
      isSkipQuery,
      isActive: true,
    })

  const { subscribeCarePlansToEvent: subscribeCarePlansPastToEvent } =
    useCarePlansQuery({
      isSkipQuery,
      isActive: false,
    })

  useEffect(() => {
    const unsubscribeTodo = subscribeToFeedItems()
    const unsubscribeBillClaimFiled = subscribeBillClaimsFiledtoEvent()
    const unsubscribeBillClaimResolved = subscribeBillClaimsResolvedtoEvent()
    const unsubscribeCarePlansActive = subscribeCarePlansActiveToEvent()
    const unsubscribeCarePlansPast = subscribeCarePlansPastToEvent()
    return () => {
      unsubscribeTodo()
      unsubscribeBillClaimFiled()
      unsubscribeBillClaimResolved()
      unsubscribeCarePlansActive()
      unsubscribeCarePlansPast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default SubscriptionHandler

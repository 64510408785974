import { sample } from 'effector'
import { createGate } from 'effector-react'

import { $$navigate } from 'src/entities/navigate'
import {
  $$pharmacySearch,
  selectPharmacyFx,
  drugSearchPharmaciesQuery,
} from 'src/features/pharmacySearch'
import { DEFAULT_COORDS } from 'src/utils/locationHelpers'

export const PreferredPharmacyResultGate = createGate<{ drugName: string }>({
  name: 'PreferredPharmacyResultGate',
})

sample({
  clock: selectPharmacyFx.doneData,
  filter: PreferredPharmacyResultGate.status,
  target: $$navigate.backFx,
})

sample({
  clock: PreferredPharmacyResultGate.open,
  source: $$pharmacySearch.$preferredPharmacyCoordinates,
  fn: (coordinates) => coordinates ?? DEFAULT_COORDS,
  target: drugSearchPharmaciesQuery.refresh,
})

import { createEffect } from 'effector'

import { store } from 'src/store'
import { clearStore } from 'src/store/slices/common'
import tokensStorageInterface from 'src/utils/storage/tokens'

// FIXME: migrate to effector-managed token storage
export const resetTokensFx = createEffect(() =>
  tokensStorageInterface.modify.resetTokens(),
)

export const clearStoreFx = createEffect(
  () => void store.dispatch(clearStore()),
)

import { memo, MouseEventHandler } from 'react'

import styled from 'styled-components'

import BackIcon from 'src/components/SvgIcons/BackIcon'
import { IconBoxShadow } from 'src/theme/baseStyles'
import { primaryWhite, secondaryGrey, primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonBack40Props {
  isDarkTheme?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  dataTestProp?: string
  className?: string
}

/** @deprecated Prefer using NavButton from `shared/ui` */
export function ButtonBack40({
  onClick,
  isDarkTheme = false,
  dataTestProp = 'backButton',
  className = '',
}: ButtonBack40Props) {
  return (
    <Root
      onClick={onClick}
      data-dark={isDarkTheme}
      data-test={dataTestProp}
      className={className}
    >
      <BackIcon fill={isDarkTheme ? primaryWhite : secondaryGrey} />
    </Root>
  )
}

const Root = styled.div`
  pointer-events: auto;
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${primaryDarkNavy};
  border: none;
  cursor: pointer;

  &[data-dark='false'] {
    background-color: ${primaryWhite};
    ${IconBoxShadow};
  }
`

/** @deprecated Prefer using NavButton from `shared/ui` */
export default memo(ButtonBack40)

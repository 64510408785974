import { useMemo } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { buildAddressFromAttributes } from '../../lib/address'
import { $editableLocation } from '../../model/edit'

import { AddressField } from './AddressField'
import { ApartmentInput } from './ApartmentInput'

export function AddressFields() {
  const location = useUnit($editableLocation)

  const address = useMemo(() => {
    if (location) {
      return buildAddressFromAttributes(location)
    }
    return null
  }, [location])

  if (!address) return null

  return (
    <Root>
      <AddressField address={address} />

      <ApartmentInput text={location?.address_line_2 ?? ''} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: ${convertPxToRem(24)};
`

import { pipe, map } from 'ramda'

import i18next from 'src/translations/i18n'

const extractGender = (g) => {
  switch (g) {
    case 'm':
      return i18next.t(
        'translation.home.care_tools.care_finder.filters.filter_gender.male',
      )
    case 'f':
      return i18next.t(
        'translation.home.care_tools.care_finder.filters.filter_gender.female',
      )
    default:
      return null
  }
}

const extractDoctorBase = (x) => {
  const addresses = x?.addresses || []
  const sortAddressesByMinDistance = [...addresses].sort(
    (a, b) => a.distance - b.distance,
  )
  const distance = sortAddressesByMinDistance?.[0]?.distance ?? 0
  const preferredAddress = addresses.find((a) => a?.is_preferred === true)
  const defaultAddress = addresses?.[0] || {}
  const firstName = x?.first_name || ''
  const lastName = x?.last_name || ''
  const gender = x?.gender || ''

  return {
    id: x.id || '',
    name: `${firstName} ${lastName}`,
    firstName,
    middleName: x?.middle_name || '',
    lastName,
    biography: x?.biography || '',
    initials: firstName && lastName ? `${firstName?.[0]}${lastName?.[0]}` : '',
    specialization: x?.specialties?.[0]?.display_name || '',
    specialties: x?.specialties || [],
    rating: x?.rating ?? 0,
    distance,
    videoUrls: x?.video_urls || [],
    languages: x?.languages || [],
    educations: x?.educations || [],
    introduction: x?.introduction || '',
    certifications: x?.board_certifications || [],
    awards: x?.awards || [],
    memberships: x?.memberships || [],
    gender: extractGender(gender),
    imageUrl: x?.image_url || '',
    addresses,
    surgeries: x?.surgeries || [],
    npi: x?.npi || '',
    preferredAddress,
    defaultAddress,
    preferredPhone: preferredAddress?.phone || '',
    isFavorite: x.isFavorite ?? false,
    insuranceTier: x?.insuranceTier?.name ?? '',
    insuranceCost: x?.insuranceCost ?? '',
  }
}

export const extractDoctor = (x) => {
  const result = extractDoctorBase(x)
  result.cost = x?.cost?.value || ''
  return result
}

const extractFacilityBase = (x) => ({
  id: x.id || '',
  name: x?.name || '',
  introduction: x?.introduction || '',
  address: x?.address || '',
  distance: x?.distance ?? 0,
  latitude: x?.latitude ?? 0,
  longitude: x?.longitude ?? 0,
  initials: x?.name ? x.name.charAt(0) : '',
  work_periods: x?.work_periods || [],
  weekday_text: x?.weekday_text || [],
  isFacility: true,
  preferredPhone: x?.phone || '',
  isFavorite: x?.isFavorite || false,
  categories: x?.categories ?? [],
  tier: x?.tier?.name ?? '',
  cost: x?.insuranceCost ?? '',
})

export const extractFacility = (x) => {
  const result = extractFacilityBase(x)
  return result
}

export const extractDoctorsCollection = (data) => pipe(map(extractDoctor))(data)
export const extractFacilitiesCollection = (data) =>
  pipe(map(extractFacility))(data)

export const extractDoctorsData = (data) => {
  const doctors = extractDoctorsCollection(data?.doctors?.nodes || [])

  const pageInfo = data?.doctors?.pageInfo || {}

  return { doctors, pageInfo }
}

export const extractFacilitiesData = (data) => {
  const facilities = extractFacilitiesCollection(data?.facilities?.nodes || [])

  const pageInfo = data?.facilities?.pageInfo || {}

  return { facilities, pageInfo }
}

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { primaryWhite, primaryBlue } from 'src/theme/colors'
import { textLink1_16 } from 'src/theme/fonts'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface GuidesPhoneLinkProps {
  phoneNumber: string
  title?: string
  dataTestProp?: string
}

function GuidesPhoneLink({
  phoneNumber,

  title = '',
  dataTestProp = 'guidesPhone',
}: GuidesPhoneLinkProps) {
  const phone = formatPhone(phoneNumber, PHONE_FORMATS.national)
  const url = formatPhone(phoneNumber, PHONE_FORMATS.uri)!

  return (
    <Root data-test={dataTestProp}>
      <GuideTitle>{title}</GuideTitle>
      <CallLink href={url} data-test={`${dataTestProp}.link`}>
        <Sprite
          name="misc/call"
          height={convertPxToRem(24)}
          width={convertPxToRem(24)}
        />
        <GuideNumber>{phone}</GuideNumber>
      </CallLink>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const GuideTitle = styled.span`
  ${textLink1_16};
  text-align: left;
  margin-bottom: ${convertPxToRem(8)};
`
const CallLink = styled.a`
  border-radius: ${convertPxToRem(36)};
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(0, 44, 0, 24)};
  margin-bottom: ${convertPxToRem(16)};
  width: 100%;
  border: 0;
  display: flex;
  align-items: center;
  background-color: ${primaryBlue};
  color: ${primaryWhite};
  ${textLink1_16};
`
const GuideNumber = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`

export default GuidesPhoneLink

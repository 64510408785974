import { useCallback } from 'react'

import { reflect, variant } from '@effector/reflect'
import { useLocation, useNavigate, WindowLocation } from '@reach/router'
import { or } from 'patronum'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { Pharmacy } from 'src/entities/pharmacy'
import { HoverButton } from 'src/features/HoverButton'
import {
  $$pharmacySearch,
  drugSearchPharmaciesQuery,
  pharmaciesListExpanded,
} from 'src/features/pharmacySearch'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryBlue } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { PreferredPharmacyCard } from 'src/widgets/PreferredPharmacyCard'

import { ErrorContent } from './ErrorContent'

interface LocationState {
  drugName: string
}

interface PharmaciesContentProps {
  pharmacies: Pharmacy[]
}

function PharmaciesContentView({ pharmacies }: PharmaciesContentProps) {
  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName } = state ?? {}

  const t = useScopedTranslation('translation.medications.drug_search.buttons')

  const navigate = useNavigate()

  const navigateToMap = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_PHARMACY_MAP, {
      state: { drugName },
      replace: true,
    })
  }, [drugName, navigate])

  return (
    <>
      {pharmacies.map((pharmacy, index) => (
        <PreferredPharmacyCard
          key={pharmacy.name}
          pharmacy={pharmacy}
          order={index + 1}
        />
      ))}
      <ShowMoreButton>
        {t('show_more')}
        <Chevron />
      </ShowMoreButton>
      <MapButton>
        <HoverButton.Map onClick={navigateToMap} dataTestProp="mapButton" />
      </MapButton>
    </>
  )
}
function ContentSpinner() {
  const t = useScopedTranslation('translation.medications.drug_search')

  return <SpinnerWithTitle text={t('status.searching')} />
}

const MapButton = styled(HoverButton)`
  display: flex;
  position: absolute;
  bottom: ${convertPxToRem(50)};

  ${mediaQueryFor.tabletOrLess} {
    bottom: ${convertPxToRem(128)};
  }
`
const Button = styled.button`
  ${textLink2_14};
  color: ${primaryBlue};
  border: none;
  background: none;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: ${convertPxToRem(5)};
`
const Chevron = styled(Sprite).attrs({ name: 'misc/chevron' })`
  width: ${convertPxToRem(16)};
  height: ${convertPxToRem(16)};

  transform: rotate(90deg);
`

const Content = reflect({
  view: PharmaciesContentView,
  bind: {
    pharmacies: $$pharmacySearch.$foundPharmacies,
  },
})

const ShowMoreButton = variant({
  if: $$pharmacySearch.$hasExtendOption,
  then: Button,
  bind: {
    onClick: pharmaciesListExpanded,
  },
})

const ContentController = variant({
  if: $$pharmacySearch.$hasPharmacies,
  // @ts-expect-error: invalid variant typing
  then: Content,
  else: ErrorContent,
})

export const PharmaciesContent = variant({
  if: or(
    drugSearchPharmaciesQuery.$pending,
    $$pharmacySearch.$isPendingSelection,
  ),
  then: ContentSpinner,
  else: ContentController,
})

import { FunctionComponent } from 'react'

export interface ActivityCardItem extends CardItem {
  id: string
  category: string
  createdAt: Date | string
  updatedAt: Date | string
  cardType: string
  resourceId: string
  resourceStatus: string
  resourceImageUrl: string
  imageUrl: string
  isResourceArchived: boolean
  url: string
  icon?: FunctionComponent
}

export interface CardItem {
  title: string
  description: string
  label: string
  callToAction: string
  resourceImageUrl?: string
  imageUrl?: string
}

export interface IterableInAppMessage {
  messageId: string
  createdAt: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customPayload: Record<string, any>
  read: boolean
}

export enum FeedItemType {
  InApp = 'InApp',
  Todo = 'Todo',
}

import { TypePolicy } from '@apollo/client'

export const DoctorTypePolicy: TypePolicy = {
  fields: {
    addresses: {
      keyArgs: (args) => args?.tier_kinds?.join(':') ?? '*',
    },
  },
}

export const CareSearchSpecialtyPolicy: TypePolicy = {
  keyFields: ({ id }) => `CareSearchInterface:${id}`,
}

export const CareSearchSubSpecialtyPolicy: TypePolicy = {
  keyFields: ({ id }) => `CareSearchInterface:${id}`,
}

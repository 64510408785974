import React from 'react'

import { Router } from '@reach/router'

import GetCare from 'src/sections/SectionHome/GetCare/GetCare'

function CareToolsIndex() {
  return (
    <Router className="rootRouter">
      <GetCare path="/" />
    </Router>
  )
}

export default CareToolsIndex

import { TypedDocumentNode, gql } from '@apollo/client'

import {
  CarePlansSubscriptionResult,
  CarePlansSubscriptionVariables,
} from './types'

export const CARE_PLANS_SUBSCRIPTION: TypedDocumentNode<
  CarePlansSubscriptionResult,
  CarePlansSubscriptionVariables
> = gql`
  subscription carePlansSubscription($archived: Boolean) {
    carePlansEvents(archived: $archived) {
      event
      carePlan: care_plan {
        id
        isArchived: archived
        feedFields: feed_fields {
          description
          label
        }
      }
    }
  }
`

import { LocationAttributesInput } from 'src/shared/api/types'

import { UserLocation } from '../api/location'

export function buildAddressFromAttributes(
  location: Pick<
    LocationAttributesInput,
    'address_line_1' | 'city' | 'state' | 'zip'
  >,
): string {
  const address = [location.address_line_1, location.city, location.state]
    .filter(Boolean)
    .join(', ')

  return [address, location.zip].filter(Boolean).join(' ').trim()
}

export function mapLocationToAttributes({
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  latitude,
  longitude,
  kind,
  name,
  isPreferred,
}: UserLocation): LocationAttributesInput {
  return {
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    city,
    state,
    zip,
    latitude,
    longitude,
    kind,
    name,
    is_preferred: isPreferred,
  }
}

import { TypedDocumentNode, gql } from '@apollo/client'

import { InboxStatusResult, InboxStatusVariables } from './types'

export const INBOX_STATUS_QUERY: TypedDocumentNode<
  InboxStatusResult,
  InboxStatusVariables
> = gql`
  query inboxStatus {
    user: currentUser {
      id

      unreadCount: unread_chats_count

      status: navigator_work_schedule {
        isOnline: online
      }
    }
  }
`

import { TypedDocumentNode, gql } from '@apollo/client'

import { LOCATION_FRAGMENT } from '../location/document'

import { UpdateLocationResult, UpdateLocationVariables } from './types'

export const UPDATE_LOCATION_MUTATION: TypedDocumentNode<
  UpdateLocationResult,
  UpdateLocationVariables
> = gql`
  mutation updateLocation($id: ID!, $attributes: LocationAttributesInput!) {
    updateLocation(id: $id, attributes: $attributes) {
      ...userLocation
    }
  }

  ${LOCATION_FRAGMENT}
`

import { gql } from '@apollo/client'

const MEDICATION_PRICE_BREAKDOWN_QUERY = gql`
  query medicationPriceBreakdown($price_id: String!) {
    medicationPriceBreakdown(price_id: $price_id) {
      patient_pay_type
      patient_pay_amount
      ingredient_cost
      plan_pay_amount
      plan_name
    }
  }
`

export default MEDICATION_PRICE_BREAKDOWN_QUERY

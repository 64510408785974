import React, { FunctionComponent, MouseEventHandler } from 'react'

import styled from 'styled-components'

import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import { IconProps } from 'src/components/SvgIcons/types'
import {
  secondaryLightGrey,
  primaryDarkNavy,
  secondaryGrey,
  primaryWhite,
} from 'src/theme/colors'
import { text2_14, heading7_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface INavButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  Icon: FunctionComponent<IconProps>
  title: string
  color: string
  description: string
  dataTestProp: string
}

function NavButton({
  onClick,
  Icon,
  title,
  color,
  description,
  dataTestProp,
}: INavButtonProps) {
  return (
    <Content onClick={onClick} data-test={dataTestProp}>
      <IconWrapper>
        <IconSvg40
          fill={primaryDarkNavy}
          IconSvg={Icon}
          bgColor={color}
          border="none"
        />
        <IconText>
          <IconTitle>{title}</IconTitle>
          <IconDescription>{description}</IconDescription>
        </IconText>
      </IconWrapper>
    </Content>
  )
}

const Content = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${convertPxToRem(140)};
  width: 100%;
  padding: ${convertPxToRem(20)} ${convertPxToRem(16)};

  border-radius: ${convertPxToRem(8)};
  background-color: ${primaryWhite};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  margin-bottom: ${convertPxToRem(8)};
  min-height: ${convertPxToRem(96)};

  :active,
  :hover,
  :focus {
    outline: none;
  }
`
const IconText = styled.div`
  margin-left: ${convertPxToRem(14)};
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const IconTitle = styled.h2`
  ${heading7_14};
  color: ${primaryDarkNavy};
`
const IconDescription = styled.p`
  ${text2_14};
  color: ${secondaryGrey};
`
const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default NavButton

import { createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'

import { remap } from '@shared/lib/effector/remap'

import { save, TokenStore } from './compat'
import { setup } from './setup'

/** @deprecated pending refactoring & split into separate stores */
export const $tokens = createStore<TokenStore | null>(null)

export const $access = remap($tokens, 'access')
export const $refresh = remap($tokens, 'refresh')

sample({ clock: save, target: $tokens })

persist({ store: $tokens, key: 'tokens', pickup: setup })

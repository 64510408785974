import {
  Children,
  ElementType,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  ReactElement,
} from 'react'

import { useIntersectionObserver } from '../lib/useIntersectionObserver'

interface PaginatedParams {
  fetchMore: () => void

  parent?: ElementType
  children: ReactElement | ReactElement[]

  after?: ReactNode
}

export function Paginated({
  fetchMore,

  parent: Component = 'div',

  children,
  after = null,
}: PaginatedParams) {
  // Lazy callback ref that does not cause
  // IntersectionObserver to be recreated
  const callback = useRef(fetchMore)

  useEffect(() => {
    callback.current = fetchMore
  }, [fetchMore])

  const { root, observe } = useIntersectionObserver(callback)

  const target = Children.toArray(children).length - 1

  const list = Children.map(children, (child, index) =>
    index === target ? cloneElement(child, { ref: observe }) : child,
  )

  return (
    <Component ref={root}>
      {list}

      {after}
    </Component>
  )
}

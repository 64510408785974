import { useRef } from 'react'

import { reflect, variant } from '@effector/reflect'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { SearchBar, useAutomaticFocus } from 'src/features/SearchBar'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { secondaryBackgroundGrey, secondaryMidGrey } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $search, searchChanged } from '../../model/autocomplete'
import { $editableLocationId, locationDestroyed } from '../../model/edit'

import { FoundAddressesList } from './FoundAddressesList'

export function SearchLocation() {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  const searchRef = useRef<HTMLInputElement>(null)

  useAutomaticFocus(searchRef)

  return (
    <Wrapper>
      <Content>
        <Search placeholder={t('input_placeholder')} inputRef={searchRef} />

        <FoundAddressesList />
      </Content>

      <RemoveButton title={t('remove_address')} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: ${convertPxToRem(24)};

  ${mediaQueryFor.mobile} {
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: start;

  gap: ${convertPxToRem(32)};
  width: 100%;
  height: 100%;

  ${mediaQueryFor.mobile} {
    gap: ${convertPxToRem(16)};
  }
`

const AddressBar = reflect({
  view: SearchBar,
  bind: {
    value: $search,
    onChange: searchChanged,
    icon: <Sprite name="map/locator" />,
  },
})

const Search = styled(AddressBar)`
  background-color: ${secondaryBackgroundGrey};

  & > input::placeholder {
    color: ${secondaryMidGrey};
  }
`

const RemoveButton = variant({
  if: $editableLocationId.map(Boolean),
  then: ButtonBottom,
  bind: {
    type: 'button',
    theme: 'delete',
    handleClick: locationDestroyed,
  },
})

/* eslint-disable import/prefer-default-export */

import { shallowEqual } from 'react-redux'

import { AppStore, RootState } from 'src/store'

interface IObserveStoreArgs<T> {
  store: AppStore
  selector: (state: RootState) => T
  skipInitial?: boolean
  onChange: (selected: T) => void
}

/**
 * Helper function to observe store changes
 * Makes a reference equality check before firing callback
 *
 * @param store Redux store
 * @param selector Function to select some slice/part of the store
 * @param skipInitial Skip calling onChange on subscription
 * @param onChange Callback that will be called on store change
 */
export function observeStore<T>({
  store,
  selector,
  skipInitial = false,
  onChange,
}: IObserveStoreArgs<T>) {
  if (!store) {
    throw new Error(`"store" should be redux store, received ${store}`)
  }

  if (typeof selector !== 'function') {
    throw new Error(`"selector" should be a function, received ${selector}`)
  }

  if (typeof onChange !== 'function') {
    throw new Error(`"onChange" should be a function, received ${onChange}`)
  }

  let currentState: T

  function observe() {
    const nextState = selector(store.getState())

    if (shallowEqual(nextState, currentState)) return

    currentState = nextState
    onChange(currentState)
  }

  const unsubscribe = store.subscribe(observe)

  if (skipInitial) {
    currentState = selector(store.getState())
  } else {
    observe()
  }

  return unsubscribe
}

import { HTMLAttributes, ReactNode } from 'react'

import styled from 'styled-components'

import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface NavButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
}

export function NavButton({
  children,
  className = undefined,

  ...props
}: NavButtonProps) {
  return (
    <Button className={className} {...props}>
      {children}
    </Button>
  )
}

const Button = styled.button`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  color: ${secondaryGrey};
  background-color: ${primaryWhite};

  cursor: pointer;
  padding: ${convertPxToRem(8)};

  border: none;
  border-radius: 50%;

  box-shadow: ${convertPxToRem(0, 4, 16, 0)} #cecece80;

  &:disabled {
    cursor: inherit;
  }

  & > svg {
    /* Safari SVG fix */
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }
`

import React from 'react'

import { nativeSystemBlack } from 'src/theme/colors'

interface CalendarIconProps {
  className?: string
}

export function CalendarIcon({ className = undefined }: CalendarIconProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 16.5C2.22386 16.5 2 16.2761 2 16V7.5C2 7.22386 2.22386 7 2.5 7H16C16.2761 7 16.5 7.22386 16.5 7.5V9.57C17.1635 9.478 17.8365 9.478 18.5 9.57V3C18.5 2.44772 18.0523 2 17.5 2H15C14.8619 2 14.75 1.88807 14.75 1.75V0.75C14.75 0.335786 14.4142 0 14 0C13.5858 0 13.25 0.335786 13.25 0.75V4.5C13.25 4.91421 12.9142 5.25 12.5 5.25C12.0858 5.25 11.75 4.91421 11.75 4.5V2.5C11.75 2.22386 11.5261 2 11.25 2H7C6.86193 2 6.75 1.88807 6.75 1.75V0.75C6.75 0.335786 6.41421 0 6 0C5.58579 0 5.25 0.335786 5.25 0.75V4.5C5.25 4.91421 4.91421 5.25 4.5 5.25C4.08579 5.25 3.75 4.91421 3.75 4.5V2.5C3.75 2.22386 3.52614 2 3.25 2H1C0.447715 2 0 2.44772 0 3V16.5C0 17.6046 0.89543 18.5 2 18.5H9.569C9.477 17.8365 9.477 17.1635 9.569 16.5H2.5Z"
        fill={nativeSystemBlack}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.5C11 13.9101 13.9101 11 17.5 11C21.088 11.0044 23.9956 13.912 24 17.5C24 21.0899 21.0899 24 17.5 24C13.9101 24 11 21.0899 11 17.5ZM13.3425 19.2221C14.0391 20.9036 15.6799 22 17.5 22C19.9841 21.9972 21.9972 19.9841 22 17.5C22 15.6799 20.9036 14.0391 19.2221 13.3425C17.5405 12.646 15.605 13.031 14.318 14.318C13.031 15.605 12.646 17.5405 13.3425 19.2221Z"
        fill={nativeSystemBlack}
      />
      <path
        d="M19.5 16.751H18.25V15C18.25 14.5858 17.9142 14.25 17.5 14.25C17.0858 14.25 16.75 14.5858 16.75 15V17.5C16.75 17.9142 17.0858 18.25 17.5 18.25H19.5C19.9142 18.25 20.25 17.9142 20.25 17.5C20.25 17.0858 19.9142 16.75 19.5 16.75V16.751Z"
        fill={nativeSystemBlack}
      />
    </svg>
  )
}

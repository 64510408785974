import { useCallback } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'
import { useUnit } from 'effector-react'

import { $query } from '../../model/chats'
import { ChatBrief } from '../chatBrief'

import { CHATS_QUERY } from './graphql'

export function useChatsQuery() {
  const search = useUnit($query)

  const {
    data,
    fetchMore: fetch,
    networkStatus: status,
  } = useQuery(CHATS_QUERY, {
    variables: { search },
    notifyOnNetworkStatusChange: true,
  })

  const fetchMore = useCallback(async () => {
    const cursor = data?.chats?.pageInfo.cursor
    if (!cursor || status !== NetworkStatus.ready) return

    await fetch({
      variables: { cursor },
      updateQuery({ chats: prev }, { fetchMoreResult: { chats: next } }) {
        const nodes = [prev?.nodes, next?.nodes]
          .flat()
          .filter((chat): chat is ChatBrief => !!chat)

        // We're sure previous pageInfo is non-null, otherwise we couldn't have fetched more
        // Still, waiting for correct typings
        const pageInfo = next?.pageInfo ?? prev!.pageInfo

        // spread `next` to preserve 'extra' fields, e.g. __typename
        return { chats: { ...next, nodes, pageInfo } }
      },
    })
  }, [data?.chats?.pageInfo.cursor, fetch, status])

  return { chats: data?.chats ?? null, status, fetchMore }
}

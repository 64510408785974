import { TypedDocumentNode, gql } from '@apollo/client'

import { DrugConfigurationResult, DrugConfigurationVariables } from './types'

export const DRUG_CONFIGURATION_QUERY: TypedDocumentNode<
  DrugConfigurationResult,
  DrugConfigurationVariables
> = gql`
  query drugConfiguration($drugName: String!) {
    drugConfiguration: pdsDrug(name: $drugName) {
      drug {
        ...drugInformation
      }
    }
  }

  fragment drugInformation on Drug {
    name
    isAlternativesOnly: alternatives_only
    configurations {
      ...drugConfiguration
    }
  }

  fragment drugConfiguration on Configuration {
    daysSupply: days_supply
    form
    dosages {
      dosage
      packages {
        gpi
        ndc
        package
      }
    }
  }
`

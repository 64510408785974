export interface ResetPasswordGateProps {
  hasToken: boolean
  stepType?: ResetPasswordFlow
}

export enum ResetPasswordStep {
  ForgotPassword = 'ForgotPassword',
  NumericToken = 'NumericToken',
  AccountLocked = 'AccountLocked',
  AccountLockedFinish = 'AccountLockedFinish',
  CreateNewPassword = 'CreateNewPassword',
  Success = 'Success',
  PasswordPrompt = 'PasswordPrompt',
}

export enum ResetPasswordFlow {
  ResetRequired = 'ResetRequired',
  Default = 'Default',
}

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { ButtonNavigateTo16 } from 'src/shared/ui/buttons/ButtonNavigateTo16'
import {
  primaryLightBlue,
  primaryDarkNavy,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { drugSearchMedicationSelected } from '../../model/search'

interface ResultItemProps {
  name: string
}

export function ResultItem({ name }: ResultItemProps) {
  const selectDrug = useUnit(drugSearchMedicationSelected)

  const onClick = () => {
    selectDrug(name)
  }

  return (
    <Root onClick={onClick} data-test="resultItem">
      <Title>{name}</Title>

      <ButtonNavigateTo16 fill={primaryLightBlue} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${convertPxToRem(55)};
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`
const Title = styled.span`
  display: flex;
  flex: 1;
  color: ${primaryDarkNavy};
  ${textLink1_16};
`

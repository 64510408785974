import React from 'react'

import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { ConnectedProps, connect } from 'react-redux'

import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { RootState, withDispatch } from 'src/store'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'

import { remoteErrorClosed } from '../model'

type RemoteErrorUnauthorizedProps = ConnectedProps<typeof connector>

function RemoteErrorUnauthorizedComponent({
  isLoggedIn,
  dispatchOpenThirdSection,
  dispatchStartNewChat,
}: RemoteErrorUnauthorizedProps) {
  const { t } = useTranslation()

  const dismiss = useUnit(remoteErrorClosed)

  const onContact = () => {
    dismiss()
    dispatchStartNewChat()
    dispatchOpenThirdSection()
  }

  const i18n = {
    title: t('translation.shared.errors.unauthorized.title'),
    description: t('translation.shared.errors.unauthorized.description'),

    dismiss: t('translation.shared.buttons.okay'),
    chatForSupport: t(
      'translation.shared.errors.unauthorized.chat_for_support',
    ),
  }

  return (
    <NativeAlert title={i18n.title} description={i18n.description}>
      <NativeAlertAction mode="secondary" onClick={dismiss}>
        {i18n.dismiss}
      </NativeAlertAction>

      {isLoggedIn && (
        <NativeAlertAction mode="secondary" onClick={onContact}>
          {i18n.chatForSupport}
        </NativeAlertAction>
      )}
    </NativeAlert>
  )
}

const mapStateToProps = ({ user }: RootState) => ({
  isLoggedIn: Boolean(user.userId),
})

const mapDispatchToProps = withDispatch({
  openThirdSection: () => setIsShowThirdSectionModal(true),
  startNewChat,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export const RemoteErrorUnauthorized = connector(
  RemoteErrorUnauthorizedComponent,
)

import { memo, useCallback, useState } from 'react'

import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { KebabBoxShadow } from 'src/theme/baseStyles'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { DeactivateModal } from './DeactivateModal'

// TODO: reconsider if we need intermediate context menu
type ContextState = 'closed' | 'context' | 'modal'

function ContextActionsComponent() {
  const t = useScopedTranslation('translation.chat.thread')
  const [state, setState] = useState<ContextState>('closed')

  const close = useCallback(() => setState('closed'), [])

  const toggleContext = () =>
    setState(state === 'context' ? 'closed' : 'context')

  const toggleModal = () => setState(state === 'modal' ? 'closed' : 'modal')

  return (
    <Wrapper>
      <Kebab data-test="chatContextMenu" onClick={toggleContext} />

      {state === 'context' && (
        <Context onClick={toggleModal}>{t('kebab_title')}</Context>
      )}

      {state === 'modal' && <DeactivateModal close={close} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Kebab = styled(Sprite).attrs({ name: 'nav/more' })`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};

  color: ${secondaryGrey};

  cursor: pointer;
`

const Context = styled.button`
  ${textLink2_14};
  ${KebabBoxShadow};

  position: absolute;
  right: ${convertPxToRem(7.5)};
  bottom: ${convertPxToRem(-48)};

  width: max-content;

  color: ${secondaryGrey};
  background-color: ${primaryWhite};

  padding: ${convertPxToRem(10, 16)};
  border-radius: ${convertPxToRem(8)};

  border: none;
  cursor: pointer;
`

export const ContextActions = memo(ContextActionsComponent)

import { attach, createEvent, sample } from 'effector'

import { $$router } from '@shared/lib/router'

import { $$chat } from '@entities/chat'

import { ROUTES } from 'src/constants/routesConstants'
import { store } from 'src/store'
import {
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'

import { deeplink } from '../lib/resources'

export const navigateFx = attach({
  source: { navigate: $$router.$navigate },
  effect: ({ navigate }, path: string) =>
    void navigate(path, { replace: true }),
})

export const openInboxFx = attach({
  source: { navigate: $$router.$navigate },
  effect: ({ navigate }) => {
    void navigate(`/${ROUTES.HOME}`, { replace: true })

    store.dispatch(setIsShowThirdSectionModal(true))
    store.dispatch(setThirdSectionContent(THIRD_SECTION_CONTENT.CHAT))
  },
})

export const chatOpened = createEvent<string>()
export const chatCreated = createEvent<void>()

sample({
  clock: [chatCreated, chatOpened],
  target: openInboxFx,
})

sample({
  clock: chatOpened,
  fn: (path) => {
    const regex = deeplink.virtual['chat']
    return path.match(regex)!.groups!.chatId
  },
  target: $$chat.open,
})

sample({
  clock: chatCreated,
  target: $$chat.create,
})

/* eslint-disable effector/mandatory-scope-binding */
import { ApolloClient, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { signOut } from 'src/apollo/utils/authHelpers'
import { ClientContext } from 'src/apollo/utils/types'
import { GraphQLErrorCode } from 'src/constants/errorCodeConstants'
import { ROUTES } from 'src/constants/routesConstants'
import { loggingLineCreated } from 'src/entities/logging'
import { remoteErrorReceived } from 'src/features/RemoteErrorHandler/model'
import { RemoteError } from 'src/features/RemoteErrorHandler/types'
import persistentStorageInterface from 'src/utils/storage/persistent'
import tokensStorageInterface from 'src/utils/storage/tokens'

type GraphQLErrorHandler = (options: {
  error: Error
  name: string
  client: ApolloClient<unknown>
}) => void

enum AdditionalErrorCodeHandlers {
  Unknown = 'unknown',
  Network = 'network',
}

interface LinkWithClient extends ApolloLink {
  client: ApolloClient<unknown>
}

const CAN_SKIP_ERRORS: GraphQLErrorCode[] = [
  GraphQLErrorCode.UNABLE_REGISTER,
  GraphQLErrorCode.UNAUTHORIZED,
  GraphQLErrorCode.UNAUTHENTICATED,
]

const genericError =
  (text: string): GraphQLErrorHandler =>
  ({ error, name }) => {
    loggingLineCreated({
      line: `[Apollo] @${name} ${text}: ${error.message}`,
      level: 'error',
      meta: { on: 'apollo', operation: name },
    })
  }

const errorHandlers: Record<
  GraphQLErrorCode | AdditionalErrorCodeHandlers,
  GraphQLErrorHandler
> = {
  // Requested resource not found
  NOT_FOUND: genericError('Not Found'),
  // There was an error updating a resource
  NOT_UPDATED: genericError('Not Updated'),
  // Unprocessed and unhandled server error
  SERVER_ERROR: genericError('Server Error'),
  // There was an error during registration
  UNABLE_REGISTER: genericError('Unable to register'),
  // There was an error during registration
  LOCKED: genericError('Locked'),

  VERIFY_CODE_EXPIRED: genericError('Verify Code Expired'),
  VERIFY_CODE_INCORRECT: genericError('Verify Code Incorrect'),

  UNAUTHENTICATED: (handle) => {
    genericError('Unauthenticated')(handle)

    signOut(handle.client, { preventLocationReplace: true })

    const { search } = window.location
    window.location.replace(`/${ROUTES.UNAUTHENTICATED}${search}`)
  },

  EXPIRED_SESSION: (handle) => {
    genericError('Expired Session')(handle)

    const {
      access: getPersistentStorageState,
      modify: { clearRedirect },
    } = persistentStorageInterface

    const {
      modify: { resetTokens },
    } = tokensStorageInterface

    const { redirectUrl } = getPersistentStorageState()

    clearRedirect()
    resetTokens()

    window.location.replace(redirectUrl ?? `/${ROUTES.EXPIRED_SESSION}`)
  },

  UNAUTHORIZED: (handle) => {
    genericError('Unauthorized')(handle)

    remoteErrorReceived(RemoteError.unauthorized)
  },

  UNCONFIRMED: (handle) => {
    genericError('Unconfirmed')(handle)

    remoteErrorReceived(RemoteError.unconfirmed)
  },

  DEACTIVATED: (handle) => {
    genericError('Deactivated')(handle)

    const { access: tokensStorageState } = tokensStorageInterface
    const { access } = tokensStorageState()

    if (access) {
      signOut(handle.client, { preventLocationReplace: true })

      window.location.replace(`/${ROUTES.DEACTIVATED}`)
    }
  },

  // Unknown case
  unknown: genericError('Unknown'),
  network: genericError('Network error'),
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const name = operation.operationName ?? 'UnknownOperation'

    if (name.includes('IgnoreErrors')) {
      if (response) {
        // eslint-disable-next-line no-param-reassign
        response.errors = undefined
      }

      return
    }

    const context = operation.getContext() as ClientContext

    if (networkError)
      errorHandlers.network({
        error: networkError,
        name,
        client: errorLink.client,
      })

    if (!graphQLErrors) return

    graphQLErrors.forEach((error) => {
      const code = error.extensions.code as GraphQLErrorCode

      if (CAN_SKIP_ERRORS.includes(code) && context.isSkipError) return

      if (code in errorHandlers) {
        errorHandlers[code]({ error, name, client: errorLink.client })
        return
      }

      errorHandlers.unknown({ error, name, client: errorLink.client })
    })
  },
) as LinkWithClient

export default errorLink

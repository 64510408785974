import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import CURRENT_USER_CARE_TEAM_QUERY from 'src/apollo/queries/CURRENT_USER_CARE_TEAM_QUERY'
import { checkIsAddedToCareTeam } from 'src/sections/SectionCareTeam/careTeamHelpers'

const useFavouriteCareTeamQuery = (profileId, isFacility) => {
  const { data, loading } = useQuery(CURRENT_USER_CARE_TEAM_QUERY, {
    variables: { id: profileId },
  })

  const isAddedToCareTeam = useMemo(
    () => checkIsAddedToCareTeam(data, profileId, isFacility),
    [data],
  )

  return { isAddedToCareTeam, loading }
}

export default useFavouriteCareTeamQuery

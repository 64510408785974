import React from 'react'

import { list, variant } from '@effector/reflect'
import styled from 'styled-components'

import { $specialtyPharmacies } from 'src/entities/drugDetails'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { SpecialtyPharmaciesCardController } from './SpecialtyPharmaciesCardController'

function SpecialtyPharmaciesListComponent() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  return (
    <Section>
      <Title>{t('specialty_pharmacy')}</Title>
      <PharmaciesList />
    </Section>
  )
}

const Title = styled.span`
  display: flex;

  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  text-transform: uppercase;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${convertPxToRem(8)};
`

const PharmaciesList = list({
  view: SpecialtyPharmaciesCardController,
  source: $specialtyPharmacies,
  mapItem: {
    pharmacyData: (pharmacy) => pharmacy,
  },
  getKey: ({ pharmacy }) => pharmacy.nabp,
})

export const SpecialtyPharmaciesList = variant({
  if: $specialtyPharmacies.map((pharmacies) => pharmacies.length > 0),
  then: SpecialtyPharmaciesListComponent,
})

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import NAVIGATOR_ACTIVITIES_QUERY from 'src/apollo/queries/NAVIGATOR_ACTIVITIES_QUERY'
import CHATS_ACTIVITY_SUBSCRIPTION from 'src/apollo/subscriptions/CHATS_ACTIVITY_SUBSCRIPTION'
import { extractNavigatorActivities } from 'src/sections/SectionNavigator/navigatorHelpers'

const useNavigatorActivitiesQuery = () => {
  const { data, loading, refetch, subscribeToMore } = useQuery(
    NAVIGATOR_ACTIVITIES_QUERY,
  )

  const refetchIfNotLoading = () => {
    if (!loading) {
      refetch()
    }
  }

  const subscribeChatsActivityToEvent = () =>
    subscribeToMore({
      document: CHATS_ACTIVITY_SUBSCRIPTION,
      variables: {
        event_types: ['chat_activity'],
      },
      updateQuery: (cachedData) => {
        refetch()
        return cachedData
      },
    })
  const { newMessagesCount, newMessagesIdsList, isNavigatorOnline } = useMemo(
    () => extractNavigatorActivities(data),
    [data],
  )

  return {
    newMessagesCount,
    newMessagesIdsList,
    isNavigatorOnline,
    subscribeChatsActivityToEvent,
    refetch,
    refetchIfNotLoading,
  }
}

export default useNavigatorActivitiesQuery

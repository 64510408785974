import { lazyWithRetry } from 'src/shared/lib/lazy'

const SignUp = lazyWithRetry(() => import('src/sections/SectionRegistration'))

const GetAssistance = lazyWithRetry(
  () =>
    import(
      'src/sections/SectionRegistration/pages/GetAssistance/GetAssistance'
    ),
)

const GetAssistanceSuccess = lazyWithRetry(
  () =>
    import(
      'src/sections/SectionRegistration/pages/GetAssistance/GetAssistanceSuccess'
    ),
)

export { SignUp, GetAssistance, GetAssistanceSuccess }

import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { AddIcon } from 'src/shared/ui/icons/misc/AddIcon'
import {
  featurePaleBlue,
  primaryDarkNavy,
  secondaryLightGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { heading6_16_Medium, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function AddPharmacyCard() {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const i18n = {
    title: t(
      'translation.medications.drug_search.labels.add_preferred_pharmacy',
    ),
    required: t('translation.shared.errors.required'),
  }

  const handleClick = () => {
    void navigate(
      `/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH_PHARMACY_SEARCH_PATH}`,
    )
  }

  return (
    <Card onClick={handleClick} data-test="addPharmacyCard">
      <IconRoundContainer>
        <AddIcon />
      </IconRoundContainer>

      <Title>{i18n.title}</Title>

      <Required>{i18n.required}</Required>
    </Card>
  )
}

const Card = styled.div`
  display: grid;
  align-items: center;

  gap: ${convertPxToRem(4)};
  grid-template-areas:
    'icon title'
    'icon required';
  grid-template-columns: max-content 1fr;

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};

  padding: ${convertPxToRem(16, 20)};
  width: 100%;

  cursor: pointer;
`

const IconRoundContainer = styled.div`
  background-color: ${featurePaleBlue};
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: icon;

  margin-right: ${convertPxToRem(12)};
`

const Title = styled.span`
  grid-area: title;
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
`

const Required = styled.span`
  grid-area: required;
  ${text2_14};
  color: ${statusErrorRed};
`

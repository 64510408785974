import { useCallback } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { TextInput } from 'src/features/TextInput'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { locationUpdated } from '../../model/edit'

interface AptInputProps {
  text: string
}

export function ApartmentInput({ text }: AptInputProps) {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  const editLocation = useUnit(locationUpdated)

  const handleChange = useCallback(
    (value: string) => {
      editLocation({ address_line_2: value })
    },
    [editLocation],
  )

  const handleClearName = useCallback(() => {
    editLocation({ address_line_2: '' })
  }, [editLocation])

  return (
    <Root>
      <FieldLabel>{t('apt_number')}</FieldLabel>

      <ApartmentField
        value={text}
        onChange={handleChange}
        onClear={handleClearName}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: ${convertPxToRem(4)};
`

const FieldLabel = styled.span`
  ${text3_12};
  color: ${primaryDarkNavy};
`

const ApartmentField = styled(TextInput)`
  & > input {
    height: ${convertPxToRem(50)};
  }
`

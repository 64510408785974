import { gql } from '@apollo/client'

const NAVIGATOR_FRAGMENT = gql`
  fragment navigatorFragment on ChatNavigator {
    id
    first_name
    middle_name
    last_name
    profile_image_url(version: small)
  }
`

export default NAVIGATOR_FRAGMENT

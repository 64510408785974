import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

import { drugDetailsRequested } from 'src/entities/drugDetails'

interface DrugSearchDetailsMapGateProps {
  drugName: string
}

const DrugSearchDetailsMap = createDomain('DrugSearchDetailsMap')

export const DrugSearchDetailsMapGate =
  createGate<DrugSearchDetailsMapGateProps>({
    name: 'DrugSearchDetailsMapGate',
    domain: DrugSearchDetailsMap,
  })

sample({
  clock: DrugSearchDetailsMapGate.open,
  filter: ({ drugName }) => Boolean(drugName),
  fn: ({ drugName }) => drugName,
  target: drugDetailsRequested,
})

import { ROUTES } from 'src/constants/routesConstants'
import {
  featurePaleBlue,
  featurePaleGreen,
  featurePaleYellow,
} from 'src/theme/colors'
import { SCREEN_SIZES } from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function getHelpButtonPosition({
  isSequoiaUser,
  hasMobileToolbar,
  isShowGetHelpModal,
}) {
  if (isSequoiaUser) {
    return convertPxToRem(isShowGetHelpModal ? 75 : 138)
  }

  if (hasMobileToolbar) return convertPxToRem(75)

  return convertPxToRem(15)
}

export const quickLinks = {
  carePlans: {
    to: ROUTES.CARE_PLANS_NEW_PATH,
    icon: 'services/options_for_care',
    title: 'care',
    bgColor: featurePaleBlue,
  },
  telemed: {
    to: ROUTES.TALK_WITH_DOCTOR,
    icon: 'services/telemedicine',
    title: 'talk_doctor',
    bgColor: featurePaleBlue,
  },
  billClaims: {
    to: ROUTES.BILL_CLAIMS_NEW,
    icon: 'services/billing_advocacy',
    title: 'bill',
    bgColor: featurePaleGreen,
  },
  pharmacy: {
    to: `${ROUTES.HOME_COVERAGE_PATH}/${ROUTES.PHARMACY_BENEFIT_DETAILS}`,
    icon: 'pbm/pills',
    title: 'view_pharmacy',
    bgColor: featurePaleYellow,
  },
  mailOrder: {
    to: `${ROUTES.HOME_COVERAGE_PATH}/${ROUTES.PHARMACY_BENEFIT_DETAILS}/${ROUTES.PHARMACY_MAIL_ORDER_INFO}`,
    icon: 'services/mail',
    title: 'rx_mail_order',
    bgColor: featurePaleYellow,
  },
}

const isMobileAgent = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

/** @deprecated Use `shared/lib/device` instead */
export const isMobileDevice =
  isMobileAgent && window.innerWidth < SCREEN_SIZES.MOBILE

export const CHAT_BOX_MIN_HEIGHT = 100

export const TUTORIAL_FIRST_STEP = 0
export const TUTORIAL_SECOND_STEP = 1
export const TUTORIAL_THIRD_STEP = 2

export const MOBILE_TOOLBAR_HIDDEN_FLAG = 'hidden'

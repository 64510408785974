import styled from 'styled-components'

import { secondaryGrey, primaryDarkNavy } from 'src/theme/colors'
import { text1_16, text2_14, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RxCardFrontContentProps } from '../../types'

export function RxCardFrontContent({
  cardId,
  firstName,
  lastName,
  rxIin,
  rxGrp,
  rxPcn,
  issuer,
}: RxCardFrontContentProps) {
  return (
    <>
      <InfoColumn>
        <MainInfo>{`${firstName} ${lastName}`}</MainInfo>
        <MainInfoID>{`ID: ${cardId}`}</MainInfoID>
      </InfoColumn>
      <InfoRow>
        <InfoColumn>
          <RxInfo>{`RxBIN: ${rxIin}`}</RxInfo>
          <RxInfo>{`RxPCN: ${rxPcn}`}</RxInfo>
        </InfoColumn>
        <InfoColumnWithMargin>
          <RxInfo>{`RxGRP: ${rxGrp}`}</RxInfo>
          <RxInfo>{`Issuer ${issuer}`}</RxInfo>
        </InfoColumnWithMargin>
      </InfoRow>
    </>
  )
}

const MainInfo = styled.span`
  ${text1_16};
  color: ${primaryDarkNavy};
`
const MainInfoID = styled.span`
  ${text2_14};
  color: ${primaryDarkNavy};
`
const RxInfo = styled.span`
  text-align: left;
  ${text3_12};
  color: ${secondaryGrey};
`
const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${convertPxToRem(10, 0)};
  user-select: none;
`
const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
`
const InfoColumnWithMargin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${convertPxToRem(50)};
`

import NavigateToIcon from 'src/components/SvgIcons/NavigateToIcon'
import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonNavigateTo16Props {
  fill?: string
  width?: string
  height?: string
}

export function ButtonNavigateTo16({
  fill = secondaryGrey,
  width = convertPxToRem(16),
  height = convertPxToRem(16),
}: ButtonNavigateTo16Props) {
  return <NavigateToIcon fill={fill} width={width} height={height} />
}

import { useMemo } from 'react'

import { reflect, variant } from '@effector/reflect'
import { equals, not } from 'patronum'
import styled from 'styled-components'

import { $$geolocation } from 'src/entities/geolocation'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { LocationCrossIcon } from 'src/shared/ui/icons/misc/LocationCrossIcon'
import { secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'

import {
  $currentGeolocation,
  $currentGeolocationState,
  CURRENT_LOCATION_ID,
} from '../../model/current'
import {
  $preferredLocationId,
  deviceLocationSelected,
} from '../../model/locations'
import { DeviceLocationState } from '../../types'

import { LocationCard } from './LocationCard'

interface DeviceLocationSectionViewProps {
  state: DeviceLocationState
  zip: string
}

function DeviceLocationSectionView({
  state,
  zip,
}: DeviceLocationSectionViewProps) {
  const t = useScopedTranslation('translation.profile.preferred_locations')
  const sharedT = useScopedTranslation('translation.shared.others')

  const title = useMemo(() => {
    switch (state) {
      case DeviceLocationState.Aquired:
        return `${t('current_location')}: ${zip}`

      case DeviceLocationState.Loading:
        return `${t('current_location')}: ${sharedT('loading')}`

      default:
        return t('current_location')
    }
  }, [state, t, zip, sharedT])

  const isLocationAccessible = state === DeviceLocationState.Accessible

  return (
    <Root>
      <Title>{t('device_location')}</Title>

      {isLocationAccessible && (
        <Card
          title={title}
          subtitle={t('location_access_is_off')}
          label={t('turn_on')}
        />
      )}

      {!isLocationAccessible && <SelectedCard title={title} />}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

const Title = styled.span`
  ${capsHeading1_12};
  text-transform: uppercase;
  color: ${secondaryGrey};
`

const Card = reflect({
  view: LocationCard,
  bind: {
    onClick: deviceLocationSelected,
    onLabelClick: deviceLocationSelected,
    icon: <LocationCrossIcon />,
  },
})

const SelectedCard = reflect({
  view: LocationCard,
  bind: {
    onClick: deviceLocationSelected,
    icon: <LocationCrossIcon />,
    isSelected: equals($preferredLocationId, CURRENT_LOCATION_ID),
  },
})

export const DeviceLocationSection = variant({
  if: not($$geolocation.permission.$denied),
  then: DeviceLocationSectionView,
  bind: {
    state: $currentGeolocationState,
    zip: $currentGeolocation.map((location) => location?.zip ?? ''),
  },
})

import { primaryDarkNavy } from 'src/theme/colors'

export function AddIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.541 10c0 .52-.418.938-.938.938h-6.25v6.25a.938.938 0 0 1-1.874 0v-6.25h-6.25a.937.937 0 1 1 0-1.875h6.25v-6.25a.937.937 0 0 1 1.875 0v6.25h6.25c.519 0 .937.421.937.937z"
        fill={primaryDarkNavy}
      />
    </svg>
  )
}

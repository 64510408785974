import { useMemo } from 'react'

import { useNavigate } from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'

import useDismissFeedItemMutation from 'src/apollo/hooks/useDismissFeedItemMutation'
import CardCategory from 'src/components/Cards/CardCategory'
import {
  BG_COLORS,
  getLabelColors,
  openActivityCard,
} from 'src/sections/SectionHome/Activity/activityFeedHelpers'
import { ActivityCardItem } from 'src/sections/SectionHome/Activity/types'
import { FeedItemCardType } from 'src/shared/api/types'
import { withDispatch } from 'src/store'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'

interface CurrentCardProps extends ConnectedProps<typeof connector> {
  item: ActivityCardItem
  setDismissItemId: (id: string) => void
  setErrorMessage: (id: string) => void
}

function CurrentCard({
  item,
  dispatchOpenThirdSectionModal,
  dispatchStartNewChat,
  setDismissItemId,
  setErrorMessage,
}: CurrentCardProps) {
  const { category, resourceStatus, id, isResourceArchived, url, cardType } =
    item
  const navigate = useNavigate()

  const { dismissFeedItem } = useDismissFeedItemMutation()

  const openDismissAlert = () => {
    setDismissItemId(id)
  }

  const redirectToChat = () => {
    dispatchOpenThirdSectionModal()
    dispatchStartNewChat()
  }

  const openCard = () => {
    switch (cardType) {
      case FeedItemCardType.AppointmentRejected:
        redirectToChat()
        break
      case FeedItemCardType.BillingResolvedSuccessfully:
      case FeedItemCardType.BillingResolvedWithError:
        openActivityCard({ url, setErrorMessage, navigate })()
        dismissFeedItem({ id })
        break
      default:
        openActivityCard({ url, setErrorMessage, navigate })()
    }
  }

  const labelColor = useMemo(
    () =>
      getLabelColors({
        category,
        resourceStatus,
        isResourceArchived,
      }),
    [category, resourceStatus, isResourceArchived],
  )

  return (
    <CardCategory
      key={id}
      item={item}
      bgColor={BG_COLORS[category]}
      labelBg={labelColor}
      dataTestProp={`${category}-card`}
      onClick={openCard}
      dismissable
      openDismissAlert={openDismissAlert}
    />
  )
}

const mapDispatchToProps = withDispatch({
  openThirdSectionModal: () => setIsShowThirdSectionModal(true),
  startNewChat,
})

const connector = connect(null, mapDispatchToProps)

export default connector(CurrentCard)

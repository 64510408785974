import styled, { css } from 'styled-components'

import { nativeSystemBlue, nativeSystemRed } from 'src/theme/colors'
import { heading2_17, heading2_17_Medium } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type AlertActionMode = 'primary' | 'secondary' | 'danger'

interface NativeAlertActionProps {
  mode: AlertActionMode
  children: React.ReactNode
}

const ModePrimary = css`
  ${heading2_17_Medium};
  color: ${nativeSystemBlue};
`

const ModeSecondary = css`
  color: ${nativeSystemBlue};
`

const ModeDanger = css`
  color: ${nativeSystemRed};
`

const ActionModeMap = {
  primary: ModePrimary,
  secondary: ModeSecondary,
  danger: ModeDanger,
} satisfies Record<AlertActionMode, unknown>

export const NativeAlertAction = styled.button<NativeAlertActionProps>`
  ${heading2_17};

  padding: ${convertPxToRem(11)};

  border: none;
  background-color: transparent;

  ${({ mode }) => ActionModeMap[mode]};
`

import styled from 'styled-components'

import { DrugPrice } from 'src/entities/drugDetails'
import { PriceLabel } from 'src/features/MedicationCostWithTooltip'
import { Schedule } from 'src/features/Schedule'
import { getAddressField } from 'src/shared/lib/getAddressField'
import {
  boxShadowScreenLight,
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
  primaryWhite,
} from 'src/theme/colors'
import { heading5_19, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface RetailPharmacyCardProps {
  pharmacyInfo: DrugPrice | null
  onClick?: () => void
  dataTestProp?: string
}

export function RetailPharmacyCard({
  pharmacyInfo,
  onClick = undefined,
  dataTestProp = '',
}: RetailPharmacyCardProps) {
  if (!pharmacyInfo) return null

  const {
    patientPay = '0',
    insurancePay,
    pharmacy,
    isBestPrice,
    isRejected,
  } = pharmacyInfo
  const { name, workSchedule } = pharmacy ?? {}

  return (
    <Root data-test={dataTestProp} onClick={onClick}>
      <Content>
        <NameWrapper>
          <Name>{name}</Name>
        </NameWrapper>
        <Address data-test="drugDetailsPharmacyCard.location">
          {getAddressField(pharmacy)}
        </Address>
      </Content>

      <PriceLabel
        patientPay={patientPay}
        isBestPrice={isBestPrice}
        isRejected={isRejected}
        insurancePay={insurancePay}
      />

      <Schedule workSchedule={workSchedule} />
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-areas:
    'content label'
    'schedule schedule';
  grid-template-columns: 3fr 1fr;

  gap: ${convertPxToRem(8)};
  padding: ${convertPxToRem(16, 20)};
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  box-shadow: ${convertPxToRem(0, 4, 26)} ${boxShadowScreenLight};
  background-color: ${primaryWhite};

  cursor: pointer;
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Name = styled.span`
  color: ${primaryDarkNavy};
  ${heading5_19};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(8)};
`

const Address = styled.span`
  color: ${secondaryGrey};
  ${text2_14};
`

import styled from 'styled-components'

import emptyFeedImage from 'src/assets/images/empty_activity_feed.png'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { text1_16, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ActivityBlankState() {
  const t = useScopedTranslation('translation.home.activity.updates')

  return (
    <Root data-test="activity.blank.state">
      <BlankState>
        <Image src={emptyFeedImage} alt="" />

        <BlankStateTitle>{t('title')}</BlankStateTitle>
        <BlankStateSubtitle>{t('sub_title')}</BlankStateSubtitle>
      </BlankState>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${convertPxToRem(80, 24)};
`

const BlankState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${convertPxToRem(50)};
`

const Image = styled.img`
  width: ${convertPxToRem(70)};
  height: ${convertPxToRem(75)};

  margin-bottom: ${convertPxToRem(20)};
`

const BlankStateTitle = styled.div`
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(8)};
  ${text1_16};
`

const BlankStateSubtitle = styled.div`
  color: ${secondaryGrey};
  ${text3_12};
  text-align: center;
`

export default ActivityBlankState

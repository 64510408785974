import { ApolloMock, WithTypename } from 'src/shared/api/mocks'
import {
  ContentStepTypeEnum,
  PharmacyDayOfWeekType,
} from 'src/shared/api/types'

import { PREFERRED_PHARMACY_QUERY } from './document'
import {
  PreferredPharmacy,
  PreferredPharmacyQueryResult,
  PreferredPharmacyQueryVariables,
} from './types'

export const MockPreferredPharmacy: WithTypename<PreferredPharmacy> = {
  __typename: 'Pharmacies',
  name: 'CVS Pharmacy',
  addressLine1: '851 John Street',
  latitude: 1.0,
  longitude: 1.0,
  city: 'New York',
  state: 'NY',
  zip: '10029',
  nabp: 'preferredPharmacyNabp',
  phone: '2342342323',
  deliveryTime: 'deliveryTime',
  workSchedule: [
    {
      dayOfWeek: PharmacyDayOfWeekType.Monday,
      openHour: '09:00',
      closeHour: '11:00',
    },
  ],
  pharmacySteps: [
    {
      title: 'pharmacyStep',
      content: [
        {
          content: 'content',
          type: ContentStepTypeEnum.Text,
          url: '',
        },
      ],
    },
  ],
}

const withPreferredPharmacy: ApolloMock<
  PreferredPharmacyQueryResult,
  PreferredPharmacyQueryVariables
> = {
  request: {
    query: PREFERRED_PHARMACY_QUERY,
  },
  result: {
    data: {
      preferredPharmacy: MockPreferredPharmacy,
    },
  },
}

export const MockPreferredPharmacyQuery = { withPreferredPharmacy }

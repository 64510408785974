import React from 'react'

import styled from 'styled-components'

import { ServiceAgreement } from 'src/entities/termsOfServices'
import { ContentContainer } from 'src/sections/SectionRegistration/styled'
import { secondaryGrey } from 'src/theme/colors'
import { heading6_16, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface TermsExtraInfoProps {
  agreement: ServiceAgreement
}

export function TermsExtraInfo({ agreement }: TermsExtraInfoProps) {
  const { displayName, text } = agreement

  return (
    <>
      <Title>{displayName}</Title>
      <Description>
        <ContentContainer dangerouslySetInnerHTML={{ __html: text }} />
      </Description>
    </>
  )
}

const Title = styled.h1`
  color: ${secondaryGrey};
  ${heading6_16};
`
const Description = styled.div`
  padding: ${convertPxToRem(20, 0, 20)};
  color: ${secondaryGrey};
  ${text2_14};
`

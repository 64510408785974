import { attach, sample } from 'effector'

import { $isInEarlyAccess } from 'src/entities/earlyAccess/model'
import { createModal } from 'src/entities/modalsQueue'
import { $$sessionStorage } from 'src/shared/storage/session'
import { store } from 'src/store'

import { EarlyAccessModal } from '../ui/EarlyAccessModal'

import { modalClosed } from './core'

export const checkShouldOpenFx = attach({
  source: {
    isInEarlyAccess: $isInEarlyAccess,
    sessionStorage: $$sessionStorage.$state,
  },
  effect({ isInEarlyAccess, sessionStorage }) {
    const isTutorialCompleted = store.getState().tutorial.isCompleted

    return (
      isInEarlyAccess &&
      sessionStorage.hasEarlyAccessModal &&
      isTutorialCompleted
    )
  },
})

export const earlyAccessModal = createModal({
  view: EarlyAccessModal,
  beforeOpen: checkShouldOpenFx,
  openOn: checkShouldOpenFx.doneData,
})

sample({
  clock: modalClosed,
  target: earlyAccessModal.next,
})

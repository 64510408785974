import React from 'react'

import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { quickLinks } from 'src/sections/SectionDashboard/dashboardHelpers'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { SCREEN_SIZES } from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function GetHelpTabs({
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  hasTelemedicineAccess = false,
  hasNavFeaturesAccess = false,
  hasPBMFeaturesAccess = false,
  hasBillAdvocacyAccess = false,
  hasCarePlansAccess = false,
}) {
  const { t } = useTranslation()

  const handleClick = () => {
    showThirdSectionModal()
    openNewChat()
  }

  const hasNavAndPbmAccess = hasPBMFeaturesAccess && hasNavFeaturesAccess

  const getGetHelpOptions = () => {
    const getHelpOptions = []

    if (hasNavAndPbmAccess) {
      if (hasCarePlansAccess) {
        getHelpOptions.push(quickLinks.carePlans)
      }

      getHelpOptions.push(
        quickLinks.telemed,
        quickLinks.pharmacy,
        quickLinks.chat,
      )

      if (hasBillAdvocacyAccess) {
        getHelpOptions.splice(2, 0, quickLinks.billClaims)
      }
    } else if (hasNavFeaturesAccess) {
      if (hasCarePlansAccess) {
        getHelpOptions.push(quickLinks.carePlans)
      }
      getHelpOptions.push(quickLinks.telemed, quickLinks.chat)

      if (hasBillAdvocacyAccess) {
        getHelpOptions.splice(2, 0, quickLinks.billClaims)
      }
    } else if (hasPBMFeaturesAccess) {
      getHelpOptions.push(
        quickLinks.pharmacy,
        quickLinks.mailOrder,
        quickLinks.chatPharmacy,
      )
    }
    return getHelpOptions
  }

  return (
    <>
      {getGetHelpOptions().map((item) => {
        const isNeedHideTelemedicineRoute =
          item.to === ROUTES.TALK_WITH_DOCTOR && !hasTelemedicineAccess
        const isChatLink = item.to === ROUTES.HOME_ACTIVITY_PATH
        const isDesktop = window.innerWidth > SCREEN_SIZES.TABLET_MAX
        if (isNeedHideTelemedicineRoute) return null
        if (isChatLink && isDesktop) return null
        return (
          <QuickLink
            key={item.title}
            to={item.to}
            onClick={isChatLink ? () => handleClick() : () => {}}
          >
            <IconWrapper>
              <IconSvg40
                fill={primaryDarkNavy}
                IconSvg={item.icon}
                bgColor={item.bgColor}
                border="none"
              />
            </IconWrapper>
            <IconTitle>{t(item.title)}</IconTitle>
          </QuickLink>
        )
      })}
    </>
  )
}

const QuickLink = styled(Link)`
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${primaryWhite};
  margin-bottom: ${convertPxToRem(12)};
  width: 100%;
  height: ${convertPxToRem(48)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(38)};
  :active,
  :hover,
  :focus {
    outline: none;
  }
  :last-child {
    margin-bottom: 0;
  }
`
const IconTitle = styled.span`
  ${textLink2_14};
  color: ${secondaryGrey};
  width: 100%;
  padding: ${convertPxToRem(0, 8, 0, 53)};
`
const IconWrapper = styled.div`
  position: absolute;
  left: ${convertPxToRem(4)};
`

GetHelpTabs.propTypes = {
  showThirdSectionModal: PropTypes.func,
  openNewChat: PropTypes.func,
  hasTelemedicineAccess: PropTypes.bool,
  hasNavFeaturesAccess: PropTypes.bool,
  hasPBMFeaturesAccess: PropTypes.bool,
  hasBillAdvocacyAccess: PropTypes.bool,
  hasCarePlansAccess: PropTypes.bool,
}

const mapStateToProps = ({ features }) => ({
  hasTelemedicineAccess: features.featureFlags[FeatureFlag.TELEMEDICINE],
  hasNavFeaturesAccess: features.featureFlags[FeatureFlag.CORE_NAV],
  hasPBMFeaturesAccess: features.featureFlags[FeatureFlag.PBM],
  hasBillAdvocacyAccess: features.featureFlags[FeatureFlag.BILL_ADVOCACY],
  hasCarePlansAccess: features.featureFlags[FeatureFlag.CARE_PLANS],
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GetHelpTabs)

import { createDomain } from 'effector'

import { RemoteError } from './types'

const RemoteErrors = createDomain('RemoteErrors')

export const remoteErrorReceived = RemoteErrors.event<RemoteError>()
export const remoteErrorClosed = RemoteErrors.event<void>()

export const $remoteError = RemoteErrors.store<RemoteError | null>(null)
  .on(remoteErrorReceived, (_, error) => error)
  .reset(remoteErrorClosed)

export enum FeatureFlag {
  CoreNavigation = 'core',

  // Coverage
  Coverage = 'shared_insurance_benefits',
  MedicalCoverage = 'core_insurance_card',
  VisionCoverage = 'shared_vision_coverage',
  DentalCoverage = 'shared_dental_coverage',
  PharmacyCoverage = 'shared_pharmacy_coverage',

  // Care Search
  CareLocator = 'find_care',
  ProviderNetworkFilter = 'care_locator_net_filter',
  Ratings = 'ratings',

  Appointments = 'appointments',
  CareTeam = 'my_care_team',
  CarePlans = 'care_plans',

  BillAdvocacy = 'bill_advocacy',

  // Pharmacy Benefits
  PharmacyBenefits = 'pbm',
  MedicineCabinet = 'medicine_cabinet',
  DrugSearch = 'pbm_drug_search',
  PharmacyAccumulator = 'pbm_accumulator',

  // Profile & Settings
  Profile = 'shared_profile',
  Settings = 'shared_settings',
  HealthProfile = 'shared_health_profile',
  Wellness = 'wellness',

  ActivityFeed = 'shared_activity_feed',
  SessionTimeout = 'shared_session_timeout',

  EarlyAccess = 'early_access',
  ClearCost = 'clear_cost',

  Telemedicine = 'telemedicine',
}

export type FeatureFlagMap = { [flag in FeatureFlag]?: boolean }

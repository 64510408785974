import { useQuery } from '@apollo/client'

import MEDICATION_PRICE_BREAKDOWN_QUERY from 'src/apollo/queries/MEDICATION_PRICE_BREAKDOWN_QUERY'
import { extractMedicationPriceBreakdownData } from 'src/sections/SectionMedications/medicationHelpers'

const useMedicationPriceBreakdownQuery = (priceId: string) => {
  const { data, loading: isLoading } = useQuery(
    MEDICATION_PRICE_BREAKDOWN_QUERY,
    {
      variables: {
        price_id: priceId,
      },
    },
  )

  const price = extractMedicationPriceBreakdownData(data)

  return {
    price,
    isLoading,
  }
}

export default useMedicationPriceBreakdownQuery

import { lazy } from 'react'

const sleep = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms)
  })

// TODO: consider location.reload() once when chunks fail, to force purge `index.html`
export const lazyWithRetry: typeof lazy = (factory) =>
  lazy(() => factory().catch(() => sleep(1000).then(factory)))

import { useCallback } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'

import FEED_ITEMS_EVENTS_SUBSCRIPTION from 'src/apollo/subscriptions/FEED_ITEMS_EVENTS_SUBSCRIPTION'
import { FeedItemCategory } from 'src/shared/api/types'

import { FEED_ITEMS_QUERY } from './document'

const ACTIVITY_FEED_FILTER = {
  only_to_do: true,
  categories: Object.values(FeedItemCategory),
}

export const useFeedItemsQuery = () => {
  const {
    data,
    loading: isLoading,
    subscribeToMore,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery(FEED_ITEMS_QUERY, {
    variables: {
      filters: ACTIVITY_FEED_FILTER,
      first: 10,
      shouldSkipCustomItems: false,
    },
    notifyOnNetworkStatusChange: true,
  })

  const fetchMoreFeedItems = useCallback(async () => {
    await fetchMore({
      variables: {
        filters: ACTIVITY_FEED_FILTER,
        cursor: data?.feedItems?.pageInfo?.endCursor,
        shouldSkipCustomItems: true,
      },
    })
  }, [data, fetchMore])

  const subscribeToFeedItems = () =>
    subscribeToMore({
      document: FEED_ITEMS_EVENTS_SUBSCRIPTION,
      variables: {
        categories: Object.values(FeedItemCategory),
      },
      updateQuery: (currentCache) => {
        void refetch()
        return currentCache
      },
    })

  return {
    feedItems: data?.feedItems?.nodes ?? [],
    hasNextPage: data?.feedItems?.pageInfo?.hasNextPage ?? false,
    customFeedItems: data?.customFeedItems ?? [],
    totalCount: data?.feedItems?.pageInfo?.totalCount ?? '',
    isLoading,
    fetchMoreFeedItems,
    isFetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
    subscribeToFeedItems,
  }
}

export const checkIsEndScrollReached = (
  event,
  SCROLL_GAP,
  isReversePagination,
) => {
  const {
    target: { scrollHeight, clientHeight, scrollTop },
  } = event
  const maxScrolling = scrollHeight - clientHeight

  const remainedScrollHeight = isReversePagination
    ? maxScrolling + scrollTop
    : maxScrolling - scrollTop

  return remainedScrollHeight <= SCROLL_GAP
}

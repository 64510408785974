import { useCallback, useContext } from 'react'

import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import { TUTORIAL_THIRD_STEP } from 'src/sections/SectionDashboard/dashboardHelpers'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import { Sprite } from 'src/shared/ui/Sprite'
import { RootState } from 'src/store'
import type { IMobileTabLink } from 'src/store/slices/common'
import {
  primaryWhite,
  primaryBlue,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text4_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type HomeTabBarMobileProps = ConnectedProps<typeof connector>

function HomeTabBarMobile({
  isThirdTutorialStep = false,
  homeTabsDataMobile,
  activeSection,
  hasMobileToolbar,
}: HomeTabBarMobileProps) {
  const { setScrollTop } = useContext(HeaderAnimationContext)

  const tabWidth = `${(1 / homeTabsDataMobile.length) * 100}%`
  const scrollToTop = useCallback(() => setScrollTop(0), [setScrollTop])

  if (!hasMobileToolbar) {
    return null
  }

  return (
    <Root>
      {homeTabsDataMobile.map((tab) => (
        <MobileTab
          {...tab}
          key={tab.tabTitle}
          isActive={activeSection === tab.section}
          scrollToTop={scrollToTop}
          tabWidth={tabWidth}
          hasTutorialHighlight={
            isThirdTutorialStep &&
            tab.tabTitle === 'translation.home.tabs.activity'
          }
        />
      ))}
    </Root>
  )
}

interface MobileTabProps extends IMobileTabLink {
  tabWidth: string
  scrollToTop: () => void
  hasTutorialHighlight: boolean
  isActive: boolean
}

function MobileTab({
  tabTitle,
  to,
  icon,
  isActive,
  hasTutorialHighlight,
  tabWidth,
  scrollToTop,
}: MobileTabProps) {
  const { t } = useTranslation()

  const currentColor = isActive ? primaryBlue : secondaryGrey

  return (
    <HomeTab
      data-is-highlighted={hasTutorialHighlight}
      $width={tabWidth}
      to={to}
      onClick={scrollToTop}
    >
      <Icon name={icon} data-is-active={isActive} />
      <TabTitle color={currentColor}>{t(tabTitle)}</TabTitle>
    </HomeTab>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  background-color: ${primaryWhite};
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`

const HomeTabTutorialHighlight = css`
  margin: ${convertPxToRem(0, 0, 20, 12)};
  z-index: 2;
  cursor: default;

  width: ${convertPxToRem(60)};
  background-color: white;
  border-radius: ${convertPxToRem(50)};
`

const HomeTab = styled(Link)<{ $width: string }>`
  ${text4_10};

  display: flex;
  flex-direction: column;
  align-items: center;

  width: ${({ $width }) => $width};

  color: ${secondaryGrey};
  text-align: center;

  padding: ${convertPxToRem(10, 0)};
  cursor: 'pointer';

  :active,
  :hover,
  :focus {
    outline: none;
    color: ${primaryBlue};
  }

  &[data-is-highlighted='true'] {
    ${HomeTabTutorialHighlight}
  }
`

const Icon = styled(Sprite)`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};

  color: ${secondaryGrey};

  &[data-is-active='true'] {
    color: ${primaryBlue};
  }
`

const TabTitle = styled.span`
  color: ${({ color }) => color && color};
  margin-top: ${convertPxToRem(6)};
  padding: ${convertPxToRem(0, 2)};
`

const mapStateToProps = ({
  tutorial: { tutorialStep },
  common: { availableRoutes, activeSection, hasMobileToolbar },
}: RootState) => ({
  isThirdTutorialStep: tutorialStep === TUTORIAL_THIRD_STEP,
  homeTabsDataMobile: availableRoutes.homeTabsDataMobile,
  activeSection,
  hasMobileToolbar,
})

const connector = connect(mapStateToProps)
export default connector(HomeTabBarMobile)

import { attach, createDomain, sample } from 'effector'
import { not } from 'patronum'

import client from 'src/apollo/client'
import { $currentDrugConfig } from 'src/entities/drugDetails'

import { DRUG_SEARCH_CREATE_CHAT_MUTATION } from '../api/drugSearchCreateChatMutation/document'
import { DrugSearchCreateChatVariables } from '../api/drugSearchCreateChatMutation/types'

export type ChatCreatedArguments = {
  price?: string | null
  pharmacyName?: string | null
}

const DrugSearchTicketCreation = createDomain('DrugSearchTicketCreation')

export const chatCreated =
  DrugSearchTicketCreation.event<ChatCreatedArguments>()

export const createChatFx = DrugSearchTicketCreation.effect<
  DrugSearchCreateChatVariables,
  string
>(async (variables) => {
  const { data } = await client.mutate({
    mutation: DRUG_SEARCH_CREATE_CHAT_MUTATION,
    variables,
  })

  if (!data) throw new Error('Creating a drug search chat failed')

  return data.chat.id
})

const createDrugSearchChatFx = attach({
  source: $currentDrugConfig,
  effect: createChatFx,
  mapParams: (
    { price, pharmacyName }: ChatCreatedArguments,
    { drugName, config },
  ) => ({
    drugInformation: {
      name: drugName,
      dosage: config.dosage,
      price: price ?? '',
      quantity: Number(config.quantity),
      supply: Number(config.daysSupplyCustom),
      package: config.typeOfQuantity,
      pharmacy_name: pharmacyName ?? '',
    },
  }),
})

sample({
  clock: chatCreated,
  filter: not(createChatFx.pending),
  target: createDrugSearchChatFx,
})

import { useMemo } from 'react'

import type { LDContext } from 'launchdarkly-js-client-sdk'

import { useFeatureFlagIdentityQuery } from '../api/featureFlagIdentityQuery'
import { LaunchDarklyContextGate } from '../model'

export function LaunchDarklyController() {
  const { user, isLoading } = useFeatureFlagIdentityQuery()

  const context: LDContext | null = useMemo(() => {
    if (isLoading || !user) return null

    return {
      kind: 'multi',

      user: { key: user.employeeId },

      organization: {
        key: user.organization.id,
        name: user.organization.name,
      },
    }
  }, [isLoading, user])

  return <LaunchDarklyContextGate context={context} />
}

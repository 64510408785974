import { TypedDocumentNode, gql } from '@apollo/client'

import { APPOINTMENT_TIME_SLOT_FRAGMENTS } from '../timeSlots/document'

import {
  CancelAppointmentTimeSlotResult,
  CancelAppointmentTimeSlotVariables,
} from './types'

export const CANCEL_APPOINTMENT_TIME_SLOT_MUTATION: TypedDocumentNode<
  CancelAppointmentTimeSlotResult,
  CancelAppointmentTimeSlotVariables
> = gql`
  mutation cancelAppointmentTimeSlot($timeSlot: ID) {
    cancelAppointment(time_slot_id: $timeSlot) {
      id
      displayStatus: display_status
      status

      timeSlots: time_slots {
        ...asapTime
        ...specificTime
        ...timeBlock

        ...suggestedTime
        ...pendingTime
        ...confirmedTime
        ...cancelledTime
      }
    }
  }

  ${APPOINTMENT_TIME_SLOT_FRAGMENTS}
`

import React from 'react'

import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LocationIcon from 'src/components/SvgIcons/LocationIcon'
import { $$geolocation } from 'src/entities/geolocation'
import { formatDistance } from 'src/sections/SectionCareLocator/careLocatorHelpers'
import { secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface AppointmentProfileAddressProps {
  address: string
  distance: number
}

export function AppointmentProfileAddress({
  address,
  distance,
}: AppointmentProfileAddressProps) {
  const { t } = useTranslation()

  const hasGeolocation = useUnit($$geolocation.$isKnown)

  const i18n = {
    distance: t(
      'translation.home.care_tools.care_finder.doctor_details.other_locations.distance1_title',
      { insert: formatDistance(distance) },
    ),
  }

  return (
    <Wrapper>
      <LocationIcon fill={secondaryGrey} />

      <Address>{address}</Address>
      {hasGeolocation && <Distance>{i18n.distance}</Distance>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;

  grid-auto-flow: row;
  column-gap: ${convertPxToRem(13)};

  ${text2_14};
  color: ${secondaryGrey};

  > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};

    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
`

const Address = styled.span``
const Distance = styled.span``

import { Store } from 'effector'

import { FeatureFlag } from '../types'

import { $features } from './store'

type FlagStore = Record<FeatureFlag, Store<boolean>>

export const flag = Object.fromEntries(
  Object.values(FeatureFlag).map((name) => {
    const $value = $features.map((map) => map[name] ?? false)
    return [name, $value]
  }),
) as FlagStore

import styled from 'styled-components'

import { statusErrorRed } from 'src/theme/colors'
import { text4_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { Sprite } from './Sprite'

interface InlineInputErrorProps {
  children: string
  className?: string
  dataTestProp?: string
}

export function InlineInputError({
  children,
  className = undefined,
  dataTestProp = 'inlineInputError',
}: InlineInputErrorProps) {
  return (
    <ErrorMessage className={className} data-test={dataTestProp}>
      <Sprite name="misc/exclamation_mark" />

      <span>{children}</span>
    </ErrorMessage>
  )
}

const ErrorMessage = styled.div`
  ${text4_10};
  color: ${statusErrorRed};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${convertPxToRem(5)};

  & > svg {
    height: ${convertPxToRem(8)};
    width: ${convertPxToRem(8)};
  }
`

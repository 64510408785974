import React from 'react'

import { Link, useLocation, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import {
  TUTORIAL_SECOND_STEP,
  TUTORIAL_THIRD_STEP,
} from 'src/sections/SectionDashboard/dashboardHelpers'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  secondaryGrey,
  secondaryMidGrey,
  secondarySoftGrey,
  primaryBlue,
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  featurePaleBlue,
  secondaryLightGrey,
} from 'src/theme/colors'
import { textLink1_16, heading6_16_Medium, textLink2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkPathName } from 'src/utils/routeHelpers'

function SideBarDesktop({
  toggleGetHelpMenu = () => {},
  isVisibleGetHelpMenu = false,
  isSecondTutorialStep = false,
  isThirdTutorialStep = false,
  logoSidebarUrl = '',
  poweredByText = '',
  mainLinks = [],
  homePage = '',
  activeSection = '',
}) {
  const { t } = useTranslation()
  const i18n = {
    getHelpTitle: t('translation.home.tabbar.get_help'),
    logo: t('translation.home.tabbar.logo'),
    poweredByText: t('translation.home.tabbar.powered_by'),
  }
  const navigate = useNavigate()
  const location = useLocation()

  const getCurrentTutorialStep = (to) =>
    isThirdTutorialStep && to === ROUTES.HOME_ACTIVITY_PATH

  const getCursorOption = (to) =>
    getCurrentTutorialStep(to) ? 'inherit' : 'pointer'

  return (
    <NavBar>
      <LogoWrapper>
        <Logo
          src={logoSidebarUrl}
          onClick={() => navigate(`/${homePage}`)}
          alt={i18n.logo}
          data-test="side-bar-desktop-logo"
        />
      </LogoWrapper>
      <Content isCurrentTutorialStep={isSecondTutorialStep}>
        <QuickLinks>
          <Links>
            <List>
              {mainLinks.map(
                ({ section, to, title, onClick, icon, links }, index) => {
                  const isActive = activeSection === section

                  return (
                    <React.Fragment key={title}>
                      <QuickLinksRoot
                        isCurrentTutorialStep={getCurrentTutorialStep(to)}
                        isActive={isActive}
                        isMargin={isThirdTutorialStep && index === 1}
                      >
                        <IconWrapper
                          to={to}
                          onClick={onClick}
                          isCurrentTutorialStep={getCurrentTutorialStep(to)}
                        >
                          <Icon
                            data-is-active={isActive}
                            data-cursor={getCursorOption(to)}
                          >
                            <Sprite name={icon} />
                          </Icon>
                          <GetHelpTitle isActive={isActive}>
                            {t(title)}
                          </GetHelpTitle>
                        </IconWrapper>
                      </QuickLinksRoot>
                      {links && (
                        <LinksList>
                          {links.map((link) => {
                            const isActiveLink = checkPathName(
                              link.section,
                              location,
                            )
                            return (
                              <LinkWrapper key={link.to}>
                                <LinkRoot
                                  to={link.to}
                                  onClick={link.onClick}
                                  isActiveLink={isActiveLink}
                                >
                                  {t(link.title)}
                                </LinkRoot>
                              </LinkWrapper>
                            )
                          })}
                        </LinksList>
                      )}
                    </React.Fragment>
                  )
                },
              )}
            </List>
          </Links>
        </QuickLinks>

        <BottomContent isCurrentTutorialStep={isSecondTutorialStep}>
          <GetHelpButton
            onClick={toggleGetHelpMenu}
            isPoweredByText={!!poweredByText}
            data-gethelp
            isCurrentTutorialStep={isSecondTutorialStep}
            isAnimateGetHelp={isVisibleGetHelpMenu}
            disabled={isSecondTutorialStep}
          >
            <LogoIcon data-gethelp />
            <GetHelpText data-gethelp>{i18n.getHelpTitle}</GetHelpText>
          </GetHelpButton>

          {!!poweredByText && (
            <PoweredByTextWrapper
              isAnimateGetHelp={isVisibleGetHelpMenu}
              isCurrentTutorialStep={isSecondTutorialStep}
              data-test="powered-by-text-wrapper"
            >
              <PoweredByText>{i18n.poweredByText}</PoweredByText>
              <PoweredByOrganization>{poweredByText}</PoweredByOrganization>
            </PoweredByTextWrapper>
          )}
        </BottomContent>
      </Content>
    </NavBar>
  )
}

const NavBar = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: ${convertPxToRem(350)};
  padding-top: ${convertPxToRem(40)};
  border-right: ${convertPxToRem(1)} solid ${secondarySoftGrey};
  position: relative;

  ${mediaQueryFor.tabletOrLess} {
    display: none;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 50%;
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  color: ${secondaryGrey};

  cursor: inherit;

  &[data-is-active='true'] {
    border: none;

    color: ${primaryBlue};

    background-color: ${featurePaleBlue};
  }

  &[data-cursor='pointer'] {
    cursor: pointer;
  }

  & > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`

const IconWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${convertPxToRem(56)};
  cursor: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'default' : 'pointer'};

  :focus {
    outline: none;
  }
`
const Links = styled.div`
  margin-bottom: ${convertPxToRem(14)};
  padding-top: ${convertPxToRem(70)};
`
const List = styled.ul``

const GetHelpTitle = styled.span`
  color: ${({ isActive }) => (isActive ? primaryBlue : primaryDarkNavy)};
  margin-left: ${convertPxToRem(20)};
  ${textLink1_16};
`

const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const BottomContent = styled.div`
  padding: ${convertPxToRem(0, 40, 40)};
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  margin-bottom: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(45)};
`
const Content = styled.div`
  overflow-y: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'hidden' : 'auto'};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const QuickLinksRoot = styled.li`
  padding-left: ${({ isCurrentTutorialStep }) =>
    !isCurrentTutorialStep && convertPxToRem(40)};
  padding-top: ${({ isMargin }) => isMargin && convertPxToRem(55)};
  background-color: ${({ isActive, isCurrentTutorialStep }) =>
    isActive && !isCurrentTutorialStep
      ? secondaryBackgroundGrey
      : primaryWhite};
  position: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 'fixed'};
  left: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(40)};
  z-index: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 2};
  border-radius: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(20)};
  height: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(30)};
  box-shadow: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && `${convertPxToRem(0, 12, 0, 15)} ${primaryWhite}`};
  :hover {
    background-color: ${({ isCurrentTutorialStep }) =>
      !isCurrentTutorialStep && secondaryBackgroundGrey};
  }
`
const PoweredByTextWrapper = styled.div`
  flex-direction: row;
  z-index: ${({ isAnimateGetHelp }) => isAnimateGetHelp && 2};
  display: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep ? 'none' : 'flex'};
  margin-top: ${convertPxToRem(24)};
`
const PoweredByText = styled.div`
  color: ${secondaryMidGrey};
  ${heading6_16_Medium};
  margin-right: ${convertPxToRem(5)};
`
const PoweredByOrganization = styled.div`
  color: ${secondaryGrey};
  ${heading6_16_Medium};
`
const LogoWrapper = styled.div`
  position: absolute;
  height: ${convertPxToRem(70)};
  width: 100%;
  cursor: pointer;
  padding-left: ${convertPxToRem(40)};
  background-color: ${primaryWhite};
  padding-bottom: ${convertPxToRem(28)};
`

const Logo = styled.img`
  height: ${convertPxToRem(42)};
`
const LinksList = styled.ul`
  padding: ${convertPxToRem(16, 0, 16, 80)};
`
const LinkWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: ${convertPxToRem(1)} solid ${secondaryMidGrey};
  padding-bottom: ${convertPxToRem(28)};
  :last-child {
    padding-bottom: 0;
  }
`
const LinkRoot = styled(Link)`
  padding-left: ${convertPxToRem(16)};
  width: 100%;
  color: ${({ isActiveLink }) => (isActiveLink ? primaryBlue : secondaryGrey)};

  ${textLink1_16};
`
const GetHelpButton = styled.button`
  color: ${primaryBlue};
  border: ${convertPxToRem(1)} solid ${primaryBlue};
  border-radius: ${convertPxToRem(24)};
  background-color: ${({ isAnimateGetHelp }) =>
    isAnimateGetHelp ? featurePaleBlue : primaryWhite};
  left: ${convertPxToRem(40)};
  bottom: ${({ isCurrentTutorialStep }) =>
    isCurrentTutorialStep && convertPxToRem(40)};
  ${textLink2_14};
  padding: ${convertPxToRem(8, 16)};
  height: ${convertPxToRem(40)};
  display: flex;
  position: ${({ isCurrentTutorialStep }) => isCurrentTutorialStep && 'fixed'};
  align-items: center;
  cursor: pointer;
  z-index: ${({ isCurrentTutorialStep, isAnimateGetHelp }) =>
    isCurrentTutorialStep || isAnimateGetHelp ? 2 : 1};
  :hover {
    background-color: ${({ isCurrentTutorialStep }) =>
      !isCurrentTutorialStep && featurePaleBlue};
  }
  > svg {
    pointer-events: none;
  }
`

const LogoIcon = styled(Sprite).attrs({ name: 'misc/rightway' })`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
  color: ${primaryBlue};
`

const GetHelpText = styled.h3`
  margin-left: ${convertPxToRem(8)};
`

const mapStateToProps = ({ tutorial, user, common }) => ({
  isSecondTutorialStep: tutorial.tutorialStep === TUTORIAL_SECOND_STEP,
  isThirdTutorialStep: tutorial.tutorialStep === TUTORIAL_THIRD_STEP,
  logoSidebarUrl: user.branding?.logoSidebarUrl,
  poweredByText: user.branding?.poweredByText,
  mainLinks: common.availableRoutes?.mainLinks,
  homePage: common.homePage,
  activeSection: common.activeSection,
})

export default connect(mapStateToProps)(SideBarDesktop)

import { gql } from '@apollo/client'

export const APPOINTMENT_TIME_SLOT_FRAGMENTS = gql`
  fragment asapTime on AsSoonAsPossible {
    __typename
    id

    isAmPreferred: is_am_preferred
    isPmPreferred: is_pm_preferred
  }

  fragment specificTime on SpecificTime {
    __typename
    id

    date
    endTime: end_time
    startTime: start_time
  }

  fragment timeBlock on TimeBlock {
    __typename
    id

    days {
      displayName: display_name
      enabled
      name
    }

    endTime: end_time
    startTime: start_time
  }

  fragment suggestedTime on SuggestedTime {
    __typename
    id

    date
    endTime: end_time
    startTime: start_time
  }

  fragment pendingTime on PendingTime {
    __typename
    id

    date
    endTime: end_time
    startTime: start_time
  }

  fragment confirmedTime on ConfirmedTime {
    __typename
    id

    date
    endTime: end_time
    startTime: start_time
  }

  fragment cancelledTime on CancelledTime {
    __typename
    id

    date
    endTime: end_time
    startTime: start_time
  }
`

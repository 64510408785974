import React from 'react'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface StepIndicatorDotsIconProps {
  width?: string
  height?: string
  className?: string
}

export function StepIndicatorDotsIcon({
  width = convertPxToRem(4),
  height = convertPxToRem(28),
  className = undefined,
}: StepIndicatorDotsIconProps) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 4 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" />
      <circle cx="2" cy="10" r="2" />
      <circle cx="2" cy="18" r="2" />
      <circle cx="2" cy="26" r="2" />
    </svg>
  )
}

export enum LocationState {
  Unknown = 'unknown',
  Requested = 'requested',
  Known = 'known',
  Failed = 'failed',
}

export enum LocationError {
  Denied = 1,
  Unavailable = 2,
  Timeout = 3,

  Unsupported = 4,
  Unknown = 5,
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export enum RequestMode {
  Auto = 'auto',
  Manual = 'manual',
}

import { variant } from '@effector/reflect'
import styled from 'styled-components'

import { $hasPreferredPharmacy } from 'src/entities/pharmacy'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { AddPharmacyCard } from './AddPharmacyCard'
import { PreferredPharmacyCard } from './PreferredPharmacyCard'

export function PreferredPharmacyController() {
  const t = useScopedTranslation('translation.medications.drug_search')

  return (
    <Root>
      <Title>{t('labels.your_preferred_pharmacy')}</Title>

      <Content />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${convertPxToRem(24)};
  width: 100%;
`
const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  margin-bottom: ${convertPxToRem(8)};
  text-transform: uppercase;
`

const Content = variant({
  if: $hasPreferredPharmacy,
  then: PreferredPharmacyCard,
  else: AddPharmacyCard,
})

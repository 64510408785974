import { useEffect } from 'react'

import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'

import useBrandingUserQuery from 'src/apollo/hooks/useBrandingUserQuery'
import { withDispatch } from 'src/store'
import { setBranding } from 'src/store/slices/user'
import { getSlug } from 'src/utils/currentUserHelpers'
import useDynamicManifest from 'src/utils/hooks/useDynamicManifest'

interface BrandingHelmetProps extends ConnectedProps<typeof connector> {
  userId?: string | null
}

function BrandingHelmet({
  dispatchSetBranding,
  userId = null,
}: BrandingHelmetProps) {
  const location = useLocation()

  const locationSlug = getSlug(location)

  const { branding, isLoadingBranding } = useBrandingUserQuery(
    userId ? null : locationSlug,
  )

  const { title, faviconUrl } = branding

  useEffect(() => {
    if (!isLoadingBranding) dispatchSetBranding(branding)
  }, [branding, dispatchSetBranding, isLoadingBranding])

  const manifest = useDynamicManifest(branding)

  return (
    <Helmet defer>
      <title>{title ? `${title} - Member` : ''}</title>

      {title && <link rel="manifest" href={manifest} />}

      {faviconUrl && <link rel="icon" href={faviconUrl} />}

      {faviconUrl && (
        <link rel="apple-touch-icon" type="image/png" href={faviconUrl} />
      )}
    </Helmet>
  )
}

const mapDispatchToProps = withDispatch({
  setBranding,
})

const connector = connect(null, mapDispatchToProps)
export default connector(BrandingHelmet)

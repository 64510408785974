import moment from 'moment'

import { PharmacyDayOfWeekType } from 'src/shared/api/types'
import i18next from 'src/translations/i18n'

interface Period {
  dayOfWeek: PharmacyDayOfWeekType
  openHour: string
  closeHour: string
}

interface PharmacyWorkingInfo {
  when: string
  isOpenNow: boolean
}

const formatDay = (day: string) => {
  const daysIndex = moment.localeData('en').weekdaysParse(day, 'dddd', false)
  return moment.weekdaysShort(daysIndex)
}

const formatTime = (time: string) => moment(time, 'HH:mm').format('h:mm A')

const days = [
  PharmacyDayOfWeekType.Sunday,
  PharmacyDayOfWeekType.Monday,
  PharmacyDayOfWeekType.Tuesday,
  PharmacyDayOfWeekType.Wednesday,
  PharmacyDayOfWeekType.Thursday,
  PharmacyDayOfWeekType.Friday,
  PharmacyDayOfWeekType.Saturday,
]

const twoWeeks = days.concat(days)

function findNextPeriod(
  periods: Period[],
  today: PharmacyDayOfWeekType,
): Period {
  const existPeriods = new Set(periods.map(({ dayOfWeek }) => dayOfWeek))
  const daysAfterToday = twoWeeks.slice(days.indexOf(today) + 1)

  const nextDayAfterToday = daysAfterToday.find((day) => existPeriods.has(day))

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return periods.find(({ dayOfWeek }) => dayOfWeek === nextDayAfterToday)!
}

const i18nSchedule = {
  open: i18next.t('translation.shared.schedule.open'),
  closed: i18next.t('translation.shared.schedule.closed'),
  opens(time: string) {
    return i18next.t('translation.shared.schedule.opens', { time })
  },
  closes(time: string) {
    return i18next.t('translation.shared.schedule.closes', { time })
  },
}

const parse = (time: string | null) => moment(time, 'LT')

export function getWorkingInfo(week: Period[]): PharmacyWorkingInfo | null {
  const now = moment().locale('en')
  const today = now.format('dddd').toLowerCase() as PharmacyDayOfWeekType

  const periods = week.filter(({ dayOfWeek }) => dayOfWeek === today)
  const found = periods.find(({ closeHour }) => parse(closeHour).isAfter(now))

  const period = {
    start: parse(found?.openHour ?? null), // null to make date invalid if not found
    end: parse(found?.closeHour ?? null),
  }

  if (found && now.isBefore(period.start)) {
    return {
      isOpenNow: false,
      when: i18nSchedule.opens(period.start.format('h:mm A')),
    }
  }

  if (found && now.isSameOrBefore(period.end)) {
    return {
      isOpenNow: true,
      when: i18nSchedule.closes(period.end.format('h:mm A')),
    }
  }

  const nextOpen = findNextPeriod(week, today)
  if (!nextOpen) return null
  const nextOpensAt = parse(nextOpen.openHour).format('h:mm A')

  return {
    isOpenNow: false,
    when: i18nSchedule.opens(`${nextOpensAt} ${formatDay(nextOpen.dayOfWeek)}`),
  }
}

export function getFormattedSchedule(periods: Period[]) {
  return periods.map(({ dayOfWeek, openHour, closeHour }) => ({
    dayOfWeek,
    workingTime: `${formatTime(openHour)} - ${formatTime(closeHour)}`,
  }))
}

import { list, variant } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $drugSearchResults, $isDrugSearchLoading } from '../../model/search'

import { LoadingResults } from './LoadingResults'
import { NoResults } from './NoResults'
import { ResultItem } from './ResultItem'

const $count = $drugSearchResults.map((results) => results.length)

function SearchResultsComponent() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  const count = useUnit($count)

  const i18n = {
    results: t('results', { value: count }),
  }

  return (
    <Root>
      <ResultCounter>{i18n.results}</ResultCounter>

      <Results />
    </Root>
  )
}

const Results = list({
  source: $drugSearchResults,
  view: ResultItem,
  getKey: ({ name }) => name,
})

const Root = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`
const ResultCounter = styled.span`
  color: ${secondaryGrey};
  ${capsHeading1_12};
  text-transform: uppercase;
  padding-top: ${convertPxToRem(24)};

  width: 100%;
`

export const SearchResults = variant({
  if: $isDrugSearchLoading,
  then: LoadingResults,
  else: variant({
    if: $count.map((count) => count > 0),
    then: SearchResultsComponent,
    else: NoResults,
  }),
})

import { createEffect, createEvent, sample } from 'effector'
import { condition } from 'patronum'

import { $$chat } from 'src/entities/chat'
import { store } from 'src/store'
import { observeStore } from 'src/store/effects'

import {
  ChatStep,
  openChatThread,
  openInbox,
  setPrefilledMessage,
  startNewChat,
} from '../slices/chats'

const chatStepChanged = createEvent<{ id: string; step: ChatStep }>()
const chatPresetChanged = createEvent<string>()

const updateStepFx = createEffect((id: string | null): void => {
  if (id === null) store.dispatch(openInbox())
  else if (id === 'new') store.dispatch(startNewChat())
  else store.dispatch(openChatThread(id))
})

const updatePresetFx = createEffect((preset: string | null): void => {
  const payload = preset ?? ''
  store.dispatch(setPrefilledMessage(payload))
})

// Map Effector to Redux
sample({
  clock: $$chat.$id,
  target: updateStepFx,
})

sample({
  clock: $$chat.$preset,
  target: updatePresetFx,
})

// Map Redux to Effector
observeStore({
  store,
  skipInitial: true,
  selector: ({ chats }) => ({ id: chats.chatId, step: chats.step }),
  onChange: chatStepChanged,
})

observeStore({
  store,
  skipInitial: true,
  selector: ({ chats }) => chats.prefilledMessage,
  onChange: chatPresetChanged,
})

sample({
  clock: chatStepChanged,
  fn: ({ id, step }) => {
    if (step === ChatStep.INBOX) return null
    if (step === ChatStep.CHAT_NEW) return 'new'
    return id
  },
  target: $$chat.$id,
})

condition({
  source: chatPresetChanged,
  if: (preset) => preset !== '',
  then: $$chat.selectPreset,
  else: $$chat.consumePreset,
})

// eslint-disable-next-line import/prefer-default-export
export function localeDetector() {
  const locale = window.navigator.language

  switch (true) {
    case /^es(.)*/.test(locale):
      return 'es'
    case /^en(.)*/.test(locale): // fallthrough
    default:
      return 'en'
  }
}

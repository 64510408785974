import { NetworkStatus } from '@apollo/client'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { Paginated } from 'src/features/Paginated'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useChatsQuery } from '../../api/chatsQuery'
import { ChatPreview } from '../Preview/ChatPreview'

export function ChatsList() {
  const t = useScopedTranslation('translation.chat.inbox.with_messages')

  const { chats, status, fetchMore } = useChatsQuery()

  const nodes = chats?.nodes ?? []

  if (status === NetworkStatus.loading || status === NetworkStatus.setVariables)
    return <Spinner />

  if (nodes.length === 0) return <Notice>{t('empty_search')}</Notice>

  return (
    <Paginated
      parent={List}
      after={status === NetworkStatus.fetchMore && <PaginationLoader />}
      fetchMore={fetchMore}
    >
      {nodes
        .filter((chat) => chat !== null)
        .map((chat) => (
          <ChatPreview key={chat.id} chat={chat} />
        ))}
    </Paginated>
  )
}

const List = styled.div.attrs({ 'data-test': 'chatsList' })`
  display: flex;
  flex-flow: column nowrap;

  flex: 1 1 max-content;
  overflow-y: auto;

  background-color: ${primaryWhite};
`

const PaginationLoader = styled(Spinner).attrs({ size: 'small' })`
  // Add space above and below
  // to prevent rotating Spinner from affecting overflow
  flex: 0 0 ${convertPxToRem(40)};
`

const Notice = styled.span`
  ${text2_14};
  color: ${secondaryGrey};

  padding: ${convertPxToRem(0, 24)};
`

import { list } from '@effector/reflect'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'

import { $locations } from '../../model/requests'

import { AddLocationCard } from './AddLocationCard'
import { SavedLocationCard } from './SavedLocationCard'

export function SavedLocations() {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  return (
    <Root>
      <Title>{t('saved_locations')}</Title>

      <SavedLocationsList />

      <AddLocationCard />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

const Title = styled.span`
  ${capsHeading1_12};
  text-transform: uppercase;
  color: ${secondaryGrey};
`

const SavedLocationsList = list({
  view: SavedLocationCard,
  source: $locations,
  mapItem: {
    location: (item) => item,
  },
  getKey: ({ id }) => id,
})

import { datadogLogs } from '@datadog/browser-logs'
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum'

import config from 'src/config'
import getReleaseName from 'src/utils/sentryHelpers/getReleaseName'

import { useApplyLoggingContext, logmask } from './lib'
import { loggingLineCreated, clearUserFx } from './model'

export function startupIngestion() {
  if (!config.DATADOG_ID || !config.DATADOG_TOKEN) return

  datadogRum.init({
    site: 'datadoghq.com',
    service: 'member-web',

    applicationId: config.DATADOG_ID,
    clientToken: config.DATADOG_TOKEN,

    env: __APP_ENV__,
    version: getReleaseName(),

    beforeSend,

    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    traceSampleRate: 100,

    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,

    allowedTracingUrls: [config.GATEWAY_URL],

    defaultPrivacyLevel: 'mask',
  })

  datadogLogs.init({
    site: 'datadoghq.com',
    service: 'member-web',

    clientToken: config.DATADOG_TOKEN,

    env: __APP_ENV__,
    version: getReleaseName(),

    forwardReports: 'all',
  })

  loggingLineCreated({ line: 'Application started', meta: { on: 'app' } })
}

const beforeSend: RumInitConfiguration['beforeSend'] = (event, context) => {
  if (event.type !== 'resource') return true // Only modify resources
  if (!('requestInput' in context)) return true // Narrow down `context` to `fetch` type resource
  if (!context.requestInit?.headers) return true // If headers are missing we can do nothing

  const headers = new Headers(context.requestInit.headers)

  // Take a pre-defined header from src/apollo/links/auxillary.ts
  const operationName = headers.get('X-Apollo-Operation-Name')
  if (!operationName) return true

  event.context = { operationName }
  event.resource.url = `GraphQL/${operationName}`

  return true
}

export const $$logging = { resetFx: clearUserFx, log: loggingLineCreated }

export {
  /** @deprecated Use `$$logging` instead */ loggingLineCreated,
  useApplyLoggingContext,
  logmask,
}

import { gql } from '@apollo/client'

import INSURANCE_INFO_FRAGMENT from 'src/apollo/fragments/INSURANCE_INFO_FRAGMENT'

const CURRENT_USER_FRAGMENT = gql`
  fragment currentUserFragment on User {
    id
    preferred_phone_number
    first_name
    last_name
    preferred_email
    organization {
      id
      name
    }
    medical_insurance {
      name
    }
    insurance_info {
      ...insuranceInfoFragment
    }
    insurance_id
    employee_id
    email
    gender
  }
  ${INSURANCE_INFO_FRAGMENT}
`

export default CURRENT_USER_FRAGMENT

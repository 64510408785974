import { createEvent, sample } from 'effector'

import { $$client } from '@shared/api/client'
import { $$device } from '@shared/lib/device'
import { $$unstable_router } from '@shared/router'

import { $$security } from '@features/security'

import client from 'src/apollo/client'

import { router } from '../router'

export const appStarted = createEvent<void>()

sample({
  clock: appStarted,
  target: $$device.setup,
})

sample({
  clock: appStarted,
  fn: () => router,
  target: $$unstable_router.setup,
})

sample({
  clock: appStarted,
  // TODO: switch to DI through Fork API for Scope usage
  fn: () => client,
  target: $$client.$instance,
})

sample({
  clock: appStarted,
  target: $$security.setup,
})

import { gql } from '@apollo/client'

const CUSTOM_FEED_ITEMS_QUERY = gql`
  query CustomFeedItems {
    customFeedItems {
      card_type
      title
      body
      label
      call_to_action
      image_url
      url
    }
  }
`
export default CUSTOM_FEED_ITEMS_QUERY

import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { ButtonNavigateTo16 } from 'src/shared/ui/buttons/ButtonNavigateTo16'
import { primaryDarkNavy, primaryLightBlue } from 'src/theme/colors'
import { textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface LinkWithArrowProps {
  title: string
  navigateTo: () => void
  icon: ReactNode
  isDisabled?: boolean
}

function LinkWithArrow({
  title,
  navigateTo,
  icon,
  isDisabled = false,
}: LinkWithArrowProps) {
  return (
    <Root
      onClick={isDisabled ? undefined : navigateTo}
      data-test="linkWithArrow"
      data-is-disabled={isDisabled}
    >
      <Icon>{icon}</Icon>

      <Title>{title}</Title>

      <ButtonNavigateTo16 fill={primaryLightBlue} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  padding-right: ${convertPxToRem(25)};

  &[data-is-disabled='true'] {
    cursor: default;
  }
`

const Icon = styled.div``

const Title = styled.span`
  display: flex;
  flex: 1;
  padding-left: ${convertPxToRem(14)};
  color: ${primaryDarkNavy};
  ${textLink1_16};
`

export default LinkWithArrow

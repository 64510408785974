import { TypedDocumentNode, gql } from '@apollo/client'

import { ChatMessage } from './types'

export const CHAT_MESSAGE_FRAGMENT: TypedDocumentNode<
  ChatMessage,
  Record<string, never>
> = gql`
  fragment chatMessage on ChatMessage {
    id

    isOwned: is_self_sent
    updatedAt: updated_at

    body

    attachment {
      ...chatFileAttachment

      ... on SystemNotificationType {
        kind: __typename
        message
      }
    }
  }

  fragment chatFileAttachment on ChatMessageFileType {
    kind: __typename

    url: short_url
    contentType: content_type

    scanStatus: scan_status
  }
`

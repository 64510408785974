import { useState } from 'react'

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import NavigateToIcon from 'src/components/SvgIcons/NavigateToIcon'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  featurePaleBlue,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
  secondarySoftGrey,
  statusErrorRed,
} from 'src/theme/colors'
import { text1_16, heading8_12, text4_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ConfigType, ValidationErrors } from '../model/types'
import { $validationErrors } from '../model/validation'

interface DropdownProps {
  className?: string
  value?: string
  options?: Record<string, string>
  title?: string
  placeholder?: string
  onSelect?: (option: string) => void
  isDisabled?: boolean
  dataTestProp?: string
  type: ConfigType
  errors: ValidationErrors
}

function DropdownView({
  className = '',
  value = '',
  title = '',
  placeholder = '',
  options = {},
  onSelect = () => {},
  isDisabled = false,
  dataTestProp = 'dropdown',
  type,
  errors,
}: DropdownProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const [isExpanded, setIsExpanded] = useState(false)

  const handleClose = () => {
    setIsExpanded(false)
  }
  const handleSelect = (newValue: string) => () => {
    setIsExpanded(false)
    onSelect(newValue)
  }

  const handleClick = () => {
    if (isDisabled) return
    setIsExpanded((isExp) => !isExp)
  }

  const error = errors[type]

  return (
    <Root className={className} tabIndex={0} onBlur={handleClose}>
      {title && <Title>{title}</Title>}
      <Select
        onClick={handleClick}
        data-has-selected-option={Boolean(value)}
        data-has-error={Boolean(error) && !isExpanded}
        data-disabled={isDisabled}
        data-test={`${dataTestProp}.select`}
      >
        {options[value] || placeholder}
        <ExpandArrow fill={secondaryGrey} rotate={isExpanded ? '-90' : '90'} />
      </Select>

      {error && !isExpanded && (
        <ErrorMessage>
          <Sprite name="misc/exclamation_mark" />
          {t(error)}
        </ErrorMessage>
      )}

      {isExpanded && Object.entries(options).length > 0 && (
        <OptionsContainer>
          <OptionsList>
            {Object.entries(options).map(([val, displayValue]) => (
              <DropDownOptionItem
                key={val}
                onClick={handleSelect(val)}
                data-test={`${dataTestProp}.option.${val}`}
              >
                {displayValue}
              </DropDownOptionItem>
            ))}
          </OptionsList>
        </OptionsContainer>
      )}
    </Root>
  )
}

const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${heading8_12};
  margin-bottom: ${convertPxToRem(8)};
  padding-left: ${convertPxToRem(8)};
`
const Select = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${convertPxToRem(48)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: 8px;
  padding: ${convertPxToRem(9, 24, 9, 16)};
  position: relative;
  ${text1_16};
  color: ${secondaryMidGrey};

  cursor: pointer;

  &[data-disabled='true'] {
    background-color: ${secondarySoftGrey};
    color: ${secondaryGrey};
  }
  &[data-has-selected-option='true'] {
    color: ${primaryDarkNavy};
  }
  &[data-has-error='true'] {
    border-color: ${statusErrorRed};
    color: ${primaryDarkNavy};
  }
`
const ExpandArrow = styled(NavigateToIcon)`
  position: absolute;
  right: ${convertPxToRem(8)};
  transition: all 0.2s;
`
const OptionsContainer = styled.div`
  position: relative;
`
const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(4)};
  padding-left: ${convertPxToRem(4)};

  position: absolute;
  bottom: ${convertPxToRem(-14)};
  left: 0;

  ${text4_10};
  color: ${statusErrorRed};

  & > svg {
    height: ${convertPxToRem(8)};
    width: ${convertPxToRem(8)};
  }
`
const OptionsList = styled.ul`
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: 8px;
  position: absolute;
  top: ${convertPxToRem(4)};
  background-color: ${primaryWhite};
  width: 100%;
  z-index: 5;
`
const DropDownOptionItem = styled.li`
  padding: ${convertPxToRem(14, 16)};
  color: ${secondaryGrey};
  cursor: pointer;
  &:hover {
    background-color: ${featurePaleBlue};
    color: ${primaryDarkNavy};
  }
  transition: all 0.2s;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  user-select: none;
`

export const Dropdown = reflect({
  view: DropdownView,
  bind: {
    errors: $validationErrors,
  },
})

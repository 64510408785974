export const enum DrugSearchFlowEntrypoint {
  Activity = 'Activity',
  Medications = 'Medications',
}

export interface RequestData {
  drugName: string
  latitude: number
  longitude: number
}

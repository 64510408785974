import React from 'react'

import PropTypes from 'prop-types'

import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function TooltipIcon({
  width = convertPxToRem(16),
  height = convertPxToRem(16),
  fillCircle = secondaryGrey,
  fill = 'none',
  onClick = () => {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8 0C3.582 0 0 3.582 0 8s3.582 8 8 8 8-3.582 8-8c-.005-4.416-3.584-7.995-8-8zm0 12.667c-.552 0-1-.448-1-1 0-.553.448-1 1-1s1 .447 1 1c0 .552-.448 1-1 1zm.667-3.442c0-.266.157-.506.4-.612v.001c1.22-.533 1.86-1.884 1.5-3.167-.36-1.282-1.612-2.1-2.932-1.919-1.319.182-2.302 1.31-2.302 2.642 0 .368.299.667.667.667.368 0 .667-.299.667-.667 0-.666.49-1.23 1.15-1.321.66-.092 1.286.318 1.466.959.181.64-.14 1.317-.75 1.583-.729.318-1.2 1.038-1.2 1.834 0 .368.299.666.667.666.368 0 .667-.298.667-.666z"
        clipRule="evenodd"
      />
      <path
        fill={fillCircle}
        d="M8 12.666c-.552 0-1-.447-1-1 0-.552.448-1 1-1s1 .448 1 1c0 .553-.448 1-1 1zM9.067 8.613c-.243.106-.4.346-.4.612 0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666 0-.796.471-1.516 1.2-1.834.61-.266.93-.942.75-1.583-.18-.641-.806-1.05-1.466-.96-.66.092-1.15.656-1.15 1.322 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667 0-1.332.983-2.46 2.302-2.642 1.32-.182 2.571.637 2.932 1.92.36 1.281-.28 2.633-1.5 3.166z"
      />
    </svg>
  )
}

TooltipIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  fillCircle: PropTypes.string,
}

export default TooltipIcon

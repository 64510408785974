import { createEvent, createStore } from 'effector'

import { NEW_THREAD_ID } from '../lib'

export const chatSelected = createEvent<string>()
export const chatCreationSelected = createEvent<void>()
export const chatClosed = createEvent<void>()

export const $chatId = createStore<string | null>(null)
  .on(chatSelected, (_, id) => id)
  .on(chatCreationSelected, () => NEW_THREAD_ID)
  .reset(chatClosed)

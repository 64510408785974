import { TypedDocumentNode, gql } from '@apollo/client'

import {
  PreferredPharmacyQueryResult,
  PreferredPharmacyQueryVariables,
} from './types'

export const PREFERRED_PHARMACY_QUERY: TypedDocumentNode<
  PreferredPharmacyQueryResult,
  PreferredPharmacyQueryVariables
> = gql`
  query preferredPharmacyQuery {
    preferredPharmacy: pdsPreferredPharmacy {
      ...preferredPharmacy
    }
  }

  fragment preferredPharmacy on Pharmacies {
    name
    addressLine1: address_line1
    latitude
    longitude
    phone: telephone_number
    city
    state
    zip
    nabp
    deliveryTime: delivery_time
    workSchedule: work_schedule {
      dayOfWeek: day_of_week
      openHour: open_hour
      closeHour: close_hour
    }
    pharmacySteps: pharmacy_steps {
      title
      content {
        content
        type
        url
      }
    }
  }
`

import styled from 'styled-components'

import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${primaryWhite};
  width: ${convertPxToRem(48)};
  height: ${convertPxToRem(48)};

  border-radius: 50%;
  cursor: pointer;

  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);

  & > svg {
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }
`

/* eslint no-param-reassign: ["warn", { "props": true, "ignorePropertyModificationsFor": ["draft", "user"] }] */
import { produce, original, type Draft } from 'immer'

import { createStorageInterface, StorageContainer } from 'src/utils/storage/api'

/*
 * List of migrated/obsolete LS fields.
 * These may contain stale data for some users.
 * Avoid using these in the future.
 *
 * - `isCarePlanQuestionnaireTutorialFinished`
 */

export interface IPersonalState {
  isBillSupportTutorialFinished: boolean
  isCarePlanTutorialFinished: boolean
}

interface IUserStorage {
  [userId: string]: IPersonalState
}

type WithUser<T> = T extends void
  ? { userId: string }
  : T extends Record<string, unknown>
  ? { userId: string } & T
  : { userId: string; value: T }

interface ModifyUserArgs {
  draft: Draft<IUserStorage>
  userId: string
  fn: (user: IPersonalState) => void
}

const initialUserState: IUserStorage[string] = {
  isBillSupportTutorialFinished: false,
  isCarePlanTutorialFinished: false,
}

function modifyUser({ draft, userId, fn }: ModifyUserArgs) {
  const current = original(draft)?.[userId] ?? initialUserState

  const next = produce(current, fn)

  draft[userId] = next
}

export default createStorageInterface({
  container: StorageContainer.personal,
  initialState: {} as IUserStorage,
  actions: {
    completeBillSupportTutorial(draft, { userId }: WithUser<void>) {
      modifyUser({
        draft,
        userId,
        fn(user) {
          user.isBillSupportTutorialFinished = true
        },
      })
    },

    completeCarePlanTutorial(draft, { userId }: WithUser<void>) {
      modifyUser({
        draft,
        userId,
        fn(user) {
          user.isCarePlanTutorialFinished = true
        },
      })
    },
  },
})

export type Maybe<T> = T
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  ApolloFileUploadInput: { input: File; output: File }
  AssetURL: { input: string; output: string }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date | string; output: string }
  HexColorString: { input: string; output: string }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: Date | string; output: string }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: Date | string; output: string }
  /** 10-digit National Provider Identifier */
  NPI: { input: number | string; output: number | string }
  /** Phone number string. Output is in international format: +16460070071. */
  PhoneString: { input: string; output: string }
  /** Short link to download a file. */
  ShortLink: { input: string; output: string }
  /** URLString scalar type */
  URLString: { input: string; output: string }
  /** String that represents ZIP code. */
  ZIP: { input: string; output: string }
}

export enum AnnualPhysicalFormAnswersKindsType {
  Checkbox = 'Checkbox',
  Choice = 'Choice',
  Date = 'Date',
  File = 'File',
  Input = 'Input',
  Number = 'Number',
}

export enum AnnualPhysicalFormBooleanChoiceType {
  No = 'No',
  Yes = 'Yes',
}

export enum ApplicationFeatureName {
  Appointments = 'appointments',
  BillAdvocacy = 'bill_advocacy',
  CareLocatorNetFilter = 'care_locator_net_filter',
  CarePlans = 'care_plans',
  Core = 'core',
  CoreInsuranceCard = 'core_insurance_card',
  EarlyAccess = 'early_access',
  FindCare = 'find_care',
  GetCareSectionNavigation = 'get_care_section_navigation',
  MedicineCabinet = 'medicine_cabinet',
  MyCareTeam = 'my_care_team',
  OldPbm = 'old_pbm',
  Pbm = 'pbm',
  PbmAccumulator = 'pbm_accumulator',
  PbmDrugSearch = 'pbm_drug_search',
  PbmMccp = 'pbm_mccp',
  Ratings = 'ratings',
  Rewards = 'rewards',
  SharedActivityFeed = 'shared_activity_feed',
  SharedDentalCoverage = 'shared_dental_coverage',
  SharedHealthProfile = 'shared_health_profile',
  SharedInsuranceBenefits = 'shared_insurance_benefits',
  SharedPharmacyCoverage = 'shared_pharmacy_coverage',
  SharedPhoneVerify = 'shared_phone_verify',
  SharedProfile = 'shared_profile',
  SharedSessionTimeout = 'shared_session_timeout',
  SharedSettings = 'shared_settings',
  SharedVisionCoverage = 'shared_vision_coverage',
  SmsAlert = 'sms_alert',
  Telemedicine = 'telemedicine',
  Wellness = 'wellness',
}

export enum AppointmentEntity {
  Doctor = 'doctor',
  Facility = 'facility',
}

export enum AppointmentStatus {
  /** Cancelled */
  Cancelled = 'cancelled',
  /** Completed */
  Completed = 'completed',
  /** Confirmed */
  Confirmed = 'confirmed',
  /** Pending */
  Pending = 'pending',
  /** Pending cancelled */
  PendingCancelled = 'pending_cancelled',
  /** Rejected */
  Rejected = 'rejected',
  /** Suggested */
  Suggested = 'suggested',
}

export interface AcceptTermsOfServiceAttributesInput {
  id: Scalars['ID']['input']
}

export enum AccumulatorType {
  Family = 'FAMILY',
  Individual = 'INDIVIDUAL',
}

export interface AddressInput {
  address_line_1?: InputMaybe<Scalars['String']['input']>
  address_line_2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<UsStateName>
  zip?: InputMaybe<Scalars['ZIP']['input']>
}

export interface AddressStringInput {
  address_line_1?: InputMaybe<Scalars['String']['input']>
  address_line_2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<UsStateName>
  zip?: InputMaybe<Scalars['ZIP']['input']>
}

export interface AnnualPhysicalFormAttributesInput {
  a1c?: InputMaybe<Scalars['String']['input']>
  attachment: Scalars['ApolloFileUploadInput']['input']
  blood_pressure?: InputMaybe<Scalars['String']['input']>
  body_mass_index?: InputMaybe<Scalars['String']['input']>
  fasting_glucose?: InputMaybe<Scalars['String']['input']>
  hdl_cholesterol?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['String']['input']>
  ldl_cholesterol?: InputMaybe<Scalars['String']['input']>
  mammogram?: InputMaybe<Scalars['String']['input']>
  pap_smear?: InputMaybe<Scalars['String']['input']>
  patient_name: Scalars['String']['input']
  prostate_screening?: InputMaybe<Scalars['String']['input']>
  /** Date of the screening. */
  screen_date?: InputMaybe<Scalars['ISO8601Date']['input']>
  smoking_attestation?: InputMaybe<AnnualPhysicalFormBooleanChoiceType>
  total_cholesterol?: InputMaybe<Scalars['String']['input']>
  triglycerides?: InputMaybe<Scalars['String']['input']>
  weight?: InputMaybe<Scalars['String']['input']>
}

export interface AppointmentAttributesInput {
  /** ID of doctor or facility, to which you want to book an appointment. */
  entity_id: Scalars['ID']['input']
  /** ID of doctor or facility, to which you want to book an appointment. */
  entity_type: AppointmentEntity
  is_first_time_visit?: InputMaybe<Scalars['Boolean']['input']>
  /** ZIP code of the preferred location. */
  preferred_address?: InputMaybe<Scalars['String']['input']>
  reason_of_visit?: InputMaybe<Scalars['String']['input']>
}

export interface AppointmentTimeslotsInput {
  /** Adds ASAP (with no preselceted date and time) time-slot to appointment request. */
  asap?: InputMaybe<AsSoonAsPossibleSlotInput>
  specific?: InputMaybe<Array<SpecificSlotInput>>
  time_block?: InputMaybe<Array<TimeBlockSlotInput>>
}

export interface AsSoonAsPossibleSlotInput {
  /** ZIP code of the preferred location. */
  preference?: InputMaybe<DayPartPreferrence>
}

/** Possible Bill Claim categories. */
export enum BillClaimCategory {
  /** Dental */
  Dental = 'dental',
  /** Medical */
  Medical = 'medical',
  /** Pharmacy */
  Pharmacy = 'pharmacy',
  /** Vision */
  Vision = 'vision',
}

/** Possible Bill Claim statuses. */
export enum BillClaimStatus {
  /** In progress */
  InProgress = 'in_progress',
  /** Pending */
  Pending = 'pending',
  /** Resolved */
  Resolved = 'resolved',
}

export enum BrandingLogoUrlVersion {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum BenefitType {
  Deductible = 'DEDUCTIBLE',
  OutOfPocket = 'OUT_OF_POCKET',
}

export interface BillClaimAttributesInput {
  /** Bill Claim Category Name. */
  claim_category?: InputMaybe<BillClaimCategory>
  focus_request?: InputMaybe<FocusRequestType>
  focus_request_other?: InputMaybe<Scalars['String']['input']>
  medical_bills?: InputMaybe<Array<MedicalBillAttributesInput>>
  notes?: InputMaybe<Scalars['String']['input']>
}

export interface BillClaimFilterInput {
  /** Show only resolved (true) or unresolved (false) Bill Claim requests. Show all if none specified (null). */
  resolved?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CarePlanCareType {
  Acute = 'acute',
  Chronic = 'chronic',
  Wellness = 'wellness',
}

export enum CarePlanPreferredDistance {
  /** Any distance */
  Any = 'any',
  /** Less than 5 miles */
  LessThan5Miles = 'less_than_5_miles',
  /** Less than 10 miles */
  LessThan10Miles = 'less_than_10_miles',
  /** Less than 20 miles */
  LessThan20Miles = 'less_than_20_miles',
  /** Less than a mile */
  LessThanAMile = 'less_than_a_mile',
}

export enum CarePlanRequest {
  /** Allergist */
  Allergist = 'allergist',
  /** Back Pain */
  BackPain = 'back_pain',
  /** Blood Test */
  BloodTest = 'blood_test',
  /** Chiropractor */
  Chiropractor = 'chiropractor',
  /** Dentist */
  Dentist = 'dentist',
  /** Dermatologist */
  Dermatologist = 'dermatologist',
  /** Endocrinologist */
  Endocrinologist = 'endocrinologist',
  /** Gastroenterologist (GI) */
  Gastroenterology = 'gastroenterology',
  /** General */
  General = 'general',
  /** OB-GYN */
  Gynecologist = 'gynecologist',
  /** Hip Pain */
  HipPain = 'hip_pain',
  /** Knee Pain */
  KneePain = 'knee_pain',
  /** MRI */
  Mri = 'mri',
  /** Neurologist */
  Neurology = 'neurology',
  /** Eye Doctor */
  Ophthalmology = 'ophthalmology',
  /** Orthodontist */
  Orthodontist = 'orthodontist',
  /** Orthopedic Surgeon */
  Orthopedics = 'orthopedics',
  /** Pediatrician */
  Pediatrician = 'pediatrician',
  /** Physical Therapy */
  PhysicalTherapy = 'physical_therapy',
  /** Podiatrist */
  Podiatrist = 'podiatrist',
  /** Primary Care Provider */
  PrimaryCareDoctor = 'primary_care_doctor',
  /** Mental Health */
  Psychiatrist = 'psychiatrist',
  /** Radiology */
  Radiology = 'radiology',
  /** Shoulder Pain */
  ShoulderPain = 'shoulder_pain',
  /** Specialist */
  Specialist = 'specialist',
  /** Urgent Care */
  UrgentCare = 'urgent_care',
  /** Wellness Visit */
  WellnessVisit = 'wellness_visit',
}

/** Care program status. */
export enum CareProgramStatusType {
  /** eligible */
  Eligible = 'eligible',
  /** enrolled */
  Enrolled = 'enrolled',
  /** non_adherent */
  NonAdherent = 'non_adherent',
  /** unspecified */
  Unspecified = 'unspecified',
}

export enum ChatMessagesOrder {
  /** Ascending sort by 'created' field. */
  Created = 'created',
  /** Descending sort by 'created' field. */
  ReverseCreated = 'reverse_created',
}

/** Resource to be searched for. */
export enum ChatResourceType {
  /** Appointment */
  Appointment = 'appointment',
  /** Bill Claim */
  BillClaim = 'bill_claim',
  /** Care Plan */
  CarePlan = 'care_plan',
}

export enum ChatStatsEventType {
  ChatRead = 'chat_read',
  MessageCreated = 'message_created',
  MessageUpdated = 'message_updated',
}

export enum CustomFeedItemsTypes {
  /** Health profile */
  HealthProfile = 'health_profile',
  /** Wellness */
  Wellness = 'wellness',
}

export interface CarePlanRequestAttributesInput {
  comments?: InputMaybe<Scalars['String']['input']>
  contact_phone?: InputMaybe<Scalars['String']['input']>
  first_time_visit?: InputMaybe<Scalars['Boolean']['input']>
  health_issue?: InputMaybe<Scalars['String']['input']>
  /** The postal (ZIP) code of the place where the user prefers the doctors to be closest. */
  preferred_address?: InputMaybe<Scalars['String']['input']>
  preferred_distance?: InputMaybe<CarePlanPreferredDistance>
  provider_name?: InputMaybe<Scalars['String']['input']>
  response_threshold?: InputMaybe<Scalars['String']['input']>
  severity_rating?: InputMaybe<Scalars['Int']['input']>
  special_needs?: InputMaybe<Scalars['String']['input']>
}

export interface CarePlansFiltersInput {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum CareProgramStatus {
  Eligible = 'ELIGIBLE',
  Enrolled = 'ENROLLED',
  NonAdherent = 'NON_ADHERENT',
  UnspecifiedStatus = 'UNSPECIFIED_STATUS',
}

export interface ChatAttributesInput {
  subject?: InputMaybe<Scalars['String']['input']>
}

export interface ChatMessageAttributesInput {
  body?: InputMaybe<Scalars['String']['input']>
  file?: InputMaybe<Scalars['ApolloFileUploadInput']['input']>
}

export interface ChatResourceInput {
  id: Scalars['ID']['input']
  type: ChatResourceType
}

export interface ChatsFilterInput {
  /** Allows to fins chats which were linked to particular resource. */
  attached_to?: InputMaybe<ChatResourceInput>
  /** True: show only active. False: show archived & inactive. Nil or not passed: show everything. */
  only_active?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export enum ContentStepTypeEnum {
  /** link */
  Link = 'link',
  /** rx_card */
  RxCard = 'rx_card',
  /** text */
  Text = 'text',
}

export interface CurrentUserAttributesInput {
  /** The user's address */
  address?: InputMaybe<AddressInput>
  decline_health_profile?: InputMaybe<Scalars['Boolean']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<MemberGenderType>
  /** The user's preferred search location */
  location?: InputMaybe<LatitudeLongitudeInput>
  password?: InputMaybe<PasswordChangeInput>
  preferred_email?: InputMaybe<Scalars['String']['input']>
  preferred_phone_number?: InputMaybe<Scalars['PhoneString']['input']>
  /** The location identifier of the user's preferred health provider */
  preferred_provider_location_uuid?: InputMaybe<Scalars['String']['input']>
  /** The National Provider Identifier of the user's preferred health provider */
  preferred_provider_npi?: InputMaybe<Scalars['NPI']['input']>
  /** Terms of service ID. */
  terms_of_service_id?: InputMaybe<Scalars['ID']['input']>
  tooltips_medications_viewed?: InputMaybe<Scalars['Boolean']['input']>
  tooltips_viewed?: InputMaybe<Scalars['Boolean']['input']>
}

export interface CurrentUserInsuranceInfoAttributesInput {
  dental?: InputMaybe<VisionCareAttributes>
  medical?: InputMaybe<MedicalCareAttributes>
  pharmacy?: InputMaybe<PharmacyCareAttributes>
  vision?: InputMaybe<DentalCareAttributes>
}

export enum DayName {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

export enum DayPartPreferrence {
  Am = 'AM',
  Pm = 'PM',
}

/** Possible doctor genders */
export enum DoctorGenderType {
  /** Female */
  F = 'f',
  /** Male */
  M = 'm',
}

/** Possible names for searh for doctor by language. */
export enum DoctorLanguageType {
  /** Abkhazian */
  Abkhazian = 'abkhazian',
  /** Afar */
  Afar = 'afar',
  /** Afrikaans */
  Afrikaans = 'afrikaans',
  /** Akan */
  Akan = 'akan',
  /** Albanian */
  Albanian = 'albanian',
  /** Amharic */
  Amharic = 'amharic',
  /** Arabic */
  Arabic = 'arabic',
  /** Aragonese */
  Aragonese = 'aragonese',
  /** Armenian */
  Armenian = 'armenian',
  /** Assamese */
  Assamese = 'assamese',
  /** Avaric */
  Avaric = 'avaric',
  /** Avestan */
  Avestan = 'avestan',
  /** Aymara */
  Aymara = 'aymara',
  /** Azerbaijani */
  Azerbaijani = 'azerbaijani',
  /** Bambara */
  Bambara = 'bambara',
  /** Bashkir */
  Bashkir = 'bashkir',
  /** Basque */
  Basque = 'basque',
  /** Belarusian */
  Belarusian = 'belarusian',
  /** Bengali */
  Bengali = 'bengali',
  /** Bislama */
  Bislama = 'bislama',
  /** Bosnian */
  Bosnian = 'bosnian',
  /** Breton */
  Breton = 'breton',
  /** Bulgarian */
  Bulgarian = 'bulgarian',
  /** Burmese */
  Burmese = 'burmese',
  /** Cambodian */
  Cambodian = 'cambodian',
  /** Chamorro */
  Chamorro = 'chamorro',
  /** Chechen */
  Chechen = 'chechen',
  /** Chinese */
  Chinese = 'chinese',
  /** Chuvash */
  Chuvash = 'chuvash',
  /** Cornish */
  Cornish = 'cornish',
  /** Corsican */
  Corsican = 'corsican',
  /** Cree */
  Cree = 'cree',
  /** Croatian */
  Croatian = 'croatian',
  /** Czech */
  Czech = 'czech',
  /** Danish */
  Danish = 'danish',
  /** Dzongkha */
  Dzongkha = 'dzongkha',
  /** English */
  English = 'english',
  /** Esperanto */
  Esperanto = 'esperanto',
  /** Estonian */
  Estonian = 'estonian',
  /** Ewe */
  Ewe = 'ewe',
  /** Faroese */
  Faroese = 'faroese',
  /** Fijian */
  Fijian = 'fijian',
  /** Finnish */
  Finnish = 'finnish',
  /** French */
  French = 'french',
  /** Fulah */
  Fulah = 'fulah',
  /** Galician */
  Galician = 'galician',
  /** Ganda */
  Ganda = 'ganda',
  /** Georgian */
  Georgian = 'georgian',
  /** German */
  German = 'german',
  /** Guarani */
  Guarani = 'guarani',
  /** Gujarati */
  Gujarati = 'gujarati',
  /** Hausa */
  Hausa = 'hausa',
  /** Hebrew */
  Hebrew = 'hebrew',
  /** Herero */
  Herero = 'herero',
  /** Hindi */
  Hindi = 'hindi',
  /** Hungarian */
  Hungarian = 'hungarian',
  /** Icelandic */
  Icelandic = 'icelandic',
  /** Ido */
  Ido = 'ido',
  /** Igbo */
  Igbo = 'igbo',
  /** Indonesian */
  Indonesian = 'indonesian',
  /** Inuktitut */
  Inuktitut = 'inuktitut',
  /** Inupiaq */
  Inupiaq = 'inupiaq',
  /** Irish */
  Irish = 'irish',
  /** Italian */
  Italian = 'italian',
  /** Japanese */
  Japanese = 'japanese',
  /** Javanese */
  Javanese = 'javanese',
  /** Kannada */
  Kannada = 'kannada',
  /** Kanuri */
  Kanuri = 'kanuri',
  /** Kashmiri */
  Kashmiri = 'kashmiri',
  /** Kazakh */
  Kazakh = 'kazakh',
  /** Kinyarwanda */
  Kinyarwanda = 'kinyarwanda',
  /** Komi */
  Komi = 'komi',
  /** Kongo */
  Kongo = 'kongo',
  /** Korean */
  Korean = 'korean',
  /** Kurdish */
  Kurdish = 'kurdish',
  /** Lao */
  Lao = 'lao',
  /** Latin */
  Latin = 'latin',
  /** Latvian */
  Latvian = 'latvian',
  /** Lingala */
  Lingala = 'lingala',
  /** Lithuanian */
  Lithuanian = 'lithuanian',
  /** Macedonian */
  Macedonian = 'macedonian',
  /** Malagasy */
  Malagasy = 'malagasy',
  /** Malay */
  Malay = 'malay',
  /** Malayalam */
  Malayalam = 'malayalam',
  /** Maltese */
  Maltese = 'maltese',
  /** Manx */
  Manx = 'manx',
  /** Maori */
  Maori = 'maori',
  /** Marathi */
  Marathi = 'marathi',
  /** Marshallese */
  Marshallese = 'marshallese',
  /** Mongolian */
  Mongolian = 'mongolian',
  /** Nauru */
  Nauru = 'nauru',
  /** Ndonga */
  Ndonga = 'ndonga',
  /** Nepali */
  Nepali = 'nepali',
  /** Norwegian */
  Norwegian = 'norwegian',
  /** Ojibwa */
  Ojibwa = 'ojibwa',
  /** Oriya */
  Oriya = 'oriya',
  /** Oromo */
  Oromo = 'oromo',
  /** Pali */
  Pali = 'pali',
  /** Persian */
  Persian = 'persian',
  /** Polish */
  Polish = 'polish',
  /** Portuguese */
  Portuguese = 'portuguese',
  /** Quechua */
  Quechua = 'quechua',
  /** Romansh */
  Romansh = 'romansh',
  /** Rundi */
  Rundi = 'rundi',
  /** Russian */
  Russian = 'russian',
  /** Samoan */
  Samoan = 'samoan',
  /** Sango */
  Sango = 'sango',
  /** Sanskrit */
  Sanskrit = 'sanskrit',
  /** Sardinian */
  Sardinian = 'sardinian',
  /** Serbian */
  Serbian = 'serbian',
  /** Shona */
  Shona = 'shona',
  /** Sindhi */
  Sindhi = 'sindhi',
  /** Slovak */
  Slovak = 'slovak',
  /** Slovenian */
  Slovenian = 'slovenian',
  /** Somali */
  Somali = 'somali',
  /** Spanish */
  Spanish = 'spanish',
  /** Sundanese */
  Sundanese = 'sundanese',
  /** Swahili */
  Swahili = 'swahili',
  /** Swati */
  Swati = 'swati',
  /** Swedish */
  Swedish = 'swedish',
  /** Tagalog */
  Tagalog = 'tagalog',
  /** Tahitian */
  Tahitian = 'tahitian',
  /** Tajik */
  Tajik = 'tajik',
  /** Tamil */
  Tamil = 'tamil',
  /** Tatar */
  Tatar = 'tatar',
  /** Telugu */
  Telugu = 'telugu',
  /** Thai */
  Thai = 'thai',
  /** Tibetan */
  Tibetan = 'tibetan',
  /** Tigrinya */
  Tigrinya = 'tigrinya',
  /** Tsonga */
  Tsonga = 'tsonga',
  /** Tswana */
  Tswana = 'tswana',
  /** Turkish */
  Turkish = 'turkish',
  /** Turkmen */
  Turkmen = 'turkmen',
  /** Twi */
  Twi = 'twi',
  /** Ukrainian */
  Ukrainian = 'ukrainian',
  /** Urdu */
  Urdu = 'urdu',
  /** Uzbek */
  Uzbek = 'uzbek',
  /** Venda */
  Venda = 'venda',
  /** Vietnamese */
  Vietnamese = 'vietnamese',
  /** Walloon */
  Walloon = 'walloon',
  /** Welsh */
  Welsh = 'welsh',
  /** Wolof */
  Wolof = 'wolof',
  /** Xhosa */
  Xhosa = 'xhosa',
  /** Yiddish */
  Yiddish = 'yiddish',
  /** Yoruba */
  Yoruba = 'yoruba',
  /** Zulu */
  Zulu = 'zulu',
}

export enum DoctorSpecialtyGroup {
  /** Acupuncturist */
  Acupuncturist = 'acupuncturist',
  /** Allergist */
  Allergist = 'allergist',
  /** Audiologist (Hearing Aids) */
  AudiologistHearingAids = 'audiologist_hearing_aids',
  /** Cardiologist */
  Cardiologist = 'cardiologist',
  /** Chiropractor */
  Chiropractor = 'chiropractor',
  /** Dentists and Dental Specialists */
  DentistsAndDentalSpecialists = 'dentists_and_dental_specialists',
  /** Dermatologist */
  Dermatologist = 'dermatologist',
  /** Dietician / Nutritionist */
  DieticianNutritionist = 'dietician_nutritionist',
  /** Ear, Nose, & Throat Doctor (ENT/Otolaryngologist) */
  EarNoseThroatDoctorEntOtolaryngologist = 'ear_nose_throat_doctor_ent_otolaryngologist',
  /** Endocrinologist (Hormones, Thyroid, Osteoporosis, and/or Diabetes) */
  EndocrinologistHormonesThyroidOsteoporosisAndOrDiabetes = 'endocrinologist_hormones_thyroid_osteoporosis_and_or_diabetes',
  /** Endodontist */
  Endodontist = 'endodontist',
  /** Eye Doctors */
  EyeDoctors = 'eye_doctors',
  /** Gastroenterologist (Colonoscopy) */
  GastroenterologistColonoscopy = 'gastroenterologist_colonoscopy',
  /** General Dentist */
  GeneralDentist = 'general_dentist',
  /** General OBGYN */
  GeneralObgyn = 'general_obgyn',
  /** General Surgeon */
  GeneralSurgeon = 'general_surgeon',
  /** Gynecologist */
  Gynecologist = 'gynecologist',
  /** Hematologist (Blood Specialist) */
  HematologistBloodSpecialist = 'hematologist_blood_specialist',
  /** Infectious Disease Specialist */
  InfectiousDiseaseSpecialist = 'infectious_disease_specialist',
  /** Mental Health Providers */
  MentalHealthProviders = 'mental_health_providers',
  /** Nephrologist (Kidney Specialist) */
  NephrologistKidneySpecialist = 'nephrologist_kidney_specialist',
  /** Neurologist (Headache and Nervous System) */
  NeurologistHeadacheAndNervousSystem = 'neurologist_headache_and_nervous_system',
  /** Neurosurgeon */
  Neurosurgeon = 'neurosurgeon',
  /** Obsetrician */
  Obsetrician = 'obsetrician',
  /** Obstetrician-Gynecologists (OBGYNs) */
  ObstetricianGynecologistsObgyns = 'obstetrician_gynecologists_obgyns',
  /** Occupational Therapy */
  OccupationalTherapy = 'occupational_therapy',
  /** Oncologist (Cancer Specialist) */
  OncologistCancerSpecialist = 'oncologist_cancer_specialist',
  /** Ophthalmologist */
  Ophthalmologist = 'ophthalmologist',
  /** Optometrist */
  Optometrist = 'optometrist',
  /** Oral Surgeon */
  OralSurgeon = 'oral_surgeon',
  /** Orthodontist */
  Orthodontist = 'orthodontist',
  /** Orthopedist/Orthopedic Surgeon */
  OrthopedistOrthopedicSurgeon = 'orthopedist_orthopedic_surgeon',
  /** Pain Medicine Specialist */
  PainMedicineSpecialist = 'pain_medicine_specialist',
  /** Pediatricians */
  Pediatricians = 'pediatricians',
  /** Periodontist */
  Periodontist = 'periodontist',
  /** Physiatrist (Physical Medicine) */
  PhysiatristPhysicalMedicine = 'physiatrist_physical_medicine',
  /** Physical Therapy */
  PhysicalTherapy = 'physical_therapy',
  /** Plastic / Reconstructive Surgeon */
  PlasticReconstructiveSurgeon = 'plastic_reconstructive_surgeon',
  /** Podiatrist (Foot/Ankle Specialist) */
  PodiatristFootAnkleSpecialist = 'podiatrist_foot_ankle_specialist',
  /** Primary Care Physicians (PCPs) */
  PrimaryCarePhysiciansPcps = 'primary_care_physicians_pcps',
  /** Proctologist / Colorectal Surgeon */
  ProctologistColorectalSurgeon = 'proctologist_colorectal_surgeon',
  /** Prosthodontist */
  Prosthodontist = 'prosthodontist',
  /** Psychiatrist / Medication Management */
  PsychiatristMedicationManagement = 'psychiatrist_medication_management',
  /** Psychologist / Therapist */
  PsychologistTherapist = 'psychologist_therapist',
  /** Pulmonologist (Lung Specialist) */
  PulmonologistLungSpecialist = 'pulmonologist_lung_specialist',
  /** Reproductive Endocrinologist */
  ReproductiveEndocrinologist = 'reproductive_endocrinologist',
  /** Reproductive Endocrinologist (Fertility Specialist) */
  ReproductiveEndocrinologistFertilitySpecialist = 'reproductive_endocrinologist_fertility_specialist',
  /** Rheumatologist (Arthritis and/or Autoimmune Conditions) */
  RheumatologistArthritisAndOrAutoimmuneConditions = 'rheumatologist_arthritis_and_or_autoimmune_conditions',
  /** Sleep Specialist */
  SleepSpecialist = 'sleep_specialist',
  /** Speech Therapy */
  SpeechTherapy = 'speech_therapy',
  /** Surgeons */
  Surgeons = 'surgeons',
  /** Urologist */
  Urologist = 'urologist',
  /** Vascular Surgeon */
  VascularSurgeon = 'vascular_surgeon',
}

export interface DentalCareAttributes {
  group_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export enum DisclaimerType {
  /** ERROR */
  Error = 'ERROR',
  /** SUCCESS */
  Success = 'SUCCESS',
  /** UNSPECIFIED_PRIORITY */
  UnspecifiedPriority = 'UNSPECIFIED_PRIORITY',
  /** WARNING */
  Warning = 'WARNING',
}

export interface DoctorFilterInputType {
  /** Address for distance calculation. Incomplete US address is allowed. */
  address?: InputMaybe<AddressStringInput>
  /** Geolocation coordinates for distance calculation. Overrides `address`. */
  location?: InputMaybe<LatitudeLongitudeInput>
  /**
   * If a member was doing a care search, we'd show costs & tiers based on their search.
   * For example, the doctor is a neurologist and psychiatrist.
   * - when a member finds him in the "Mental Health" category - no price or tier.
   * - when a member finds him in the "Neurologists" category - show price and tier.
   */
  specialty?: InputMaybe<DoctorSpecialtyGroup>
}

export interface DoctorsFilterInputType {
  /** Search doctors near the address. Can be incomplete US address. */
  address?: InputMaybe<AddressStringInput>
  /** Limit radius of location/address search. */
  distance?: InputMaybe<Scalars['Int']['input']>
  /** Filter doctors by gender. */
  gender?: InputMaybe<DoctorGenderType>
  /** Filter doctors by supported insurance benefits tier. */
  insurance_tier?: InputMaybe<Array<InsuranceTierType>>
  /** Filter doctors by supported insurance tier. */
  insurance_tiers?: InputMaybe<Array<Scalars['String']['input']>>
  /** Filter doctors by language they spoke. */
  language?: InputMaybe<DoctorLanguageType>
  /** Search for doctors near particular geolocation coordinates. Will override address. */
  location?: InputMaybe<LatitudeLongitudeInput>
  /** Search doctor by its first/last name. */
  name?: InputMaybe<Scalars['String']['input']>
  /** Filter doctors by their minimim rating. Should be in range 0.0..5.0 */
  rating?: InputMaybe<Scalars['Float']['input']>
  /** Search doctors by specialties group. */
  specialty?: InputMaybe<DoctorSpecialtyGroup>
}

export interface DrugConfigurationInput {
  daysSupply: Scalars['Float']['input']
  gpi: Scalars['String']['input']
  ndc: Scalars['String']['input']
  quantity: Scalars['Float']['input']
}

export interface DrugInformationInput {
  dosage?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  package?: InputMaybe<Scalars['String']['input']>
  pharmacy_name?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Float']['input']>
  supply?: InputMaybe<Scalars['Int']['input']>
}

export enum EmployeeAccessibility {
  /** Eligible */
  Eligible = 'eligible',
  /** Registered */
  Registered = 'registered',
  /** Set password required */
  SetPasswordRequired = 'set_password_required',
}

export enum Event {
  /** Object was added to subscription scope. */
  Added = 'added',
  /** New object was created. */
  Created = 'created',
  /** Object was deleted. */
  Deleted = 'deleted',
  /** Object was removed from subscription scope. */
  Removed = 'removed',
  /** Object was updated. */
  Updated = 'updated',
}

export enum EventDetails {
  AppointmentAlertMuted = 'appointment_alert_muted',
  AppointmentApproved = 'appointment_approved',
  AppointmentCancellationFinished = 'appointment_cancellation_finished',
  AppointmentCancelled = 'appointment_cancelled',
  AppointmentCompleted = 'appointment_completed',
  AppointmentConfirmed = 'appointment_confirmed',
  AppointmentCreated = 'appointment_created',
  AppointmentFirstAvailableRequested = 'appointment_first_available_requested',
  AppointmentRejected = 'appointment_rejected',
  AppointmentSuggested = 'appointment_suggested',
  BillClaimAlertMuted = 'bill_claim_alert_muted',
  BillClaimCreated = 'bill_claim_created',
  BillClaimFileUploaded = 'bill_claim_file_uploaded',
  BillClaimResolved = 'bill_claim_resolved',
  BillClaimStarted = 'bill_claim_started',
  BillClaimStopped = 'bill_claim_stopped',
  BillClaimUpdated = 'bill_claim_updated',
  MessageAlertMuted = 'message_alert_muted',
  MessageCreated = 'message_created',
  MessageDeleted = 'message_deleted',
  MessageEdited = 'message_edited',
  MessageRead = 'message_read',
}

export enum EventType {
  /** Appointment activities changed. */
  AppointmentActivity = 'appointment_activity',
  /** Chat activities changed. */
  ChatActivity = 'chat_activity',
  /** Claim activities changed. */
  ClaimActivity = 'claim_activity',
  /** Roadmap activities changed. */
  RoadmapActivity = 'roadmap_activity',
}

/** A list of possible facility types in Member app. */
export enum FacilityCategory {
  /** Ambulatory Surgery Center */
  AmbulatorySurgeryCenter = 'ambulatory_surgery_center',
  /** Assisted Living Facility */
  AssistedLivingFacility = 'assisted_living_facility',
  /** Clinic */
  Clinic = 'clinic',
  /** Clinic - Dental */
  ClinicDental = 'clinic_dental',
  /** Clinic - Vision */
  ClinicVision = 'clinic_vision',
  /** Dialysis Center */
  DialysisCenter = 'dialysis_center',
  /** Emergency Room */
  EmergencyRoom = 'emergency_room',
  /** Hospital */
  Hospital = 'hospital',
  /** Hospital - Children Chronic Disease */
  HospitalChildrenChronicDisease = 'hospital_children_chronic_disease',
  /** Hospital - Children General Acute Care */
  HospitalChildrenGeneralAcuteCare = 'hospital_children_general_acute_care',
  /** Hospital - Children Rehabilitation */
  HospitalChildrenRehabilitation = 'hospital_children_rehabilitation',
  /** Hospital - Chronic Disease */
  HospitalChronicDisease = 'hospital_chronic_disease',
  /** Hospital - General Acute Care */
  HospitalGeneralAcuteCare = 'hospital_general_acute_care',
  /** Hospital - Long Term Care */
  HospitalLongTermCare = 'hospital_long_term_care',
  /** Hospital - Psychiatric */
  HospitalPsychiatric = 'hospital_psychiatric',
  /** Hospital - Rehabilitation */
  HospitalRehabilitation = 'hospital_rehabilitation',
  /** Imaging Center */
  ImagingCenter = 'imaging_center',
  /** Independent Clinic */
  IndependentClinic = 'independent_clinic',
  /** Laboratory - Dental */
  LaboratoryDental = 'laboratory_dental',
  /** Laboratory - Medical */
  LaboratoryMedical = 'laboratory_medical',
  /** Laboratory - Physiological */
  LaboratoryPhysiological = 'laboratory_physiological',
  /** Pharmacy */
  Pharmacy = 'pharmacy',
  /** Physical Therapy */
  PhysicalTherapy = 'physical_therapy',
  /** Primary Care Clinic */
  PrimaryCareClinic = 'primary_care_clinic',
  /** Residential Treatment Facility - Mental or Developmental Disabilities */
  ResidentialTreatmentFacilityMentalOrDevelopmentalDisabilities = 'residential_treatment_facility_mental_or_developmental_disabilities',
  /** Residential Treatment Facility - Physical Disabilities */
  ResidentialTreatmentFacilityPhysicalDisabilities = 'residential_treatment_facility_physical_disabilities',
  /** Residential Treatment Facility - Psychiatric */
  ResidentialTreatmentFacilityPsychiatric = 'residential_treatment_facility_psychiatric',
  /** Residential Treatment Facility - Substance Abuse */
  ResidentialTreatmentFacilitySubstanceAbuse = 'residential_treatment_facility_substance_abuse',
  /** Retail Health Clinic */
  RetailHealthClinic = 'retail_health_clinic',
  /** Skilled Nursing Facility */
  SkilledNursingFacility = 'skilled_nursing_facility',
  /** Skilled Nursing Facility - Pediatric */
  SkilledNursingFacilityPediatric = 'skilled_nursing_facility_pediatric',
  /** Urgent Care */
  UrgentCare = 'urgent_care',
}

export enum FeedItemCardType {
  /** Appointment approved */
  AppointmentApproved = 'appointment_approved',
  /** Appointment cancellation finished */
  AppointmentCancellationFinished = 'appointment_cancellation_finished',
  /** Appointment cancelled */
  AppointmentCancelled = 'appointment_cancelled',
  /** Appointment completed */
  AppointmentCompleted = 'appointment_completed',
  /** Appointment confirmed */
  AppointmentConfirmed = 'appointment_confirmed',
  /** Appointment rejected */
  AppointmentRejected = 'appointment_rejected',
  /** Appointment reminder */
  AppointmentReminder = 'appointment_reminder',
  /** Appointment requested */
  AppointmentRequested = 'appointment_requested',
  /** Appointment suggested */
  AppointmentSuggested = 'appointment_suggested',
  /** Appointment ticket closed */
  AppointmentTicketClosed = 'appointment_ticket_closed',
  /** Billing advocacy archived */
  BillingAdvocacyArchived = 'billing_advocacy_archived',
  /** Billing advocacy request */
  BillingAdvocacyRequest = 'billing_advocacy_request',
  /** Billing advocacy request in progress */
  BillingAdvocacyRequestInProgress = 'billing_advocacy_request_in_progress',
  /** Billing advocacy request pending */
  BillingAdvocacyRequestPending = 'billing_advocacy_request_pending',
  /** Billing advocacy updated */
  BillingAdvocacyUpdated = 'billing_advocacy_updated',
  /** Billing resolved successfully */
  BillingResolvedSuccessfully = 'billing_resolved_successfully',
  /** Billing resolved with error */
  BillingResolvedWithError = 'billing_resolved_with_error',
  /** Billing ticket closed resolved */
  BillingTicketClosedResolved = 'billing_ticket_closed_resolved',
  /** Billing ticket in progress */
  BillingTicketInProgress = 'billing_ticket_in_progress',
  /** Care plan archived */
  CarePlanArchived = 'care_plan_archived',
  /** Care plan created */
  CarePlanCreated = 'care_plan_created',
  /** Care plan submitted */
  CarePlanSubmitted = 'care_plan_submitted',
  /** Care plan updated */
  CarePlanUpdated = 'care_plan_updated',
  /** Care program new */
  CareProgramNew = 'care_program_new',
  /** Chat created */
  ChatCreated = 'chat_created',
  /** Chat new message */
  ChatNewMessage = 'chat_new_message',
  /** Chat unread */
  ChatUnread = 'chat_unread',
  /** Chat updated */
  ChatUpdated = 'chat_updated',
  /** Demo account invite */
  DemoAccountInvite = 'demo_account_invite',
  /** Download app invite */
  DownloadAppInvite = 'download_app_invite',
  /** Mail order transfer created */
  MailOrderTransferCreated = 'mail_order_transfer_created',
  /** Mail order transfer successful */
  MailOrderTransferSuccessful = 'mail_order_transfer_successful',
  /** Medication new */
  MedicationNew = 'medication_new',
  /** Medication new mail order opportunity */
  MedicationNewMailOrderOpportunity = 'medication_new_mail_order_opportunity',
  /** Medication ready to refill */
  MedicationReadyToRefill = 'medication_ready_to_refill',
  /** Request HIPAA auth */
  RequestHipaaAuth = 'request_hipaa_auth',
  /** Send email confirmation */
  SendEmailConfirmation = 'send_email_confirmation',
  /** Send email verify code */
  SendEmailVerifyCode = 'send_email_verify_code',
  /** Send invitation */
  SendInvitation = 'send_invitation',
  /** Send SMS */
  SendSms = 'send_sms',
  /** Ticket closed resolved */
  TicketClosedResolved = 'ticket_closed_resolved',
  /** Ticket closed unresolved */
  TicketClosedUnresolved = 'ticket_closed_unresolved',
  /** Wellness form approved by health guide */
  WellnessFormApprovedByHealthGuide = 'wellness_form_approved_by_health_guide',
  /** Wellness form submit by member */
  WellnessFormSubmitByMember = 'wellness_form_submit_by_member',
}

export enum FeedItemCategory {
  Appointment = 'appointment',
  BillingAdvocacy = 'billing_advocacy',
  CarePlan = 'care_plan',
  CareProgram = 'care_program',
  MailOrderTransfer = 'mail_order_transfer',
  Medication = 'medication',
}

export enum FocusRequestType {
  /** Disputing a charge */
  DisputingACharge = 'disputing_a_charge',
  /** Other */
  Other = 'other',
  /** Requesting information about my coverage */
  RequestingInformationAboutMyCoverage = 'requesting_information_about_my_coverage',
  /** Submit an out-of-network claim for reimbursement */
  SubmittingOutOfNetworkForReimbursement = 'submitting_out_of_network_for_reimbursement',
}

export interface FacilitiesFilterInputType {
  /**
   * Search facilities near the address. Incomplete US address allowed. Ribbon
   * defaults address to the NY center in their searches, unless you provide
   * address or name argument.
   */
  address?: InputMaybe<AddressStringInput>
  categories?: InputMaybe<Array<FacilityCategory>>
  /** Search radius (in miles) */
  distance?: InputMaybe<Scalars['Int']['input']>
  /** Filter by the available network options of the insurance plan. */
  insurance_tier?: InputMaybe<Array<InsuranceTierType>>
  /** Search facilities near particular geolocation coordinates. Will override address. */
  location?: InputMaybe<LatitudeLongitudeInput>
  min_confidence?: InputMaybe<Scalars['Int']['input']>
  /** Search facility by its name. */
  name?: InputMaybe<Scalars['String']['input']>
}

export interface FacilityFilterInputType {
  /** Address for distance calculation. Can be incomplete US address. */
  address?: InputMaybe<AddressStringInput>
  /** Geolocation coordinates for distance calculation. Will override address. */
  location?: InputMaybe<LatitudeLongitudeInput>
}

export interface FeedItemFilterInput {
  /** Show Feed Items created after this date */
  after_date?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** Show Feed Items created before this date */
  before_date?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** Feed items of which categories you want to get. */
  categories?: InputMaybe<Array<FeedItemCategory>>
  /** True: only show to_do items. False: only show non to_do items. Nil or not passed: show all */
  only_to_do?: InputMaybe<Scalars['Boolean']['input']>
  /** True: only show unseen items. False: only show seen items. Nil or not passed: show all */
  only_unseen?: InputMaybe<Scalars['Boolean']['input']>
}

/** Possible types of insurance card photos. */
export enum InsuranceCardPhotoType {
  /** Dental */
  Dental = 'dental',
  /** Medical */
  Medical = 'medical',
  /** Rx */
  Rx = 'rx',
  /** Vision */
  Vision = 'vision',
}

export enum InsurancePlanReferralType {
  /** Does Not Require */
  None = 'none',
  /** Plan Requires Referral For Specialist */
  Specialist = 'specialist',
}

export enum InsuranceTierType {
  /** Allegiance */
  Allegiance = 'allegiance',
  /** Allegiance Network */
  AllegianceNetwork = 'allegiance_network',
  /** Anthem Blue Cross of CA */
  AnthemBlueCrossOfCa = 'anthem_blue_cross_of_ca',
  /** Anthem National Network */
  AnthemNationalNetwork = 'anthem_national_network',
  /** BlueChoice Adv OA */
  BlueChoiceAdvOa = 'blue_choice_adv_oa',
  /** Blue Cross Blue Shield of AZ */
  BlueCrossBlueShieldOfAz = 'blue_cross_blue_shield_of_az',
  /** Blue Open Access POS */
  BlueOpenAccessPos = 'blue_open_access_pos',
  /** Blue Preferred POS */
  BluePreferredPos = 'blue_preferred_pos',
  /** Cedar */
  Cedar = 'cedar',
  /** Cigna */
  Cigna = 'cigna',
  /** Cigna Network */
  CignaNetwork = 'cigna_network',
  /** Community Blue Network */
  CommunityBlueNetwork = 'community_blue_network',
  /** Contact Provider */
  ContactProvider = 'contact_provider',
  /** Does Not Accept */
  DoesNotAccept = 'does_not_accept',
  /** Empire POS */
  EmpirePos = 'empire_pos',
  /** First Health */
  FirstHealth = 'first_health',
  /** Harvard Pilgrim */
  HarvardPilgrim = 'harvard_pilgrim',
  /** In Network */
  InNetwork = 'in_network',
  /** Individual In-Network */
  IndividualInNetwork = 'individual_in_network',
  /** Individual Out-of-Network */
  IndividualOutOfNetwork = 'individual_out_of_network',
  /** Multiplan */
  Multiplan = 'multiplan',
  /** Network Blue */
  NetworkBlue = 'network_blue',
  /** No Pay */
  NoPay = 'no_pay',
  None = 'none',
  /** Open Access */
  OpenAccess = 'open_access',
  /** Out of Network */
  OutOfNetwork = 'out_of_network',
  /** Oxford Freedom Network */
  OxfordFreedomNetwork = 'oxford_freedom_network',
  /** Parent & Child/Children In-Network */
  ParentInNetwork = 'parent_in_network',
  /** Parent & Child/Children Out-of-Network */
  ParentOutOfNetwork = 'parent_out_of_network',
  /** PHCS */
  Phcs = 'phcs',
  /** PPO */
  Ppo = 'ppo',
  /** Pre-negotiated */
  PreNegotiated = 'pre_negotiated',
  /** Preferred Care Blue */
  PreferredCareBlue = 'preferred_care_blue',
  /** Tier I */
  Tier1 = 'tier_1',
  /** Tier 1 Associate */
  Tier1Associate = 'tier_1_associate',
  /** Tier 1 Associate +1 */
  Tier1AssociatePlus1 = 'tier_1_associate_plus_1',
  /** Tier 1 Family */
  Tier1Family = 'tier_1_family',
  /** Tier I - Level 1A */
  Tier1Lvl1a = 'tier_1_lvl_1a',
  /** Tier II */
  Tier2 = 'tier_2',
  /** Tier 2 Associate */
  Tier2Associate = 'tier_2_associate',
  /** Tier 2 Associate +1 */
  Tier2AssociatePlus1 = 'tier_2_associate_plus_1',
  /** Tier 2 Family */
  Tier2Family = 'tier_2_family',
  /** Tier II - Level 1B */
  Tier2Lvl1b = 'tier_2_lvl_1b',
  /** Tier III */
  Tier3 = 'tier_3',
  /** Tier 3 Associate */
  Tier3Associate = 'tier_3_associate',
  /** Tier 3 Associate +1 */
  Tier3AssociatePlus1 = 'tier_3_associate_plus_1',
  /** Tier 3 Family */
  Tier3Family = 'tier_3_family',
  /** Tier III - Level 2 */
  Tier3Lvl2 = 'tier_3_lvl_2',
  /** Tier IV */
  Tier4 = 'tier_4',
  /** Tier IV - Level 3 */
  Tier4Lvl3 = 'tier_4_lvl_3',
  /** Tier V */
  Tier5 = 'tier_5',
  /** Tier V In-network */
  Tier5InNetwork = 'tier_5_in_network',
  /** Tier V - Level 4 CHI/Prov/Swed */
  Tier5Lvl4 = 'tier_5_lvl_4',
  /** Tier V Out-of-network */
  Tier5OutOfNetwork = 'tier_5_out_of_network',
  /** Tier VI */
  Tier6 = 'tier_6',
  /** Two Person/Family In-Network */
  TwoPersonInNetwork = 'two_person_in_network',
  /** Two Person/Family Out-of-Network */
  TwoPersonOutOfNetwork = 'two_person_out_of_network',
  /** UHC Choice Plus */
  UhcChoicePlus = 'uhc_choice_plus',
  /** UHC PPO */
  UhcPpo = 'uhc_ppo',
}

export enum InsuranceType {
  Dental = 'dental',
  Medical = 'medical',
  Pharmacy = 'pharmacy',
  Vision = 'vision',
}

export enum InfoContentType {
  Paragraph = 'PARAGRAPH',
  Title = 'TITLE',
}

export interface InsuranceCardAttributesInput {
  custom_id?: InputMaybe<Scalars['String']['input']>
}

export interface InsurancePlanFilterInput {
  categories?: InputMaybe<Array<InsuranceType>>
}

/** Possible location kinds. */
export enum LocationKindType {
  /** home_address */
  HomeAddress = 'home_address',
  /** other_address */
  OtherAddress = 'other_address',
  /** work_address */
  WorkAddress = 'work_address',
}

export interface LatitudeLongitudeInput {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export interface LocationAttributesInput {
  address_line_1?: InputMaybe<Scalars['String']['input']>
  address_line_2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  is_preferred: Scalars['Boolean']['input']
  kind: LocationKindType
  latitude?: InputMaybe<Scalars['Float']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<UsStateName>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** Possible statuses for mail order transfer. */
export enum MailOrderTransferStatusType {
  /** in_progress */
  InProgress = 'in_progress',
  /** pending */
  Pending = 'pending',
  /** resolved */
  Resolved = 'resolved',
  /** unresolved */
  Unresolved = 'unresolved',
}

/** Medication attribute. */
export enum MedicationAttributeType {
  /** brand */
  Brand = 'brand',
  /** generic */
  Generic = 'generic',
  /** maintenance */
  Maintenance = 'maintenance',
  /** non_maintenance */
  NonMaintenance = 'non_maintenance',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Medication categories. */
export enum MedicationCategory {
  /** active */
  Active = 'active',
  /** past */
  Past = 'past',
  /** pending */
  Pending = 'pending',
}

/** Medication info item type. */
export enum MedicationInfoItemType {
  /** paragraph */
  Paragraph = 'paragraph',
  /** title */
  Title = 'title',
}

/** Medication refill status. */
export enum MedicationRefillStatusType {
  /** no_refills */
  NoRefills = 'no_refills',
  /** ready_to_refill */
  ReadyToRefill = 'ready_to_refill',
  /** too_early */
  TooEarly = 'too_early',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Possible member genders */
export enum MemberGenderType {
  /** Female */
  F = 'f',
  /** Male */
  M = 'm',
  /** Other */
  O = 'o',
  /** Undefined */
  U = 'u',
}

export enum MemberRelationToInsuredType {
  Dependent = 'dependent',
  Main = 'main',
  Pending = 'pending',
  Spouse = 'spouse',
}

export interface MailOrderTransferAttributesInput {
  /** Marc Cuban Cost Plus account exists */
  mccp_account_exists: Scalars['Boolean']['input']
  medications?: InputMaybe<
    Array<InputMaybe<MailOrderTransferMedicationAttributesInput>>
  >
}

export interface MailOrderTransferMedicationAttributesInput {
  /** Doctor record ID. */
  doctor_id?: InputMaybe<Scalars['ID']['input']>
  /** Doctor name. */
  doctor_name?: InputMaybe<Scalars['String']['input']>
  /** Doctor phone number. */
  doctor_phone_number?: InputMaybe<Scalars['String']['input']>
  /** Medication ID. */
  medication_id?: InputMaybe<Scalars['ID']['input']>
  /** Medication name. */
  medication_name: Scalars['String']['input']
}

export interface MedicalBillAttributesInput {
  delete?: InputMaybe<Scalars['Boolean']['input']>
  file?: InputMaybe<Scalars['ApolloFileUploadInput']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export interface MedicalCareAttributes {
  group_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  referral?: InputMaybe<InsurancePlanReferralType>
}

export enum MedicationAttribute {
  Brand = 'BRAND',
  Generic = 'GENERIC',
  Maintenance = 'MAINTENANCE',
  NonMaintenance = 'NON_MAINTENANCE',
  UnspecifiedMedicationAttribute = 'UNSPECIFIED_MEDICATION_ATTRIBUTE',
}

export enum MedicationsStatus {
  Active = 'ACTIVE',
  Past = 'PAST',
  Pending = 'PENDING',
  UnspecifiedMedicationStatus = 'UNSPECIFIED_MEDICATION_STATUS',
}

export enum NavigatorImageVersions {
  /** original image version. */
  Original = 'original',
  /** small image version. */
  Small = 'small',
}

export enum PartnerCardImageVersions {
  /** large image version. */
  Large = 'large',
  /** normal image version. */
  Normal = 'normal',
  /** original image version. */
  Original = 'original',
}

export enum PartnerCardTitle {
  /** Benefits Portal */
  BenefitsPortal = 'BENEFITS_PORTAL',
  /** Benefit Support */
  BenefitSupport = 'BENEFIT_SUPPORT',
  /** Caregiving Support */
  CaregivingSupport = 'CAREGIVING_SUPPORT',
  /** Carrier */
  Carrier = 'CARRIER',
  /** Case Management */
  CaseManagement = 'CASE_MANAGEMENT',
  /** Critical Illness/Accident */
  CriticalIllnessAccident = 'CRITICAL_ILLNESS_ACCIDENT',
  /** Dental */
  Dental = 'DENTAL',
  /** Diabetes */
  Diabetes = 'DIABETES',
  /** Disability */
  Disability = 'DISABILITY',
  /** Discount Programs */
  DiscountPrograms = 'DISCOUNT_PROGRAMS',
  /** Education */
  Education = 'EDUCATION',
  /** Employee Assistance Program */
  EmployeeAssistanceProgram = 'EMPLOYEE_ASSISTANCE_PROGRAM',
  /** Female Wellness */
  FemaleWellness = 'FEMALE_WELLNESS',
  /** Fertility */
  Fertility = 'FERTILITY',
  /** Finances */
  Finances = 'FINANCES',
  /** Fitness */
  Fitness = 'FITNESS',
  /** Flex/Commuter */
  FlexCommuter = 'FLEX_COMMUTER',
  /** FMLA */
  Fmla = 'FMLA',
  /** 401 (k) (Retirement) */
  FourOOneKRetirement = 'FOUR_O_ONE_K_RETIREMENT',
  /** FSA */
  Fsa = 'FSA',
  /** GAP Insurance */
  GapInsurance = 'GAP_INSURANCE',
  /** Grief Counseling */
  GriefCounseling = 'GRIEF_COUNSELING',
  /** Home and Auto Insurance */
  HomeAndAutoInsurance = 'HOME_AND_AUTO_INSURANCE',
  /** Hospital Indemnity */
  HospitalIndemnity = 'HOSPITAL_INDEMNITY',
  /** HRA */
  Hra = 'HRA',
  /** HSA */
  Hsa = 'HSA',
  /** HSA/FSA */
  HsaFsa = 'HSA_FSA',
  /** Identity Theft */
  IdentityTheft = 'IDENTITY_THEFT',
  /** Investment Advice */
  InvestmentAdvice = 'INVESTMENT_ADVICE',
  /** Legal Services */
  LegalServices = 'LEGAL_SERVICES',
  /** Life Insurance */
  LifeInsurance = 'LIFE_INSURANCE',
  /** Life Support Services */
  LifeSupportServices = 'LIFE_SUPPORT_SERVICES',
  /** Long-Term Care */
  LongTermCare = 'LONG_TERM_CARE',
  /** Long-Term Disability */
  LongTermDisability = 'LONG_TERM_DISABILITY',
  /** Maternity */
  Maternity = 'MATERNITY',
  /** Medical */
  Medical = 'MEDICAL',
  /** Mental Health */
  MentalHealth = 'MENTAL_HEALTH',
  /** Musculoskeletal */
  Musculoskeletal = 'MUSCULOSKELETAL',
  /** New Mother Wellness */
  NewMotherWellness = 'NEW_MOTHER_WELLNESS',
  /** Opt-In Form */
  OptInForm = 'OPT_IN_FORM',
  /** Other */
  Other = 'OTHER',
  /** Parental Support */
  ParentalSupport = 'PARENTAL_SUPPORT',
  /** Payroll */
  Payroll = 'PAYROLL',
  /** Pediatrics */
  Pediatrics = 'PEDIATRICS',
  /** Pet Insurance */
  PetInsurance = 'PET_INSURANCE',
  /** Prescriptions */
  Prescriptions = 'PRESCRIPTIONS',
  /** Primary Care */
  PrimaryCare = 'PRIMARY_CARE',
  /** Retirement */
  Retirement = 'RETIREMENT',
  /** Second Opinion */
  SecondOpinion = 'SECOND_OPINION',
  /** Short Term Disability */
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  /** Specialty Pharmacy */
  SpecialtyPharmacy = 'SPECIALTY_PHARMACY',
  /** Speech Therapy */
  SpeechTherapy = 'SPEECH_THERAPY',
  /** Telemedicine */
  Telemedicine = 'TELEMEDICINE',
  /** Travel Assistance */
  TravelAssistance = 'TRAVEL_ASSISTANCE',
  /** Tuition Assistance Program */
  TuitionAssistanceProgram = 'TUITION_ASSISTANCE_PROGRAM',
  /** Vision */
  Vision = 'VISION',
  /** Voluntary Benefits */
  VoluntaryBenefits = 'VOLUNTARY_BENEFITS',
  /** Weight Loss */
  WeightLoss = 'WEIGHT_LOSS',
  /** Wellness */
  Wellness = 'WELLNESS',
  /** Whole Life Insurance */
  WholeLifeInsurance = 'WHOLE_LIFE_INSURANCE',
}

export enum PartnerCardType {
  OutboundSso = 'outbound_sso',
  Regular = 'regular',
}

/** Medication authorization priority. */
export enum PendingMedicationPriorityType {
  /** expedited */
  Expedited = 'expedited',
  /** standard */
  Standard = 'standard',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Medication authorization request type. */
export enum PendingMedicationRequestTypeType {
  /** first_level_appeal */
  FirstLevelAppeal = 'first_level_appeal',
  /** initial_dmr */
  InitialDmr = 'initial_dmr',
  /** initial_request */
  InitialRequest = 'initial_request',
  /** second_level_appeal */
  SecondLevelAppeal = 'second_level_appeal',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Medication authorization status reason. */
export enum PendingMedicationStatusReasonType {
  /** additional_information_required */
  AdditionalInformationRequired = 'additional_information_required',
  /** pending_aor */
  PendingAor = 'pending_aor',
  /** pending_clinical_review */
  PendingClinicalReview = 'pending_clinical_review',
  /** pending_review */
  PendingReview = 'pending_review',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Medication authorization status. */
export enum PendingMedicationStatusType {
  /** approved */
  Approved = 'approved',
  /** denied */
  Denied = 'denied',
  /** dismissed */
  Dismissed = 'dismissed',
  /** partial_approval */
  PartialApproval = 'partial_approval',
  /** pending */
  Pending = 'pending',
  /** unspecified */
  Unspecified = 'unspecified',
  /** withdrawn */
  Withdrawn = 'withdrawn',
}

export enum PharmacyCardKindType {
  /** mail_order */
  MailOrder = 'mail_order',
  /** specialty */
  Specialty = 'specialty',
}

export enum PharmacyCardStepContentType {
  /** link */
  Link = 'link',
  /** rx_card */
  RxCard = 'rx_card',
  /** text */
  Text = 'text',
}

export enum PharmacyCardType {
  OutboundSso = 'outbound_sso',
  Regular = 'regular',
}

/** Pharmacy day of week type. */
export enum PharmacyDayOfWeekType {
  /** friday */
  Friday = 'friday',
  /** monday */
  Monday = 'monday',
  /** saturday */
  Saturday = 'saturday',
  /** sunday */
  Sunday = 'sunday',
  /** thursday */
  Thursday = 'thursday',
  /** tuesday */
  Tuesday = 'tuesday',
  /** unspecified */
  Unspecified = 'unspecified',
  /** wednesday */
  Wednesday = 'wednesday',
}

/** Pharmacy payment type. */
export enum PharmacyPaymentType {
  /** coinsurance */
  Coinsurance = 'coinsurance',
  /** copay */
  Copay = 'copay',
  /** unspecified */
  Unspecified = 'unspecified',
}

/** Possible plan benefit values. */
export enum PlanBenefitValueType {
  Money = 'money',
  Percent = 'percent',
  Text = 'text',
}

export enum PlanImageVersions {
  /** large image version. */
  Large = 'large',
  /** normal image version. */
  Normal = 'normal',
  /** original image version. */
  Original = 'original',
}

export interface PasswordChangeInput {
  confirm: Scalars['String']['input']
  current: Scalars['String']['input']
  new: Scalars['String']['input']
}

export enum PbmContentStepTypeEnum {
  Link = 'link',
  RxCard = 'rx_card',
  Text = 'text',
}

export enum PbmDayName {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  UnspecifiedDayOfWeek = 'UNSPECIFIED_DAY_OF_WEEK',
  Wednesday = 'WEDNESDAY',
}

export enum PbmPaymentType {
  Coinsurance = 'COINSURANCE',
  Copay = 'COPAY',
  UnspecifiedPaymentType = 'UNSPECIFIED_PAYMENT_TYPE',
}

export enum PbmUsStateName {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
}

export enum PendingMedicationPriority {
  Expedited = 'EXPEDITED',
  Standard = 'STANDARD',
  UnspecifiedPriority = 'UNSPECIFIED_PRIORITY',
}

export enum PendingMedicationRequestType {
  FirstLevelAppeal = 'FIRST_LEVEL_APPEAL',
  InitialDmr = 'INITIAL_DMR',
  InitialRequest = 'INITIAL_REQUEST',
  SecondLevelAppeal = 'SECOND_LEVEL_APPEAL',
  UnspecifiedRequestType = 'UNSPECIFIED_REQUEST_TYPE',
}

export enum PendingMedicationStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Dismissed = 'DISMISSED',
  InProgress = 'IN_PROGRESS',
  PartiallyDenied = 'PARTIALLY_DENIED',
  UnspecifiedStatus = 'UNSPECIFIED_STATUS',
  Withdrawn = 'WITHDRAWN',
}

export enum PendingMedicationStatusReason {
  AdditionalInformationRequired = 'ADDITIONAL_INFORMATION_REQUIRED',
  PendingAor = 'PENDING_AOR',
  PendingClinicalReview = 'PENDING_CLINICAL_REVIEW',
  PendingReview = 'PENDING_REVIEW',
  UnspecifiedStatusReason = 'UNSPECIFIED_STATUS_REASON',
}

export interface PharmacyCareAttributes {
  bin?: InputMaybe<Scalars['String']['input']>
  group_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  pcn?: InputMaybe<Scalars['String']['input']>
}

export enum PharmacyTypeEnum {
  /** CVS */
  Cvs = 'CVS',
  /** MARK_CUBAN */
  MarkCuban = 'MARK_CUBAN',
  /** RETAIL */
  Retail = 'RETAIL',
  /** UNSPECIFIED_TYPE */
  UnspecifiedType = 'UNSPECIFIED_TYPE',
  /** WALGREENS */
  Walgreens = 'WALGREENS',
  /** WELLDYNE */
  Welldyne = 'WELLDYNE',
}

export enum RegistrationLookupFeedbackAssistanceType {
  /** Deactivated */
  Deactivated = 'deactivated',
  /** Ineligible */
  Ineligible = 'ineligible',
  /** Locked */
  Locked = 'locked',
  /** Verification code */
  VerificationCode = 'verification_code',
}

export enum RegistrationLookupFeedbackReferredFrom {
  /** Dependent */
  Dependent = 'dependent',
  /** Employer */
  Employer = 'employer',
}

export enum RegistrationLookupIdentifierTypes {
  /** The date input type */
  Date = 'Date',
  /** The integer input type */
  Integer = 'Integer',
  /** The string input type */
  String = 'String',
}

export enum RightwayProductsType {
  /** core */
  Core = 'core',
  /** pbm */
  Pbm = 'pbm',
  /** unity */
  Unity = 'unity',
}

export enum RefillStatus {
  NoRefills = 'NO_REFILLS',
  ReadyToRefill = 'READY_TO_REFILL',
  TooEarlyToRefill = 'TOO_EARLY_TO_REFILL',
  UnspecifiedRefillStatus = 'UNSPECIFIED_REFILL_STATUS',
}

export interface RegistrationLookupDataInput {
  /** Do you go by any other first or last names? */
  any_other_names?: InputMaybe<Scalars['String']['input']>
  assistance_type?: InputMaybe<RegistrationLookupFeedbackAssistanceType>
  /** Date of birth from the Confirm your account screen */
  dob?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** Email from the Welcome to Rightway screen */
  email: Scalars['String']['input']
  /** First Name from the Confirm your account screen */
  first_name?: InputMaybe<Scalars['String']['input']>
  /** Did you join your employer in the last 30 days? */
  join_in_the_last_30_days?: InputMaybe<Scalars['Boolean']['input']>
  /** Last Name from the Confirm your account screen */
  last_name?: InputMaybe<Scalars['String']['input']>
  member_id?: InputMaybe<Scalars['String']['input']>
  /** Company Name from the Get assistance form */
  organization_name?: InputMaybe<Scalars['String']['input']>
  /** Phone Number from the Get assistance form */
  phone_number?: InputMaybe<Scalars['String']['input']>
  /** Do you receive Rightway as a benefit from your employer or as a dependent? */
  referred_from?: InputMaybe<RegistrationLookupFeedbackReferredFrom>
  /** Have you registered with Rightway before? */
  registered?: InputMaybe<Scalars['Boolean']['input']>
  /** Last 4 digits of SSN from the We found a match screen */
  ssn?: InputMaybe<Scalars['String']['input']>
  /** Did you request another code? */
  verification_code_another_code?: InputMaybe<Scalars['Boolean']['input']>
  /** Did you receive a verification code in your email inbox? */
  verification_code_email_inbox?: InputMaybe<Scalars['Boolean']['input']>
}

export interface RegistrationLookupIdentifierInput {
  name?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export enum RejectType {
  DaysSupplyExceeds = 'DAYS_SUPPLY_EXCEEDS',
  DrugNotCovered = 'DRUG_NOT_COVERED',
  Generic = 'GENERIC',
  QuantityExceeds = 'QUANTITY_EXCEEDS',
  UnspecifiedRejectType = 'UNSPECIFIED_REJECT_TYPE',
}

export enum RejectTypeEnum {
  /** DAYS_SUPPLY_EXCEEDS */
  DaysSupplyExceeds = 'DAYS_SUPPLY_EXCEEDS',
  /** DRUG_NOT_COVERED */
  DrugNotCovered = 'DRUG_NOT_COVERED',
  /** GENERIC */
  Generic = 'GENERIC',
  /** QUANTITY_EXCEEDS */
  QuantityExceeds = 'QUANTITY_EXCEEDS',
  /** UNSPECIFIED_REJECT_TYPE */
  UnspecifiedRejectType = 'UNSPECIFIED_REJECT_TYPE',
}

export interface ResetPasswordCredentials {
  /** Numeric password reset token. */
  numeric_token?: InputMaybe<Scalars['String']['input']>
}

/** Possible survey answer kinds. */
export enum SurveyAnswerKind {
  Doctor = 'doctor',
  Email = 'email',
  Height = 'height',
  Inherit = 'inherit',
  Phone = 'phone',
  Text = 'text',
  Weight = 'weight',
}

/** Possible survey question kinds. */
export enum SurveyQuestionKind {
  Address = 'address',
  Boolean = 'boolean',
  Checkbox = 'checkbox',
  Email = 'email',
  Height = 'height',
  Phone = 'phone',
  Radio = 'radio',
  Text = 'text',
  Weight = 'weight',
}

export interface SpecificSlotInput {
  /** Date of the time-slot. */
  date: Scalars['ISO8601Date']['input']
  /** Seconds since midnight to a end of the time-block. */
  end: Scalars['Int']['input']
  /** Seconds since midnight to a start of the time-block. */
  start: Scalars['Int']['input']
}

export interface SurveyUserAnswerInput {
  /** ID of the answer. */
  id?: InputMaybe<Scalars['ID']['input']>
  /** What user has selected or typed. */
  user_answer_value: Scalars['String']['input']
}

/** Possible types of agreement. */
export enum TermsOfServiceAgreementKindType {
  /** href */
  Href = 'href',
  /** inline */
  Inline = 'inline',
  /** modal */
  Modal = 'modal',
}

export enum TierType {
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2',
  Tier3 = 'TIER_3',
  Tier4 = 'TIER_4',
  Tier5 = 'TIER_5',
  Tier6 = 'TIER_6',
}

export interface TimeBlockSlotInput {
  /** List of preferred days. */
  days: Array<DayName>
  /** Seconds since midnight to a end of the time-block. */
  end: Scalars['Int']['input']
  /** Seconds since midnight to a start of the time-block. */
  start: Scalars['Int']['input']
}

/** Possible upload scan statuses. */
export enum UploadFileScanStatus {
  Clean = 'clean',
  Infected = 'infected',
  InvalidLink = 'invalid_link',
  NotChecked = 'not_checked',
  SanitizationFailed = 'sanitization_failed',
  Sanitized = 'sanitized',
  Started = 'started',
}

export enum UploadFileType {
  /** Card photo */
  CardPhoto = 'card_photo',
  /** File attachment in chat */
  ChatAttachment = 'chat_attachment',
  /** Medical bill */
  MedicalBill = 'medical_bill',
  /** Wellness form attachment */
  WellnessAttachment = 'wellness_attachment',
}

export enum UsStateName {
  /** Alaska */
  Ak = 'AK',
  /** Alabama */
  Al = 'AL',
  /** Arkansas */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Arizona */
  Az = 'AZ',
  /** California */
  Ca = 'CA',
  /** Colorado */
  Co = 'CO',
  /** Connecticut */
  Ct = 'CT',
  /** District Of Columbia */
  Dc = 'DC',
  /** Delaware */
  De = 'DE',
  /** Florida */
  Fl = 'FL',
  /** Federated States Of Micronesia */
  Fm = 'FM',
  /** Georgia */
  Ga = 'GA',
  /** Guam */
  Gu = 'GU',
  /** Hawaii */
  Hi = 'HI',
  /** Iowa */
  Ia = 'IA',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Indiana */
  In = 'IN',
  /** Kansas */
  Ks = 'KS',
  /** Kentucky */
  Ky = 'KY',
  /** Louisiana */
  La = 'LA',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Maine */
  Me = 'ME',
  /** Marshall Islands */
  Mh = 'MH',
  /** Michigan */
  Mi = 'MI',
  /** Minnesota */
  Mn = 'MN',
  /** Missouri */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Nebraska */
  Ne = 'NE',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** Nevada */
  Nv = 'NV',
  /** New York */
  Ny = 'NY',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Oregon */
  Or = 'OR',
  /** Pennsylvania */
  Pa = 'PA',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palau */
  Pw = 'PW',
  /** Rhode Island */
  Ri = 'RI',
  /** South Carolina */
  Sc = 'SC',
  /** South Dakota */
  Sd = 'SD',
  /** Tennessee */
  Tn = 'TN',
  /** Texas */
  Tx = 'TX',
  /** Utah */
  Ut = 'UT',
  /** Virginia */
  Va = 'VA',
  /** Virgin Islands */
  Vi = 'VI',
  /** Vermont */
  Vt = 'VT',
  /** Washington */
  Wa = 'WA',
  /** Wisconsin */
  Wi = 'WI',
  /** West Virginia */
  Wv = 'WV',
  /** Wyoming */
  Wy = 'WY',
}

export interface UpdateCardPhotoInput {
  back?: InputMaybe<Scalars['ApolloFileUploadInput']['input']>
  front?: InputMaybe<Scalars['ApolloFileUploadInput']['input']>
  type?: InsuranceCardPhotoType
}

export interface VisionCareAttributes {
  group_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export enum WellnessFormType {
  /** AnnualPhysicalForm */
  AnnualPhysicalForm = 'AnnualPhysicalForm',
}

import { useQuery } from '@apollo/client'

import { HAS_CHATS_QUERY } from './graphql'

export function useHasChatsQuery() {
  const { data, loading: isLoading } = useQuery(HAS_CHATS_QUERY)

  const chats = data?.chats?.nodes ?? []

  return { hasChats: chats.length > 0, isLoading }
}

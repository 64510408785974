import { useMemo } from 'react'

import { reflect } from '@effector/reflect'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $earlyAccess } from 'src/entities/earlyAccess/model'
import { secondaryGrey } from 'src/theme/colors'
import { heading6_16, text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface DrugSearchEarlyAccessMessageProps {
  launchDate: string
}

function DrugSearchEarlyAccessMessageComponent({
  launchDate,
}: DrugSearchEarlyAccessMessageProps) {
  const { t } = useTranslation()

  const date = useMemo(
    () => moment(launchDate).format('MM/DD/YYYY'),
    [launchDate],
  )

  return (
    <Root>
      <Trans
        t={t}
        i18nKey="translation.medications.drug_search.messages.early_access"
        values={{ date }}
        components={{ launchDate: <Date /> }}
      />
    </Root>
  )
}

const Root = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;

  width: ${convertPxToRem(247)};
  padding-top: ${convertPxToRem(16)};

  color: ${secondaryGrey};
  ${text1_16};
`

const Date = styled.span`
  ${heading6_16};
`

export const DrugSearchEarlyAccessMessage = reflect({
  view: DrugSearchEarlyAccessMessageComponent,
  bind: {
    launchDate: $earlyAccess.map(
      (earlyAccess) => earlyAccess?.launchDate ?? '',
    ),
  },
})

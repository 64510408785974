import { connect } from 'react-redux'
import styled from 'styled-components'

import FeatureGuard from 'src/components/FeatureGuard'
import { FeatureFlag } from 'src/constants/featureFlags'
import { RootState } from 'src/store'
import { primaryWhite } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { CallGuideAction, CreateChatAction } from './Actions'

export function BlankActions() {
  return (
    <WrapperWithTutorial data-test="blankInboxActions">
      <CreateChatAction />

      <FeatureGuard permit={FeatureFlag.CORE_NAV}>
        <CallGuideAction call="navigator" />
      </FeatureGuard>

      <FeatureGuard permit={FeatureFlag.PBM}>
        <CallGuideAction call="pharmacist" />
      </FeatureGuard>
    </WrapperWithTutorial>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  background-color: ${primaryWhite};

  gap: ${convertPxToRem(20)};

  // padding & margin are split for "tutorial" state with highlighted actions
  padding: ${convertPxToRem(6)};
  margin: ${convertPxToRem(6, 18, 18)};

  &[data-tutorial='true'] {
    z-index: 2;

    border-radius: ${convertPxToRem(26)};
  }
`

const mapStateToProps = ({ tutorial }: RootState) => ({
  'data-tutorial': !tutorial.isCompleted && tutorial.tutorialStep === 0,
})

const connector = connect(mapStateToProps, null)
const WrapperWithTutorial = connector(Wrapper)

import { variant } from '@effector/reflect'
import { useGate } from 'effector-react'

import { $step, UserLocationsGate } from '../model/core'
import { UserLocationsStep } from '../types'

import { EditLocation } from './EditLocation'
import { LocationsList } from './LocationsList'
import { SearchLocation } from './SearchLocation'

interface UserLocationsProps {
  withDeviceLocationCard?: boolean
}

export function UserLocations({
  withDeviceLocationCard = true,
}: UserLocationsProps) {
  useGate(UserLocationsGate)

  return <Content withDeviceLocationCard={withDeviceLocationCard} />
}

const Content = variant({
  source: $step,
  cases: {
    [UserLocationsStep.List]: LocationsList,
    [UserLocationsStep.Search]: SearchLocation,
    [UserLocationsStep.Edit]: EditLocation,
  },
})

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import BRANDING_QUERY from 'src/apollo/queries/BRANDING_QUERY'
import { extractBrandingUserData } from 'src/utils/currentUserHelpers'

const useBrandingUserQuery = (slug) => {
  const {
    data,
    loading: isLoadingBranding,
    error,
  } = useQuery(BRANDING_QUERY, {
    variables: { slug },
  })
  const branding = useMemo(() => extractBrandingUserData(data), [data])

  return { branding, isLoadingBranding, error }
}

export default useBrandingUserQuery

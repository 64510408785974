import { variant } from '@effector/reflect'
import { useLocation, useNavigate, WindowLocation } from '@reach/router'
import { useGate } from 'effector-react'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { LocationChange } from 'src/features/LocationChange'
import { remap } from 'src/shared/lib/effector/remap'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { ContentWithLightNavHeader } from 'src/widgets/ContentWithLightNavHeader'
import { $$userLocations } from 'src/widgets/UserLocations'

import { PreferredPharmacyResultGate } from '../model'

import { PharmaciesContent } from './PharmaciesContent'

interface LocationState {
  isFreshSearch?: boolean
  drugName: string
}

export function DrugSearchPreferredPharmacyResult() {
  const navigate = useNavigate()
  const { state } = useLocation() as WindowLocation<LocationState>
  const { isFreshSearch, drugName } = state ?? {}

  useGate(PreferredPharmacyResultGate, { drugName })

  const t = useScopedTranslation('translation.medications.drug_search')

  const i18n = {
    title: isFreshSearch
      ? t('search_pharmacies.title')
      : t('search_pharmacies.list_title'),
    searching: t('status.searching'),
    errorMessage: t('search_pharmacies.error_message'),
  }

  const navigateToSearchScreen = () => {
    void navigate(ROUTES.DRUG_SEARCH_PHARMACY_SEARCH, {
      state: { drugName },
      replace: true,
    })
  }

  return (
    <ContentWithLightNavHeader>
      <Title>{i18n.title}</Title>
      <UpdateLocation navigateToLocation={navigateToSearchScreen} />

      <Content>
        <PharmaciesContent />
      </Content>
    </ContentWithLightNavHeader>
  )
}

const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${heading4_21};
  margin-bottom: ${convertPxToRem(24)};
`
const StyledLocationChange = styled(LocationChange)`
  padding: ${convertPxToRem(0, 0, 24)};
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: ${convertPxToRem(8)};
`
const UpdateLocation = variant({
  if: $$userLocations.$locationsReady,
  then: StyledLocationChange,
  bind: {
    zipCode: remap($$userLocations.$preferredLocation, 'zip'),
  },
})

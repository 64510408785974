import React from 'react'

import PropTypes from 'prop-types'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

function MiscIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 20 20"
    >
      <path
        fill="#2E3141"
        d="M10 10.417c2.761 0 5-2.239 5-5 0-2.762-2.239-5-5-5s-5 2.238-5 5c.003 2.76 2.24 4.997 5 5zm0-1.667c-1.84 0-3.333-1.492-3.333-3.333.001-.42.084-.837.245-1.225.023-.056.069-.1.126-.12.058-.019.122-.012.174.02 1.24.743 2.658 1.135 4.104 1.135.589 0 1.176-.067 1.75-.197.112-.024.222.047.246.159l.004.02c.01.069.015.138.017.208 0 1.84-1.492 3.333-3.333 3.333zM10 11.25c-4.37.005-7.911 3.546-7.916 7.917 0 .23.187.416.417.416h15c.23 0 .416-.186.416-.416-.005-4.37-3.546-7.912-7.916-7.917z"
      />
    </svg>
  )
}

MiscIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default MiscIcon

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'

import { connect, ConnectedProps } from 'react-redux'

import Spinner from 'src/components/Spinner'
import { RootState } from 'src/store'
import { LocationCheckStatus } from 'src/store/slices/geolocation'

interface GDPRGuardProps extends ConnectedProps<typeof connector> {
  children: React.ReactNode
  fallback: React.ReactNode
}

function GDPRGuard({ state, children, fallback }: GDPRGuardProps) {
  switch (state) {
    case LocationCheckStatus.Pending:
      return <Spinner />
    case LocationCheckStatus.Forbidden:
      return <>{fallback}</>
    default:
      return <>{children}</>
  }
}

const mapStateToProps = ({ geolocation }: RootState) => ({
  state: geolocation.locationCheck,
})

const connector = connect(mapStateToProps, null)

export default connector(GDPRGuard)

import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

import config from 'src/config'
import i18n from 'src/translations/i18n'
import tokensStorageInterface from 'src/utils/storage/tokens'

const channelName = 'ConsumerGraphQLChannel'

let actionCable: ActionCableLink | null = null

interface BuildSubscriptionsURLParams {
  token: string
  locale: string
}

function buildSubscriptionsURL({ token, locale }: BuildSubscriptionsURLParams) {
  const search = new URLSearchParams({ auth_token: token, locale })
  return `${config.WS_URL}?${search}`
}

export function instateActionCableLink() {
  if (actionCable !== null) destroyActionCableLink()

  // A dynamic URL allows us to change token without refreshing the page
  const url = () => {
    const { access: token = '' } = tokensStorageInterface.access()
    const locale = i18n.resolvedLanguage ?? 'en'

    return buildSubscriptionsURL({ token, locale })
  }

  const cable = createConsumer(url)
  actionCable = new ActionCableLink({ cable, channelName })

  return actionCable
}

export function destroyActionCableLink() {
  try {
    actionCable?.cable?.disconnect?.()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to destroy ActionCable', error)
  } finally {
    actionCable = null
  }
}

import { gql, TypedDocumentNode } from '@apollo/client'

import { InboxStatusUpdatesResult, InboxStatusUpdatesVariables } from './types'

export const INBOX_STATUS_UPDATES_SUBSCRIPTION: TypedDocumentNode<
  InboxStatusUpdatesResult,
  InboxStatusUpdatesVariables
> = gql`
  subscription inboxStatusUpdates {
    update: chatStatsEvents {
      user {
        id
        unread_chats_count
        navigator_work_schedule {
          online
        }
      }
    }
  }
`

import { useCallback } from 'react'

import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { $preferredPharmacy, DrugPrice } from 'src/entities/drugDetails'
import { remap } from 'src/shared/lib/effector/remap'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { RetailPharmacyCard } from 'src/widgets/RetailPharmacyCard'

import { DrugSearchDetailsGate } from '../../model'

import { NotAvailableMessage } from './NotAvailableMessage'

interface DrugDetailsPreferredPharmacyProps {
  preferredPharmacy: DrugPrice | null
  drugName: string
}

function DrugDetailsPreferredPharmacy({
  preferredPharmacy,
  drugName,
}: DrugDetailsPreferredPharmacyProps) {
  const { pharmacy, isRejected = false } = preferredPharmacy ?? {}
  const { nabp = '' } = pharmacy ?? {}

  const t = useScopedTranslation('translation.medications.drug_search')
  const navigate = useNavigate()

  const navigateToDetails = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_DETAILS_PHARMACY, {
      state: {
        drugName,
        pharmacyNabp: nabp,
        isExtended: true,
      },
    })
  }, [drugName, nabp, navigate])

  if (!pharmacy) {
    return null
  }

  return (
    <Section>
      <Title>{t('labels.your_preferred_pharmacy')}</Title>

      {isRejected && <NotAvailableMessage isForPreferred />}

      <RetailPharmacyCard
        onClick={navigateToDetails}
        dataTestProp="preferredPharmacy.card"
        pharmacyInfo={preferredPharmacy}
      />
    </Section>
  )
}

const Title = styled.span`
  display: flex;

  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  text-transform: uppercase;

  margin: ${convertPxToRem(16, 0, 8)};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

export const PreferredPharmacy = reflect({
  view: DrugDetailsPreferredPharmacy,
  bind: {
    preferredPharmacy: $preferredPharmacy,
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})

import { createEffect, createEvent, sample } from 'effector'

import { postpone } from 'src/shared/lib/effector/postpone'

import { trackFreshpaint } from '../lib'
import { FreshpaintEvent, FreshpaintTrackingInfo } from '../types'

import { $isFreshpaintReady } from './api'

export const freshpaintEventTracked = createEvent<FreshpaintTrackingInfo>()

export const trackFreshpaintEventFx = createEffect<
  FreshpaintTrackingInfo<FreshpaintEvent>,
  void
>()

sample({
  clock: postpone({ clock: freshpaintEventTracked, until: $isFreshpaintReady }),
  target: trackFreshpaintEventFx,
})

trackFreshpaintEventFx.use(trackFreshpaint)

import { TypedDocumentNode, gql } from '@apollo/client'

import { PharmacyRxCardsResult, PharmacyRxCardsVariables } from './types'

export const PHARMACY_RX_CARDS_QUERY: TypedDocumentNode<
  PharmacyRxCardsResult,
  PharmacyRxCardsVariables
> = gql`
  query pharmacyRxCards {
    currentUser {
      id
      firstName: first_name
      lastName: last_name
      family {
        ...dependentsRxCard
      }
      card: pharmacy_id_card {
        ...rxCard
      }
    }
  }

  fragment dependentsRxCard on FamilyMember {
    firstName: first_name
    lastName: last_name
    card: pharmacy_id_card {
      ...rxCard
    }
  }

  fragment rxCard on PharmacyIdCard {
    cardId: card_id
    rxIin: rx_iin
    rxGrp: rx_grp
    rxPcn: rx_pcn
    issuer
    memberHelpDesk: member_help_desk
    pharmacyHelpDesk: pharmacy_help_desk
  }
`

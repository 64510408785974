import React from 'react'

import styled from 'styled-components'

import { UniversalHeader } from '@widgets/Navigation'

import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ContentWithLightNavHeaderProps {
  children: React.ReactNode
  onBack?: () => void
  className?: string
}

export function ContentWithLightNavHeader({
  children,
  onBack = undefined,
  className = undefined,
}: ContentWithLightNavHeaderProps) {
  return (
    <Root className={className}>
      <Content>
        <NavigationHeader onClick={onBack} />

        {children}
      </Content>
    </Root>
  )
}

const Root = styled.div`
  padding: ${convertPxToRem(24)};

  ${mediaQueryFor.mobile} {
    padding: 0;
    display: flex;
    flex: 1;
  }
`

const Content = styled.div`
  overflow-y: scroll;
  padding: ${convertPxToRem(40)};
  width: 100%;
  height: 100%;

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 42)};
  }

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${primaryWhite};
`

const NavigationHeader = styled(UniversalHeader)`
  padding-bottom: ${convertPxToRem(26)};
`

import { useCallback } from 'react'

import { useStoreMap, useUnit } from 'effector-react'

import { facilityFavoriteToggled, $facilityPendingById } from '../model'

export function useFacilityCareTeamToggle(id: string, isFavorite: boolean) {
  const facilityToggle = useUnit(facilityFavoriteToggled)

  const isLoading = useStoreMap({
    store: $facilityPendingById,
    keys: [id],
    fn: (ids) => ids.includes(id),
  })

  const toggle = useCallback(() => {
    facilityToggle({ id, isFavorite })
  }, [id, isFavorite, facilityToggle])

  return {
    toggle,
    isLoading,
  }
}

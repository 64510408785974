import { memo, MouseEventHandler } from 'react'

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { IconSvgBase } from 'src/theme/baseStyles'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonClose40Props {
  onClick: MouseEventHandler<HTMLDivElement>
  fill?: string
  border?: string
  bgColor?: string
  withShadow?: boolean
  width?: string
  dataTestProp?: string
  disabled?: boolean
  isNotVisibleCursor?: boolean
  className?: string
}

interface RootProps {
  readonly disabled: boolean
  readonly isNotVisibleCursor: boolean
  readonly withShadow: boolean
  readonly width: string
  readonly bgColor: string
  readonly border: string
  readonly $color: string
}

function ButtonClose40({
  onClick = () => {},
  fill = secondaryGrey,
  border = `${convertPxToRem(1)}, solid ${secondaryLightGrey}`,
  bgColor = primaryWhite,
  withShadow = false,
  width = convertPxToRem(40),
  dataTestProp = 'closeButton',
  disabled = false,
  isNotVisibleCursor = false,
  className = undefined,
}: ButtonClose40Props) {
  return (
    <Root
      onClick={onClick}
      width={width}
      bgColor={bgColor}
      border={border}
      withShadow={withShadow}
      data-test={dataTestProp}
      disabled={disabled}
      $color={fill}
      isNotVisibleCursor={isNotVisibleCursor}
      className={className}
    >
      <Sprite name="nav/close" />
    </Root>
  )
}

const Root = styled.div<RootProps>`
  pointer-events: auto;
  ${IconSvgBase};
  cursor: ${({ disabled, isNotVisibleCursor }) =>
    disabled || isNotVisibleCursor ? 'inherit' : 'pointer'};

  padding: ${convertPxToRem(8)};
  color: ${({ $color }) => $color};

  & > svg {
    /* Safari SVG fix */
    width: ${convertPxToRem(24)};
    height: ${convertPxToRem(24)};
  }
`

/** @deprecated Use `NavIcon` and `Sprite` instead */
export default memo(ButtonClose40)

// eslint-disable-next-line
export const ROUTES = {
  CARE_PLANS: 'care-plans',
  CARE_PLAN: 'care-plan',
  CARE_PLANS_ACTIVE: 'active',
  CARE_PLANS_PAST: 'past',
  CARE_PLANS_NEW: 'new',
  CARE_PLAN_RESOURCES: 'resources',
  CARE_PLAN_ASSIGNEE_PROVIDER: 'assignee/provider',
  CARE_PLAN_ASSIGNEE_FACILITY: 'assignee/facility',
  CARE_PLANS_ACTIVE_PATH: 'care-plans/active',
  CARE_PLANS_PAST_PATH: 'care-plans/past',
  CARE_PLANS_NEW_PATH: 'care-plans/new',
  CARE_PLAN_MAP: 'map',
  CARE_PLAN_UPDATE_ADDRESS: 'update-address',

  HOME: 'home',
  HOME_ACTIVITY: 'activity',
  HOME_COVERAGE: 'coverage',
  HOME_COVERAGE_PATH: 'home/coverage',

  HOME_COVERAGE_EDIT: 'edit',
  HOME_COVERAGE_EDIT_PATH: 'home/coverage/edit',

  HOME_COVERAGE_PLAN: 'plan',

  HOME_COVERAGE_PLAN_MEDICAL: 'medical',
  HOME_COVERAGE_PLAN_VISION: 'vision',
  HOME_COVERAGE_PLAN_DENTAL: 'dental',
  HOME_COVERAGE_PLAN_PHARMACY: 'pharmacy',

  HOME_COVERAGE_PLAN_OVERVIEW: 'overview',
  HOME_COVERAGE_PLAN_BENEFITS: 'benefits',
  HOME_COVERAGE_PLAN_EDIT: 'edit-card',
  HOME_COVERAGE_PLAN_EMPTY: 'blank',

  HOME_COVERAGE_DENTAL_VISION_PLAN_INSURANCE: 'insurance',
  HOME_COVERAGE_DENTAL_VISION_PLAN_INSURANCE_PATH: 'plan/insurance',
  HOME_COVERAGE_DENTAL_VISION_PLAN_BENEFITS: 'benefits',
  HOME_COVERAGE_DENTAL_VISION_PLAN_BENEFITS_PATH: 'plan/benefits',

  PHARMACY_BENEFIT_DETAILS: 'pharmacy-benefit-details',
  PHARMACY_MAIL_ORDER_INFO: 'mail-order-info',
  PHARMACY_SPECIALTY_PHARMACY_INFO: 'specialty-pharmacy-info',
  PHARMACY_TUTORIAL_MODAL: 'tutorial',
  PHARMACY_FORMULARY: 'formulary',
  PHARMACY_MAIL_ORDER_INTRO_OVERLAY: 'learn-more-about-mail-order',
  PHARMACY_MAIL_ORDER_CONTACT_INFO: 'mail-order-contact-info',
  PHARMACY_SPECIALTY_PHARMACY_INTRO_OVERLAY:
    'learn-more-about-specialty-pharmacy',
  PHARMACY_SPECIALTY_PHARMACY_CONTACT_INFO: 'specialty-pharmacy-contact-info',
  PHARMACY_SPENDING: 'pharmacy-spending',
  IN_NETWORK_SPENDING: 'in-network',
  OUT_OF_NETWORK_SPENDING: 'out-of-network',

  HOME_CARE_TOOLS: 'care-tools',
  HOME_GET_CARE: 'get-care',
  HOME_ACTIVITY_PATH: 'home/activity',
  HOME_CARE_TOOLS_PATH: 'home/care-tools',
  HOME_GET_CARE_PATH: 'home/get-care',
  HOME_BILL_CLAIMS: 'home/bill-claims',
  HOME_BILL_CLAIMS_ACTIVE_PATH: 'home/bill-claims/active',
  HOME_BILL_CLAIMS_RESOLVED_PATH: 'home/bill-claims/resolved',
  HOME_MEDICATIONS: 'home/medications',
  HOME_MEDICATIONS_CABINET_PATH: 'home/medications/tabs/cabinet',
  HOME_MEDICATIONS_CARE_PATH: 'home/medications/tabs/care-complete',
  HOME_MEDICATION_DETAILS: 'home/medication-details',

  MEDICATIONS: 'medications',
  MEDICATIONS_CABINET: 'cabinet',
  MEDICATIONS_CARE: 'care-complete',
  MEDICATIONS_CABINET_TAB: 'tabs/cabinet',
  MEDICATIONS_CARE_TAB: 'tabs/care-complete',
  MEDICATIONS_CARE_COMPLETE_DETAILS: 'care-complete-details',
  MEDICATIONS_TRANSFER: 'transfer-medications',
  MEDICATION_UPDATE_ADDRESS: 'update-address',
  MEDICATIONS_CABINET_PATH: 'medications/tabs/cabinet',
  MEDICATIONS_CARE_PATH: 'medications/tabs/care-complete',
  MEDICATIONS_TRANSFER_PATH: 'medications/transfer-medications',
  MEDICATIONS_CARE_COMPLETE_DETAILS_PATH: 'medications/care-complete-details',
  MEDICATION_UPDATE_ADDRESS_PATH: 'medications/update-address',

  MEDICATION_DETAILS: 'medication-details',
  MEDICATION_DETAILS_INFO: 'info',
  MEDICATION_DETAILS_RX_HISTORY: 'rx-history',
  MEDICATION_DETAILS_PRICING: 'pricing',
  MEDICATION_DETAILS_PRICING_MAP: 'map',
  MEDICATION_DETAILS_PENDING: 'pending',
  MEDICATION_PHARMACY_DETAILS: 'medication-pharmacy-details',
  MEDICATION_DETAILS_PRICING_PHARMACY_DETAILS: 'pricing/pharmacy',

  DRUG_SEARCH_PATH: 'drug-search',
  DRUG_SEARCH: 'search',
  DRUG_SEARCH_INTRO: 'intro',
  DRUG_SEARCH_INTRO_PATH: 'search/intro',
  DRUG_SEARCH_ERROR: 'error',
  DRUG_SEARCH_ERROR_PATH: '/drug-search/error',
  DRUG_SEARCH_ALTERNATIVES: 'search/alternatives',
  DRUG_SEARCH_CONFIGURE: 'configure',
  DRUG_SEARCH_CONFIGURE_PATH: 'search/configure',
  DRUG_SEARCH_PHARMACY: 'pharmacy',
  DRUG_SEARCH_PHARMACY_SEARCH: 'search',
  DRUG_SEARCH_PHARMACY_SEARCH_PATH: 'pharmacy/search',
  DRUG_SEARCH_PHARMACY_MAP: 'map',
  DRUG_SEARCH_PHARMACY_RESULTS: 'results',
  DRUG_SEARCH_PHARMACY_RESULTS_PATH: 'pharmacy/results',
  DRUG_SEARCH_DETAILS: 'details',
  DRUG_SEARCH_DETAILS_FILTERS: 'details/filters',
  DRUG_SEARCH_DETAILS_MAP: 'details/map',
  DRUG_SEARCH_DETAILS_PHARMACY: 'details/pharmacy',

  BILL_CLAIMS: 'bill-claims',
  BILL_CLAIMS_ACTIVE: 'active',
  BILL_CLAIMS_RESOLVED: 'resolved',
  BILL_CLAIMS_NEW: 'new-bill-claim',
  BILL_CLAIM: 'bill-claim',
  BILL_CLAIMS_ACTIVE_PATH: 'bill-claims/active',
  BILL_CLAIMS_RESOLVED_PATH: 'bill-claims/past',

  PROFILE: 'profile',
  PROFILE_HEALTH: 'health',
  PROFILE_DEPENDENTS: 'dependents',
  PROFILE_DEPENDENT: 'dependent',
  PROFILE_ACCOUNT_SETTINGS: 'account',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_EMAIL: 'account/update-email',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_PHONE: 'account/update-phone',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_ADDRESS: 'account/update-address',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_PASSWORD: 'account/update-password',

  PROFILE_HEALTH_PATH: 'profile/health',
  PROFILE_LEAVE_FEEDBACK: 'leave-feedback',
  PROFILE_LEAVE_FEEDBACK_PATH: 'profile/leave-feedback',
  PROFILE_ACCOUNT_SETTINGS_PATH: 'profile/account',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_EMAIL_PATH: 'profile/account/update-email',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_PHONE_PATH: 'profile/account/update-phone',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_ADDRESS_PATH:
    'profile/account/update-address',
  PROFILE_ACCOUNT_SETTINGS_UPDATE_PASSWORD_PATH:
    'profile/account/update-password',
  PROFILE_DEPENDENTS_PATH: 'profile/dependents',
  PROFILE_DEPENDENT_PATH: `profile/dependent`,

  PROFILE_WELLNESS: `wellness`,
  PROFILE_WELLNESS_SUBMIT: `submit`,
  PROFILE_WELLNESS_COMPLETED: `completed`,
  PROFILE_WELLNESS_PROGRAM_PATH: `profile/wellness`,
  PROFILE_WELLNESS_FORM: `form`,
  PROFILE_WELLNESS_PHYSICAL_FORM_PATH: `submit/form`,
  PROFILE_WELLNESS_COMPLETED_FORM_PATH: `completed/:formId`,
  // will be changed in the future
  // PROFILE_WELLNESS_PHYSICAL_FORM_PATH: `submit/:type`,

  FIND_CARE: 'find-care',
  FIND_CARE_SPECIALIST: 'specialist',
  FIND_CARE_SPECIALIST_SELECT: 'specialist-select',
  FIND_CARE_ALL_SPECIALTIES: 'all-specialties',
  FIND_CARE_URGENT_CARE: 'urgent-care',
  FIND_CARE_PROVIDER: 'provider',
  FIND_CARE_FACILITY: 'facility',
  FIND_CARE_FACILITIES: 'facilities',
  FIND_CARE_FACILITY_SELECT: 'facility-select',
  FIND_CARE_FACILITY_SELECT_PATH: 'find-care/facility-select',

  FIND_CARE_SPECIALIST_PATH: 'find-care/specialist',
  FIND_CARE_SPECIALIST_SELECT_PATH: 'find-care/specialist-select',
  FIND_CARE_URGENT_CARE_PATH: 'find-care/urgent-care',
  FIND_CARE_PROVIDER_PATH: 'find-care/provider',
  FIND_CARE_FACILITY_PATH: 'find-care/facility',
  FIND_CARE_FACILITIES_PATH: 'find-care/facilities',
  FIND_CARE_MAP: 'map',
  FIND_CARE_SPECIALIST_MAP: 'specialist/map',
  FIND_CARE_URGENT_CARE_MAP: 'urgent-care/map',
  FIND_CARE_FACILITIES_MAP: 'facilities/map',
  FIND_CARE_LOCATION: 'change-location',
  FIND_CARE_SPECIALIST_LOCATION: 'specialist/change-location',
  FIND_CARE_URGENT_CARE_LOCATION: 'urgent-care/change-location',
  FIND_CARE_FACILITIES_LOCATION: 'facilities/change-location',

  EMAIL_ENTRY: 'email-entry',
  PASSWORD_ENTRY: 'password-entry',
  RELOGIN: 'relogin',
  ELIGIBILITY: 'eligibility',
  USER_VERIFICATION: 'user-verification',
  EMAIL_VERIFICATION: 'email-verification',
  GET_ASSISTANCE: 'get-assistance',
  GET_ASSISTANCE_SUCCESS: 'get-assistance-success',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'signup',
  SIGN_IN_EMBRIGHT: '/embright/sign-in',
  SIGN_UP_EMBRIGHT: '/embright/signup',
  EMBRIGHT: '/embright',
  TOKEN_SIGN_IN: 'token-sign-in',

  CARE_TEAM: 'care-team',
  CARE_TEAM_PROVIDERS: 'providers',
  CARE_TEAM_PROVIDERS_PATH: 'care-team/providers',
  CARE_TEAM_FACILITIES: 'facilities',
  CARE_TEAM_PROVIDER: 'provider',
  CARE_TEAM_FACILITY: 'facility',
  CARE_TEAM_PROVIDER_PATH: 'care-team/provider',
  CARE_TEAM_FACILITY_PATH: 'care-team/facility',

  APPOINTMENT: 'appointment',
  APPOINTMENTS: 'appointments',
  APPOINTMENT_NEW: 'new',
  APPOINTMENT_SUCCESS: 'success', // temporary solution, because all the flow is not ready yet

  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD_EMBRIGHT: '/embright/reset-password',

  MANUAL: 'manual',

  TALK_WITH_DOCTOR: 'talk-with-doctor',

  INVALID_SAML_CREDENTIALS: 'invalid-saml-credentials',
  EXPIRED_SESSION: 'expired-session',
  UNAUTHENTICATED: 'unauthenticated',
  DEACTIVATED: 'deactivated',
  GDPR: 'gdpr',

  TABS: 'tabs',
}

import { DrugSearchConfiguration } from 'src/shared/storage/drugSearch'

import { RequestData } from './types'

export const defaultConfig = {
  drugName: '',
  isDrugCovered: false,
  config: {
    daysSupply: '',
    daysSupplyCustom: '',
    form: '',
    dosage: '',
    typeOfQuantity: '',
    quantity: '',
    ndc: '',
    gpi: '',
  },
}

export const prepareVariables = (
  savedDrugs: DrugSearchConfiguration[],
  requestData: RequestData,
) => {
  const savedDrug = savedDrugs.find(
    ({ drugName }) => drugName === requestData.drugName,
  )

  return {
    ndc: savedDrug?.config.ndc,
    gpi: savedDrug?.config.gpi,
    days_supply: Number(savedDrug?.config.daysSupplyCustom),
    quantity: Number(savedDrug?.config.quantity),
    latitude: requestData.latitude,
    longitude: requestData.longitude,
  }
}

import { TypedDocumentNode, gql } from '@apollo/client'

import { CUSTOM_FEED_ITEM_FRAGMENT } from '../customFeedItem'
import { FEED_ITEM_FRAGMENT } from '../feedItem'

import { FeedItemsQueryResult, FeedItemsQueryVariables } from './types'

export const FEED_ITEMS_QUERY: TypedDocumentNode<
  FeedItemsQueryResult,
  FeedItemsQueryVariables
> = gql`
  query feedItemsQuery(
    $filters: FeedItemFilterInput
    $first: Int
    $cursor: String
    $shouldSkipCustomItems: Boolean!
  ) {
    feedItems(filters: $filters, first: $first, after: $cursor) {
      nodes {
        ...feedItem
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
    customFeedItems @skip(if: $shouldSkipCustomItems) {
      ...customFeedItem
    }
  }
  ${FEED_ITEM_FRAGMENT}
  ${CUSTOM_FEED_ITEM_FRAGMENT}
`

import { attach, sample } from 'effector'

import { LocationAttributesInput } from 'src/shared/api/types'
import { DEFAULT_COORDS, DEFAULT_ZIP_CODE } from 'src/utils/locationHelpers'

import { mapLocationToAttributes } from '../lib/address'
import { UserLocationsStep } from '../types'

import { UserLocations, UserLocationsGate, stepChanged } from './core'
import { $availableLocationKinds } from './locations'
import {
  $locations,
  createUserLocationFx,
  destroyUserLocationFx,
  updateUserLocationFx,
} from './requests'

export const locationSelected = UserLocations.event<string>()
export const locationConfirmed = UserLocations.event<void>()
export const locationDestroyed = UserLocations.event<void>()

export const locationUpdated =
  UserLocations.event<Partial<LocationAttributesInput>>()

export const locationInitialized = UserLocations.event<void>()

export const $editableLocationId = UserLocations.store<string | null>(null)
export const $editableLocation =
  UserLocations.store<LocationAttributesInput | null>(null)

$editableLocationId
  .on(locationSelected, (_, id) => id)
  .reset(UserLocationsGate.close)
  .reset(locationInitialized)

$editableLocation
  .on(locationUpdated, (state, update) =>
    state ? { ...state, ...update } : null,
  )
  .reset(UserLocationsGate.close)

export const createEditableLocationFx = attach({
  source: $editableLocation,
  effect: createUserLocationFx,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  mapParams: (_: void, attributes) => ({ attributes: attributes! }),
})

export const updateEditableLocationFx = attach({
  source: $editableLocation,
  effect: updateUserLocationFx,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  mapParams: (id: string, attributes) => ({ id, attributes: attributes! }),
})

export const destroyEditableLocationFx = attach({
  effect: destroyUserLocationFx,
})

sample({
  clock: locationInitialized,
  source: $availableLocationKinds,
  fn: (kinds) => ({
    is_preferred: true,
    kind: kinds[0],
    latitude: DEFAULT_COORDS.latitude,
    longitude: DEFAULT_COORDS.longitude,
    zip: DEFAULT_ZIP_CODE,
  }),
  target: $editableLocation,
})

sample({
  clock: sample({
    clock: $editableLocationId.updates,
    source: $locations,
    filter: (_, id) => id !== null,
    fn: (locations, target) => locations.find(({ id }) => id === target),
  }),
  filter: Boolean,
  fn: mapLocationToAttributes,
  target: $editableLocation,
})

sample({
  clock: locationConfirmed,
  source: $editableLocationId,
  filter: (id): id is string => !!id,
  target: updateEditableLocationFx,
})

sample({
  clock: locationConfirmed,
  source: $editableLocationId,
  filter: (id): id is null => !id,
  target: createEditableLocationFx,
})

sample({
  clock: locationDestroyed,
  source: $editableLocationId,
  filter: (id): id is string => !!id,
  target: destroyEditableLocationFx,
})

sample({
  clock: [locationInitialized, locationSelected],
  fn: () => UserLocationsStep.Search,
  target: stepChanged,
})

sample({
  clock: [
    updateEditableLocationFx.done,
    createEditableLocationFx.done,
    destroyEditableLocationFx.done,
  ],
  fn: () => UserLocationsStep.List,
  target: stepChanged,
})

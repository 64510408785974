import { TypedDocumentNode, gql } from '@apollo/client'

import {
  PreferredPharmacyMutationResult,
  PreferredPharmacyMutationVariables,
} from './types'

export const PREFERRED_PHARMACY_MUTATION: TypedDocumentNode<
  PreferredPharmacyMutationResult,
  PreferredPharmacyMutationVariables
> = gql`
  mutation preferredPharmacyMutation($nabp: String!, $zip_code: String!) {
    pdsPreferredPharmacy(nabp: $nabp, zip_code: $zip_code) {
      success
    }
  }
`

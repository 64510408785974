import { useLocation, WindowLocation } from '@reach/router'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ConfigurationContent } from './ConfigurationContent'

interface LocationState {
  drugName: string
  isPreconfigured: boolean
}

export function Configuration() {
  const {
    state: { drugName, isPreconfigured },
  } = useLocation() as WindowLocation<LocationState>

  const t = useScopedTranslation('translation.medications.drug_search.labels')

  return (
    <>
      <Title>{t('configure', { name: drugName })}</Title>

      <ConfigurationContent isPreconfigured={isPreconfigured} />
    </>
  )
}

const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${heading4_21};
  margin-bottom: ${convertPxToRem(24)};
  ${mediaQueryFor.mobile} {
    justify-content: center;
  }
`

type AddressFields = {
  addressLine1?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

export const getAddressField = (data: AddressFields | undefined) => {
  if (!data) return ''
  const { addressLine1 = '', city = '', state = '', zip = '' } = data ?? {}
  const address = [addressLine1, city, state?.toUpperCase()]
    .filter(Boolean)
    .join(', ')
  return `${address} ${zip}`.trim()
}

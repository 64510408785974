import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { text4_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RxCardBackContentProps } from '../../types'

import { PhoneString } from './PhoneString'

export function RxCardBackContent({
  memberHelpDesk,
  pharmacyHelpDesk,
}: RxCardBackContentProps) {
  const t = useScopedTranslation(
    'translation.home.coverage.pharmacy_benefits.rx_card',
  )

  return (
    <>
      <InfoRow>
        <Title>{t('member_services')}</Title>
        <PhoneString value={memberHelpDesk} />
      </InfoRow>
      <InfoRow>
        <Title>{t('pharmacy_help_desk')}</Title>
        <PhoneString value={pharmacyHelpDesk} />
      </InfoRow>
    </>
  )
}

const InfoRow = styled.div`
  padding: ${convertPxToRem(0, 0, 16, 0)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Title = styled.span`
  ${text4_10};
  color: ${secondaryGrey};
  padding-bottom: ${convertPxToRem(5)};
`

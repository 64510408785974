import { $$popup } from 'src/features/PopupModal'

import { CareTeamUpdatePopup } from '../ui/CareTeamUpdatePopup'

export const addFacilitySuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: CareTeamUpdatePopup.AddFacilitySuccess,
})

export const addFacilityErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: CareTeamUpdatePopup.AddFacilityFailure,
})

export const removeFacilitySuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: CareTeamUpdatePopup.RemoveFacilitySuccess,
})

export const removeFacilityErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: CareTeamUpdatePopup.RemoveFacilityFailure,
})

export const addDoctorSuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: CareTeamUpdatePopup.AddDoctorSuccess,
})

export const addDoctorErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: CareTeamUpdatePopup.AddDoctorFailure,
})

export const removeDoctorSuccessPopupShown = $$popup.openWith({
  type: 'success',
  content: CareTeamUpdatePopup.RemoveDoctorSuccess,
})

export const removeDoctorErrorPopupShown = $$popup.openWith({
  type: 'error',
  content: CareTeamUpdatePopup.RemoveDoctorFailure,
})

import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LocationIcon from 'src/components/SvgIcons/LocationIcon'
import { ROUTES } from 'src/constants/routesConstants'
import { $preferredPharmacy } from 'src/entities/drugDetails'
import { primaryDarkNavy, primaryBlue } from 'src/theme/colors'
import { heading8_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface LocationProps {
  zipCode: string
  drugName: string
}

/* The component will be removed in NEX-18282 */
function Location({ zipCode, drugName }: LocationProps) {
  const { t } = useTranslation('translation.medications.drug_search.messages')

  const i18n = {
    resultsNear: t('translation.medications.drug_search.labels.results_near', {
      value: zipCode,
    }),
    change: t('translation.medications.drug_search.buttons.change'),
  }
  const navigate = useNavigate()

  const goToPreferredPharmacySearchScreen = () => {
    void navigate(ROUTES.DRUG_SEARCH_PHARMACY_SEARCH_PATH, {
      state: { drugName },
    })
  }

  return (
    <Section>
      <ResultsNear>{i18n.resultsNear}</ResultsNear>

      <ChangeLocation
        onClick={goToPreferredPharmacySearchScreen}
        data-test="drugDetails.changeLocation"
      >
        <LocationIcon fill={primaryBlue} />
        {i18n.change}
      </ChangeLocation>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ResultsNear = styled.span`
  display: flex;
  ${heading8_14};
  color: ${primaryDarkNavy};
`
const ChangeLocation = styled.button`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(4)};

  ${textLink2_14};
  color: ${primaryBlue};

  background: none;
  border: none;
`

export const DrugDetailsLocation = reflect({
  view: Location,
  bind: {
    zipCode: $preferredPharmacy.map((data) => data?.pharmacy?.zip ?? ''),
  },
})

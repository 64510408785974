import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  primaryBlue,
  primaryWhite,
  secondaryLightGrey,
  primaryDarkNavy,
} from 'src/theme/colors'
import { textLink3_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ChatStatusProps {
  chat: Record<'isResolved' | 'isUnread', boolean>
}

export function ChatStatus({ chat }: ChatStatusProps) {
  const t = useScopedTranslation('translation.chat.thread.chat_bubble')

  if (chat.isResolved)
    return <UpdateTag data-type="resolved">{t('status_resolved')}</UpdateTag>

  if (chat.isUnread)
    return <UpdateTag data-type="new">{t('status_new')}</UpdateTag>

  return null
}

const UpdateTag = styled.span`
  ${textLink3_10};

  grid-area: badge;

  width: max-content;
  height: max-content;

  padding: ${convertPxToRem(4)};
  border-radius: ${convertPxToRem(4)};

  user-select: none;

  &[data-type='new'] {
    background-color: ${primaryBlue};
    color: ${primaryWhite};
  }

  &[data-type='resolved'] {
    background-color: ${secondaryLightGrey};
    color: ${primaryDarkNavy};
  }
`

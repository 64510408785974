import styled from 'styled-components'

import { secondaryLightGrey } from 'src/theme/colors'
import { heading6_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const AppointmentProfileImage = styled.img`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  border-radius: 50%;
`

export const AppointmentProfileInitials = styled.div.attrs({
  'data-test': 'appointmentProfile.initials',
})`
  ${heading6_16};

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  line-height: ${convertPxToRem(40)};
  text-align: center;

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: 50%;

  user-select: none;
`

import { useMemo } from 'react'

import { reflect } from '@effector/reflect'
import { useMatch } from '@reach/router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { $$chat } from 'src/entities/chat'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { ButtonLink } from 'src/shared/ui/buttons/ButtonLink'
import { Sprite } from 'src/shared/ui/Sprite'
import { RootState } from 'src/store'
import { primaryBlue } from 'src/theme/colors'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function CreateChatAction() {
  const t = useScopedTranslation('translation.chat.inbox.without_messages')

  // splat route to match nested pages
  const isMedicineCabinet = useMatch(`/${ROUTES.HOME_MEDICATIONS}/*`) !== null

  // FIXME: migrate from Redux & replace with `useUnit`
  const hasPharmacyOnly = useSelector<RootState>(
    ({ features }) =>
      !features.featureFlags[FeatureFlag.CORE_NAV] &&
      features.featureFlags[FeatureFlag.PBM],
  )

  const withPharmacyGuide = hasPharmacyOnly || isMedicineCabinet

  return (
    <CreateChat>
      {withPharmacyGuide ? t('button_chat_pharmacist') : t('button_chat')}
    </CreateChat>
  )
}

interface CallGuideActionProps {
  call: 'navigator' | 'pharmacist'
}

export function CallGuideAction({ call }: CallGuideActionProps) {
  const t = useScopedTranslation('translation.chat.inbox.without_messages')

  const phone = useOrganizationPhoneQuery()

  const display = useMemo(
    () => formatPhone(phone[call], PHONE_FORMATS.national),
    [phone, call],
  )

  const url = useMemo(
    () => formatPhone(phone[call], PHONE_FORMATS.uri),
    [phone, call],
  )

  const onClick = () => window.open(url!, '_top', 'noreferrer')

  const text =
    call === 'navigator'
      ? t('button_call_health_guide')
      : t('button_call_pharmacy_guide')

  return (
    <Button before={<Sprite name="misc/call" />} onClick={onClick}>
      {text}
      &#x20;
      {/* TODO: include phone into i18n line + Trans component */}
      <PhoneLink>{display}</PhoneLink>
    </Button>
  )
}

const Button = styled(ButtonLink).attrs({ 'data-test': 'blankInboxAction' })`
  & > svg:first-of-type {
    flex-basis: ${convertPxToRem(22)};

    width: ${convertPxToRem(22)};
    height: ${convertPxToRem(22)};
  }

  [data-tutorial='true'] > & {
    pointer-events: none;
  }
`

const PhoneLink = styled.span`
  color: ${primaryBlue};
`

const CreateChat = reflect({
  view: Button,
  bind: {
    before: <Sprite name="misc/conversation" />,
    after: <Sprite name="misc/chevron" />,

    onClick: $$chat.create,
  },
})

import { combine, createEvent, restore, sample } from 'effector'

import { $$chat } from 'src/entities/chat'
import {
  freshpaintEventTracked,
  FreshpaintEvent,
} from 'src/entities/freshpaint'
import { store } from 'src/store'
import { observeStore } from 'src/store/effects'
import { isDesktopView } from 'src/utils/responsiveHelpers'

import { ChatStep } from '../slices/chats'
import { THIRD_SECTION_CONTENT } from '../slices/common'

// FIXME: to be removed after chat slice is deleted

const modalStateToggled = createEvent<boolean>()
const sectionChanged = createEvent<THIRD_SECTION_CONTENT>()

const $isModalOpen = restore(modalStateToggled, isDesktopView)
const $sectionContent = restore(sectionChanged, THIRD_SECTION_CONTENT.CHAT)

const $chatStep = $$chat.$id.map(mapChatStepToLegacy)

const $content = combine($sectionContent, $chatStep, (section, chat) =>
  section === THIRD_SECTION_CONTENT.CHAT ? chat : section,
)

sample({
  clock: [$content, $isModalOpen],
  source: $content,
  filter: $isModalOpen,
  fn: (Content) => ({
    event: FreshpaintEvent.ModalNavigation as const,
    properties: { Content },
  }),
  target: freshpaintEventTracked,
})

observeStore({
  store,
  skipInitial: true,
  selector: ({ common }) => common.isShowThirdSectionModal || isDesktopView,
  onChange: modalStateToggled,
})

observeStore({
  store,
  skipInitial: true,
  selector: ({ common }) => common.thirdSectionContent,
  onChange: sectionChanged,
})

function mapChatStepToLegacy(chatId: string | null): ChatStep {
  switch (chatId) {
    case null:
      return ChatStep.INBOX
    case 'new':
      return ChatStep.CHAT_NEW
    default:
      return ChatStep.CHAT_THREAD
  }
}

import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { SIGN_OUT_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { signOut } from 'src/apollo/utils/authHelpers'
import { getGraphQlError } from 'src/utils/errorHelpers'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'

const useSignOutMutation = ({ preventLocationReplace, resetRedux } = {}) => {
  const [signOutMutation, { loading, client }] = useMutation(
    SIGN_OUT_MUTATION,
    { context: { isSkipError: true } },
  )

  const { getState: getTokensState } = useLocalStorage(StorageContainer.tokens)

  // eslint-disable-next-line consistent-return
  const handleSignOut = useCallback(async () => {
    try {
      await signOutMutation({
        variables: { access_token: getTokensState(({ access }) => access) },
      })
    } catch (error) {
      const graphQLErrors = getGraphQlError(error)

      return graphQLErrors
    } finally {
      await signOut(client, {
        isSsoLogin: false,
        preventLocationReplace,
        resetRedux,
      })
    }
  }, [
    signOutMutation,
    getTokensState,
    client,
    preventLocationReplace,
    resetRedux,
  ])

  return { handleSignOut, loading }
}

export default useSignOutMutation

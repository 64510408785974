import { variant } from '@effector/reflect'
import {
  Redirect,
  useLocation,
  useNavigate,
  WindowLocation,
} from '@reach/router'
import { useGate } from 'effector-react'
import { not } from 'patronum'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { $$drugConfiguration } from 'src/entities/drugConfiguration'
import { LocationChange } from 'src/features/LocationChange'
import { remap } from 'src/shared/lib/effector/remap'
import { select } from 'src/shared/lib/effector/select'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { $$userLocations } from 'src/widgets/UserLocations'

import { ContentWithLightNavHeader } from '../../ContentWithLightNavHeader'
import { DrugSearchConfigGate } from '../model/core'

import { Configuration } from './Configuration'

interface LocationState {
  drugName: string
}

export function DrugSearchConfiguration() {
  const { state } = useLocation() as WindowLocation<LocationState>
  const drugName = state?.drugName ?? ''

  useGate(DrugSearchConfigGate, { drugName })

  return <ConfigurationController />
}

function ContentView() {
  const navigate = useNavigate()
  const { state } = useLocation() as WindowLocation<LocationState>
  const drugName = state?.drugName ?? ''

  const navigateToLocation = () => {
    void navigate(ROUTES.DRUG_SEARCH_LOCATION, {
      state: { drugName, isRegularFlow: false },
    })
  }

  return (
    <ContentWithLightNavHeader>
      <UpdateLocation navigateToLocation={navigateToLocation} />

      <Configuration />
    </ContentWithLightNavHeader>
  )
}

function ErrorView() {
  const { state } = useLocation() as WindowLocation<LocationState>
  const drugName = state?.drugName ?? ''

  return (
    <Redirect to={ROUTES.DRUG_SEARCH_ERROR_PATH} state={{ drugName }} noThrow />
  )
}

const ConfigurationController = variant({
  source: select(
    [
      [$$drugConfiguration.loadOptionsQuery.$pending, 'loading'],
      [not($$userLocations.$locationsReady), 'loading'],
      [$$drugConfiguration.$hasError, 'error'],
    ],
    'content',
  ),
  cases: {
    loading: Spinner,
    error: ErrorView,
    content: ContentView,
  },
})

const StyledLocationChange = styled(LocationChange)`
  padding: ${convertPxToRem(0, 0, 24)};
  width: 100%;
`

const UpdateLocation = variant({
  if: $$userLocations.$locationsReady,
  then: StyledLocationChange,
  bind: {
    zipCode: remap($$userLocations.$preferredLocation, 'zip'),
  },
})

import { useEffect, useRef, useState } from 'react'

import { StorageContainer, useLocalStorageState } from 'src/utils/storage'

import { UploadedFileResult } from '../api/userUploadQuery/types'

interface FetchSecureLinkParams {
  link: string
  token: string
}

interface UseFetchSecureFileArgs {
  getNewLink: () => Promise<{ data?: UploadedFileResult }>
  link: string
}

const fetchSecureLink = ({ link, token }: FetchSecureLinkParams) =>
  fetch(link, {
    method: 'GET',
    headers: {
      'Authorization': token,
      'Rightway-Consumer-Version': 'Member WEB Application',
      'Content-Type': 'application/zip',
    },
  })

const extractObject = async (response: Response) => {
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

const withAbortSignal =
  <T extends unknown[]>(
    { signal }: AbortController,
    fn: (...args: T) => void,
  ) =>
  (...args: T): void => {
    if (!signal.aborted) fn(...args)
  }

export function useFetchSecureFile({
  link: baseLink,
  getNewLink,
}: UseFetchSecureFileArgs) {
  const [source, setSource] = useState<{ url: string }>({ url: baseLink })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const fileSrcRef = useRef<string | null>(null)
  const { access: token } = useLocalStorageState(StorageContainer.tokens)

  useEffect(() => {
    if (source.url !== baseLink) {
      setSource({ url: baseLink })
    }
    fileSrcRef.current = null
    setIsLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLink])

  useEffect(() => {
    const controller = new AbortController()
    const setSourceAbortable = withAbortSignal(controller, setSource)
    const setImageAbortable = withAbortSignal(controller, (blob: string) => {
      fileSrcRef.current = blob
      setIsLoading(false)
    })

    fetchSecureLink({ link: source.url, token })
      .then((result) => {
        if (result.status === 200) return result
        throw new Error('Failed to load')
      })
      .then(extractObject)
      .then(setImageAbortable)
      .catch(async () => {
        const { data } = await getNewLink()
        if (!data?.file?.url) return new Error('Failed to get the file URL')
        setSourceAbortable({ url: data.file.url })
      })
    return () => controller.abort()
  }, [source, getNewLink, token])

  return { fileSrc: fileSrcRef.current, isLoading }
}

import { ApolloError } from '@apollo/client'
import { createEffect } from 'effector'
import { createGate } from 'effector-react'
import { condition } from 'patronum'

import client from 'src/apollo/client'
import { $$navigate } from 'src/entities/navigate'

import { ELIGIBILITY_CHECK_QUERY } from '../api/eligibilityCheckQuery'
import { EligibilityCheckGateProps, EmployeeEligibility } from '../types'

export const EligibilityCheckGate =
  createGate<EligibilityCheckGateProps>('EligibilityCheck')

export const checkEligibilityFx = createEffect<
  string,
  EmployeeEligibility,
  ApolloError
>()

condition({
  source: EligibilityCheckGate.open.map(({ email }) => email),
  if: Boolean,
  then: checkEligibilityFx,
  else: $$navigate.to('/', { replace: true }),
})

checkEligibilityFx.use(async (email) => {
  const { data } = await client.query({
    query: ELIGIBILITY_CHECK_QUERY,
    variables: { email },
  })

  return data.eligibility.status
})

import React from 'react'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

function LocationIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(22),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 22"
    >
      <path
        fill={fill}
        stroke="#fff"
        d="M10 1c-3.68.004-6.663 2.986-6.667 6.667 0 2.925 4.167 10.02 5.957 12.936.152.247.42.397.71.397.29.001.56-.15.71-.398 1.79-2.914 5.957-10.007 5.957-12.935C16.662 3.987 13.68 1.004 10 1z"
      />
      <path
        fill="#fff"
        d="M10 10.583c-1.61 0-2.917-1.306-2.917-2.916C7.083 6.056 8.39 4.75 10 4.75c1.61 0 2.917 1.306 2.917 2.917 0 1.61-1.306 2.916-2.917 2.916z"
      />
    </svg>
  )
}

export default LocationIcon

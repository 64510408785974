import { attach, split } from 'effector'

import { ROUTES } from 'src/constants/routesConstants'
import { $$navigate, NavigateParams } from 'src/entities/navigate'

import { EmployeeEligibility } from '../types'

import { EligibilityCheckGate, checkEligibilityFx } from './core'

const navigateToSuccessFx = attach({
  effect: $$navigate.navigateFx,
  source: EligibilityCheckGate.state,
  mapParams({ to, state = {} }: NavigateParams, { email }) {
    return { to, withQuery: true, replace: true, state: { email, ...state } }
  },
})

// FIXME: see https://github.com/effector/effector/issues/999
const cases = {
  [EmployeeEligibility.Registered]: attach({
    effect: navigateToSuccessFx,
    mapParams: () => ({ to: ROUTES.PASSWORD_ENTRY }),
  }),

  [EmployeeEligibility.Eligible]: attach({
    effect: navigateToSuccessFx,
    mapParams: () => ({
      to: `/${ROUTES.SIGN_UP}`,
      state: { source: 'eligible-quick' },
    }),
  }),

  [EmployeeEligibility.SetPasswordRequired]: attach({
    effect: navigateToSuccessFx,
    mapParams: () => ({
      to: `${ROUTES.RESET_PASSWORD}`,
      state: { stepType: 'ResetRequired' },
    }),
  }),
}

split({
  source: checkEligibilityFx.doneData,
  match: (status) => status,
  cases,
})

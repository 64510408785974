import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryOrange, secondaryBackgroundGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconContent } from './IconContent'

export function Icons() {
  const t = useScopedTranslation(
    'translation.home.care_tools.care_finder.rating_tooltip',
  )
  return (
    <Root>
      <TopIconsWrapper>
        <IconContent
          icon="legacy/primary_care_provider"
          title={t('care_title')}
        />
        <IconContent icon="legacy/billing_advocacy" title={t('cost_title')} />
      </TopIconsWrapper>

      <BottomIconsWrapper>
        <IconContent
          icon="misc/physician_experience"
          title={t('physica_title')}
        />

        <StarIconWrapper>
          <Sprite name="misc/star" />
        </StarIconWrapper>
        <IconContent icon="misc/schedule" title={t('consumer_title')} />
      </BottomIconsWrapper>
    </Root>
  )
}

const Root = styled.div``

const TopIconsWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: ${convertPxToRem(20)};

  gap: ${convertPxToRem(80)};
`

const BottomIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: ${convertPxToRem(26)};
`

const StarIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background-color: ${secondaryBackgroundGrey};
  height: ${convertPxToRem(120)};
  width: ${convertPxToRem(120)};

  > svg {
    width: ${convertPxToRem(94)};
    height: ${convertPxToRem(84)};

    color: ${primaryOrange};
  }
`

import { Suspense } from 'react'

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'

import { InboxHeader, InboxHeaderActions } from '../InboxHeader'

import { BlankActions } from './BlankActions'
import { BlankImage } from './BlankImage'

export function BlankInbox() {
  return (
    <Suspense fallback={<Spinner />}>
      <Header />

      <BlankActions />

      <BlankImage />
    </Suspense>
  )
}

const Header = styled(InboxHeader)`
  ${InboxHeaderActions} {
    display: none;
  }
`

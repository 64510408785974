import React from 'react'

import { primaryDarkNavy } from 'src/theme/colors'

export function WorkIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.625 2.25h6.75c.206 0 .375.169.375.375V4.5h-7.5V2.625c0-.206.169-.375.375-.375zM6 2.625V4.5H3c-1.655 0-3 1.345-3 3V12h24V7.5c0-1.655-1.345-3-3-3h-3V2.625A2.627 2.627 0 0 0 15.375 0h-6.75A2.627 2.627 0 0 0 6 2.625zM24 13.5h-9V15c0 .83-.67 1.5-1.5 1.5h-3c-.83 0-1.5-.67-1.5-1.5v-1.5H0v6c0 1.655 1.345 3 3 3h18c1.655 0 3-1.345 3-3v-6z"
        fill={primaryDarkNavy}
      />
    </svg>
  )
}

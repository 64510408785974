import { sample } from 'effector'

import { iterableModel } from 'src/entities/iterable'
import { loggingLineCreated } from 'src/entities/logging'

// TODO: move to App layer when created

sample({
  clock: iterableModel.$utils,
  filter: (value) => Boolean(value),
  fn: () => ({
    line: 'Iterable initialized',
    meta: { on: 'iterable' },
  }),
  target: loggingLineCreated,
})

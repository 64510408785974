import { ForwardedRef, forwardRef, useMemo } from 'react'

import styled from 'styled-components'

import { UploadFileScanStatus } from 'src/shared/api/types'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ChatMessage, ChatFileAttachment } from '../../../api/chatMessage'
import { UpdatedAt } from '../UpdatedAt'

import { DeliveredAttachment } from './DeliveredAttachment'
import { InsecureAttachment } from './InsecureAttachment'

interface AttachmentProps {
  message: ChatMessage & { attachment: ChatFileAttachment }
}

function AttachmentComponent(
  { message }: AttachmentProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const { isOwned, attachment } = message

  const content = useMemo(() => {
    if (InsecureStatus.has(attachment.scanStatus))
      return (
        <InsecureAttachment
          isOwned={isOwned}
          scanStatus={attachment.scanStatus}
        />
      )

    return <DeliveredAttachment message={message} />
  }, [attachment.scanStatus, isOwned, message])

  return (
    <Wrapper
      data-test="chatAttachment"
      data-owned={isOwned}
      // @ts-expect-error: ref HTMLDivElement is actually assignable to ref HTMLElement
      ref={ref}
    >
      {content}
    </Wrapper>
  )
}

const InsecureStatus = new Set<UploadFileScanStatus>()
  .add(UploadFileScanStatus.Infected)
  .add(UploadFileScanStatus.InvalidLink)
  .add(UploadFileScanStatus.SanitizationFailed)

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: ${convertPxToRem(8)};

  &[data-owned='false'] {
    align-items: start;
  }

  &[data-owned='true'] {
    align-items: end;
  }

  &:not(:first-of-type) ${UpdatedAt} {
    display: none;
  }
`

export const Attachment = forwardRef(AttachmentComponent)

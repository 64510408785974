import { gql } from '@apollo/client'

const CURRENT_USER_QUERY = gql`
  query currentUserQuery {
    currentUser {
      id
      employee_id
      employee_external_service_id
      organization {
        id
        name
      }
      tooltips_viewed
      claims_total_amount_saved
      family {
        id
        first_name
        last_name
        dob
        relation_to_insured
        gender
        display_gender
        address {
          address
          address_line_1
          address_line_2
          city
          state
          street
          zip
        }
      }
      address {
        zip
      }
    }
  }
`

export default CURRENT_USER_QUERY

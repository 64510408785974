import { useState } from 'react'

import styled from 'styled-components'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { RxCardData } from '../types'

import { RxCard } from './content/RxCard'

interface FlipRxCardProps {
  card: RxCardData
}

export function FlipRxCard({ card }: FlipRxCardProps) {
  const [isRotated, setIsRotated] = useState(false)

  return (
    <Root>
      <CardsContainer data-is-rotated={isRotated}>
        <RxCard card={card} setIsRotated={setIsRotated} isFront />
        <RxCard card={card} setIsRotated={setIsRotated} />
      </CardsContainer>
    </Root>
  )
}

const Root = styled.div`
  padding-top: ${convertPxToRem(20)};
  perspective: ${convertPxToRem(3000)};
`

const CardsContainer = styled.div`
  position: relative;

  transform-style: preserve-3d;
  transition: 0.8s ease-in-out;

  &[data-is-rotated='true'] {
    transform: rotateY(180deg);
  }
`

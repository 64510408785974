import React from 'react'

import { useTranslation } from 'react-i18next'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'

import { useAcceptAppointmentTimeSlotMutation } from '../../api/acceptAppointmentTimeSlotMutation'
import { useCancelAppointmentTimeSlotMutation } from '../../api/cancelAppointmentTimeSlotMutation'

export function SuggestedActionBar({ timeSlotId }: { timeSlotId: string }) {
  const { t } = useTranslation()

  const { cancelTimeSlot, isCancellationPending } =
    useCancelAppointmentTimeSlotMutation()

  const { acceptTimeSlot, isAcceptancePending } =
    useAcceptAppointmentTimeSlotMutation()

  const handleAccept = () => acceptTimeSlot(timeSlotId)
  const handleReject = () => cancelTimeSlot(timeSlotId)

  const i18n = {
    accept: t('translation.appointments.action_bar.accept'),
    reject: t('translation.appointments.action_bar.reject'),
  }

  const isActionPending = isCancellationPending || isAcceptancePending

  return (
    <>
      <ButtonBottom
        title={i18n.accept}
        theme="primary"
        handleClick={handleAccept}
        disabled={isActionPending}
        loading={isAcceptancePending}
      />
      <ButtonBottom
        title={i18n.reject}
        theme="linkBlue"
        handleClick={handleReject}
        loading={isCancellationPending}
        disabled={isActionPending}
      />
    </>
  )
}

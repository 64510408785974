import styled, { css } from 'styled-components'

import {
  FocusDefault,
  FooterPaddingH40,
  MobileFooterPaddingH24,
} from 'src/theme/baseStyles'
import {
  primaryDarkNavy,
  primaryBlue,
  primaryWhite,
  secondaryBackgroundGrey,
  secondaryGrey,
  secondaryLightGrey,
  secondaryMidGrey,
  statusErrorRed,
} from 'src/theme/colors'
import {
  heading2_29,
  text1_16,
  text3_12,
  textLink1_16,
  textLink2_14,
} from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const Root = styled.div`
  display: flex;
  flex: 1;

  height: calc(var(--vh) * 100);
  overflow-y: auto;

  justify-content: center;
  align-items: center;

  background-color: ${secondaryBackgroundGrey};
  border-radius: ${convertPxToRem(44)};

  ${mediaQueryFor.mobile} {
    border-radius: 0;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: ${convertPxToRem(500)};
  width: 100vw;
  min-height: ${convertPxToRem(666)};
  background: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(0, 0, 0, 0.1);
  border-radius: ${convertPxToRem(44)};
  padding: ${convertPxToRem(40, 32, 28)};
  ${mediaQueryFor.mobile} {
    box-shadow: none;
    border-radius: 0;
    height: calc(var(--vh) * 100);
    justify-content: space-between;
    overflow: auto;
    max-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const Fieldset = styled.fieldset`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`

export const Legend = styled.legend``

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`
export const Title = styled.h1`
  margin-top: ${convertPxToRem(40)};
  color: ${primaryDarkNavy};
  ${heading2_29};
`
export const SubTitle = styled.div`
  padding: ${convertPxToRem(10, 0, 24)};
  color: ${secondaryGrey};
  ${text1_16};
`
export const Label = styled.label`
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(4)};
  ${text3_12};
`
export const PasswordBox = styled.div`
  position: relative;
`

export const PrimaryInput = css`
  height: ${convertPxToRem(44)};
  width: 100%;
  background: ${primaryWhite};
  border: ${({ hasError }) =>
    hasError
      ? `${convertPxToRem(1)} solid ${statusErrorRed};`
      : `${convertPxToRem(1)} solid ${secondaryLightGrey}`};
  color: ${secondaryMidGrey};
  box-sizing: border-box;
  border-radius: ${convertPxToRem(8)};
  padding: ${convertPxToRem(14, 50, 12, 16)};
  color: ${primaryDarkNavy};
  ::-webkit-input-placeholder {
    color: ${secondaryMidGrey};
  }
  ::-moz-placeholder {
    color: ${secondaryMidGrey};
  }
  :-ms-input-placeholder {
    color: ${secondaryMidGrey};
  }

  ${FocusDefault};
  ${text1_16};
`
export const InputBox = styled.div`
  position: relative;
  margin-bottom: ${convertPxToRem(26)};
  & .date-picker-input {
    ${PrimaryInput};
  }
`
export const Email = styled.input`
  ${PrimaryInput};
`
export const Password = styled.input`
  ${PrimaryInput};
`

export const ForgotPasswordBox = styled.div`
  text-align: right;
  text-transform: capitalize;
  padding-right: ${convertPxToRem(10)};
  margin-bottom: ${convertPxToRem(40)};
  color: ${secondaryGrey};
  display: flex;
  justify-content: flex-end;
  ${textLink2_14}
`

export const ForgotPasswordLink = styled.div`
  cursor: pointer;
`
export const SignUpBox = styled.div`
  padding-top: ${convertPxToRem(14)};
  padding-bottom: ${convertPxToRem(14)};
  margin-top: ${convertPxToRem(8)};
  margin-bottom: ${convertPxToRem(8)};
  display: flex;
  justify-content: center;
  ${textLink2_14}
`

export const SignUpLinkSecondary = styled.div`
  display: flex;
  cursor: pointer;
  color: ${primaryDarkNavy};
  font-size: ${convertPxToRem(16)};
`

export const SignUpLink = styled.div`
  padding-left: ${convertPxToRem(8)};
  color: ${primaryBlue};
`

export const Description = styled.div`
  color: ${secondaryGrey};
  margin-top: ${convertPxToRem(22)};
  ${text3_12}

  &[data-is-relogin='true'] {
    margin-top: 0;
  }
`

export const Show = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${convertPxToRem(12)};
  right: ${convertPxToRem(18)};
  width: ${convertPxToRem(19)};
  height: ${convertPxToRem(13)};
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ url }) => `url(${url})`};
  ${({ visiblePassword }) =>
    !visiblePassword &&
    css`
      &:before {
        content: '';
        border-top: ${convertPxToRem(1)} solid ${primaryWhite};
        position: absolute;
        top: ${convertPxToRem(8)};
        left: ${convertPxToRem(-2)};
        width: ${convertPxToRem(22)};
        height: ${convertPxToRem(3)};
        z-index: 1;
        transform: rotate(45deg);
        background: ${secondaryGrey};
      }
    `};
`

import { TypedDocumentNode, gql } from '@apollo/client'

import { CHAT_MESSAGE_FRAGMENT } from '../chatMessage'

import {
  ChatPreviewUpdate,
  SendChatMessageResult,
  SendChatMessageVariables,
} from './types'

export const SEND_CHAT_MESSAGE_MUTATION: TypedDocumentNode<
  SendChatMessageResult,
  SendChatMessageVariables
> = gql`
  mutation sendChatMessage(
    $chatId: ID!
    $text: String
    $file: ApolloFileUploadInput
  ) {
    message: replyToChat(
      id: $chatId
      attributes: { body: $text, file: $file }
    ) {
      ...chatMessage
    }
  }

  ${CHAT_MESSAGE_FRAGMENT}
`

export const CHAT_PREVIEW_UPDATE_FRAGMENT: TypedDocumentNode<
  ChatPreviewUpdate,
  Record<string, never>
> = gql`
  fragment chatPreviewUpdate on Chat {
    preview: last_message {
      id
    }
  }
`

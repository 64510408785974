import { createEffect, createEvent, createStore, sample } from 'effector'
import { createQuery } from 'effector-apollo'

import { toggleFavoritePharmacyMutation } from '@entities/favoritePharmacy'

import client, { cache } from 'src/apollo/client'
import { remap } from 'src/shared/lib/effector/remap'
import {
  $$drugSearchConfigurationStorage,
  DrugSearchConfiguration,
} from 'src/shared/storage/drugSearch'

import {
  DRUG_SEARCH_PRICES_QUERY,
  DrugSearchPricesQueryResult,
} from '../api/drugSearchPricesQuery'

import { defaultConfig, prepareVariables } from './helpers'
import { DrugSearchFlowEntrypoint, RequestData } from './types'

export { DrugSearchFlowEntrypoint } from './types'

export const drugDetailsRequested = createEvent<RequestData>()

export const evictPricesFx = createEffect(() => {
  cache.evict({ fieldName: 'pdsPrices' })
})

export const drugSearcPricesQuery = createQuery({
  document: DRUG_SEARCH_PRICES_QUERY,
  client,
})

export const $drugSearchFlowEntrypoint = createStore(
  DrugSearchFlowEntrypoint.Medications,
)

export const $drugSearchPrices =
  createStore<DrugSearchPricesQueryResult | null>(null)

export const $currentDrugConfig =
  createStore<DrugSearchConfiguration>(defaultConfig)

export const $recommendedPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.recommendedPharmacies ?? [],
)

export const $favoritePharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.favoritePharmacies ?? [],
)

export const $otherPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.otherPharmacies ?? [],
)

export const $specialtyPharmacies = $drugSearchPrices.map(
  (prices) => prices?.drugSearchPrices?.specialtyPharmacies ?? [],
)

export const $rejectionInfo = remap(
  $drugSearchPrices,
  'drugSearchPrices.rejection',
)

export const $hasErrorInPricesRequest = $drugSearchPrices.map((prices) =>
  Boolean(prices?.drugSearchPrices?.rejection),
)

sample({
  clock: drugDetailsRequested,
  source: $$drugSearchConfigurationStorage.$state,
  filter: (savedDrugs, requestData) =>
    savedDrugs.some(({ drugName }) => drugName === requestData.drugName),
  fn: prepareVariables,
  target: drugSearcPricesQuery.refresh,
})

sample({
  clock: toggleFavoritePharmacyMutation.start,
  target: evictPricesFx,
})

// ToDo: Get rid of the $drugSearchPrices after migration to the pbm resolvers
sample({
  source: drugSearcPricesQuery.$data,
  target: $drugSearchPrices,
})

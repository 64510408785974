import { useUnit } from 'effector-react'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { Pharmacy } from 'src/entities/pharmacy'
import {
  $$pharmacySearch,
  preferredPharmacyChosen,
} from 'src/features/pharmacySearch'
import { Schedule } from 'src/features/Schedule'
import { getAddressField } from 'src/shared/lib/getAddressField'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  primaryDarkNavy,
  primaryWhite,
  primaryBlue,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading5_19, text1_16, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface PreferredPharmacyCardProps {
  order?: number
  pharmacy: Pharmacy
}

export function PreferredPharmacyCard({
  pharmacy,
  order = undefined,
}: PreferredPharmacyCardProps) {
  const t = useScopedTranslation('translation.shared')

  const isPendingSelection = useUnit($$pharmacySearch.$isPendingSelection)
  const selectPharmacy = useUnit(preferredPharmacyChosen)
  const handleSelection = () => {
    selectPharmacy({ nabp: pharmacy.nabp, zipCode: pharmacy.zip })
  }

  return (
    <Card data-test="preferredPharmacyCard">
      <CardHeader>
        <TitleRow>
          {Boolean(order) && <OrderIcon>{order}</OrderIcon>}
          <PharmacyName>{pharmacy.name}</PharmacyName>
        </TitleRow>
      </CardHeader>

      <CardContent>
        <Address>{getAddressField(pharmacy)}</Address>
        <SelectButton
          theme="borderBlue"
          title={t('buttons.select')}
          handleClick={handleSelection}
          loading={isPendingSelection}
        />
      </CardContent>

      <Schedule workSchedule={pharmacy.workSchedule} />
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  padding: ${convertPxToRem(20)};
  background-color: ${primaryWhite};
`
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const OrderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${primaryWhite};
  background-color: ${primaryBlue};
  min-width: ${convertPxToRem(24)};
  height: ${convertPxToRem(24)};
  border-radius: 50%;
  ${text1_16};
  margin-right: ${convertPxToRem(8)};
`
const TitleRow = styled.div`
  display: flex;
`
const PharmacyName = styled.span`
  color: ${primaryDarkNavy};
  ${heading5_19};
`
const Address = styled.span`
  color: ${secondaryGrey};
  ${text2_14};
`
const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${convertPxToRem(8)};
`
const SelectButton = styled(ButtonBottom)`
  width: max-content;
  padding: ${convertPxToRem(8, 16)};
`

import { gql } from '@apollo/client'

import CHAT_MESSAGE_FRAGMENT from 'src/apollo/fragments/CHAT_MESSAGE_FRAGMENT'
import NAVIGATOR_FRAGMENT from 'src/apollo/fragments/NAVIGATOR_FRAGMENT'

const CHATS_EVENTS_SUBSCRIPTION = gql`
  subscription chatsEventsSubscription {
    chatsEvents {
      event
      chat {
        id
        is_inactive
        last_message {
          ...chatMessageFragment
        }
        subject
        navigator {
          ...navigatorFragment
        }
      }
    }
  }
  ${NAVIGATOR_FRAGMENT}
  ${CHAT_MESSAGE_FRAGMENT}
`

export default CHATS_EVENTS_SUBSCRIPTION

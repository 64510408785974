import { Suspense, useContext, useEffect, useState } from 'react'

import { Router, useLocation } from '@reach/router'
import { useUnit } from 'effector-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import useCurrentUserQuery from 'src/apollo/hooks/useCurrentUserQuery'
import useMemberProfileQuery from 'src/apollo/hooks/useMemberProfileQuery'
import FeatureGuard from 'src/components/FeatureGuard'
import ModalTelemed from 'src/components/Modals/ModalTelemed'
import Spinner from 'src/components/Spinner'
import SubscriptionHandler from 'src/components/SubscriptionHandler'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { IterableController } from 'src/entities/iterable'
import { logmask, useApplyLoggingContext } from 'src/entities/logging'
import { createModalsQueue } from 'src/entities/modalsQueue'
import { useTermsOfServiceQuery } from 'src/entities/termsOfServices'
import { DrugSearchRouter } from 'src/pages/drugSearch'
import SectionAppointments from 'src/sections/SectionAppointments'
import GeolocationProvider from 'src/sections/SectionCareLocator/contexts/GeolocationProvider'
import {
  isMobileDevice,
  MOBILE_TOOLBAR_HIDDEN_FLAG,
  TUTORIAL_SECOND_STEP,
} from 'src/sections/SectionDashboard/dashboardHelpers'
import GetHelpTabs from 'src/sections/SectionDashboard/GetHelpTabs'
import GuidesPhoneNumbersModal from 'src/sections/SectionDashboard/GuidesPhoneNumbersModal'
import SideBarDesktop from 'src/sections/SectionDashboard/SideBarDesktop'
import SideBarMobile from 'src/sections/SectionDashboard/SideBarMobile'
import ThirdSection from 'src/sections/SectionDashboard/ThirdSection'
import SectionHome from 'src/sections/SectionHome'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import AcceptTerms from 'src/sections/SectionRegistration/pages/AcceptTerms'
import SsoFailedScreen from 'src/sections/SectionRegistration/pages/SsoFailedScreen'
import { lazyWithRetry } from 'src/shared/lib/lazy'
import { $$sessionStorage } from 'src/shared/storage/session'
import { Sprite } from 'src/shared/ui/Sprite'
import { withDispatch } from 'src/store'
import { openChatThread } from 'src/store/slices/chats'
import {
  setIsShowThirdSectionModal,
  setIsShowGuidesPhoneNumberModal,
  turnOffMobileToolbar,
} from 'src/store/slices/common'
import {
  boxShadowScreen,
  modalBg,
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  primaryBlue,
  secondarySoftGrey,
} from 'src/theme/colors'
import { heading4_21, textLink1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { useRestoreDeeplink } from 'src/utils/hooks/useDeeplink'
import useQueryString from 'src/utils/hooks/useQueryString'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkPathName } from 'src/utils/routeHelpers'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'
import { earlyAccessModal } from 'src/widgets/EarlyAccessModal'
import { shareYourLocationModal } from 'src/widgets/ShareYourLocation'
import { GeolocationController } from 'src/widgets/UserLocations'

import './model'

const SectionBookAppointment = lazyWithRetry(
  () => import('src/sections/SectionBookAppointment'),
)
const CarePlan = lazyWithRetry(
  () => import('src/sections/SectionCarePlans/CarePlan'),
)
const SectionCarePlans = lazyWithRetry(
  () => import('src/sections/SectionCarePlans'),
)
const SectionCareLocator = lazyWithRetry(
  () => import('src/sections/SectionCareLocator'),
)
const SectionCareTeam = lazyWithRetry(
  () => import('src/sections/SectionCareTeam'),
)
const SectionProfile = lazyWithRetry(
  () => import('src/sections/SectionProfile'),
)
const MedicationSectionRouter = lazyWithRetry(
  () => import('src/sections/SectionMedications/MedicationSectionRouter'),
)
const MedicationPharmacyDetails = lazyWithRetry(
  () =>
    import(
      'src/sections/SectionMedications/MedicationDetails/Pricing/MedicationPharmacyDetails'
    ),
)
const SectionBillingAdvocacy = lazyWithRetry(
  () => import('src/sections/SectionBillingAdvocacy'),
)
const BillingClaim = lazyWithRetry(
  () =>
    import(
      'src/sections/SectionBillingAdvocacy/BillingClaim/BillingClaimPreview'
    ),
)

const AfterLoginModals = createModalsQueue([
  shareYourLocationModal,
  earlyAccessModal,
])

function Dashboard({
  isShowThirdSectionModal = false,
  shouldShowGuidesPhoneNumberModal = false,
  dispatchShowThirdSectionModal,
  dispatchOpenChat,
  dispatchClosePhoneNumberModal,
  dispatchTurnOffMobileToolbar,
  isSecondTutorialStep = false,
  isInitialDataReceived = false,
  poweredByText = '',
}) {
  const { t } = useTranslation()

  const [isVisibleGetHelpMenu, setIsVisibleGetHelpMenu] = useState(false)
  const location = useLocation()
  const { termsOfService, isLoading } = useTermsOfServiceQuery(undefined)

  const { setEmail } = useLocalStorage(StorageContainer.persistent)

  const { acceptTermsOfService } = useUnit($$sessionStorage)
  const { hasTermsOfService } = useUnit($$sessionStorage.$state)

  const { userId } = useCurrentUserQuery()

  const {
    profileData: { email },
  } = useMemberProfileQuery()

  const {
    parsed: { chat_id, mobile_toolbar },
  } = useQueryString()

  const i18n = {
    getHelpMenuTitle: t('translation.home.tabbar.description'),
    getHelpTitle: t('translation.home.tabbar.get_help'),
  }

  useEffect(() => {
    if (mobile_toolbar === MOBILE_TOOLBAR_HIDDEN_FLAG) {
      dispatchTurnOffMobileToolbar()
    }
  }, [mobile_toolbar, dispatchTurnOffMobileToolbar])

  useRestoreDeeplink()

  const toggleGetHelpMenu = () => {
    setIsVisibleGetHelpMenu((isVisible) => !isVisible)
  }

  const disableGetHelpMenu = (e) => {
    if (!e.target.dataset.gethelp) setIsVisibleGetHelpMenu(false)
  }

  useEffect(() => {
    if (chat_id) {
      dispatchOpenChat(chat_id)
      dispatchShowThirdSectionModal()
    }
  }, [chat_id, dispatchOpenChat, dispatchShowThirdSectionModal])

  useEffect(() => {
    if (email) setEmail(email)
  }, [email, setEmail])

  useEffect(() => {
    if (termsOfService?.accepted) {
      acceptTermsOfService()
    }
  }, [acceptTermsOfService, termsOfService])

  const isPageHomeTabs =
    checkPathName(ROUTES.HOME_ACTIVITY_PATH, location) &&
    !isShowThirdSectionModal

  const { setScrollTop } = useContext(HeaderAnimationContext)
  const handleScroll = (event) => {
    if (isMobileDevice) setScrollTop(event.currentTarget.scrollTop)
  }

  useApplyLoggingContext('user', {
    id: userId,
    email: logmask.email(email),
    termsOfService: termsOfService?.accepted,
  })

  const shouldShowTermsOfService =
    !termsOfService?.accepted && hasTermsOfService

  if (!isInitialDataReceived || isLoading) return <Spinner />

  if (shouldShowTermsOfService) {
    return <AcceptTerms />
  }

  return (
    <>
      <GeolocationProvider />
      <IterableController />
      <GeolocationController />

      <SubscriptionHandler />

      <Root onScroll={handleScroll} onClick={disableGetHelpMenu}>
        <SideBarDesktop
          isVisibleGetHelpMenu={isVisibleGetHelpMenu}
          toggleGetHelpMenu={toggleGetHelpMenu}
        />

        <Content>
          <Suspense fallback={<Spinner size="large" />}>
            <Router className="rootRouter">
              <SectionHome path={`${ROUTES.HOME}/*`} />

              <FeatureGuard
                permit={FeatureFlag.CARE_PLANS}
                path={`${ROUTES.CARE_PLANS}/*`}
                component={<SectionCarePlans />}
              />

              <FeatureGuard
                permit={FeatureFlag.CARE_PLANS}
                path={`${ROUTES.CARE_PLAN}/:carePlanId/*`}
                component={<CarePlan />}
              />

              <FeatureGuard
                permit={FeatureFlag.SHARED_PROFILE}
                path={`${ROUTES.PROFILE}/*`}
                component={<SectionProfile />}
              />

              <FeatureGuard
                permit={FeatureFlag.BILL_ADVOCACY}
                path={`${ROUTES.BILL_CLAIMS_NEW}/*`}
                component={<SectionBillingAdvocacy />}
              />

              <FeatureGuard
                permit={FeatureFlag.BILL_ADVOCACY}
                path={`${ROUTES.BILL_CLAIM}/:id`}
                component={<BillingClaim />}
              />

              <SectionCareTeam path={`${ROUTES.CARE_TEAM}/*`} />
              <SectionAppointments path={`${ROUTES.APPOINTMENT}/*`} />

              <FeatureGuard
                permit={FeatureFlag.CARE_LOCATOR}
                path={`${ROUTES.FIND_CARE}/*`}
                component={<SectionCareLocator />}
              />

              <SectionBookAppointment path={`${ROUTES.APPOINTMENTS}/*`} />
              <ModalTelemed path={`${ROUTES.TALK_WITH_DOCTOR}/*`} />
              <SsoFailedScreen path={ROUTES.INVALID_SAML_CREDENTIALS} />

              <FeatureGuard
                permit={FeatureFlag.MED_CABINET}
                path={`${ROUTES.MEDICATION_PHARMACY_DETAILS}/*`}
                component={<MedicationPharmacyDetails />}
              />

              <FeatureGuard
                permit={FeatureFlag.MED_CABINET}
                path={`/${ROUTES.MEDICATIONS}/*`}
                component={<MedicationSectionRouter />}
              />

              <FeatureGuard
                permit={FeatureFlag.PBM_DRUG_SEARCH}
                path={`/${ROUTES.DRUG_SEARCH_PATH}/*`}
                component={<DrugSearchRouter />}
              />
            </Router>
          </Suspense>
        </Content>

        <ThirdSection />

        {isPageHomeTabs && <SideBarMobile />}

        {isPageHomeTabs && (
          <GetHelpBtnTablet
            isSecondTutorialStep={isSecondTutorialStep}
            data-gethelp
            onClick={toggleGetHelpMenu}
            disabled={isSecondTutorialStep}
          >
            <LogoIcon data-gethelp />
            <GetHelpText data-gethelp>{i18n.getHelpTitle}</GetHelpText>
          </GetHelpBtnTablet>
        )}

        <GetHelpMenuWrapper
          isAnimateGetHelp={isVisibleGetHelpMenu}
          isPoweredByText={!!poweredByText}
        >
          {isVisibleGetHelpMenu && (
            <>
              <GetHelpMenuTitle>{i18n.getHelpMenuTitle}</GetHelpMenuTitle>
              <GetHelpTabs />
            </>
          )}
        </GetHelpMenuWrapper>

        {shouldShowGuidesPhoneNumberModal && (
          <GuidesPhoneNumbersModal
            closeModal={dispatchClosePhoneNumberModal}
            isMobileModal
          />
        )}

        <AfterLoginModals />
      </Root>
    </>
  )
}

const Content = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  border-right: ${convertPxToRem(1)} solid ${secondarySoftGrey};
  background: ${secondaryBackgroundGrey};
  ${mediaQueryFor.tabletOrLess} {
    border-right: none;
  }
`

const Root = styled.section`
  display: flex;
  flex: 1;
  overflow: auto;

  ${mediaQueryFor.tabletOrLess} {
    flex-direction: column;
  }
`

const GetHelpBtnTablet = styled.button`
  ${mediaQueryFor.mobile} {
    display: none;
  }
  ${mediaQueryFor.tablet} {
    position: fixed;
    bottom: ${convertPxToRem(40)};
    left: ${convertPxToRem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${primaryWhite};
    height: ${convertPxToRem(40)};
    padding: ${convertPxToRem(10, 22)};
    border-radius: ${convertPxToRem(40)};
    box-shadow: ${convertPxToRem(0, 5, 10, 0)} rgba(0, 0, 0, 0.35);
    ${textLink1_16};
    border: none;
    cursor: pointer;
    color: ${primaryBlue};
    z-index: ${({ isSecondTutorialStep }) => (isSecondTutorialStep ? 2 : 1)};
    > svg {
      pointer-events: none;
    }
  }
  ${mediaQueryFor.desktop} {
    display: none;
  }
`

const LogoIcon = styled(Sprite).attrs({ name: 'misc/rightway' })`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
  color: ${primaryBlue};
`

const GetHelpMenuWrapper = styled.div`
  max-width: 100%;
  min-width: ${convertPxToRem(359)};
  opacity: ${({ isAnimateGetHelp }) => (isAnimateGetHelp ? 1 : 0)};
  visibility: ${({ isAnimateGetHelp }) =>
    isAnimateGetHelp ? 'visible' : 'hidden'};
  position: fixed;
  left: ${convertPxToRem(40)};
  z-index: 1;
  background-color: ${primaryWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isAnimateGetHelp }) =>
    isAnimateGetHelp ? convertPxToRem(25, 30, 30) : 0};
  box-shadow: ${boxShadowScreen};
  border-radius: ${convertPxToRem(24)};
  background-image: linear-gradient(to bottom, ${primaryWhite}, ${modalBg});
  transition: opacity 0.4s ease-out;
  border: ${convertPxToRem(1)} solid ${primaryWhite};
  ${mediaQueryFor.mobile} {
    display: none;
  }
  ${mediaQueryFor.tablet} {
    bottom: ${convertPxToRem(98)};
  }
  ${mediaQueryFor.desktop} {
    bottom: ${({ isPoweredByText }) =>
      isPoweredByText ? convertPxToRem(144) : convertPxToRem(98)};
  }
`

const GetHelpMenuTitle = styled.h2`
  ${heading4_21};
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(20)};
`
const GetHelpText = styled.h3`
  margin-left: ${convertPxToRem(8)};
`

Dashboard.propTypes = {
  poweredByText: PropTypes.string,
  isShowThirdSectionModal: PropTypes.bool,
  shouldShowGuidesPhoneNumberModal: PropTypes.bool,
  dispatchShowThirdSectionModal: PropTypes.func.isRequired,
  dispatchOpenChat: PropTypes.func.isRequired,
  dispatchClosePhoneNumberModal: PropTypes.func.isRequired,
  dispatchTurnOffMobileToolbar: PropTypes.func.isRequired,
  isSecondTutorialStep: PropTypes.bool,
  isInitialDataReceived: PropTypes.bool,
}

const mapStateToProps = ({ user, common, tutorial }) => ({
  isShowThirdSectionModal: common.isShowThirdSectionModal,
  shouldShowGuidesPhoneNumberModal: common.isShowGuidesPhoneNumberModal,
  isSecondTutorialStep: tutorial.tutorialStep === TUTORIAL_SECOND_STEP,
  poweredByText: user.branding?.poweredByText,
  isInitialDataReceived: common.isInitialDataReceived,
})

const mapDispatchToProps = withDispatch({
  showThirdSectionModal: () => setIsShowThirdSectionModal(true),
  closePhoneNumberModal: () => setIsShowGuidesPhoneNumberModal(false),
  openChat: openChatThread,

  turnOffMobileToolbar,
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

import { createDomain } from 'effector'
import { createQuery } from 'effector-apollo'

import client from 'src/apollo/client'
import { remap } from 'src/shared/lib/effector/remap'

import { PREFERRED_PHARMACY_QUERY } from '../api/preferredPharmacyQuery'

export const preferredPharmacyQuery = createQuery({
  document: PREFERRED_PHARMACY_QUERY,
  client,
})

export const DrugSearchConfig = createDomain('DrugSearchConfig')

export const $preferredPharmacy = remap(
  preferredPharmacyQuery.$data,
  'preferredPharmacy',
)

export const $hasPreferredPharmacy = $preferredPharmacy.map(Boolean)

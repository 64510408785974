import { createQuery, watchQuery } from 'effector-apollo'

import client from 'src/apollo/client'

import { DRUG_SEARCH_PHARMACIES_QUERY } from './graphql'
export { DRUG_SEARCH_PHARMACIES_QUERY } from './graphql'

export const drugSearchPharmaciesQuery = createQuery({
  document: DRUG_SEARCH_PHARMACIES_QUERY,
  client,
})

watchQuery(drugSearchPharmaciesQuery)

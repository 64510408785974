import { pathOr } from 'ramda'

import {
  extractDoctorsCollection,
  extractFacilitiesCollection,
} from 'src/apollo/hooks/useDoctorsQueryHelpers'

export const extractCareTeamDoctorsData = (data) => {
  const doctorsData = extractDoctorsCollection(
    pathOr([], ['currentUser', 'care_team_doctors', 'nodes'], data),
  )
  const pageInfo = pathOr(
    {},
    ['currentUser', 'care_team_doctors', 'pageInfo'],
    data,
  )
  return {
    doctorsData,
    pageInfo,
  }
}

export const extractCareTeamFacilitiesData = (data) => {
  const facilitiesData = extractFacilitiesCollection(
    pathOr([], ['currentUser', 'care_team_facilities', 'nodes'], data),
  )
  const pageInfo = pathOr(
    {},
    ['currentUser', 'care_team_facilities', 'pageInfo'],
    data,
  )
  return {
    facilitiesData,
    pageInfo,
  }
}

export const checkIsAddedToCareTeam = (data, profileId, isFacility) => {
  const careTeamItems = isFacility
    ? pathOr([], ['currentUser', 'care_team_facilities', 'nodes'], data)
    : pathOr([], ['currentUser', 'care_team_doctors', 'nodes'], data)

  return careTeamItems.some((item) => item.id === profileId)
}

import { gql } from '@apollo/client'

export const SIGN_IN_MUTATION = gql`
  mutation signInMutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      access_token
      access_token_expires_at
      refresh_token
      refresh_token_expires_at
    }
  }
`

export const SIGN_OUT_MUTATION = gql`
  mutation signOutMutation {
    signOut {
      access_token
      access_token_expires_at
      refresh_token
      refresh_token_expires_at
    }
  }
`

export const REFRESH_TOKENS_MUTATION = gql`
  mutation refreshTokens {
    refreshTokens {
      access_token
      access_token_expires_at
      refresh_token
      refresh_token_expires_at
    }
  }
`

import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import SuccessIcon from 'src/components/SvgIcons/SuccessIcon'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleGreen,
  featurePaleRed,
  secondaryGrey,
  statusActive,
  statusErrorRed,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $popup, PopupType, popupMessageHidden } from '../model/core'

export function PopupMessage() {
  const popup = useUnit($popup)

  return (
    <Root data-type={popup?.type}>
      {popup?.type && IconMap.get(popup.type)}

      {popup?.content}

      <CloseIcon />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;

  padding: ${convertPxToRem(16)};
  gap: ${convertPxToRem(12)};
  border-radius: ${convertPxToRem(6)};

  &[data-type='success'] {
    background-color: ${featurePaleGreen};
    border: ${convertPxToRem(1)} solid ${statusActive};
  }

  &[data-type='error'] {
    background-color: ${featurePaleRed};
    border: ${convertPxToRem(1)} solid ${statusErrorRed};
  }
`

const CloseIconComponent = styled(Sprite).attrs({ name: 'nav/close' })`
  flex-shrink: 0;
  margin-left: auto;

  color: ${secondaryGrey};
  height: ${convertPxToRem(20)};
  width: ${convertPxToRem(20)};

  cursor: pointer;
`

const CloseIcon = reflect({
  view: CloseIconComponent,
  bind: {
    onClick: popupMessageHidden,
  },
})

const StyledSuccessIcon = styled(SuccessIcon)`
  flex-shrink: 0;
`
const StyledExclamationIcon = styled(ExclamationIcon)`
  flex-shrink: 0;
`

const IconMap = new Map<PopupType, React.ReactElement>([
  ['success', <StyledSuccessIcon />],
  [
    'error',
    <StyledExclamationIcon
      width={convertPxToRem(20)}
      height={convertPxToRem(20)}
      fill={statusErrorRed}
    />,
  ],
])

import React from 'react'

import { reflect, variant } from '@effector/reflect'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'
import { useGate } from 'effector-react'
import { and } from 'patronum'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { ContentWithLightNavHeader } from 'src/widgets/ContentWithLightNavHeader'
import { $$userLocations, UserLocations } from 'src/widgets/UserLocations'

import {
  navigatedBack,
  pharmaciesSearchStarted,
  PreferredPharmacyFindGate,
} from '../model'

interface LocationState {
  isFreshSearch: boolean
  drugName: string
}

function PreferredPharmacyFind() {
  const {
    state: { isFreshSearch, drugName },
  } = useLocation() as WindowLocation<LocationState>

  useGate(PreferredPharmacyFindGate, {
    isFreshSearch,
    drugName,
  })

  const t = useScopedTranslation(
    'translation.medications.drug_search.search_pharmacies',
  )
  const i18n = {
    title: isFreshSearch ? t('title') : t('edit_title'),
    buttonTitle: isFreshSearch ? t('button') : t('select_button'),
  }

  return (
    <Root>
      <Title>{i18n.title}</Title>

      <UserLocations />

      <ButtonWrapper>
        <ContinueButton title={i18n.buttonTitle} />
      </ButtonWrapper>
    </Root>
  )
}

const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${heading4_21};
  margin-bottom: ${convertPxToRem(24)};
`
const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: ${convertPxToRem(40, 0)};
`

const ContinueButton = variant({
  if: and($$userLocations.$hasPreferredLocation, $$userLocations.$isClosable),
  then: ButtonBottom,
  bind: {
    theme: 'primary',
    handleClick: pharmaciesSearchStarted,
  },
})

const Root = reflect({
  view: ContentWithLightNavHeader,
  bind: {
    onBack: navigatedBack,
  },
})

export const DrugSearchPreferredPharmacyFind =
  PreferredPharmacyFind as React.FC<RouteComponentProps>

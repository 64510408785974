import { gql } from '@apollo/client'

const FEED_FIELDS_FRAGMENT = gql`
  fragment feedFieldsFragment on FeedFields {
    title
    description
    label
    call_to_action
    image_url
  }
`

export default FEED_FIELDS_FRAGMENT

import { gql } from '@apollo/client'

import { APPOINTMENT_TIME_SLOT_FRAGMENTS } from '../timeSlots/document'

export const APPOINTMENT_SUBSCRIPTION = gql`
  subscription appointmentsSubscription($id: ID) {
    appointmentsEvents(id: $id) {
      event
      appointment {
        id

        displayStatus: display_status
        status

        timeSlots: time_slots {
          ...asapTime
          ...specificTime
          ...timeBlock

          ...suggestedTime
          ...pendingTime
          ...confirmedTime
          ...cancelledTime
        }
      }
    }
  }

  ${APPOINTMENT_TIME_SLOT_FRAGMENTS}
`

import styled from 'styled-components'

import { DisclaimerType } from 'src/shared/api/types'
import {
  featurePaleGreen,
  featurePaleRed,
  featurePaleYellow,
  primaryDarkNavy,
} from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface DisclaimerProps {
  text: string
  type: DisclaimerType
}

export function Disclaimer({ text, type }: DisclaimerProps) {
  return <Root data-type={type}>{text}</Root>
}

const Root = styled.span`
  ${text2_14};
  color: ${primaryDarkNavy};

  padding: ${convertPxToRem(16, 40)};
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(10, 24)};
  }

  &[data-type=${DisclaimerType.Error}] {
    background-color: ${featurePaleRed};
  }
  &[data-type=${DisclaimerType.Warning}] {
    background-color: ${featurePaleYellow};
  }
  &[data-type=${DisclaimerType.Success}] {
    background-color: ${featurePaleGreen};
  }
`

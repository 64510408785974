import { gql, TypedDocumentNode } from '@apollo/client'

import { DeactivateChatResult, DeactivateChatVariables } from './types'

/**
 * Disambiguation:
 * - 'deactivating' removes the chat from list as visible,
 *   and is initiated by the member.
 * - 'resolving' marks chat as resolved and read-only,
 *   keeping it visible for member, and is initiated from
 *   the Advocate.
 */
export const DEACTIVATE_CHAT_MUTATION: TypedDocumentNode<
  DeactivateChatResult,
  DeactivateChatVariables
> = gql`
  mutation deactivateChat($id: ID!) {
    chat: deactivateChat(id: $id) {
      id
      isInactive: is_inactive
    }
  }
`

import { useLocation, useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import qs from 'qs'
import { identical } from 'ramda'

const useQueryString = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const qsParse = (_searchStr) => qs.parse(_searchStr)
  const qsStringify = (_nextParsed) =>
    qs.stringify(_nextParsed, { encodeValuesOnly: true })
  const setQs = (_rout, _qsValue) => `${_rout}?${qsStringify(_qsValue)}`

  const change = (nextQueryString = null, cb = identical, replace = false) => {
    const changeHistory = replace
      ? (search) =>
          navigate(`?${search}`, {
            replace: true,
            state: { ...location.state },
          })
      : (search) =>
          navigate(`?${search}`, {
            replace: false,
            state: { ...location.state },
          })

    if (typeof nextQueryString === 'string') {
      // Do not transform when string
      changeHistory(nextQueryString)
      return
    }

    let nextParsed = nextQueryString

    if (typeof nextQueryString === 'function') {
      // current values in location search
      const parsed = qsParse(location.search.slice(1))
      // next values location in search
      // queryString.change(q => ({ ...q, [filter]: value.filter }))
      nextParsed = nextQueryString(parsed)
    }
    // transform to string
    const nextRaw = qsStringify(nextParsed)
    // write to url
    changeHistory(nextRaw)

    cb({ nextParsed, nextRaw })
  }

  const replace = (nextQueryString, cb) => change(nextQueryString, cb, true)

  const raw = location.search
  const parsed = qsParse(raw.slice(1))

  return { raw, parsed, change, replace, qsParse, qsStringify, setQs }
}

export default useQueryString

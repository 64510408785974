import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { z } from 'zod'

import { DEFAULT_NUMBER, validatePhone } from 'src/utils/phoneHelpers'

import { ORGANIZATION_PHONE_QUERY } from './graphql'

export function useOrganizationPhoneQuery() {
  const { data } = useQuery(ORGANIZATION_PHONE_QUERY)

  const navigator = useMemo(
    () => phoneSchema.parse(data?.user?.organization?.navigator),
    [data?.user?.organization?.navigator],
  )

  const pharmacist = useMemo(
    () => phoneSchema.parse(data?.user?.organization?.pharmacist),
    [data?.user?.organization?.pharmacist],
  )

  return { navigator, pharmacist }
}

const phoneSchema = z
  .custom<string>((val) => typeof val === 'string' && validatePhone(val))
  .catch(DEFAULT_NUMBER)

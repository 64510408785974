import { TypedDocumentNode, gql } from '@apollo/client'

import { CustomFeedItem } from './types'

export const CUSTOM_FEED_ITEM_FRAGMENT: TypedDocumentNode<
  CustomFeedItem,
  Record<string, never>
> = gql`
  fragment customFeedItem on CustomFeedItem {
    cardType: card_type
    title
    body
    label
    callToAction: call_to_action
    imageUrl: image_url
    url
  }
`

import styled from 'styled-components'

import StarIcon from 'src/components/SvgIcons/StarIcon'
import TooltipIcon from 'src/components/SvgIcons/TooltipIcon'
import { secondaryGrey, secondaryLightGrey } from 'src/theme/colors'
import { heading5_19 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface RatingsWithTooltipProps {
  rating: number
  onTooltipClick: () => void
}

export function RatingsWithTooltip({
  rating,
  onTooltipClick,
}: RatingsWithTooltipProps) {
  return (
    <Wrapper data-test="ratings">
      <StarIcon />

      <Rating>{rating}</Rating>

      <TooltipWrapper data-test="ratings.tooltip" onClick={onTooltipClick}>
        <TooltipIcon fill={secondaryLightGrey} fillCircle={secondaryGrey} />
      </TooltipWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: ${convertPxToRem(6)};
`

const Rating = styled.span`
  ${heading5_19};

  user-select: none;
  color: ${secondaryGrey};
`

const TooltipWrapper = styled.div`
  width: ${convertPxToRem(16)};
  height: ${convertPxToRem(28)};

  padding: ${convertPxToRem(6, 0)};

  > svg {
    cursor: pointer;
  }
`

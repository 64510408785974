/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react'

import { WindowLocation, useLocation, useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'

import client from 'src/apollo/client'
import useMemberProfileQuery from 'src/apollo/hooks/useMemberProfileQuery'
import { signOut } from 'src/apollo/utils/authHelpers'
import { ROUTES } from 'src/constants/routesConstants'
import { GetAssistanceScreenType } from 'src/sections/SectionRegistration/pages/GetAssistance/model'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'

import { remoteErrorClosed } from '../model'

export function RemoteErrorUnconfirmed() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { state } = useLocation() as WindowLocation<{ email?: string }>
  const dismiss = useUnit(remoteErrorClosed)

  const {
    profileData: { email: profileEmail },
  } = useMemberProfileQuery({
    isSkipError: true,
    isSkipRequest: Boolean(state?.email),
  })

  const email: string | null = profileEmail || state?.email || null

  const onContact = useCallback(async () => {
    await signOut(client, { preventLocationReplace: true })

    dismiss()

    await navigate(`/${ROUTES.GET_ASSISTANCE}`, {
      state: { email, screenType: GetAssistanceScreenType.Universal },
    })
  }, [email, dismiss])

  const i18n = {
    title: t('translation.shared.errors.unconfirmed.title'),
    description: t('translation.shared.errors.unconfirmed.description'),

    contactSupport: t('translation.shared.errors.unconfirmed.contact_support'),
  }

  return (
    <NativeAlert title={i18n.title} description={i18n.description}>
      <NativeAlertAction mode="secondary" onClick={onContact}>
        {i18n.contactSupport}
      </NativeAlertAction>
    </NativeAlert>
  )
}

import { useRef } from 'react'

import { reflect, variant } from '@effector/reflect'
import { WindowLocation, useLocation } from '@reach/router'
import { useGate } from 'effector-react'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import Spinner from 'src/components/Spinner'
import { DrugSearchFlowEntrypoint } from 'src/entities/drugDetails'
import { preferredPharmacyQuery } from 'src/entities/pharmacy'
import { SearchBar, useAutomaticFocus } from 'src/features/SearchBar'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $drugSearchInput,
  DrugSearchGate,
  drugSearchInputChanged,
} from '../model/search'

import { Content } from './content/Content'

interface LocationState {
  returnTo: DrugSearchFlowEntrypoint
}

export function DrugSearch() {
  const {
    state: { returnTo },
  } = useLocation() as WindowLocation<LocationState>

  useGate(DrugSearchGate, { returnTo })

  return <DrugSearchPage />
}

function SearchView() {
  const t = useScopedTranslation(
    'translation.medications.drug_search.placeholders',
  )

  const ref = useRef<HTMLInputElement>(null)

  useAutomaticFocus(ref)

  return (
    <Root>
      <HeaderWrapper>
        <HeaderNavLightTheme />
      </HeaderWrapper>

      <DrugSearchInput placeholder={t('search_drug')} inputRef={ref} />

      <Content />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  flex: 1;

  padding: ${convertPxToRem(24)};
  border-radius: ${convertPxToRem(8)};
`

const HeaderWrapper = styled.div`
  width: 100%;

  padding-bottom: ${convertPxToRem(26)};
`

const DrugSearchInput = reflect({
  view: SearchBar,
  bind: {
    value: $drugSearchInput,
    onChange: drugSearchInputChanged,
  },
})

const DrugSearchPage = variant({
  if: preferredPharmacyQuery.$finished,
  then: SearchView,
  else: Spinner,
})

import { WindowLocation } from '@reach/router'
import { createStore, createEvent, attach, scopeBind, sample } from 'effector'

import { $history, setup } from './core'

export const $path = createStore<string>('/')

const updated = createEvent<WindowLocation>()

const subscribeFx = attach({
  source: $history,
  effect: (history) => {
    const update = scopeBind(updated, { safe: true })
    history.listen(({ location }) => update(location))
  },
})

sample({
  clock: updated,
  fn: (location) => location.pathname,
  target: $path,
})

sample({
  clock: setup,
  source: $history,
  fn: ({ location }) => location.pathname,
  target: $path,
})

sample({
  clock: setup,
  target: subscribeFx,
})

import { gql } from '@apollo/client'

export const PHARMACY_FRAGMENT = gql`
  fragment pharmacy on Pharmacies {
    name
    addressLine1: address_line1
    latitude
    longitude
    phone: telephone_number
    city
    state
    zip
    nabp
    deliveryTime: delivery_time
    workSchedule: work_schedule {
      ...workSchedule
    }
  }

  fragment workSchedule on WorkSchedule {
    closeHour: close_hour
    dayOfWeek: day_of_week
    openHour: open_hour
  }
`

import { TypedDocumentNode, gql } from '@apollo/client'

import { UploadedFileResult, UploadedFileVariables } from './types'

export const UPLOADED_FILE_QUERY: TypedDocumentNode<
  UploadedFileResult,
  UploadedFileVariables
> = gql`
  query uploadedFile($type: UPLOAD_FILE_TYPE!, $id: ID!) {
    file: uploadedFile(type: $type, id: $id) {
      url: short_url
    }
  }
`

import React, { MouseEventHandler } from 'react'

import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { primaryWhite, primaryBlue } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export interface HoverButtonItemProps {
  isWide?: boolean
  dataTestProp?: string
  onClick: () => void
}

interface HoverButtonFactoryParams {
  icon: React.ComponentType
  text: {
    short: string
    wide: string
  }
}

export function hoverButtonItemFactory({
  icon: Icon,
  text,
}: HoverButtonFactoryParams): React.FC<HoverButtonItemProps> {
  return function HoverButtonItem({
    onClick,
    // isWide prop is always exists since it's binded in the parent component
    // eslint-disable-next-line react/require-default-props
    isWide,
    dataTestProp = 'hoverButtonItem',
  }: HoverButtonItemProps) {
    const t = useScopedTranslation('translation.shared.buttons')

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      onClick()
    }

    return (
      <Root onClick={handleClick} data-test={dataTestProp}>
        <Icon />
        <Title>{isWide ? t(text.wide) : t(text.short)}</Title>
      </Root>
    )
  }
}

const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${convertPxToRem(8)};
  background-color: ${primaryBlue};
  height: ${convertPxToRem(40)};

  border-radius: ${convertPxToRem(36)};
  border: none;
  padding: ${convertPxToRem(0, 16)};
`
const Title = styled.span`
  white-space: nowrap;
  color: ${primaryWhite};
  ${textLink2_14};
`

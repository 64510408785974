import {
  ApolloCache,
  MutationUpdaterFunction,
  useMutation,
} from '@apollo/client'

import {
  DOCTOR_CARE_TEAM_FAVORITE_FRAGMENT,
  DOCTOR_CARE_PLAN_FAVORITE_FRAGMENT,
} from '../doctorCareTeamStatuses'

import { CARE_TEAM_DOCTOR_MUTATIONS } from './graphql'
import {
  ToggleCareTeamDoctorVariables,
  ToggleCareTeamDoctorResult,
} from './types'

type Updater = MutationUpdaterFunction<
  ToggleCareTeamDoctorResult,
  ToggleCareTeamDoctorVariables,
  unknown,
  ApolloCache<unknown>
>

const update: Updater = (cache, _, { variables }) => {
  cache.writeFragment({
    id: cache.identify({
      id: variables?.id,
      __typename: 'Doctor',
    }),
    data: { id: variables?.id, isFavorite: !variables?.isFavorite },
    fragment: DOCTOR_CARE_TEAM_FAVORITE_FRAGMENT,
  })
  cache.writeFragment({
    id: cache.identify({
      id: variables?.id,
      __typename: 'CarePlanDoctor',
    }),
    data: { id: variables?.id, isFavorite: !variables?.isFavorite },
    fragment: DOCTOR_CARE_PLAN_FAVORITE_FRAGMENT,
  })
}

export function useToggleDoctorCareTeamMutations(
  id: string,
  isFavorite: boolean,
) {
  const [toggle, { loading: isLoading }] = useMutation(
    CARE_TEAM_DOCTOR_MUTATIONS,
    {
      variables: {
        id,
        isFavorite,
      },
      update,
    },
  )

  return { toggle, isLoading }
}

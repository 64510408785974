import { useCallback } from 'react'

import { ApolloCache, FetchResult, useMutation } from '@apollo/client'
import { useUnit } from 'effector-react'

import { $$chat } from 'src/entities/chat'

import { START_CHAT_MUTATION, START_CHAT_UPDATE_QUERY } from './graphql'
import { StartChatResult } from './types'

export function useStartChatMutation() {
  const openChat = useUnit($$chat.open)

  const onCompleted = useCallback(
    (data: StartChatResult) => {
      if (data.chat?.id) openChat(data.chat.id)
    },
    [openChat],
  )

  const [startChat, { loading: isLoading }] = useMutation(START_CHAT_MUTATION, {
    onCompleted,
    update,
  })

  const start = useCallback(
    () => startChat().then(({ data }) => data?.chat?.id ?? Promise.reject()),
    [startChat],
  )

  return { start, isLoading }
}

function update(
  cache: ApolloCache<unknown>,
  { data }: Omit<FetchResult<StartChatResult>, 'context'>,
) {
  const id = data?.chat?.id
  if (!id) return

  /** Write data to query `chat` so that the chat can be opened instantly */
  cache.writeQuery({
    query: START_CHAT_UPDATE_QUERY,
    variables: { id },
    data: { chat: { __typename: 'Chat', id } },
  })
}

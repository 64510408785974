import { ReactElement } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { $$chat } from 'src/entities/chat'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface PresetItemProps {
  icon: ReactElement

  color: string
  children: string
}

export function PresetItem({ icon, color, children }: PresetItemProps) {
  const select = useUnit($$chat.selectPreset)

  const onClick = () => select(children)

  return (
    <Wrapper onClick={onClick}>
      <Icon $color={color}>{icon}</Icon>
      <Message>&quot;{children}&quot;</Message>
    </Wrapper>
  )
}

const Wrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  outline: none;

  gap: ${convertPxToRem(8)};
  padding: ${convertPxToRem(4)};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(24)};

  background-color: ${primaryWhite};
`

const Icon = styled.div<{ $color: string }>`
  padding: ${convertPxToRem(10)};

  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  background-color: ${({ $color }) => $color};
  border-radius: 50%;

  & > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`

const Message = styled.span`
  ${text1_16};

  text-align: start;

  color: ${secondaryGrey};
`

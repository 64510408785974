import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import LocationExclamationSolid from 'src/components/SvgIcons/LocationExclamationSolid'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { withDispatch } from 'src/store'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { secondaryGrey, secondaryMidGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type ErrorProps = ConnectedProps<typeof connector>

function Error({
  dispatchOpenThirdSectionModal,
  dispatchStartNewChat,
}: ErrorProps) {
  const t = useScopedTranslation('translation.medications.drug_search')
  const i18n = {
    errorMessage: t('search_pharmacies.error_message'),
    button: t('buttons.chat_with_a_pharmacy_guide'),
  }

  const startChat = () => {
    dispatchOpenThirdSectionModal()
    dispatchStartNewChat()
  }

  return (
    <Root data-test="ErrorContent">
      <Content>
        <IconRoundContainer>
          <LocationExclamationSolid />
        </IconRoundContainer>
        <ErrorMessage>{i18n.errorMessage}</ErrorMessage>
      </Content>
      <ChatButton title={i18n.button} theme="primary" handleClick={startChat} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${convertPxToRem(100, 0)};
`
const ErrorMessage = styled.span`
  color: ${secondaryGrey};
  ${text2_14};
  padding: ${convertPxToRem(16, 8)};
  text-align: center;
`
const IconRoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${secondaryMidGrey};
  border-radius: 50%;
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
`
const ChatButton = styled(ButtonBottom)`
  margin-top: auto;
`

const mapDispatchToProps = withDispatch({
  openThirdSectionModal: () => setIsShowThirdSectionModal(true),
  startNewChat,
})

const connector = connect(null, mapDispatchToProps)

export const ErrorContent = connector(Error)

import { gql } from '@apollo/client'

import BILL_CLAIM_FRAGMENT from 'src/apollo/fragments/BILL_CLAIM_FRAGMENT'

const BILL_CLAIMS_SUBSCRIPTION = gql`
  subscription claimsSubscription($resolved: Boolean) {
    claimsEvents(resolved: $resolved) {
      event
      claim {
        ...billClaimFragment
      }
    }
  }
  ${BILL_CLAIM_FRAGMENT}
`

export default BILL_CLAIMS_SUBSCRIPTION

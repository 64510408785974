import {
  StepActionBaseProps,
  StepActionComponent,
  StepActionContent,
  ContentStepType,
  StepData,
} from './types'
import { StepLinkContent } from './ui/content/StepLinkContent'
import { StepRxContent } from './ui/content/StepRxContent'
import { StepTextContent } from './ui/content/StepTextContent'
import { Steps } from './ui/Steps'

export const StepContent = {
  Link: StepLinkContent,
  Text: StepTextContent,
  Rx: StepRxContent,
}

export { Steps, ContentStepType }

export type {
  StepActionBaseProps,
  StepActionContent,
  StepActionComponent,
  StepData,
}

import { useMemo } from 'react'

import styled from 'styled-components'

import { TimeFormatter } from 'src/shared/lib/formatters'
import { secondaryGrey } from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'

interface UpdatedAtProps {
  at: string

  className?: string
}

function UpdatedAtComponent({ at, className = undefined }: UpdatedAtProps) {
  const updatedAt = useMemo(() => TimeFormatter.updatedAt(at), [at])

  return <Root className={className}>{updatedAt}</Root>
}

const Root = styled.span`
  ${text3_12};

  color: ${secondaryGrey};
`

// Allow using `UpdatedAt` as selector
export const UpdatedAt = styled(UpdatedAtComponent)``

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ICoverageSlice {
  isDisplayPharmacy: boolean
}

const initialState: ICoverageSlice = {
  isDisplayPharmacy: false,
}

export const coverageSlice = createSlice({
  name: 'coverage',
  initialState,
  reducers: {
    setIsDisplayPharmacy: (state, { payload }: PayloadAction<boolean>) => {
      state.isDisplayPharmacy = payload
    },
  },
})

export const { setIsDisplayPharmacy } = coverageSlice.actions

export default coverageSlice.reducer

import React, { useCallback, useMemo } from 'react'

import { InfoBox, InfoBoxProps } from '@react-google-maps/api'
import styled from 'styled-components'

import {
  LargeMapBaseMarker,
  LargeMapMarkerComponent,
  LargeMapProvidedProps,
} from 'src/features/LargeMap'
import { GeoTagIcon } from 'src/shared/ui/icons/misc/GeoTagIcon'
import { primaryDarkNavy, primaryWhite, primaryBlue } from 'src/theme/colors'
import { textLink3_10 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const infoBoxOptions: InfoBoxProps['options'] = {
  boxStyle: {
    overflow: 'unset',
    width: 'auto',
  },
  pane: 'mapPane',
  closeBoxURL: '',
  enableEventPropagation: true,
  alignBottom: true,
}

interface PreferredPharmacyMarker extends LargeMapBaseMarker {
  name: string
}

interface PreferredPharmacyMarkerProps
  extends LargeMapProvidedProps<PreferredPharmacyMarker> {
  onClick: (name: string) => void
  selectedMarkerId: string
}

function PreferredPharmacyMarkerComponent({
  marker,
  onClick,
  selectedMarkerId,
  map,
}: PreferredPharmacyMarkerProps) {
  const { name, latitude, longitude } = marker

  const position = useMemo(
    () =>
      new google.maps.LatLng({
        lat: latitude,
        lng: longitude,
      }),
    [latitude, longitude],
  )

  const isSelected = useMemo(
    () => selectedMarkerId === name,
    [name, selectedMarkerId],
  )
  const onSelectMarker = useCallback(() => {
    map.panTo(position)
    onClick(name)
  }, [map, name, onClick, position])

  return (
    <InfoBox
      zIndex={isSelected ? 2 : 1}
      position={position}
      options={infoBoxOptions}
    >
      <Root
        onClick={onSelectMarker}
        data-is-selected={isSelected}
        data-test="preferredPharmacyMarker"
      >
        <GeoTagVector />
        <Content>
          <MarkerTitle>{name}</MarkerTitle>
        </Content>
      </Root>
    </InfoBox>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-direction: column;
  border-radius: ${convertPxToRem(5)};
  padding: ${convertPxToRem(10)};
  background-color: ${primaryWhite};

  [data-is-selected='true'] & {
    background-color: ${primaryBlue};
  }
`
const MarkerTitle = styled.span`
  ${textLink3_10};
  white-space: nowrap;
  min-width: ${convertPxToRem(30)};
  color: ${primaryDarkNavy};

  [data-is-selected='true'] & {
    color: ${primaryWhite};
  }
`
const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${convertPxToRem(10)};
  cursor: pointer;
`
const GeoTagVector = styled(GeoTagIcon)`
  position: absolute;
  bottom: 0;

  > path {
    fill: ${primaryWhite};
  }

  [data-is-selected='true'] & > path {
    fill: ${primaryBlue};
  }
`

export const PreferredPharmacyMarker =
  PreferredPharmacyMarkerComponent as LargeMapMarkerComponent<PreferredPharmacyMarkerProps>

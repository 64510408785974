import { TypedDocumentNode, gql } from '@apollo/client'

import { DrugInfoResult, DrugInfoVariables } from './types'

export const DRUG_INFO_QUERY: TypedDocumentNode<
  DrugInfoResult,
  DrugInfoVariables
> = gql`
  query drugInfo($drugName: String!) {
    drugInfo: pdsDrug(name: $drugName) {
      drug {
        ...drug
      }
      alternatives {
        ...drug
      }
    }
  }

  fragment drug on Drug {
    name
    isAlternativesOnly: alternatives_only
    configurations {
      daysSupply: days_supply
      form
      dosages {
        dosage
        packages {
          gpi
          ndc
          package
        }
      }
    }
  }
`

import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  modalBlackOpacity,
  primaryDarkNavy,
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading6_16_Medium, text1_16, text2_14_Medium } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ModalActionConfirmation({
  title = '',
  description,
  buttons = [],
  isBold = false,
  hPadding,
}) {
  return (
    <Root>
      <Modal data-test="action-confirmation-modal">
        <Content hPadding={hPadding}>
          {Boolean(title) && <Title isBold={isBold}>{title}</Title>}
          {Boolean(description) && <Description>{description}</Description>}
        </Content>

        <ButtonBottomWrapper>
          {buttons.map((button) => (
            <Button
              key={button.text}
              onClick={button.onClick}
              textColor={button.textColor}
            >
              {button.text}
            </Button>
          ))}
        </ButtonBottomWrapper>
      </Modal>
    </Root>
  )
}

const Root = styled.div`
  position: fixed;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${modalBlackOpacity};
  padding: ${convertPxToRem(0, 15)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
`

const Modal = styled.div`
  background-color: ${primaryWhite};
  max-width: ${convertPxToRem(400)};
  min-height: ${convertPxToRem(150)};
  display: flex;
  margin: auto;
  flex-direction: column;
  border-radius: ${convertPxToRem(14)};
  position: relative;
  ${mediaQueryFor.mobile} {
    max-width: ${convertPxToRem(300)};
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ hPadding }) =>
    convertPxToRem(22, hPadding || 21, 0, hPadding || 22)};
  min-height: ${convertPxToRem(107)};
`

const Title = styled.span`
  text-align: center;
  color: ${primaryDarkNavy};
  ${heading6_16_Medium};
  font-weight: ${({ isBold }) => (isBold ? 'bolder' : 'normal')};
`
const Description = styled.span`
  padding-bottom: ${convertPxToRem(15)};
  text-align: center;
  margin-top: ${convertPxToRem(6)};
  color: ${secondaryGrey};
  ${text2_14_Medium};
`

const ButtonBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`

const Button = styled.button`
  background-color: ${primaryWhite};
  color: ${({ textColor }) => textColor || `${secondaryGrey}`};
  height: ${convertPxToRem(43)};
  width: ${convertPxToRem(200)};
  padding: ${convertPxToRem(10, 0)};
  display: flex;
  justify-content: center;
  ${text1_16};
  border-bottom: none;
  border-left: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-right: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  :only-child {
    width: ${convertPxToRem(400)};
  }
  :first-child {
    border-left: none;
    border-bottom-left-radius: ${convertPxToRem(14)};
  }
  :last-child {
    border-right: none;
    border-bottom-right-radius: ${convertPxToRem(14)};
  }
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  cursor: pointer;
`

ModalActionConfirmation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
      textColor: PropTypes.string,
    }),
  ),
  isBold: PropTypes.bool,
  hPadding: PropTypes.number,
}

export default ModalActionConfirmation

import { useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { InboxStatus } from '@features/InboxStatus'

import { $$device } from 'src/shared/lib/device'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryWhite } from 'src/theme/colors'

import { CallButton } from './CallButton'

interface UniversalHeaderProps {
  theme?: 'light' | 'dark'
  mode?: 'back' | 'close'
  onClick?: () => void
  className?: string
}

export function UniversalHeader({
  theme = 'light',
  mode = 'back',
  onClick = undefined,
  className = undefined,
}: UniversalHeaderProps) {
  const isMobile = useUnit($$device.$mobile)

  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) onClick()
    else void navigate(-1)
  }

  return (
    <Root data-theme={theme} data-test="universalHeader" className={className}>
      <NavigateButton
        data-test={`universalHeader.${mode}`}
        onClick={handleClick}
      >
        <Sprite name={mode === 'close' ? 'nav/close' : 'nav/back'} />
      </NavigateButton>

      {isMobile && <InboxButton />}

      {isMobile && <ButtonCall />}
    </Root>
  )
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-areas: 'navigate inbox call';
  align-items: center;

  width: 100%;
  background-color: inherit;
`

const NavigateButton = styled(NavButton)`
  grid-area: navigate;
  pointer-events: auto;

  [data-theme='dark'] & {
    background-color: inherit;
    box-shadow: none;

    & > svg {
      color: ${primaryWhite};
    }
  }
`

const InboxButton = styled(InboxStatus)`
  grid-area: inbox;
  pointer-events: auto;

  [data-theme='dark'] & {
    box-shadow: none;
  }
`

const ButtonCall = styled(CallButton)`
  grid-area: call;
  justify-self: end;
  pointer-events: auto;

  [data-theme='dark'] & {
    background-color: inherit;
    box-shadow: none;

    & > svg {
      color: ${primaryWhite};
    }
  }
`

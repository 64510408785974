import { IconProps } from 'src/components/SvgIcons/types'
import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export default function KebabIcon({
  className = undefined,
  onClick = undefined,
  width = convertPxToRem(20),
  height = convertPxToRem(20),
}: IconProps) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test="kebabIcon"
      width={width}
      height={height}
    >
      <circle cx="12" cy="4" r="2" fill={primaryDarkNavy} />
      <circle cx="12" cy="20" r="2" fill={primaryDarkNavy} />
      <circle cx="12" cy="12" r="2" fill={primaryDarkNavy} />
    </svg>
  )
}

export interface IconsMap {
  action:
    | 'attachment'
    | 'delete'
    | 'expand'
    | 'eye_closed'
    | 'eye_opened'
    | 'send'
  benefits:
    | 'diagnostics'
    | 'emergency'
    | 'hospital'
    | 'maternity'
    | 'mental'
    | 'pharmacy'
    | 'preventative'
    | 'primary'
    | 'specialist'
    | 'telehealth'
    | 'urgent'
  calendar: 'add' | 'confirmed' | 'pending'
  doctor:
    | 'allergist'
    | 'back_pain'
    | 'blood_test'
    | 'bone_density_test'
    | 'chiropractor'
    | 'dentist'
    | 'dermatologist'
    | 'endocrinologist'
    | 'gastroenterology'
    | 'general'
    | 'gynecologist'
    | 'hip_pain'
    | 'knee_pain'
    | 'mri'
    | 'neurology'
    | 'ophthalmology'
    | 'orthodontist'
    | 'orthopedics'
    | 'pediatrician'
    | 'physical_therapy'
    | 'podiatrist'
    | 'primary_care_doctor'
    | 'psychiatrist'
    | 'radiology'
    | 'shoulder_pain'
    | 'specialist'
    | 'urgent_care'
    | 'wellness_visit'
  facility:
    | 'dental_care'
    | 'dialysis'
    | 'emergency_rooms'
    | 'hospitals'
    | 'long_term_care_facilities'
    | 'pharmacies'
    | 'physical_therapy_and_chiropractors'
    | 'primary_care'
    | 'rehabilitation_centers'
    | 'specialty_care'
    | 'testing_and_diagnostics'
    | 'urgent_care'
    | 'vision_care'
  insurance: 'doctor' | 'eye'
  logo: 'clearcost' | 'rightway_text' | 'rightway'
  map: 'locator' | 'pin' | 'selectedMarker' | 'target'
  misc:
    | 'add'
    | 'briefcase'
    | 'call'
    | 'check'
    | 'chevron'
    | 'clipboard'
    | 'clock'
    | 'edit'
    | 'exclamation_mark'
    | 'external'
    | 'file_image'
    | 'file_pdf'
    | 'filter'
    | 'heart'
    | 'individual'
    | 'list'
    | 'phone_rotary'
    | 'physician_experience'
    | 'pill'
    | 'question'
    | 'schedule'
    | 'search'
    | 'send'
    | 'star_half'
    | 'star'
    | 'step_circle'
    | 'step_dots'
    | 'tooth'
    | 'truck'
    | 'xmark'
  nav: 'back' | 'close' | 'drag_line' | 'more' | 'swipe'
  pbm:
    | 'care_complete'
    | 'info'
    | 'medication'
    | 'pills'
    | 'prescription'
    | 'progress'
    | 'special'
  profile:
    | 'award'
    | 'baby'
    | 'background_check'
    | 'bandage'
    | 'certification'
    | 'education'
    | 'globe'
    | 'heart_rate'
    | 'instrument_syringe'
    | 'medical'
    | 'membership'
    | 'personnel_doctor'
    | 'single_woman_circle'
    | 'surgeries_record'
  services:
    | 'billing_advocacy'
    | 'chat'
    | 'coverage'
    | 'facility'
    | 'home'
    | 'mail'
    | 'options_for_care'
    | 'primary_care_doctor'
    | 'profile'
    | 'rx'
    | 'telemedicine'
  settings:
    | 'dependents'
    | 'feedback'
    | 'health_profile'
    | 'launch'
    | 'sign_out'
    | 'wellness'
}
export const IconsMeta: {
  [Key in keyof IconsMap]: {
    filePath: string
    items: Record<
      IconsMap[Key],
      {
        viewBox: string
        width: number
        height: number
      }
    >
  }
} = {
  action: {
    filePath: 'action.svg',
    items: {
      attachment: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      delete: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      expand: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      eye_closed: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      eye_opened: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      send: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  benefits: {
    filePath: 'benefits.svg',
    items: {
      diagnostics: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      emergency: {
        viewBox: '0 0 22 20',
        width: 22,
        height: 20,
      },
      hospital: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      maternity: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      mental: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pharmacy: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      preventative: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      primary: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      specialist: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      telehealth: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      urgent: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  calendar: {
    filePath: 'calendar.svg',
    items: {
      add: {
        viewBox: '0 0 21 24',
        width: 21,
        height: 24,
      },
      confirmed: {
        viewBox: '0 0 21 24',
        width: 21,
        height: 24,
      },
      pending: {
        viewBox: '0 0 27 24',
        width: 27,
        height: 24,
      },
    },
  },
  doctor: {
    filePath: 'doctor.svg',
    items: {
      allergist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      back_pain: {
        viewBox: '0 0 26 25',
        width: 26,
        height: 25,
      },
      blood_test: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      bone_density_test: {
        viewBox: '0 0 480 480',
        width: 480,
        height: 480,
      },
      chiropractor: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      dentist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      dermatologist: {
        viewBox: '0 0 25 26',
        width: 25,
        height: 26,
      },
      endocrinologist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      gastroenterology: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      general: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      gynecologist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      hip_pain: {
        viewBox: '0 0 25 26',
        width: 25,
        height: 26,
      },
      knee_pain: {
        viewBox: '0 0 24 26',
        width: 24,
        height: 26,
      },
      mri: {
        viewBox: '0 0 26 26',
        width: 26,
        height: 26,
      },
      neurology: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      ophthalmology: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      orthodontist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      orthopedics: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      pediatrician: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      physical_therapy: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      podiatrist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      primary_care_doctor: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      psychiatrist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      radiology: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      shoulder_pain: {
        viewBox: '0 0 24 26',
        width: 24,
        height: 26,
      },
      specialist: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      urgent_care: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      wellness_visit: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  facility: {
    filePath: 'facility.svg',
    items: {
      dental_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      dialysis: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      emergency_rooms: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      hospitals: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      long_term_care_facilities: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pharmacies: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      physical_therapy_and_chiropractors: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      primary_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      rehabilitation_centers: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      specialty_care: {
        viewBox: '0 0 20 19',
        width: 20,
        height: 19,
      },
      testing_and_diagnostics: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      urgent_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      vision_care: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  insurance: {
    filePath: 'insurance.svg',
    items: {
      doctor: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      eye: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  logo: {
    filePath: 'logo.svg',
    items: {
      clearcost: {
        viewBox: '0 0 369 40',
        width: 369,
        height: 40,
      },
      rightway_text: {
        viewBox: '0 0 95 26',
        width: 95,
        height: 26,
      },
      rightway: {
        viewBox: '0 0 100 100',
        width: 100,
        height: 100,
      },
    },
  },
  map: {
    filePath: 'map.svg',
    items: {
      locator: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      pin: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      selectedMarker: {
        viewBox: '0 0 29 45',
        width: 29,
        height: 45,
      },
      target: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  misc: {
    filePath: 'misc.svg',
    items: {
      add: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      briefcase: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      call: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      check: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      chevron: {
        viewBox: '0 0 12 12',
        width: 12,
        height: 12,
      },
      clipboard: {
        viewBox: '0 0 18 24',
        width: 18,
        height: 24,
      },
      clock: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      edit: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      exclamation_mark: {
        viewBox: '0 0 8 8',
        width: 8,
        height: 8,
      },
      external: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      file_image: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      file_pdf: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      filter: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      heart: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      individual: {
        viewBox: '0 0 7 15',
        width: 7,
        height: 15,
      },
      list: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      phone_rotary: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      physician_experience: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      pill: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      question: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      schedule: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      search: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      send: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      star_half: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      star: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      step_circle: {
        viewBox: '0 0 14 14',
        width: 14,
        height: 14,
      },
      step_dots: {
        viewBox: '0 0 4 12',
        width: 4,
        height: 12,
      },
      tooth: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      truck: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      xmark: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
    },
  },
  nav: {
    filePath: 'nav.svg',
    items: {
      back: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      close: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      drag_line: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      more: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      swipe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  pbm: {
    filePath: 'pbm.svg',
    items: {
      care_complete: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      info: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      medication: {
        viewBox: '0 0 43 35',
        width: 43,
        height: 35,
      },
      pills: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      prescription: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      progress: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      special: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  profile: {
    filePath: 'profile.svg',
    items: {
      award: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      baby: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      background_check: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      bandage: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      certification: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      education: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      globe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      heart_rate: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      instrument_syringe: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      medical: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      membership: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      personnel_doctor: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      single_woman_circle: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      surgeries_record: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
  services: {
    filePath: 'services.svg',
    items: {
      billing_advocacy: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      chat: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      coverage: {
        viewBox: '0 0 24 19',
        width: 24,
        height: 19,
      },
      facility: {
        viewBox: '0 0 24 22',
        width: 24,
        height: 22,
      },
      home: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      mail: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      options_for_care: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      primary_care_doctor: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      profile: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      rx: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      telemedicine: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
  settings: {
    filePath: 'settings.svg',
    items: {
      dependents: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      feedback: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      health_profile: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      launch: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      sign_out: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      wellness: {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
    },
  },
}

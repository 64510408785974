import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading8_14, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface UpdatePharmacyPopupContentProps {
  title: string
  description?: string
}

export function UpdatePharmacyPopupContent({
  title,
  description = undefined,
}: UpdatePharmacyPopupContentProps) {
  const t = useScopedTranslation(
    'translation.medications.drug_search.labels.favorite_toasts',
  )

  return (
    <Root>
      <Title>{t(title)}</Title>
      {description && <Description>{t(description)}</Description>}
    </Root>
  )
}

const Title = styled.span`
  color: ${primaryDarkNavy};
  ${heading8_14};
`

const Description = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(4)};
`

import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { ACCEPT_APPOINTMENT_TIME_SLOT_MUTATION } from './document'

export function useAcceptAppointmentTimeSlotMutation() {
  const [acceptAppointmentTimeSlotM, { loading: isAcceptancePending }] =
    useMutation(ACCEPT_APPOINTMENT_TIME_SLOT_MUTATION, { errorPolicy: 'none' })

  const acceptTimeSlot = useCallback(
    (timeSlot: string) =>
      acceptAppointmentTimeSlotM({ variables: { timeSlot } }),
    [acceptAppointmentTimeSlotM],
  )

  return {
    acceptTimeSlot,
    isAcceptancePending,
  }
}

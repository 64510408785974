import React, { useContext } from 'react'

import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import RightwayText from 'src/assets/images/rightway_logo_text.svg'
import { ROUTES } from 'src/constants/routesConstants'
import { HeaderAnimationContext } from 'src/sections/SectionHome/HeaderAnimationContext'
import {
  secondaryGrey,
  secondaryLightGrey,
  secondaryBackgroundGrey,
} from 'src/theme/colors'
import { text4_10 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { checkRootPathName } from 'src/utils/routeHelpers'

const RightwayFooter = () => {
  const { t } = useTranslation()
  const links = [
    {
      title: t('translation.home.tabbar.privacy_title'),
      href: 'https://rightwayhealthcare.com/termsservices/',
    },
    {
      title: t('translation.home.tabbar.privacy_policy'),
      href: 'https://rightwayhealthcare.com/privacypolicy/',
    },
  ]
  const location = useLocation()
  const { isChangeGetHelpBtn } = useContext(HeaderAnimationContext)
  const isActivityTab = checkRootPathName(ROUTES.HOME_ACTIVITY, location)

  return isActivityTab ? (
    <Root isVisible={isChangeGetHelpBtn}>
      <LinksContainer>
        {links.map((link) => (
          <FooterLink
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkTitle>{link.title}</LinkTitle>
          </FooterLink>
        ))}
      </LinksContainer>
      <TextLogoContainer>
        <PoweredByText>
          Powered By <Image src={RightwayText} alt="rightwayText" />
        </PoweredByText>
      </TextLogoContainer>
    </Root>
  ) : null
}

export default RightwayFooter

const Root = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'none' : 'flex')};
  width: 100%;
  justify-content: space-between;
  background-color: ${secondaryBackgroundGrey};
  border-top: ${convertPxToRem(1)} solid ${secondaryLightGrey};

  ${mediaQueryFor.desktop} {
    display: none;
  }
  ${mediaQueryFor.tablet} {
    display: none;
  }

  ${`@media (max-width: 352px)`} {
    flex-direction: column;
  }
`

const LinksContainer = styled.div`
  display: flex;
`

const LinkTitle = styled.u``

const FooterLink = styled.a`
  padding: ${convertPxToRem(16, 8)};
  color: ${secondaryGrey};
  text-transform: uppercase;
  ${text4_10};
`

const Image = styled.img``

const TextLogoContainer = styled.div`
  display: flex;
  padding: ${convertPxToRem(16, 8)};
  margin-left: auto;

  ${`@media (max-width: 352px)`} {
    margin-left: 0;
  }
`

const PoweredByText = styled.div`
  padding-right: ${convertPxToRem(5)};
  color: ${secondaryGrey};
  text-transform: uppercase;
  ${text4_10};
`

import { SCREEN_SIZES } from 'src/theme/mediaQueries'

const REM_DEFAULT_VALUE = 16

export const convertPxToRem = (...values: number[]) =>
  values
    .map((item) => item / REM_DEFAULT_VALUE)
    .map((item) => (item === 0 ? item : `${item.toFixed(2)}rem`))
    .join(' ')

/** @deprecated Use `shared/lib/device` instead */
export const isDesktopView = window.innerWidth >= SCREEN_SIZES.DESKTOP

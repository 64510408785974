import {
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector'
import { combineEvents, debounce, not } from 'patronum'

import { $$auth } from '@shared/auth'

import { ROUTES } from 'src/constants/routesConstants'

import { USER_ACTIVITY_EVENTS } from './lib'

export const userMoved = createEvent<void>()
export const userTimedOut = createEvent<void>()
export const sessionExtended = createEvent<void>()
export const userListenersSetup = createEvent<void>()
export const userListenersCleared = createEvent<void>()

export const setupUserListenersFx = createEffect<void, () => void>()
const destroyUserListenersFx = createEffect<() => void, void>()

const openExpiredSessionFx = createEffect(() =>
  window.location.replace(`/${ROUTES.EXPIRED_SESSION}`),
)

export const $isTimeoutAlertVisible =
  createStore<boolean>(false).reset(sessionExtended)

sample({
  clock: debounce({ source: userMoved, timeout: 240_000 }),
  fn: () => true,
  target: $isTimeoutAlertVisible,
})

sample({
  clock: debounce({
    source: sample({ clock: userMoved, filter: not($isTimeoutAlertVisible) }),
    timeout: 300_000,
  }),
  target: userTimedOut,
})

sample({
  clock: sessionExtended,
  target: userMoved,
})

sample({
  clock: userListenersSetup,
  target: setupUserListenersFx,
})

sample({
  clock: userListenersCleared,
  source: setupUserListenersFx.doneData,
  target: destroyUserListenersFx,
})

sample({
  clock: userTimedOut,
  target: $$auth.signOut.start,
})

sample({
  clock: combineEvents([userTimedOut, $$auth.signOut.completed]),
  target: openExpiredSessionFx,
})

setupUserListenersFx.use(() => {
  const reset = scopeBind(userMoved, { safe: true }) as () => void

  for (const name of USER_ACTIVITY_EVENTS) {
    document.addEventListener(name, reset, { passive: true })
  }

  return () => {
    for (const name of USER_ACTIVITY_EVENTS) {
      document.removeEventListener(name, reset)
    }
  }
})

destroyUserListenersFx.use((destroy) => destroy())

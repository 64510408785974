import { ApolloClient, InMemoryCache } from '@apollo/client'

import link, { onClientCreated } from 'src/apollo/links'
import config from 'src/config'

import { typePolicies } from './cacheTypePolicy'
import { mockTypeDefinitions } from './local/schema'
import { GraphQLTypesRelations } from './utils/relations'

export const cache = new InMemoryCache({
  typePolicies,
  possibleTypes: GraphQLTypesRelations,
})

const client = new ApolloClient({
  link,
  cache,
  devtools: { enabled: config.CONNECT_APOLLO_DEVTOOLS },
  typeDefs: [mockTypeDefinitions],
})

export default client

onClientCreated(client)

import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { condition, equals } from 'patronum'

import { UserLocationsStep } from '../types'

export const UserLocations = createDomain('Locations')

export const UserLocationsGate = createGate<Record<string, never>>({
  name: 'UserLocationsGate',
  domain: UserLocations,
})

export const stepChanged = UserLocations.event<UserLocationsStep>()
export const stepChangedBack = UserLocations.event<void>()

export const $step = UserLocations.store<UserLocationsStep>(
  UserLocationsStep.List,
)
  .on(stepChanged, (_, step) => step)
  .reset(UserLocationsGate.close)

export const $isClosable = equals($step, UserLocationsStep.List)

condition({
  source: sample({ clock: stepChangedBack, source: $step }),
  if: equals($step, UserLocationsStep.Edit),
  then: stepChanged.prepend(() => UserLocationsStep.Search),
  else: stepChanged.prepend(() => UserLocationsStep.List),
})

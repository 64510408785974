import { IconProps } from 'src/components/SvgIcons/types'
import { statusActive } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function SuccessIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = statusActive,
  className = undefined,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm4.523 8.273-5 5c-.214.215-.492.32-.773.32s-.56-.105-.774-.32l-2.5-2.5a1.095 1.095 0 0 1 1.548-1.548l1.726 1.728 4.227-4.226a1.095 1.095 0 0 1 1.548 0 1.097 1.097 0 0 1-.002 1.546z"
        fill={fill}
      />
    </svg>
  )
}

export default SuccessIcon

import { createEvent, createStore, sample } from 'effector'
import { createMutation } from 'effector-apollo'
import { condition } from 'patronum'

import client from 'src/apollo/client'

import { TOGGLE_FAVORITE_FACILITY_MUTATION } from '../api/toggleFavoriteFacilityMutation'
import { ToggleFavoriteFacilityVariables } from '../api/toggleFavoriteFacilityMutation/types'

import {
  addFacilityErrorPopupShown,
  addFacilitySuccessPopupShown,
  removeFacilityErrorPopupShown,
  removeFacilitySuccessPopupShown,
} from './popup'
import { toggleInFacilityUpdateFx } from './updateFragments'

export const facilityFavoriteToggled =
  createEvent<ToggleFavoriteFacilityVariables>()

const toggleFacilityCareTeamMutation = createMutation({
  client,
  document: TOGGLE_FAVORITE_FACILITY_MUTATION,
})

export const $facilityPendingById = createStore<string[]>([])
  .on(toggleFacilityCareTeamMutation.start, (state, { id }) => [...state, id])
  .on(toggleFacilityCareTeamMutation.finished.finally, (state, { variables }) =>
    state.filter((id) => id !== variables.id),
  )

sample({
  clock: facilityFavoriteToggled,
  target: toggleFacilityCareTeamMutation.start,
})

sample({
  source: toggleFacilityCareTeamMutation.finished.success,
  fn: ({ variables }) => variables,
  target: toggleInFacilityUpdateFx,
})

condition({
  source: toggleFacilityCareTeamMutation.finished.success,
  if: ({ variables }) => variables.isFavorite,
  then: removeFacilitySuccessPopupShown,
  else: addFacilitySuccessPopupShown,
})

condition({
  source: toggleFacilityCareTeamMutation.finished.failure,
  if: ({ variables }) => variables.isFavorite,
  then: removeFacilityErrorPopupShown,
  else: addFacilityErrorPopupShown,
})

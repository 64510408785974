import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import { LocationKindType } from 'src/shared/api/types'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  $editableLocation,
  $editableLocationId,
  locationConfirmed,
} from '../../model/edit'
import { $locationsUpdatePending } from '../../model/requests'

import { AddressFields } from './AddressFields'
import { LocationName } from './LocationName'

export function EditLocation() {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  const locationId = useUnit($editableLocationId)

  const isNewLocation = locationId === null

  return (
    <Root data-new={isNewLocation}>
      <Content>
        <AddressFields />

        <LocationName />
      </Content>

      <ButtonContinue
        title={isNewLocation ? t('confirm_address') : t('update_address')}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  gap: ${convertPxToRem(40)};

  &[data-new='true'] {
    gap: ${convertPxToRem(24)};
  }

  ${mediaQueryFor.mobile} {
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${convertPxToRem(40)};
`

const ButtonContinue = reflect({
  view: ButtonBottom,
  bind: {
    theme: 'primary',
    handleClick: locationConfirmed,
    loading: $locationsUpdatePending,
    disabled: $editableLocation.map(
      (location) =>
        location?.kind === LocationKindType.OtherAddress && !location?.name,
    ),
  },
})

import { TypedDocumentNode, gql } from '@apollo/client'

import { LOCATION_FRAGMENT } from '../location/document'

import { LocationsResult, LocationsVariables } from './types'

export const LOCATIONS_QUERY: TypedDocumentNode<
  LocationsResult,
  LocationsVariables
> = gql`
  query locations {
    currentUser {
      id
      locations {
        ...userLocation
      }
    }
  }

  ${LOCATION_FRAGMENT}
`

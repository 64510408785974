import React from 'react'

import styled from 'styled-components'

import { primaryDarkNavy, featurePaleGreen } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface NoticeBubbleProps {
  children?: React.ReactNode
  icon?: React.ReactElement | null
  className?: string
  dataTestProp?: string
}

export function NoticeBubble({
  children = undefined,
  icon = null,
  className = '',
  dataTestProp = 'noticeBubble',
}: NoticeBubbleProps) {
  if (!children) {
    return null
  }

  return (
    <Root className={className} data-test={dataTestProp}>
      {icon}
      <Title>{children}</Title>
    </Root>
  )
}

const Root = styled.div`
  padding: ${convertPxToRem(12, 16)};
  border-radius: ${convertPxToRem(8)};
  display: flex;
  gap: ${convertPxToRem(8)};
  background-color: ${featurePaleGreen};
  width: 100%;

  svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`
const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${text2_14};
`

export interface PreparedGraphQLErrors {
  graphQLErrors: {
    message: string
    code: string
    requestId: string
    title: string
    email?: string
    resetPassword?: boolean
  }
}

export function getGraphQlError({
  graphQLErrors: [errors] = [],
}: any = {}): PreparedGraphQLErrors {
  const graphQLErrors = {
    code: errors?.extensions?.code ?? '',
    message: errors?.message ?? '',
    requestId: errors?.extensions?.request_identifier ?? '',
    title: errors?.extensions?.title ?? '',
    email: errors?.extensions?.email,
    resetPassword: errors?.extensions?.reset_password,
    errors: errors?.extensions?.errors,
  }

  return { graphQLErrors }
}

// TODO: fix TS types
export function formatErrors(errors: Record<string, any>, ids: Array<any>) {
  const extractErrors = Object.entries(errors).map((error) => ({
    id: error[0],
    message: error[1].message,
  }))

  const formattedErrors = extractErrors.map((t1) => ({
    ...t1,
    ...ids.find((t2) => (t2.name || t2.id) === t1.id),
  }))

  const newErrorMessages = formattedErrors.map((error) => ({
    [error.displayName]: { message: error.message },
  }))

  return newErrorMessages
}

import React from 'react'

import styled from 'styled-components'

import { ModalBase } from 'src/theme/baseStyles'
import {
  modalBlackOpacity,
  nativeSystemSeparator,
  nativeSystemBlack,
} from 'src/theme/colors'
import { heading2_17_Medium, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type AtLeastOne<T> = Partial<T> & { [K in keyof T]: Pick<T, K> }[keyof T]

interface NativeAlertContentProps {
  title: React.ReactNode
  description: React.ReactNode
}

interface NativeAlertBaseProps {
  dataTestProp?: string
  className?: string

  children?: React.ReactNode
}

export type NativeAlertProps = NativeAlertBaseProps &
  AtLeastOne<NativeAlertContentProps>

export function NativeAlert({
  title,
  description,

  dataTestProp = 'nativeAlert',
  className = undefined,

  children = null,
}: NativeAlertProps) {
  return (
    <Background>
      <Alert role="dialog" className={className} data-test={dataTestProp}>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>

        {children}
      </Alert>
    </Background>
  )
}

const Background = styled.div`
  ${ModalBase};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${modalBlackOpacity};
`

const Alert = styled.div`
  background-color: #f8f8f8;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-template-areas: 'content';

  width: ${convertPxToRem(270)};

  border-radius: 14px;

  button {
    border-top: ${convertPxToRem(0.5)} inset ${nativeSystemSeparator};
  }
`

const Content = styled.div`
  grid-area: content;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  gap: ${convertPxToRem(6)};

  padding: ${convertPxToRem(19, 16)};

  text-align: center;
  color: ${nativeSystemBlack};
`

const Title = styled.span`
  ${heading2_17_Medium};
`

const Description = styled.span`
  ${text2_14};
`

import { useQuery } from '@apollo/client'

import { APPOINTMENT_QUERY } from './document'
import { AppointmentResult, AppointmentVariables } from './types'

export function useAppointmentQuery(id: string) {
  const { data, loading: isAppointmentLoading } = useQuery<
    AppointmentResult,
    AppointmentVariables
  >(APPOINTMENT_QUERY, {
    variables: { id },
    errorPolicy: 'none',
    /**
     * @see {@link https://rightwayhealthcare.atlassian.net/browse/NEX-13483}
     */
    fetchPolicy: 'cache-and-network',
  })

  return {
    appointment: data?.appointment,
    isAppointmentLoading,
  }
}

import { attach, sample } from 'effector'
import { createGate } from 'effector-react'
import { equals, not, or } from 'patronum'

import {
  $$geolocation,
  LocationState,
  RequestMode,
} from 'src/entities/geolocation'
import { LocationKindType } from 'src/shared/api/types'
import { select } from 'src/shared/lib/effector/select'

import { UserLocation } from '../api/location'
import { buildAddressFromAttributes } from '../lib/address'
import { DeviceLocationState } from '../types'

import { UserLocations } from './core'
import { geocodeCoordinatesFx } from './google'
import { locationsRequested } from './requests'

export const CURRENT_LOCATION_ID = 'current'

export const GeolocationControllerGate = createGate<Record<string, never>>({
  domain: UserLocations,
  name: 'GeolocationControllerGate',
})

export const geolocationStarted = UserLocations.event<void>()

export const currentGeolocationRequested = UserLocations.event<void>()

export const $currentGeolocation = UserLocations.store<UserLocation | null>(
  null,
)

const geocodeCurrentLocationFx = attach({ effect: geocodeCoordinatesFx })

export const $currentGeolocationPending = or(
  equals($$geolocation.$state, LocationState.Requested),
  geocodeCoordinatesFx.pending,
)

export const $currentGeolocationState = select(
  [
    [$currentGeolocation, DeviceLocationState.Aquired],
    [$currentGeolocationPending, DeviceLocationState.Loading],
    [$$geolocation.permission.$granted, DeviceLocationState.Available],
    [$$geolocation.permission.$prompt, DeviceLocationState.Accessible],
  ],
  DeviceLocationState.Restricted,
)

sample({
  clock: GeolocationControllerGate.open,
  target: geolocationStarted,
})

sample({
  clock: geolocationStarted,
  target: [$$geolocation.permissionCheckRequested, locationsRequested],
})

sample({
  clock: geolocationStarted,
  filter: $$geolocation.permission.$granted,
  fn: () => RequestMode.Auto,
  target: $$geolocation.coordinatesRequested,
})

sample({
  clock: geolocationStarted,
  source: $$geolocation.$current,
  filter: Boolean,
  target: geocodeCurrentLocationFx,
})

sample({
  clock: sample({
    source: $$geolocation.permission.$granted,
    filter: Boolean,
  }),
  filter: GeolocationControllerGate.status,
  fn: () => RequestMode.Auto,
  target: $$geolocation.coordinatesRequested,
})

sample({
  source: sample({
    clock: $$geolocation.$current,
    filter: Boolean,
  }),
  filter: GeolocationControllerGate.status,
  target: geocodeCurrentLocationFx,
})

sample({
  clock: currentGeolocationRequested,
  filter: not($$geolocation.permission.$denied),
  fn: () => RequestMode.Manual,
  target: $$geolocation.coordinatesRequested,
})

sample({
  clock: geocodeCurrentLocationFx.doneData,
  fn: (attrs) =>
    attrs
      ? ({
          id: CURRENT_LOCATION_ID,
          address: buildAddressFromAttributes(attrs),
          addressLine1: attrs.address_line_1,
          addressLine2: attrs.address_line_2 ?? '',
          city: attrs.city,
          state: attrs.state,
          zip: attrs.zip,
          latitude: attrs.latitude,
          longitude: attrs.longitude,
          kind: LocationKindType.OtherAddress,
          name: '',
          isPreferred: true,
        } as UserLocation)
      : attrs,
  target: $currentGeolocation,
})

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { secondaryGrey } from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface IconContentProps {
  icon:
    | 'legacy/primary_care_provider'
    | 'legacy/billing_advocacy'
    | 'misc/physician_experience'
    | 'misc/schedule'
  title: string
}

export function IconContent({ icon, title }: IconContentProps) {
  return (
    <Root>
      <IconWrapper>
        <Sprite name={icon} />
      </IconWrapper>
      <Title>{title}</Title>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${convertPxToRem(48)};
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(8)};

  border-radius: 50%;
  color: ${secondaryGrey};
  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);

  > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }
`

const Title = styled.span`
  ${text3_12};
  padding-top: ${convertPxToRem(7)};
  width: ${convertPxToRem(75)};
  text-align: center;
  color: ${secondaryGrey};
`

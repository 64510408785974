import { Reference, TypePolicy } from '@apollo/client'

export const ChatPolicy: TypePolicy = {
  fields: {
    // TODO: implement proper merge policy using relay-style pagination
    messages: { keyArgs: false, merge: true },
  },
}

export const ChatConnectionPolicy: TypePolicy = {
  merge: true,
  fields: {
    nodes: {
      // never return inactive threads
      read: (nodes: Reference[] | undefined, { readField }) =>
        nodes?.filter((node) => readField('is_inactive', node) !== true),
    },
  },
}

import { TypePolicies, TypePolicy } from '@apollo/client'

import { ActivityFeedPolicy } from './utils/policies/activityFeed'
import {
  CareSearchSpecialtyPolicy,
  CareSearchSubSpecialtyPolicy,
  DoctorTypePolicy,
} from './utils/policies/careSearch'
import { ChatConnectionPolicy, ChatPolicy } from './utils/policies/chats'
import { DoctorsPolicy } from './utils/policies/doctors'
import { PharmacyTypePolicy } from './utils/policies/pharmacy'
import { ProcessedMedicationsTypePolicy } from './utils/policies/processedMedications'
import { UserPolicy } from './utils/policies/user'

/** Ensures the Type is a singleton in the Cache */
const Singleton: TypePolicy = { keyFields: ({ __typename }) => __typename }

export const typePolicies: TypePolicies = {
  Query: {
    queryType: true,
    fields: {
      feedItems: ActivityFeedPolicy,
      doctors: DoctorsPolicy,
      // distinguish chats by search value & @connection
      chats: { keyArgs: ['filters', ['search'], '@connection', ['key']] },
    },
  },

  User: UserPolicy,

  Activity: { merge: true },
  ActivitiesGroup: Singleton,

  ChatConnection: ChatConnectionPolicy,
  Chat: ChatPolicy,
  ChatMessageFileType: { merge: true },

  Doctor: DoctorTypePolicy,

  CareSearchSpecialty: CareSearchSpecialtyPolicy,
  CareSearchSubSpecialty: CareSearchSubSpecialtyPolicy,

  MedicationProcessed: ProcessedMedicationsTypePolicy,
  Pharmacy: PharmacyTypePolicy,

  PaymentBenefitType: {
    keyFields: ['name', 'group', 'category'],
  },

  PlanCategory: {
    keyFields: ['code'],
  },

  PbmRetailPharmacy: {
    keyFields: ['nabp'],
  },
}

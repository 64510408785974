import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { FlipRxCard } from 'src/features/RxCard'
import {
  secondaryLightGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { usePharmacyRxCardsQuery } from '../api/pharmacyRxCardsQuery'

export function DependentsCardsCarousel() {
  const { pharmacyIdCard, dependentsCards, hasPharmacyIdCard } =
    usePharmacyRxCardsQuery()

  const { t } = useTranslation()
  const i18n = {
    swipeText: t(
      'translation.home.coverage.pharmacy_benefits.see_dependents_cards',
    ),
  }
  const items = dependentsCards?.map((dependent, index) => (
    <FlipRxCard key={`${dependent.cardId}_${String(index)}`} card={dependent} />
  ))

  if (hasPharmacyIdCard) {
    items.unshift(
      <FlipRxCard key={pharmacyIdCard.cardId} card={pharmacyIdCard} />,
    )
  }

  return (
    <Root>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable
        emulateTouch
        renderIndicator={(onClickHandler, isSelected, index) => (
          <DotItem
            key={index}
            onClick={onClickHandler}
            data-is-active={isSelected}
          />
        )}
      >
        {items}
      </Carousel>
      <SwipeText>{i18n.swipeText}</SwipeText>
    </Root>
  )
}

const DotItem = styled.li`
  width: 6px; // we use pixels here instead of rem because the size of the middle dot
  height: 6px; // set in rems changes in fact in a browser.
  display: inline-block;
  border-radius: 50%;

  background-color: ${secondaryLightGrey};
  &[data-is-active='true'] {
    background-color: ${primaryBlue};
  }

  margin-right: ${convertPxToRem(6)};
  cursor: pointer;
  margin-bottom: ${convertPxToRem(-35)};
`
const Root = styled.div`
  .carousel {
    overflow: visible;
  }
  .carousel .control-dots {
    z-index: 0;
  }
`
const SwipeText = styled.div`
  ${text3_12};
  color: ${secondaryGrey};
  text-align: center;
  user-select: none;
  padding-top: ${convertPxToRem(30)};
`

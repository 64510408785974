import { useCallback } from 'react'

import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'

import { ROUTES } from 'src/constants/routesConstants'
import { DrugPrice } from 'src/entities/drugDetails'
import { remap } from 'src/shared/lib/effector/remap'
import { RetailPharmacyCard as RetailPharmacyCardContent } from 'src/widgets/RetailPharmacyCard'

import { DrugSearchDetailsGate } from '../../model'

interface RetailPharmacyCardProps {
  pharmacyData: DrugPrice
  drugName: string
}

export function RetailPharmacyCardComponent({
  pharmacyData,
  drugName,
}: RetailPharmacyCardProps) {
  const { pharmacy } = pharmacyData

  const navigate = useNavigate()

  const navigateToDetails = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_DETAILS_PHARMACY, {
      state: {
        drugName,
        pharmacyNabp: pharmacy.nabp,
        isExtended: true,
      },
    })
  }, [drugName, navigate, pharmacy.nabp])

  return (
    <RetailPharmacyCardContent
      pharmacyInfo={pharmacyData}
      onClick={navigateToDetails}
      dataTestProp="retailPharmacyCard"
    />
  )
}

export const RetailPharmacyCard = reflect({
  view: RetailPharmacyCardComponent,
  bind: {
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})

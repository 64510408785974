/* eslint no-param-reassign: ["error", { "props": true, ignorePropertyModificationsFor: ["draft"] }] */

import { setContext } from '@apollo/client/link/context'
import { produce } from 'immer'

import { ClientContext } from 'src/apollo/utils/types'
import { localeDetector } from 'src/utils/i18Helpers'
import persistentStorageInterface from 'src/utils/storage/persistent'

function getLocationHeader() {
  const { location } = persistentStorageInterface.access()
  return `${location.latitude},${location.longitude}`
}

const auxillaryHeadersLink = setContext(
  // @ts-expect-error: Apollo does not allow specifying context type
  ({ operationName }, ctx: ClientContext) => {
    const userLocation = getLocationHeader()

    return produce(ctx, (draft) => {
      if (!draft.headers) draft.headers = {}

      draft.headers.LocalTimeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone

      draft.headers['Rightway-Consumer-Version'] = 'Member WEB Application'
      draft.headers['Rightway-Consumer-Location'] = userLocation
      draft.headers['Accept-Language'] = localeDetector()

      draft.headers['X-Apollo-Operation-Name'] = operationName
    })
  },
)

export default auxillaryHeadersLink

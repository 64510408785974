import { useContext, useEffect } from 'react'

import { useChatDetailsFragment } from '../api/chatDetails'
import { useReadChatMutation } from '../api/readChatMutation'

import { ThreadIdContext } from './ThreadIdContext'

export function useReadChat() {
  const id = useContext(ThreadIdContext)!

  const chat = useChatDetailsFragment(id)

  const { read } = useReadChatMutation(id)
  // TODO: consider only reading the chat on user action
  // to prevent 'reading' chats when a member is not looking
  useEffect(() => {
    if (chat?.isUnread) void read()
  }, [chat?.isUnread, read])
}

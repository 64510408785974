import { ROUTES } from 'src/constants/routesConstants'
import { black, primaryWhite } from 'src/theme/colors'

export const extractBrandingUserData = (data) => {
  const branding = data?.branding ?? {}
  const carouselItems = branding?.carousel_items ?? []
  return {
    slug: branding?.slug ?? '',
    logoUrl: branding?.logo_svg_url ?? '',
    logoSidebarUrl: branding?.logo_sidebar_svg_url ?? '',
    title: branding?.title ?? '',
    poweredByText: branding?.powered_by_text ?? '',
    faviconUrl: branding?.favicon_url ?? '',
    welcomeText: branding?.welcome_text ?? '',
    carouselItems: carouselItems.map((item) => extractCarouselItem(item)),
  }
}

const extractCarouselItem = (item) => ({
  title: item?.title ?? '',
  description: item?.description ?? '',
  imageUrl: item?.image_url ?? '',
})

export const extractCurrentUserData = (data) => {
  const currentUser = data?.currentUser ?? {}
  const family = currentUser.family ?? []
  const address = currentUser.address ?? {}
  return {
    userId: currentUser.id ?? '',
    tooltipsViewed: currentUser.tooltips_viewed ?? true,
    organization: currentUser.organization ?? '',
    claimsTotalSavings: currentUser.claims_total_amount_saved ?? 0,
    employeeId: currentUser.employee_id ?? '',
    family: family.map((dependent) => extractFamily(dependent)),
    address,
    externalId: currentUser.employee_external_service_id ?? null,
  }
}

const extractFamily = (family) => ({
  id: family?.id ?? '',
  firstName: family?.first_name ?? '',
  lastName: family?.last_name ?? '',
  dob: family?.dob ?? '',
  gender: family?.display_gender ?? '',
  relationToMember: family?.relation_to_insured ?? '',
  address: family?.address ?? '',
})

export const getManifestFile = ({ faviconUrl, title }) => {
  const manifestFile = {
    short_name: title,
    name: `${title} - Member`,
    icons: [
      {
        src: faviconUrl,
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon',
      },
      {
        src: 'logo280.png',
        type: 'image/png',
        sizes: '280x280',
      },
      {
        src: 'logo421.png',
        type: 'image/png',
        sizes: '421x420',
      },
    ],
    start_url: '/',
    scope: '.',
    display: 'standalone',
    theme_color: black,
    background_color: primaryWhite,
  }

  const stringManifest = JSON.stringify(manifestFile)

  const blob = new Blob([stringManifest], { type: 'application/json' })

  return URL.createObjectURL(blob)
}

export const getSlug = (location) => {
  const url = new URL(location.href)
  const queryParamSlug = url.searchParams.get('slug')

  if (queryParamSlug) {
    return queryParamSlug
  }

  const allAvailableRoutes = Object.values(ROUTES)
  return (
    location.pathname
      .split('/')
      .filter((item) => item && !allAvailableRoutes.includes(item))?.[0] || ''
  )
}

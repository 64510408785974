import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { WorkSchedule } from 'src/entities/drugDetails'
import { getWorkingInfo } from 'src/entities/schedule'
import { primaryDarkNavy, statusErrorRed, statusActive } from 'src/theme/colors'
import { text2_14, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ScheduleProps {
  workSchedule?: WorkSchedule[]
}

export function Schedule({ workSchedule = undefined }: ScheduleProps) {
  const { t } = useTranslation()

  const i18n = {
    open: t('translation.shared.schedule.open'),
    close: t('translation.shared.schedule.closed'),
  }

  const scheduleInfo = useMemo(
    () => (workSchedule ? getWorkingInfo(workSchedule) : null),
    [workSchedule],
  )

  if (!scheduleInfo) return null

  return (
    <Root>
      <ScheduleStatus data-is-open={scheduleInfo.isOpenNow}>
        {scheduleInfo.isOpenNow ? i18n.open : i18n.close}
      </ScheduleStatus>
      <ScheduleMessage>{scheduleInfo.when}</ScheduleMessage>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  grid-area: schedule;
`

const ScheduleStatus = styled.span`
  color: ${statusErrorRed};
  ${textLink2_14};
  margin-right: ${convertPxToRem(16)};
  text-transform: uppercase;

  &[data-is-open='true'] {
    color: ${statusActive};
  }
`

const ScheduleMessage = styled.span`
  color: ${primaryDarkNavy};
  ${text2_14};
`

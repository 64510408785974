import React from 'react'

import PropTypes from 'prop-types'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

function LocatorIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M19.694.307c-.323-.327-.824-.401-1.228-.182L.55 9.707c-.42.226-.633.709-.517 1.171.116.463.532.788 1.009.789h7.292v7.291c0 .478.325.894.788 1.01.082.02.166.031.25.032.384 0 .736-.212.917-.55l9.583-17.918c.218-.402.146-.901-.178-1.225z"
      />
    </svg>
  )
}

LocatorIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default LocatorIcon

import { useCallback } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'

import { CHAT_QUERY } from './graphql'
import { ChatResult } from './types'

export function useChatQuery(id: string) {
  const {
    data,
    fetchMore: fetch,
    networkStatus: status,
  } = useQuery(CHAT_QUERY, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    // We must use `cache-and-network` since there's no
    // subscription to updates to the list of messages
    fetchPolicy: 'cache-and-network',
  })

  const fetchMore = useCallback(async () => {
    const cursor = data?.chat?.messages?.pageInfo.cursor
    if (!cursor || status !== NetworkStatus.ready) return

    await fetch({
      variables: { cursor },
      // TODO: migrate this to cache policy after old chats are removed
      updateQuery({ chat: prev }, { fetchMoreResult: { chat: next } }) {
        const updated =
          next?.messages?.nodes?.filter((message) => message !== null) ?? []

        const ids = updated.map((message) => message.id)

        // Remove duplicates, preferring `updated` over `obsolete`
        const obsolete =
          prev?.messages?.nodes
            ?.filter((message) => message !== null)
            .filter((message) => !ids.includes(message.id)) ?? []

        const nodes = [...obsolete, ...updated]

        // We're sure previous pageInfo is non-null, otherwise we couldn't have fetched more
        // Still, waiting for correct typings
        const pageInfo = next?.messages?.pageInfo ?? prev!.messages!.pageInfo
        const chat = { ...next, messages: { pageInfo, nodes } }

        return { chat } as ChatResult
      },
    })
  }, [data?.chat?.messages?.pageInfo.cursor, fetch, status])

  return { data, status, fetchMore }
}

export { CHAT_MESSAGES_UPDATE_FRAGMENT } from './graphql'

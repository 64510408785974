import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'

import {
  IngestLogLineParams,
  LoggingContext,
  ContextStackAddedParams,
} from './types'

export const loggingContextStackAdded = createEvent<ContextStackAddedParams>()
export const loggingLineCreated = createEvent<IngestLogLineParams>()

const ingestUserFx = createEffect<Record<string, unknown>, void>()
export const clearUserFx = createEffect<void, void>()

const $loggingContext = createStore<LoggingContext>({}).on(
  loggingContextStackAdded,
  (state, { stack, ctx }) => ({ ...state, [stack]: ctx }),
)

export const ingestLogLineFx = attach({
  source: $loggingContext,
  effect(ctx, { line, level = 'info', meta = {} }: IngestLogLineParams) {
    if (level in console) console[level](`${line}`, meta)

    datadogLogs.logger.log(line, { ...ctx, ...meta }, level)
  },
})

sample({
  clock: loggingLineCreated,
  target: ingestLogLineFx,
})

sample({
  clock: loggingContextStackAdded,
  filter: ({ stack }) => stack === 'user',
  fn: ({ ctx }) => ctx,
  target: ingestUserFx,
})

ingestUserFx.use((user) => {
  datadogRum.setUser(user)
  datadogLogs.setUser(user)
})

clearUserFx.use(() => datadogRum.clearUser())

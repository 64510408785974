import { gql } from '@apollo/client'

import CURRENT_USER_FRAGMENT from 'src/apollo/fragments/CURRENT_USER_FRAGMENT'

const MEMBER_PROFILE_QUERY = gql`
  query memberProfileQuery {
    currentUser {
      ...currentUserFragment
      survey_registration {
        id
        questions_count
        title
        questions {
          nodes {
            is_answered
            id
            title
            description
            display_kind
            kind
            position
            required
            summary_label
            answers {
              nodes {
                id
                blocked_by_default
                display_kind
                kind
                position
                proceed_to_question_id
                title
                unblocks_answer_id
                user_answer_value
              }
            }
          }
        }
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`

export default MEMBER_PROFILE_QUERY

import styled from 'styled-components'

import LocationIcon from 'src/components/SvgIcons/LocationIcon'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy, primaryBlue } from 'src/theme/colors'
import { heading7_14, textLink2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { DEFAULT_ADDRESS } from 'src/utils/locationHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface LocationChangeProps {
  zipCode: string | null
  navigateToLocation: () => void
  className?: string
}

export function LocationChange({
  zipCode,
  navigateToLocation,
  className = undefined,
}: LocationChangeProps) {
  const t = useScopedTranslation(
    'translation.home.care_tools.care_finder.location_preferences',
  )

  const locationTitle = t('results_near', {
    place: zipCode ?? DEFAULT_ADDRESS,
  })

  return (
    <Root data-test="locationChange" className={className}>
      <Location data-test="locationChange.title">{locationTitle}</Location>
      <ChangeLocationBox
        onClick={navigateToLocation}
        data-test="locationChange.button"
      >
        <LocationIcon
          fill={primaryBlue}
          width={convertPxToRem(16)}
          height={convertPxToRem(16)}
        />
        <Change>{t('change_button')}</Change>
      </ChangeLocationBox>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${convertPxToRem(0, 40)};

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(0, 24)};
  }
`

const Location = styled.span`
  ${heading7_14};
  color: ${primaryDarkNavy};
`

const ChangeLocationBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Change = styled.span`
  ${textLink2_14};
  color: ${primaryBlue};
`

import { createFactory } from '@withease/factories'
import {
  attach,
  createEvent,
  createStore,
  sample,
  Store,
  EventCallable,
} from 'effector'

import { postpone } from 'src/shared/lib/effector/postpone'

import { $client, $isClientReady } from './client'

interface CreateFlagProps<T> {
  key: string
  default: T
}

export interface DarklyFlag<T> {
  $value: Store<T>
  $ready: Store<boolean>

  aquire: EventCallable<void>

  __: { key: string }
}

function createFlagFactory<T>(options: CreateFlagProps<T>): DarklyFlag<T> {
  const $ready = createStore<boolean>(false)
  const $value = createStore<T>(options.default)

  const aquire = createEvent<void>()

  const aquireFx = attach({
    source: $client,
    effect(client) {
      if (!client) return options.default

      return client.variation(options.key, options.default) as T
    },
  })

  sample({
    clock: postpone({ clock: aquire, until: $isClientReady }),
    target: aquireFx,
  })

  sample({ clock: aquireFx.doneData, target: $value })
  sample({ clock: aquireFx.finally, fn: () => true, target: $ready })

  return { $ready, $value, aquire, __: { key: options.key } }
}

export const createFlag = createFactory(createFlagFactory)

import moment from 'moment'
import { pathOr } from 'ramda'

const ATTACHMENT_TYPE = {
  FILE_LINK: 'ChatMessageFileType',
  SYSTEM_MESSAGE: 'SystemNotificationType',
}

const getLastMessageTime = (_time) => {
  const today = moment()
  const dateDiff = today.diff(_time, 'seconds')
  if (dateDiff < 60) {
    return 'Just now'
  }
  return moment(_time).calendar(null, {
    sameDay: 'h:mma',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: 'M/D/YY',
  })
}

const extractInboxAttachment = (data) => ({
  contentType: pathOr('', ['content_type'], data),
  url: pathOr('', ['url'], data),
  size: pathOr(0, ['size'], data),
})

const extractInboxItem = (data) => {
  const inboxData = pathOr([], ['chats', 'nodes'], data)

  return inboxData
    .filter((item) => item.last_message !== null)
    .map((item) => {
      const lastMessage = pathOr({}, ['last_message'], item)
      const messageUpdated = pathOr('', ['updated_at'], lastMessage)
      const typeName = pathOr(
        '',
        ['last_message', 'attachment', '__typename'],
        item,
      )
      const isSystemMessage = typeName === ATTACHMENT_TYPE.SYSTEM_MESSAGE
      const messageBody = isSystemMessage
        ? pathOr('', ['attachment', 'message'], lastMessage)
        : pathOr('', ['body'], lastMessage)

      const attachment = pathOr({}, ['attachment'], lastMessage)

      return {
        chatId: pathOr('', ['id'], item),
        subject: pathOr('', ['subject'], item),
        createdAt: pathOr('', ['created_at'], item),
        isResolved: pathOr(false, ['is_resolved'], item),
        lastMessage: {
          messageBody,
          attachment: extractInboxAttachment(attachment),
          lastTimeUpdated: getLastMessageTime(messageUpdated),
          timestamp: moment(messageUpdated).format('x'),
        },
        chatCreatedAt: pathOr('', ['created_at'], item),
        chatUpdatedAt: pathOr('', ['updated_at'], item),
      }
    })
    .sort((a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp)
}

export const extractInboxData = (data) => {
  const inboxData = extractInboxItem(data)
  const pageInfo = pathOr({}, ['chats', 'pageInfo'], data)
  return {
    inboxData,
    pageInfo,
  }
}

export const extractNavigatorActivities = (data) => {
  const navigatorActivities = pathOr(
    {},
    ['currentUser', 'activities', 'chat'],
    data,
  )
  return {
    newMessagesCount: pathOr('', ['count'], navigatorActivities),
    newMessagesIdsList: pathOr([], ['ids'], navigatorActivities),
    isNavigatorOnline: pathOr(
      false,
      ['currentUser', 'navigator_work_schedule', 'online'],
      data,
    ),
  }
}

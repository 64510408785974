export enum FreshpaintEvent {
  ModalNavigation = 'ModalNavigation',

  CarePlansNavigation = 'CarePlansNavigation',

  ActivityCardRecieved = 'activityCardRecieved',
  ActivityCardClicked = 'activityCardClicked',
  ActivityCardDismissed = 'activityCardDismissed',

  ClearCostRedirected = 'Care Finder | Clear Cost SSO | Redirect',
}

interface FreshpaintTrackingProperties {
  [FreshpaintEvent.ModalNavigation]: {
    Content: 'CHAT_NEW' | 'CHAT_THREAD' | 'INBOX' | 'SELECT_PHONE'
  }

  [FreshpaintEvent.CarePlansNavigation]: { Content: string }

  [FreshpaintEvent.ActivityCardRecieved]: { CardID: string }
  [FreshpaintEvent.ActivityCardClicked]: { CardID: string }
  [FreshpaintEvent.ActivityCardDismissed]: { CardID: string }

  [FreshpaintEvent.ClearCostRedirected]: Record<string, never>
}

export interface FreshpaintTrackingInfo<
  E extends FreshpaintEvent = FreshpaintEvent,
> {
  event: E
  properties: FreshpaintTrackingProperties[E]
}

import { useNavigate } from '@reach/router'
import styled from 'styled-components'

import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import ButtonClose40 from 'src/components/Buttons/ButtonClose40'
import NavigatorIconLightTheme from 'src/components/NavigatorIcons/NavigatorIconLightTheme'
import ButtonBack40 from 'src/shared/ui/buttons/ButtonBack40'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface HeaderNavLightThemeProps {
  onClose?: () => void
  onBack?: () => void
  isLeftBtnClose?: boolean
  withMiddleButton?: boolean
}

function HeaderNavLightTheme({
  onClose = undefined,
  onBack = undefined,
  isLeftBtnClose = false,
  withMiddleButton = true,
}: HeaderNavLightThemeProps) {
  const navigate = useNavigate()

  const navigateBack = () => navigate(-1)

  return (
    <Root>
      {isLeftBtnClose ? (
        <ButtonClose40
          onClick={onClose ?? navigateBack}
          border="none"
          withShadow
        />
      ) : (
        <ButtonBack40 onClick={onBack ?? navigateBack} />
      )}

      {withMiddleButton && (
        <ButtonNavigatorWrapper>
          <NavigatorIconLightTheme />
        </ButtonNavigatorWrapper>
      )}

      <ButtonCallWrapper>
        <ButtonCall40 withShadow />
      </ButtonCallWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${convertPxToRem(40)};
  flex-shrink: 0;
`

const ButtonNavigatorWrapper = styled.div`
  pointer-events: auto;
  ${mediaQueryFor.desktop} {
    display: none;
  }
`

const ButtonCallWrapper = styled.div`
  pointer-events: auto;
  ${mediaQueryFor.desktop} {
    display: none;
  }
`

export default HeaderNavLightTheme

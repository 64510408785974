import { combine, createEvent, createStore } from 'effector'

import { drugSearchPharmaciesQuery } from '../api/pharmaciesSearchQuery'

export const INITIAL_LIST_LENGTH = 5

export const pharmaciesListExpanded = createEvent()

export const $isShortList = createStore<boolean>(true).on(
  pharmaciesListExpanded,
  () => false,
)

export const $pharmacies = drugSearchPharmaciesQuery.$data.map(
  (data) => data?.pharmacies ?? [],
)

export const $foundPharmacies = combine(
  $pharmacies,
  $isShortList,
  (pharmacies, isShortList) =>
    isShortList ? pharmacies.slice(0, INITIAL_LIST_LENGTH) : pharmacies,
)

export const $hasPharmacies = $pharmacies.map(
  (pharmacies) => pharmacies.length > 0,
)
export const $hasExtendOption = combine(
  $pharmacies,
  $isShortList,
  (pharmacies, isShortList) =>
    isShortList && pharmacies.length > INITIAL_LIST_LENGTH,
)

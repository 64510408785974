import { ConfigType, SavedConfig } from './types'

type Option = {
  gpi: string
  ndc: string
  package: string
  form: string
  dosage: string
}

const mapping = new Map<ConfigType, keyof Option>()
  .set(ConfigType.Form, 'form')
  .set(ConfigType.Dosage, 'dosage')
  .set(ConfigType.TypeOfQuantity, 'package')

export const findBestConfigMatch = (
  options: Option[],
  config: SavedConfig,
): Partial<SavedConfig> => {
  const current: Partial<SavedConfig> = {
    [ConfigType.Form]: '',
    [ConfigType.Dosage]: '',
    [ConfigType.TypeOfQuantity]: '',
  }
  let list = options

  // eslint-disable-next-line no-restricted-syntax
  for (const key of mapping.keys()) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    list = list.filter((item) => item[mapping.get(key)!] === config[key])
    if (list.length === 0) return current

    current[key] = config[key]
  }

  return current
}

export const filterSavedConfig = (
  savedConfig: SavedConfig,
  options: Option[],
) => {
  const result = findBestConfigMatch(options, savedConfig)
  return { ...savedConfig, ...result }
}

import React, { useMemo } from 'react'

import styled from 'styled-components'

import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { StepData, StepActionContent, ContentStepType } from '../types'

import { StepLinkContent } from './content/StepLinkContent'
import { StepRxContent } from './content/StepRxContent'
import { StepTextContent } from './content/StepTextContent'
import { SingleStepItem } from './SingleStepItem'

interface StepsProps {
  steps: StepData[]

  content?: StepActionContent

  className?: string
  theme?: 'dark' | 'light'
}

export function Steps({
  steps,
  content = {},
  className = undefined,
  theme = 'dark',
}: StepsProps) {
  const components = useMemo(() => ({ ...defaults, ...content }), [content])

  return (
    <Wrapper className={className} data-theme={theme}>
      {steps.map(({ title, content: actions }) => (
        <SingleStepItem
          key={title}
          title={title}
          actions={actions}
          components={components}
        />
      ))}
    </Wrapper>
  )
}

const defaults: Required<StepActionContent> = {
  [ContentStepType.Text]: <StepTextContent />,
  [ContentStepType.Link]: <StepLinkContent />,
  [ContentStepType.RxCard]: <StepRxContent />,
}

const Wrapper = styled.div`
  display: grid;

  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr;

  column-gap: ${convertPxToRem(18)};

  ${text1_16};
`

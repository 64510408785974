import styled from 'styled-components'

import { secondaryGrey } from 'src/theme/colors'
import { heading8_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const ActionTitle = styled.span`
  color: ${secondaryGrey};
  ${heading8_12};
  padding-top: ${convertPxToRem(12)};
  text-align: center;
`

import { gql } from '@apollo/client'

import CARE_PLAN_FRAGMENT from 'src/apollo/fragments/CARE_PLAN_FRAGMENT'

const FEED_ITEMS_EVENTS_SUBSCRIPTION = gql`
  subscription feedItemsEventsSubscription($categories: [FEED_ITEM_CATEGORY]) {
    feedItemsEvents(categories: $categories) {
      event
      feed_item {
        category
        created_at
        title
        description
        label
        call_to_action
        id
        seen
        to_do
        resource {
          ... on CarePlan {
            ...carePlanFragment
          }
          ... on BillClaim {
            # should be handled by BILL_CLAIMS_SUBSCRIPTION
            id
          }
          ... on Appointment {
            id
          }
        }
      }
    }
  }
  ${CARE_PLAN_FRAGMENT}
`

export default FEED_ITEMS_EVENTS_SUBSCRIPTION

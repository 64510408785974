import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { PHARMACY_RX_CARDS_QUERY } from './graphql'
import { RxCard, DependentsRxCard } from './types'

export type { RxCard, DependentsRxCard }

// ToDo: Remove this when the backend is ready (non-null and required fields)
const prepareData = ({ card, firstName, lastName }: DependentsRxCard) => ({
  firstName,
  lastName,
  issuer: card?.issuer ?? 'N/A',
  cardId: card?.cardId ?? 'N/A',
  rxIin: card?.rxIin ?? 'N/A',
  rxGrp: card?.rxGrp ?? 'N/A',
  rxPcn: card?.rxPcn ?? 'N/A',
  memberHelpDesk: card?.memberHelpDesk ?? 'N/A',
  pharmacyHelpDesk: card?.pharmacyHelpDesk ?? 'N/A',
})

export function usePharmacyRxCardsQuery() {
  const { data, loading: isLoading } = useQuery(PHARMACY_RX_CARDS_QUERY)

  const dependentsCards = useMemo(
    () => data?.currentUser?.family?.map(prepareData) ?? [],
    [data],
  )
  const pharmacyIdCard = useMemo(
    () => prepareData(data?.currentUser ?? { firstName: '', lastName: '' }),
    [data],
  )

  return {
    dependentsCards,
    pharmacyIdCard,
    hasPharmacyIdCard: Boolean(data?.currentUser?.card),
    hasDependents: dependentsCards.length > 0,
    isLoading,
  }
}

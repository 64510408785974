import { createEvent, sample } from 'effector'

import { $$auth } from '@shared/auth'
import { postpone } from '@shared/lib/effector/postpone'

import { featuresQuery } from '../api/featuresQuery'

export const setup = createEvent<void>()

sample({
  clock: postpone({ clock: setup, until: $$auth.$authenticated }),
  target: featuresQuery.refresh,
})

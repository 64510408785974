import React, { useMemo } from 'react'

import Actions from 'src/sections/SectionCareLocator/components/ProfileComponents/Actions'

import { Appointment } from '../api/appointmentQuery/types'
import { isDoctorAssignee, pickDoctorAddress } from '../lib/doctor'

interface AppointmentAssigneeActionsProps {
  appointment: Appointment
}

export function AppointmentAssigneeActions({
  appointment,
}: AppointmentAssigneeActionsProps) {
  const { assignee, savedAddress } = appointment

  const isDoctor = isDoctorAssignee(assignee)

  const address = useMemo(
    () =>
      isDoctor ? pickDoctorAddress(assignee.addresses, savedAddress) : assignee,
    [isDoctor, assignee, savedAddress],
  )

  return (
    <Actions
      profileId={assignee.id}
      preferredPhone={address.phone}
      location={address}
      isFacility={!isDoctor}
      disableConfirmation
    />
  )
}

import React from 'react'

import styled from 'styled-components'

import SwipeDownIcon from 'src/components/SvgIcons/SwipeDownIcon'
import { modalBlackOpacity, primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ModalBoxProps {
  children: React.ReactNode
  handleClick: React.MouseEventHandler<HTMLDivElement>
  dataTestProp?: string
  className?: string
}

export function ModalBox({
  children,
  handleClick,
  dataTestProp = 'modalBox',
  className = undefined,
}: ModalBoxProps) {
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  return (
    <Overlay
      onClick={handleClick}
      data-test={dataTestProp}
      className={className}
    >
      <ModalWrapper>
        <IconContainer>
          <SwipeDownIcon />
        </IconContainer>

        <Modal onClick={stopPropagation} data-test={`${dataTestProp}.modal`}>
          {children}
        </Modal>
      </ModalWrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${modalBlackOpacity};
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueryFor.mobile} {
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }
`

const IconContainer = styled.div`
  display: none;

  ${mediaQueryFor.mobile} {
    display: flex;
    margin-bottom: ${convertPxToRem(7)};
  }
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${convertPxToRem(500)};
  border-radius: ${convertPxToRem(20)};

  background-color: ${primaryWhite};

  ${mediaQueryFor.mobile} {
    width: 100%;
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`

import { attach, sample } from 'effector'
import { StorageAdapter } from 'effector-storage'
import { once } from 'patronum'

import { $history } from '../core'

const keyArea = Symbol('persist:query')

type PersistQueryMethod = 'push' | 'replace'

interface PersistQueryConfig {
  method?: PersistQueryMethod
  /** Default `$store` value for when search param is missing */
  def?: string | null
}

interface WriteQueryParams {
  method: PersistQueryMethod
  key: string
  value: string | null
}

const readQueryFx = attach({
  source: $history,
  effect: ({ location }, key: string) => {
    const params = new URLSearchParams(location.search)
    return params.get(key)
  },
})

const writeQueryFx = attach({
  source: $history,
  effect: async (
    { navigate, location },
    { method, key, value }: WriteQueryParams,
  ) => {
    const params = new URLSearchParams(location.search)

    if (value) params.set(key, value)
    else params.delete(key)

    const url = `?${params.toString()}`

    const options = {
      replace: method === 'replace',
      state: location.state as NonNullable<unknown>,
    }

    await navigate(url, options)
  },
})

const subscribeFx = attach({
  source: $history,
  effect: (history, fn: () => void) => history.listen(fn),
})

persistQueryAdapter.factory = true as const

/** Unit-testing internals */
persistQueryAdapter.__ = { readQueryFx, writeQueryFx, subscribeFx }

export function persistQueryAdapter({
  method = 'replace',
  def = null,
}: PersistQueryConfig = {}): StorageAdapter {
  const adapter: StorageAdapter = <State>(key: string, update: () => void) => {
    /** batch all updates to store into next tick */
    const updated = () => setTimeout(update, 0)

    const getFx = attach({
      effect: readQueryFx,
      mapParams: () => key,
    })

    const setFx = attach({
      effect: writeQueryFx,
      mapParams: (value: string | null) => ({ method, key, value }),
    })

    // Subscribe once get is run at least once
    // This allows subscribing on `pickup` only
    sample({
      clock: once(getFx.done),
      fn: () => updated,
      target: subscribeFx,
    })

    return {
      get: () => getFx().then((value) => (value ?? def) as State),
      set: (value: State) => setFx(value as string),
    }
  }

  adapter.keyArea = keyArea
  return adapter
}

import { useCallback } from 'react'

import { reflect } from '@effector/reflect'
import { useNavigate } from '@reach/router'

import { ROUTES } from 'src/constants/routesConstants'
import { DrugPrice } from 'src/entities/drugDetails'
import { remap } from 'src/shared/lib/effector/remap'
import { MailOrderPharmacyCard as MailOrderPharmacyContent } from 'src/widgets/MailOrderPharmacyCard'

import { DrugSearchDetailsGate } from '../../model'

interface MailOrderCardProps {
  pharmacyData: DrugPrice
  drugName: string
}

function MailOrderCardComponent({
  pharmacyData,
  drugName,
}: MailOrderCardProps) {
  const {
    pharmacy: { nabp },
  } = pharmacyData

  const navigate = useNavigate()

  const navigateToDetails = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_DETAILS_PHARMACY, {
      state: {
        drugName,
        pharmacyNabp: nabp,
      },
    })
  }, [drugName, nabp, navigate])

  return (
    <MailOrderPharmacyContent
      pharmacyData={pharmacyData}
      onClick={navigateToDetails}
    />
  )
}

export const MailOrderCard = reflect({
  view: MailOrderCardComponent,
  bind: {
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})

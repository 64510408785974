import { createEvent, sample } from 'effector'

import {
  $$pharmacySearch,
  drugSearchPharmaciesQuery,
} from 'src/features/pharmacySearch'
import { DEFAULT_COORDS } from 'src/utils/locationHelpers'
import { $$userLocations } from 'src/widgets/UserLocations'

export const mapInitialized = createEvent()

export const $markers = $$pharmacySearch.$foundPharmacies.map((pharmacies) =>
  pharmacies.map((pharmacy) => ({
    name: pharmacy.name,
    id: pharmacy.name,
    latitude: pharmacy.latitude,
    longitude: pharmacy.longitude,
  })),
)

sample({
  clock: mapInitialized,
  source: $$userLocations.$preferredLocation,
  fn: (userLocation) => ({
    latitude: userLocation?.latitude ?? DEFAULT_COORDS.latitude,
    longitude: userLocation?.longitude ?? DEFAULT_COORDS.longitude,
  }),
  target: drugSearchPharmaciesQuery.refresh,
})

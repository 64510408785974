import { gql, TypedDocumentNode } from '@apollo/client'

import {
  ToggleFavoriteFacilityResult,
  ToggleFavoriteFacilityVariables,
} from './types'

export const TOGGLE_FAVORITE_FACILITY_MUTATION: TypedDocumentNode<
  ToggleFavoriteFacilityResult,
  ToggleFavoriteFacilityVariables
> = gql`
  mutation toggleFavoriteFacility($id: ID!, $isFavorite: Boolean!) {
    removeFromCareTeam(id: $id, type: facility) @include(if: $isFavorite) {
      id
      care_team_facilities {
        nodes {
          id
        }
      }
    }

    addToCareTeam(id: $id, type: facility) @skip(if: $isFavorite) {
      id
      care_team_facilities {
        nodes {
          id
        }
      }
    }
  }
`

import React, { Suspense, useEffect, useMemo, useState } from 'react'

import {
  Router,
  Redirect,
  useLocation,
  RouteComponentProps,
} from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import useCurrentUserQuery from 'src/apollo/hooks/useCurrentUserQuery'
import { isMobilePlatform } from 'src/components/AttachComponents/uploadHelpers'
import BrandingHelmet from 'src/components/BrandingHelmet'
import { DeactivatedErrorAlert } from 'src/components/DeactivatedErrorAlert'
import ExpiredSession from 'src/components/ExpiredSession'
import GDPRGuard from 'src/components/GDPRGuard'
import Spinner from 'src/components/Spinner'
import UnauthenticatedErrorAlert from 'src/components/UnauthenticatedErrorAlert'
import { ROUTES } from 'src/constants/routesConstants'
import { RemoteErrorHandler } from 'src/features/RemoteErrorHandler'
import { QuickEligibilityCheck } from 'src/pages/QuickEligibilityCheckPage'
import CheckGeolocation from 'src/sections/SectionAuth/CheckGeolocation'
import {
  CreateNewPassword,
  EmailEntry,
  PasswordEntry,
} from 'src/sections/SectionAuth/lazy'
import TokenSignIn from 'src/sections/SectionAuth/TokenSignIn'
import Welcome from 'src/sections/SectionAuth/Welcome'
import SectionDashboard from 'src/sections/SectionDashboard'
import {
  GetAssistance,
  GetAssistanceSuccess,
  SignUp,
} from 'src/sections/SectionRegistration/lazy'
import SsoFailedScreen from 'src/sections/SectionRegistration/pages/SsoFailedScreen'
import { withDispatch } from 'src/store'
import { checkUserLocation } from 'src/store/slices/geolocation'
import { setUserId as setUserIdAction } from 'src/store/slices/user'
import { getSlug } from 'src/utils/currentUserHelpers'
import { useSaveDeeplink } from 'src/utils/hooks/useDeeplink'
import { checkPathName } from 'src/utils/routeHelpers'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'

interface ProtectedRouteProps extends RouteComponentProps {
  setUserId: (id: string) => void
}

function ProtectedRoute({ setUserId, ...rest }: ProtectedRouteProps) {
  const { userId, loading: isLoading } = useCurrentUserQuery()

  useEffect(() => {
    if (userId) {
      setUserId(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  if (isLoading) return <Spinner />

  return userId ? (
    <>
      <BrandingHelmet userId={userId} />
      <SectionDashboard {...rest} />
    </>
  ) : (
    <Redirect from="" to="/" />
  )
}

type PublicRouteProps<T> = T &
  RouteComponentProps & {
    component: React.FunctionComponent<T>
  }

function PublicRoute<T>({
  component: Component,
  ...rest
}: PublicRouteProps<T>) {
  const location = useLocation()
  const { getState: getTokensState } = useLocalStorage(StorageContainer.tokens)

  const token = useMemo(
    () => getTokensState(({ access }) => access),
    [getTokensState],
  )

  const isSsoLogin = checkPathName(ROUTES.TOKEN_SIGN_IN, location) as boolean

  if (token && !isSsoLogin) return <Redirect to={`/${ROUTES.HOME}`} noThrow />

  return (
    <>
      <BrandingHelmet />
      <Component {...(rest as T & JSX.IntrinsicAttributes)} />
    </>
  )
}

type AppProps = ConnectedProps<typeof connector>

function App({ dispatchSetUserIdAction, dispatchCheckUserLocation }: AppProps) {
  const isCheck = window.innerWidth > window.innerHeight
  const [isRotate, setRotate] = useState(isCheck)
  const isMobileRotate = isMobilePlatform && isRotate
  const orientationFunction = () => {
    if (window.innerWidth < window.innerHeight) {
      setRotate(true)
    } else {
      setRotate(false)
    }
  }

  const { location } = window
  const slug = getSlug(location) as string

  useEffect(() => {
    void dispatchCheckUserLocation()
  }, [dispatchCheckUserLocation])

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => orientationFunction(),
      false,
    )

    const setHeight = () => {
      const vh = window.innerHeight * 0.01 || 1 // if window.innerHeight is undefined - will assign 1
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    setHeight()

    window.addEventListener('resize', setHeight)
  }, [])

  useSaveDeeplink()

  if (isMobileRotate) return <Rotate>Please rotate your device!</Rotate>

  return (
    <Suspense fallback={<Spinner size="large" />}>
      <GDPRGuard
        fallback={
          <Router className="rootRouter">
            <PublicRoute
              path={`${slug}/${ROUTES.TOKEN_SIGN_IN}`}
              component={TokenSignIn}
            />

            <SsoFailedScreen path={ROUTES.INVALID_SAML_CREDENTIALS} />

            <CheckGeolocation default />
          </Router>
        }
      >
        <Router className="rootRouter">
          <PublicRoute path={`/${slug}`} component={Welcome} />

          <PublicRoute
            path={`${slug}/${ROUTES.EMAIL_ENTRY}`}
            component={EmailEntry}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.ELIGIBILITY}`}
            component={QuickEligibilityCheck}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.PASSWORD_ENTRY}`}
            component={PasswordEntry}
          />
          <PublicRoute
            path={`${slug}/${ROUTES.RELOGIN}`}
            component={PasswordEntry}
          />
          <PublicRoute
            path={`${slug}/${ROUTES.SIGN_IN}`}
            component={PasswordEntry}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.GET_ASSISTANCE}`}
            component={GetAssistance}
          />
          <PublicRoute
            path={`${slug}/${ROUTES.GET_ASSISTANCE_SUCCESS}`}
            component={GetAssistanceSuccess}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.RESET_PASSWORD}`}
            component={CreateNewPassword}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.SIGN_UP}/*`}
            component={SignUp}
          />
          <PublicRoute path={`${ROUTES.SIGN_UP}/*`} component={SignUp} />

          <PublicRoute
            path={ROUTES.EXPIRED_SESSION}
            component={ExpiredSession}
          />
          <PublicRoute
            path={ROUTES.UNAUTHENTICATED}
            component={UnauthenticatedErrorAlert}
          />
          <PublicRoute
            path={ROUTES.DEACTIVATED}
            component={DeactivatedErrorAlert}
          />

          <PublicRoute
            path={`${slug}/${ROUTES.TOKEN_SIGN_IN}`}
            component={TokenSignIn}
          />

          <SsoFailedScreen path={ROUTES.INVALID_SAML_CREDENTIALS} />

          <ProtectedRoute path="/*" setUserId={dispatchSetUserIdAction} />
        </Router>
      </GDPRGuard>

      <RemoteErrorHandler />
    </Suspense>
  )
}

const Rotate = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const mapDispatchToProps = withDispatch({
  setUserIdAction,
  checkUserLocation,
})

const connector = connect(null, mapDispatchToProps)
export default connector(App)

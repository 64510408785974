import { $chatId, chatClosed, chatCreationSelected, chatSelected } from './chat'
import { $preset, presetConsumed, presetSelected } from './preset'

export const $$chat = {
  /** Open chat by its ID */
  open: chatSelected,
  /** Open new chat creation */
  create: chatCreationSelected,
  /** Close currently open chat */
  close: chatClosed,

  /** Select a preset for a chat */
  selectPreset: presetSelected,
  /** Consume a preset once it's filled into the input */
  consumePreset: presetConsumed,

  /** Current chat ID */
  $id: $chatId,
  /* Current chat preset */
  $preset,
}

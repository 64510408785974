import { useState } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  primaryDarkNavy,
  featurePaleBlue,
  primaryWhite,
  primaryBlue,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading8_14, text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $otherPharmaciesSortingOrder, sortingOrderChanged } from '../model'
import { DrugSearchSortingOrder } from '../model/sortDrugsBy'

const translationKeysMap = {
  [DrugSearchSortingOrder.Price]: 'price',
  [DrugSearchSortingOrder.Hours]: 'open_hours',
}

export function PharmaciesSorting() {
  const [isShowOptions, setIsShowOptions] = useState(false)

  const currentOrder = useUnit($otherPharmaciesSortingOrder)
  const changeOrder = useUnit(sortingOrderChanged)

  const t = useScopedTranslation('translation.medications.drug_search.labels')

  const switchVisibility = () => {
    setIsShowOptions((isShow) => !isShow)
  }

  const handleSelection = (order: DrugSearchSortingOrder) => () => {
    switchVisibility()
    changeOrder(order)
  }

  return (
    <SortingSelector>
      {t('sort_by')}
      <DropdownLink
        type="button"
        onClick={switchVisibility}
        data-test="pharmaciesSorting.dropdownLink"
      >
        {t(translationKeysMap[currentOrder])}
      </DropdownLink>
      {isShowOptions && (
        <OptionsContainer>
          <OptionsList>
            <DropDownOptionItem
              onClick={handleSelection(DrugSearchSortingOrder.Price)}
            >
              {t(translationKeysMap[DrugSearchSortingOrder.Price])}
            </DropDownOptionItem>
            <DropDownOptionItem
              onClick={handleSelection(DrugSearchSortingOrder.Hours)}
            >
              {t(translationKeysMap[DrugSearchSortingOrder.Hours])}
            </DropDownOptionItem>
          </OptionsList>
        </OptionsContainer>
      )}
    </SortingSelector>
  )
}

const SortingSelector = styled.div`
  ${heading8_14};
  color: ${secondaryGrey};
`
const DropdownLink = styled.button`
  color: ${primaryBlue};
  border: none;
  background: none;
  ${heading8_14};
`
const OptionsContainer = styled.div`
  position: relative;
`
const OptionsList = styled.ul`
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: 8px;
  position: absolute;
  background-color: ${primaryWhite};
  width: ${convertPxToRem(140)};
  z-index: 5;
  padding: ${convertPxToRem(6, 0)};
  right: 0;
`
const DropDownOptionItem = styled.li`
  padding: ${convertPxToRem(8, 15)};
  color: ${secondaryGrey};
  cursor: pointer;
  ${text1_16};
  :hover {
    background-color: ${featurePaleBlue};
    color: ${primaryDarkNavy};
  }
  transition: all 0.2s;
`

const LOCATION_TIMEOUT = 10_000

export function getCoordinates() {
  return new Promise<GeolocationCoordinates>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve(coords),
      ({ code }) => reject(code),
      { timeout: LOCATION_TIMEOUT },
    )
  })
}

import { KeyPrefix, TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

export function useScopedTranslation<
  const Scope extends KeyPrefix<'translation'>,
>(path: Scope): TFunction<'translation', Scope> {
  const { t } = useTranslation(undefined, { keyPrefix: path })

  return t
}

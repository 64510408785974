import { Suspense } from 'react'

import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { secondaryBackgroundGrey } from 'src/theme/colors'

import { InboxHeader } from '../InboxHeader'

import { ChatsList } from './ChatsList'
import { ChatsSearch } from './ChatsSearch'

export function ChatsInbox() {
  return (
    <Suspense fallback={<Spinner />}>
      <Header />

      <ChatsSearch />
      <ChatsList />
    </Suspense>
  )
}

const Header = styled(InboxHeader)`
  background-color: ${secondaryBackgroundGrey};
`

import { PharmacyDayOfWeekType, UsStateName } from '@shared/api/types'

import { WithTypename } from 'src/shared/api/mocks'

import { Pharmacy } from './types'

export const MockPharmacy: WithTypename<Pharmacy> = {
  __typename: 'Pharmacies',
  name: 'CVS Pharmacy',
  addressLine1: '851 John Street',
  latitude: 1.0,
  longitude: 1.0,
  isFavorite: false,
  city: 'New York',
  state: UsStateName.Ny,
  zip: '10029',
  nabp: 'pharmacyNabp',
  phone: '2342342323',
  workSchedule: [
    {
      dayOfWeek: PharmacyDayOfWeekType.Monday,
      openHour: '09:00',
      closeHour: '11:00',
    },
  ],
}

import {
  $alternatives,
  $isDrugCovered,
  $hasError,
  drugInfoQuery,
} from './model'

export type { Drug } from './api/drugInfoQuery'

export const $$drugInfo = {
  $alternatives,
  $hasError,
  $isDrugCovered,
  drugInfoQuery,
}

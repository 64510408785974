import styled from 'styled-components'

import {
  featurePaleBlue,
  primaryDarkNavy,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const MessageBubble = styled.div`
  ${text1_16};

  display: inline;
  max-width: 90%;

  padding: ${convertPxToRem(16)};

  border-radius: ${convertPxToRem(8)};

  word-break: break-word;
  white-space: pre-wrap;
  color: ${primaryDarkNavy};

  [data-owned='true'] > & {
    align-self: end;

    background-color: ${featurePaleBlue};
    border-top-right-radius: 0;
  }

  [data-owned='false'] > & {
    align-self: start;

    background-color: ${secondaryLightGrey};
    border-top-left-radius: 0;
  }
`

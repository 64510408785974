import { attach, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

import { createChatFx } from 'src/features/drugSearchTicketCreation'

interface DrugSearchErrorGateProps {
  drugName: string
  dispatchOpenChatThread: (payload: string) => void
  dispatchSetIsShowThirdSectionModal: (payload: boolean) => void
}

const DrugSearchErrorPage = createDomain('DrugSearchErrorPage')

export const DrugSearchErrorGate = createGate<DrugSearchErrorGateProps>({
  name: 'PharmacyDetailsGate',
  domain: DrugSearchErrorPage,
})

export const emptyInfoChatCreated = DrugSearchErrorPage.event()

export const createDrugSearchChatFx = attach({
  source: DrugSearchErrorGate.state,
  effect: createChatFx,
  mapParams: (_: void, { drugName }) => ({
    drugInformation: { name: drugName },
  }),
})

export const openDrugSearchChatFx = attach({
  source: DrugSearchErrorGate.state,
  effect(
    { dispatchOpenChatThread, dispatchSetIsShowThirdSectionModal },
    chatId: string,
  ) {
    dispatchOpenChatThread(chatId)
    dispatchSetIsShowThirdSectionModal(true)
  },
})

sample({
  clock: emptyInfoChatCreated,
  target: createDrugSearchChatFx,
})

sample({
  clock: createDrugSearchChatFx.doneData,
  filter: DrugSearchErrorGate.status,
  target: openDrugSearchChatFx,
})

import { combineReducers, configureStore } from '@reduxjs/toolkit'

import reducers from 'src/store/slices'
import { clearStore } from 'src/store/slices/common'

const combinedReducers = combineReducers(reducers)

const rootReducer: typeof combinedReducers = (state, action) => {
  let updatedState = state

  if (clearStore.match(action)) {
    updatedState = undefined
  }

  return combinedReducers(updatedState, action)
}

// Stryker disable all
/* istanbul ignore if */
if (import.meta.hot) {
  import.meta.hot.accept('./index.js', () => {
    // When any of reducers change
    store.replaceReducer(rootReducer)
  })
}
// Stryker restore all

export const store = configureStore({ reducer: rootReducer })

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

import React from 'react'

import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'
import { useGate, useUnit } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { withDispatch } from 'src/store'
import { openChatThread } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import {
  createDrugSearchChatFx,
  DrugSearchErrorGate,
  emptyInfoChatCreated,
} from '../model'

interface LocationState {
  drugName: string
}

type DrugSearchErrorProps = ConnectedProps<typeof connector>

function DrugSearchError({
  dispatchOpenChatThread,
  dispatchSetIsShowThirdSectionModal,
}: DrugSearchErrorProps) {
  const t = useScopedTranslation('translation.medications.drug_search')

  const {
    state: { drugName },
  } = useLocation() as WindowLocation<LocationState>

  useGate(DrugSearchErrorGate, {
    dispatchOpenChatThread,
    dispatchSetIsShowThirdSectionModal,
    drugName,
  })

  const createChat = useUnit(emptyInfoChatCreated)
  const isLoading = useUnit(createDrugSearchChatFx.pending)

  const i18n = {
    buttonTitle: t('buttons.chat_with_a_pharmacy_guide'),
    description: t('search_pharmacies.system_error'),
  }

  return (
    <Root>
      <HeaderWrapper>
        <HeaderNavLightTheme />
      </HeaderWrapper>

      <Content>
        <ExclamationIcon
          width={convertPxToRem(40)}
          height={convertPxToRem(40)}
        />
        <ErrorMessage>{i18n.description}</ErrorMessage>
      </Content>

      <ChatButton
        handleClick={createChat}
        theme="primary"
        title={i18n.buttonTitle}
        disabled={isLoading}
        loading={isLoading}
      />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  padding: ${convertPxToRem(24)};
  border-radius: ${convertPxToRem(8)};
`

const HeaderWrapper = styled.div`
  width: 100%;
  padding-bottom: ${convertPxToRem(26)};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${convertPxToRem(100, 0)};
`

const ErrorMessage = styled.span`
  text-align: center;
  color: ${secondaryGrey};
  ${text2_14};

  padding: ${convertPxToRem(16, 50)};
`

const ChatButton = styled(ButtonBottom)`
  margin-top: ${convertPxToRem(54)};

  ${mediaQueryFor.mobile} {
    margin-top: auto;
  }
`

const mapDispatchToProps = withDispatch({
  setIsShowThirdSectionModal,
  openChatThread,
})

const connector = connect(null, mapDispatchToProps)
export const DrugSearchErrorPage = connector(
  DrugSearchError,
) as React.FC<RouteComponentProps>

import { classicGray } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { IconProps } from './types'

interface NavigateToIconProps extends IconProps {
  rotate?: string
  dataTest?: string
}

function NavigateToIcon({
  width = convertPxToRem(16),
  height = convertPxToRem(16),
  fill = classicGray,
  rotate = '360',
  dataTest = 'navigateToIcon',
  className = '',
}: NavigateToIconProps) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      data-test={dataTest}
      className={className}
    >
      <path
        d="M6 4L10 8L6 12"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NavigateToIcon

import { gql } from '@apollo/client'

const CURRENT_USER_CARE_TEAM_QUERY = gql`
  query currentUserCareTeamQuery {
    currentUser {
      id
      care_team_doctors {
        nodes {
          id
        }
      }
      care_team_facilities {
        nodes {
          id
        }
      }
    }
  }
`

export default CURRENT_USER_CARE_TEAM_QUERY

import { createEffect, createEvent, createStore, sample } from 'effector'

import { $$auth } from '@shared/auth'

import config from 'src/config'

export const report = createEvent<void>()
export const setup = createEvent<void>()

export const probeLockedFx = createEffect(() =>
  fetch(config.GATEWAY_URL, { method: 'HEAD' })
    .then((res) => res.status === 451 /* Unavailable for Legal Reasons */)
    .catch(() => false /* not blocked by default */),
)

export const $locked = createStore<boolean>(false).on(report, () => true)

sample({
  clock: probeLockedFx.doneData,
  filter: (locked) => locked,
  target: report,
})

sample({
  clock: $locked.updates,
  filter: $locked,
  target: $$auth.signOut.start,
})

sample({
  clock: setup,
  target: probeLockedFx,
})

export const IMG_PDF_UPLOAD_TYPES = '.jpg, .jpeg, .png, .pdf, .gif'
export const IMG_UPLOAD_TYPES = '.jpg, .jpeg, .png'
export const IMG_PDF_UPLOAD_TYPES_ARRAY = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
]
export const UPLOAD_TYPES = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  PDF: 'application/pdf',
}

export const FILE_SIZE_LIMIT_BYTES = 52428800 // 50MB

export const FILE_SCAN_STATUS = {
  STARTED: 'started',
  NOT_CHECKED: 'not_checked',
  SANITIZED: 'sanitized',
  CLEAN: 'clean',
  INFECTED: 'infected',
  INVALID_LINK: 'invalid_link',
  SANITIZATION_FAILED: 'sanitization_failed',
}

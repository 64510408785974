import { MouseEventHandler } from 'react'

import styled from 'styled-components'

import { Sprite } from '@shared/ui/Sprite'

import { useFavoritePharmacyToggle } from '@entities/favoritePharmacy'

import { Pharmacy } from 'src/entities/pharmacy'
import { Schedule } from 'src/features/Schedule'
import { getAddressField } from 'src/shared/lib/getAddressField'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import {
  boxShadowScreenLight,
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
  primaryWhite,
} from 'src/theme/colors'
import { heading5_19, text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface FavoritePharmacyCardProps {
  pharmacy: Pharmacy
  isInNetwork: boolean
  onClick?: () => void
  dataTestProp?: string
}

export function FavoritePharmacyCard({
  pharmacy,
  // ToDo: will be used later
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  isInNetwork,
  onClick = undefined,
  dataTestProp = undefined,
}: FavoritePharmacyCardProps) {
  const { name, workSchedule, nabp, isFavorite } = pharmacy ?? {}

  // ToDo: will be used later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  const { toggle, isLoading } = useFavoritePharmacyToggle({
    id: nabp,
    isFavorite,
  })

  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    void toggle()
  }

  return (
    <Root data-test={dataTestProp} onClick={onClick}>
      <Content>
        <NameWrapper>
          <Name>{name}</Name>
        </NameWrapper>
        <Address data-test="pharmacyCard.location">
          {getAddressField(pharmacy)}
        </Address>
      </Content>

      <HeartIcon
        onClick={handleClick}
        data-is-added={isFavorite}
        data-is-disabled={isLoading}
        data-test="addToFavoritePharmacies"
      />

      <Schedule workSchedule={workSchedule} />

      {/* // ToDo: will be used later */}
      {/* <PharmacyType data-in-network={isInNetwork}>
        {isInNetwork ? t('in_network') : t('out_of_network')}
      </PharmacyType> */}
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-areas:
    'content heart'
    'schedule schedule'
    'label label';
  grid-template-columns: 1fr max-content;

  gap: ${convertPxToRem(8)};
  padding: ${convertPxToRem(16, 20)};
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  box-shadow: ${boxShadowScreenLight};
  background-color: ${primaryWhite};
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Name = styled.span`
  color: ${primaryDarkNavy};
  ${heading5_19};
`

const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(8)};
`

const Address = styled.span`
  color: ${secondaryGrey};
  ${text2_14};
`

const HeartIcon = styled(Sprite).attrs({ name: 'misc/heart' })`
  grid-area: heart;

  height: ${convertPxToRem(16)};
  width: ${convertPxToRem(16)};
  cursor: pointer;
  color: ${primaryDarkNavy};

  &[data-is-added='true'] {
    --secondary: ${primaryDarkNavy};
  }

  &[data-is-disabled='true'] {
    color: ${secondaryGrey};
    --secondary: ${secondaryGrey};

    pointer-events: none;
  }
`

// ToDo: will be used later
// const PharmacyType = styled(ChipTag.Rounded)`
//   background-color: ${secondaryLightGrey};
//   &[data-in-network='true'] {
//     background-color: ${featurePaleBlue};
//   }

//   color: ${primaryDarkNavy};
//   grid-area: label;
// `

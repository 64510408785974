// eslint-disable-next-line import/prefer-default-export
export enum GraphQLErrorCode {
  NOT_FOUND = 'NOT_FOUND',
  NOT_UPDATED = 'NOT_UPDATED',
  SERVER_ERROR = 'SERVER_ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  EXPIRED_SESSION = 'EXPIRED_SESSION',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNCONFIRMED = 'UNCONFIRMED',
  DEACTIVATED = 'DEACTIVATED',
  LOCKED = 'LOCKED',
  UNABLE_REGISTER = 'UNABLE_REGISTER',
  VERIFY_CODE_EXPIRED = 'VERIFY_CODE_EXPIRED',
  VERIFY_CODE_INCORRECT = 'VERIFY_CODE_INCORRECT',
}

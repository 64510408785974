import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { useScopedTranslation } from '@shared/lib/useScopedTranslation'

import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { heading2_29, text1_16, textLink1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function RestrictedLocation() {
  const t = useScopedTranslation('translation.auth.signin')

  return (
    <Page>
      <Wrapper data-test="restrictedLocation">
        <RightwayLogo />

        <Content>
          <Title>{t('us_residents')}</Title>

          <Trans
            t={t}
            parent={Description}
            i18nKey="description"
            values={{ email: t('contact') }}
            components={{
              emailLink: <ContactLink href={`mailto:${t('contact')}`} />,
            }}
          />
        </Content>
      </Wrapper>
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: ${secondaryBackgroundGrey};

  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  align-items: center;

  gap: ${convertPxToRem(60)};

  background: ${primaryWhite};

  width: ${convertPxToRem(500)};
  height: ${convertPxToRem(666)};

  padding: ${convertPxToRem(70, 80)};

  border-radius: ${convertPxToRem(44)};

  ${mediaQueryFor.mobile} {
    height: 100%;
    width: 100%;

    gap: ${convertPxToRem(64)};

    padding: ${convertPxToRem(100, 24)};

    border-radius: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${convertPxToRem(16)};

  text-align: center;

  ${mediaQueryFor.mobile} {
    max-width: ${convertPxToRem(284)};
  }
`

const RightwayLogo = styled(Sprite).attrs({ name: 'misc/rightway' })`
  width: ${convertPxToRem(100)};
  height: ${convertPxToRem(100)};
  color: ${primaryBlue};

  flex: 0 0 auto;

  ${mediaQueryFor.mobile} {
    width: ${convertPxToRem(75)};
    height: ${convertPxToRem(75)};
  }
`

const Title = styled.h1`
  ${heading2_29};
  color: ${primaryDarkNavy};
`

const Description = styled.p.attrs({
  'data-test': 'restrictedLocation.description',
})`
  ${text1_16};
  color: ${secondaryGrey};
`

const ContactLink = styled.a`
  ${textLink1_16};
  color: ${primaryBlue};
`

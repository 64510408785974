import {
  ActionCreator,
  AsyncThunk,
  bindActionCreators,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import { useDispatch, useStore } from 'react-redux'

import type { AppDispatch, RootState } from 'src/store/store'

// `any` is necessary in this case because we need to match any ThunkAction.
// This allows us to pass on correct typings to React components through ConnectedProps
type WhenDispatched<K> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  K extends ThunkAction<infer Returned, any, any, any>
    ? Returned
    : K extends Action
      ? K
      : never

type DispatchMap<M> = {
  [P in keyof M as `dispatch${Capitalize<string & P>}`]: M[P] extends (
    ...args: infer A
  ) => infer R
    ? (...mapped: A) => WhenDispatched<R>
    : never
}

type ActionCreatorsMap = {
  [k: string]:
    | AsyncThunk<unknown, unknown, Record<string, unknown>>
    | ActionCreator<unknown>
}

export const withDispatch =
  <M extends ActionCreatorsMap>(actions: M) =>
  (dispatch: AppDispatch): DispatchMap<M> => {
    const bound = bindActionCreators(actions, dispatch)
    const mapped = Object.entries(bound).map(([key, fn]) => [
      `dispatch${key.charAt(0).toUpperCase()}${key.slice(1)}`,
      fn,
    ])

    return Object.fromEntries(mapped) as DispatchMap<M>
  }

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppStore = () => useStore<RootState>()

import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { secondaryGrey, primaryWhite } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function GenericEmptyStateIcon() {
  const { t } = useTranslation()
  const i18n = {
    emptyText: t('translation.home.medication_details.info.empty_text'),
    x: t('translation.shared.alert_text.exit_button'),
  }

  return (
    <Wrapper data-test="emptyState">
      <ButtonWrapper>
        <HeaderNavLightTheme />
      </ButtonWrapper>
      <ContentContainer>
        <ExclamationIcon />
        <Label>{i18n.emptyText}</Label>
      </ContentContainer>
    </Wrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 100%;
  width: 90%;
  position: absolute;
  margin: auto;
  top: ${convertPxToRem(40)};
`

const Wrapper = styled.div`
  background-color: ${primaryWhite};
  min-height: ${convertPxToRem(300)};
  margin: ${convertPxToRem(40)};
  border-radius: ${convertPxToRem(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${mediaQueryFor.tabletOrLess} {
    flex: 1;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${convertPxToRem(200)};
  ${mediaQueryFor.tabletOrLess} {
    margin-top: ${convertPxToRem(150)};
  }
`
const Label = styled.span`
  ${text2_14};
  color: ${secondaryGrey};
  margin-top: ${convertPxToRem(10)};
  padding: ${convertPxToRem(0, 40)};
  text-align: center;
`
export default memo(GenericEmptyStateIcon)

import { MouseEventHandler, useState } from 'react'

import styled from 'styled-components'

import TooltipIcon from 'src/components/SvgIcons/TooltipIcon'
import {
  primaryDarkNavy,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { ContentController } from './ContentController'
import { PriceModal } from './PriceModal'

interface MedicationCostWithTooltipProps {
  cost: string
  priceId?: string
  insurancePay?: string | null
  additionalInfo?: string
}

export function MedicationCostWithTooltip({
  cost,
  priceId = '',
  insurancePay = '',
  additionalInfo = '',
}: MedicationCostWithTooltipProps) {
  const [isVisibleCostTooltip, setIsVisibleCostTooltip] = useState(false)

  const openCostTooltip: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setIsVisibleCostTooltip(true)
  }
  const closeCostTooltip: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setIsVisibleCostTooltip(false)
  }

  const costValue = Number(cost)

  if (Number.isNaN(costValue)) return null

  return (
    <Cost>
      <CostNumber>${costValue.toFixed(2)}</CostNumber>
      <TooltipWrapper
        data-test="drugDetails.cost.tooltipWrapper"
        onClick={openCostTooltip}
      >
        <TooltipIcon fillCircle={secondaryLightGrey} fill={secondaryGrey} />
      </TooltipWrapper>
      {isVisibleCostTooltip && (
        <PriceModal closeCostTooltip={closeCostTooltip}>
          <ContentController
            priceId={priceId}
            closeCostTooltip={closeCostTooltip}
            cost={cost}
            insurancePay={insurancePay}
            additionalInfo={additionalInfo}
          />
        </PriceModal>
      )}
    </Cost>
  )
}

const Cost = styled.div`
  display: flex;
  align-items: center;
`

const CostNumber = styled.span`
  color: ${primaryDarkNavy};
  margin-right: ${convertPxToRem(4)};
  ${heading4_21};
`

const TooltipWrapper = styled.div`
  cursor: pointer;
`

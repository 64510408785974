import moment from 'moment'

function updatedAt(at: string) {
  const now = moment()
  const target = moment(at)

  if (target.isSame(now, 'day')) return target.format('h:mm A')
  if (target.isSame(now, 'year')) return target.format('MM/DD')
  return target.format('MM/DD/YYYY')
}

const currency = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const Formatter = { currency }

export const TimeFormatter = { updatedAt }

import { invoke } from '@withease/factories'

import { $$client } from '@shared/api/client'
import { $$drugSearchConfigurationStorage } from '@shared/storage/drugSearch'
import { $$sessionStorage } from '@shared/storage/session'

import { $$freshpaint } from '@entities/freshpaint'
import { $$logging } from '@entities/logging'
import { signOutMutation } from '@entities/me'

import { createStep } from '../lib/step'

import { clearStoreFx, resetTokensFx } from './effects'

export const revokeTokenStep = invoke(createStep, [
  { mutation: signOutMutation },
])

export const stopWorldStep = invoke(createStep, [{ effect: $$client.stopFx }])

export const resetServicesStep = invoke(createStep, [
  { effect: $$client.resetFx },
  { effect: $$freshpaint.resetFx },
  { effect: $$logging.resetFx },
  { effect: clearStoreFx },
])

export const resetStorageStep = invoke(createStep, [
  { effect: resetTokensFx },
  { fire: $$sessionStorage.reset },
  { fire: $$drugSearchConfigurationStorage.reset },
])

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { FeatureFlag } from 'src/constants/featureFlags'

interface IFeaturesSlice {
  featureFlags: Partial<Record<FeatureFlag, boolean>>
}

const initialState: IFeaturesSlice = {
  featureFlags: {},
}

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatureFlags(
      state,
      { payload }: PayloadAction<Record<FeatureFlag, boolean>>,
    ) {
      state.featureFlags = payload
    },
  },
})

export const { /** @deprecated */ setFeatureFlags } = featuresSlice.actions

/**
 * @deprecated Use Effector-based implementation from 'src/entities/features' instead.
 */
// eslint-disable-next-line import-x/no-default-export
export default featuresSlice.reducer

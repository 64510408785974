import { TypedDocumentNode, gql } from '@apollo/client'

import {
  DrugSearchCreateChatResult,
  DrugSearchCreateChatVariables,
} from './types'

export const DRUG_SEARCH_CREATE_CHAT_MUTATION: TypedDocumentNode<
  DrugSearchCreateChatResult,
  DrugSearchCreateChatVariables
> = gql`
  mutation drugSearchCreateChat($drugInformation: DrugInformationInput!) {
    chat: pdsCreateChat(drug_information: $drugInformation) {
      id
    }
  }
`

// eslint-disable-next-line no-restricted-imports
import { History, NavigateFn, globalHistory, navigate } from '@reach/router'
import { createEvent, createStore, sample } from 'effector'

export const setup = createEvent<void>()

/**
 * TODO: swap for a non-global history (e.g. map from
 * a Router instance)after migration to a new routing
 * library.
 *
 * A router instance is always static, so this should
 * not need a separate Gate.
 */
export const $history = createStore<History>(globalHistory, { skipVoid: false })

export const $navigate = createStore<NavigateFn>(navigate, { skipVoid: false })

sample({
  clock: $history.updates,
  fn: ({ navigate: fn }) => fn,
  target: $navigate,
})

/* eslint no-param-reassign: ["error", { "props": true, ignorePropertyModificationsFor: ["draft"] }] */

import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  NextLink,
  Operation,
  Observable,
  fromPromise,
} from '@apollo/client'
import type { GraphQLFormattedError } from 'graphql'
import { produce } from 'immer'

import { REFRESH_TOKENS_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { ClientContext, RefreshMutationResponse } from 'src/apollo/utils/types'
import { GraphQLErrorCode } from 'src/constants/errorCodeConstants'
import tokensStorageInterface from 'src/utils/storage/tokens'

export class TokenManagerLink extends ApolloLink {
  public client: ApolloClient<unknown> | null = null

  private refresher: Promise<void> | null = null

  request(operation: Operation, forward: NextLink) {
    const ctx = operation.getContext() as ClientContext

    if (ctx.isRefreshOperation) return forward(operation)

    return new Observable<FetchResult>((sub) => {
      TokenManagerLink.authorizeOperation(operation)

      const request = forward(operation)
      let isLocked = false

      const onRetry = () =>
        forwardObservable({
          from: this.request(operation, forward),
          to: sub,
        })

      const onNext = (result: FetchResult) => {
        const isExpired =
          // False positive
          // eslint-disable-next-line @typescript-eslint/unbound-method
          result.errors?.some(TokenManagerLink.isExpiredError) ?? false

        if (!isExpired) return sub.next(result)

        isLocked = true

        return forwardObservable({
          from: fromPromise(this.barrier),
          to: { complete: onRetry, error: sub.error.bind(sub) },
        })
      }

      request.subscribe({
        next: onNext,
        error: sub.error.bind(sub),
        complete: () => {
          if (!isLocked) sub.complete()
        },
      })
    })
  }

  private async doRefresh(): Promise<{ access: string; refresh: string }> {
    const token = TokenManagerLink.refreshToken
    if (!token) throw new Error('No refresh token is present.')
    if (!this.client) throw new Error('No client registered, can not refresh.')

    const { data } = await this.client.mutate<
      RefreshMutationResponse,
      Record<string, never>,
      ClientContext
    >({
      mutation: REFRESH_TOKENS_MUTATION,
      context: {
        isRefreshOperation: true,
        headers: { 'X-Refresh-Token': `Bearer ${token}` },
      },
    })

    const { access_token: access, refresh_token: refresh } =
      data?.refreshTokens ?? {}

    if (!access || !refresh) throw new Error('Refresh mutation has failed.')

    return { access, refresh }
  }

  private static authorizeOperation(operation: Operation): void {
    const prev = operation.getContext() as ClientContext
    const token = TokenManagerLink.accessToken

    if (!token) return

    const next = produce(prev, (draft) => {
      draft.headers.Authorization = `Bearer ${token}`
    })

    operation.setContext(next)
  }

  private get barrier(): Promise<void> {
    if (!this.refresher) {
      this.refresher = this.doRefresh()
        .then(({ access, refresh }) => {
          tokensStorageInterface.modify.setTokens({ access, refresh })
        })
        .finally(() => {
          this.refresher = null
        })
    }

    return this.refresher
  }

  private static get accessToken(): string {
    const { access } = tokensStorageInterface.access()
    return access
  }

  private static get refreshToken(): string {
    const { refresh } = tokensStorageInterface.access()
    return refresh
  }

  public static isExpiredError({ extensions }: GraphQLFormattedError): boolean {
    return extensions?.code === GraphQLErrorCode.EXPIRED_SESSION
  }
}

interface SubscriptionObserver<T> {
  next?(value: T): void
  error?(errorValue: unknown): void
  complete?(): void
}

function forwardObservable<T>({
  from,
  to,
}: {
  from: Observable<T>
  to: SubscriptionObserver<T>
}) {
  from.subscribe({
    next: to.next?.bind(to),
    error: to.error?.bind(to),
    complete: to.complete?.bind(to),
  })
}

const tokenLink = new TokenManagerLink()

export default tokenLink

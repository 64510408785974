import { TypedDocumentNode, gql } from '@apollo/client'

import { CarePlansQueryResult, CarePlansQueryVariables } from './types'

export const CARE_PLANS_QUERY: TypedDocumentNode<
  CarePlansQueryResult,
  CarePlansQueryVariables
> = gql`
  query carePlansQuery(
    $filters: CarePlansFiltersInput
    $first: Int
    $cursor: String
  ) {
    carePlans(filters: $filters, first: $first, after: $cursor) {
      nodes {
        ...carePlan
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment carePlan on CarePlan {
    id
    imageUrl: image_url
    feedFields: feed_fields {
      ...feedFields
    }
  }

  fragment feedFields on FeedFields {
    title
    description
    label
    callToAction: call_to_action
  }
`

import { TypedDocumentNode, gql } from '@apollo/client'

import { EligibilityCheckResult, EligibilityCheckVariables } from './types'

export const ELIGIBILITY_CHECK_QUERY: TypedDocumentNode<
  EligibilityCheckResult,
  EligibilityCheckVariables
> = gql`
  query eligibilityCheck($email: String!) {
    eligibility: employeeAccessibilityCheck(email: $email) {
      status: accessibility_status
    }
  }
`

import React from 'react'

import styled from 'styled-components'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { Appointment } from '../../api/appointmentQuery/types'
import {
  AsapTime,
  CancelledTime,
  ConfirmedTime,
  PendingTime,
  SpecificTime,
  SuggestedTime,
  TimeBlock,
} from '../../api/timeSlots/types'

import { AppointmentChoiceCard } from './AppointmentChoiceCard'
import { AppointmentTimeCard } from './AppointmentTimeCard'

interface AppointmentTimeSlotsProps {
  timeSlots: Appointment['timeSlots']
}

interface TimeSlotProps {
  index: number
  slot:
    | AsapTime
    | SpecificTime
    | TimeBlock
    | SuggestedTime
    | PendingTime
    | ConfirmedTime
    | CancelledTime
}

export function AppointmentTimeSlots({ timeSlots }: AppointmentTimeSlotsProps) {
  if (timeSlots.length === 0) return null

  return (
    <Wrapper>
      {timeSlots.map((slot, index) => (
        <TimeSlot slot={slot} index={index + 1} key={slot.id} />
      ))}
    </Wrapper>
  )
}

function TimeSlot({ index, slot }: TimeSlotProps) {
  switch (slot.__typename) {
    case 'AsSoonAsPossible':
    case 'SpecificTime':
    case 'TimeBlock':
      return <AppointmentChoiceCard index={index} slot={slot} />

    case 'SuggestedTime':
    case 'PendingTime':
    case 'ConfirmedTime':
    case 'CancelledTime':
      return <AppointmentTimeCard slot={slot} />
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: ${convertPxToRem(16)};
`

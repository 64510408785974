import { LargeMapBaseMarker } from 'src/features/LargeMap'

import {
  AppointmentDoctor,
  AppointmentFacility,
} from '../api/appointmentQuery/types'

export function isFacilityAssignee(
  assignee: AppointmentDoctor | AppointmentFacility,
): assignee is AppointmentFacility {
  return assignee.__typename === 'AppointmentFacility'
}

export function pickFacilityMarker(
  facility: AppointmentFacility,
): LargeMapBaseMarker {
  return {
    id: 'address',
    latitude: facility.latitude,
    longitude: facility.longitude,
  }
}

import React, { memo } from 'react'

import styled from 'styled-components'

import NewMessageIcon from 'src/components/SvgIcons/NewMessageIcon'
import { IconBoxShadow } from 'src/theme/baseStyles'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonNavigateToChatNew40Props {
  onClick: () => void
  fill?: string
  width?: string
  height?: string
  disabled?: boolean
  dataTestProp?: string
}

function ButtonNavigateToChatNew40({
  onClick = () => {},
  fill = secondaryGrey,
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  disabled = false,
  dataTestProp = 'ButtonNavigateToChatNew40TestId',
}: ButtonNavigateToChatNew40Props) {
  const handleClick = () => {
    if (disabled) return
    onClick()
  }

  return (
    <Root disabled={disabled} onClick={handleClick} data-test={dataTestProp}>
      <NewMessageIcon fill={fill} width={width} height={height} />
    </Root>
  )
}

const Root = styled.div<{ disabled?: boolean }>`
  ${IconBoxShadow};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(26)};
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
`

export default memo(ButtonNavigateToChatNew40)

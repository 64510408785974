import { useMutation } from '@apollo/client'

import { DEACTIVATE_CHAT_MUTATION } from './graphql'

export function useDeactivateChatMutation(id: string) {
  const [deactivate, { loading: isLoading }] = useMutation(
    DEACTIVATE_CHAT_MUTATION,
    {
      variables: { id },
      optimisticResponse: { chat: { id, isInactive: true } },
    },
  )

  return { deactivate, isLoading }
}

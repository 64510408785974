import { useNavigate } from '@reach/router'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { DrugSearchFlowEntrypoint } from 'src/entities/drugDetails'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleYellow,
  primaryDarkNavy,
  primaryWhite,
} from 'src/theme/colors'
import { textLink1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ActivityDrugSearchAccessButtonProps {
  className?: string
}

export function ActivityDrugSearchAccessButton({
  className = '',
}: ActivityDrugSearchAccessButtonProps) {
  const navigate = useNavigate()

  const t = useScopedTranslation(
    'translation.medications.drug_search.placeholders',
  )

  const handleClick = () => {
    void navigate(`/${ROUTES.DRUG_SEARCH_PATH}/${ROUTES.DRUG_SEARCH}`, {
      state: { returnTo: DrugSearchFlowEntrypoint.Activity },
    })
  }

  return (
    <Root className={className}>
      <Card
        data-test="activityDrugSearchAccessButton.card"
        onClick={handleClick}
      >
        <SearchIconWrapper>
          <Sprite name="misc/search" />
        </SearchIconWrapper>

        <Title>{t('search_drug')}</Title>
      </Card>
    </Root>
  )
}

const Root = styled.div`
  padding-bottom: ${convertPxToRem(22)};
`

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: ${convertPxToRem(10)};

  height: ${convertPxToRem(74)};
  border-radius: ${convertPxToRem(8)};

  padding: ${convertPxToRem(16)};

  background-color: ${primaryWhite};
  color: ${primaryDarkNavy};

  cursor: pointer;
`

const SearchIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${convertPxToRem(40)};
  width: ${convertPxToRem(40)};

  background-color: ${featurePaleYellow};

  border-radius: 50%;

  & > svg {
    height: ${convertPxToRem(20)};
    width: ${convertPxToRem(20)};
  }
`

const Title = styled.span`
  ${textLink1_16};
`

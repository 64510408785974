import { RetryLink } from '@apollo/client/link/retry'

// depending on some errors (specially network errors) we may want the requests to be retried automatically
const retryLink = new RetryLink({
  attempts: (count) => count <= 1, // Only retry once, and ignore on window focus to retry
  delay: {
    initial: 1_000, // Delay is one second between retries
    jitter: false,
  },
})

export default retryLink

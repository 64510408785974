import React, { useEffect, useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { signOut } from 'src/apollo/utils/authHelpers'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { primaryLightBlue } from 'src/theme/colors'
import { text2_14_Bold } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import useQueryString from 'src/utils/hooks/useQueryString'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function SsoFailedScreen() {
  const client = useApolloClient()
  const t = useScopedTranslation('translation.auth.failed')

  const { parsed } = useQueryString() as { parsed: Record<string, string> }
  const { redirect_url } = parsed

  const close = () => {
    window.location.replace(redirect_url || '/')
  }

  useEffect(() => {
    void signOut(client, { isSsoLogin: true })
  }, [client])

  const phoneUrl = useMemo(
    () => formatPhone(t('contact_phone'), PHONE_FORMATS.uri) ?? undefined,
    [t],
  )

  const description = (
    <Trans
      t={t}
      parent={null}
      i18nKey="description"
      values={{ email: t('contact_email'), phone: t('contact_phone') }}
      components={{
        phoneLink: <Link href={phoneUrl} />,
        emailLink: <Link href={`mailto:${t('contact_email')}`} />,
      }}
    />
  )

  return (
    <Alert title={t('title')} description={description}>
      <NativeAlertAction mode="primary" onClick={close}>
        {redirect_url ? t('button_back') : t('button')}
      </NativeAlertAction>
    </Alert>
  )
}

export default SsoFailedScreen as React.FC<RouteComponentProps>

const Alert = styled(NativeAlert)`
  // widen the alert to accomodate for larger content
  width: ${convertPxToRem(320)};
`

// On mobile email and phone can overflow (as they are long)
const Link = styled.a`
  ${text2_14_Bold};
  color: ${primaryLightBlue};

  overflow-wrap: anywhere;

  ${mediaQueryFor.mobile} {
    display: flex;
    justify-content: center;
  }
`

import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import HeaderNavDarkTheme from 'src/components/HeaderNavs/HeaderNavDarkTheme'
import { WebCameraIcon } from 'src/components/SvgIcons/WebCameraIcon'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  FooterPaddingH40,
  BodyPadding40,
  ContentsRootBase,
  MobileFooterPaddingH24,
} from 'src/theme/baseStyles'
import {
  primaryWhite,
  primaryDarkNavy,
  secondaryLightGrey,
  featurePaleBlue,
} from 'src/theme/colors'
import { text1_16, capsHeading1_12, heading2_29 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ModalTelemed({
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  homePage = '',
}) {
  const { t } = useTranslation()
  const i18n = {
    title: t('translation.home.talk_with_doctor.title'),
    subtitle: t('translation.home.talk_with_doctor.sub_title'),
    firstDescription: t('translation.home.talk_with_doctor.description_1'),
    secondDescription: t('translation.home.talk_with_doctor.description_2'),
    thirdDescription: t('translation.home.talk_with_doctor.description_3'),
    buttonText: t('translation.chat.inbox.without_messages.button_chat'),
  }

  const navigate = useNavigate()

  const navigateBack = () => {
    navigate(`/${homePage}`)
  }
  const handleClick = () => {
    showThirdSectionModal()
    openNewChat()
  }
  return (
    <Wrapper>
      <Root>
        <BgOverlay />
        <Content>
          <HeaderNavDarkTheme onBack={navigateBack} />

          <Header>
            <Title>{i18n.subtitle.toUpperCase()}</Title>
            <WebCameraIcon
              fill={primaryDarkNavy}
              fillCircle={featurePaleBlue}
            />
          </Header>

          <ContentTitle>{i18n.title}</ContentTitle>

          <Description>{i18n.firstDescription}</Description>
          <Description>{i18n.secondDescription}</Description>
          <Description>{i18n.thirdDescription}</Description>
        </Content>
        <ButtonBottomWrapper onClick={handleClick}>
          <ButtonBottom theme="carePlan" title={i18n.buttonText} />
        </ButtonBottomWrapper>
      </Root>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertPxToRem(40)};
  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`
const Root = styled.div`
  ${ContentsRootBase};
  border-radius: ${convertPxToRem(8)};
  ${mediaQueryFor.tabletOrLess} {
    border-radius: 0;
  }
  ${mediaQueryFor.mobile} {
    padding: 0;
  }
`
const Content = styled.div`
  position: absolute;
  ${ContentsRootBase};
  ${BodyPadding40};
  padding-bottom: ${convertPxToRem(200)};
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(24, 24, 150)};
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${convertPxToRem(45)};
  margin-bottom: ${convertPxToRem(25)};
`
const Title = styled.div`
  color: ${secondaryLightGrey};
  padding-bottom: ${convertPxToRem(23)};
  ${capsHeading1_12};
`
const ContentTitle = styled.div`
  color: ${primaryWhite};
  padding-bottom: ${convertPxToRem(13)};
  ${heading2_29};
`
const Description = styled.div`
  padding-bottom: ${convertPxToRem(22)};
  color: ${primaryWhite};
  ${text1_16}
`
const BgOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${primaryDarkNavy};
`

const ButtonBottomWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(45)};
  left: 0;
  right: 0;
  ${FooterPaddingH40};

  ${mediaQueryFor.mobile} {
    ${MobileFooterPaddingH24};
  }
`

ModalTelemed.propTypes = {
  showThirdSectionModal: PropTypes.func,
  openNewChat: PropTypes.func,
  homePage: PropTypes.string,
}

const mapStateToProps = ({ common }) => ({
  homePage: common.homePage,
})

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalTelemed)

import { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

import useFavouriteCareTeamQuery from 'src/apollo/hooks/useFavouriteCareTeamQuery'
import ButtonCall40 from 'src/components/Buttons/ButtonCall40'
import FeatureGuard from 'src/components/FeatureGuard'
import FavouriteIcon from 'src/components/SvgIcons/FavouriteIcon'
import IconSvg40 from 'src/components/SvgIcons/IconSvg40'
import LocatorIcon from 'src/components/SvgIcons/LocatorIcon'
import { FeatureFlag } from 'src/constants/featureFlags'
import { useFacilityCareTeamToggle } from 'src/entities/careTeam'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import ActionConfrimationModal, {
  ACTION_TYPE,
} from 'src/sections/SectionCareLocator/components/ProfileComponents/ActionConfirmationModal'
import { setPrefilledMessage, startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  primaryDarkNavy,
  secondaryGrey,
  primaryWhite,
  featurePaleRed,
} from 'src/theme/colors'
import { heading8_12 } from 'src/theme/fonts'
import { openLocations } from 'src/utils/locationHelpers'
import {
  formatPhone,
  PHONE_FORMATS,
  validatePhone,
} from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { useDoctorCareTeamToggle } from 'src/widgets/DoctorCard/lib'

function Actions({
  location = { latitude: '', longitude: '' },
  profileId = '',
  preferredPhone = '',
  isFacility = false,
  disableConfirmation = false,
  providerName = '',
  setChatPrefill = () => {},
  showThirdSectionModal = () => {},
  openNewChat = () => {},
}) {
  const { t } = useTranslation()
  const i18n = {
    callAction: t('translation.shared.buttons.call'),
    directionsAction: t('translation.shared.buttons.directions'),
    careTeamAction: t('translation.shared.buttons.care_team'),
    chatPrefill: (provider) =>
      t(
        'translation.home.care_tools.care_finder.doctor_details.actions_confirmation.chat_prefill',
        { provider },
      ),
  }

  const [tooltip, setTooltip] = useState(ACTION_TYPE.NONE)
  const { navigator: navigatorPhone } = useOrganizationPhoneQuery()

  const { isAddedToCareTeam } = useFavouriteCareTeamQuery(profileId, isFacility)

  const { toggle: toggleDoctorCareTeam, isLoading: isDoctorLoading } =
    useDoctorCareTeamToggle({
      id: profileId,
      isFavorite: isAddedToCareTeam,
    })

  const { toggle: toggleFacilityCareTeam, isLoading: isFacilityLoading } =
    useFacilityCareTeamToggle(profileId, isAddedToCareTeam)

  const isPhoneValid = validatePhone(preferredPhone)
  const formattedPhone = isPhoneValid
    ? formatPhone(preferredPhone, PHONE_FORMATS.national)
    : formatPhone(navigatorPhone, PHONE_FORMATS.national)

  const careTeamSwitcher = () =>
    isFacility ? void toggleFacilityCareTeam() : void toggleDoctorCareTeam()

  const goToDirections = () =>
    openLocations({
      location,
    })

  const makeCall = () => {
    // This will never be called from Medicine cabinet, so we do not check for it
    // skip renderGuidePhoneNumberModal
    const link = isPhoneValid
      ? formatPhone(preferredPhone, PHONE_FORMATS.uri)
      : formatPhone(navigatorPhone, PHONE_FORMATS.uri)
    window.open(link, '_self')
  }

  const closeTooltip = () => setTooltip(ACTION_TYPE.NONE)
  const openCallTooltip = () => setTooltip(ACTION_TYPE.CALL_PHONE)
  const openDirectionsTooltip = () => setTooltip(ACTION_TYPE.FIND_DIRECTIONS)
  const openCareTeamTooltip = () => setTooltip(ACTION_TYPE.TOGGLE_CARE_TEAM)

  const openChat = () => {
    showThirdSectionModal()
    openNewChat()
    setChatPrefill(i18n.chatPrefill(providerName))
  }

  const confirmationPrimaryAction = () => {
    closeTooltip()
    openChat()
  }

  const confirmationSecondaryAction = () => {
    closeTooltip()
    switch (tooltip) {
      case ACTION_TYPE.CALL_PHONE:
        makeCall()
        break
      case ACTION_TYPE.FIND_DIRECTIONS:
        goToDirections()
        break
      case ACTION_TYPE.TOGGLE_CARE_TEAM:
        careTeamSwitcher()
        break
      default:
        // nothing
        break
    }
  }

  return (
    <Root>
      {!!preferredPhone && (
        <ButtonWrapper>
          <ButtonCall40
            fill={secondaryGrey}
            withShadow
            preferredPhone={formattedPhone}
            onClick={disableConfirmation ? null : openCallTooltip}
          />
          <ActionTitle>{i18n.callAction}</ActionTitle>
        </ButtonWrapper>
      )}

      <ButtonWrapper>
        <IconSvg40
          fill={secondaryGrey}
          IconSvg={LocatorIcon}
          border="none"
          onClick={disableConfirmation ? goToDirections : openDirectionsTooltip}
          withShadow
        />
        <ActionTitle>{i18n.directionsAction}</ActionTitle>
      </ButtonWrapper>

      <FeatureGuard permit={FeatureFlag.MY_CARE_TEAM}>
        <ButtonWrapper isLoading={isDoctorLoading || isFacilityLoading}>
          <IconSvg40
            fill={isAddedToCareTeam ? primaryDarkNavy : secondaryGrey}
            bgColor={isAddedToCareTeam ? featurePaleRed : primaryWhite}
            IconSvg={FavouriteIcon}
            border="none"
            onClick={
              isAddedToCareTeam || disableConfirmation
                ? careTeamSwitcher
                : openCareTeamTooltip
            }
            withShadow
          />
          <ActionTitle>{i18n.careTeamAction}</ActionTitle>
        </ButtonWrapper>
      </FeatureGuard>

      {tooltip !== ACTION_TYPE.NONE && (
        <ActionConfrimationModal
          actionType={tooltip}
          actionPrimary={confirmationPrimaryAction}
          actionSecondary={confirmationSecondaryAction}
          toggleTooltip={closeTooltip}
          additionalInfo={formattedPhone}
          isFacility={isFacility}
        />
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding-top: ${convertPxToRem(10)};
  padding-bottom: ${convertPxToRem(10)};
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${convertPxToRem(70)};
  opacity: ${({ isLoading }) => isLoading && '0.2'};
  margin-right: ${convertPxToRem(2)};
  :last-child {
    margin-right: 0;
  }
`
const ActionTitle = styled.span`
  color: ${secondaryGrey};
  ${heading8_12};
  padding-top: ${convertPxToRem(12)};
  text-align: center;
`

Actions.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isFacility: PropTypes.bool,
  disableConfirmation: PropTypes.bool,
  providerName: PropTypes.string,
  setChatPrefill: PropTypes.func,
  profileId: PropTypes.string,
  preferredPhone: PropTypes.string,
  showThirdSectionModal: PropTypes.func,
  openNewChat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  setChatPrefill: (value) => dispatch(setPrefilledMessage(value)),
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
})

const connector = connect(null, mapDispatchToProps)

// FIXME: add correct types when refactoring this file to TS
export default connector(/** @type {React.FC<Record<string, any>>} */ (Actions))

import { gql } from '@apollo/client'

const DISMISS_CUSTOM_FEED_ITEM_MUTATION = gql`
  mutation DismissCustomFeedItem($feed_item_type: CUSTOM_FEED_ITEMS_TYPES!) {
    dismissCustomFeedItem(feed_item_type: $feed_item_type) {
      success
    }
  }
`
export default DISMISS_CUSTOM_FEED_ITEM_MUTATION

import { createEffect, createStore, sample } from 'effector'

import { initFreshpaint, identifyFreshpaint, resetFreshpaint } from '../lib'

export const initFreshpaintFx = createEffect(initFreshpaint)
export const identifyFreshpaintFx = createEffect(identifyFreshpaint)
export const resetFreshpaintFx = createEffect(resetFreshpaint)

export const $isFreshpaintReady = createStore<boolean>(false)

sample({
  source: initFreshpaintFx.doneData,
  fn: () => true,
  target: $isFreshpaintReady,
})

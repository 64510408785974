import { list } from '@effector/reflect'
import styled from 'styled-components'

import { $$drugInfo } from 'src/entities/drugInfo'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { primaryDarkNavy } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'

import { alternativeSelected } from '../../model'

import { AlternativeItem } from './AlternativeItem'

export function AlternativesList() {
  const t = useScopedTranslation('translation.medications.drug_search.labels')

  return (
    <Root>
      <Title>{t('alternatives')}</Title>

      <AlternativeItems />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Title = styled.span`
  display: flex;
  width: 100%;
  color: ${primaryDarkNavy};
  ${capsHeading1_12};
  text-transform: uppercase;
`

const AlternativeItems = list({
  view: AlternativeItem,
  source: $$drugInfo.$alternatives,
  mapItem: {
    name: ({ name }) => name,
  },
  getKey: ({ name }) => name,
  bind: {
    selectAlternative: alternativeSelected,
  },
})

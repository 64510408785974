import { attach, sample } from 'effector'

import { Coordinates } from 'src/entities/geolocation'

import { mapGeocodedAddress } from '../lib/geocode'

import { UserLocations } from './core'

const completionService = new google.maps.places.AutocompleteService()
const geocoderService = new google.maps.Geocoder()

/**
 * ISO 3166 Alpha-2 Country Codes
 * - United States
 * - Puerto Rico
 * - Virgin Islands
 */
const countryCodes = ['us', 'pr', 'vi']

export const completionSessionCreated = UserLocations.event<void>()

export const $completionSession =
  UserLocations.store<google.maps.places.AutocompleteSessionToken>(
    new google.maps.places.AutocompleteSessionToken(),
  )

export const fetchCompletionsFx = attach({
  source: $completionSession,
  async effect(sessionToken, input: string) {
    if (!input) return []

    const { predictions } = await completionService.getPlacePredictions({
      input,
      sessionToken,
      componentRestrictions: { country: countryCodes },
      types: ['subpremise', 'premise', 'street_address', 'postal_code'],
    })

    return predictions
  },
})

export const geocodePlaceIdFx = UserLocations.effect(
  async (placeId: string) => {
    const { results } = await geocoderService.geocode({ placeId })
    const address = results.find(({ place_id }) => place_id === placeId)

    return address ? mapGeocodedAddress(address) : null
  },
)

export const geocodeCoordinatesFx = UserLocations.effect(
  async (location: Coordinates) => {
    const { results } = await geocoderService.geocode({
      location: { lat: location.latitude, lng: location.longitude },
    })

    const address = results.find(({ types }) =>
      types.includes('street_address'),
    )

    return address ? mapGeocodedAddress(address) : null
  },
)

sample({
  clock: completionSessionCreated,
  fn: () => new google.maps.places.AutocompleteSessionToken(),
  target: $completionSession,
})

import React from 'react'

import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { NativeInlineAlert } from 'src/shared/ui/alerts/NativeInlineAlert'

interface AppointmentCancellationAlertProps {
  onDismiss: () => void
  onCancel: () => void
}

export function AppointmentCancellationAlert({
  onCancel,
  onDismiss,
}: AppointmentCancellationAlertProps) {
  const t = useScopedTranslation(
    'translation.appointments.confirmed_appointment',
  )

  const i18n = {
    title: t('alert_title'),
    description: t('alert_subtitle'),

    dismiss: t('dismiss'),
    cancel: t('cancel'),
  }

  return (
    <NativeInlineAlert title={i18n.title} description={i18n.description}>
      <NativeAlertAction mode="danger" onClick={onCancel}>
        {i18n.cancel}
      </NativeAlertAction>
      <NativeAlertAction mode="primary" onClick={onDismiss}>
        {i18n.dismiss}
      </NativeAlertAction>
    </NativeInlineAlert>
  )
}

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import ExclamationIcon from 'src/components/SvgIcons/ExclamationIcon'
import { $specialtyPharmacies } from 'src/entities/drugDetails'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NoticeBubble } from 'src/shared/ui/NoticeBubble'
import { featurePaleYellow, primaryOrange } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface WarningMessageProps {
  isSpecialty: boolean
}

function WarningMessageView({ isSpecialty }: WarningMessageProps) {
  const t = useScopedTranslation('translation.medications.drug_search.messages')

  const i18n = {
    speciality: t('available_through_specialty'),
    notCovered: t('medication_is_not_covered'),
  }

  return (
    <Warning icon={<ExclamationIcon fill={primaryOrange} />}>
      {isSpecialty ? i18n.speciality : i18n.notCovered}
    </Warning>
  )
}

const Warning = styled(NoticeBubble)`
  margin-bottom: ${convertPxToRem(16)};
  background-color: ${featurePaleYellow};
`

export const WarningMessage = reflect({
  view: WarningMessageView,
  bind: {
    isSpecialty: $specialtyPharmacies.map(
      (pharmacies) => pharmacies.length > 0,
    ),
  },
})

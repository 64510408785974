import { useEffect } from 'react'

import { useUnit } from 'effector-react'

import { loggingContextStackAdded } from './model'
import { LoggingContextStack } from './types'

export function useApplyLoggingContext(
  stack: LoggingContextStack,
  meta: Record<string, unknown>,
) {
  const ingest = useUnit(loggingContextStackAdded)

  useEffect(() => {
    const ctx = Object.fromEntries(
      Object.entries(meta).filter(([_, v]) => Boolean(v)),
    )

    ingest({ stack, ctx })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.keys(meta), ...Object.values(meta)])
}

export const logmask = {
  email(email: string): string {
    if (!email) return '<n/a>'

    const match = email.match(/^(?<username>.+)@(?<domain>.+)$/i)

    if (!match?.groups) return this.line(email)

    const { username, domain } = match.groups
    const masked = `${username.slice(0, 5)}***`

    return `${masked}@${domain}`
  },
  jwt(token = '<n/a>', upTo = 6): string {
    if (!/^.*\..+\..*$/.test(token)) return this.line(token, upTo)

    const [header, payload, hash] = token.split('.')

    return [
      `${header.slice(0, 4)}***`,
      `${payload.slice(0, 7)}***`,
      `${hash.slice(0, 4)}***`,
    ].join('.')
  },
  line(line = '<n/a>', upTo = 5): string {
    return Array.from(line, (v, i) => (i >= upTo ? '*' : v)).join('')
  },
  map(
    rec: Record<string | number, string | null> | undefined | null,
    upTo = 3,
  ): string {
    if (!rec) return '<empty>'

    const acc = (Array.isArray(rec) ? [] : {}) as typeof rec

    for (const [key, value] of Object.entries(rec)) {
      acc[key] = value ? this.line(value, upTo) : null
    }

    return JSON.stringify(acc)
  },
}

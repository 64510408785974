import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { EU_COUNTRIES } from 'src/utils/locationHelpers'

export enum LocationCheckStatus {
  Pending,
  Allowed,
  Forbidden,
  Failed,
}

export interface GeolocationSlice {
  locationCheck: LocationCheckStatus
}

const COUNTRY_DETECTION_TIMEOUT = 3_000

const initialState: GeolocationSlice = {
  locationCheck: LocationCheckStatus.Pending,
}

function fetchTimeout(delayMilliseconds: number): AbortSignal {
  if (typeof AbortSignal.timeout === 'function')
    return AbortSignal.timeout(delayMilliseconds)

  const controller = new AbortController()
  setTimeout(() => controller.abort(), delayMilliseconds)
  return controller.signal
}

export function getCountry(): Promise<string> {
  const api = 'https://api.country.is'
  return fetch(api, {
    cache: 'no-cache',
    signal: fetchTimeout(COUNTRY_DETECTION_TIMEOUT),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      return res.json()
    })
    .then((res) => res.country)
    .catch((err) => {
      throw Error(err.message)
    })
}

export const checkUserLocation = createAsyncThunk(
  'geolocation/checkGeolocation',
  async () => {
    const country = await getCountry()
    const isSequoia = navigator.userAgent.indexOf('Sequoia') !== -1
    return {
      isAccessAllowed: isSequoia || !EU_COUNTRIES.includes(country),
    }
  },
)

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkUserLocation.fulfilled, (state, { payload }) => {
      state.locationCheck = payload.isAccessAllowed
        ? LocationCheckStatus.Allowed
        : LocationCheckStatus.Forbidden
    })

    builder.addCase(checkUserLocation.rejected, (state) => {
      state.locationCheck = LocationCheckStatus.Failed
    })
  },
})

export default geolocationSlice.reducer

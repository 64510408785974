import { gql, TypedDocumentNode } from '@apollo/client'

import { Pharmacy } from './types'

export const PHARMACY_FRAGMENT: TypedDocumentNode<
  Pharmacy,
  Record<string, never>
> = gql`
  fragment pharmacy on PbmRetailPharmacy {
    name
    addressLine1
    isFavorite
    latitude
    longitude
    phone
    city
    state
    zip
    nabp
    workSchedule {
      ...workSchedule
    }
  }

  fragment workSchedule on WorkSchedule {
    closeHour: close_hour
    dayOfWeek: day_of_week
    openHour: open_hour
  }
`

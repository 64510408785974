import { DrugPrice } from 'src/entities/drugDetails'
import { getWorkingInfo } from 'src/entities/schedule'

export enum DrugSearchSortingOrder {
  Price = 'price',
  Hours = 'hours',
}

const comparators = {
  [DrugSearchSortingOrder.Hours]: (left: DrugPrice, right: DrugPrice) => {
    if (left.isRejected !== right.isRejected) {
      return left.isRejected ? 1 : -1
    }

    return getWorkingInfo(left.pharmacy.workSchedule)?.isOpenNow ? -1 : 1
  },
  [DrugSearchSortingOrder.Price]: (left: DrugPrice, right: DrugPrice) => {
    if (left.isRejected !== right.isRejected) {
      return left.isRejected ? 1 : -1
    }

    if (left.isBestPrice) return -1
    if (right.isBestPrice) return 1

    return Number(left.patientPay) - Number(right.patientPay)
  },
}

export const sortDrugsBy = (
  order: DrugSearchSortingOrder,
  drugs: DrugPrice[],
) => drugs.toSorted(comparators[order])

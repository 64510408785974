import chatsReducer from 'src/store/slices/chats'
import commonReducer from 'src/store/slices/common'
import featuresReducer from 'src/store/slices/features'
import tutorialReducer from 'src/store/slices/tutorial'
import userReducer from 'src/store/slices/user'

export default {
  common: commonReducer,
  chats: chatsReducer,
  tutorial: tutorialReducer,
  features: featuresReducer,
  user: userReducer,
}

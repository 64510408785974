import React from 'react'

import PropTypes from 'prop-types'

import { primaryDarkNavy } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function PillIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = primaryDarkNavy,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.152 1.761c2.254-2.345 5.906-2.345 8.16 0h-.003c2.245 2.351 2.245 6.15 0 8.5l-7.665 7.985A5.611 5.611 0 0 1 6.568 20c-2.337.007-4.447-1.458-5.34-3.708-.893-2.25-.392-4.839 1.268-6.553l7.656-7.978zM14.2 12.185l2.98-3.102a4.392 4.392 0 0 0 1.214-3.067c.008-1.758-1.005-3.346-2.563-4.021-1.559-.675-3.354-.302-4.545.944l-2.98 3.106a.43.43 0 0 0 0 .59l5.328 5.55c.157.163.41.163.566 0z"
        fill="#2E3141"
      />
      <path
        d="M14.107 2.633a3.208 3.208 0 0 0-2.328 1.004.644.644 0 0 0 .007.876.583.583 0 0 0 .841.008 2.08 2.08 0 0 1 2.96 0 .583.583 0 0 0 .849 0 .644.644 0 0 0 0-.884 3.207 3.207 0 0 0-2.329-1.004z"
        fill={fill}
      />
    </svg>
  )
}

PillIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default PillIcon

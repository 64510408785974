import { useCallback, useMemo, useState } from 'react'

import { reflect } from '@effector/reflect'
import { useLocation, useNavigate, WindowLocation } from '@reach/router'
import { useGate } from 'effector-react'
import styled from 'styled-components'

import HeaderNavLightTheme from 'src/components/HeaderNavs/HeaderNavLightTheme'
import { ROUTES } from 'src/constants/routesConstants'
import { Pharmacy } from 'src/entities/pharmacy'
import { HoverButton } from 'src/features/HoverButton'
import {
  LargeMap,
  LargeMapHookParams,
  MAP_PADDING_PIXELS,
} from 'src/features/LargeMap'
import {
  $$pharmacySearch,
  drugSearchPharmaciesQuery,
} from 'src/features/pharmacySearch'
import { SpinnerWithTitle } from 'src/features/SpinnerWithTitle'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'
import { PreferredPharmacyCard } from 'src/widgets/PreferredPharmacyCard'

import { $markers, PreferredPharmacyMapGate } from '../model'

import { PreferredPharmacyMarker } from './PreferredPharmacyMarker'

interface LocationState {
  drugName: string
}

interface PreferredPharmacyMapProps {
  pharmacies: Pharmacy[]
  isLoading: boolean
}

function PreferredPharmacyMapView({
  pharmacies,
  isLoading,
}: PreferredPharmacyMapProps) {
  const t = useScopedTranslation('translation.medications.drug_search.status')

  const navigate = useNavigate()
  const { state } = useLocation() as WindowLocation<LocationState>
  const { drugName } = state ?? {}

  useGate(PreferredPharmacyMapGate, { drugName })

  const [selectedPharmacyName, setSelectedPharmacyName] = useState('')

  const navigateToPharmaciesList = useCallback(() => {
    void navigate(ROUTES.DRUG_SEARCH_PHARMACY_RESULTS, {
      state: { drugName },
      replace: true,
    })
  }, [drugName, navigate])

  const selectedPharmacy = useMemo(
    () => pharmacies?.find(({ name }) => name === selectedPharmacyName),
    [pharmacies, selectedPharmacyName],
  )

  const onSelectMarker = useCallback((name: string) => {
    setSelectedPharmacyName(name)
  }, [])

  const onMapLoaded = useCallback(({ map, bounds }: LargeMapHookParams) => {
    map.fitBounds(bounds, MAP_PADDING_PIXELS)
  }, [])

  if (isLoading) {
    return <SpinnerWithTitle text={t('searching')} />
  }

  return (
    <Root>
      <Content>
        <Map
          onLoaded={onMapLoaded}
          draggable="greedy"
          element={
            <PreferredPharmacyMarker
              selectedMarkerId={selectedPharmacyName}
              onClick={onSelectMarker}
            />
          }
        />
        <HeaderButtons>
          <HeaderNavLightTheme
            withMiddleButton={false}
            isLeftBtnClose
            onClose={navigateToPharmaciesList}
          />
        </HeaderButtons>
      </Content>

      <BottomContainer>
        <HoverButton>
          <HoverButton.List
            onClick={navigateToPharmaciesList}
            dataTestProp="listButton"
          />
        </HoverButton>

        {selectedPharmacy && (
          <PreferredPharmacyCard pharmacy={selectedPharmacy} />
        )}
      </BottomContainer>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  padding: ${convertPxToRem(24)};
  position: relative;

  ${mediaQueryFor.tabletOrLess} {
    padding: 0;
  }
`
const Content = styled.div`
  position: relative;
  width: 100%;

  border-radius: ${convertPxToRem(8)};
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledMap = styled(LargeMap)`
  width: 100%;
  height: 100%;

  ${mediaQueryFor.desktop} {
    border-radius: ${convertPxToRem(20, 20, 0, 0)};
  }
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: ${convertPxToRem(48)};
  right: ${convertPxToRem(48)};
  gap: ${convertPxToRem(16)};
  position: absolute;
  bottom: ${convertPxToRem(50)};
  ${mediaQueryFor.mobile} {
    left: ${convertPxToRem(24)};
    right: ${convertPxToRem(24)};
  }
`
const HeaderButtons = styled.div`
  position: absolute;
  top: ${convertPxToRem(40)};
  left: ${convertPxToRem(40)};
  right: ${convertPxToRem(40)};
  ${mediaQueryFor.mobile} {
    top: ${convertPxToRem(25)};
    left: ${convertPxToRem(25)};
    right: ${convertPxToRem(25)};
  }
`

const Map = reflect({
  view: StyledMap,
  bind: {
    markers: $markers,
  },
})

export const DrugSearchPreferredPharmacyMap = reflect({
  view: PreferredPharmacyMapView,
  bind: {
    pharmacies: $$pharmacySearch.$foundPharmacies,
    isLoading: drugSearchPharmaciesQuery.$pending,
  },
})

import styled from 'styled-components'

import EmptyInboxIcon from 'src/components/SvgIcons/EmptyInboxIcon'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function BlankImage() {
  const t = useScopedTranslation('translation.chat.inbox.without_messages')

  return (
    <Wrapper>
      <EmptyInboxIcon />

      <Subscript>{t('message')}</Subscript>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  gap: ${convertPxToRem(58)};

  padding: ${convertPxToRem(56, 24, 0, 24)};
`

const Subscript = styled.span`
  ${text2_14};

  color: ${secondaryGrey};

  text-align: center;
`

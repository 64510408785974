import { TypedDocumentNode, gql } from '@apollo/client'

import { APPOINTMENT_TIME_SLOT_FRAGMENTS } from '../timeSlots/document'

import {
  AcceptAppointmentTimeSlotResult,
  AcceptAppointmentTimeSlotVariables,
} from './types'

export const ACCEPT_APPOINTMENT_TIME_SLOT_MUTATION: TypedDocumentNode<
  AcceptAppointmentTimeSlotResult,
  AcceptAppointmentTimeSlotVariables
> = gql`
  mutation acceptAppointmentTimeSlot($timeSlot: ID) {
    approveAppointment(time_slot_id: $timeSlot) {
      id
      displayStatus: display_status
      status

      timeSlots: time_slots {
        ...asapTime
        ...specificTime
        ...timeBlock

        ...suggestedTime
        ...pendingTime
        ...confirmedTime
        ...cancelledTime
      }
    }
  }

  ${APPOINTMENT_TIME_SLOT_FRAGMENTS}
`

import Spinner from 'src/components/Spinner'
import { FlipRxCard } from 'src/features/RxCard'
import { usePharmacyRxCardsQuery } from 'src/widgets/PharmacyRxCards'

export function WrappedRxCard() {
  const { pharmacyIdCard, isLoading } = usePharmacyRxCardsQuery()

  if (isLoading) return <Spinner />

  return <FlipRxCard card={pharmacyIdCard} />
}

import { useFragment } from '@apollo/client'

import { TERMS_OF_SERVICE_AGREEMENT_FRAGMENT } from './document'

export function useTermsOfServiceAgreementFragment(id: string) {
  const { data, complete: isComplete } = useFragment({
    fragment: TERMS_OF_SERVICE_AGREEMENT_FRAGMENT,
    fragmentName: 'serviceAgreement',
    from: { __typename: 'TermsOfServiceAgreement', id },
  })

  const agreement = isComplete ? data : null

  return {
    agreement,
  }
}

import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { black } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function FavouriteIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = black,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6611 1.66663C13.2969 1.66663 11.9337 2.16745 10.8887 3.18069L9.99211 4.04344L9.11244 3.18913C7.02237 1.16264 3.65759 1.16267 1.56757 3.18913C-0.522524 5.21562 -0.522524 8.55528 1.56757 10.5817C4.1924 13.1268 6.81742 15.6662 9.4423 18.2112C9.59354 18.3565 9.7951 18.4376 10.0048 18.4376C10.2145 18.4376 10.416 18.3565 10.5673 18.2112L18.4335 10.5733C20.5208 8.54549 20.5235 5.20715 18.4335 3.18069C17.3885 2.16745 16.0253 1.66663 14.6611 1.66663Z"
        fill={fill}
      />
    </svg>
  )
}

export default FavouriteIcon

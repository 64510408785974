import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ChatStep {
  INBOX = 'INBOX',
  CHAT_NEW = 'CHAT_NEW',
  CHAT_THREAD = 'CHAT_THREAD',
}

interface IChatsSlice {
  prefilledMessage: string
  step: ChatStep
  chatId: string
}

const initialState: IChatsSlice = {
  prefilledMessage: '',
  step: ChatStep.INBOX,
  chatId: '',
}

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setPrefilledMessage: (state, action: PayloadAction<string>) => {
      state.prefilledMessage = action.payload
    },
    openInbox: (state) => {
      state.step = ChatStep.INBOX
    },
    startNewChat: (state) => {
      state.step = ChatStep.CHAT_NEW
    },
    openChatThread: (state, action: PayloadAction<string>) => {
      state.step = ChatStep.CHAT_THREAD
      state.chatId = action.payload
    },
  },
})

export const { setPrefilledMessage, openInbox, startNewChat, openChatThread } =
  chatsSlice.actions

export default chatsSlice.reducer

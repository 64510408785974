import { useCallback, useEffect, useMemo, useState } from 'react'

import identity from 'lodash/identity'

import { StorageContainer, StorageSubscriber } from 'src/utils/storage/api'
import persistentStorageInterface from 'src/utils/storage/persistent'
import personalStorageInterface from 'src/utils/storage/personal'
import tokensStorageInterface from 'src/utils/storage/tokens'

export const StorageInterfaces = {
  [StorageContainer.tokens]: tokensStorageInterface,
  [StorageContainer.persistent]: persistentStorageInterface,
  [StorageContainer.personal]: personalStorageInterface,
}

type SelectStorage<T extends StorageContainer> = (typeof StorageInterfaces)[T]

type UseLocalStorageGetState<C extends StorageContainer> = {
  (): ReturnType<SelectStorage<C>['access']>
  <T>(fn: (state: ReturnType<SelectStorage<C>['access']>) => T): T
}

type UseLocalStorage<C extends StorageContainer> =
  SelectStorage<C>['modify'] & {
    getState: UseLocalStorageGetState<C>
  }
type UseLocalStorageState<C extends StorageContainer> = ReturnType<
  SelectStorage<C>['access']
>

const mapRawState = identity as <C extends StorageContainer>(
  state: ReturnType<SelectStorage<C>['access']>,
) => ReturnType<SelectStorage<C>['access']>

/**
 * A React hook to access a certain Storage Container.
 * This will give you a stable access to container actions as well as a function to aquire container's state.
 *
 * @param container The name of the Container you wish to access
 * @returns A `getState` function and all available actions, bound to the container
 */
export function useLocalStorage<C extends StorageContainer>(
  container: C,
): UseLocalStorage<C> {
  type State = UseLocalStorageState<C>

  const api: SelectStorage<C> = useMemo(
    () => StorageInterfaces[container],
    [container],
  )

  const getState = useCallback<UseLocalStorageGetState<C>>(
    (fn = mapRawState) => {
      const state = api.access() as State
      return fn(state)
    },
    [api],
  )

  return {
    getState,
    ...api.modify,
  }
}

/**
 * A React hook to subscribe to a certain container's state.
 * This will re-render your component when state of container you selected changes.
 *
 * For access to actions please see `useLocalStorage` hook.
 *
 * @param container The name of the Container you wish to access
 * @returns The State of the container
 */
export function useLocalStorageState<
  C extends StorageContainer,
  State extends UseLocalStorageState<C>,
>(container: C): State {
  const api: SelectStorage<C> = useMemo(
    () => StorageInterfaces[container],
    [container],
  )

  const [state, setState] = useState(() => api.access() as State)

  useEffect(() => {
    const listener: StorageSubscriber<ReturnType<typeof api.access>> = (next) =>
      setState(next as State)

    const unsubscribe = api.subscribe(listener)
    return unsubscribe
  }, [api, container])

  return state
}

export { StorageContainer }

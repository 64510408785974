import React, { memo } from 'react'

import styled from 'styled-components'

import { primaryWhite, primaryLightBlue } from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function IconCounter({ newMessagesCount }) {
  return <Counter>{newMessagesCount}</Counter>
}

export default memo(IconCounter)

const Counter = styled.div`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
  border-radius: 50%;
  text-align: center;
  background: ${primaryLightBlue};
  color: ${primaryWhite};
  ${capsHeading1_12};
  line-height: ${convertPxToRem(20)};
`

import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'
import {
  chatCreated,
  createChatFx,
} from 'src/features/drugSearchTicketCreation'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { textLink2_14 } from 'src/theme/fonts'

import { ChatWidget } from './ChatWidget'

interface OnlyChatWidgetProps {
  description: string
}

export function OnlyChatWidget({ description }: OnlyChatWidgetProps) {
  const t = useScopedTranslation('translation.medications.drug_search.buttons')

  return (
    <ChatWidget description={description}>
      <ChatButton title={t('chat_with_a_pharmacy_guide')} />
    </ChatWidget>
  )
}

const StyledButtonBottom = styled(ButtonBottom)`
  ${textLink2_14};
`

const ChatButton = reflect({
  view: StyledButtonBottom,
  bind: {
    theme: 'primary',
    handleClick: chatCreated,
    loading: createChatFx.pending,
    disabled: createChatFx.pending,
  },
})

import { gql } from '@apollo/client'

import MEDICAL_BILLS_FRAGMENT from 'src/apollo/fragments/MEDICAL_BILLS_FRAGMENT'

const BILL_CLAIM_FRAGMENT = gql`
  fragment billClaimFragment on BillClaim {
    amount_saved
    claim_category
    claim_category_name
    created_at
    resolved_at
    focus_request
    focus_request_other
    id
    medical_bills {
      ...medicalBillsFragment
    }
    notes
    originally_billed
    status
  }
  ${MEDICAL_BILLS_FRAGMENT}
`

export default BILL_CLAIM_FRAGMENT

import { useEffect } from 'react'

import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import styled, { keyframes } from 'styled-components'

import useCurrentUserQuery from 'src/apollo/hooks/useCurrentUserQuery'
import useUpdateTutorialMutation from 'src/apollo/hooks/useUpdateTutorialMutation'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import { useTermsOfServiceQuery } from 'src/entities/termsOfServices'
import { TUTORIAL_THIRD_STEP } from 'src/sections/SectionDashboard/dashboardHelpers'
import { RootState, withDispatch } from 'src/store'
import {
  advanceTutorial,
  resetTutorial,
  setIsCompleted,
} from 'src/store/slices/tutorial'
import { primaryWhite } from 'src/theme/colors'
import { capsHeading1_12, heading3_25, text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

type TutorialOverlayPropsT = ConnectedProps<typeof connector>

function TutorialOverlay({
  dispatchAdvanceTutorial,
  dispatchResetTutorial,
  dispatchSetIsCompleted,
  tutorialStep = 0,
  isCompleted = true,
  userId = '',
  hasSharedActivityFeedAccesss = false,
  isInitialDataReceived = false,
}: TutorialOverlayPropsT) {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { tooltipsViewed } = useCurrentUserQuery()
  const { termsOfService, isLoading } = useTermsOfServiceQuery(undefined)
  const { updateTutorialVievedMutation } = useUpdateTutorialMutation()

  const steps = [
    {
      title: t('translation.home.welcome_tutorial.title'),
      subTitle: t('translation.home.welcome_tutorial.steps.step1_sub_title'),
      description: t(
        'translation.home.welcome_tutorial.steps.step1_description',
      ),
    },
    {
      title: t('translation.home.welcome_tutorial.title'),
      subTitle: t('translation.home.welcome_tutorial.steps.step2_sub_title'),
      description: t(
        'translation.home.welcome_tutorial.steps.step2_description',
      ),
    },
    {
      title: t('translation.home.welcome_tutorial.title'),
      subTitle: t('translation.home.welcome_tutorial.steps.step3_sub_title'),
      description: t(
        'translation.home.welcome_tutorial.steps.step3_description',
      ),
    },
  ]

  useEffect(() => {
    if (!userId) return

    dispatchSetIsCompleted(tooltipsViewed)
  }, [tooltipsViewed, userId, dispatchSetIsCompleted])

  const nextStep = () => {
    document
      .getElementById('firstMeetAnimate')
      ?.animate([{ opacity: 0.7 }, { opacity: 1 }], {
        duration: 1000,
        easing: 'ease-in',
      })

    if (tutorialStep === TUTORIAL_THIRD_STEP) {
      const variables = {
        attributes: {
          tooltips_viewed: true,
        },
      }

      void updateTutorialVievedMutation(variables)

      dispatchSetIsCompleted(true)
      dispatchResetTutorial()

      return
    }

    dispatchAdvanceTutorial()
    void navigate(`/${ROUTES.HOME_ACTIVITY_PATH}`)
  }

  const shouldShowTutorial =
    !isCompleted &&
    hasSharedActivityFeedAccesss &&
    isInitialDataReceived &&
    !isLoading &&
    termsOfService?.accepted

  if (shouldShowTutorial && tutorialStep !== null)
    return (
      <Overlay
        id="firstMeetAnimate"
        onClick={nextStep}
        data-test="tutorialOverlay"
      >
        <DescriptionWrapper>
          <Title>{steps[tutorialStep]?.title}</Title>
          <SubTitle>{steps[tutorialStep]?.subTitle}</SubTitle>
          <Description>{steps[tutorialStep]?.description}</Description>
        </DescriptionWrapper>
      </Overlay>
    )

  return null
}

const overlayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(21, 29, 70, 0.8),
    rgba(0, 0, 0, 0.95)
  );
  z-index: 2;
  animation: ${overlayAnimation} 1s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${mediaQueryFor.mobile} {
    justify-content: flex-start;
    padding-left: ${convertPxToRem(24)};
  }
`

const DescriptionWrapper = styled.div`
  padding-top: ${convertPxToRem(70)};
  height: ${convertPxToRem(150)};
  width: ${convertPxToRem(270)};
`
const Title = styled.div`
  color: ${primaryWhite};
  ${capsHeading1_12};
  text-transform: uppercase;
  opacity: 0.5;
`
const SubTitle = styled.div`
  color: ${primaryWhite};
  ${heading3_25};
  margin-top: ${convertPxToRem(16)};
`
const Description = styled.div`
  color: ${primaryWhite};
  ${text1_16};
  margin-top: ${convertPxToRem(16)};
`

const mapStateToProps = ({ user, tutorial, common, features }: RootState) => ({
  tutorialStep: tutorial.tutorialStep,
  isCompleted: tutorial.isCompleted,
  userId: user.userId,
  hasSharedActivityFeedAccesss:
    features.featureFlags[FeatureFlag.SHARED_ACTIVITY_FEED],
  isInitialDataReceived: common.isInitialDataReceived,
})

const mapDispatchToProps = withDispatch({
  advanceTutorial,
  resetTutorial,
  setIsCompleted,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(TutorialOverlay)

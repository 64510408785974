/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, combine } from 'effector'

export function select<Enum>(
  states: [when: Store<any>, then: Enum][],
  fallback: Enum,
): Store<Enum> {
  return combine(
    states.map(([when]) => when),
    (conditions) => {
      const idx = conditions.findIndex(Boolean)
      return idx >= 0 ? states[idx][1] : fallback
    },
  )
}

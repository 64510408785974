import React from 'react'

import { useGate } from 'effector-react'

import Spinner from 'src/components/Spinner'
import useQueryString from 'src/utils/hooks/useQueryString'

import { EligibilityCheckGate } from '../model/core'

export function QuickEligibilityCheck() {
  const { parsed } = useQueryString()

  useGate(EligibilityCheckGate, { email: parsed.email as string })

  return <Spinner size="large" />
}

import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import {
  initialize,
  LDOptions,
  LDClient,
  LDContext,
} from 'launchdarkly-js-client-sdk'
import { condition } from 'patronum'

import config from 'src/config'
import getReleaseName from 'src/utils/sentryHelpers/getReleaseName'

const options: LDOptions = {
  fetchGoals: false,
  sendEvents: true,
  streaming: true,

  evaluationReasons: false,

  application: { id: 'member-web', version: getReleaseName() },
}

export const identify = createEvent<LDContext>()
export const ready = createEvent<void>()

export const $client = createStore<LDClient | null>(null)
export const $isClientReady = $client.map((client) => client !== null)

export const initializeFx = createEffect<LDContext, LDClient>()

const identifyFx = attach({
  source: $client,
  async effect(client, context: LDContext) {
    if (client) await client.identify(context)
  },
})

condition({
  source: identify,
  if: $isClientReady,
  then: identifyFx,
  else: initializeFx,
})

sample({
  clock: initializeFx.doneData,
  target: $client,
})

sample({
  clock: $isClientReady.updates,
  filter: Boolean,
  target: ready,
})

/** Using 4s timeout, which is lower than 5s recommended by LaunchDarkly team */
const LAUNCH_DARKLY_TIMEOUT_MS = 4_000

initializeFx.use(async (context) => {
  if (!config.LAUNCH_DARKLY_KEY)
    throw new Error('LaunchDarkly key is missing, feature flagging is disabled')

  const client = initialize(config.LAUNCH_DARKLY_KEY, context, options)

  await client.waitForInitialization(LAUNCH_DARKLY_TIMEOUT_MS)

  return client
})

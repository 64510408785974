import { useCallback, useEffect, useMemo } from 'react'

import { useMutation } from '@apollo/client'
import { useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'

import { $$auth } from '@shared/auth'

import { REFRESH_TOKENS_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { signOut } from 'src/apollo/utils/authHelpers'
import { ClientContext, RefreshMutationResponse } from 'src/apollo/utils/types'
import Spinner from 'src/components/Spinner'
import { ROUTES } from 'src/constants/routesConstants'
import { loggingLineCreated, logmask } from 'src/entities/logging'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { $$sessionStorage } from 'src/shared/storage/session'
import { NativeAlert } from 'src/shared/ui/alerts/NativeAlert'
import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { getGraphQlError } from 'src/utils/errorHelpers'
import useQueryString from 'src/utils/hooks/useQueryString'
import { StorageContainer, useLocalStorage } from 'src/utils/storage'

function TokenSignIn() {
  const { parsed } = useQueryString() as { parsed: Record<string, string> }

  const { setRedirect } = useLocalStorage(StorageContainer.persistent)

  const navigate = useNavigate()
  const { resetSession } = useUnit($$sessionStorage)
  const saveTokens = useUnit($$auth.token.save)

  const log = useUnit(loggingLineCreated)

  const {
    refresh_token: queryStringToken,
    relay_state,
    redirect_url: redirectUrl,
  } = parsed

  const [refresh, { error, client }] = useMutation<RefreshMutationResponse>(
    REFRESH_TOKENS_MUTATION,
    {
      errorPolicy: 'all',
      context: {
        isSkipError: true,
        headers: { 'X-Refresh-Token': queryStringToken },
      } satisfies ClientContext,
    },
  )

  const requestError = useMemo(
    () => (error ? getGraphQlError(error).graphQLErrors.message : null),
    [error],
  )

  const loginWithSSO = useCallback(async () => {
    await signOut(client, { isSsoLogin: true })

    const { data } = await refresh()

    if (!data || !data.refreshTokens) return

    const { access_token, refresh_token } = data.refreshTokens

    log({
      line: 'SSO login successfull',
      meta: { on: 'TokenSignIn' },
    })

    saveTokens({ access: access_token, refresh: refresh_token })
    resetSession()

    const dest_url = relay_state || `/${ROUTES.HOME}`
    void navigate(dest_url.toString(), { replace: true })
  }, [client, navigate, resetSession, refresh, relay_state, saveTokens, log])

  useEffect(() => {
    if (redirectUrl) setRedirect(redirectUrl.toString())
  }, [setRedirect, redirectUrl])

  useEffect(() => {
    log({
      line: 'SSO login attempted',
      meta: {
        on: 'TokenSignIn',
        token: logmask.jwt(queryStringToken?.toString()),
      },
    })

    void loginWithSSO()
  }, [loginWithSSO, log, queryStringToken])

  if (!requestError) return <Spinner />

  return <TokenErrors error={requestError} />
}

export function TokenErrors({ error }: { error: string }) {
  const t = useScopedTranslation('translation.auth.failed')

  const navigate = useNavigate()

  const returnToHome = useCallback(
    () => void navigate('/', { replace: true }),
    [navigate],
  )

  return (
    <NativeAlert description={error}>
      <NativeAlertAction mode="secondary" onClick={returnToHome}>
        {t('button')}
      </NativeAlertAction>
    </NativeAlert>
  )
}

export default TokenSignIn

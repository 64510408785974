import { TypedDocumentNode, gql } from '@apollo/client'

import { CHAT_MESSAGE_FRAGMENT } from '../chatMessage'

import { ChatMessagesResult, ChatMessagesVariables } from './types'

export const CHAT_SUBSCRIPTION: TypedDocumentNode<
  ChatMessagesResult,
  ChatMessagesVariables
> = gql`
  subscription chatMessages($id: ID!) {
    chat: chatMessagesEvents(chat_id: $id) {
      event

      message {
        ...chatMessage
      }
    }
  }

  ${CHAT_MESSAGE_FRAGMENT}
`

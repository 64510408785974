import { useMemo } from 'react'

import { useMatch } from '@reach/router'
import { useUnit } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import { ROUTES } from 'src/constants/routesConstants'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import { $$device } from 'src/shared/lib/device'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'
import { withDispatch } from 'src/store'
import {
  setIsShowGuidesPhoneNumberModal,
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface CallButtonViewProps extends ConnectedProps<typeof connector> {
  className?: string
}

function CallButtonView({
  className = undefined,

  dispatchShowPhonesModal,
  dispatchShowThirdSection,
  dispatchShowPhones,
}: CallButtonViewProps) {
  const { navigator: navigatorPhone } = useOrganizationPhoneQuery()

  const medicineCabinet = useMatch(ROUTES.MEDICATIONS_CABINET_PATH)
  const carePrograms = useMatch(ROUTES.MEDICATIONS_CARE_PATH)

  const isMobile = useUnit($$device.$mobile)

  const isMedicationsTabs = Boolean(medicineCabinet || carePrograms)

  const link = useMemo(
    /** The link can't be a null because navigatorPhone is always a valid number */
    () => formatPhone(navigatorPhone, PHONE_FORMATS.uri)!,
    [navigatorPhone],
  )

  const handleClick = () => {
    if (isMedicationsTabs && isMobile) {
      dispatchShowPhonesModal()
      return
    }

    if (isMobile) {
      window.location.assign(link)
      return
    }

    dispatchShowThirdSection()
    dispatchShowPhones()
  }

  return (
    <Button className={className} data-test="callButton" onClick={handleClick}>
      <Sprite name="misc/call" />
    </Button>
  )
}

const Button = styled(NavButton)`
  & > svg {
    width: ${convertPxToRem(22)};
    height: ${convertPxToRem(22)};
  }
`

const mapDispatchToProps = withDispatch({
  showPhonesModal: () => setIsShowGuidesPhoneNumberModal(true),

  showThirdSection: () => setIsShowThirdSectionModal(true),
  showPhones: () => setThirdSectionContent(THIRD_SECTION_CONTENT.SELECT_PHONE),
})

const connector = connect(null, mapDispatchToProps)

export const CallButton = connector(CallButtonView)

import { createEvent, sample } from 'effector'

import { $$client } from '@shared/api/client'
import { $$auth } from '@shared/auth'
import { $$device } from '@shared/lib/device'
import { $$router } from '@shared/lib/router'
import { $$unstable_router } from '@shared/router'

import { $$features } from '@entities/features'

import { $$deeplink } from '@features/deeplink'
import { $$security } from '@features/security'

import client from 'src/apollo/client'

import { router } from '../router'

export const appStarted = createEvent<void>()

sample({
  clock: appStarted,
  fn: () => router,
  target: $$unstable_router.setup,
})

sample({
  clock: appStarted,
  // TODO: switch to DI through Fork API for Scope usage
  fn: () => client,
  target: $$client.$instance,
})

// Shared Modules
sample({
  clock: appStarted,
  target: [$$device.setup, $$auth.setup, $$router.setup],
})

// Entities
sample({
  clock: appStarted,
  target: $$features.setup,
})

// Features
sample({
  clock: appStarted,
  target: [$$deeplink.setup, $$security.setup],
})

import { useState, useCallback } from 'react'

import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { AppointmentCancellationAlert } from './AppointmentCancellationAlert'

interface AppointmentCancelContextMenuProps {
  onCancel: () => void
}

export function AppointmentCancelContextMenu({
  onCancel,
}: AppointmentCancelContextMenuProps) {
  const [isModalOpen, setModalOpen] = useState(false)

  const handlePopupClick = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleModalClosure = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleCancel = useCallback(() => {
    setModalOpen(false)

    onCancel()
  }, [onCancel])

  return (
    <>
      <CloseButton data-test="close" onClick={handlePopupClick} />

      {isModalOpen && (
        <AppointmentCancellationAlert
          onCancel={handleCancel}
          onDismiss={handleModalClosure}
        />
      )}
    </>
  )
}

const CloseButton = styled(Sprite).attrs({ name: 'nav/close' })`
  cursor: pointer;

  color: ${secondaryGrey};

  grid-row: 1 / 2;
  grid-column: 2 / 3;

  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
`

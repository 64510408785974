import React from 'react'

import styled from 'styled-components'

import { secondaryGrey, secondaryLightGrey } from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ChatWidgetProps {
  description: string
  children: React.ReactNode | React.ReactNode[]
}

export function ChatWidget({ description, children }: ChatWidgetProps) {
  return (
    <Section>
      <Description>{description}</Description>
      <Buttons>{children}</Buttons>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxToRem(16)};
  padding: ${convertPxToRem(16)};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
`

const Description = styled.span`
  display: flex;
  ${text2_14};
  color: ${secondaryGrey};
`

const Buttons = styled.div`
  display: flex;
`

import styled from 'styled-components'

import navigatorImage from 'src/assets/images/navigator@2x.png'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function EmptyInboxIcon() {
  return <Image src={navigatorImage} alt="" draggable={false} />
}

const Image = styled.img`
  width: ${convertPxToRem(100)};
  height: ${convertPxToRem(190)};

  user-select: none;
`

export default EmptyInboxIcon

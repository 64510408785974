import React from 'react'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface StepIndicatorCircleIconProps {
  width?: string
  height?: string
  className?: string
}

export function StepIndicatorCircleIcon({
  width = convertPxToRem(14),
  height = convertPxToRem(14),
  className = undefined,
}: StepIndicatorCircleIconProps) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6" strokeWidth="2" />
    </svg>
  )
}

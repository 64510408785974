import { useRef, useCallback, KeyboardEvent, memo } from 'react'

import styled from 'styled-components'

import { isMobilePlatform } from 'src/components/AttachComponents/uploadHelpers'
import Spinner from 'src/components/Spinner'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import { FocusWithin } from 'src/theme/baseStyles'
import {
  primaryBlue,
  primaryWhite,
  secondaryLightGrey,
  secondaryMidGrey,
} from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useConsumePreset } from '../../lib/useConsumePreset'

interface ReplyInputProps {
  submit: (payload: { text: string }) => void

  isLoading?: boolean
}

function ReplyInputComponent({ submit, isLoading = false }: ReplyInputProps) {
  const t = useScopedTranslation('translation.chat.thread.input')

  const ref = useRef<HTMLElement>(null)

  useConsumePreset(ref)

  const onSubmit = useCallback(() => {
    if (!ref.current) return

    const text = ref.current.innerText.trim()
    ref.current.innerText = ''

    if (!text) return

    submit({ text })
  }, [submit])

  const onKeyboard = useCallback(
    (event: KeyboardEvent) => {
      // allow Shift+Enter to send a message on Desktop only
      if (event.key === 'Enter' && event.shiftKey && !isMobilePlatform) {
        event.preventDefault()
        onSubmit()
      }
    },
    [onSubmit],
  )

  return (
    <Wrapper data-test="replyInput">
      <Input
        ref={ref}
        onKeyDown={onKeyboard}
        role="textbox"
        contentEditable={isLoading ? false : 'plaintext-only'}
        data-placeholder={t('input_placeholder')} /* text used in ::before */
        aria-disabled={isLoading}
      />

      <SendButton onClick={onSubmit} data-loading={isLoading} role="button">
        {isLoading ? <SendSpinner /> : <Sprite name="misc/send" />}
      </SendButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: end;

  flex: 1 1 0%;

  gap: ${convertPxToRem(8)};
  padding: ${convertPxToRem(4, 4, 4, 16)};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(20)};

  background-color: ${primaryWhite};

  ${FocusWithin};
`

const Input = styled.span`
  ${text1_16};

  flex-grow: 1;

  resize: none;
  overflow-y: auto;
  background: transparent;

  word-break: break-word;

  --line: ${convertPxToRem(22)};

  line-height: var(--line);
  margin: ${convertPxToRem(5, 0)};

  min-height: var(--line);
  max-height: calc(var(--line) * 3); /* three lines max */

  &:empty::before {
    /* if Input is empty, display a placeholder */
    /* use placeholder text (after i18n) */
    content: attr(data-placeholder);
    color: ${secondaryMidGrey};
  }
`

const SendSpinner = styled(Spinner).attrs({ size: 'small' })`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(20)};
`

const SendButton = styled.div`
  width: ${convertPxToRem(32)};
  height: ${convertPxToRem(32)};

  padding: ${convertPxToRem(7, 8, 7, 6)};
  border-radius: 50%;

  color: ${primaryWhite};
  background-color: ${primaryBlue};

  cursor: pointer;

  &[data-loading='true'] {
    padding: ${convertPxToRem(6)};
  }

  & > svg {
    width: ${convertPxToRem(18)};
    height: ${convertPxToRem(18)};
  }
`

export const ReplyInput = memo(ReplyInputComponent)

import { TypedDocumentNode, gql } from '@apollo/client'

import { CHAT_DETAILS_FRAGMENT } from '../chatDetails'
import { CHAT_MESSAGE_FRAGMENT } from '../chatMessage'

import { ChatResult, ChatVariables, ChatMessagesUpdate } from './types'

export const CHAT_QUERY: TypedDocumentNode<ChatResult, ChatVariables> = gql`
  query chat($id: ID!, $cursor: String) {
    chat(id: $id) {
      ...chatDetails

      messages(first: 10, after: $cursor) {
        nodes {
          ...chatMessage
        }

        pageInfo {
          hasNextPage
          cursor: endCursor
        }
      }
    }
  }

  ${CHAT_DETAILS_FRAGMENT}
  ${CHAT_MESSAGE_FRAGMENT}
`

export const CHAT_MESSAGES_UPDATE_FRAGMENT: TypedDocumentNode<
  ChatMessagesUpdate,
  Record<string, never>
> = gql`
  fragment chatMessagesUpdate on Chat {
    messages {
      nodes {
        id
      }
    }
  }
`

import { reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { Sprite } from 'src/shared/ui/Sprite'
import {
  featurePaleGreen,
  featurePaleRed,
  primaryWhite,
  secondaryGrey,
  statusActive,
  statusErrorRed,
} from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $popup, popupMessageHidden, PopupType } from '../model/core'

export function PopupMessage() {
  const popup = useUnit($popup)

  return (
    <Root data-type={popup?.type}>
      {popup?.type && IconMap[popup.type]}

      {popup?.content}

      <CloseIcon />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;

  padding: ${convertPxToRem(16)};
  gap: ${convertPxToRem(12)};
  border-radius: ${convertPxToRem(6)};

  box-shadow: ${convertPxToRem(0, 5, 16, 0)} rgba(206, 206, 206, 0.5);

  & > svg {
    width: ${convertPxToRem(20)};
    height: ${convertPxToRem(20)};
  }

  &[data-type='success'] {
    background-color: ${featurePaleGreen};
  }

  &[data-type='error'] {
    background-color: ${featurePaleRed};
  }
`

const CloseIconComponent = styled(Sprite).attrs({ name: 'nav/close' })`
  flex-shrink: 0;
  margin-left: auto;

  color: ${secondaryGrey};
  height: ${convertPxToRem(20)};
  width: ${convertPxToRem(20)};

  cursor: pointer;
`

const CloseIcon = reflect({
  view: CloseIconComponent,
  bind: {
    onClick: popupMessageHidden,
  },
})

const CheckMark = styled(Sprite).attrs({ name: 'misc/check' })`
  flex-shrink: 0;

  box-sizing: border-box;

  padding: ${convertPxToRem(4)};

  border-radius: 50%;

  background-color: ${statusActive};
  color: ${primaryWhite};
`

const ErrorIcon = styled(Sprite).attrs({
  name: 'misc/exclamation_mark',
})`
  color: ${statusErrorRed};

  flex-shrink: 0;
`

const IconMap: Record<PopupType, React.ReactElement> = {
  success: <CheckMark />,
  error: <ErrorIcon />,
}

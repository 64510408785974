import { HTMLAttributes, ReactNode } from 'react'

import styled from 'styled-components'

import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonLinkProps extends HTMLAttributes<HTMLButtonElement> {
  before?: ReactNode
  after?: ReactNode

  children: ReactNode | ReactNode[]
}

export function ButtonLink({
  after = null,
  before = null,

  children,

  ...rest
}: ButtonLinkProps) {
  return (
    <Button {...rest}>
      {before}

      <Content>{children}</Content>

      {after}
    </Button>
  )
}

const Content = styled.span`
  ${text2_14};

  flex: 1 1 max-content;

  text-align: left;
  user-select: none;
`

const Button = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: ${convertPxToRem(8)};

  padding: ${convertPxToRem(10, 18)};

  background: ${primaryWhite};
  color: ${secondaryGrey};

  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(42)};

  & > svg {
    flex: 0 0 ${convertPxToRem(16)};

    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};
  }
`

import { useContext } from 'react'

import { useUnit } from 'effector-react'

import { $$chat, NEW_THREAD_ID } from 'src/entities/chat'
import { NavButton } from 'src/shared/ui/icons/NavButton'
import { Sprite } from 'src/shared/ui/Sprite'

import { ThreadIdContext } from '../../lib/ThreadIdContext'

export function ChatCloseButton() {
  const id = useContext(ThreadIdContext)!

  const close = useUnit($$chat.close)

  const name = id === NEW_THREAD_ID ? 'nav/close' : 'nav/back'

  return (
    <NavButton data-test="chatHeader.close" onClick={close}>
      <Sprite name={name} />
    </NavButton>
  )
}

import { invoke } from '@withease/factories'
import { sample } from 'effector'

import { $$auth } from '@shared/auth'

import { chainStep } from '../lib/step'

import {
  resetServicesStep,
  resetStorageStep,
  revokeTokenStep,
  stopWorldStep,
} from './steps'

const signOutChain = invoke(chainStep, [
  revokeTokenStep,
  stopWorldStep,
  resetServicesStep,
  resetStorageStep,
])

sample({
  clock: $$auth.signOut.start,
  target: signOutChain.start,
})

sample({
  clock: signOutChain.done,
  target: $$auth.signOut.completed,
})

import { MouseEventHandler } from 'react'

import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { $$drugSearchConfigurationStorage } from 'src/shared/storage/drugSearch'
import { Sprite } from 'src/shared/ui/Sprite'
import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { drugSearchRecentConfigSelected } from '../../model/search'

interface RecentSearchItemProps {
  name: string
}

export function RecentSearchItem({ name }: RecentSearchItemProps) {
  const [selectDrug, removeConfig] = useUnit([
    drugSearchRecentConfigSelected,
    $$drugSearchConfigurationStorage.configRemoved,
  ])

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
    selectDrug(name)
  }

  const handleClear: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
    removeConfig(name)
  }

  return (
    <Root onClick={handleClick} data-test="recentSearchItem">
      <Title>{name}</Title>

      <ButtonClear
        onClick={handleClear}
        data-test="recentSearchItem.clearButton"
      >
        <Sprite name="misc/xmark" />
      </ButtonClear>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`
const Title = styled.span`
  display: flex;
  color: ${primaryDarkNavy};
  ${text1_16};
`

// ToDo: Get rid of the duplicate wrappers (NEX-17802)
const ButtonClear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${convertPxToRem(40)};
  width: ${convertPxToRem(40)};

  cursor: pointer;

  & > svg {
    width: ${convertPxToRem(16)};
    height: ${convertPxToRem(16)};

    flex-shrink: 0;

    color: ${secondaryGrey};

    user-select: none;
  }
`

import { gql } from '@apollo/client'

const BRANDING_QUERY = gql`
  query branding($slug: String) {
    branding(slug: $slug) {
      carousel_items {
        image_url
        title
        description
      }
      slug

      logo_svg_url
      logo_sidebar_svg_url

      powered_by_text
      title
      favicon_url
      welcome_text
    }
  }
`

export default BRANDING_QUERY

import React from 'react'

import styled from 'styled-components'

import TooltipIcon from 'src/components/SvgIcons/TooltipIcon'
import { ServiceAgreement } from 'src/entities/termsOfServices'
import { TermsOfServiceAgreementKindType } from 'src/shared/api/types'
import { ButtonNavigateTo16 } from 'src/shared/ui/buttons/ButtonNavigateTo16'
import {
  primaryWhite,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { text2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { TermsExtraInfo } from './TermsExtraInfo'

interface TermsAgreementProps {
  agreement: ServiceAgreement
  setModalData: (value: string) => void
}

export function TermsAgreement({
  agreement,
  setModalData,
}: TermsAgreementProps) {
  const { id, kind, displayName, url } = agreement

  const onTermsOpen = () => {
    switch (kind) {
      case TermsOfServiceAgreementKindType.Modal:
        setModalData(id)
        break
      case TermsOfServiceAgreementKindType.Href:
        if (url) window.open(url, '_blank', 'noopener')
        break

      default:
        break
    }
  }

  if (kind === TermsOfServiceAgreementKindType.Inline) {
    return <TermsExtraInfo key={id} agreement={agreement} />
  }

  return (
    <TermsLinkWrapper data-test="termsLink" onClick={onTermsOpen}>
      <QuestionWrapper>
        <TooltipIcon
          fillCircle={primaryWhite}
          fill={secondaryGrey}
          width={convertPxToRem(20)}
          height={convertPxToRem(20)}
        />
      </QuestionWrapper>

      <TermsLinkTitle>{displayName}</TermsLinkTitle>

      <Arrow>
        <ButtonNavigateTo16 fill={secondaryGrey} />
      </Arrow>
    </TermsLinkWrapper>
  )
}

const TermsLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${convertPxToRem(20)};
  padding: ${convertPxToRem(10, 13, 10, 18)};
  border-radius: ${convertPxToRem(32)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  position: relative;

  :last-child {
    margin-bottom: 0;
  }
`

const QuestionWrapper = styled.div`
  flex-shrink: 0;
`

const TermsLinkTitle = styled.span`
  padding-left: ${convertPxToRem(13)};
  color: ${secondaryGrey};
  ${text2_14};
  flex-grow: 1;
`

const Arrow = styled.div`
  flex-shrink: 0;
`

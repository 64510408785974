export enum ConfigType {
  DaysSupply = 'daysSupply',
  DaysSupplyCustom = 'daysSupplyCustom',
  Form = 'form',
  Dosage = 'dosage',
  TypeOfQuantity = 'typeOfQuantity',
  Quantity = 'quantity',
}

export type SavedConfig = Record<ConfigType, string>

export interface ConfigChangedEvent {
  type: ConfigType
  value: string
}

export enum ErrorKeys {
  Min = 'errors.min_days_supply',
  Max = 'errors.max_days_supply',
  Required = 'errors.required',
}

export type ValidationErrors = Record<ConfigType, ErrorKeys | null>

import { useCallback } from 'react'

import { useUnit } from 'effector-react'

import {
  addDoctorErrorPopupShown,
  addDoctorSuccessPopupShown,
  removeDoctorSuccessPopupShown,
  removeDoctorErrorPopupShown,
  useToggleDoctorCareTeamMutations,
} from 'src/entities/careTeam'

interface UseDoctorCareTeamToggleArgs {
  id: string
  isFavorite: boolean
}

export function useDoctorCareTeamToggle({
  id,
  isFavorite,
}: UseDoctorCareTeamToggleArgs) {
  const { toggle: doctorToggle, isLoading } = useToggleDoctorCareTeamMutations(
    id,
    isFavorite,
  )

  const [
    showDoctorAddedPopup,
    showDoctorAdditionError,
    showDoctorRemovedPopup,
    showDoctorRemovalError,
  ] = useUnit([
    addDoctorSuccessPopupShown,
    addDoctorErrorPopupShown,
    removeDoctorSuccessPopupShown,
    removeDoctorErrorPopupShown,
  ])

  const toggle = useCallback(
    () =>
      doctorToggle()
        .then(() =>
          isFavorite ? showDoctorRemovedPopup() : showDoctorAddedPopup(),
        )
        .catch(() =>
          isFavorite ? showDoctorRemovalError() : showDoctorAdditionError(),
        ),

    [
      doctorToggle,
      isFavorite,
      showDoctorAddedPopup,
      showDoctorAdditionError,
      showDoctorRemovalError,
      showDoctorRemovedPopup,
    ],
  )

  return {
    toggle,
    isLoading,
  }
}

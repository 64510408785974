import { variant } from '@effector/reflect'
import styled from 'styled-components'

import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { $isOpened } from '../model/core'

import { PopupMessage } from './PopupMessage'

interface PopupModalProps {
  className?: string
}

function PopupModalComponent({ className = undefined }: PopupModalProps) {
  return (
    <Modal className={className}>
      <PopupMessage />
    </Modal>
  )
}

export const PopupModal = variant({
  if: $isOpened,
  then: PopupModalComponent,
})

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  left: ${convertPxToRem(40)};
  right: ${convertPxToRem(40)};
  bottom: ${convertPxToRem(40)};

  z-index: 2;

  ${mediaQueryFor.mobile} {
    left: ${convertPxToRem(24)};
    right: ${convertPxToRem(24)};
    bottom: ${convertPxToRem(24)};
  }
`

import React from 'react'

interface GeoTagIconProps {
  className?: string
}

export function GeoTagIcon({ className = undefined }: GeoTagIconProps) {
  return (
    <svg
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.5131 13.4495C27.5131 23.8986 15.381 33.576 15.381 33.576C14.6721 34.1414 13.5119 34.1414 12.803 33.576C12.803 33.576 0.670898 23.8986 0.670898 13.4495C0.671033 6.24243 6.67984 0.400024 14.0921 0.400024C21.5043 0.400024 27.5131 6.24243 27.5131 13.4495Z"
        fill="white"
      />
    </svg>
  )
}

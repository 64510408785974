import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { SIGN_OUT_MUTATION } from 'src/apollo/mutations/AUTH_MUTATIONS'
import { signOut } from 'src/apollo/utils/authHelpers'

const useSignOutMutation = ({ preventLocationReplace, resetRedux } = {}) => {
  const [signOutMutation, { loading, client }] = useMutation(
    SIGN_OUT_MUTATION,
    { context: { isSkipError: true } },
  )

  const handleSignOut = useCallback(async () => {
    try {
      await signOutMutation()
    } finally {
      await signOut(client, { preventLocationReplace, resetRedux })
    }
  }, [signOutMutation, client, preventLocationReplace, resetRedux])

  return { handleSignOut, loading }
}

export default useSignOutMutation

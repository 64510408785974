import React, { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { signOut } from 'src/apollo/utils/authHelpers'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  primaryDarkNavy,
  primaryWhite,
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import { heading2_29, text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function CheckGeolocation() {
  const { t } = useTranslation()
  const client = useApolloClient()

  useEffect(() => {
    signOut(client, { preventLocationReplace: true })
  }, [client])

  const i18n = {
    usResidentsQuestion: t('translation.auth.signin.us_residents'),
    description: t('translation.auth.signin.description'),
    contact: t('translation.auth.signin.contact'),
  }

  return (
    <Root>
      <Wrapper>
        <Content>
          <LogoIconContainer>
            <LogoIcon />
          </LogoIconContainer>
          <Title>{i18n.usResidentsQuestion}</Title>
          <Description>
            <Trans
              i18nKey={i18n.description}
              values={{
                email: i18n.contact,
              }}
              components={{
                emailLink: <ContactLink href={`mailto:${i18n.contact}`} />,
              }}
            />
          </Description>
        </Content>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${secondaryBackgroundGrey};
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${convertPxToRem(44)};
  background: ${primaryWhite};
  width: ${convertPxToRem(500)};
  height: ${convertPxToRem(666)};
  ${mediaQueryFor.mobile} {
    height: 100%;
    width: 100%;
    border-radius: none;
  }
`

const Content = styled.div`
  width: ${convertPxToRem(284)};
  padding-bottom: ${convertPxToRem(160)};
  text-align: center;
`

const LogoIconContainer = styled.div`
  margin-bottom: ${convertPxToRem(60)};
`

const LogoIcon = styled(Sprite).attrs({ name: 'misc/rightway' })`
  width: ${convertPxToRem(100)};
  height: ${convertPxToRem(100)};
  color: ${primaryBlue};
`

const Title = styled.h2`
  ${heading2_29};
  color: ${primaryDarkNavy};
  margin-bottom: ${convertPxToRem(16)};
`

const Description = styled.p`
  ${text1_16};
  color: ${secondaryGrey};
`

const ContactLink = styled.a`
  ${text1_16};
  color: ${primaryBlue};
`

export default CheckGeolocation as React.FC<RouteComponentProps>

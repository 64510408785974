import React from 'react'

import PropTypes from 'prop-types'

import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function Calendar({
  width = convertPxToRem(15),
  height = convertPxToRem(16),
  fill = secondaryGrey,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08711 12.7531C6.82148 13.0719 6.34648 13.0719 6.05273 12.7531L4.05273 10.7531C3.76211 10.4875 3.76211 10.0125 4.05273 9.71875C4.34648 9.42813 4.82148 9.42813 5.08711 9.71875L6.58398 11.1906L9.55273 8.21875C9.84648 7.92812 10.3215 7.92812 10.5871 8.21875C10.9059 8.5125 10.9059 8.9875 10.5871 9.25313L7.08711 12.7531ZM4.33398 0C4.74961 0 5.08398 0.335938 5.08398 0.75V2H9.58398V0.75C9.58398 0.335938 9.91836 0 10.334 0C10.7496 0 11.084 0.335938 11.084 0.75V2H12.334C13.4371 2 14.334 2.89531 14.334 4V14C14.334 15.1031 13.4371 16 12.334 16H2.33398C1.2293 16 0.333984 15.1031 0.333984 14V4C0.333984 2.89531 1.2293 2 2.33398 2H3.58398V0.75C3.58398 0.335938 3.91836 0 4.33398 0ZM12.834 6H1.83398V14C1.83398 14.275 2.05773 14.5 2.33398 14.5H12.334C12.609 14.5 12.834 14.275 12.834 14V6Z"
        fill={fill}
      />
    </svg>
  )
}

Calendar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default Calendar

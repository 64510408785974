import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { useHasChatsQuery } from 'src/entities/inbox'

import { BlankInbox } from './Blank'
import { ChatsInbox } from './Chats'

export function Inbox() {
  const { hasChats, isLoading } = useHasChatsQuery()

  if (isLoading) return <Spinner />

  return (
    <Root data-test="chatsInbox">
      {hasChats ? <ChatsInbox /> : <BlankInbox />}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;

  height: 100%;
`

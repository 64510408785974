import React from 'react'

import { IconProps } from 'src/components/SvgIcons/types'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function BillingAdvocacyIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.1106 13.4167V13.4166C15.1161 11.774 14.1147 10.2958 12.5873 9.69165L8.33726 7.99248C7.56176 7.68204 7.18476 6.8017 7.49521 6.0262C7.72506 5.45204 8.28131 5.07566 8.89977 5.07582H13.0856C13.776 5.07582 14.3356 4.51617 14.3356 3.82582C14.3356 3.13546 13.776 2.57582 13.0856 2.57582H11.4581C11.3431 2.57582 11.2498 2.48254 11.2498 2.36748V1.25C11.2498 0.559644 10.6902 0 9.99981 0C9.30946 0 8.74981 0.559644 8.74981 1.25V2.3925V2.3925C8.75023 2.50076 8.66766 2.59131 8.55981 2.60083C6.35775 2.77745 4.7158 4.70575 4.89241 6.90782C5.01459 8.4312 5.99411 9.75183 7.41648 10.3108L11.6665 12.01C12.442 12.3204 12.819 13.2008 12.5085 13.9763C12.2787 14.5504 11.7224 14.9268 11.104 14.9267H6.91148C6.22112 14.9267 5.66148 15.4863 5.66148 16.1767C5.66148 16.867 6.22112 17.4267 6.91148 17.4267H8.54148C8.65654 17.4267 8.74981 17.5199 8.74981 17.635V18.75C8.74981 19.4404 9.30946 20 9.99981 20C10.6902 20 11.2498 19.4404 11.2498 18.75V17.6075V17.6075C11.2494 17.4992 11.332 17.4087 11.4398 17.3992C13.5109 17.2242 15.1046 15.4951 15.1106 13.4167V13.4167Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default BillingAdvocacyIcon

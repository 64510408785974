import React from 'react'

import { useTranslation } from 'react-i18next'

import { NativeAlertAction } from 'src/shared/ui/alerts/NativeAlertAction'
import { NativeInlineAlert } from 'src/shared/ui/alerts/NativeInlineAlert'

interface AppointmentSlotRemovalAlertProps {
  onClose: () => void
  onDelete: () => void
}

export function AppointmentSlotRemovalAlert({
  onClose,
  onDelete,
}: AppointmentSlotRemovalAlertProps) {
  const { t } = useTranslation()

  const i18n = {
    title: t('translation.appointments.choice_card.delete.title'),
    description: t('translation.appointments.choice_card.delete.description'),

    cancel: t('translation.shared.buttons.button_cancel'),
    delete: t('translation.shared.buttons.delete'),
  }

  return (
    <NativeInlineAlert title={i18n.title} description={i18n.description}>
      <NativeAlertAction mode="danger" onClick={onDelete}>
        {i18n.delete}
      </NativeAlertAction>
      <NativeAlertAction mode="primary" onClick={onClose}>
        {i18n.cancel}
      </NativeAlertAction>
    </NativeInlineAlert>
  )
}

import { Link } from '@reach/router'
import styled, { css } from 'styled-components'

import dashedBorder from 'src/assets/icons/dashed_border.svg'
import {
  ModalBase,
  ContentsRootBase,
  KebabBoxShadow,
  FocusDefault,
  FooterPaddingH40,
} from 'src/theme/baseStyles'
import {
  secondaryLightGrey,
  primaryWhite,
  primaryLightBlue,
  primaryDarkNavy,
  secondaryMidGrey,
  secondaryBackgroundGrey,
  primaryBlue,
  secondaryGrey,
} from 'src/theme/colors'
import {
  text1_16,
  heading6_16_Medium,
  heading2_29,
  text2_14,
  text3_12,
  heading1_20,
  heading7_14,
  capsHeading1_12_Medium,
  textLink2_14,
  text4_10,
  capsHeading1_12,
} from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export const Root = styled.div`
  ${ContentsRootBase};

  padding: ${convertPxToRem(40)};
  height: calc(80 * var(--vh));

  ${mediaQueryFor.tabletOrLess} {
    padding-top: ${convertPxToRem(100)};
  }

  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(180, 16, 150)};
  }
`

export const CoverageWrapper = styled.div`
  overflow-y: auto;
`

interface StyledProps {
  paddingTop: string
  paddingBottom: string
  marginBottom: string
}

export const Wrapper = styled.div<StyledProps>`
  padding: ${({ paddingTop, paddingBottom }) =>
    ` ${paddingTop || convertPxToRem(26)}
    ${convertPxToRem(24)}
    ${paddingBottom || '0'}
    `};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  background-color: ${primaryWhite};
  margin-bottom: ${(props) => props.marginBottom || 'unset'};
  &:last-child {
    margin-bottom: 0;
  }
`

export const WrapperForPlan = styled.div`
  margin-top: ${convertPxToRem(16)};
`

export const PlanItemIconWrapper = styled.div`
  cursor: pointer;
  margin-left: ${convertPxToRem(8)};
  display: flex;
`

export const PlanBlankTitle = styled.div`
  ${heading1_20};
  color: ${primaryDarkNavy};
  text-align: left;
`
export const PlanBlankMessage = styled.div`
  ${heading7_14};
  margin-top: ${convertPxToRem(8)};
  color: ${secondaryGrey};
`
export const PlanBlankButton = styled.div`
  ${heading6_16_Medium};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${convertPxToRem(24)} auto;
  height: ${convertPxToRem(48)};
  font-weight: 500;
  color: ${primaryWhite};
  border-radius: ${convertPxToRem(36)};
  background-color: ${primaryBlue};
  cursor: pointer;
  text-align: center;
`
export const CardTitleWrapper = styled.ul``
export const CardTitle = styled.li`
  display: flex;
  ${capsHeading1_12};
  text-transform: uppercase;
  margin: ${convertPxToRem(0, 0, 16, 16)};
  color: ${primaryDarkNavy};
`

// MEDICAL CARD
export const MedicalCardWrapper = styled.div`
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
  padding: ${convertPxToRem(16, 16, 20, 20)};
  position: relative;
  background: ${primaryWhite};
`
export const MedicalCardLogo = styled.img``
export const MedicalCardName = styled.h2`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
`
// INSURANCE CARD
export const InsuranceCardWrapper = styled.div``
export const InsuranceCardHeaderWrapper = styled.div`
  height: ${convertPxToRem(50)};
  display: flex;
  justify-content: space-evenly;
  margin: ${convertPxToRem(0, -24)};
  padding: ${convertPxToRem(0, 24)};
  border-bottom: ${convertPxToRem(1)} solid ${secondaryLightGrey};
`
export const InsuranceCardPhotoLoadingWrapper = styled.div`
  height: ${convertPxToRem(186)};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InsuranceCardPhotoWrapper = styled.div`
  margin-top: ${convertPxToRem(24)};
`
export const InsuranceCardPhotoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${convertPxToRem(600)};
  max-height: ${convertPxToRem(600)};
  margin: 0 auto ${convertPxToRem(25)} auto;
  overflow: ${({ overflow }) => overflow || 'unset'};
  ${mediaQueryFor.mobile} {
    flex-wrap: wrap;
  }
`

export const InsuranceCardPhotoNoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${convertPxToRem(186)};
  background-image: url(${dashedBorder});
  border-radius: ${convertPxToRem(11)};
  flex-direction: column;
  position: relative;
`
export const InsuranceCardPhotoNoImageText = styled.div`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  margin-top: ${convertPxToRem(13)};
`

const InsuranceCardHeaderItemActive = css`
  ${heading6_16_Medium};
  color: ${primaryDarkNavy};
  border-bottom: ${convertPxToRem(2)} solid ${primaryBlue};
`

export const InsuranceCardHeaderItem = styled.div<{ active: boolean }>`
  width: 25%;
  max-width: ${convertPxToRem(179)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${secondaryGrey};
  cursor: pointer;
  border-bottom: ${convertPxToRem(2)} solid transparent;
  margin-bottom: ${convertPxToRem(-1)};
  ${text1_16};
  ${({ active }) => active && InsuranceCardHeaderItemActive}
`
// VIEW EDIT INSURANCE PHOTO
export const ViewEditWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  height: calc(100 * var(--vh));
  overflow-y: auto;
  background: ${secondaryBackgroundGrey};
  width: 100%;
  z-index: 1;
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(21, 24, 40)};
  }
  ${mediaQueryFor.desktop} {
    padding: ${convertPxToRem(40, 40, 36)};
  }
`
export const ViewEditWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  ${mediaQueryFor.tablet} {
    padding: ${convertPxToRem(24, 40, 43)};
  }
  ${mediaQueryFor.desktop} {
    padding: ${convertPxToRem(40, 40, 36)};
    background: ${primaryWhite};
    box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);
    border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
    border-radius: ${convertPxToRem(8)};
  }
`
export const ViewEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${convertPxToRem(25)};
`
export const ButtonKebabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  justify-content: center;
  border-radius: 50%;
  background: ${primaryWhite};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
`
export const KebabModal = styled.div`
  position: absolute;
  top: ${convertPxToRem(65)};
  right: ${convertPxToRem(16)};
  width: ${convertPxToRem(196)};
  background-color: ${primaryWhite};
  border-radius: ${convertPxToRem(8)};
  ${KebabBoxShadow};
  ${mediaQueryFor.desktop} {
    top: ${convertPxToRem(127)};
    right: ${convertPxToRem(60)};
  }
`
export const KebabItemTitle = styled.div`
  padding: ${convertPxToRem(11, 16)};
  color: ${({ color }) => color};
  cursor: pointer;
  ${textLink2_14};
`

export const MedicalCardEdit = styled(Link)`
  position: absolute;
  top: ${convertPxToRem(16)};
  right: ${convertPxToRem(16)};
`
export const MedicalCardFullName = styled.h2`
  margin-top: ${convertPxToRem(16)};
`
export const MedicalCardMemberInformation = styled.div`
  display: flex;
  margin-top: ${convertPxToRem(16)};
`
export const MedicalCardMemberInformationItem = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: ${convertPxToRem(56)};
  }
`
export const MedicalCardMemberInformationName = styled.div`
  ${text4_10};
  color: ${secondaryGrey};
`
export const MedicalCardMemberInformationValue = styled.div`
  ${text2_14};
  color: ${primaryDarkNavy};
  margin-top: ${convertPxToRem(5)};
`
// MY PLAN
export const MyPlanWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  height: calc(100 * var(--vh));
  overflow: hidden;
  width: 100%;
  background: ${secondaryBackgroundGrey};
`
export const MyPlanRoot = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  height: calc(100 * var(--vh));
  background-color: ${primaryWhite};

  ${mediaQueryFor.desktop} {
    margin: ${convertPxToRem(40)};

    box-shadow: ${convertPxToRem(0, 4, 26, 0)} rgba(187, 187, 187, 0.15);

    border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
    border-radius: ${convertPxToRem(8)};
  }

  ${mediaQueryFor.mobile} {
    background-color: ${secondaryBackgroundGrey};
  }
`

// EDIT INSURANCE
export const EditInsuranceWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${primaryWhite};
  height: 100%;
  padding: 26px 24px 0;
  ${mediaQueryFor.desktop} {
    padding: 40px 40px 79px;
  }
  ${mediaQueryFor.tablet} {
    padding: 40px 40px 79px;
  }
`
export const EditInsuranceHeader = styled.h1`
  ${heading2_29};
  margin-top: 46px;
  font-weight: 600;
  color: ${primaryDarkNavy};
  ${mediaQueryFor.desktop} {
    margin-top: 26px;
  }
`
export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const EditInsuranceItem = styled.div`
  margin-top: 32px;
  color: ${primaryDarkNavy};
`
export const EditInsuranceItemLabel = styled.div`
  ${text3_12};
  margin-bottom: ${convertPxToRem(4)};
`
export const EditInsuranceItemInput = styled.input`
  height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(16, 14)};
  width: 100%;
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  border-radius: ${convertPxToRem(8)};
  color: ${primaryDarkNavy};
  ${text1_16};
  ${FocusDefault};
  ::-webkit-input-placeholder {
    color: ${secondaryMidGrey};
  }
  ::-moz-placeholder {
    color: ${secondaryMidGrey};
  }
  :-ms-input-placeholder {
    color: ${secondaryMidGrey};
  }
`
export const EditInsuranceButtonWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(45)};
  left: 0;
  right: 0;
  ${FooterPaddingH40};
  ${mediaQueryFor.mobile} {
    padding: ${convertPxToRem(0, 24)};
  }
`

// Tooltip modal
export const TooltipModalRoot = styled.div`
  ${ModalBase};
  position: fixed;
  background: rgba(46, 49, 65, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TooltipModalWrapper = styled.div`
  background: ${primaryWhite};
  padding: ${convertPxToRem(24, 24, 21, 22)};
  border-radius: ${convertPxToRem(20)};
  box-shadow: ${convertPxToRem(0, 4, 16, 0)} rgba(206, 206, 206, 0.5);
  max-width: ${convertPxToRem(500)};
  width: 100%;
`
export const TooltipModalWrapperTitle = styled.div`
  ${heading1_20};
  display: flex;
  justify-content: center;
  margin: ${convertPxToRem(20, 0, 16)};
`
export const TooltipModalWrapperDescription = styled.div`
  ${text1_16};
  margin-bottom: ${convertPxToRem(20)};
`
export const TooltipModalBackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CoverageButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
`

export const CoverageButton = styled.div`
  flex-shrink: 0;
  margin-left: ${convertPxToRem(8)};
  background-color: ${({ activeButton, itemClicked }) =>
    activeButton === itemClicked ? primaryLightBlue : secondaryLightGrey};
  padding: ${convertPxToRem(6)};
  border-radius: ${convertPxToRem(16)};
  color: ${({ activeButton, itemClicked }) =>
    activeButton === itemClicked ? primaryWhite : primaryDarkNavy};
  ${capsHeading1_12_Medium}
`

export const PlanContainer = styled.div``

export const Divider = styled.div`
  margin-top: ${convertPxToRem(40)};
  margin-bottom: ${convertPxToRem(40)};
  background-color: ${primaryWhite};
  height: ${convertPxToRem(3)};
`

import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Calendar from 'src/components/SvgIcons/Misc/Calendar'
import ClockHistory from 'src/components/SvgIcons/Misc/ClockHistory'
import { DrugPrice } from 'src/entities/drugDetails'
import { PriceLabel } from 'src/features/MedicationCostWithTooltip'
import InfoTitle from 'src/sections/SectionMedications/MedicationDetails/Pricing/InfoTitle'
import {
  boxShadowScreenLight,
  primaryDarkNavy,
  secondaryBackgroundGrey,
  secondaryGrey,
  secondaryLightGrey,
} from 'src/theme/colors'
import { heading5_19, text3_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface MailOrderPharmacyCardProps {
  pharmacyData: DrugPrice
  onClick: () => void
}

export function MailOrderPharmacyCard({
  pharmacyData,
  onClick,
}: MailOrderPharmacyCardProps) {
  const { t } = useTranslation()

  const {
    patientPay,
    isBestPrice,
    isRejected,
    insurancePay,
    pharmacy: { name, deliveryTime },
  } = pharmacyData

  const i18n = {
    description: (
      <Trans
        i18nKey="translation.medications.pricing_tab.mc_description"
        values={{
          value: `$${Number(patientPay).toFixed(2)}`,
          dayCount: pharmacyData.pharmacy.daysSupply,
        }}
        components={{ bold: <BoldText /> }}
      />
    ),
    daysSupply: t(
      'translation.medications.pricing_tab.card_options.days_supply_extended',
    ),
    deliveryTime: t(
      'translation.medications.pricing_tab.card_options.delivery_time_extended',
    ),
    days: t('translation.shared.others.day', {
      count: pharmacyData.pharmacy.daysSupply,
    }),
  }

  return (
    <Root isBestPrice={isBestPrice} data-test="mailOrderCard" onClick={onClick}>
      <Content>
        <Name>{name}</Name>

        <InfoTitle
          icon={<Calendar />}
          textKey={i18n.deliveryTime}
          value={deliveryTime}
          type="time"
        />

        <InfoTitle
          icon={<ClockHistory />}
          textKey={i18n.daysSupply}
          value={i18n.days}
          type="daysSupply"
        />
      </Content>

      <PriceLabel
        patientPay={patientPay}
        isBestPrice={isBestPrice}
        isRejected={isRejected}
        insurancePay={insurancePay}
      />

      {isBestPrice && <Description>{i18n.description}</Description>}
    </Root>
  )
}

interface IsBestProps {
  isBestPrice?: boolean
}

const Root = styled.div<IsBestProps>`
  position: relative;
  display: grid;
  grid-template-areas:
    'content label'
    'description description';
  grid-template-columns: 3fr 1fr;

  gap: ${({ isBestPrice }) => (isBestPrice ? `${convertPxToRem(8)}` : null)};
  padding: ${convertPxToRem(16, 20)};
  border-radius: ${convertPxToRem(8)};
  border: ${convertPxToRem(1)} solid ${secondaryLightGrey};
  box-shadow: ${convertPxToRem(0, 4, 26)} ${boxShadowScreenLight};

  cursor: pointer;
`

const BoldText = styled.span`
  font-weight: bold;
`

const Description = styled.span`
  color: ${secondaryGrey};
  background-color: ${secondaryBackgroundGrey};
  padding: ${convertPxToRem(12)};
  border-radius: ${convertPxToRem(8)};
  ${text3_12};
  grid-area: description;
`

const Name = styled.span`
  color: ${primaryDarkNavy};
  ${heading5_19};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
  gap: ${convertPxToRem(8)};
`

import { IconProps } from 'src/components/SvgIcons/types'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ClockIconProps extends IconProps {
  className?: string
}

function ClockIcon({
  width = convertPxToRem(24),
  height = convertPxToRem(24),
  fill = 'none',
  className = undefined,
}: ClockIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 12C0 5.373 5.373 0 12 0c6.624.007 11.993 5.376 12 12 0 6.627-5.373 12-12 12S0 18.627 0 12zm15.787 5.283c.41.364 1.036.337 1.413-.062v.001c.179-.196.273-.455.26-.72-.012-.266-.13-.515-.326-.694L12.5 11.56V6.5c0-.552-.448-1-1-1s-1 .448-1 1V12c.001.28.12.548.328.737l4.959 4.546z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ClockIcon

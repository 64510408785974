import React from 'react'

import { primaryDarkNavy } from 'src/theme/colors'

export function HomeIcon() {
  return (
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.993 9.705c0 .625-.52 1.114-1.111 1.114H17.77l.024 5.56c0 .097-.007.187-.017.28v.563c0 .768-.622 1.39-1.39 1.39h-.555c-.038 0-.076-.032-.114-.004-.049-.028-.098.003-.146.003H13.61a1.389 1.389 0 0 1-1.389-1.389v-3.055a1.11 1.11 0 0 0-1.11-1.111H8.888a1.11 1.11 0 0 0-1.111 1.11v3.056c0 .768-.622 1.39-1.39 1.39h-1.94c-.052 0-.104-.004-.156-.008a1.496 1.496 0 0 1-.125.007H3.61a1.389 1.389 0 0 1-1.389-1.389v-3.889c0-.03.001-.066.003-.097V10.82H1.113A1.1 1.1 0 0 1 0 9.705c0-.313.104-.59.348-.833l8.902-7.76c.243-.244.52-.279.764-.279.243 0 .52.07.732.244l8.865 7.795c.278.243.42.52.382.833z"
        fill={primaryDarkNavy}
      />
    </svg>
  )
}

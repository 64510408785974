import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import VIEW_SETTINGS_QUERY from 'src/apollo/queries/VIEW_SETTINGS_QUERY'

const getFeaturesArray = (data) => {
  const features = data?.currentUser?.view_settings?.features
  return Array.isArray(features) ? features : []
}

const useViewSettingsQuery = ({ skip = false } = {}) => {
  const {
    data,
    error,
    loading: isLoadingUserSettings,
  } = useQuery(VIEW_SETTINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip,
  })

  const featureFlags = useMemo(() => {
    const features = getFeaturesArray(data)

    return features.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.name]: currentValue.available,
      }),
      {},
    )
  }, [data])

  const hasFeaturesData = getFeaturesArray(data).length > 0

  return {
    featureFlags,
    isLoadingUserSettings,
    hasFeaturesData,
    error,
  }
}

export default useViewSettingsQuery

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import MEMBER_PROFILE_QUERY from 'src/apollo/queries/MEMBER_PROFILE_QUERY'
import { extractProfileData } from 'src/sections/SectionProfile/profileHelpers'

const useMemberProfileSurveyQuestionsQuery = () => {
  const { data, loading, error } = useQuery(MEMBER_PROFILE_QUERY)
  const profileSurveyData = useMemo(() => extractProfileData(data), [data])
  return {
    profileSurveyData: profileSurveyData || {},
    profileSurveyLoading: loading,
    error,
  }
}

export default useMemberProfileSurveyQuestionsQuery

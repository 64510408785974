// export const fontRegular = 'SailecRegular'
// export const fontMedium = 'SailecMedium'
// export const fontBold = 'SailecBold'

import { convertPxToRem } from 'src/utils/responsiveHelpers'

const fontSourceSerifProSemiBold = 'SourceSerifPro-SemiBold'
const fontSourceSansProSemiBold = 'SourceSansPro-SemiBold'
export const fontSourceSerifProRegular = 'SourceSerifPro-Regular'
const fontInterBold = 'Inter-Bold'
const fontInterSemiBold = 'Inter-SemiBold'
export const fontInterMedium = 'Inter-Medium'
export const fontInterRegular = 'Inter-Regular'

export const heading1_38 = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(38),
  lineHeight: convertPxToRem(42),
  letterSpacing: '0',
}

export const heading2_29 = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(29),
  lineHeight: convertPxToRem(36),
  letterSpacing: '0',
}

export const heading2_17 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(17),
  lineHeight: convertPxToRem(22),
  letterSpacing: convertPxToRem(-0.41),
}

export const heading2_17_Medium = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(17),
  lineHeight: convertPxToRem(22),
  letterSpacing: convertPxToRem(-0.41),
}

export const heading3_25 = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(25),
  lineHeight: convertPxToRem(32),
  letterSpacing: '0',
}

export const heading4_21 = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(21),
  lineHeight: convertPxToRem(28),
  letterSpacing: convertPxToRem(0.04),
}

export const heading5_19 = {
  fontFamily: fontSourceSansProSemiBold,
  fontSize: convertPxToRem(19),
  lineHeight: convertPxToRem(24),
  letterSpacing: '0',
}

export const heading6_16 = {
  fontFamily: fontInterSemiBold,
  fontSize: convertPxToRem(16),
  lineHeight: convertPxToRem(20),
}

export const heading6_16_Medium = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(16),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}

export const heading7_14 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(20),
  letterSpacing: '0',
}

export const heading8_14 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(18),
  letterSpacing: convertPxToRem(-0.16),
}

export const heading8_12 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(16),
  letterSpacing: '0',
}

export const capsHeading1_12 = {
  fontFamily: fontInterBold,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(18),
  letterSpacing: convertPxToRem(0.48),
}

export const capsHeading1_12_Medium = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(18),
  letterSpacing: convertPxToRem(0.48),
}
export const capsHeading2_10 = {
  fontFamily: fontInterBold,
  fontSize: convertPxToRem(10),
  lineHeight: convertPxToRem(12),
  letterSpacing: convertPxToRem(0.5),
}
export const capsHeading3_9_Medium = {
  fontFamily: fontInterSemiBold,
  fontSize: convertPxToRem(9),
  lineHeight: convertPxToRem(12),
  letterSpacing: convertPxToRem(0.45),
}
export const capsHeading4_9 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(9),
  lineHeight: convertPxToRem(12),
  letterSpacing: convertPxToRem(0.45),
}

export const text1_16 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(16),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}
export const text1_16_Bold = {
  fontFamily: fontInterSemiBold,
  fontSize: convertPxToRem(16),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}
export const text2_14 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}
export const text2_14_Medium = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}
export const text2_14_Bold = {
  fontFamily: fontInterSemiBold,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(22),
  letterSpacing: '0',
}
export const text3_12 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(18),
  letterSpacing: '0',
}
export const text4_10 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(10),
  lineHeight: convertPxToRem(12),
  letterSpacing: '0',
}
export const text4_12 = {
  fontFamily: fontInterSemiBold,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(18),
  letterSpacing: '0',
}

export const textLink1_16 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(16),
  lineHeight: convertPxToRem(20),
  letterSpacing: '0',
}
export const textLink2_14 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(14),
  lineHeight: convertPxToRem(24),
  letterSpacing: '0',
}
export const textLink3_10 = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(10),
  lineHeight: convertPxToRem(12),
  letterSpacing: '0',
}

export const heading1_20 = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(20),
  lineHeight: convertPxToRem(24),
  letterSpacing: '0',
}
export const mobileNav1_11 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(11),
  lineHeight: convertPxToRem(16),
  letterSpacing: '0',
}
export const regular_14 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(14),
}
export const regular_12 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(11),
  lineHeight: convertPxToRem(16),
}
export const sailec_Medium = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(24),
  letterSpacing: convertPxToRem(0.6),
}
export const sailec_Large = {
  fontFamily: fontInterMedium,
  fontSize: convertPxToRem(24),
  lineHeight: convertPxToRem(32),
  letterSpacing: convertPxToRem(0.6),
}
export const sailec_Regular = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(12),
  lineHeight: convertPxToRem(24),
  letterSpacing: convertPxToRem(0.6),
}
export const Heading = {
  fontFamily: fontSourceSerifProSemiBold,
  fontSize: convertPxToRem(24),
  lineHeight: convertPxToRem(28),
  letterSpacing: '0',
}
export const heading6_24 = {
  fontFamily: fontInterRegular,
  fontSize: convertPxToRem(24),
  letterSpacing: '0',
}

import React from 'react'

import PropTypes from 'prop-types'

import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ClockHistory({
  width = convertPxToRem(16),
  height = convertPxToRem(16),
  fill = secondaryGrey,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C12.4187 0 16 3.58125 16 8C16 12.4187 12.4187 16 8 16C6.30312 16 4.725 15.4688 3.42812 14.5656C2.975 14.2219 2.86375 13.625 3.18125 13.1719C3.49688 12.7188 4.11875 12.6094 4.57188 12.925C5.54375 13.6031 6.725 14 8 14C11.3125 14 14 11.3125 14 8C14 4.65938 11.3125 2 8 2C6.31563 2 4.84375 2.67062 3.75625 3.75625L4.71875 4.71875C5.19063 5.19063 4.85625 6 4.19063 6H0.75C0.335938 6 0 5.66563 0 5.25V1.81062C0 1.1425 0.807813 0.807813 1.28031 1.28031L2.34313 2.34313C3.79063 0.896563 5.79063 0 7.97188 0H8ZM8 4C8.41562 4 8.75 4.33437 8.75 4.75V7.69063L10.7531 9.71875C11.0719 10.0125 11.0719 10.4875 10.7531 10.7531C10.4875 11.0719 10.0125 11.0719 9.71875 10.7531L7.46875 8.50313C7.32812 8.39062 7.25 8.2 7.25 8V4.75C7.25 4.33437 7.58437 4 8 4Z"
        fill={fill}
      />
    </svg>
  )
}

ClockHistory.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default ClockHistory

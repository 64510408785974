import { useMemo } from 'react'

import { useNavigate } from '@reach/router'
import { useUnit } from 'effector-react'

import CardCategory from 'src/components/Cards/CardCategory'
import { freshpaintEventTracked } from 'src/entities/freshpaint'
import { FreshpaintEvent } from 'src/entities/freshpaint/types'

import {
  BG_COLORS,
  getLabelColors,
  openIterableCard,
} from './activityFeedHelpers'

interface InAppCardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inAppMessagePayload: Record<string, any>
  setErrorMessage: (value: string) => void
  setDismissItemType: (id: string) => void
  messageId: string
}

export default function InAppCard({
  inAppMessagePayload,
  setDismissItemType,
  setErrorMessage,
  messageId,
}: InAppCardProps) {
  const navigate = useNavigate()
  const trackFreshpaint = useUnit(freshpaintEventTracked)

  const { cardType, title, body, label, callToAction, imageUrl, url } =
    inAppMessagePayload

  const labelColor = useMemo(
    () =>
      getLabelColors({
        category: cardType,
        resourceStatus: null,
        isResourceArchived: false,
      }),
    [cardType],
  )

  const categoryCardItem = {
    id: messageId,
    title,
    description: body,
    label,
    callToAction,
    imageUrl,
  }

  const openDismissAlert = () => {
    setDismissItemType(messageId)
  }

  const handleClick = () => {
    trackFreshpaint({
      event: FreshpaintEvent.ActivityCardClicked,
      properties: { CardID: inAppMessagePayload.id },
    })
    openIterableCard({ url, setErrorMessage, navigate })
  }

  return (
    <CardCategory
      dataTestProp={`${cardType}-card`}
      item={categoryCardItem}
      bgColor={BG_COLORS[cardType]}
      labelBg={labelColor}
      onClick={handleClick}
      dismissable
      openDismissAlert={openDismissAlert}
    />
  )
}

import { gql } from '@apollo/client'

const CURRENT_USER_IGNORE_ERRORS_QUERY = gql`
  query currentUserIgnoreErrorsQuery {
    currentUser {
      id
    }
  }
`

export default CURRENT_USER_IGNORE_ERRORS_QUERY

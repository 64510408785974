import { useMutation } from '@apollo/client'

import UPDATE_TUTORIAL_MUTATION from 'src/apollo/mutations/UPDATE_TUTORIAL_MUTATION'
import { getGraphQlError } from 'src/utils/errorHelpers'

const useUpdateTutorialMutation = () => {
  const [updateTooltipsViewed] = useMutation(UPDATE_TUTORIAL_MUTATION)

  const updateTutorialVievedMutation = async (variables) => {
    try {
      return await updateTooltipsViewed({ variables })
    } catch (error) {
      return getGraphQlError(error)
    }
  }

  return { updateTutorialVievedMutation }
}

export default useUpdateTutorialMutation

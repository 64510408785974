import { lazy, Suspense } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import GuidesPhoneNumbersModal from 'src/sections/SectionDashboard/GuidesPhoneNumbersModal'
import { RootState } from 'src/store'
import { THIRD_SECTION_CONTENT } from 'src/store/slices/common'
import { ModalBase } from 'src/theme/baseStyles'
import { primaryWhite } from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const ThirdSectionChats = lazy(() =>
  import('src/pages/thirdSection/Chats')
    .then((module) => module.ThirdSectionChats)
    .then((component) => ({ default: component })),
)

interface ThirdSectionProps {
  withModal: boolean
  content: (typeof THIRD_SECTION_CONTENT)[keyof typeof THIRD_SECTION_CONTENT]
}

function ThirdSection({ withModal, content }: ThirdSectionProps) {
  return (
    <Root data-modal={withModal}>
      <Suspense fallback={<Spinner />}>{Content[content] ?? null}</Suspense>
    </Root>
  )
}

const Content = {
  [THIRD_SECTION_CONTENT.CHAT]: <ThirdSectionChats />,
  [THIRD_SECTION_CONTENT.SELECT_PHONE]: <GuidesPhoneNumbersModal />,
}

const Root = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-width: ${convertPxToRem(375)};
  background-color: ${primaryWhite};

  ${mediaQueryFor.mobile} {
    display: none;

    &[data-modal='true'] {
      display: block;
      max-width: 100%;

      ${ModalBase};
    }
  }
`

const mapStateToProps = ({ common }: RootState) => ({
  withModal: common.isShowThirdSectionModal,
  content: common.thirdSectionContent,
})

export default connect(mapStateToProps, null)(ThirdSection)

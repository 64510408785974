import { navigate, NavigateOptions } from '@reach/router'
import CoreLinkify, { FullRule, Match } from 'linkify-it'
import tlds from 'tlds'

import { formatPhone, PHONE_FORMATS } from './phoneHelpers'

export interface LocationCoordinates {
  latitude: number
  longitude: number
}

interface GoogleMapsLocationParams extends Record<string, string> {
  query: string
  api: string
}

interface OpenLocationParams {
  location: LocationCoordinates
}

const linkifyPhoneRule: FullRule = {
  validate(text: string, at: number) {
    const match = text.slice(at).match(/\+\d+/g)
    return match?.at(0)?.length ?? 0
  },
  normalize(match: Match) {
    // eslint-disable-next-line no-param-reassign
    match.text = formatPhone(match.url, PHONE_FORMATS.national) ?? ''
  },
}

export const Linkify = new CoreLinkify()
  .tlds(tlds)
  .add('tel:', linkifyPhoneRule)
  .set({ fuzzyLink: true })

export const formatCoordsToString = (coords: LocationCoordinates) =>
  `${coords.latitude},${coords.longitude}`

export const isDefaultCoords = (
  coords: LocationCoordinates,
): coords is typeof DEFAULT_COORDS =>
  coords?.latitude === DEFAULT_COORDS.latitude &&
  coords?.longitude === DEFAULT_COORDS.longitude

const encodeQueryParams = (params: Record<string, string | null>) =>
  Object.entries(params)
    .filter((item): item is [string, string] => Boolean(item[1]))
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(value)

      return `${encodedKey}=${encodedValue}`
    })
    .join('&')

const isValidLatLong = (coordinate: number, range: number) =>
  typeof coordinate === 'number' &&
  coordinate <= range &&
  coordinate >= -1 * range

const isValidLocation = ({ latitude, longitude }: LocationCoordinates) =>
  isValidLatLong(latitude, COORDINATE_RANGE.latitude) &&
  isValidLatLong(longitude, COORDINATE_RANGE.longitude)

export function buildGoogleMapsLocationLink(
  target: LocationCoordinates,
  params: Partial<GoogleMapsLocationParams> = {},
) {
  const query = encodeQueryParams({
    query: isValidLocation(target) ? formatCoordsToString(target) : null,

    api: '1',

    ...params,
  })

  return `https://www.google.com/maps/search/?${query}`
}

export function navigateWithQuery(
  path: string,
  options?: NavigateOptions<object>,
): Promise<void> {
  const { search } = window.location

  return navigate(`${path}${search}`, options)
}

export function openLocations({ location }: OpenLocationParams) {
  if (!location) return

  const url = buildGoogleMapsLocationLink(location)
  window.open(url, '_blank')
}

export const DEFAULT_COORDS = {
  // New York
  latitude: 40.73061,
  longitude: -73.935242,
} as const

export const DEFAULT_ZIP_CODE = '10000'

export const DEFAULT_ADDRESS = 'New York, NY'

const COORDINATE_RANGE = {
  latitude: 90,
  longitude: 180,
} as const

export const EU_COUNTRIES: string[] = [
  // EU countries
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  // EEA countries
  'IS',
  'LI',
  'NO',
]

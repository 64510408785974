import { UploadFileType } from 'src/shared/api/types'

import { useUploadedFileQuery } from '../api/userUploadQuery'

import { useFetchSecureFile } from './useFetchSecureFile'

export interface UseShortLinkArgs {
  id: string
  type: UploadFileType
  link: string
}

export function useShortLink({ id, type, link }: UseShortLinkArgs) {
  const { getNewLink } = useUploadedFileQuery({ id, type })

  return useFetchSecureFile({ link, getNewLink })
}

import { useUnit } from 'effector-react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import healthGuide from 'src/assets/images/assistanceIcon.png'
import { $$chat } from 'src/entities/chat'
import { withDispatch } from 'src/store'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import {
  featurePaleGreen,
  primaryLightBlue,
  primaryWhite,
  secondaryLightGrey,
  statusActive,
} from 'src/theme/colors'
import { capsHeading1_12 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { useInboxStatusQuery } from '../api/inboxStatusQuery'
import { useInboxStatusUpdatesSubscription } from '../api/inboxStatusSubscription'

interface InboxStatusViewProps extends ConnectedProps<typeof connector> {
  className?: string
}

function InboxStatusView({
  className = undefined,

  dispatchShowThirdSectionModal,
}: InboxStatusViewProps) {
  const { unreadCount, isOnline } = useInboxStatusQuery()

  useInboxStatusUpdatesSubscription()

  const [openInbox, startChat] = useUnit([$$chat.close, $$chat.create])

  const handleClick = () => {
    dispatchShowThirdSectionModal()

    if (unreadCount) openInbox()
    else startChat()
  }

  return (
    <Wrapper
      className={className}
      data-test="inboxStatus"
      onClick={handleClick}
    >
      <HealthGuide src={healthGuide} />
      <Online data-online={isOnline} />

      {unreadCount > 0 && (
        <Count data-test="inboxStatus.count">{unreadCount}</Count>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  user-select: none;

  cursor: pointer;
`

const HealthGuide = styled.img`
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};

  border: ${convertPxToRem(4)} solid ${primaryWhite};
  border-radius: 50%;

  padding: ${convertPxToRem(2)};
  object-fit: contain;

  background-color: ${featurePaleGreen};

  box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);
`

const Count = styled.span`
  ${capsHeading1_12};

  height: ${convertPxToRem(22)};
  min-width: ${convertPxToRem(22)};

  border-radius: ${convertPxToRem(22)};

  text-align: center;
  padding: ${convertPxToRem(3, 3, 3.67, 3)};
  line-height: ${convertPxToRem(16.8)};

  color: ${primaryWhite};
  background-color: ${primaryLightBlue};

  position: absolute;
  left: ${convertPxToRem(28)};
`

const Online = styled.div`
  width: ${convertPxToRem(12)};
  height: ${convertPxToRem(12)};

  border: ${convertPxToRem(2)} solid ${primaryWhite};
  border-radius: 50%;

  position: absolute;
  bottom: ${convertPxToRem(2)};
  right: ${convertPxToRem(-2)};

  background-color: ${secondaryLightGrey};

  &[data-online='true'] {
    background-color: ${statusActive};
  }
`

const mapDispatchToProps = withDispatch({
  showThirdSectionModal: () => setIsShowThirdSectionModal(true),
})

const connector = connect(null, mapDispatchToProps)

export const InboxStatus = connector(InboxStatusView)

import { createMutation } from 'effector-apollo'

import client from 'src/apollo/client'

import { SIGN_OUT_MUTATION } from './graphql'

export const signOutMutation = createMutation({
  document: SIGN_OUT_MUTATION,
  context: { isSkipError: true },
  client,
})

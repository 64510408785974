import { reflect } from '@effector/reflect'
import styled from 'styled-components'

import { remap } from 'src/shared/lib/effector/remap'
import { primaryDarkNavy } from 'src/theme/colors'
import { heading4_21 } from 'src/theme/fonts'

import { DrugSearchDetailsGate } from '../../model'

interface DrugDetailsNameComponentProps {
  drugName: string
}

function DrugDetailsNameComponent({ drugName }: DrugDetailsNameComponentProps) {
  return <Title>{drugName}</Title>
}

const Title = styled.span`
  display: flex;

  color: ${primaryDarkNavy};
  ${heading4_21};
`

export const DrugDetailsName = reflect({
  view: DrugDetailsNameComponent,
  bind: {
    drugName: remap(DrugSearchDetailsGate.state, 'drugName'),
  },
})

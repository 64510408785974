/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce, type Draft } from 'immer'
import isEqual from 'lodash/isEqual'

/**
 * A enum of available (supported) storage containers.
 */
export enum StorageContainer {
  /**
   * Persistent container is used for global things, unrelated to user.
   * The contents are not intended to be deleted
   */
  persistent = 'persistent',
  /**
   * Personal container is a custom per-user container for
   * information that is not shared between users.
   * The contents are personalized and are not inteded to be reset.
   */
  personal = 'personal',
  /**
   * Token container that is used to store access/refresh tokens.
   */
  tokens = 'tokens',
}

type PayloadAction<S = any, A = any> = (
  draft: Draft<S>,
  arg: A,
) => S | void | null
type PureAction<S = any> = (draft: Draft<S>) => S | void | null
type AnyAction<S = any, A = any> = (draft: Draft<S>, arg?: A) => S | void | null

type ActionMap<State> = { [K: string]: AnyAction<State> }

type HooksForActions<Actions extends ActionMap<any>> = {
  [Name in keyof Actions]: Actions[Name] extends PureAction
    ? () => void
    : Actions[Name] extends PayloadAction<any, infer A>
    ? (arg: A) => void
    : never
}

export type StorageSubscriber<S = any> = (state: S) => void

interface IStorageOptions<
  Container extends StorageContainer,
  State = any,
  Actions extends ActionMap<State> = ActionMap<State>,
> {
  /**
   * A container to which the Storage API will be bound
   */
  container: Container

  /**
   * An initial state in case container is missing.
   * This will be used to populate state when an empty container is accessed.
   *
   * Also, this is useful for TypeScript when you want to specify container state types.
   * ```
   * interface MyContainerState {
   *   termsOfService: boolean
   * }
   * // ...
   * createStorageInterface({
   *   initialState: {} as MyContainerState,
   *   actions: {
   *     setTermsOfService(state, value: boolean) {
   *       state.termsOfService = value // state is typed correctly!
   *     }
   *   }
   * })
   * ```
   */
  initialState: State

  /**
   * Specify actions to modify container.
   */
  actions: Actions
}

/**
 * Storage API represents a 'connection' to a storage container.
 * All provided actions are bound to a specific container.
 *
 * This should be created once during initialization and then reused when needed.
 */
interface IStorageAPI<
  Container extends StorageContainer,
  State = any,
  Actions extends ActionMap<State> = ActionMap<State>,
> {
  /**
   * A container to which the Storage API is bound
   */
  container: Container

  /**
   * An object that contains actions, provided during creation, bound to a container.
   * This means that actions will operate on a specific container when called.
   *
   * Actions can only modify the contents of the container, and do not return anything.
   */
  modify: HooksForActions<Actions>

  /**
   * A stable way to access contents of the container.
   * Returns current container state, or `initialState` when container is broken or empty.
   */
  access: () => State

  /**
   * Allows you to subscribe to state changes.
   * Returns an unsubscribe function when called, allowing you to unsubscribe from changes.
   */
  subscribe: (fn: StorageSubscriber<State>) => () => void
}

export function createStorageInterface<
  State,
  Actions extends ActionMap<State>,
  Container extends StorageContainer,
>(
  options: IStorageOptions<Container, State, Actions>,
): IStorageAPI<Container, State, Actions> {
  const { container, actions, initialState } = options

  const subscribers: StorageSubscriber<State>[] = []

  const aquireState = (): State => {
    const storage = localStorage.getItem(container) ?? ''
    try {
      return JSON.parse(storage)
    } catch {
      return initialState
    }
  }

  const saveState = (state: State): void => {
    if (state === null) localStorage.removeItem(container)
    else localStorage.setItem(container, JSON.stringify(state))
  }

  const wrapModify =
    <T>(fn: AnyAction<State, T>) =>
    (arg?: T) => {
      const prev = aquireState()
      const next = produce(prev, (s): any => fn(s, arg))

      if (isEqual(next, prev)) return

      setTimeout(() => {
        // On next tick run all subscribers
        const state = next === null ? initialState : next
        subscribers.forEach((sub) => sub(state))
      }, 0)

      saveState(next)
    }

  const subscribe = (fn: StorageSubscriber<State>) => {
    if (!subscribers.includes(fn)) {
      subscribers.push(fn)
    }

    return function unsubscribe(): void {
      const idx = subscribers.indexOf(fn)

      if (idx >= 0) {
        subscribers.splice(idx, 1)
      }
    }
  }

  return {
    container,
    modify: Object.keys(actions).reduce(
      (acc, key) => Object.assign(acc, { [key]: wrapModify(actions[key]) }),
      {} as HooksForActions<Actions>,
    ),
    access: aquireState,
    subscribe,
  }
}

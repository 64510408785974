import { Appointment } from '../../api/appointmentQuery/types'
import { isDoctorAssignee } from '../../lib/doctor'

import { AppointmentDoctorInfo } from './AppointmentDoctorInfo'
import { AppointmentFacilityInfo } from './AppointmentFacilityInfo'

interface AppointmentAssigneeInfoProps {
  appointment: Appointment
}

export function AppointmentAssigneeInfo({
  appointment,
}: AppointmentAssigneeInfoProps) {
  const { assignee, savedAddress } = appointment

  const isDoctor = isDoctorAssignee(assignee)

  return isDoctor ? (
    <AppointmentDoctorInfo doctor={assignee} savedAddress={savedAddress} />
  ) : (
    <AppointmentFacilityInfo facility={assignee} />
  )
}

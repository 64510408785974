import React, { createContext, useState } from 'react'

import { useLocation } from '@reach/router'

import { ROUTES } from 'src/constants/routesConstants'
import { CHAT_BOX_MIN_HEIGHT } from 'src/sections/SectionDashboard/dashboardHelpers'
import { checkPathName } from 'src/utils/routeHelpers'

export const HeaderAnimationContext = createContext()

const HeaderAnimationProvider = ({ children }) => {
  const location = useLocation()
  const [scrollTop, setScrollTop] = useState(0)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const isCoverageTab = checkPathName(`/${ROUTES.HOME_COVERAGE_PATH}`, location)
  const isMedicationsTab = checkPathName(`/${ROUTES.MEDICATIONS}`, location)
  const divisor = isMedicationsTab
    ? CHAT_BOX_MIN_HEIGHT / 10
    : CHAT_BOX_MIN_HEIGHT
  const isAnimateChatBox = scrollTop > CHAT_BOX_MIN_HEIGHT / 2
  const isHideChatBox = scrollTop > divisor
  const isChangeGetHelpBtn = scrollTop > 0

  const handleScroll = (scroll) => {
    if (scroll > CHAT_BOX_MIN_HEIGHT && !isCoverageTab && !isMedicationsTab)
      return
    setScrollTop(scroll)
  }

  const value = {
    setScrollTop: handleScroll,
    scrollTop,
    isAnimateChatBox,
    isHideChatBox,
    isChangeGetHelpBtn,
    scrolledToBottom,
    setScrolledToBottom,
  }

  return (
    <HeaderAnimationContext.Provider value={value}>
      {children}
    </HeaderAnimationContext.Provider>
  )
}

export default HeaderAnimationProvider

import { useCallback } from 'react'

import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import ButtonBottom from 'src/components/Buttons/ButtonBottom'

export function DefaultActionBar() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const i18n = {
    close: t('translation.shared.buttons.button_close'),
  }

  const handleClose = useCallback(() => navigate(-1), [navigate])

  return (
    <ButtonBottom
      title={i18n.close}
      theme="primary"
      handleClick={handleClose}
    />
  )
}

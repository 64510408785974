import { TypedDocumentNode, gql } from '@apollo/client'

import { CHAT_BRIEF_FRAGMENT } from 'src/widgets/ChatsInbox'

import {
  ChatsSubscriptionResult,
  ChatsSubscriptionVariables,
  ChatsUpdateResult,
  ChatsUpdateVariables,
} from './types'

export const CHATS_SUBSCRIPTION: TypedDocumentNode<
  ChatsSubscriptionResult,
  ChatsSubscriptionVariables
> = gql`
  subscription chatsSubscription {
    chatsEvents {
      event
      chat {
        ...chatBrief
      }
    }
  }

  ${CHAT_BRIEF_FRAGMENT}
`

export const CHATS_UPDATE_QUERY: TypedDocumentNode<
  ChatsUpdateResult,
  ChatsUpdateVariables
> = gql`
  query chatsUpdate {
    chats(filters: { search: "" }) @connection(key: "new") {
      nodes {
        id
      }
    }
  }
`

import { Suspense } from 'react'

import { Redirect, Router } from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import FeatureGuard from 'src/components/FeatureGuard'
import HeaderNavMainTableOrLess from 'src/components/HeaderNavs/HeaderNavMainTableOrLess'
import HomeTabBarMobile from 'src/components/HomeTabBarMobile'
import Spinner from 'src/components/Spinner'
import { FeatureFlag } from 'src/constants/featureFlags'
import { ROUTES } from 'src/constants/routesConstants'
import RightwayFooter from 'src/sections/SectionHome/RightwayFooter'
import { lazyWithRetry } from 'src/shared/lib/lazy'
import { RootState } from 'src/store'
import mediaQueryFor from 'src/theme/mediaQueries'

const Activity = lazyWithRetry(
  () => import('src/sections/SectionHome/Activity'),
)

const Coverage = lazyWithRetry(
  () => import('src/sections/SectionHome/Coverage'),
)

const GetCare = lazyWithRetry(() => import('src/sections/SectionHome/GetCare'))

const SectionMedications = lazyWithRetry(
  () => import('src/sections/SectionMedications'),
)

const BillingClaims = lazyWithRetry(
  () => import('src/sections/SectionBillingAdvocacy/BillingClaims'),
)

interface SectionHomeProps extends ConnectedProps<typeof connector> {
  isSequoiaUser: boolean
  homePage: string
}

function SectionHome({
  isSequoiaUser = false,
  homePage = '',
}: SectionHomeProps) {
  return (
    <Root>
      <HeaderNavMainTableOrLess />
      <Suspense fallback={<Spinner size="large" />}>
        <Router className="rootRouter">
          <FeatureGuard
            permit={FeatureFlag.SHARED_ACTIVITY_FEED}
            path={`${ROUTES.HOME_ACTIVITY}/*`}
            component={<Activity isSequoiaUser={isSequoiaUser} />}
          />

          <FeatureGuard
            permit={FeatureFlag.SHARED_INSURANCE_BENEFITS}
            path={`${ROUTES.HOME_COVERAGE}/*`}
            component={<Coverage />}
          />

          <FeatureGuard
            permit={FeatureFlag.CORE_NAV}
            path={ROUTES.HOME_GET_CARE}
            component={<GetCare />}
          />

          <FeatureGuard
            permit={[FeatureFlag.BILL_ADVOCACY, FeatureFlag.CORE_NAV]}
            path={`${ROUTES.BILL_CLAIMS}/*`}
            component={<BillingClaims isSequoiaUser={isSequoiaUser} />}
          />

          <FeatureGuard
            permit={FeatureFlag.MED_CABINET}
            path={`${ROUTES.MEDICATIONS}/*`}
            component={<SectionMedications />}
          />

          <Redirect from="/" to={`/${homePage}`} noThrow />
        </Router>
      </Suspense>

      <Bottom>
        <HomeTabBarMobile />
      </Bottom>

      {isSequoiaUser && <RightwayFooter />}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`

const Bottom = styled.div`
  display: none;
  ${mediaQueryFor.mobile} {
    display: flex;
  }
`

const mapStateToProps = ({ user, common }: RootState) => ({
  isSequoiaUser: user.isSequoiaUser,
  homePage: common.homePage,
})

const connector = connect(mapStateToProps, null)
export default connector(SectionHome)

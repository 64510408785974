import { useCallback } from 'react'

import {
  ApolloCache,
  MutationUpdaterFunction,
  useMutation,
} from '@apollo/client'

import DISMISS_FEEDITEM_MUTATION from 'src/apollo/mutations/DISMISS_FEEDITEM_MUTATION'
import { getGraphQlError } from 'src/utils/errorHelpers'

// TODO: change this when GraphQL + TS arrives
interface UseDismissFeedItemResponse {
  dismissFeedItem: {
    feed_item: {
      id: string
      expired_at: string
      __typename: string
    }
  }
}

interface UseDismissFeedItemVariables {
  id: string
}

const useDismissFeedItemMutation = () => {
  const [handleDismiss, { loading }] = useMutation<
    UseDismissFeedItemResponse,
    UseDismissFeedItemVariables
  >(DISMISS_FEEDITEM_MUTATION, { update: updateActivityFeedOnDismiss })

  const dismissFeedItem = useCallback(
    async (variables: UseDismissFeedItemVariables) => {
      try {
        const dismissedData = await handleDismiss({ variables })
        return { dismissedData }
      } catch (err) {
        return getGraphQlError(err)
      }
    },
    [handleDismiss],
  )

  return { dismissFeedItem, dismissLoading: loading }
}

const updateActivityFeedOnDismiss: MutationUpdaterFunction<
  UseDismissFeedItemResponse,
  unknown,
  unknown,
  ApolloCache<unknown>
> = (cache, result) => {
  const { dismissFeedItem: item = null } = result.data ?? {}

  if (!item) {
    // If mutation failed, bail
    return
  }

  cache.evict({
    // Remove dismissed item from cache
    // This will make any references 'dangling', and thus remove from any views
    id: cache.identify(item),
  })

  // We still need to update the Activity Feed counter, as it relies on `totalCount` in `pageInfo`
  cache.modify({
    fields: {
      feedItems(current, { canRead }) {
        const {
          nodes,
          pageInfo: { totalCount, ...otherInfo },
        } = current

        // We can *not* dismiss the same item twice, as the all mutations except first will just fail on BE
        // Therefore, we can change the count by substracting once
        const count = Number(totalCount) - 1

        return {
          // While we are at it, remove any 'dangling' references from the array
          nodes: nodes.filter(canRead),
          pageInfo: {
            ...otherInfo,
            totalCount: String(count),
          },
        }
      },
    },
  })
}

export default useDismissFeedItemMutation

import React from 'react'

import styled from 'styled-components'

import pillsImage from 'src/assets/images/pills@2x.png'
import { useScopedTranslation } from 'src/shared/hooks/useScopedTranslation'
import { secondaryGrey } from 'src/theme/colors'
import { text1_16 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

export function NoResults() {
  const t = useScopedTranslation('translation.medications.drug_search.messages')
  return (
    <Root>
      <Image src={pillsImage} alt="" />
      <Description>{t('no_search_results')}</Description>
    </Root>
  )
}

const Root = styled.div`
  display: flex;

  align-items: center;
  flex-direction: column;
  padding-top: ${convertPxToRem(68)};

  width: 100%;
`
const Image = styled.img`
  width: ${convertPxToRem(100)};
`
const Description = styled.span`
  color: ${secondaryGrey};
  ${text1_16};
  padding: ${convertPxToRem(22, 36, 0)};
  text-align: center;
`

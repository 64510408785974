import { useState } from 'react'

import { variant } from '@effector/reflect'
import { combine } from 'effector'
import styled from 'styled-components'

import {
  $currentDrugConfig,
  $specialtyPharmacies,
} from 'src/entities/drugDetails'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { NoticeBubble } from 'src/shared/ui/NoticeBubble'
import { primaryBlue } from 'src/theme/colors'
import { textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { WarningMessage } from './WarningMessage'

function InfoMessageView() {
  const t = useScopedTranslation('translation.medications.drug_search')

  const i18n = {
    narrowed: t('messages.disclaimer'),
    expanded: t('messages.disclaimer_expanded'),
    more: t('buttons.view_more'),
    less: t('buttons.view_less'),
  }

  const [isExpanded, updateExpanded] = useState(false)

  const handleClick = () => updateExpanded(!isExpanded)

  return (
    <NoticeBubble>
      <Container>
        <Message>{isExpanded ? i18n.expanded : i18n.narrowed}</Message>
        <ExpandButton onClick={handleClick} data-test="expandButton">
          {isExpanded ? i18n.less : i18n.more}
        </ExpandButton>
      </Container>
    </NoticeBubble>
  )
}

const Container = styled.span``

const Message = styled.span`
  padding-right: ${convertPxToRem(4)};

  // because the ExpandButton in the same row
  line-height: ${convertPxToRem(24)};
`

const ExpandButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  color: ${primaryBlue};
  ${textLink2_14};
`

const $hasWarning = combine(
  $currentDrugConfig,
  $specialtyPharmacies,
  ({ isDrugCovered }, pharmacies) => !isDrugCovered || pharmacies.length > 0,
)

export const InfoMessage = variant({
  if: $hasWarning,
  then: WarningMessage,
  else: InfoMessageView,
})

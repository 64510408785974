import { createEvent } from 'effector'

import { reset, save } from './compat'
import { $access, $refresh, $tokens } from './persist'
import { setup } from './setup'
import { $authenticated } from './status'

export const $$auth = {
  setup,

  $authenticated,

  token: { $access, $refresh, save, reset },

  signOut: {
    /**
     * A command to shut down the app and clean up.
     *
     * _Trigger_ this event when you need app to be closed.
     *
     * Invoking side _MUST_ reload the page after `signOut.completed`
     * completion to ensure browser state is 100% clean.
     */
    start: createEvent<void>(),
    completed: createEvent<void>(),
  },

  /** Testing Utilities */
  __: { $tokens },
}

import React from 'react'

import styled, { css } from 'styled-components'

import Spinner from 'src/components/Spinner'
import {
  primaryWhite,
  primaryDarkNavy,
  primaryBlue,
  primaryOrange,
  featurePaleGreen,
  secondaryLightGrey,
  secondaryGrey,
  featurePaleRed,
  featurePaleYellow,
  featurePaleBlue,
  statusErrorRed,
} from 'src/theme/colors'
import { textLink1_16, textLink2_14 } from 'src/theme/fonts'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

interface ButtonBottomProps {
  handleClick?: () => void
  title: string
  theme: ButtonBottomTheme
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  name?: string
  dataTestProp?: string
  className?: string
}

interface RootProps {
  readonly buttonTheme: ButtonBottomTheme
  readonly disabled?: boolean
}

function ButtonBottom({
  title,
  theme,
  handleClick = () => {},
  disabled = false,
  loading = false,
  type = 'button',
  name = '',
  dataTestProp = 'buttonBottom',
  className = '',
}: ButtonBottomProps) {
  return (
    <Root
      className={className}
      data-test={dataTestProp}
      onClick={handleClick}
      disabled={disabled}
      buttonTheme={theme}
      type={type}
      name={name}
    >
      {loading ? <Spinner size="small" /> : title}
    </Root>
  )
}

const styles = {
  primary: css`
    background-color: ${primaryBlue};
    color: ${primaryWhite};
  `,
  primaryThin: css`
    background-color: ${primaryBlue};
    color: ${primaryWhite};
    min-height: ${convertPxToRem(40)};
    ${textLink2_14};
  `,
  rightway: css`
    background-color: ${primaryOrange};
    color: ${primaryDarkNavy};
  `,
  delete: css`
    background-color: transparent;
    color: ${statusErrorRed};
  `,
  link: css`
    background-color: transparent;
    color: ${primaryDarkNavy};
  `,
  linkBlue: css`
    background-color: transparent;
    color: ${primaryBlue};

    &:disabled {
      background-color: transparent;
      color: ${secondaryLightGrey};
    }
  `,
  billing: css`
    background-color: ${featurePaleGreen};
    color: ${primaryDarkNavy};
  `,
  disabled: css`
    background-color: ${secondaryLightGrey};
    color: ${primaryWhite};
  `,
  carePlan: css`
    background-color: ${featurePaleBlue};
    color: ${primaryDarkNavy};
  `,
  insuranceEdit: css`
    background-color: ${primaryBlue};
    color: ${primaryWhite};
  `,
  border: css`
    background-color: transparent;
    border: ${convertPxToRem(1)} solid ${primaryDarkNavy};
  `,
  borderBlue: css`
    background-color: ${primaryWhite};
    border: ${convertPxToRem(1)} solid ${primaryBlue};
    color: ${primaryBlue};

    &:disabled {
      background-color: ${secondaryLightGrey};
      border: none;
      color: ${primaryWhite};
    }
  `,
  borderGrey: css`
    background-color: transparent;
    color: ${primaryDarkNavy};
    border: ${convertPxToRem(1)} solid ${secondaryGrey};
  `,
  coverageDetails: css`
    background-color: ${featurePaleRed};
    color: ${primaryDarkNavy};
  `,
  pbm: css`
    background-color: ${featurePaleYellow};
    color: ${primaryDarkNavy};
  `,
}

type ButtonBottomTheme = keyof typeof styles

const Root = styled.button<RootProps>`
  border-radius: ${convertPxToRem(36)};
  min-height: ${convertPxToRem(48)};
  padding: ${convertPxToRem(10, 30)};
  width: 100%;
  border: 0;
  ${textLink1_16};

  ${({ buttonTheme }) => styles[buttonTheme]};
  ${({ disabled }) => disabled && styles.disabled};
`

export default ButtonBottom

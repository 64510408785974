import { useGate } from 'effector-react'
import { ConnectedProps, connect } from 'react-redux'

import { withDispatch } from 'src/store'
import { startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'

import { EarlyAccessModalGate } from '../model/core'

import { EarlyAccessModalContent } from './EarlyAccessModalContent'

type EarlyAccessModalComponentProps = ConnectedProps<typeof connector>

function EarlyAccessModalComponent({
  dispatchStartNewChat,
  dispatchSetIsShowThirdSectionModal,
}: EarlyAccessModalComponentProps) {
  useGate(EarlyAccessModalGate, {
    dispatchStartNewChat,
    dispatchSetIsShowThirdSectionModal,
  })

  return <EarlyAccessModalContent />
}

const mapDispatchToProps = withDispatch({
  setIsShowThirdSectionModal,
  startNewChat,
})

const connector = connect(null, mapDispatchToProps)
export const EarlyAccessModal = connector(EarlyAccessModalComponent)
